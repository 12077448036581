import { Box, Chip, Toolbar, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import Backdrop from '@mui/material/Backdrop'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { parseURL, createTransfer } from '@solana/pay'
import { useWallet } from '@solana/wallet-adapter-react'
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { clusterApiUrl, Connection, Keypair, PublicKey } from '@solana/web3.js'
import BigNumber from 'bignumber.js'
import { collection, doc, getDocs, getFirestore, query, updateDoc, where } from 'firebase/firestore'
import React, { useEffect } from 'react'
import Countdown from 'react-countdown'
import { BiAddToQueue, BiAward, BiChart, BiCoin } from 'react-icons/bi'
import { useParams, useSearchParams } from 'react-router-dom'
import Label from '../../../components/label/Label'
import { Collections } from '../../../constants'
import { db } from '../../../contexts/firebaseContext'

export default function ProductPage(props) {
  const [product, setProduct] = React.useState(null)
  const [amount, setAmount] = React.useState(0)
  const [open, setOpen] = React.useState(false)
  const [openError, setOpenError] = React.useState(false)
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [openSending, setOpenSending] = React.useState(false)
  const [ticketsBought, setTicketsBought] = React.useState(0)
  const [productDBID, setProductDBID] = React.useState(null)

  const [ticketsPurchased, setTicketsPurchased] = React.useState(null)

  const [errorTxt, setErrorTxt] = React.useState('Error')

  let [searchParams, setSearchParams] = useSearchParams()
  const { productID } = useParams()
  const { publicKey, sendTransaction } = useWallet()

  const handleClick = () => {
    setOpen(true)
  }

  useEffect(() => {
    if (product) {
      let tix = localStorage.getItem(product.title)
      if (tix) {
        setTicketsPurchased(tix)
      }
    }
  }, [product])

  console.log('purhc', ticketsPurchased)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenSuccess(false)
    setOpenError(false)
  }

  let goLive = new Date('April 1, 2022 22:00:00')
  const getGoLive = date => {
    return new Date(date)
  }

  const addTicketSales = async amount => {
    let prod = product
    prod.sales += amount / product.price
    setProduct(prod)
    if (product) {
      console.log(product.sales)
      let sales = amount / product.price + product.sales
      let id = productDBID
      const productRef = doc(db, 'Products', id)
      await updateDoc(productRef, {
        sales: sales,
      })
    }
  }

  async function sendPayment(am, isSOL) {
    // Variable to keep state of the payment status
    // Connecting to devnet for this example

    console.log('1. ✅ Establish connection to the network')
    const connection = new Connection(clusterApiUrl('mainnet-beta'), 'confirmed')
    console.log('2. 🛍 Simulate a customer checkout \n')
    const splToken = isSOL ? null : new PublicKey('AShCRr7fqsMf7ieM5AkJqNY566HsYmtvpvK8oPUL4Bh8')
    let recipient = new PublicKey('13XaqvKH8YrgH6A31V7vhaLnQ9UHda6cJZxhW1iQNfR5')
    if (product.id === 'solana_money_girl_#214') {
      recipient = new PublicKey('CT7hHAwYnDQHo82NCFBuVsiLnnvpdK4p2P9zJDR227k4')
    }
    if (product.id === 'Money_Girl_Custom') {
      recipient = new PublicKey('CPHCzayp9vhxmnaX56zDRRbLFZD8CaUojxyxNyQNEvEH')
    }
    const amount = new BigNumber(am)
    const reference = new Keypair().publicKey
    const label = '$MBC Raffle Ticket'
    const message = 'Solana Money Boys - Raffle Ticket'
    const memo = 'RT' + Math.random().toString()
    console.log('3. 💰 Create a payment request link \n')
    // const { recipient, amount, splToken, reference, label, message, memo } = parseURL(url)
    const payer = publicKey

    try {
      const tx = await createTransfer(connection, payer, { recipient, amount, reference, memo, splToken })
      const signature = await sendTransaction(tx, connection)
      setOpenError(false)
      setOpen
      setAmount(0)
      setOpenSending(true)
      const signatureStatus = await connection.confirmTransaction(signature, 'processed')
      console.log('status of txn', signatureStatus)
      addTicketSales(am)
      setOpenSending(false)
      setOpenSuccess(true)

      if (ticketsPurchased) {
        let agg = parseInt(ticketsPurchased) + parseInt(amount / product.price)
        console.log(agg)
        localStorage.setItem(product.title, agg)
      } else {
        localStorage.setItem(product.title, amount / product.price)
      }
    } catch (e) {
      console.log('error', e)
      setOpenError(true)
      setOpenSending(false)
      setOpenSuccess(false)
      //   setErrorTxt(e)
    }
  }

  const changeTicketAmount = amount => {
    if (amount > 100) {
      setAmount(100)
    } else if (amount < 0) {
      setAmount(0)
    } else {
      setAmount(amount)
    }
  }

  useEffect(async () => {
    const db = getFirestore()
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    let prodUrl = urlParams.get('product')
    const collectionRef = collection(db, Collections.PRODUCTS)
    const q = await query(collectionRef, where('id', '==', prodUrl))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach(doc => {
      setProductDBID(doc.id)
      setProduct(doc.data())
    })
    return () => {}
  }, [])
  return (
    <div>
      <Toolbar
        style={{ borderBottom: '1px solid #424549', padding: 0, display: 'flex', justifyContent: 'space-between' }}
      >
        <p style={{ fontWeight: 'bold', margin: 10 }}> Raffle: {product ? product.title : ''} </p>
        <Grid display={{}} style={{ margin: 10, minWidth: 200 }}>
          <WalletModalProvider>
            <WalletMultiButton />
          </WalletModalProvider>
        </Grid>
      </Toolbar>
      <Box display="flex" justifyContent="center" alignItems="center" style={{}}>
        <Grid
          maxWidth={500}
          display="flex"
          container
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          style={{
            paddingTop: 20,
            padding: 10,
            overflow: 'hidden',
            width: '100%',
            //   height: 550,
          }}
        >
          {product ? (
            <Grid
              maxWidth="sm"
              display="flex"
              container
              flexDirection="column"
              style={{
                margin: 5,
                marginTop: 10,
                boxShadow: '0px 0px 0px 0px rgb(0, 0, 0, 0.2)',
                padding: 0,
                border: '0.8px solid #424549',
                borderRadius: 10,
                overflow: 'hidden',
                width: '100%',
                //   height: 550,
              }}
            >
              {!product.isLive && (
                <Chip
                  style={{
                    maxWidth: '25%',
                    marginBottom: -55,
                    marginRight: 20,
                    marginLeft: 20,
                    marginTop: 20,
                    zIndex: 10,
                    color: '#7fff9b',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    backgroundColor: '#202a30',
                    border: '1.2px solid #7fff9b',
                  }}
                  label="Raffle Ended"
                >
                  {' '}
                </Chip>
              )}

              <img src={product.image} style={{ objectFit: 'cover', margin: 0, height: 300, width: '100%' }} />
              <Box flexDirection="column" style={{ padding: 20, marginBottom: -30 }} display="flex">
                <p style={{ fontSize: 30, fontWeight: 'bold', margin: 0 }}> {product.title} </p>
                <p style={{ fontSize: 14, fontWeight: '300', marginTop: 0 }}> {product.description} </p>
              </Box>

              {product.isLive ? (
                <>
                  <Grid style={{ paddingRight: 10, paddingLeft: 10, margin: 0 }} display="flex" container spacing={1.5}>
                    <Grid item xs={6} sm={4}>
                      <Label title={'Status'} metric={'Live'} />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Label title={'Price'} metric={`${product.price} ${!product.isSOL ? '$MBC' : 'SOL'}`} />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Countdown
                        date={getGoLive(product.endDate)}
                        renderer={props => (
                          <Label
                            title={'Raffle Ends'}
                            metric={`${props.days}d ${props.hours}h ${props.minutes}m ${props.seconds}s`}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid display="flex" container style={{ padding: 20 }} spacing={3} justifyContent="space-between">
                    <Grid item xs={12} sm={6} display="flex" flexDirection="column">
                      <TextField
                        value={amount}
                        onChange={e => changeTicketAmount(e.target.value)}
                        id="outlined-number"
                        label="Raffle Tickets"
                        type="number"
                        InputLabelProps={{ inputProps: { min: 0, max: 10 }, shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} display="flex" flexDirection="column">
                      <Button
                        onClick={() => sendPayment(amount * product.price, product.isSOL)}
                        variant="contained"
                        style={{ backgroundColor: '#14f095', height: 55 }}
                        startIcon={<BiCoin style={{ color: 'black' }} />}
                      >
                        <p style={{ fontWeight: 'bold', textTransform: 'none' }}> Buy {amount} Tickets </p>
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid
                  style={{ borderTop: '1px solid #424549', padding: 20, marginTop: 15 }}
                  display="flex"
                  container
                  spacing={1}
                >
                  <Stack
                    style={{ width: '100%' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    direction="row"
                  >
                    <Stack display="flex" alignItems="center" direction="row">
                      <BiAward style={{ color: '#7fff9b' }} size={20} />
                      <Typography style={{ fontWeight: 'bold', color: '#7fff9b', fontSize: 20, marginLeft: 6 }}>
                        {' '}
                        Winner(s)
                      </Typography>
                    </Stack>{' '}
                    <Chip label={`Ended: ${product.ended}`}> </Chip>
                  </Stack>

                  <Grid style={{ margin: -2 }} display="flex" container spacing={1}>
                    <Grid style={{ marginLeft: -2 }} item xs={12} sm={12}>
                      <Label title={'Wallet'} size={'small'} metric={product.winner} />
                    </Grid>
                    <Grid style={{ marginTop: -5 }} item xs={6} sm={6}>
                      <Label title={'Entries'} metric={product.winner_entries} />
                    </Grid>
                    <Grid style={{ marginTop: -5 }} item xs={6} sm={6}>
                      <Label title={'Claimed'} metric={'false'} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : (
            <div></div>
          )}
          <Box
            style={{
              borderRadius: 8,
              width: '100%',
              ontSize: 15,
              marginTop: 10,
              padding: 10,
              backgroundColor: '#202a30',
            }}
          >
            {/* <Stack direction="row" display="flex" alignItems="center">
              {' '}
              <BiCart style={{ marginRight: 10 }} />{' '}
              <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>My Purchase </Typography>
            </Stack>
            <Box
              style={{ height: '70%', marginTop: 10, borderTop: '0.75px solid #424549' }}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                style={{ paddingRight: 0, paddingLeft: 0, marginLeft: -5, margin: 0 }}
                display="flex"
                container
                spacing={1.5}
              >
                <Grid item xs={6} sm={4}>
                  <Label title={'Tickets Bought'} metric={product ? ticketsBought / product.price : '0'} />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Label title={'MBC Spent'} metric={ticketsBought} />
                </Grid>
              </Grid>
            </Box> */}
            <Stack style={{ marginTop: 0 }} direction="row" display="flex" alignItems="center">
              {' '}
              <BiChart style={{ marginRight: 10 }} />{' '}
              <Typography style={{ fontWeight: 'bold', fontSize: 15 }}> Stats </Typography>
            </Stack>
            <Box
              style={{ height: '70%', marginTop: 10, paddingBottom: 10, borderTop: '0.75px solid #424549' }}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                style={{ paddingRight: 0, paddingLeft: 0, marginLeft: -5, margin: 0 }}
                display="flex"
                container
                spacing={1.5}
              >
                <Grid item xs={6} sm={6}>
                  <Label title={'Total Tickets Sold'} metric={product ? product.sales : '0'} />
                </Grid>
                <Grid item xs={6} sm={6}>
                  {product && !product.isSOL && (
                    <Label title={'Total $MBC Burned'} metric={product ? product.sales * product.price : '0'} />
                  )}
                  {product && ticketsPurchased && product.isSOL && (
                    <Label title={'My Tickets Purchased'} metric={ticketsPurchased.toString()} />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={openSending}
          onClick={handleClose}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{
              maxWidth: 300,
              width: '60vw',
              padding: 15,
              backgroundColor: '#35363a',
              borderRadius: 10,
              border: '1.2px solid #424549',
            }}
          >
            <CircularProgress style={{ color: '#5e5df0' }} color="primary" />
            <Typography style={{ marginLeft: 20, fontSize: 16, fontWeight: 600 }}> Purchasing Ticket </Typography>{' '}
          </Box>
        </Backdrop>

        <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            severity="error"
            onClose={handleClose}
            style={{ backgroundColor: '#f399af' }}
            sx={{ width: '100%', backgroundColor: '#ffc48c' }}
          >
            Transaction Not Confirmed: Make sure your wallet is connected and you have enough $MBC and SOL.
          </Alert>
        </Snackbar>
        <Snackbar open={openSending} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            style={{ backgroundColor: '#22222' }}
            severity="info"
            onClose={handleClose}
            sx={{ width: '100%' }}
          >
            Sending Transaction ...
          </Alert>
        </Snackbar>

        <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            style={{ backgroundColor: '#14f095' }}
            severity="success"
            onClose={handleClose}
            sx={{ width: '100%' }}
          >
            Transaction Sent! {}
          </Alert>
        </Snackbar>

        <Snackbar open={openSending} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            variant="filled"
            style={{ backgroundColor: '#7970bf' }}
            severity="info"
            onClose={handleClose}
            sx={{ width: '100%' }}
          >
            Sending Transaction ...
          </Alert>
        </Snackbar>
      </Box>
    </div>
  )
}

// {product && product.isCounting ? (
//     <Countdown
//       date={getGoLive(props.product.goLive)}
//       renderer={props => (
//         <p style={{ color: '#9a94ff', fontSize: 20, fontWeight: 'bold', margin: 14 }}>
//           {props.days} days, {props.hours} hours, {props.minutes} minutes, {props.seconds} seconds.{' '}
//         </p>
//       )}
//     />
//   ) : (
//     <p> </p>
//   )}
