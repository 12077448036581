import { NavLink } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import BoltSharpIcon from '@mui/icons-material/BoltSharp'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'
import { BiChart, BiHome, BiCart, BiBell, BiLockAlt, BiArrowFromLeft, BiDetail, BiDollar } from 'react-icons/bi'
import { BiCoinStack, BiUserCircle } from 'react-icons/bi'
import { BsWallet2, BsCursor } from 'react-icons/bs'
import { Badge } from '@mui/material'
import ReactTooltip from 'react-tooltip'

const navigation = [
  {
    label: 'home',
    icon: <BiHome size={20} />,
    link: '/',
  },
  {
    label: 'store',
    icon: <BiCart size={20} />,
    link: '/store',
  },
  {
    label: 'dao',
    icon: <BiDollar size={20} />,
    link: '/dao',
  },

  {
    label: 'swap',
    icon: <BiArrowFromLeft size={20} />,
    link: '/',
  },
  // {
  //   label: '$MBC',
  //   icon: <BiCoinStack />,
  //   link: '/mbc',
  // },

  {
    label: 'staking 2.0',
    icon: <BiLockAlt size={20} />,
    link: '/',
  },
]

export default function NavMenuDesktop() {
  return (
    <Box direction="column" style={{ marginTop: 0, marginLeft: -10, maxWidth: 200 }} spacing={0}>
      {navigation.map(item => (
        <NavLink key={item.label} to={item.link} style={{ textDecoration: 'none' }}>
          {({ isActive }) => (
            <Badge
              badgeContent={1}
              style={{ marginLeft: 1 }}
              overlap="circular"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              invisible={item.link !== 's/alpha'}
              color="secondary"
            >
              <Button
                disabled={item.label === 'staking 2.0' || item.label === 'swap'}
                size="small"
                // variant={'outlined'}
                startIcon={item.icon}
                // disabled={item.link != '/'}
                // style={{ color: 'white', : 'white' }}
                sx={{
                  color: isActive ? '#7eff9a' : 'white',
                  px: 2,
                  margin: 0.5,
                  fontSize: 18,
                  marginRight: 0,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  boxSizing: 'border-box',
                }}
              >
                {item.label}
              </Button>
            </Badge>
          )}
        </NavLink>
      ))}
    </Box>
  )
}
