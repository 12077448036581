import React from 'react'
import { Box } from '@mui/system'
import { Typography, Stack, Button } from '@mui/material'
import { BiCoin, BiRightArrow, BiWalletAlt, BiArrowToRight, BiImageAlt } from 'react-icons/bi'
import { BsWallet2 } from 'react-icons/bs'
import CircularProgress from '@mui/material/CircularProgress'
import CoinCard from '../coinCard/CoinCard'
import NFTGrid from '../NFTgrid/NFTGrid'
import { numberWithCommas } from '../../utils/utils'

export default function NFTPreview(props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        width: '100%',
        height: '100%',
        borderRadius: 10,
        border: '0.5px solid #424549',
        margin: 4,
        marginTop: 12,
        marginBottom: 50,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ height: '100%', padding: 6, borderBottom: '0.5px solid #424549', padding: 16, width: '100%' }}
      >
        {' '}
        <Stack style={{ margin: 0 }} display="flex" alignItems="center" direction="row">
          <BiImageAlt style={{ marginRight: 4, marginLeft: 10 }} />
          <Typography style={{ marginLeft: 4, fontWeight: 'bold', fontSize: 17 }}> NFTs </Typography>
        </Stack>
        {props.user.summary && (
          <Stack>
            <Typography style={{ fontSize: 17, fontWeight: 'bold', textAlign: 'right' }}>
              {' '}
              {props.user.summary.totalNFTCount}{' '}
            </Typography>
            <Typography style={{ fontSize: 12, color: '#e3e6fe', textAlign: 'right' }}>Count </Typography>
          </Stack>
        )}
      </Box>
      <Box style={{ padding: 10, minHeight: 260 }}>
        {props.nfts ? (
          <NFTGrid money={props.money} solPrice={props.solPrice} limit={3} nfts={props.nfts} />
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" style={{ padding: 10 }}>
            <CircularProgress style={{ marginTop: 50 }} color="primary" />
          </Box>
        )}
      </Box>

      <Button
        onClick={() => props.goToNFTTab()}
        style={{ height: 35, margin: 5, textTransform: 'none', color: 'white', fontSize: 17, fontWeight: 'bold' }}
        endIcon={<BiArrowToRight />}
      >
        {' '}
        <Typography style={{ fontSize: 15, fontWeight: 'bold' }}> See all</Typography>
      </Button>
    </Box>
  )
}
