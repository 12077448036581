import React from 'react'
import { Paper } from '@material-ui/core'
import Box from '@mui/material/Box'
import { theme } from '../../theme'
import { Avatar } from '@mui/material'
import { alpha } from '@mui/system'

import sol_logo from '../../assets/images/logos/solanaLogoMark.png'

export default function CoinCardPrice(props) {
  const [isHovering, setIsHovering] = React.useState(false)

  let isGain = null

  // let dollar_balance = props.coin.coin.price * props.coin.ui_amount
  if (props.coin.percent_change_day) {
    isGain = props.coin.percent_change_day >= 0
  }
  // let balanceChange = (dollar_balance * props.coin.coin.percent_change_day) / 100

  const { palette } = theme

  let color = isGain ? '#14f095' : palette.error.main
  let bg = alpha(color, 0.3)
  function numberWithCommas(x) {
    if (x > 0) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else {
      return x
    }
  }
  if (isGain === null) {
    color = 'white'
  }

  return (
    <Paper
      border={0}
      elevation={0}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        padding: 5,
        margin: 5,
        // minWidth: '100%',
        borderRadius: 10,
        background: isHovering ? '#1D262C' : 'none',
        color: 'white',
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        style={{ marginTop: -5, margin: 0 }}
      >
        {props.coin.logo ? (
          <Avatar src={props.coin.logo} sx={{ width: 32, height: 32 }} size="lg" />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              overflow: 'hidden',
              borderRadius: 100,
              height: 38,
              width: 45,
              padding: 0,
              border: '1px solid #424549',
            }}
          >
            <img style={{ height: 15, width: 15, margin: 1 }} src={sol_logo} />
          </Box>
        )}
        <Box
          display="flex"
          style={{ width: '100%', overflow: 'hidden' }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box style={{ marginLeft: 10 }} display="flex" al flexDirection="column">
            <p style={{ margin: 0, fontSize: 14, fontWeight: 'bold' }}> {props.coin.name} </p>
            {/* <p style={{ margin: 0, marginTop: 0, fontSize: 11, color: '#BEBEBE', fontWeight: '400' }}> SPL </p> */}
          </Box>

          <Box style={{ marginRight: 10, textAlign: 'right' }} display="flex" al flexDirection="column">
            <p style={{ margin: 0, fontSize: 14, fontWeight: 'bold' }}>
              {' '}
              {props.coin.price > 1
                ? '$' + numberWithCommas(props.coin.price.toFixed(2))
                : '$' + props.coin.price.toFixed(4)}
            </p>

            <p
              style={{
                color: 'white',
                // padding: '2px 4px 2px 0px',
                borderRadius: 5,
                // backgroundColor: bg,
                color: color,
                fontSize: 11,
                fontWeight: 'regular',
                margin: 0,
                fontWeight: '400',
              }}
            >
              {isGain ? '+' : ''}
              {props.coin.percent_change_day ? props.coin.percent_change_day.toFixed(2) + '%' : ''}
            </p>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}
// {props.coin.coin.price && (
//   <Box style={{ marginLeft: 10, textAlign: 'end' }} display="flex" flexDirection="column">
//     <p style={{ margin: 0, fontSize: 16, fontWeight: 'bold' }}>
//       {/* ${(props.coin.ui_amount * props.coin.coin.price).toFixed(2)} */}
//     </p>
//     <Box display="flex" alignItems="center">
//       <p
//         style={{
//           color: 'white',
//           padding: '3px 10px 3px 10px',
//           borderRadius: 5,
//           color: color,
//           fontSize: 14,
//           fontWeight: 'regular',
//           margin: 0,
//           marginLeft: -4,
//         }}
//       >
//         {' '}
//         {/* {isGain ? '+' : '-'}${isGain ? balanceChange.toFixed(2) : balanceChange.toFixed(2).substring(1)}{' '} */}
//       </p>

//       <p
//         style={{
//           color: 'white',
//           margin: 5,
//           padding: '2px 10px 2px 10px',
//           borderRadius: 5,
//           backgroundColor: bg,
//           color: color,
//           fontSize: 14,
//           fontWeight: 'regular',
//           margin: 0,
//         }}
//       >
//         {' '}
//         {/* {props.coin.coin.percent_change_day.toFixed(2)} */}
//         {'% '}
//       </p>
//     </Box>
//   </Box>
// )}
