export const farm = [
  {
    "publicKey": "BxQpVSm5dLgY4pRfiafMs5NqJdi9JqRuiE3Ya4E8Vdkp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9H88ZD38TmVa5U5kZjiQDuxzi5pNTCHdHkiREhUJn2fn",
      "vault": "DJ4MxbV145oGn1oYDFKPzW2d7oBrEpJjSD6qzzKgxLeF",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AG7WEg4DY6A8eqio3BWRRGtvND5m9JpaMLjooRR8TY41",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9dB44fenscMTdaovv9hEmu9inxxk9FQD9q9rzW6tt2Wc",
      "vault": "D3NwWYnt3wLtrr7zQxd3uEsRMofacmDBXddcCEeExm49",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "10bef237",
        "accruedReward": "10bef237",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DyyNFiMcdyADsq16PkJotCpWtzyTockmPhMQrnaGt7oY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HtSAKXhTimMHjHH3DxMYdXJpJxwVW7RCpJqTHSKtREVc",
      "vault": "EaYswsgrREEHj3SkgHEkEeCuxSX3Gj5TejbBr9PC8xcP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625f9ecf",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3033fcd0",
        "accruedReward": "3033fcd0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f9ecf",
          "beginScheduleTs": "625f9ecf",
          "lastUpdatedTs": "6266d7f9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f1b3a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f9ecf",
          "beginScheduleTs": "6266d7f9",
          "lastUpdatedTs": "6266d7f9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AwyeR5LSywh1BB7h6s9ZhqghokpaZxhU1f7JvHPshgD9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7fwePhukYWnX1g2ff3n3hv6vubsQMhVv8t9RBcp7gHYP",
      "vault": "HMF1RmjxwpgZNgWwLpA2mEc3Jn5ycdjFtnGTzB9fAiNu",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247881c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247881c",
          "beginScheduleTs": "6247881c",
          "lastUpdatedTs": "6247881c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2731ed",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247881c",
          "beginScheduleTs": "6247881c",
          "lastUpdatedTs": "6247881c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DWAqEPKYmpbwoeVdiEkk2re13deXgoDA5B44B4ru6UGj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "87Bhdke8qGbLJnkgDft3GTLJTUhJ4umHYgXH5uunk9UF",
      "vault": "631s97kUWwBEVxPAsyMFUof4bDJiXbCiSt3RUMoccjUx",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2CmSn6YHpjVCTeoLiGzACdyWapzrAkimpz6aN28zKD8i",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Hk4mmNuZnzEQe3bkv7hypYE1sZyLDAvyNx2sXvQKApWH",
      "vault": "FiXEM1DR7NMBxwvkY5Jr92jbBwJa8QUezEN6tA6xc89X",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625e13de",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0b39df6f",
        "accruedReward": "0b39df6f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e13de",
          "beginScheduleTs": "625e13de",
          "lastUpdatedTs": "626a7e24",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "10a62b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e13de",
          "beginScheduleTs": "626a7e24",
          "lastUpdatedTs": "626a7e24",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3vq4sYV6URKFwjbTWaEh6GeWpy8EfbeNhb5iV2ijkGoV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GiTQjMKhzFFQ7cVsEkAQ9cJjsiU5QCnsypsWrbmT8Sdw",
      "vault": "9bninf1HAQgAq1sgeoBUzc7CSsi5efBsnKtWFtQvubyW",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CaWYgxLLKh9QS22YVrkfhTWJD6pSM5cH4qXh78SeXhYQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DXf1Q3XDxZH7RLor1T16GT6yEpTp9fvnDTpdTW7aryAZ",
      "vault": "Hf996xQjmqVQDZZwiQApS3MujbEKefDzZXqj2T24qsep",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EY1aJNjG7XgbFKNrjRzng5Upo66XqMPuCas6xrag8BhF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "835zkRne1XkJDrudGpDDg7ZqqspVdy7dTjdL79QQTYQH",
      "vault": "E5rFnsSQbgmg1B9FByAT2ySotQZLKMLWgViA8tLbFppw",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625f703b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0aea1b55",
        "accruedReward": "0aea27fd",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f703b",
          "beginScheduleTs": "625f703b",
          "lastUpdatedTs": "625f7049",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f49ce",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f703b",
          "beginScheduleTs": "625f7049",
          "lastUpdatedTs": "625f7049",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DRA646fFdMuqwGNnJQVgMt6Q9YvcC1ffrAPWT52fywLm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AXA8gaik3ckUTjK1hLDBC7quVGgvgjKPZFai3d8zceTA",
      "vault": "5Naj6w9sokWzVfquxrpFswDiWnRHNAKinwgM3wUBZg1T",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "625d041e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "42b49496",
        "accruedReward": "42b49496",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d041e",
          "beginScheduleTs": "625d041e",
          "lastUpdatedTs": "6268db27",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11b5eb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d041e",
          "beginScheduleTs": "6268db27",
          "lastUpdatedTs": "6268db27",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BhWcubdMn7KHcJStdXbLk5m8R8j98dgYxM5qwwvuqEfD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8YkgkpbbX7RMtem5dVPqJUo9riyCfqdLNLAnQfBU471B",
      "vault": "69BqVTPoz9rvox91YJ53AfMLVdKoTDsgxMYhStSLq9aR",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "02e406cb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DHrKsfD24iwF2BzyR2z3EabxSdjRSMzWJCXE3B8uwuGP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2oP7N2w7QRNxgtrfZakonTFJ9mHNDsvSxEzP7ykUuw2C",
      "vault": "9AKLKKgbzdTNh3y1vA1xxQh33QLNSv2MvFMDuHBRo4Vb",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6agcipNU7Q2EQum9MUzkapSWHfQNbHAsPyr8Jb668yhh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "94wF29KXLAScrxxT2eA6AD6Gf8gjVNzkc9pScoaM2D23",
      "vault": "HaSv3a2qce7AwWyRv9Q5yZ8wgH6ZMGzbDDC1Rx3uYKHn",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625f54d3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f54d3",
          "beginScheduleTs": "625f54d3",
          "lastUpdatedTs": "625f54d3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f6536",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f54d3",
          "beginScheduleTs": "625f54d3",
          "lastUpdatedTs": "625f54d3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8fHxwzbjbTCqvyRnFiWKEtk1ZArJucXJJDoSAWjZAapr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7eQgUYeM2F9atNu6nBtM66NdYkiSri5p4q6TdADvgUA1",
      "vault": "5BEZKLJ6WyqRaiu9gEWjqRZF73BWvSVBv8jy9beTAGQM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6257976d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3c863488",
        "accruedReward": "3c863488",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257976d",
          "beginScheduleTs": "6257976d",
          "lastUpdatedTs": "6269c82b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17229c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257976d",
          "beginScheduleTs": "6269c82b",
          "lastUpdatedTs": "6269c82b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2ZM6Q9D4KBfvgED2zHv9GLVbVoxZ7eQwDMNNnSpf6BUA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "99J7MhzeQnQhsQhceTzSSZEomexRwf9peGUzZAqiD8vK",
      "vault": "J7BhHqWE1MZ31V3mPN2dHZ29PJ1xJ54Jwi7Pf1GpEWUv",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62598b64",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "03664275",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62598b64",
          "beginScheduleTs": "62598b64",
          "lastUpdatedTs": "62598b64",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "152ea5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62598b64",
          "beginScheduleTs": "62598b64",
          "lastUpdatedTs": "62598b64",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CVBbF8bTb41KC2iUWHMGg2Gmz9sRe5nEKHQYHThE7jQh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AYixiXSHmv315Mh7JzkziDQkgUTDxNQihhoYuSoRYDLS",
      "vault": "Geuv5wEg1wYtvqkjynY2zahCkzqfvJaaQZYiPDKxfSzH",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3NeeR3XNdwGdz1vcXjtrFGh8HdE7jEFucUwLAXB1mizj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3TQr8Wbyy9qdpgNwxsar7EpoxGWQGNkGiRiJ9vxpi3w2",
      "vault": "2c5rixRa9MJnDVWpYNnFvuKfuDYPwNZK9Yn1jFsxkeGM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62552999",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "32a2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62552999",
          "beginScheduleTs": "62552999",
          "lastUpdatedTs": "625529d1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "199070",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62552999",
          "beginScheduleTs": "625529d1",
          "lastUpdatedTs": "625529d1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9uiPbbZ5oNp4V3okxjtnxemRk3uFF6WnTNhf4vBp9a3S",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5W5EqzHbrPSRE2vgqixR5BW191z7nhrmvRSUrDJnUFey",
      "vault": "7VQokvurBu5qveovFcpdDvbdC5Pc1xD1FpE98aA7AV1g",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "625d8752",
      "cooldownEndsTs": "626ae93a",
      "rewardA": {
        "paidOutReward": "1fd449dc",
        "accruedReward": "200f79f3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HkAbR3fgxtVyVzwcB47daZ5R7eXuuSqRhRgBokyFfDVL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EULT4tigHgHAJNgwwZJr635K6pb9fcMtB24PsFsJxVg2",
      "vault": "CwqqCXwuLjjZ9AiBp4S3Dh5cWaHEN5pJHHiQ4cQYF4rx",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FFKqJmiJVGgMVj7uH4Bq3eB2bPr92VkcMMZgypiHJ85t",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GPQXDtWRsEJKKG6EHuZVjbinSoXuoqifrV9fMN2FVUYn",
      "vault": "AfuhXtomxT6H5AnVuRo9skWm7mcAgXLkLa5C7ZXfw6Xb",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J5ST4JwPnmsnuq8fGmbHVxaejtKe2Gcunkb4wh17jTDB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E66MtVVHphSDU8SgXc2Da8DGK7PSYsAvkZoMK7JuCP2Y",
      "vault": "EEz6pd5mfnu1k9CZfKwjmtokBWkp3RZnznJXqLAYXbRN",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "07319bd1",
        "accruedReward": "0731d3e0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AMXdGK8dPBndTi2xKUkG3ffJ4NyxQkBLSngpvupbyjuF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Evr4LTgcu7PK7cb9uGJkypmRsE6v9W2HypqZi1S3Pfjj",
      "vault": "BuMbV4ntBUUfJ2FDbjf2FnvNuEtiHcjfyHZ4rparNR4S",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "626a3312",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3013ed2a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a3312",
          "beginScheduleTs": "626a3312",
          "lastUpdatedTs": "626a3312",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0486f7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a3312",
          "beginScheduleTs": "626a3312",
          "lastUpdatedTs": "626a3312",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "F9kpSGkQA6k8fwm62BohzTCDTjcAkVfdomsPpfUAFhB4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5NFAUSE9KGV6eZddkTkjr7Ex1eCZ7QgjkQ3uQHN9hvUY",
      "vault": "7gzeZSuz3XzH3TCpkdY8rxdkiqp7YH4nXL8TS5hmxSjW",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GMMKWtAf6WDfzVGdw6qrkwTVzE8ZXGMb7fHPbrgFA1GV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2SeqHBpk7vFCmNYbnptgmx1qg7Snj1XiYWtN1bnqso3a",
      "vault": "Ai4R52EzFH12sbJoyMUzWcGRSHh2Xs5wnSZ5ZD2rgDis",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7ePiqdeWmjZ3rmZzkxEjMyLNKzXY6m9CUY41mx3skzZL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Fz9CbCaksGpgmwxFPJFHH5SYRfmiyFmv6y29tAaN6roH",
      "vault": "2mDzTHpV5jF71iXC2w3UePduanepqyHrDEqUr87AB3He",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6262f83e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "62ac7584",
        "accruedReward": "62ae035f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262f83e",
          "beginScheduleTs": "6262f83e",
          "lastUpdatedTs": "6262f83e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0bc1cb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262f83e",
          "beginScheduleTs": "6262f83e",
          "lastUpdatedTs": "6262f83e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "96kHUk6Hp1FzP9tYTBGLZzVXK7Ph1iZqBE2wtFUaTN1q",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4cE5SaGw9cwAc1qseTfZNNe7vhcFwpZK9ZH635wdaRR5",
      "vault": "ECySiw9LUuyQswTwN37qkdNwFM9UsTkxg1X8WEv8eyQ7",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5rHPCDUyjz8uA9Q8vxSsr3Gjta87H7d3bMm6fTFnxcYJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HNbcMB8wmCZs6wgSxXVu2ZNLbVxJztmaSP3DPd7vfKTb",
      "vault": "2QeVZmwF1suqAK4HwTZnbqupas1BtgS5a77tuKYMfVyj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "07",
      "rarityPointsStaked": "07",
      "minStakingEndsTs": "62552cc0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "24f5000e",
        "accruedReward": "24f5000e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62552cc0",
          "beginScheduleTs": "62552cc0",
          "lastUpdatedTs": "62552cc0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "198d49",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62552cc0",
          "beginScheduleTs": "62552cc0",
          "lastUpdatedTs": "62552cc0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DCYBHxHTWSUCrZB56WBFu9vZmijJsrk8H4tposnaJAsZ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5yzhfbBacRcnmQhGWe21o3ptLxaKgM6XMpeuc1UZV5pp",
      "vault": "DT1UepdewLhHPxCB8Y5mNp9FipbsVk8iKNbtA5i8EHpk",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62575557",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "109ccc27",
        "accruedReward": "109ccc27",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62575557",
          "beginScheduleTs": "62575557",
          "lastUpdatedTs": "626971ad",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1764b2",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62575557",
          "beginScheduleTs": "626971ad",
          "lastUpdatedTs": "626971ad",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "G3SDFYNnVt8HzfQk8eRwh2iHHSNGwQHYwACSBH8ZjGbG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2AXswXDNGRtMHv4u4KiXusmvNNMFDBtkcXLKBP9f1fvs",
      "vault": "HsBusuSvjYwC9N2ZX6gMHafSeWQ94gjznbLupcBj5tv7",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62638065",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "23795979",
        "accruedReward": "237968d8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62638065",
          "beginScheduleTs": "62638065",
          "lastUpdatedTs": "6265dfa5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0b39a4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62638065",
          "beginScheduleTs": "6265dfa5",
          "lastUpdatedTs": "6265dfa5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HAHgSdMJFwZ4tCLGgw53gxiGJ1eB21tnsQuFrZFxPcRs",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8sC2xdXWDZq2bYV6TaeuSkjpG8GLoTrkxQwbjYjNHvmE",
      "vault": "GsGCSJUQ5PbDa2nKYpbA2GJ4tHdQnV7UXF87A6zPF1ui",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62680c02",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1eb461a9",
        "accruedReward": "1eb48d10",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62680c02",
          "beginScheduleTs": "62680c02",
          "lastUpdatedTs": "62680c02",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "06ae07",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62680c02",
          "beginScheduleTs": "62680c02",
          "lastUpdatedTs": "62680c02",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "12kwfmSeim7aEUMCSJU2YtjvLD6Ue11q4BjSCp5q4W7b",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "WGoW3SbZavW7YK8FAGBJP4jXsRTNnRpfKqkzmZ1kEqx",
      "vault": "8PrCyBqJywRGwdjETFwwSiAwS4oXYxtdtTwYBew2uoHG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625295ed",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "233ea0b9",
        "accruedReward": "233ea0b9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625295ed",
          "beginScheduleTs": "625295ed",
          "lastUpdatedTs": "626700c4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c241c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625295ed",
          "beginScheduleTs": "626700c4",
          "lastUpdatedTs": "626700c4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BXMBhjnUpyCrb6HjHttYCWDNQJWY1srpZEGnJiA8XACA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Hjb7kdondA89E4TpKLX8L9Q2gjXrKuz7Qwy7zp5WAVCy",
      "vault": "ZDr3CjzQtyzNcU4m2j2NcwgejJsGY6kFCE1bMHUGTK1",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "8f9203",
        "accruedReward": "8faa6d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "H9d24XcugoFFwayFqNUD2xHKDfoXqVJz6iTXDtD1xH8F",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6utXaBmMiR3WqXgkuaFU2EX4bHvUA2kC8cwdq5G1YeQG",
      "vault": "9vLEZQ22C9ZFZ8iEHeJrpViiYQW9Scgcgo1CGGiRTdLw",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624767cf",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0d418e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624767cf",
          "beginScheduleTs": "624767cf",
          "lastUpdatedTs": "62477678",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27523a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624767cf",
          "beginScheduleTs": "62477678",
          "lastUpdatedTs": "62477678",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4ytSb3VDLpBgeJuVHWVSXhZjrh7gP8npkoYmRG2CJch6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3p7nMpxbXjKDLmWAB617MdCxxdZF3P1Dd4Lm4zHtECBX",
      "vault": "DsQahxWp3XDcsYVWR7L4LGPA2eDPdLYDvVyG47cNBGq9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6251253f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "77335fbe",
        "accruedReward": "77335fbe",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251253f",
          "beginScheduleTs": "6251253f",
          "lastUpdatedTs": "6268d5c0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d94ca",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251253f",
          "beginScheduleTs": "6268d5c0",
          "lastUpdatedTs": "6268d5c0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BGSgqi79v3eB1kZHpWkoZt4PLJAg6yLg21LU1U6Da3Tw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Gyw7WTjcc6WSHPQbx9FeCNhofjqgjtzaBxnogDLYpief",
      "vault": "AotQwjt5h6nGjzpEw12GVLUEthbapEnQviUSteVnLwQm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62484ca3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0ff3c0e5",
        "accruedReward": "0ff3c0e5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62484ca3",
          "beginScheduleTs": "62484ca3",
          "lastUpdatedTs": "6259f0f1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "266d66",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62484ca3",
          "beginScheduleTs": "6259f0f1",
          "lastUpdatedTs": "6259f0f1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7jF1n3nWs837ybitce98eZQNWav3RYzQSjGjW68mF8qX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7ZWuY151MbT2jz6gyhfX5fmciXSbWtF3vujw17tWsf8M",
      "vault": "Ccm1YmXZvR8Gh6wZ4aiwtbcwtjcMGUadkg3ue6jxcJeZ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6261111e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261111e",
          "beginScheduleTs": "6261111e",
          "lastUpdatedTs": "6261111e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0da8eb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261111e",
          "beginScheduleTs": "6261111e",
          "lastUpdatedTs": "6261111e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E1sgWYVS4jZTFERmCUgcoLxgFTmEZPzzws4VoY4Xd1y5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4rNFx3tjrRbzTLTQCqnXVFWJMNtQoV6GMH33cYMri6DZ",
      "vault": "7sRb4pWYsjV7Jg9hLUmieUMGe7Zzj2yUn86Es4BJmQUK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6260f191",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1aad7aff",
        "accruedReward": "1aad7aff",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260f191",
          "beginScheduleTs": "6260f191",
          "lastUpdatedTs": "626ac806",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0dc878",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260f191",
          "beginScheduleTs": "626ac806",
          "lastUpdatedTs": "626ac806",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4WLVYVoEf9q3LV1VGPQtjVXi1GeU5vTnbZhZTh3za1P6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4LWfTQXpGjrfnU3KKHXgUERaFPnfNz3aH93pnMJP4yUA",
      "vault": "Bpn4FaRnFwii9FjgUfdz4Q3xocAaGc5HDQE1QQkSRqFn",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625f5d9e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f5d9e",
          "beginScheduleTs": "625f5d9e",
          "lastUpdatedTs": "625f5d9e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f5c6b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f5d9e",
          "beginScheduleTs": "625f5d9e",
          "lastUpdatedTs": "625f5d9e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Bp3BcnQq7PoCqnojhwK5RtLcMRfF7rhsauWks6Z8jzA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EELzjKCJAA6AzJ8eZ6hKjtkJa7t8Yps7gqQYcinFcHcv",
      "vault": "GMqPMsy7T1qQg4HbYRbJgFQfNZxGb55b2s73y9PbMYSh",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "f33378",
        "accruedReward": "f33378",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "A3158sRmm1V8Z5a7sw3Aks677n3ntzJFXsDqjzYHwrdj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CFcp9YKydHSgLd7vCS8aVF5iJLwzMeBnbP1HLCXEmhEm",
      "vault": "CzCgsfQ5xHvG6P5cgmVPcMhsNWmEZqKbtPaDnpqFKTy9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624df0dc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0d4ca45b",
        "accruedReward": "1491f876",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624df0dc",
          "beginScheduleTs": "624df0dc",
          "lastUpdatedTs": "6264b09a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "20c92d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624df0dc",
          "beginScheduleTs": "6264b09a",
          "lastUpdatedTs": "6264b09a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DDvLvY2MurjgoJesss6p3DSayTaScuSL5oytDJmLqkSv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7Q1jBR6wZ1mxTazLtd4xMx2rbrjNUqC1oCVdvHKAELKp",
      "vault": "3qgxgXfu8NYfngk7ogEYNYQQxF4Ch2K8KZuoWhBqEEc6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b739a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "192587fd",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b739a",
          "beginScheduleTs": "624b739a",
          "lastUpdatedTs": "626742ff",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "23466f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b739a",
          "beginScheduleTs": "626742ff",
          "lastUpdatedTs": "626742ff",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HpmwWfcZGgHmLs9VZtAkPpjssJTGvvBwEmX8qXXqaPG4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AAwUegHXfkZYZ1s5rZwLcHZymZLtBs2mm7DxLmVULeg5",
      "vault": "GWq4w145KuGFss2vLENzY2SbYs6xfBDNgUsMCcYkM4DJ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EYt11BAUedddjpHWkZuhnjVg4j4ALKeTgPbzHraey3e6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "43LMaWxLttj5YNLBL5dAh1D3UK6puiKgCxUA2ikJ3nUP",
      "vault": "7Gk8E32g5h88LgeBPgrrrLj7rqKz48NLhL7fio7vQcLi",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "01f92a2d",
        "accruedReward": "0c7155f2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Gzjy8YjVByyr7J5rYs5ZvGM74oUUKhuv6nnkfAcok26f",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EdzjpjazRh8cTxR4Kr3WWh1TUHE2WCncRafvf4wroTZ4",
      "vault": "3j6Bj8toxxKVjAwKMcwdV5BjxBH4r5DjR3X7fF78Hfkj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6247727c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1bae33eb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247727c",
          "beginScheduleTs": "6247727c",
          "lastUpdatedTs": "6251a6be",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27478d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247727c",
          "beginScheduleTs": "6251a6be",
          "lastUpdatedTs": "6251a6be",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2gxtFcXfEUoAcNyEYvCadAVzgrDXM69DVSdZvG4MLkqT",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2HoNqGbcFbkz67tm3wcrvUKGsYJ5qz8FgG99DzdTHrXw",
      "vault": "AAzcNos56rerxk71KA1sJrRtbCSt4E8W3wDPAnsoT2Wt",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "252f7157",
        "accruedReward": "252faed3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EFL1kUBw5VjKvUSTxEtuUJEW5MgGutGAT3L9o1YvM7jt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F1WLGGrH7fycVQf4U6wdeRUiGq8FjdCnDVsGw1ARMNVo",
      "vault": "DLDhuQffEuY7kxGmjVNzLdt8eLxDotwpLZQtqXwPA4wu",
      "state": {
        "staked": {}
      },
      "gemsStaked": "05",
      "rarityPointsStaked": "05",
      "minStakingEndsTs": "6264ab70",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "178b53e0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6264ab70",
          "beginScheduleTs": "6264ab70",
          "lastUpdatedTs": "6269bb58",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a0e99",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6264ab70",
          "beginScheduleTs": "6269bb58",
          "lastUpdatedTs": "6269bb58",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "B59sUKs13HZLGhXBjU6EYQSWBCXb9UakQeKJnJdbjei2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BxpAFKcVBDM1NG7JVZCfFmxJ8or6tvkFtsaq3BMHYnea",
      "vault": "FPQ7RtTSJ9MozT6uWTd3cLoNbgNWN7HnaFJ9h68KoX9C",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Ej92LLWJP4d94N8Ld75u7D4Wf1F4xM8K1Sk4acViFkmH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7LWP1oDEN1q1xUHHYnfcJ9Zeive9WzKXcscb4bowyiMK",
      "vault": "4E2zMqzkchJQ4ZevDcVqP2pQmugXnG2mBatJYUeozSuX",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "017bec33",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E7p8EA1qesD8N78Pmm8boDC3CG6G7f2WHmmzBFZR2pb7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2t8HDpnASv2NnFrA3TSj6uTKDrddXTLHfBDQBwr8v73m",
      "vault": "13k6V5N679dcg235wD8FYoREBdHMCf57Vimk6P2BFgu",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "625636b3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "727fe85a",
        "accruedReward": "727fe85a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625636b3",
          "beginScheduleTs": "625636b3",
          "lastUpdatedTs": "6267136c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "188356",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625636b3",
          "beginScheduleTs": "6267136c",
          "lastUpdatedTs": "6267136c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Hip5cHUEn4wBuPDPzWCqxGCexLELWLGq3vWs9BTjCGoi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2jLF8S6YwwGGjH3hmvXjpztNYPfFM5iihHJqgxrd3zEL",
      "vault": "4oJMt4zQ9RANYZ1knWxNXT2rzALGiYgmX9etPfNe3xmo",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6eDL4MH9Vi5HshAAZSx1rumotN7Vzj7YVYMvuBos85cU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "64GafrXJcZX1Bve2N7gnGaKHbreENBQUbTqwPW1zkKqV",
      "vault": "5bubgVnXDeYbqwaQnPrWebVprWpb5oeq86QstrxE1AXg",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625bdc63",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0738a8a6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625bdc63",
          "beginScheduleTs": "625bdc63",
          "lastUpdatedTs": "6263d8db",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "12dda6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625bdc63",
          "beginScheduleTs": "6263d8db",
          "lastUpdatedTs": "6263d8db",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6TxAwLUfLhViPATE8KWQPM1HowV2PttiTDv6QNKnQ6r8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A5795uy3QuKC2GH55d4qMqfrxYRFoxJUwXm3VA3jbmev",
      "vault": "6GxCsrAaqwr4buszAWFsWnmrCnQ1SzKhjWErEG151JQj",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2ab01e5e",
        "accruedReward": "2ab04458",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7sJ7ruSd35uaBNbek9cLKYZwtdVi8pVFW4TgDwCiKuEm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4bDwQq2KRJC97FJhxhxDqmgQAEUQZ6Riyjwo5tSX6g2F",
      "vault": "AczFcGRi7MWdFR9KYETdptsbSKzXjoQ2NqGZHj8Js8TB",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3bfeba1f",
        "accruedReward": "3bfeba1f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CJpbvb2PJ7hg4PTF4RJEjmCwxo6MB9jPSkyd8EE9jU7u",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4xRGFpvEfPF7NBZjZjAh8NMKtXkwNHBwvh3i2oWyNxdG",
      "vault": "HofahFqK8bDoPHmLtd2cEaRXDoSjF2hRh8WEhG8VuyV8",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FQpc3bGwNCtXiB4hXxEsSh5oScPgw8wNQzZzAnb3oBrf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "J5bnKTLBQdWNURFs9aZXmYngWsoQrYeCdtjDV7wqrSnY",
      "vault": "J453qJTfogRGN2KfRb8Ex6KVmnhmcmVcyAzPnxtYEHHu",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62517aab",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "121a9149",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62517aab",
          "beginScheduleTs": "62517aab",
          "lastUpdatedTs": "62517aab",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d3f5e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62517aab",
          "beginScheduleTs": "62517aab",
          "lastUpdatedTs": "62517aab",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EGYjNCnx274i78NsCSnZefDbU63ZA2g878U1993wTnjR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DLh3fruip85Y3sVLkztUT8yL2XtvLJn2AvUEKLW4gwDB",
      "vault": "4yPgeQ4MLrDMLRJkAwR4HZdEybdLZHGusbynrQxNM1BG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624c6f46",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "301e507d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c6f46",
          "beginScheduleTs": "624c6f46",
          "lastUpdatedTs": "62670acf",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "224ac3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c6f46",
          "beginScheduleTs": "62670acf",
          "lastUpdatedTs": "62670acf",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9GhLA8jZR7zGQQ2X6NipujHhqxQjuMjiXdkPtM7WZNhp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F7yaj9wxyL7V2vakZ35BZA451GyXjJwxgxPVFXmHXwHU",
      "vault": "4N2AWCfprh3jSLwtRcsp6g6fpGMJTQBCdwUSRuMAVhSV",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626abf22",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3fc7cb50",
        "accruedReward": "3fc7d629",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626abf22",
          "beginScheduleTs": "626abf22",
          "lastUpdatedTs": "626abf22",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "03fae7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626abf22",
          "beginScheduleTs": "626abf22",
          "lastUpdatedTs": "626abf22",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5zB68bkuYbtfjjEPZN3eSt5t7evDKg3Q5CjU6D85gjUu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CAczwtsm5donE8J8HCnohm9FfT2kpCURHvZz1Q8LMwdw",
      "vault": "ATMS4g9Cez86MCGuAgYQXhJjHzFKaipL4Hn1ntkTFSU2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6262ef41",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "26d29f1b",
        "accruedReward": "26d2bfa8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262ef41",
          "beginScheduleTs": "6262ef41",
          "lastUpdatedTs": "6262ef7f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0bcac8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262ef41",
          "beginScheduleTs": "6262ef7f",
          "lastUpdatedTs": "6262ef7f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CdfQxY38TwEoMsfxhA2WJ9uSteLNX6L1KtJzodhDLNhx",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FvPyYx8riGPQoDkuB93hPQz2eefWpVMzD5aR32ZP4a1m",
      "vault": "7c6VqACLBu6fATdNHN79GJRsK5RTUJpx7w99EE9HD4Ap",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "15963e0e",
        "accruedReward": "15965db3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Fw6hAbgwnCXVQQm2sKD6gkv1rhGEcoRCzig8LejyQ4fw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8Q2LyvqZEVAKQ3e62FetL2JcsBFB4hjSFMSWEQx71BMq",
      "vault": "Ck8aH84BjpPe5bRS1igd7fn1RePD4DshoDzRamdCSn12",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625c7e74",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "12ad4559",
        "accruedReward": "12ad4559",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c7e74",
          "beginScheduleTs": "625c7e74",
          "lastUpdatedTs": "6266d24e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "123b95",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c7e74",
          "beginScheduleTs": "6266d24e",
          "lastUpdatedTs": "6266d24e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Bo57UMzqne7o8qNQPxEpUc7HNtjjaczg9UmnDHoVyd9E",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7biLQ4yMPBz286xmMqrkaTq5VoBZ5qcRwt7TCKYgdufx",
      "vault": "GeB356ApyPPcWwTAX9UzjP5WPVbst6aHKVF6PvN41e5u",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6255dd1a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "26c868fc",
        "accruedReward": "26c868fc",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255dd1a",
          "beginScheduleTs": "6255dd1a",
          "lastUpdatedTs": "62670dc7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "18dcef",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255dd1a",
          "beginScheduleTs": "62670dc7",
          "lastUpdatedTs": "62670dc7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7dyoCyBZF6pe366Uu4YF9284GLc5GEKDnopYUENUNSJ3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9U37kXK2qG5BeZf6VJchCXuR1WNq7s6gygvu4qQ5bnYu",
      "vault": "5BTfwtc9MKhZUsuHvJ9aQXTfNL5WYH1Qd6Pzt1ZAirsV",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6258c7d1",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "49cd5737",
        "accruedReward": "49cd5737",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258c7d1",
          "beginScheduleTs": "6258c7d1",
          "lastUpdatedTs": "6269663c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "15f238",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258c7d1",
          "beginScheduleTs": "6269663c",
          "lastUpdatedTs": "6269663c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EPbSZjLVsBimzYGFMrzzh2BfWRqaW54SJKogNpp3bEB2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3G1BYt5kzgB6Cakv49b6yjZ9szGBigPEcyZceX39HRPc",
      "vault": "A9XW157Z7qoxkoJ1LDX8t6UoV5wY32G9VpY7P8UkRzav",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62598176",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1f0fb1aa",
        "accruedReward": "1f1048aa",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62598176",
          "beginScheduleTs": "62598176",
          "lastUpdatedTs": "625981cf",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "153893",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62598176",
          "beginScheduleTs": "625981cf",
          "lastUpdatedTs": "625981cf",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8JXJYr4ehSz4jyw6Zr2bakTBMt4AsAAdRfZRYPgBMr2Y",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3qWK9FYfziGtyLWCZCKHtcNvh5WmE72cAyvHP7rePuf4",
      "vault": "8eNCNQk7GQE4wwYXA7ggsmP23WUVph92sJzZZ3fHYrmp",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0d3f9827",
        "accruedReward": "0d3f9827",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3rbv7kPMTqUaw7atPoEe5DDvuYqwtNUBvJPUbnXiMmj8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5SPtyYetzwkSezd5PCLDcG9nvZRfbAiyREucBk3Tmd4G",
      "vault": "CvZmgq4VjtAw4ySMkqAGYThw6gZxUDAdKiafWAXirSZL",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625b310d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "071b26c3",
        "accruedReward": "071b26c3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b310d",
          "beginScheduleTs": "625b310d",
          "lastUpdatedTs": "62630ce3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1388fc",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b310d",
          "beginScheduleTs": "62630ce3",
          "lastUpdatedTs": "62630ce3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "G8eHB2FzgbiDPxkYLPRtg7ECMZZatJH15od7JTXRnZBD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CWjcyitAmBQxZrz8j9uSbjAwSWQQkGwKDzF6DNimBQYy",
      "vault": "GRkPgNfRDAcszy1ADpiNvpUbowJMWFJE4dj73ePtfky9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625e7bbb",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "16d75da0",
        "accruedReward": "16d75da0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e7bbb",
          "beginScheduleTs": "625e7bbb",
          "lastUpdatedTs": "6261081b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "103e4e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e7bbb",
          "beginScheduleTs": "6261081b",
          "lastUpdatedTs": "6261081b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9bZaBEv9tqv1jajhfayoL5n7PFzHYnHzntbJt9XBqfW4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "h7qsS8JP8RKEM2eFB2v9oeZ6PArdGjqLhkRwtruCyso",
      "vault": "GqBZ7Lcv62eEH8jA4n6f7xuqvQvCL86Bw1j3fuN5Zz9J",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247754a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247754a",
          "beginScheduleTs": "6247754a",
          "lastUpdatedTs": "6247754a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2744bf",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247754a",
          "beginScheduleTs": "6247754a",
          "lastUpdatedTs": "6247754a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FD1PXCxkybJTaGTLb5v1i3qpA2nMBj9Uo8aaSqfFKfYF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5NaNspKCJ85Y77bNhw9MKkrQmBinWw6rdph7aRhmRCUx",
      "vault": "BQ6dPwFVoFE2PKb2fxS458S3ozywxN7nXmbGXAVzvidV",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6aWv8k8BBGDERaoHBPA8JVfirNh8f5d6ALPKGgMd118V",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "arAC67fBrTJm6wThaDzeWAaraU8dvcNdtAWRrqunjUK",
      "vault": "HGzVBYK7J1JgF1QuQnTkb2Ehf8mZgUMiHHjE1BXCpsUa",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "626abf48",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "beb067c8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626abf48",
          "beginScheduleTs": "626abf48",
          "lastUpdatedTs": "626abf48",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "03fac1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626abf48",
          "beginScheduleTs": "626abf48",
          "lastUpdatedTs": "626abf48",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FJLa1a8WPAJeB3Goxt3Arx1jT1jMx9Rf6iHArAhPTcS6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3ad6rJzEzyNKFBHVx9wW859epNmY84BzW5HyqZymTasv",
      "vault": "8oGfrLdxLvPHGtVME3RVk53sHN5FQwwuXB69FbfKXnR4",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6261fa0a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "09d9a1a5",
        "accruedReward": "09d9dc6b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261fa0a",
          "beginScheduleTs": "6261fa0a",
          "lastUpdatedTs": "6264d896",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0cbfff",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261fa0a",
          "beginScheduleTs": "6264d896",
          "lastUpdatedTs": "6264d896",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CnBEUeEcTMaYL6dFBjkJ2urX3W5QADKNr5a8keFLMZJA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6BVu9EaNpYFyxhXutQHGMfMSbrGjb8CeCGdhn32FwYy2",
      "vault": "A8iwYE9MfYNF6cJvAygNFDKqM4vGZtMmgGYdW9eBgjJk",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2af5d7c2",
        "accruedReward": "2af649b0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EUrEKT4HZL7ky7M2SrcW898e2uEsQmn3pgwKnRMoQqJK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5ZYMQS851eMNH95PxBX5qaxYjdfMXSPdgjxcerytAnGA",
      "vault": "9oV2vJEBVx1vrC6N9vynvrYuDCh9z8b7RKPLRyWXmw84",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62477a6f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477a6f",
          "beginScheduleTs": "62477a6f",
          "lastUpdatedTs": "62477a6f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "273f9a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477a6f",
          "beginScheduleTs": "62477a6f",
          "lastUpdatedTs": "62477a6f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AvQBP7vWq7bdXAM78eiUy1XPHYpKxp3RSfrrpbPBTFzt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3ZxLkQggu4j3Z6B1z7oaWjSZnb7f4UPxUPL6Z28GfQPX",
      "vault": "6RYZWVxHiq7KxJzBXrMvpcgA4MR2RfVjxovhZ54aAZnz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624a4291",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "10ad9d31",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a4291",
          "beginScheduleTs": "624a4291",
          "lastUpdatedTs": "625cb46b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "247778",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a4291",
          "beginScheduleTs": "625cb46b",
          "lastUpdatedTs": "625cb46b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7kCZvvJj5eTYai87DcCQmbEaFZmgtjfskFMFfagdZ9Xr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8B31tsb6wDtfP2u86qBXqq5sHRb4KwnmF74jieYWQB8H",
      "vault": "F2AHVo2n9aHVTh7VSTJhkPP7eFMEXWDx6sHdVZBhiDnU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62476b8b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3c739ae8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476b8b",
          "beginScheduleTs": "62476b8b",
          "lastUpdatedTs": "6268d8ef",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274e7e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476b8b",
          "beginScheduleTs": "6268d8ef",
          "lastUpdatedTs": "6268d8ef",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2aC3peHbofjenBHTsYdjvd1GSd74VifCeuDxSiESQ8Lo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9CQv4UgYRHYgG6rSG3w2kCgb3Dv9EuxvvSH2HK4Xy3yd",
      "vault": "DKXiYvgbhW3iskhcDNKQN8VXeJWpHuw3dD9xrth3b4a8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62477930",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "18de2b09",
        "accruedReward": "18de2b09",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477930",
          "beginScheduleTs": "62477930",
          "lastUpdatedTs": "6262f9a9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2740d9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477930",
          "beginScheduleTs": "6262f9a9",
          "lastUpdatedTs": "6262f9a9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ChjgXJS7JyHXwfy8EHLq42cgYSkmjPW6mPPf7GDpmVbe",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7EyRNmrWjc5CPzCEmqcVqTAq9gJvYCJXFxv4BMci8LeW",
      "vault": "6d5Jv8rWwMfefbNpJiK2kfQZxsbQQvncnPdkhCDfnxY1",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0731bc5f",
        "accruedReward": "0731e259",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "c8mgWy1tkCFUMnoMY6efD91M145d65nNCPBh8qBpqS1",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5uaCqWqjQNenUvoWreAZnE753mA5v8BJj2CgwqXQYnE4",
      "vault": "BHA4apCXWTHydYRUEMJE5KsxcAH5KUocpAhE5mLu9hDb",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6263913b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "31f9681e",
        "accruedReward": "31f9850d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263913b",
          "beginScheduleTs": "6263913b",
          "lastUpdatedTs": "6263913b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0b28ce",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263913b",
          "beginScheduleTs": "6263913b",
          "lastUpdatedTs": "6263913b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "97HGkiqE7ecJWkvrkwR3iQW9hTUjNH49LHg71zdqUe4c",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9bAVp8a5EMzrQXxdtdzNYAooA3ctCza5s8SYuyJX3DPu",
      "vault": "GhEstJqFk9HaDW8cwoMPyNW6i19orbyXbvoXe1BGgUa9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625fef34",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "27cb14f6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625fef34",
          "beginScheduleTs": "625fef34",
          "lastUpdatedTs": "625fef34",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0ecad5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625fef34",
          "beginScheduleTs": "625fef34",
          "lastUpdatedTs": "625fef34",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "SbjTcLL2v7JMTNQwUtx3CUFrMqx5xFYY6fkp7iTQGXb",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ABMKTxssmPtebH6BX9oqPr9c5RBx3y1J89EcNGSMjAv3",
      "vault": "AtPNWywHrJxuH1nZxRdt7EAUAFrGae7b4Kjp5C8FQgq9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626797d9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2ae493eb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626797d9",
          "beginScheduleTs": "626797d9",
          "lastUpdatedTs": "626797d9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "072230",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626797d9",
          "beginScheduleTs": "626797d9",
          "lastUpdatedTs": "626797d9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CrD4jpNktFVroTD6eXnTrb17wyALAFSZu9ZMPsaqXsRn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AoMzwScLQJ842HVoxYWUaZ7uca5enwwB5Zrsjo6vCY5h",
      "vault": "7Wq69qaYfmZebpjM9oTbaXYhHtP4o7uyr3ebU5Dzah5Z",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "6247c5a2",
      "cooldownEndsTs": "6262ceb4",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1872339b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2KJFNe57WuodcFbW9atvG4BFFPgzcy75d9zQbpfboaBX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "81V8wpqTRxfC3Mv9RGy9gRTLxnvYqgsb2fvw5tUpi2cJ",
      "vault": "CK2kQEXahQQEdD1n2hZdBJwLLQJNLiPcULFxST3KBtTN",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "85d6d9f2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7nQPMAUD7aF26w78iSMyzS3Zcq2TMfPQKjofn4gLNiSb",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CMtfqufcnudgCpfZgWTx8R5BYSerpJeHLse5HeRXx2ew",
      "vault": "5dfX5EznWcewHRGaWKjvFcCf5dv7QXuhNJpp2F6XvX29",
      "state": {
        "staked": {}
      },
      "gemsStaked": "05",
      "rarityPointsStaked": "05",
      "minStakingEndsTs": "6261bf18",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "5f113eea",
        "accruedReward": "5f154ec5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261bf18",
          "beginScheduleTs": "6261bf18",
          "lastUpdatedTs": "6261c05a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0cfaf1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261bf18",
          "beginScheduleTs": "6261c05a",
          "lastUpdatedTs": "6261c05a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BPf2KDZzbmsbQ8CebeVsCuCAUa25P6kcCsBZNnrZuuVU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3PKUbWxbc3mutp3qE5FGeCrmzvnoeB7drp5qcZQfD2yh",
      "vault": "3jFtzQdwjEKyRUTeRdqdFaEDBhEuSUWGvW68Fg8bB9bn",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "13e758fe",
        "accruedReward": "13e7798b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5DJQNEfjp63PN6JArrPeBqYzeNcqfWka1LHMQUruCpmH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GVUC6bpAUfh3MgKzoaTTFq8B4VMx3QXEwxKWktG96sRJ",
      "vault": "DhXV9bugGCYoMJtXEmSdaNoYz1drseiPG8yQwobaSKL",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1534b3e6",
        "accruedReward": "1534b3e6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ZcYWPHH9TXkEPoNBXe8sTAmStVirPcDxBzSTDwhZYPv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7cvuBSRbeqfazXKSPRtYm2EPjLM3P3i8YNWVkMKQ5gXm",
      "vault": "H4nhzVYP3fovopQsrs1XPvH2GiBTGcaNAjdYcJ4H9hND",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62576783",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "19c920f4",
        "accruedReward": "19c920f4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62576783",
          "beginScheduleTs": "62576783",
          "lastUpdatedTs": "6265a9ac",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "175286",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62576783",
          "beginScheduleTs": "6265a9ac",
          "lastUpdatedTs": "6265a9ac",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3voCxQ1cYksGScaeo8yNHaCyE7wdFUy4PUs3M77s8pEo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Du6a8Tq5amGejrNDUktXJUw9cscWvyYzCEZ9kE54MRc3",
      "vault": "9aWbCU1Ed2ju4AfYBsZ39wiUd1GUJ5Y38h9mSQN4ftkN",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b6448",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b6448",
          "beginScheduleTs": "624b6448",
          "lastUpdatedTs": "624b6448",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2355c1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b6448",
          "beginScheduleTs": "624b6448",
          "lastUpdatedTs": "624b6448",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3zWqQeoc3ffGp3Fy3VD1sVTcGiAqgkdmToiCL2MwCASm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HwcqQP18wAQ8GWqJJArYaAdwxEBSuUii2fKc1Sc4TYCM",
      "vault": "Fvwn5PTXghUACp8x3DF3XatGBSEZx8MHYoegRSu2Wm14",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6265c425",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265c425",
          "beginScheduleTs": "6265c425",
          "lastUpdatedTs": "6265c425",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08f5e4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265c425",
          "beginScheduleTs": "6265c425",
          "lastUpdatedTs": "6265c425",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4jc73nsSX5uwwj9voVAwzVqLyKckDToSiwGZCS9XCUHg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BnNCBKQoGKdjUvC8xKF9DR87rn36fRB1rqGQfzjWzb7a",
      "vault": "GkevPmTsnNXqm64VhB622W1Pnp5p5kQ45uBGT9GsxmGe",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624f6e12",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1cc83198",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624f6e12",
          "beginScheduleTs": "624f6e12",
          "lastUpdatedTs": "625f5863",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1f4bf7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624f6e12",
          "beginScheduleTs": "625f5863",
          "lastUpdatedTs": "625f5863",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AV4bNfgvFaKfscuNsFWvhbEwj2MEryGKi66Da6iHsvF8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5r1hczFdLfTqhTHFsgR2Mu2Ca6Cj9aBGDDX2zq6ZhHKd",
      "vault": "39RhRVEXtzZrdHJUFHBHZ8ewyZVrhMYJaJaji4shyQi4",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "15779394",
        "accruedReward": "1577a947",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2kERkfNUB5b1GcatnifDdxmRnKj6wc8aXvZCj6hJRrah",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "QaqwCWwSSGHjQzwztExnryPQerxvmxMVVDWqNZc2c6Y",
      "vault": "2SWaosoKcJX9dbinwsSibLhNWxKUt233WPuweZUEtfRF",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6267261a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "31d51c4d",
        "accruedReward": "31d51c4d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267261a",
          "beginScheduleTs": "6267261a",
          "lastUpdatedTs": "62672647",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0793ef",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267261a",
          "beginScheduleTs": "62672647",
          "lastUpdatedTs": "62672647",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FuDKDKpR3YCPMETPZUuhBgAK7dQYBSzzVaJKL7v86PFu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EaTqMxV1nXLeAYGGtE7naS36p6vcnpZcsNK2tRADUYd2",
      "vault": "9rCaqkCvCQjpspc7f6VdWfoQcsbbAa7JveZHCLLk6YHz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62544ea2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0bd9d088",
        "accruedReward": "0bd9f4b2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62544ea2",
          "beginScheduleTs": "62544ea2",
          "lastUpdatedTs": "62616929",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a6b67",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62544ea2",
          "beginScheduleTs": "62616929",
          "lastUpdatedTs": "62616929",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AQJznToMf8FgfUbhGp8Kn1KsXuqhojUhKRo2H68Sx24P",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "83hko8P4Jdb5UsD6CxZDJ7zfUoy49DeUqG7uHVErrddD",
      "vault": "AvVUDcuPCjZJ6B99G83BoHB1fFVNb4QYAD9w95BganWj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62691958",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "109ed5e2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62691958",
          "beginScheduleTs": "62691958",
          "lastUpdatedTs": "62694a75",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "05a0b1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62691958",
          "beginScheduleTs": "62694a75",
          "lastUpdatedTs": "62694a75",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5tEUKmFqgHNYnshTXJGspfGqEbDv2oSvCgRbtvFX6CQE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CpYTXtDDuMfrnCf5EzzRHLwqEkCzm4ZVnpipVQVWpexa",
      "vault": "3pi5zW9uKwoLz3kGcaU1KneC1rpbsNffjMPyExDDXpoD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62490501",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62490501",
          "beginScheduleTs": "62490501",
          "lastUpdatedTs": "62490501",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25b508",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62490501",
          "beginScheduleTs": "62490501",
          "lastUpdatedTs": "62490501",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3p5KTgcJtCXZoEBRkK62BHpzUoSRLk6UmNBms68tRFRT",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Hei6bZYfkLXB9GMLWA1rVWazgmuAaFD4fdKVvLwzYksB",
      "vault": "HPmNZoJ56AXe4G8u53p6WkTsPLKnCEtod1ESyYoWDoCa",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "08c07a46",
        "accruedReward": "08c0a20f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "VvRePykRXx3SeVFpqSkKFiE3rXzat2JcZHEfweQctEp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5DryFWYYnwWfBboaSpaQvKteEsX6ESkuVixJWyHeniDQ",
      "vault": "8RoJQv2f7aMAjfGveWrNR2jWXJze6iXNQULN3ZCLHu8Q",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625b3855",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "5e42e44d",
        "accruedReward": "5e42e44d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b3855",
          "beginScheduleTs": "625b3855",
          "lastUpdatedTs": "62696a4c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1381b4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b3855",
          "beginScheduleTs": "62696a4c",
          "lastUpdatedTs": "62696a4c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7n8dPYaeXZKG6z9Vxqma4veqxGqWuFzMsUtJtBDjKc5N",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9UgtPr41VznEyTv789etMdDcmN3eN32maZzYdt6KAq37",
      "vault": "CYgLoKww9WPD5Y65Kbumt4f4KesEgNFohCmrTRUxwrG8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62569446",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "5965142c",
        "accruedReward": "5965142c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62569446",
          "beginScheduleTs": "62569446",
          "lastUpdatedTs": "626583f9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1825c3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62569446",
          "beginScheduleTs": "626583f9",
          "lastUpdatedTs": "626583f9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3APBRN6YHWoiPzXRh9hoPkACGb2uAzyeEJyFDxSp2wzZ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3ZecfBnTUjUuhD2bQxaD729sBG9uce8KL4Q1tpbHqaPh",
      "vault": "DDxXk3TZZN6iqxrFYxE5o9C8gAKhZWQ9nWxes4Kzz2FH",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "a0f921",
        "accruedReward": "a0f921",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9amR98f3fEmwz38vJdwEWiU3KGUBAAvqozamtU5XpKRv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5YFtvv4znSZWTfuPHvAtkeHtzDaNo7S4aLYzBbDNBFgh",
      "vault": "BZaaULkoV9KS4P3QZN5con1cKnienRQiVV4TaurcfLWC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624ac9e8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "15ba17f1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624ac9e8",
          "beginScheduleTs": "624ac9e8",
          "lastUpdatedTs": "6262d123",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "23f021",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624ac9e8",
          "beginScheduleTs": "6262d123",
          "lastUpdatedTs": "6262d123",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ESdofwgxEdJXqnn5V3YhtCvbBLv3oYQdbBCRFVEbL3qm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9R6DLcaWoYESBcFUALiDSuHDMJyzQ8v7qKdhHNKsHM2Q",
      "vault": "8hHYNRx8D42P7qNLQ4d5ZYF2b7SNq5DXBEfzb2rhAssU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625d1615",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0681b2b9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d1615",
          "beginScheduleTs": "625d1615",
          "lastUpdatedTs": "625d1629",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11a3f4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d1615",
          "beginScheduleTs": "625d1629",
          "lastUpdatedTs": "625d1629",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ATYGK87n1CT6odHxXQ8VMndxFKXhjS98DFPNv6FqzWnK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4LjLC2iFtv7tJHvtQ83PuzZ7i7bsHuH1UdB47SpgXENB",
      "vault": "BWUAnUandhvTSEVh3ysEdxhaq2uopGizcMcvjdxy8uES",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62559a4f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "38f14945",
        "accruedReward": "38f1524f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62559a4f",
          "beginScheduleTs": "62559a4f",
          "lastUpdatedTs": "6266e559",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "191fba",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62559a4f",
          "beginScheduleTs": "6266e559",
          "lastUpdatedTs": "6266e559",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2pWjWA6U7DFahfAv2YCvvZ79nMSV3vXgNPgE9s2CN9Dx",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "a5WrXQcYLWi9JK7DFNVuUvt6UKFuX3jJkL1wwyTZp72",
      "vault": "Eqo7vNTq6VofGDgox6vgxRKwMZgjKVuLqoDWexRq8Ji3",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3rquEzk14DtKAa1hrMcRaHt7tENnLE8wnnuybAX19mH2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HqCJpYRcAjWYNe8XhYd36Dwe2V99spKBpHfQgrACYZEf",
      "vault": "5U4gCFC8B8PWNErk4UXfubVxmpenDBpn2zmRoyNvonA",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "624853ed",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "4ec55660",
        "accruedReward": "4ec55660",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624853ed",
          "beginScheduleTs": "624853ed",
          "lastUpdatedTs": "625e1b3d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26661c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624853ed",
          "beginScheduleTs": "625e1b3d",
          "lastUpdatedTs": "625e1b3d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6MCG9EgbLLzCFMVcAUpQpssUFu6U1fVkzib9EDniYN7K",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5J7AJEtoPEXuJPgsmJd81QXzXeBL2h6mKgD2BYGuX6Uv",
      "vault": "GWKVkb3cRjF1Tg1CrRjWZdqJew7sB1XBwBCgRWxYFq9U",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "107b5675",
        "accruedReward": "107b5675",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Eo5YEZD2JXRoP9guC2fUPp5B2eWKa5koDnwDc2C21HUM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8yXoWf3KD378qfTWKc9jY1SPDgeMv9aym3yQA4DaM3vo",
      "vault": "8sbECX1Q3MHqLRBgGhhtLKL845PZubvnt7tMEAjMLuV6",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "20c9d4df",
        "accruedReward": "20c9d4df",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "885n3EwZbDsAbLLoDfkqyxzfpRDaXn3vPWrGH3ZLeEBY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HXmYtBiSFN8FH6uYMayAnFYyqamjFkbNLr331dADRcDs",
      "vault": "3o741e8UTzX3PzZxx1UburmnpdsJiKrE2hTnf9MPVQsV",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1e3ea43c",
        "accruedReward": "1e3ef853",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3Fs6GHLJJSDNAekEEJQgCcrVJPmvpQpfeyMV2BawcSwL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "H8uvK3pyxB3cGHhppN2ZDtiH4yLDzfnuLGUUcMusPiTk",
      "vault": "8qjdrLMpxm17aeECb358ZKZqDt9JqhY3sVE5VfAmPVjp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62594267",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1013cfa0",
        "accruedReward": "1013cfa0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62594267",
          "beginScheduleTs": "62594267",
          "lastUpdatedTs": "62693f91",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1577a2",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62594267",
          "beginScheduleTs": "62693f91",
          "lastUpdatedTs": "62693f91",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8kRmQBEaU3XHhn2PemxZAVjXqZpbWrWx2N8W9DJZF7Gx",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EQAvoMZcTsJpAoyCHHqgizqtnRUzotvR5oZiC6y4V3wr",
      "vault": "EqgMNZcjZDZFuw1AktTs7aCEmEnrm2FY5BpvgGqi1Pg7",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8yF2rdd3CYsQVbrQSebRFauMVnZ6B6AS3MNM59v9mE1v",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EG1xCCX5ge6PyMctrHarbHUqN6VNmY5oeywBnQXZaRRM",
      "vault": "3chCfRKdfDiaYrfMq49AR7ACVv8aHLcjz1RTsLhH1rmd",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "624a2653",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "4bd2d7db",
        "accruedReward": "4bd2d7db",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a2653",
          "beginScheduleTs": "624a2653",
          "lastUpdatedTs": "626619f0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2493b6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a2653",
          "beginScheduleTs": "626619f0",
          "lastUpdatedTs": "626619f0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3EJ4rhNsCfqn64biJSoYWKkzb2N6uZsrK4th5hB2JQ3g",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "84ZWga7rbo6GMDrJYDecP4CBPKcpW7J8YRy6AGi1QZkd",
      "vault": "7BEDZgiirxzhNajxGTSt7DrYh1JUUiRGZnyAHG6voYCt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624768ae",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624768ae",
          "beginScheduleTs": "624768ae",
          "lastUpdatedTs": "624768ae",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27515b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624768ae",
          "beginScheduleTs": "624768ae",
          "lastUpdatedTs": "624768ae",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "D1bcNCjrSNkAH3fQF3tasLrfFpW1v5oTMAmY47azMVXJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CHZBg4z1PmiNsJfkf1ixcBDfYPhPUGMF3nTPfpsi9vng",
      "vault": "8f88gkakgwvSkUj1ancve3Sb59bTcrbE6rYMo9bTJaCz",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1e4981a1",
        "accruedReward": "1e4b1df4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CLZmW8zNCyNfzeypx9uedWvAGA2DR1S112vHedr1LYs",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8ySwiPvsS3wJ5symw5jXs71LjGj5bhVV82fonhW4hse1",
      "vault": "GU3iERfJ9oMjcxuj3UjPS2SdCHVt6vPRRgD6qmBCwQ9x",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2883dac4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7UMS3HuH8f4siDFPP7NXYswqvoyaAbNbBL4PXNNfbGtT",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6ZB1vdP1oQtvJ5RZs5kiRcLtvSxdsMZcxgpjeuMjEBCK",
      "vault": "467wNYTD6AjYcB1AR2Dbq9SDaP6vCDPNrcU9oUFKmaut",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626aa397",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "308fca77",
        "accruedReward": "308fca77",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626aa397",
          "beginScheduleTs": "626aa397",
          "lastUpdatedTs": "626aa3ad",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "041672",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626aa397",
          "beginScheduleTs": "626aa3ad",
          "lastUpdatedTs": "626aa3ad",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AcvVjxm3EngEbvQfkekZrvDwdYNMhXq56NKRY1wLq2XS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "By5xsN7io5Av4iwqfRf2MVhzEXLwiKnKwXxyYEjJa5z3",
      "vault": "APVgxA4hNxKrxL3WSTU5LXmYTd6sZzRMMTJDPTJGDapa",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625038c6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625038c6",
          "beginScheduleTs": "625038c6",
          "lastUpdatedTs": "625038c6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1e8143",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625038c6",
          "beginScheduleTs": "625038c6",
          "lastUpdatedTs": "625038c6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HjmbHVzVPMNKEVCRJ3ZAg1MD8SmeXsLFoDoQGYUZLXW9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9D3BcNT2BmZRM76WpSSNCXHnDfcxzcRCVyiCpqMyT7ms",
      "vault": "6eKKnzxpDmEsFvpYzGCFiXfBwkrzrhxkQc7C98NbWuTT",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "173c70ea",
        "accruedReward": "173c70ea",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DqAUbir2dvAChEj7zpQcZsVry9v6Ax331hsAP2GSmtr3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Db3P4jowS5Tnksnk7zuV4rcAXTn9LZL9FDHC445RhPbM",
      "vault": "4nb4DB5YKii8M9ZVtWoi61o2k3L1TWnKLd2nNyoEzc8W",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248223c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0e383fbc",
        "accruedReward": "1b6228da",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248223c",
          "beginScheduleTs": "6248223c",
          "lastUpdatedTs": "62666ae9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2697cd",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248223c",
          "beginScheduleTs": "62666ae9",
          "lastUpdatedTs": "62666ae9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "H9e538BdUPtoEu1d7tgU26Nq3P7FmPW9RwzGyKRL8nsn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6zzqykv2G9nfMCJax6vFTJvDJcXh5ZN5LQWkKUqmR7zh",
      "vault": "DkiJZfBSNKwHYSJxGMoBkkZL3tcHobyh1SqsGZN7EHaw",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0bfd3de8",
        "accruedReward": "0bfd3de8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4EW6pJPrMN2XdWH8J4Xf1TwiZhYsHqHkKahDdAPQ45CV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3CCTAaQeQHGkVHu8CWBmKv53nkFKxAnVWuWe8JphNwWt",
      "vault": "AJ16BaQ1LxT93EbTHDcwAyenDrHiYcVcbTabz1UDEGbN",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "05f4f5e2",
        "accruedReward": "05f4f5e2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J3VnnusdLnqopKtKqg7uJuM2p6JUi6cDFTFDXG2k8Nng",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4XbDHLbk4aqFfR134StZD6P25Xfpx6B6HogKB9Cn7mG1",
      "vault": "EykhjnbJbxBTSsZKf1PCFxC2Q5d6akAAevATTsy1GaYM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626959a9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1dcea99d",
        "accruedReward": "1dcea99d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626959a9",
          "beginScheduleTs": "626959a9",
          "lastUpdatedTs": "626959c2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "056060",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626959a9",
          "beginScheduleTs": "626959c2",
          "lastUpdatedTs": "626959c2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8e43wVgHJs3CQTRx4C9SxWh4GkkYDV9kKucRWnkfncfh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "B6uEJYPWat487PmhPj87YB2LxV6eUnNbUez1DcdipikS",
      "vault": "JAdqUStH4ou7EsxKfTSVVXXFvSmrdG9R9j2H5YCK5x3d",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "A94USb9ApxV2sYQ1axPKBdt3cfHHD9Q2J9omHuAKKVKh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4SFgkLDgf3e7UGTtWUzT7moFsuFwuWQV7dN9P7bqtS1t",
      "vault": "7X1tBojNn2jEdNSDe1teceF8YfpH3SZMg6zQToGMpNjv",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62476d2b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "23d518b7",
        "accruedReward": "370ecc38",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476d2b",
          "beginScheduleTs": "62476d2b",
          "lastUpdatedTs": "6265df07",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274cde",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476d2b",
          "beginScheduleTs": "6265df07",
          "lastUpdatedTs": "6265df07",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2iVAwS244y7VTwKTgyGbyWScn23sc6B53n3wVU4LcdC5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8rqKrQrkGVDXbef4ZbnDdzdHAgdYLKV5tNNwwbv5bKeQ",
      "vault": "Gp93bJuUUAevdWHNbmEYgrLeaUoq2EJ9B1xv9k5HnjYZ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6255efd0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255efd0",
          "beginScheduleTs": "6255efd0",
          "lastUpdatedTs": "6255efd0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "18ca39",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255efd0",
          "beginScheduleTs": "6255efd0",
          "lastUpdatedTs": "6255efd0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9GtUZQx3hXV9bQkkDHzTruxeLk8vfescCbBF8PourfnZ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5iBaWt57Q1ZCVC4oDu6fEH3RVv94qoGmw9Nk5GPtWXgE",
      "vault": "5LrbV2EtkfXq8FetTMvyEsQLsRcif6kcAMKt5fviT4Nj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62566d2f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0d2bfdb5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62566d2f",
          "beginScheduleTs": "62566d2f",
          "lastUpdatedTs": "6264fe56",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "184cda",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62566d2f",
          "beginScheduleTs": "6264fe56",
          "lastUpdatedTs": "6264fe56",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AvDne9RiCxxcX6yy8NfztaTbTqZBnKbSy8n9LcJ7ciYh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3VFv9rMUrmQZiwPiXXjWvCdXuM8QQeY5JU22MNhC6emc",
      "vault": "XqeRVZ8mEGQ8UoAQAiXeD4KRMN76JLDkxfKkfCmr7Cz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6263104f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "140847f6",
        "accruedReward": "140847f6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263104f",
          "beginScheduleTs": "6263104f",
          "lastUpdatedTs": "626ac4f9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0ba9ba",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263104f",
          "beginScheduleTs": "626ac4f9",
          "lastUpdatedTs": "626ac4f9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ATkAnVuzX1m8xacqj999aGDv2HLXPWYGBRZQ6JDARjrp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "72gqbnPjKiCXe1WWtnjwBNy6VXCa7StPaKbZn7V9cWXk",
      "vault": "78Fj7LG2VjRR5crihh8rjJwYzUDVWb4GFdUjgi3ehCHB",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0738aef6",
        "accruedReward": "0738d5d7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8W5dGiEzbDFgN5pbhbkiNeLStLzsKxEecnt9y3c4Kr9D",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DghLFyavyXvd6E3uB3rLyFM9gQpVyDshdBggiQf5H7Me",
      "vault": "DZ9fzJwZz5rv9uPJ1X5onrbTT8ppFowWbrkomXKSGUgL",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62630165",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "31788891",
        "accruedReward": "3178e4cb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62630165",
          "beginScheduleTs": "62630165",
          "lastUpdatedTs": "62630179",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0bb8a4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62630165",
          "beginScheduleTs": "62630179",
          "lastUpdatedTs": "62630179",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FxgjvrpWhc7K6tDceX4PTR7DK9rh2LwquF7fC3D6MEnE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4jqiWGANpTgLUEVunhi169tKoNB6e6mGrnkBPg7jDnv1",
      "vault": "tAp5b9T9Q8z7LjnPWVBMk7iBwtVwDvYJgg5de7j6raG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "62674848",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62674848",
          "beginScheduleTs": "62674848",
          "lastUpdatedTs": "62674848",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0771c1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62674848",
          "beginScheduleTs": "62674848",
          "lastUpdatedTs": "62674848",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8kfy32BkQueDRLS3xL2heSKRC6xixEtuDXs8JvRV8Pwm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4RR78w9a2dVxFxogztb5CsnqAkxZeVzCUotLMTvBsLP7",
      "vault": "Wm94yY8mrodFsg8SzsxvLSYZ6ckAF4fLEjp3teqmpmZ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62547db6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62547db6",
          "beginScheduleTs": "62547db6",
          "lastUpdatedTs": "62547db6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a3c53",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62547db6",
          "beginScheduleTs": "62547db6",
          "lastUpdatedTs": "62547db6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CxWYFnMPSux6HAMtHBAwmKBQXWkKknaJYf1VqLhMAiyr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HJQpPtgvBwcU9SXn5Zr9nWWHBfqS722qJFE7r3Dd4diM",
      "vault": "4b5yMSzgooMoRDhhEMfD35xDTXFwxnxJS8aBVFAuY8zE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624782ec",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624782ec",
          "beginScheduleTs": "624782ec",
          "lastUpdatedTs": "624782ec",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27371d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624782ec",
          "beginScheduleTs": "624782ec",
          "lastUpdatedTs": "624782ec",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6jt9z5wL85BP3Eu16FnjCLV3kQuX3EeXaawNGrexdJ7g",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FHSzUNHsDEg72RzQHoMRMho2kDinEJ7KkPXUFgYh4b5B",
      "vault": "EbLFQ4tLD1G2P1NusQXUmhu4HsZGyWdfSCiinfdjdfAa",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624869b5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624869b5",
          "beginScheduleTs": "624869b5",
          "lastUpdatedTs": "624869b5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "265054",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624869b5",
          "beginScheduleTs": "624869b5",
          "lastUpdatedTs": "624869b5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DBQdCnVNGQc4LG4ZqHZhjhRsqk8NgDXZAVPtxS6pcZPo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FfvhBMQddptbsxQ8t94RSLwYg9GkuM8echGGnGraTagx",
      "vault": "5pHkdCe6L2X77jMh3iTgsbPHWCg2LCQfCtfvJuioTfAQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "07",
      "rarityPointsStaked": "07",
      "minStakingEndsTs": "62542ec4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "bfc26d7e",
        "accruedReward": "bfc26d7e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62542ec4",
          "beginScheduleTs": "62542ec4",
          "lastUpdatedTs": "626a9783",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a8b45",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62542ec4",
          "beginScheduleTs": "626a9783",
          "lastUpdatedTs": "626a9783",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ECZmzuTiYbwfZCx7atUsX3FaTGEpKY7HomtSzutgtwnw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "YpC4J2oEwFrscM6BQ4QSpxivWfEev1MdrBaCyCJ6vbt",
      "vault": "6rjPj1tNh4fSmFPAcm5rgWN4v4SQ8ipCNSucCdaiqpWB",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624b44f5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "5161",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b44f5",
          "beginScheduleTs": "624b44f5",
          "lastUpdatedTs": "624b44f5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "237514",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b44f5",
          "beginScheduleTs": "624b44f5",
          "lastUpdatedTs": "624b44f5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DBUkjcQh5gXEN6hycombFq6zQwBtevT9p49ptuMKyWZB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "92RCLQVGzRk3n4DqXBt25dq8AFQxCh4s3p4QRbY6195f",
      "vault": "8L7ZAqcANrEByLEveR6anoSW3sgRUPcfD7h9d1rHHH8L",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "164e58f4",
        "accruedReward": "164e58f4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6CacN4cYuCbmw43aKMigCwnjjKbdsmhseqH3bViCHDVb",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9cizkDpVJn1Ho4QoLzEbEAaX55iTpFGrrvV538hLTFKq",
      "vault": "4j3ThAWzn9NuhFE7AbYLsnmzKttchqFLzdRjrcYnRRPe",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248fbe8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1aeeec8c",
        "accruedReward": "1aeeec8c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248fbe8",
          "beginScheduleTs": "6248fbe8",
          "lastUpdatedTs": "6266c524",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25be21",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248fbe8",
          "beginScheduleTs": "6266c524",
          "lastUpdatedTs": "6266c524",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AY655QQcaSibMpX7nV7UBwPTPVfi6akK9f39J5GLmKpg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HP5gVoVFka2gVPoyfphBzpwXwct4FyTSX9dm9eJ54dVm",
      "vault": "DwihFNwQz3nrGwoLxwnfZoppc4p6yjoT1Xwov5pyn7Z9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b6f72",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "186a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b6f72",
          "beginScheduleTs": "624b6f72",
          "lastUpdatedTs": "624b6f8d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "234a97",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b6f72",
          "beginScheduleTs": "624b6f8d",
          "lastUpdatedTs": "624b6f8d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4bP2v4PgUJheLaj2E4KhmBUnRyRJJSmXB2RF1Pq39PEg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HjoARZdaz4eQ1dsrEsPkpkgbgZdSEBVD4ZEgsSkaQTjg",
      "vault": "2MLpkHr7z3vsStL8JaFAv9HiavMZbu8h7AioSv7pNH19",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "G3QeZBJzdLvzdeUwvY1TXkTaVkWnK9p4ze6p5vXE4zgd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BqKUhAQRuiehqCdkhq3cwwWbj3sWufbYUqXExnNsLhAo",
      "vault": "9UZ8SSxRtgtYSaM9GjMBWiGLAnvu83MNvvHjW2LhtX3f",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "39881811",
        "accruedReward": "398870ae",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4jztoeAcRUrX8uvBRoM3qMu9dkS1AoLFchaS1yr6bAP3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2KJg2GbGmnPVMqmgdZFrbb7FbZhtC5wuveK7MedXfSeN",
      "vault": "CE4SrJiFLFmsqUfCn2kM6GaatJqVs8Fcgvi4qhgNsvKS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62586809",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "21d758d2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62586809",
          "beginScheduleTs": "62586809",
          "lastUpdatedTs": "626990e4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "165200",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62586809",
          "beginScheduleTs": "626990e4",
          "lastUpdatedTs": "626990e4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DsznksN4kCKhBT5myoLyAZZXfz6VeDjFeipdMRykrazd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6gdUSCYTaHaBokmkak2vXAXXHCVR6C9WfCB8owE1qbZp",
      "vault": "3Jgx4yAPbdrDuqgoTpwcDzYbt8PazhPn1QTenQG2HhNr",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6256062f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2b607310",
        "accruedReward": "2b607310",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256062f",
          "beginScheduleTs": "6256062f",
          "lastUpdatedTs": "6262d181",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "18b3da",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256062f",
          "beginScheduleTs": "6262d181",
          "lastUpdatedTs": "6262d181",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DRcnKA3rJTSMjF1geCpC6dmUzxv6JegYnZocME39peri",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "QUF7YowDdvXXi2HjjM7YzCkTCgMjdbqKL4SkN89TjYm",
      "vault": "Bvf3vBMpqadcSZhCzUkfLjYiaBcML8eNB8qapLS1iPds",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0122faeb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5SZ2xbB2kFjZfSyH8q64WFLMy7trF6CaBp6cD99etDBn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6nQ7Z3PLYvfT5bSxb487GmJ4PenLP9o1MJGqzjkohhzu",
      "vault": "Ayfd8LoAa3jsQxWgPFTsVv6jJa8qxHoLZ6YfLwDg1H5q",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "62521079",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "394bc3ea",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62521079",
          "beginScheduleTs": "62521079",
          "lastUpdatedTs": "62521079",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1ca990",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62521079",
          "beginScheduleTs": "62521079",
          "lastUpdatedTs": "62521079",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "21LW1u1VgPfjkybZYn9wrDNceq913xseHsS3fBViL16Z",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "86DcSHxBz5L1s5XrP5HPKCbNU7ENU2B4V6mnKM1cjZYF",
      "vault": "D8JEfZSkXjsdrCWpWn2VAN5Yj6LbmGcawfwXvNGZAKwo",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476c02",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0fa4b8e0",
        "accruedReward": "0fa4d4e7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476c02",
          "beginScheduleTs": "62476c02",
          "lastUpdatedTs": "6258b908",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274e07",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476c02",
          "beginScheduleTs": "6258b908",
          "lastUpdatedTs": "6258b908",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3F6eP4RMapaTvvcVwNuXpKxE3UKgdXb9ZgbprYuvrMxV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3tse35sg7AfLUnwSBzqQHJiWASfcMUfU2kK5qCWV1ZFZ",
      "vault": "ExBXcCN2Dvph1NHtjSBnw1pbBGFrZGnTrBTWR9YF3VUq",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625df12b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "03a1bf66",
        "accruedReward": "03a1bf66",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625df12b",
          "beginScheduleTs": "625df12b",
          "lastUpdatedTs": "625df12b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "10c8de",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625df12b",
          "beginScheduleTs": "625df12b",
          "lastUpdatedTs": "625df12b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7dcr7pLPi4Gdhcx2tPFYkRXCCEkiJP2LkUtKjY9re9Rc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3vWGrBscFsdXB4itU1xYKsL6ysSScDkeCv86PBGiTpoZ",
      "vault": "GZHzYYD1ikK3TDmUkqxP5uPy36JAknKhnTGKKE6chmDw",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62488a23",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "9d55",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62488a23",
          "beginScheduleTs": "62488a23",
          "lastUpdatedTs": "62488a23",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "262fe6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62488a23",
          "beginScheduleTs": "62488a23",
          "lastUpdatedTs": "62488a23",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6TRuEbExAcTTpdXyR9ys4uearhzy9iM6HU5DGwVSdBBo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DwJKFZgTYoPUyCKZCeuaUDroVx9Y6au7d2NVkFBdS4NN",
      "vault": "FTKEDiK7Uw9x8SnRuZPkANV2rfFfg6TBkv9tKwdSgy4V",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0cce3773",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ADgTrKuyy5wvKZr3bimdJdUFXimmaQ4rvMwYPdK1JihX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GCCTHtpTF8tQgPVdHok8W5kKWRJfTK3UeYfNdJrhs2KB",
      "vault": "28Af4fAnWdw1AUa28zVcNmZPGve81QswQWk6g8QeCdhd",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624cb44a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624cb44a",
          "beginScheduleTs": "624cb44a",
          "lastUpdatedTs": "624cb44a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2205bf",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624cb44a",
          "beginScheduleTs": "624cb44a",
          "lastUpdatedTs": "624cb44a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GQ1YkdHwtBjRp6REqcJfkLnikr7EQfivTfoKoJT6GnHf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AcDdjKPNSUWXdVMsFoCd8gx62ak4Gmp3mtBQfWCFLe2s",
      "vault": "nd8QYrQTAvotdf9J6aqquorHAdsio6jFiDLN3JLoe2j",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626872cb",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "02331a2f",
        "accruedReward": "02331a2f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626872cb",
          "beginScheduleTs": "626872cb",
          "lastUpdatedTs": "626ae18a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "06473e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626872cb",
          "beginScheduleTs": "626ae18a",
          "lastUpdatedTs": "626ae18a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9m8DsibMstyW5cHHaqc8E519oc4PEpBgwfvhghm7wdkL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "54Zbuj6qcgdMSShoNUdqy4XYzYQQEbeB92tAzrTXgk7T",
      "vault": "Hs7bWYZTxE9F5LpGzVWog5KWVxGkZn6cH8D2TMjFEsUs",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6260f1d7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "02da93fe",
        "accruedReward": "02da93fe",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260f1d7",
          "beginScheduleTs": "6260f1d7",
          "lastUpdatedTs": "626419cd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0dc832",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260f1d7",
          "beginScheduleTs": "626419cd",
          "lastUpdatedTs": "626419cd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CoKktXYXRfkrFTjg9SV3wosTa31qpvxXCLTkhUR7cSYL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EGXMVayq6PWWYTaDztYwA6VmN4aP5Q2ShaYxKEHGF39h",
      "vault": "GR75gB3NYWzaFgAbzMKWzcLAJrMJ9uagwFAfVBW2PxGV",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "21034e67",
        "accruedReward": "25c6635b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FNXooLBYHmfEN59DgRH6D9yUMiYbABwNdwRLmrvVWZq4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6WeBdXRLiEz5fYZQJa2nDghrK6AcVV3qyEyZt8ikLvdC",
      "vault": "ADTgt2crjmYtsjrBhGC4ijc9N63uXrtZmiA6bSTMYnsg",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8pDo8Ew96i48Pcn9qG53A8azdM31X1bVuX6wmjwfuFd5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "C17hhN7L9KWPGCX3cravKVFqVM7ZwQ5F2q9NMZGqawHs",
      "vault": "8xkMbaVDu2MnRWiM8jcBTZ7nFRHw91sTNhjGBt1WAyct",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6268f4b7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1025d15f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268f4b7",
          "beginScheduleTs": "6268f4b7",
          "lastUpdatedTs": "6269c1ce",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "05c552",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268f4b7",
          "beginScheduleTs": "6269c1ce",
          "lastUpdatedTs": "6269c1ce",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2W539GZg17rznCo2ZyMiQqv8sKFTH5Mde58NDscyoYZR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3dfq6QZAJoYWHuiaFVsiYhqCr5Sx3mbiP332H3CBmANZ",
      "vault": "4S8TSnh48dqzrJWuTpzNsYo7nh5z2bDKJk6K3BdDND1i",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62643048",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0595ceff",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62643048",
          "beginScheduleTs": "62643048",
          "lastUpdatedTs": "626a5d89",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a89c1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62643048",
          "beginScheduleTs": "626a5d89",
          "lastUpdatedTs": "626a5d89",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BavhSHs79LgBr96MDdQWibxsVnfdJCWumjHszin9ijvH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EYK6SMxu5C8VLLQCvgGJkPpEUdeqiXgraGUPMMWYWk2Z",
      "vault": "3VocFczDddMncv6KRmd4dMdqkYaLiwDZjKGeSv2PocmE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6261af34",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "5c192396",
        "accruedReward": "5e92ed1d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261af34",
          "beginScheduleTs": "6261af34",
          "lastUpdatedTs": "62646c1f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0d0ad5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261af34",
          "beginScheduleTs": "62646c1f",
          "lastUpdatedTs": "62646c1f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8JnbgiX8CyVwXzC8Mway7Qgm5GhZt2NiM3QtpG7AQJBF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CqwMCWhXnH1wk2aEThEmDx8kbg3QGp1edJuk8yZMWodE",
      "vault": "8GnGQGsBmkp7BFfiwRVE7KXvcz1Z6TWsvNtkM22LA6pA",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62570ebc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62570ebc",
          "beginScheduleTs": "62570ebc",
          "lastUpdatedTs": "62570ebc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17ab4d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62570ebc",
          "beginScheduleTs": "62570ebc",
          "lastUpdatedTs": "62570ebc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "JDxa1gxmmivN1L1c4A5jBFzK1EasYKutHhTpRvJ92ifR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4SkrwqcrK58x7qUD6xFTbDcrkMwMAVQe4r9VXhP52a3a",
      "vault": "594v6QoZHP43FTrAHvYePZDXsEDSAwShr9nszdUMmzqD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6255c493",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255c493",
          "beginScheduleTs": "6255c493",
          "lastUpdatedTs": "6255c493",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "18f576",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255c493",
          "beginScheduleTs": "6255c493",
          "lastUpdatedTs": "6255c493",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Bm8j4mmPNhVtggMNJE2x7hfWWGfPk5wxHrB9EvWfhtnv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GvAadvKZjdUEj5PuPEp8Z3aaKFgzMK2bVb47X1WtMqnK",
      "vault": "BKte3qiYvjM8TQN5FyrQRuCrH4y3fwbLy4QqddEgiiHB",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0e457f80",
        "accruedReward": "0e45a918",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AN84nX6J76RVo83sBgNJfjRYZFTCXcx4Rk7Pe7XuhP2i",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DFcrtE3LmkVgtZJBqqYHxxp1gN9xVFjYPDDW9taET1Qs",
      "vault": "BgfKf9PJeKCN5Vw82afmnbY6D2ENASmPbAj3gintSq1p",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6258b2b2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "09f2",
        "accruedReward": "04ac6f12",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258b2b2",
          "beginScheduleTs": "6258b2b2",
          "lastUpdatedTs": "625ddddb",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "160757",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258b2b2",
          "beginScheduleTs": "625ddddb",
          "lastUpdatedTs": "625ddddb",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CkY6UmEUSg625iz3qGTpUYKhcCMBkXbSFuLZ6EC9y5gx",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FYJfh7zWfu7y7uSi4JMU6xJp95oc14Aig2vXbSUhCKKF",
      "vault": "5FRJs3aQUsgtrPX8nqJ4Hotg2Dkm1yEizEABPDWdCc5W",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62476977",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "54b0ca17",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476977",
          "beginScheduleTs": "62476977",
          "lastUpdatedTs": "6266a1df",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "275092",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476977",
          "beginScheduleTs": "6266a1df",
          "lastUpdatedTs": "6266a1df",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4eTxm9YEEM3ECk7GfSJrQj5rpdsCx3VEJFrCc2aE1fth",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DBQ5f6XHey4E5MPa4xW6T5RmncX4XLT4NANMQq5ZP2cG",
      "vault": "3ix6vCitJxdZTRztyekvXbRq8XToh1X8UWmsyeRSv416",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "018b1ffb",
        "accruedReward": "018b29ed",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3Zw8L8NxDsThmuFwByb8LRFPvgA2uSBoXUgpaLYZBoKS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "N2JyMpLd5Z3LFqrSY18S9mfpgxggHjrjxfE9hcWMS9E",
      "vault": "4YMk1n3YAjjofe55vsEE4AiengiQqHmUTQ6chqFMncm5",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62670b8f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62670b8f",
          "beginScheduleTs": "62670b8f",
          "lastUpdatedTs": "62670b8f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "07ae7a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62670b8f",
          "beginScheduleTs": "62670b8f",
          "lastUpdatedTs": "62670b8f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5kSQ4YTUjNP3YUN4nXLdyAQ2jBC41VfaYxCjx3tRr9J8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8vaq2ebGPjpfwLszYvnYPJkzUtwrHAeWSPawNhLsuMW3",
      "vault": "EQVwaLCDBERMWqqGwu1fSox33dAw7j8eaufahTcVibMb",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EEPXwPFXaShzmoqBcDSR2MjtdJovFsEEL5YYRUGu2Zn1",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3PgbyTWJj4UWZef3MH2Gqbo5McaTvY6Kmfo23Q4Mm8jj",
      "vault": "HAKuuFCA7iC2oJK487ihDVp2NFxj9wXPhGApiKrQR9kc",
      "state": {
        "staked": {}
      },
      "gemsStaked": "0c",
      "rarityPointsStaked": "0c",
      "minStakingEndsTs": "62560177",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "e44dbd54",
        "accruedReward": "e44e9657",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62560177",
          "beginScheduleTs": "62560177",
          "lastUpdatedTs": "62691c39",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "18b892",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62560177",
          "beginScheduleTs": "62691c39",
          "lastUpdatedTs": "62691c39",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9LFEKghqAfVtik2LFHj215PNGo2v7Lv3SWHbbgTzgzVx",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Hh89Z987HZMps16oKFrdAR9PxqhmA72sPPxi6VcN4uyW",
      "vault": "bKeDCURHdqojGFAATFmqyfjqme5NfpeMk2tdCQRsYtJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6251a4fb",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3e71ed37",
        "accruedReward": "3e71ed37",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251a4fb",
          "beginScheduleTs": "6251a4fb",
          "lastUpdatedTs": "626a8e46",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d150e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251a4fb",
          "beginScheduleTs": "626a8e46",
          "lastUpdatedTs": "626a8e46",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "D3pVyxryoh3dUHTGyYCpM8jqyCyVNTCmLCeE2RuoEw5C",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5xrForNXwbB5zmccELR7keH3jyFAginRL3e3YrCB3MxN",
      "vault": "4qVXAYcrdDfVYv9uwrTQdWX4w4akSeEXk77SWBZjhY4X",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62477884",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "08c83f92",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477884",
          "beginScheduleTs": "62477884",
          "lastUpdatedTs": "624c53b5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274185",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477884",
          "beginScheduleTs": "624c53b5",
          "lastUpdatedTs": "624c53b5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AVgwJ4fhM1UqEP9MCGXzkchunnzoLhCdhR3VR9snfSNB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Cr2FJqYTfQNDZ87kavSTaWj8psEiZaYTGccJ726JrEbr",
      "vault": "HiS8cL928P46ZQiVXLP5yeCryoaKE4YQwZAvzwSBtkD9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6265de67",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1f0af51c",
        "accruedReward": "1f0af51c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265de67",
          "beginScheduleTs": "6265de67",
          "lastUpdatedTs": "6265de89",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08dba2",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265de67",
          "beginScheduleTs": "6265de89",
          "lastUpdatedTs": "6265de89",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GNLfPy4aiykqm2URkGVWWD2DTdGGsXb1ugCaYrsrHPz1",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6jTyNPFJ42i7yUbGb7EZS86Ap5bpj12TmWH7qkVYPqGX",
      "vault": "6z2mra7mPbFRxr2pZHmeP5jVx4vBTiNGiAEaUaGxekWH",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "123208f5",
        "accruedReward": "123208f5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4u5WiiFqNNyYQP5oXL9XStxvqS46oWiG1RBFPL98R48R",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Bawc75nLb2wafU1Nj4BSk5GEGi5h9qvkJtGdwwiVczE",
      "vault": "8SgaUd8tDDEw5Q1PdFs6PXQ46W4zgZrdpNmbNhDkfcgz",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0ad79ccf",
        "accruedReward": "0ad7c012",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GoLsEdLXYJXsj4HbPZeS6QJUFdVwdmhkP1hrNDqRDRYu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HcPcGoHaeNbdsfs6VUeo9nFof3M4NZ1dT4z2oKvZhPXF",
      "vault": "7sNatabhZNuSBaLWvoo8ek5E3AF12tWMuE2YsA9z2Vef",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "06c9ee1e",
        "accruedReward": "06ca0688",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "13kdSZgJ6fNoEdr4SiSe9txHRzAacai4mnokYjCx5n3J",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2e58NVAL68i2gRg9Yphk1YKet616dqvrQgEUMhJfqt7a",
      "vault": "2Q5867pRFRgiji6vLtiswQrd2Rgq2uFDSGq384943krG",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9Y37hN67SvxyADM12pttBWX7mqYGRC1VZ3erez9VLmaD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "74yJGo8jZJDXvspHJLn2PR8WNgPKgvUv9YwsNVRJ5ob9",
      "vault": "ExxfGz3rYE1yu4SoZzjtPqdN2NPszyH2UMtEWELJ5F3j",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624c7bf4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "141b7d27",
        "accruedReward": "141b7d27",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c7bf4",
          "beginScheduleTs": "624c7bf4",
          "lastUpdatedTs": "6262b8aa",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "223e15",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c7bf4",
          "beginScheduleTs": "6262b8aa",
          "lastUpdatedTs": "6262b8aa",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "G3A9gsAuQThjUXvGzWmRSU7ceo4PAU4bgnZHK4HYtHub",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HhAs44BxXBvVENritbbs1PEdxuEDspttUjynakvzX4BV",
      "vault": "BW5bFLWihifmfodQrD983DDVZcvrGTx4fuMcEeDkHmJG",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1d804289",
        "accruedReward": "1d80c943",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7PL1tnSHL8uUDovGES5SUM3QE8wBeoLff8CGFZkfQQv8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CKTiRN9E2CuavC1Ry9GMPmdpRbGkyPVqJ6yhVSTvXUcw",
      "vault": "pNzecarYuU4PzjWsoU8fhS4F8WnYqdG3hVsbX1vW1ED",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625ce377",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ce377",
          "beginScheduleTs": "625ce377",
          "lastUpdatedTs": "625ce377",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11d692",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ce377",
          "beginScheduleTs": "625ce377",
          "lastUpdatedTs": "625ce377",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Hs77utz2wDpHHVQusyzBwXuoUE1V5sZzkGfgZJd8MJ9a",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HHGHSHh4WvzJvQT8kAoHjSDxPaMXDnYgMBFnexcDs5TL",
      "vault": "Hcm199jmeaMy7Mofb4uc57f7pzGroQrYvXzDQ8jzXE4R",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6248764f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "25f2448d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248764f",
          "beginScheduleTs": "6248764f",
          "lastUpdatedTs": "625d71e9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2643ba",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248764f",
          "beginScheduleTs": "625d71e9",
          "lastUpdatedTs": "625d71e9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Db2tjRSNs3nYCuVeWNQLDqUw4kwJphfgBnhv4ZtWUnKX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CbpZqnnob3a9PAmbuhN4gHyrLKi3BzqUSdoVSvMzyYra",
      "vault": "aCefjBbnzpHrPmbFQe2BkyY8vuPNvogw1WritHkiCk6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625e2822",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "08554373",
        "accruedReward": "08555588",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e2822",
          "beginScheduleTs": "625e2822",
          "lastUpdatedTs": "625e2860",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1091e7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e2822",
          "beginScheduleTs": "625e2860",
          "lastUpdatedTs": "625e2860",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GFZ23kPyFdV8f1PLUAXMJcacooj2coARP3EwLXNTYqcQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "77kAcgERYyhzjM2fYNFigPjWYbBhiLioKVu9V6xismaX",
      "vault": "HZPo3k4YTMK1bbHjBR9FR5BmSm8QMQLtdWYYMkVi1ej1",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "62476962",
      "cooldownEndsTs": "624b17f2",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "06a8a1fe",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HjLiwuwRHBsMpAM1j96aB41ACpAjBcjixgFoi3Hp4Wok",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "LHh7mgkDXJAKFBh2rUHmGtNk3RW3tLpiMEEjdr5wDVW",
      "vault": "BjXBT6Tq3Ed9kyvbipDM19edephEwvtyDnwiMmjbL3o",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624e1431",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624e1431",
          "beginScheduleTs": "624e1431",
          "lastUpdatedTs": "624e1431",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "20a5d8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624e1431",
          "beginScheduleTs": "624e1431",
          "lastUpdatedTs": "624e1431",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2fKDPwxoPASty7mQGDoVg6JHCb4RbGFdTnhbCbXwh78K",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FrRzmTjsdkjNQpDLA9zgCV9ideLu2WvLESzKs4nviUpX",
      "vault": "4XxfeVbCban9EAfTfVN15xp4s1shqhqSvEdyUZArffLr",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1b1d9c93",
        "accruedReward": "1b1d9c93",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7evvuCvFAmwSJGMZzvS1QBwHKad4w7UmRk1DKDjASG2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CFugnkSRkdk7wA8cL6cHNMouau3xfALHMiNS2ErZJmh9",
      "vault": "8kvrp8Knkei5CmoseGfBWBtPEWb7wtTZ8oFiXZZfo1fB",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "b68883",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9ydxy2fr7CbBtXZmTL57f778KckAqf58KF67wuDQjKDn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8Tp9xjAF1uEcX79YYuL8fVyMCM7A6bWDWPbSpwibWmio",
      "vault": "2geX2pbH4EkqvDMCR1ESLgx3TAtpTkz4K9K9YJqnWMAf",
      "state": {
        "staked": {}
      },
      "gemsStaked": "0e",
      "rarityPointsStaked": "0e",
      "minStakingEndsTs": "625a366d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0172c5dcc7",
        "accruedReward": "0172c5dcc7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a366d",
          "beginScheduleTs": "625a366d",
          "lastUpdatedTs": "6264c941",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14839c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a366d",
          "beginScheduleTs": "6264c941",
          "lastUpdatedTs": "6264c941",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AXnZQ2UNvvG8sYBBEL3MxHizphrvuavMXQt3GgJMR3k",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "gJa2avQk9Eg3wY68UGhhrSo7ni26YXoYryW1Sv11bJj",
      "vault": "TWgJgRCnxyT7ogZGjLFfPwWVwEouWdp92WFCaJXgYYV",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6248372e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248372e",
          "beginScheduleTs": "6248372e",
          "lastUpdatedTs": "6248372e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2682db",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248372e",
          "beginScheduleTs": "6248372e",
          "lastUpdatedTs": "6248372e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3eMxEQVAoYKK4Nh89ZaRGoK2Z2MbMLoyeKrAGeAWEPqa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4bce4DGEBmgL5cuQUSs5CEp9PBMFQPWCZNkpfKmWytHo",
      "vault": "3LV7gEfNt4ueb5YChUUd3aWeAQoLEP1GimVH3w37RbUk",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6252645a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3a42b23f",
        "accruedReward": "3a42b23f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252645a",
          "beginScheduleTs": "6252645a",
          "lastUpdatedTs": "6267b4d3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c55af",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252645a",
          "beginScheduleTs": "6267b4d3",
          "lastUpdatedTs": "6267b4d3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "C29GVPpzF7ohCytZAmLDNATskvdEvuQrfCrekeyZqGbF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9iTieNXbQrSB2AdbQnWBxhHEWgNRaiX2UzntYQbLWjMz",
      "vault": "HNaYobgNqUkNDifmncN22QB1X72YsMs4Qn2WjG2u84ru",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "31e6d5b4",
        "accruedReward": "31e728e4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3r8t3Bzakvgk9UgSnTCFLxaqL9SB9og7EYYXdsKqzQxr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5uWN6jRLUhRVTGPn9k9sK2Fpir3u9MUtujLv3FZi7EdX",
      "vault": "7ds9RQxbp48ioSHsmg8M8TGMS5rmpnHNJz1Dhy3joJdc",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6265c151",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "36d6fba7",
        "accruedReward": "36d6fd75",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265c151",
          "beginScheduleTs": "6265c151",
          "lastUpdatedTs": "6265c151",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08f8b8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265c151",
          "beginScheduleTs": "6265c151",
          "lastUpdatedTs": "6265c151",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EkA8YgWEcharZT3wHUoGhq5H1EicD3xPDBgMmetBEWeh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8Tf4Vd4dPozqibnjgZtZGQ8LYB9AScB4wUhwKDVnza9b",
      "vault": "5p75KFmee2dx8xrj6YhGVCk7jegf3JaK5Rmv9N46PPCH",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62548f7c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "177ad8c2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62548f7c",
          "beginScheduleTs": "62548f7c",
          "lastUpdatedTs": "62548f7c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a2a8d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62548f7c",
          "beginScheduleTs": "62548f7c",
          "lastUpdatedTs": "62548f7c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2tCicKMyzjbT5j5byrF5TpHChjjxE93bUChErfEvi9hg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5sNSG4dNRsUH7NGqrLXbjBMx7hvgs1RLb5x81bjvKCHC",
      "vault": "7sEqkfqTAv9yULUNPKh9Z22shUnNArxDriRi1MDFnY4E",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6260900f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2d7eb73c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260900f",
          "beginScheduleTs": "6260900f",
          "lastUpdatedTs": "6260900f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e29fa",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260900f",
          "beginScheduleTs": "6260900f",
          "lastUpdatedTs": "6260900f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Dgi3vr4XwMYCTDhGHgGqiddRh7SSPEH3Z2ng4rRT5M8t",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GnKwBPSbgyiGhU76914MKUwaPcvPZa2dBt9swBrPAgn6",
      "vault": "9giim5aMgYtWwijqAFNpbdt2VH4y8XqMWtQ4hpUpCwMy",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6255127c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "25ffacff",
        "accruedReward": "25ffacff",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255127c",
          "beginScheduleTs": "6255127c",
          "lastUpdatedTs": "626a1580",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "19a78d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255127c",
          "beginScheduleTs": "626a1580",
          "lastUpdatedTs": "626a1580",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GYEj5JHuwmaQ83QCCWHzikpJ3nSa1rTNyQXQVJMfr3Cm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5QmkZYhve4JHQrgApv3TKMBEbLvPk5XDgusdPtvW4J9u",
      "vault": "ByzKetoRqrUU2P5b4QbyuHGQSbSmBnReotvhLCKTiCu9",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "455b7a04",
        "accruedReward": "455b7a04",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DVHpotnAxngj9VTjhEGmpZVMgzHQz3MfmNw5As3PyaaU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ARs3hxngm1cCbFa26sS9Dzev8S1YoKNjMyKM63cMbu2e",
      "vault": "AbeAog7z1D8nW7QXwuwrfVT8Meqwyi5F6rrFv4vRpbrY",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62609c8f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62609c8f",
          "beginScheduleTs": "62609c8f",
          "lastUpdatedTs": "62609c8f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e1d7a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62609c8f",
          "beginScheduleTs": "62609c8f",
          "lastUpdatedTs": "62609c8f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "47xXryRJ5KHbdNp9W4MD47ZbzGoq8ChGFLRVAfZSpANY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EAZafRgKmHqhf3J27EXzhHHPyXgUTYsuwgyJdjw38GpX",
      "vault": "AAGRXPWvM9n6HSkZcfkVJFV4J5oWqu6h7eosTWcHBVEH",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0f632a76",
        "accruedReward": "0f635fcf",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "En6yuHKUDTxXiyJxrqbkZzjdZ7QqARCcKqoiRDpgeYKt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3td62KyoHGGrzCHVe6rk6hFQtgDmiWkv4EJUGNa8Qv86",
      "vault": "3bPSjEdaUicwUzekPCw5uQLhvGCBLnMefyPtXhiJEW2C",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6256a991",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "50a0d16a",
        "accruedReward": "7b10cb10",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256a991",
          "beginScheduleTs": "6256a991",
          "lastUpdatedTs": "626993b8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "181078",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256a991",
          "beginScheduleTs": "626993b8",
          "lastUpdatedTs": "626993b8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GNEYyZ93y3RwUJwBTFemeyt7EVUgvTqjP9AQFUnHPPG3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5jzqMvpPn5qpvwpgHpg2ZDwEobmP1vHV2ioRRp4zBrkY",
      "vault": "HzQ8VPrt8uhjHJfJYeRCbKz4YWDce5GMovRbHmmGx21X",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0cae5ef6",
        "accruedReward": "0cae5ef6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "591xfiTykNcy68qDcxoCqacAjxcWkHo756fAPqVrnzAb",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3zX2QGY5CiLn33a24CnWweVi5hE8jmjewpaEoQXENZXG",
      "vault": "E2TX1ox5t5D7QFmeNBBvUdSDj8B1C5yG1eCvR8V87XhZ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624a3c84",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a3c84",
          "beginScheduleTs": "624a3c84",
          "lastUpdatedTs": "624a3c84",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "247d85",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a3c84",
          "beginScheduleTs": "624a3c84",
          "lastUpdatedTs": "624a3c84",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2Uc5NbWbdmChTP9TuKecHpu85jEQd4fVR75CamQeX9xX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DJuCYyXkeiYTqUm89SG7Ys682XVhkN92XGaAQuENsa1W",
      "vault": "E8uSYhYzsCt2sa9Q9hz78bRmfJH4hpjW84Hjua63hufd",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62596604",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2008c5c5",
        "accruedReward": "3dcd8539",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62596604",
          "beginScheduleTs": "62596604",
          "lastUpdatedTs": "6269dc2a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "155405",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62596604",
          "beginScheduleTs": "6269dc2a",
          "lastUpdatedTs": "6269dc2a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DqLhxAxHZP6gAW38v5kGFqNAG1rYgorEHyqEzaRHnH1h",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FHEbtUTTbR2pY7aBvcUQGWxS3UjZeuyoS2cRjDffcwkE",
      "vault": "DUiGmWXWtMQEeV8ATdDHa9WhrgbTSfVTSr8aNx51aqLB",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625090bd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "175a707f",
        "accruedReward": "175a7fde",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625090bd",
          "beginScheduleTs": "625090bd",
          "lastUpdatedTs": "626a647b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1e294c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625090bd",
          "beginScheduleTs": "626a647b",
          "lastUpdatedTs": "626a647b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DFLCMs7jfDUvc4QuySw65tFHyByB5SDX37DMHnzm3RZK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "77QvLQZou2Me3E9jxwymD3PByQ7FUmoLchV5bZiWZeXT",
      "vault": "5TFVx4ye5nBQAxTAwBy5GZ496jQmXC99EHQCBk39X1y6",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4Eyu7W5tt2G7zkZoZRV4jMBwFzo6C4SVZJGam8weud73",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "H2szYKVbKWAXsGM86CfVCUm9TiBfMv5MrdayJA1DS2yi",
      "vault": "HKHDkJHsxsXQgXRbrvUd5qsCaLqUsAhhf7fGa9PJw6MM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62477878",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477878",
          "beginScheduleTs": "62477878",
          "lastUpdatedTs": "62477878",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274191",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477878",
          "beginScheduleTs": "62477878",
          "lastUpdatedTs": "62477878",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AcxJkReKdqLBi5kDaVPQwPnSNDbdtgtUC3VkLxBjM6zK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7uo6WtaixZEoqtnbDQWzSModmpTtXYZzfvaZeriFJcBn",
      "vault": "2mkL78rZELwXJG7EfZ7ZENvXmNM5jV2jYjarVbbn6qYV",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247e08f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247e08f",
          "beginScheduleTs": "6247e08f",
          "lastUpdatedTs": "6247e08f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26d97a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247e08f",
          "beginScheduleTs": "6247e08f",
          "lastUpdatedTs": "6247e08f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8GqkVPvZsM9xhsKi57K5nAqW7DPrBXFPTZiG4tURmqvY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GSQMrntVodQhNNtPNzBDz3dhUqpJHX1AqT2HFMjKo4M1",
      "vault": "Dj9K7ZHP6uHk23btpfiMSf6AFsHXucjkgjZYpm3f3bSa",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6261c276",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0a9af3a7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261c276",
          "beginScheduleTs": "6261c276",
          "lastUpdatedTs": "6261c276",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0cf793",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261c276",
          "beginScheduleTs": "6261c276",
          "lastUpdatedTs": "6261c276",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7CeVELcw5nYXk5TDPTp47fyt2ubAyvd9c8hNW2r5ptQj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FbhPZCpEoxr391MmwyqShS7giSu3Go4zRNKxm1ZEd8Xz",
      "vault": "DtGCp7JbUjMRSDB7Li8WoGdbn4vnpbzCirGwu26DFZgS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6251a336",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "264844a0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251a336",
          "beginScheduleTs": "6251a336",
          "lastUpdatedTs": "625cb301",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d16d3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251a336",
          "beginScheduleTs": "625cb301",
          "lastUpdatedTs": "625cb301",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FMfgFjFKudH4QVwpLyeHzVa7JnqeL1ESBANWxmACD7jf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "66LeUbZa1etZn4wcNXYp11hf86kT5nUfcYhVLjXaJkn9",
      "vault": "6MnV6pgpCgqormXqokWBMXdS3EJj6QEoc9bqwELEcjm4",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CWXnkedYAq4H35dZP2FV9NtMgM7mt7cp974MPB4XUJZm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DThGyTiXKEnkUgMwom9FCYuD4ikZdqjhnqxZ4gv8dpHF",
      "vault": "5v67WwYyyiyHCuLc88habgsbLeRZ4xPjbZ9Wjv9TwCjY",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6259cf3e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0aae01f3",
        "accruedReward": "11d00b0b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259cf3e",
          "beginScheduleTs": "6259cf3e",
          "lastUpdatedTs": "62688a78",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14eacb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259cf3e",
          "beginScheduleTs": "62688a78",
          "lastUpdatedTs": "62688a78",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9Saw6jV98RD43ofEuMzuQfGqPLrBSK3nh85HYcuMKQha",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DPcU8XijSrT8XRjodHoZQaC85ZCQdJop3bPBcmBexrUF",
      "vault": "EQM8NrQ4sTkpd9BZ79diHfgL5HsZCmdELLKnsUAYjQP5",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2884f30d",
        "accruedReward": "2884f30d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CNmFdSSGCj3c5L8mXLXH38RQGfKLHmVwJmWqJgJyYim8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5oE6bUSDQVBEfpFzuxVG2NagDgvp8PncoQykgiYjN7DX",
      "vault": "88PTPNZjRUEvmS7QBNzuj8nxZa7oKSngnun5ZHNAtGif",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6269a043",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1b03ddac",
        "accruedReward": "1b03ddac",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269a043",
          "beginScheduleTs": "6269a043",
          "lastUpdatedTs": "6269a8ff",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0519c6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269a043",
          "beginScheduleTs": "6269a8ff",
          "lastUpdatedTs": "6269a8ff",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FR6bjp4jjc3buQzr9QaCkhcHVecBHQBu9PhB1JRK6B5p",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FNpKkxgvHfz3XHfUARk9YWqq6wJKCCZwwRh451wvfC64",
      "vault": "FbCf1Ea2Nfu9udhzTajPBHvvMAADwZC7HPy5REpg3Wxt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62678af9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "ad3d7f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62678af9",
          "beginScheduleTs": "62678af9",
          "lastUpdatedTs": "62678bbb",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "072f10",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62678af9",
          "beginScheduleTs": "62678bbb",
          "lastUpdatedTs": "62678bbb",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AxZQ1ifoaKh9ygFUJQmuWG2DqtsLCjhJYY9kxEYMcGHj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "53v2yRNoLPtyrMCd7W4Qt2vVxbaWS8BuNdXiHcitZoL7",
      "vault": "E2gJRbMU5L9B2VTuoDheZKrMG9SwaxQkgA57o1DTE9aK",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "04418e24",
        "accruedReward": "04418e24",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FjDMvYSs5xeSEvP5BtJbDQ1BwMcFErAwCa795xowirfS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FxWYxX5hcVoUUcF5vrSykX52SDLQFD5k5etUMxJsuV9v",
      "vault": "HiBpJjnBcqxL6bBi8rVuEsPXutD2vCE68B2KFV1vjiyM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624dbaf9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "18d46a70",
        "accruedReward": "18d46a70",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624dbaf9",
          "beginScheduleTs": "624dbaf9",
          "lastUpdatedTs": "6262e608",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "20ff10",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624dbaf9",
          "beginScheduleTs": "6262e608",
          "lastUpdatedTs": "6262e608",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3wWGvPc9nkKTP2WbQ5uWeh9P5jCcq97NDZZfHjdcXtHv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "59dTKHjPivPuMk3XDn2UPxZcwaed4xjDFcfxn7dMQSGX",
      "vault": "4aTEExREYBnkM9BCrcgsWamLpGjuYAiKWZVQsRWYbwEb",
      "state": {
        "staked": {}
      },
      "gemsStaked": "05",
      "rarityPointsStaked": "05",
      "minStakingEndsTs": "625cad24",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "82d4830f",
        "accruedReward": "951c7df5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cad24",
          "beginScheduleTs": "625cad24",
          "lastUpdatedTs": "62688f0b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "120ce5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cad24",
          "beginScheduleTs": "62688f0b",
          "lastUpdatedTs": "62688f0b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AQAeU3tTcD1QQNsA5PACu4VJ2W1zgMb1DQjLVdP75Dfz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6Q6C1dFiUzySohPfwxCyQTMDg9pWKK6G32WJB2TT5Wc",
      "vault": "GEHLS5uyCcScE6K4rnDJz4PHzgvnxeMtntesUfJGLLci",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b8f59",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "32a2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b8f59",
          "beginScheduleTs": "624b8f59",
          "lastUpdatedTs": "624b8f91",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "232ab0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b8f59",
          "beginScheduleTs": "624b8f91",
          "lastUpdatedTs": "624b8f91",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GZJYyN8z7DMnVqJsszoCH62L2o72LMah8Xa2HXNmpMKo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Fv1dxRwy8Mz84wZt5pj4QhrC9nkkoVbUoPaWFCNmVNjM",
      "vault": "DpWBrpsATkTmrE8F2JA6Mw7vVhE4LhcbTzPbFYPLZuZX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6256d069",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256d069",
          "beginScheduleTs": "6256d069",
          "lastUpdatedTs": "6256d069",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17e9a0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256d069",
          "beginScheduleTs": "6256d069",
          "lastUpdatedTs": "6256d069",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3bC4xDbZPenA77m9CULndbzUG2CxRWmqf6EaCzG29KbL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4jupScfDZhbb25qYShno3Kw6qCtRkjPixH1at7uGcQ1Y",
      "vault": "F8BgMjS9hLvQaYyPYxrBzXqSMVvbbmSrTr6Y9CxEt935",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d192c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0a39139b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d192c",
          "beginScheduleTs": "625d192c",
          "lastUpdatedTs": "62686773",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11a0dd",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d192c",
          "beginScheduleTs": "62686773",
          "lastUpdatedTs": "62686773",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FGJGGf8Bw9biZf26Z5GkMibxJP7pshxdGS86CUWyXtwd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2eBLDneWAUQoLsRNDUEtfbwmTNqGTbpsjGRQraRXnzqY",
      "vault": "9bd6WHWb7Aum9jrwUsuffRcz5MvXQ6kPgpFKSJMK5sUK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "625ae61f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2d2c373e",
        "accruedReward": "376d5793",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ae61f",
          "beginScheduleTs": "625ae61f",
          "lastUpdatedTs": "625eade8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13d3ea",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ae61f",
          "beginScheduleTs": "625eade8",
          "lastUpdatedTs": "625eade8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6JrG5GV7yqwGGPmyEhWo9fd3kSLbah21GubPiPTTPcSM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CjmRRhT8MAyzBbRZnBon1Jtu2aCsWGVsVd5KnpWWLhmv",
      "vault": "9q5LVZ6DytzeNChTzLhV2TQdb3Cd479g5V2cSiH5Q8gJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626ad872",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "085ffe7b",
        "accruedReward": "085ffe7b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ad872",
          "beginScheduleTs": "626ad872",
          "lastUpdatedTs": "626ad872",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "03e197",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ad872",
          "beginScheduleTs": "626ad872",
          "lastUpdatedTs": "626ad872",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BXcae5hhYGnBPuATnNa2beSrV9rLLgi4yh7HDcvEkyHX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3opx7zyL6KdR8d27Y8v5hVyMosdudqyREfEwXwBDP7zT",
      "vault": "6Y2CSgNRSX4KFRGcAnqJz6GhpkXizXJCQSsJiAx1gasD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624790fc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0e8bdba4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624790fc",
          "beginScheduleTs": "624790fc",
          "lastUpdatedTs": "624f9c21",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27290d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624790fc",
          "beginScheduleTs": "624f9c21",
          "lastUpdatedTs": "624f9c21",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BTkQeXZZV7twjsctQKZUhwHntnDmzgbHzTLoJHbcxz2G",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8perKfZVsCahu8Enj8mf47BjSV41btp9qe5u3WfJKaw2",
      "vault": "JBddWEZJeq22JSZ4Seqxf9wkV72oZ5L7mZ6cZR5Xr6Q9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62487923",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1056edb7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62487923",
          "beginScheduleTs": "62487923",
          "lastUpdatedTs": "62518221",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2640e6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62487923",
          "beginScheduleTs": "62518221",
          "lastUpdatedTs": "62518221",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "54AzZ5XUvFxySAJoYPeNkq2GGpPoSrdsKFMNx82EADM4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DKMZpd8UivUKkAfNdPSieqprc14SHfftHunMTGD3ngTe",
      "vault": "ABBdCtywAyiNsZNZmbESq5mVajWNqCRwBBd9U3fz2NUc",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624a4c8d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "231f2d87",
        "accruedReward": "231f2d87",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a4c8d",
          "beginScheduleTs": "624a4c8d",
          "lastUpdatedTs": "625cfebf",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "246d7c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a4c8d",
          "beginScheduleTs": "625cfebf",
          "lastUpdatedTs": "625cfebf",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "59WXh146Z6Y1BgSJtcwEtrw9kWmMA1KdkcXSRV7u46xm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4fpgKNqhFrtaHzRTLnRSWFwdxZ3in2dD8uwSnHQWdnAJ",
      "vault": "5sxsW6TZ1KpA26fYYa8vhX2kTcGwkiZGWAJqqcxWYhP6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624973f5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "fb5f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624973f5",
          "beginScheduleTs": "624973f5",
          "lastUpdatedTs": "6249750b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "254614",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624973f5",
          "beginScheduleTs": "6249750b",
          "lastUpdatedTs": "6249750b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HMHfAyWzyFEimHfZQbtC1SDnUmQfTyYqyo6fhHBVxWKV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5bELu4d3HxbE9YNPNuDKKvrGfCgMrVAASw4hgDoitQsJ",
      "vault": "9kpfs7WJi4cttzM5Tg49noVCsNzFGZfzFsCBmvYDbdLW",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248b2d0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1e50b71b",
        "accruedReward": "1e50b71b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248b2d0",
          "beginScheduleTs": "6248b2d0",
          "lastUpdatedTs": "626a398b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "260739",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248b2d0",
          "beginScheduleTs": "626a398b",
          "lastUpdatedTs": "626a398b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CCVmoiwEukGt8NQ27XtPMcYfi6U3MoVB9oRJN2iVKsrj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "85RxNJVDwdKSpYdrcPqGQJRMXYkCAyoj9DVPhH6CQDKa",
      "vault": "61SDYM6ydgUv1bqwums1C2ss24igeZGtVQZ1Pa2FcaEo",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "09710d5e",
        "accruedReward": "09713b7b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "56kptErTsVzMPZBeydx3fDUENxDyjemdVPJnedNFnaFj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7t6sPLv8pRAhMdDrB2esAF7QCzDZmNKRzJK49g3rbsb6",
      "vault": "J3oeXhXzTQcNPBE3qaijqTuXymHFBfyoNLqAsPw7r5fS",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3q3wNFFU3YzNZeTozvNGWtCzcHMXPNij7VdApFmypS11",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AzvSX4ag1mRipj5jpi9PiF5AJaUAE25aWsMAtgHHWy2s",
      "vault": "8BgPgAZBgQ79sEVAyWKqAiGgbcTALG1DFVVy586jZKb4",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "07c8b2ec",
        "accruedReward": "08a1e1e1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "qBWd7RjSa4giN2yqnXgH16cUawHXC9568fPKtesG4Xn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4iYcpFthf2r3ehVwrmkftddasTNJoDuYQtgzRDsZ3cuL",
      "vault": "8uRgnYbUXq4626AGuZbKDeEGXH7THpqtYEXXK3DYSKoX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625c813f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2aa77536",
        "accruedReward": "2aa77536",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c813f",
          "beginScheduleTs": "625c813f",
          "lastUpdatedTs": "62630b01",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1238ca",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c813f",
          "beginScheduleTs": "62630b01",
          "lastUpdatedTs": "62630b01",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3syZQb3eCpSddY6fn22EjGyjJSrdE3vzs1PuA6PVnvnC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EAg1dS6Wa4CknodCHc5qgjovveEqY82J3rMk4muSzuiQ",
      "vault": "AiwxK1NATL6jGCKeRNKXjw2Kq6wPeT7Rf54HhqQJb58b",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "a9fe",
        "accruedReward": "dcf67a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5wk1QjLs7Bn1CwHxs6juSPEfzcvSeyviMgvvUSKAtGT8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7K4j3Tst2V6htnk5E6Af3sDeVHfW2D9XoRXz93sgvcfm",
      "vault": "EqW2FAbwajHRBDhj9qzYD5HJhKU2XTWDDdk9J4hjgX9D",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "137547f7",
        "accruedReward": "137547f7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FNivJbSSFkJb7MPJurEMvH6nFhDBoR2iMCp1bumuCFHV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2dGoaQRHq3bvRnd3YLrLSwko7SXtgb9N3b1U3mR3ezmW",
      "vault": "CQmWkuh2kae5Luw52tM69gnPsZDnpWu3niSJ9aNg1P7Q",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625669fe",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "30fb7257",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625669fe",
          "beginScheduleTs": "625669fe",
          "lastUpdatedTs": "626a182a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "18500b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625669fe",
          "beginScheduleTs": "626a182a",
          "lastUpdatedTs": "626a182a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5eJ86j3Jdb3UxR5QJyxA2MN7Ur1rLdVb6TmevZYZWCqf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6AzUNdEGgnxgeC6KtXcYZTnaX5V8yibQqmK7gpUkFDH5",
      "vault": "DzdcCwHgqpZAytmp8EcxhZ9nv8hSiREmhDDhFCagVZZh",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6259a294",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0c725d14",
        "accruedReward": "0c725d14",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259a294",
          "beginScheduleTs": "6259a294",
          "lastUpdatedTs": "62676671",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "151775",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259a294",
          "beginScheduleTs": "62676671",
          "lastUpdatedTs": "62676671",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Er4YF8CWWNEz4uPPSH71rDghB4QbHqsVNNDTtB3PbGYU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GhxgYSgUbgP66o1UmUmUJdGJMo7EwL2xkhkPEJuHyJ7V",
      "vault": "5yejG9bAt6kwKz66GMsm9s962KQh68qmyrzWmS4xi1L5",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6254876e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254876e",
          "beginScheduleTs": "6254876e",
          "lastUpdatedTs": "6254876e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a329b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254876e",
          "beginScheduleTs": "6254876e",
          "lastUpdatedTs": "6254876e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7wwHXabdt3giVMy6ox4h1oHzkke7HB3bAAW7cFv7eLsN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DP8QjG7UxcGfcazfCC8ge4nvF99kmNPxmggHqFhbmQXx",
      "vault": "73Vtw824czET6mhFLr1HVXF82AkhDsTHjiXYV523YF9c",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2d035fb9",
        "accruedReward": "2d0385b3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2e9Ku6ccuFJynMnMnkBwxJhvfnQFEA3ps3T4YtzUvLZC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ELNxkRvG5zbiuPsjNYyDsQNfCj3jFr54GFAGk6embfXS",
      "vault": "DEX7mijoHFaiGTPB2Rt1kBg6ZNEUkjE939ZLXrku8yh8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624a3754",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a3754",
          "beginScheduleTs": "624a3754",
          "lastUpdatedTs": "624a3754",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2482b5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a3754",
          "beginScheduleTs": "624a3754",
          "lastUpdatedTs": "624a3754",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "H8bfKBM6Gf89c1kpKjSS3PL52ezEC25JJ6aaXNmbfkEw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CsoTagmDfSmKXHSbP6enn8KLbAzrynRVhed4EtRWTHTG",
      "vault": "HDUkVPDmf14Xbj7DMWXZ8pytnn8HC77HhowbHQ4d9vBq",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6266949d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "03cf6594",
        "accruedReward": "03cf6594",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266949d",
          "beginScheduleTs": "6266949d",
          "lastUpdatedTs": "626a922e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08256c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266949d",
          "beginScheduleTs": "626a922e",
          "lastUpdatedTs": "626a922e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Cqk3TEFiqdJNJHyVcnN4nvATVbeQYZZ9dfLrjPhVEn9Y",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CQWYUvRAzAUtpohzHBSThZxY3pj4cRbCDzHiZtT4CqPG",
      "vault": "6XEQJrVAsb8pFMJxtyC1RMrSnkFJTXUyx1F6iQRxsAPA",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62576de4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "06544cba",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62576de4",
          "beginScheduleTs": "62576de4",
          "lastUpdatedTs": "62576de4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "174c25",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62576de4",
          "beginScheduleTs": "62576de4",
          "lastUpdatedTs": "62576de4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FHaDgfradKCPNRRkcamWe8JokR78HBZ7yGqniMhW6end",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "82sydp8brfwttVnXK1BSSe5Cp683RBdLpwUiC3fxBNZ3",
      "vault": "4woY1EJqWAEdUvVsXHh8Kc21rjwKX84rqr9mEVHDkqTV",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "095a3138",
        "accruedReward": "095a3138",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "72ecjQi4XWwAEpMQzT8EZdY3KFAuhykkYQWpTd6tLKD9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9DRPeoWswLRZkxoJjzmUzKdhCjz1PyzVpJuZGKJsxFEU",
      "vault": "ECLJChuAKkGM6vd6cfFStN8g3QbB269hMUgsjjmnj3jE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "625b857e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "5a3db7d2",
        "accruedReward": "5a3db7d2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b857e",
          "beginScheduleTs": "625b857e",
          "lastUpdatedTs": "626362e3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13348b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b857e",
          "beginScheduleTs": "626362e3",
          "lastUpdatedTs": "626362e3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "NRCNbSCKadp3j5iYbqnUhVDZMe6Fy8umBY6W1hmwk8Y",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4AhxtH1ck7qMwRQdC3p9C8FwyXfAhp21ZLsCWLw8WpqN",
      "vault": "HCv1yKndNc9hpPGVtWTJdg73A7LQm7Aaed5idSeEjeSd",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625df346",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1df36bdd",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625df346",
          "beginScheduleTs": "625df346",
          "lastUpdatedTs": "625df346",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "10c6c3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625df346",
          "beginScheduleTs": "625df346",
          "lastUpdatedTs": "625df346",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2RPqSCBk5nEmZ8xPCXM6HjJWk2xhRu2mCSwuW9U96bKm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EL44NVyfG4PW7wn21y2eFhDsRqfvmZF5mdh9yzwhDF4H",
      "vault": "Gw4Lot6ha1ZMbmwYkkmJiY6xLJmEb82dSZNYR3ZMrQWx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6261b47d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "13a8f4c5",
        "accruedReward": "13a8f4c5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261b47d",
          "beginScheduleTs": "6261b47d",
          "lastUpdatedTs": "6261b48a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0d058c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261b47d",
          "beginScheduleTs": "6261b48a",
          "lastUpdatedTs": "6261b48a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6z6LsmB8bgmNVAhCKUiBWKk6LK81VaJ4Am49yPvk8xMY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "44U3mD3eJtMDoZteRGrbtAwGk4rwSLeiuTnnDNr6CoLx",
      "vault": "3kgMoz7WPTydW38LXLEbVH8T56u9XaNRENYb8Z99MAcs",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "072b7678",
        "accruedReward": "072b7678",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BFaTsgjBPaD9LLPD4AdauQFnv9QS3vLJ32WAXWLwMqgW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "JBQrGZaUBAn5fKtdwM4jZFzSFyymkZ5G1NjZGv5XDwmC",
      "vault": "CL7rocctKWsKbt412j4fxp5CReLJUvvgL8da9htfUpcQ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0ee0b405",
        "accruedReward": "0ee0c7e9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6p5UfoWehJAw4ZaHLebrsSFAaVgzp366G75R8HP9R5ig",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CdSM1Zy3KGPeCHrhKgMckNhqvRLk18Sq6zVS9LvLJPvq",
      "vault": "GFChMgmdRmkAAEW7E7A8fG8pUvetWGLipt4gJF8SocQk",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625cb9fd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "35eec17d",
        "accruedReward": "35eee5a8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cb9fd",
          "beginScheduleTs": "625cb9fd",
          "lastUpdatedTs": "6265ea02",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "12000c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cb9fd",
          "beginScheduleTs": "6265ea02",
          "lastUpdatedTs": "6265ea02",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GF2JBhj35jqZSTBLaGgqErVsnmJtWofP3r8s9YuPc6JF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5ZUtJwBMkg9mUaXQ2XpxJVT8zvEQ2Wxtihwvtc8S1vX3",
      "vault": "42tSEwdKmHrwBYwpGFnvMEFayz9mT4Prfh8q9KaUWW5r",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8XXn8po73TC5WRfPh6t7VR8U9eQHbM6fXxtqTBVvcDyF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GP6wdEHmf9EvcVQRWb7XeaDWhijGT2d83qrzbpuwX8Ue",
      "vault": "2cu4GXhBQ9isRkGXwtAwE89KoMrM6VgMMfAbQyf1NDad",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62686b08",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "31d7aaf5",
        "accruedReward": "31d7aaf5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62686b08",
          "beginScheduleTs": "62686b08",
          "lastUpdatedTs": "62686b08",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "064f01",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62686b08",
          "beginScheduleTs": "62686b08",
          "lastUpdatedTs": "62686b08",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2CgGjAZMfzn83E4JPb9TNEFJsBDoDQavbWuxXvghwYh2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3GFD3HTwvnepEf6SAG3A7nGERPD4U7P4atgkTksezGgW",
      "vault": "AYyCiQ3wkMMra2AHKsAdKVjpfio1DHqMZcn9UraC8CrW",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624c63f5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "156c49ac",
        "accruedReward": "17fe0fca",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c63f5",
          "beginScheduleTs": "624c63f5",
          "lastUpdatedTs": "6266ec96",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "225614",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c63f5",
          "beginScheduleTs": "6266ec96",
          "lastUpdatedTs": "6266ec96",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Cq387oZ8SyuqciPFVnc8U786LrThmU3MKZsmtLZCfBch",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ByDz5qJAXT9KgDLm7Vxk9rtmpLoyNHKYm9uCZT5aZsVe",
      "vault": "FWcjWYp7HgkyHD4Vhehxs9UK6mvD524WZZu2ogdbuQRj",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "123454b3",
        "accruedReward": "123454b3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HTbh3MfTFPHRWxmvi28cRYTVEkZh4N58Vedzk6yZ1BzK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "J5bGaKJbTaVo8ceHouY1kH4eqmGb2ZdGoiuUH38rFsNE",
      "vault": "8giBc1ZniMxKKfTNNckfWShyFmASo7S3uHnxorEcNNYq",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62611c09",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "10b3d49d",
        "accruedReward": "10b3d49d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62611c09",
          "beginScheduleTs": "62611c09",
          "lastUpdatedTs": "62684963",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0d9e00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62611c09",
          "beginScheduleTs": "62684963",
          "lastUpdatedTs": "62684963",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DjFDiis1YBFbdSv5tkCerhLR8B7D752ERYx1FpQZLuk",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9EgxSxG8VCei1Q3dkgvhEEvyecS1p1UD99c4c4nsLQmT",
      "vault": "BeyEVceXW1FnRae4DF7yaHRMcX4EfVKGkw8sFSzPXnwc",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62537ad7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "033f27c9",
        "accruedReward": "033f27c9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62537ad7",
          "beginScheduleTs": "62537ad7",
          "lastUpdatedTs": "62571208",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1b3f32",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62537ad7",
          "beginScheduleTs": "62571208",
          "lastUpdatedTs": "62571208",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5uYVxkKnhUSEpDCDnq6vhhZJ9qNxUAQenK5TbWfHtbfi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6deUPqPGPGrDA4q2cKMyYWFNDnCpCeJXYCWFdeJ79pa8",
      "vault": "jwLUxsaVXDYB267eWYp7hUhbHh98D8cWxBhnTfJct5V",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62617669",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0662ac02",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62617669",
          "beginScheduleTs": "62617669",
          "lastUpdatedTs": "6268860b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0d43a0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62617669",
          "beginScheduleTs": "6268860b",
          "lastUpdatedTs": "6268860b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FMmaHxs2j6fBi7SiMwp85YhXkcSLerUtQ62d2tXSNeD8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "43VuPmbAcytrCjtVHcySpcPZKYDgWfC4P5qrGACHsaEj",
      "vault": "6QT5tCRTzMM18oZxHL5XDTpgc1J1QPGYB5RwNYVCpfMs",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625aabbc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625aabbc",
          "beginScheduleTs": "625aabbc",
          "lastUpdatedTs": "625aabbc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "140e4d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625aabbc",
          "beginScheduleTs": "625aabbc",
          "lastUpdatedTs": "625aabbc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "32i9T1PPSU2RxS22fuxeunt8Z8w4kJLaXkRgsTraEPQE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8bxTZnGssYBABa1oA8PQqWmkxrp79zdg7oBMxh5XsPdF",
      "vault": "GpoCd22Q5EaV8469QgTm82UXSSBfJ4c9on9t8LujxoEi",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2e1f4bf9",
        "accruedReward": "2e1f4bf9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "zuv7R9iu6qaoowx2a7WZoLhYEAJnf85ERBBz2WCG4rC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GAFLx1PRLc8UVc6tBbKH3KuGf3hPYahQ3SnnavpZQkQG",
      "vault": "9CfwcFSqt9fUSWwG5EzMmhdBHrkWycFe4zmZLX9D6N9q",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626a6372",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a6372",
          "beginScheduleTs": "626a6372",
          "lastUpdatedTs": "626a6372",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "045697",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a6372",
          "beginScheduleTs": "626a6372",
          "lastUpdatedTs": "626a6372",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EUSXUUXXPHQQLVD9th8bTtir8mJR9qo3TdMVZYiww9nM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2ax9JDUPEwji3wYmmmmsbVqMsjdHHbUuux8d1ecWUqjK",
      "vault": "Em4humNjtdeZCat37GAmohQQ5UtEZZga1qGmkhoYbLeK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6251e886",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "07111fa9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251e886",
          "beginScheduleTs": "6251e886",
          "lastUpdatedTs": "6259b918",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1cd183",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251e886",
          "beginScheduleTs": "6259b918",
          "lastUpdatedTs": "6259b918",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GVwzEuumECKaeKzApnJQLvmbZEf39XnN5VD2srD8JGyE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Ac1fahhzrHCfWgdN62gUs2PL5rydH87qwTEHoQjNMQK6",
      "vault": "245iZr2DrDj43jAyh2XyjHKFALkGBaLFiVuPSbcNVQEQ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5Tqq2NnfUU9C7evvJeyae4CKqkZqpFD9DJ1NoogPaY4o",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FygvstGM2ZZZD4HRhpeeBqwS7kVTK311TNnEhTfjWg3H",
      "vault": "FGvdk4Q5K3omm135pLfPTmnT1FSgCkMWEG4kGzS183ro",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HU5MXJqZukCm9xMs2owwkLRQooPPJfboE64hTJfUVeR3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2XA9EiG2qe8ZSBGZtVwSVVYBRNbhLFGFQ4wPtW946sBR",
      "vault": "7tBx1JitHf12ibcpuH7U2ExhYzcWnjYajxy9XVjwyYSQ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0dd7906a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "B9yqNnzyns8keEGDQVbbQuGhyomXFHoZhtA9pcr634bV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9ixrBE3dkqCqKSPz59fy6ApGsBEwGCa8pF45agPR6CgK",
      "vault": "AP8XvKrp7RCCBNmdZaPjFFX3ZWsMrma5okBKxqk6539q",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62609a19",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "11455e4c",
        "accruedReward": "18a27e2a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62609a19",
          "beginScheduleTs": "62609a19",
          "lastUpdatedTs": "62609a19",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e1ff0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62609a19",
          "beginScheduleTs": "62609a19",
          "lastUpdatedTs": "62609a19",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FkeFXDcMU1BUz49tLQAoiyr5ieX1mEQizZE8UfGTQVS7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3ygmRWxpvcVsgx6FHETahhgyc5663vm8JPC5F3TXNaBP",
      "vault": "9UWtMwvN8mACxnNDHAhLe7LZPUdZbz1UPxBxrKMCHvYQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624eb690",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "137dab80",
        "accruedReward": "137dab80",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624eb690",
          "beginScheduleTs": "624eb690",
          "lastUpdatedTs": "626444bd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "200379",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624eb690",
          "beginScheduleTs": "626444bd",
          "lastUpdatedTs": "626444bd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6HDJa73243yHbiawrrEZ7BKAoGortfVhteuq1CTwfjsE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7EivA9RH4ykBjkKhQjvjNnumCkHB5JTaKYdTTQyH4GcP",
      "vault": "21nuSA7j3u7NPSrG9G1eHqXfaDssUKbV7zVpxenugKZ6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624fa9a1",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "072169f4",
        "accruedReward": "072169f4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624fa9a1",
          "beginScheduleTs": "624fa9a1",
          "lastUpdatedTs": "62578c64",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1f1068",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624fa9a1",
          "beginScheduleTs": "62578c64",
          "lastUpdatedTs": "62578c64",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4P1vKXDfvjtuXi3xLaWegSfrUefGickWfevWqR7WFAp4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HvSXzveemNKcFBNALHzo8q379BEZJU9kPsGBX8bgxUvR",
      "vault": "3xJznKjTiGimBjsHG7hMHVe5T5uYdsDVST8wQvDRX7EX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b6962",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "072e3a7a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b6962",
          "beginScheduleTs": "624b6962",
          "lastUpdatedTs": "62535a51",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2350a7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b6962",
          "beginScheduleTs": "62535a51",
          "lastUpdatedTs": "62535a51",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3JX4xx4FS8pV9E9WDQmAAWsZGVYpT2HMR5XeQ5WQX1pX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "87KHMn9KkPaowoy5FbW44orjGiuSKH9i5iQrZseAgG6N",
      "vault": "GcczUg2LBTpRZf9H8h1G1B5fFzFkTbevHaGBgoEFTTnR",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6256dd5a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "025e5e08",
        "accruedReward": "025e7928",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256dd5a",
          "beginScheduleTs": "6256dd5a",
          "lastUpdatedTs": "6256dd5a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17dcaf",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256dd5a",
          "beginScheduleTs": "6256dd5a",
          "lastUpdatedTs": "6256dd5a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Gi5egPwfcf4rbc3Kbhc4McLVLna7UPTZ1RTz1wcCLT9N",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "85ULLVeiVEVjZ2toU6i2dAH5mpg8WAbGNK6hkfCYiyAp",
      "vault": "6WAuiavfdorRN5qhwkvAyZHefxgqVsh1ju9a8ZdfZuPt",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0f5fb623",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CtXxtTbGwu29fBdvAk4CQzUT27ucGu1GUFntQvK6LaMv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GaUDSXWdwHZYjhR77GzaD6XJwYkWXN91MrsdSMQyTNxk",
      "vault": "W5SiUpHH3YbnyFuG6vB4swTXjtVJ7jthZrPyssB9dXN",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625adc8e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "02640c06",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625adc8e",
          "beginScheduleTs": "625adc8e",
          "lastUpdatedTs": "625d816e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13dd7b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625adc8e",
          "beginScheduleTs": "625d816e",
          "lastUpdatedTs": "625d816e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "nET3v6r8CGrne84HYu8vevwuPtFfRLDEgM9pCEh8jpp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "G8rLaC9rfvzosLv5DYqb37ceTMiuYjuFspd3mjEYfQdQ",
      "vault": "Fodusp3FZKAWQbH28XK8avvxKjQCoxrX4BAhAkkCoAFu",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4UabPiMxvivDHPN3eDPfoQNmNY1gUKEZt948t1XM66ck",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FzGb98VyZCnwWMeEemwAy6of7r6jHM5cP2saPd2UNbNh",
      "vault": "28yFo9q8cbPmv6fL7qS8zecEKM6e9LkciuCNFTMTF5kf",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "057abdec",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CU4ZcHWoPc3wsPT9eywov7ACVaw4vMKQoqVphXDjqs5r",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Hi1M7JXY9LDiE3Hu8k5tSwMCAgfUmp7Jzb1bKG3k8bJM",
      "vault": "DxM7eJsXvb6kT5LMiZCxuKNdwrQ9gcqgYMk4aE548MTX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6255c98a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1312927c",
        "accruedReward": "1312927c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255c98a",
          "beginScheduleTs": "6255c98a",
          "lastUpdatedTs": "6269814c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "18f07f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255c98a",
          "beginScheduleTs": "6269814c",
          "lastUpdatedTs": "6269814c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6EGtNRSaeUmpHCnHmZ7iURY5AxuoFoPBppvU87xxjcAK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "J7PWRqgk9uq7PRCaXES7YwVgBm6Du62HcDHX9GBuDbs2",
      "vault": "DQTZndRwy5QhU8jBL3C7g5PSunT1ffazt6f3Wkn21v7G",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6256d661",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "10b572c3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256d661",
          "beginScheduleTs": "6256d661",
          "lastUpdatedTs": "626950e5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17e3a8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256d661",
          "beginScheduleTs": "626950e5",
          "lastUpdatedTs": "626950e5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4EuwrHZu8rQ3ZEFYYeNVY1aFWxY2NLvErdyjvXBn12AU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BJPbgnaXeavTCYYV4ZtLxk37gFSfSWnyVLAycL93DLQZ",
      "vault": "3eppQGna8BZH3EmcZKN1NUgHw2YoogXkkAFtZk5h5JJS",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FpFkKYA9NJY1YDrXPFxCKpERoKKGhYF9hsNTo7oi5AYy",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6N6jrRfzGXhhzLBYnD5mbQophGaqT4siFifSMvjidrr5",
      "vault": "Hp3TCLQXG9kwwAdkVHMU3cam4qL2U87MKYE2S8gsUq4G",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247ad68",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1ed4ade4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247ad68",
          "beginScheduleTs": "6247ad68",
          "lastUpdatedTs": "6269c614",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "270ca1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247ad68",
          "beginScheduleTs": "6269c614",
          "lastUpdatedTs": "6269c614",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9hk2F6RPzfmezZMzZAkv2jPffHmTJq1VFCYV1uACqKsP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ForvTDrxR4Y6DNkrz3kXUCSk7Y1ZhFy4kxhWiDT2LaCS",
      "vault": "47RNG9rLC9uEaDsRjqEkWsvEBz8S5V68AFCFmXwWfjGu",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625f4877",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "29d58788",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f4877",
          "beginScheduleTs": "625f4877",
          "lastUpdatedTs": "625f4877",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f7192",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f4877",
          "beginScheduleTs": "625f4877",
          "lastUpdatedTs": "625f4877",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BbhE6Vb6iFe1hNTvoGSP6YyhJViVwLgeYD1XNrKzWf5k",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7jkecaG5fjRzBgR6w9HBTWjmDtx8ShiTc74BKAgyKUku",
      "vault": "GPBHbTouXkuXGXy7kJuCrmri5VRjzRBV3vFkJe2U7xaC",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "39c0f0a9",
        "accruedReward": "39c109fa",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FktcC2LtenQYMcfTB3zVsW311xi5ac7nULaYsycBWekq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3K4KeHPRs66u7VAHYXx3sX6733THHNcybyQzSrTyHNZq",
      "vault": "FUHTHo2RMX5DMadqAf4ze7rThs5GVB3tGWc5fz6Hn6yR",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Lv9FePnDkabDQcTB6oG6UhaE2pJ3chwnECdhyfHr8GR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FejDKo6KzZxRm2FjiyBNR4RApRnCEgDR3f27h6U3gFiU",
      "vault": "AthXFP6F8F5JuPb8RrEyQXob8nAkVP2a9rq6Y5RvjfZT",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "336d1973",
        "accruedReward": "336e8154",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FkSh1MuJByk4nAKePty9ukLRGphUvKiXm9Jh7Aiw5k7w",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3AzPvMViFxTvgq3qMijwdisVdrZbQq5RKWpZfpVtTaX7",
      "vault": "6vS3Jrg3DNLU5WtCipXrruQs1Xw6t5jHrAzAszocDaRN",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HuqhZMiW9UWBJbweRsuc3kPd7wjzEtu4uy5qnzksRRvg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9AbKMuQinZyuNgsD1MvBcPy4448VJS6WpVAFeSksum1g",
      "vault": "2DLLfSgJBap5gsfMpT5iHsHZiQrTEUeSARnDGrCm64Ti",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Bt6s4BwgELT2Jra38TXiuSY5g2wxdVuikwYTfSBwjVdq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "k7HRaL5EbrpNMSGxkmReD2k7UpFUuW8JuwMM3tAdtDd",
      "vault": "BWaoPMxZc5ZJiqkH93cVo4jP1F4Zr18JLm6VzF57a5ad",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626096fd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "208d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626096fd",
          "beginScheduleTs": "626096fd",
          "lastUpdatedTs": "62609721",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e230c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626096fd",
          "beginScheduleTs": "62609721",
          "lastUpdatedTs": "62609721",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "D6XHQv7q7jF4t2TWgRRGJmUpDZhjALyDkCJWVRPeHAVZ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "JLHAg2FnDdTuwjfE4kAbQtu61R69m6NVjicuZFtP4xq",
      "vault": "CRY854CFQHTRva94JfPSweap2Gg68UjKaeBACwVpbXd8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6247766d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "67e01d01",
        "accruedReward": "67e01d01",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247766d",
          "beginScheduleTs": "6247766d",
          "lastUpdatedTs": "62642e9d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27439c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247766d",
          "beginScheduleTs": "62642e9d",
          "lastUpdatedTs": "62642e9d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FWm8QKYGtt1JqVHY76m6hKUFQBAsSGyZ7X12M2aZsy4L",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CHR3CrdB6WNHyANpdNGQZpV8uTVAGBhTJiNLXvmYsEag",
      "vault": "3PQNUPFS4wK1Bs6F2eK4h3eZr5G1HH9zni9L2wTpqetQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6254e0e5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254e0e5",
          "beginScheduleTs": "6254e0e5",
          "lastUpdatedTs": "6254e0e5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "19d924",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254e0e5",
          "beginScheduleTs": "6254e0e5",
          "lastUpdatedTs": "6254e0e5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EDH4DKgSLEksmwbnCPKinc153VEmqiSt2kLE2WJWYTRD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BijhvBjhMv1HRmGu6KrZKAqaZ2Ka8bRj5HH7eEBW874a",
      "vault": "41QnWopkgZ2XTNV9p5Hq4fWwbtrN71ieWLBHev5DFUxt",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "364afd9e",
        "accruedReward": "364b16ef",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7LaBKT7fYvbqfK9mgKvbWLasyHGPiGyGxpqtPHeAFkaS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DiX2rtHeZHUNBqruHA7MQGU2i6YYpox6nhxehnEBgNw6",
      "vault": "3w1mgmUxxdsf6xeRNqgCagurPdKdft31BrGh1Xri8x4t",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "6255b3d2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "ad1f3cce",
        "accruedReward": "ad1f3cce",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255b3d2",
          "beginScheduleTs": "6255b3d2",
          "lastUpdatedTs": "62675296",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "190637",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255b3d2",
          "beginScheduleTs": "62675296",
          "lastUpdatedTs": "62675296",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7TDNa1icRe7S1ZqoMUHEoDY7T5q2NKfzmuyDCz2zfz2V",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FheAE3uJDZXrEqUnT8iQfvtgxTgudA2WSRda7eswA3GF",
      "vault": "AigE7maLvLtwV9SdEax4VDhKXHAHXQh1TBQobZMJ9Tvc",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626af90c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626af90c",
          "beginScheduleTs": "626af90c",
          "lastUpdatedTs": "626af90c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "03c0fd",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626af90c",
          "beginScheduleTs": "626af90c",
          "lastUpdatedTs": "626af90c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8Jf8EeN9LgV2KUd22BCAaNETgTtARXKBqwMN9ZncT6Nr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HjLwz79x4ESwiwFYX8Rrw79wdAhAkokk9UjCTkwWzZJ5",
      "vault": "4T9V5H82uNq6Pffdc8RSvuAhAPaGwep9VnxXBcY9Sm2i",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247a66d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "23c84bc9",
        "accruedReward": "321498d1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a66d",
          "beginScheduleTs": "6247a66d",
          "lastUpdatedTs": "626357b4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27139c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a66d",
          "beginScheduleTs": "626357b4",
          "lastUpdatedTs": "626357b4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5YvcQpoCFQ7s4bowd5VuVTC5WUd7PqSPeCLRcFmaL3jW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "33rs9DfoEJSjjc3b6CgtWywNNXx2tLPaULK1gqqAmZnY",
      "vault": "Gpp2Fngj72eLjj78uqQ8beh2hNYDK25N7MKFhwWCu1x8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6259e84a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259e84a",
          "beginScheduleTs": "6259e84a",
          "lastUpdatedTs": "6259e84a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14d1bf",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259e84a",
          "beginScheduleTs": "6259e84a",
          "lastUpdatedTs": "6259e84a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6PiiWAR28uFDtAK4BbVvgSUG43P6tJjacb9mugcD1jQY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FbFG8H7FHymBssr8Jq2DqpxUgGqCQRuCnsfHfHWoG2pj",
      "vault": "7aBdfCUt9r1KvHEsQxifiHj4iYQaic7dtScB5eULEezk",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5diQnRa1xcPiAuqqcjLib5qRdSmLjRwxTtnn3uLKsDdD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8eeWesjTSLG6Hdy6GV8bwjrBCEnwY15LWTkyHtGtsUB3",
      "vault": "zVXZBQvDxz3nxiGDfowf3g5QgJffoESb7MmMkfjJZ8E",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "KZyfNJTNF6u7ZVPUm4j5Q1oL25RYaXmSeFoyt6L84tk",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Ga8hLr2Y8kFeMDwopgREtdfAow1nKwD5pforD97nuzPx",
      "vault": "7aWwsD1DE7w7htNeKRczNuc4wNXwUuyPxFU5AmVieS6N",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "6247746f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "390e07df",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247746f",
          "beginScheduleTs": "6247746f",
          "lastUpdatedTs": "6251f89e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27459a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247746f",
          "beginScheduleTs": "6251f89e",
          "lastUpdatedTs": "6251f89e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FSM3tdZW71iZQ1w4uVXn4WZekVFK7rYWmmhUXADb1Cpe",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9H6C3GeeqXmC82WHW1CR49T5WAZ3MT92bVrdT6gkMMit",
      "vault": "83Q1fGFrKwLchn2iwJsBFkxR5SdQvBvrRQKrwhACUNFE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "05",
      "rarityPointsStaked": "05",
      "minStakingEndsTs": "62594e16",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "8e006319",
        "accruedReward": "8e006319",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62594e16",
          "beginScheduleTs": "62594e16",
          "lastUpdatedTs": "626a7501",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "156bf3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62594e16",
          "beginScheduleTs": "626a7501",
          "lastUpdatedTs": "626a7501",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6e8ZKE17529v2FtrnyH5Q9LR8ghb9ddkpeE9KAZLgT8h",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ChmBuSXV1Ytj2FGckwyLSdnXCEjfL3Hf8c9xeZdUQx5j",
      "vault": "H9cAFXYK5f1itWaJt268j4CZfYGRTaRZJHMK9rerQmix",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6265eba8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "4d14e2",
        "accruedReward": "4d14e2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265eba8",
          "beginScheduleTs": "6265eba8",
          "lastUpdatedTs": "626620e2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08ce61",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265eba8",
          "beginScheduleTs": "626620e2",
          "lastUpdatedTs": "626620e2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BJppNjDtpETn8vjaiLi2FSkqEFBaBheokLjGE9rD3ipU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FYTbGYnj4xkyAVKKdueddPE1XTrHQM1A5cqjBnPrL3TS",
      "vault": "2oPv7izioeVXE2BviTWb5hpcAiZAAtvgUDC8dJDYYggC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62696178",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "32098e3b",
        "accruedReward": "3d3b34a3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62696178",
          "beginScheduleTs": "62696178",
          "lastUpdatedTs": "62696178",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "055891",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62696178",
          "beginScheduleTs": "62696178",
          "lastUpdatedTs": "62696178",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CNV5sSiHAo68rWhbarw1phCd6CnYpe6dAMHYuSJqfAcc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "JAeRnMQAGFgQtM8BCKHX2N94GniMJ5uxb9wHkvbBRtnb",
      "vault": "E9NiKNuPcAtBpE46azJBV1dgfsde69oD2EnrRfN8xxQu",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Cbrke2BWoCLGZ7r8V89JMM66UnJTzAtgmJ11hLZ93MQm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BwWEdFwpLVAEqr6oRPDCTBediJriiuNjx7o6RyubGZVH",
      "vault": "GsitwTCHg8FrCq7wP23y5FDz9NZjGzfmywVFkiGyX6in",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62543f58",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0c52285f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62543f58",
          "beginScheduleTs": "62543f58",
          "lastUpdatedTs": "6261df97",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a7ab1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62543f58",
          "beginScheduleTs": "6261df97",
          "lastUpdatedTs": "6261df97",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FLJE855Nf1f4MMCz4FUpRBkoCDUitYEiBJBEVa6UBd2x",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2tv6nXYWpx1wSdYXsQYXYAHFCMKaGmDvmtJ8sVQVK1dP",
      "vault": "4RRkqwWuy3ZjfDKufuPxezdi4cVBXBTQfFmSQ2WeeZCT",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624a4d43",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1cc77753",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a4d43",
          "beginScheduleTs": "624a4d43",
          "lastUpdatedTs": "625a372d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "246cc6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a4d43",
          "beginScheduleTs": "625a372d",
          "lastUpdatedTs": "625a372d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FiMnEnSLsb6ySoyiDznieU2QbVpiRvkEqZgAvMwGTWRz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3Wohiq8jiiAu8jBYTwcF9dw8kt6vgPSaBGhjzaUegs99",
      "vault": "2Vhy59HeqVHSopfieSjUuD15nAr8SMDMCLn9Z39shRZu",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625be83c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "18e07d1d",
        "accruedReward": "18e0f0da",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625be83c",
          "beginScheduleTs": "625be83c",
          "lastUpdatedTs": "625be83c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "12d1cd",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625be83c",
          "beginScheduleTs": "625be83c",
          "lastUpdatedTs": "625be83c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2xHdgPiNy6xr7Zodeh4uhY3k6mJRiAwkBdqKvYSp9ezR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6rybge3PJfytNQv4BAqbmh8Mg9ZaKkdBct2aN7HXkus8",
      "vault": "EXxTpPyqG3TfFowNX6hj3A9L86ifJxzVN3U3XBEiALYq",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "01050b9d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2QRvTy8Yc5ib5vSZtuwsgQYtW9mWknU9SHw7XwcAHsQa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7CFnhitfiyjJS6286Z4UKFZQL4mqWiUSxdgcH6UCk3UF",
      "vault": "BE9WyCB2XFJU4utrMaP5Vj7t5ew29Xn58iwpTgGFBe1Q",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J6tLqGNuQXxPaNqhxkJ7cxjXqZAKzwqgz1ZtjDV3Mrn3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DT2dL2VmQHxdydYGmMGq2xzCYmhkrH4KEvHuCX1NNaW9",
      "vault": "Ga1yKsPgF2UXfCVG47KuibPEumu6qvFNe5MinJQoTZsk",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6268807c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3b0959a0",
        "accruedReward": "3b0959a0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268807c",
          "beginScheduleTs": "6268807c",
          "lastUpdatedTs": "626880dc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "06398d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268807c",
          "beginScheduleTs": "626880dc",
          "lastUpdatedTs": "626880dc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7oknsTVziLwx4hsjtiib7cSrJjqeMgKwVeqmo9jmE9s7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HT2G3sXz5xrq8bmqhLHtYTSzmyjLcDu3mfPzoU3ySmb3",
      "vault": "3KwXBkrtWdsnKosJjczQbzEb6yBcJVwJjqovCMU7ngkm",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Bh3gz64g7z7v4VYqoKyTf7EoEgu3PNtC5uY4fUFNrbo8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "yagUAmuvcHgq824fNwMAEUsXhx1ix8NfMtQTGBsqap9",
      "vault": "4tHQ8C858nFCHmymFQAEvV9cEPep5GXYZJLtukEyx3yL",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0e82af5e",
        "accruedReward": "0e82af5e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2uiF9DKjzpzVofQNnNd6nKMaTG48P9zqTWwPWp54yvAF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5d5mXxnXBrS88rzkFKUVzPp2NgkdnxB6kM4Sf31s4Jx1",
      "vault": "PvC9zweBLmpiuSLvDERfNMxHjgQdA9veC2YY3ZAkeZA",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "241d298d",
        "accruedReward": "241d298d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7oAGmSva8CwmugCZfnc4sVzm6XeRhENQNNaerH3pfo2x",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9s73BsueXReFqRGPyxkPUk6ZqzTTxJ1vf9NtTYDCTN6d",
      "vault": "9WgoTWeWEtPRWRg7KMAQ3ao7NeLiD5SF3KwHsReyrpdE",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "6254f737",
      "cooldownEndsTs": "6262f0c5",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "717244c5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FrEdn2hCKc5SqRFEs8VtTkU8wU65n9fGGFYTTfBq11zk",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "G2Uc6v2Vg8XLYKU4RqAoyGYtyUeCXbs6yNiPrvbSVzaF",
      "vault": "875T39JhDsCcQ2hWCjYdjPSpDZ6DceK4A8TwAmzFbxLP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625ee7c3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2a154440",
        "accruedReward": "2a154440",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ee7c3",
          "beginScheduleTs": "625ee7c3",
          "lastUpdatedTs": "625ee7de",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0fd246",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ee7c3",
          "beginScheduleTs": "625ee7de",
          "lastUpdatedTs": "625ee7de",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6G2YLDNfskfV7rSeecmCpDj6jqXbR1K7pFGovfhvCSzz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4a6rDUruZip86uYjns2hZQveNLdmGYEv8Z4NkseF2ygv",
      "vault": "DnPbSAvPoinXxDm7LRcNQN9DZEWdpydAfopoYRKicHdG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62673783",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62673783",
          "beginScheduleTs": "62673783",
          "lastUpdatedTs": "62673783",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "078286",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62673783",
          "beginScheduleTs": "62673783",
          "lastUpdatedTs": "62673783",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "94QcpuLk7WVD1KfwYBeoYdP3LpRu3StG6izLGLXJRMd8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2S5JDnseFzM3r5ZQ9LyGYZGuduQXQ4SkXXtvRbuD9qyC",
      "vault": "FSWWxgeQZ4xkqHq4LwS65iRR9gQXwAwyXM1cByzSHmsb",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62476cb8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476cb8",
          "beginScheduleTs": "62476cb8",
          "lastUpdatedTs": "62476cb8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274d51",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476cb8",
          "beginScheduleTs": "62476cb8",
          "lastUpdatedTs": "62476cb8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "29y4KpE3kHSUDMJDzs7fXY8eXMXj52pjE2hs1ZhXJ94d",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3LqBQM4B4c9Ge8PCCJe6Utk8HuT15ypSahCr5HimJVh9",
      "vault": "BsgNc44YuBt3D66WPRV6D2BEnrETmtRv9G6WuyrBQRHi",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "62477356",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "01d294",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477356",
          "beginScheduleTs": "62477356",
          "lastUpdatedTs": "624773ac",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2746b3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477356",
          "beginScheduleTs": "624773ac",
          "lastUpdatedTs": "624773ac",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BtYDwhM6EyYh9LSq7G7D2FLw6DyQv5tFNBDe6LEYQtwD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BYgxKwQAdex53zDsAgZCiwAhyDT8vfHCWdfoAFbiypM5",
      "vault": "ErhfqbAFLaj4B6SPyncNtWEmBdbkZZ4NDNq5wryh9uky",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62486470",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62486470",
          "beginScheduleTs": "62486470",
          "lastUpdatedTs": "62486470",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "265599",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62486470",
          "beginScheduleTs": "62486470",
          "lastUpdatedTs": "62486470",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8VcBk6WTP9wqpKiSuqvo5EWaCBhmmEDp18rptMY6eWRV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2mx2iBayzQKBCuDEKdn6vEzBNqspZDZnB7mwec3eRiYr",
      "vault": "DLXZ6Z13pz3zATBSVVsSys2QfU14auuzPZgqGZrrKaEM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248cae9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1d8dc6ff",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248cae9",
          "beginScheduleTs": "6248cae9",
          "lastUpdatedTs": "62697a0e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25ef20",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248cae9",
          "beginScheduleTs": "62697a0e",
          "lastUpdatedTs": "62697a0e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "A9SADFVvbrzL48WDBoG6c898kTHk9pdyFAgqVrk1H6RV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8bRE2jKivGzgz9MtcBFNPTjE1tae4fwZGmrc4bAQ8GZd",
      "vault": "HT1EHPN67zb1dKuuZVf4NmgzuFHPqK6RW8bKP72jxUyz",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1a6b24d3",
        "accruedReward": "1a6b24d3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FSVpz3gSf2Ligyv94H4pPKqeFBFyMV5LVtJiSXrjZSXh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CaxoNuumVUK1rGiRMpXdAoiTJoGMM7rUmPUk1hWo4HDf",
      "vault": "4CLToZ4JZHwqLTg4MsCCqczWAegGnxDvbjjRRaEA7zCA",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "05bece54",
        "accruedReward": "05bece54",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Et4CyhWuF5eLrhAByoc2Az2mwC4B5wye4DQoQPvJoL8F",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BRJNKPNDtFfjmNKdZmwYgXgFshsQxu4AURps32rfg5WL",
      "vault": "3EW1qe7CLqitBu4VJezMGqpTnTw13bRvzfhVpb5QvJ9E",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "62547691",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2adc5745",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62547691",
          "beginScheduleTs": "62547691",
          "lastUpdatedTs": "62547691",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a4378",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62547691",
          "beginScheduleTs": "62547691",
          "lastUpdatedTs": "62547691",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9BX2rKuhs6YmbiAomyeupGGX5FaJQHpaV4KUCVBywGH2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3wAp6dYFrRH9eszWo2u2gTdTK3LeY5HxhXYnTwBDS588",
      "vault": "EuM5ymcG9MKPgtXBiLexDsaDKPWrvDxV9ZgDQNCUcns6",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CdhyKtMyo1nXhe7N9KXhwA771EBTHyVqowbCpTjR9pmi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7zHZV3HvVnHs8dhHuqF3ZpbQdVMeTuQvYNpuaeX57v8z",
      "vault": "6NFmi8D71yGyBwcZScBho7se7RdRdynBHBWqmRmvTSjZ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625455ea",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "027a4568",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625455ea",
          "beginScheduleTs": "625455ea",
          "lastUpdatedTs": "6257135e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a641f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625455ea",
          "beginScheduleTs": "6257135e",
          "lastUpdatedTs": "6257135e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EEdrY9MUCbBXWgnU1dPpG4FfLRYaphBxSzifASZfvm4G",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "xDt6EKfZdyxHNvp3RW6CsfbtQ8pE8yKnzSgyGi3V38C",
      "vault": "DFmiCebJtgWTEDvTdkcyLPB4yZTErerQXa27QCCstQ8S",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "62606b2e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "22119b1c",
        "accruedReward": "2224e7ca",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62606b2e",
          "beginScheduleTs": "62606b2e",
          "lastUpdatedTs": "626070a3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e4edb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62606b2e",
          "beginScheduleTs": "626070a3",
          "lastUpdatedTs": "626070a3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GcT7rBNHNSFdbpEZo8CQFtpJjN7orSKB4kyRFZDCpgU2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AZ92naaDEy3aoMdqCtNcvMzjs1GnthvaV28WALzg9Qqd",
      "vault": "GNzLGQLnd4Sf4zDKNiApW3HZEw6zywfSy8MtGayaDAnW",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "D3FQmpeqJETNBFRa2iE44M7RmCtdEJx8nErYxsk2GFpY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6EkVnsmTgouoHbEkGJTXMYUiddau6ahMMA6C2Pe4qR69",
      "vault": "2Fgn2tEbuCnCP3piudWATuKmngknPMw9D7zpSSpmnePd",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62555e9a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "14cc",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62555e9a",
          "beginScheduleTs": "62555e9a",
          "lastUpdatedTs": "62555eb1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "195b6f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62555e9a",
          "beginScheduleTs": "62555eb1",
          "lastUpdatedTs": "62555eb1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GWDMJvXM1fH6amC7do15NxMjMh8Am4xCmmkCeGg5WeR4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "74nFe2oyU74RWpAXHnaKqRdCe2sLChVXMsZdTJ2mM7FV",
      "vault": "E1k4bKTtHkkoLLNmvhTTa94CJMt9rtTxMT3eyVueggn5",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2596c1",
        "accruedReward": "2596c1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5dNkW3yhjfC5Lq89sLRUsXaGFMna3xFog4jQsDrgzVT8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GBrxWi3fGmkX9Hnwt4hZufs4ErbKmRhgZpedUF4oLm1x",
      "vault": "oZ2sEs1W61VAASoQ8yrxpJ2VFATiYRdm4W6fgA5QE49",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6249fa0e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249fa0e",
          "beginScheduleTs": "6249fa0e",
          "lastUpdatedTs": "6249fa0e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24bffb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249fa0e",
          "beginScheduleTs": "6249fa0e",
          "lastUpdatedTs": "6249fa0e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "C97C7dXbE2YC5MyPYbkqq1NB65RAEGzRtkFq6rQpUDaJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2YtMUjwGTpqYHWjnGPPahop1FG9GHovQuzc9HfxjYoGs",
      "vault": "7h9nP9RvNv71Y5p4A489fqC2Ss6rLecaZBzXSyT9BRy8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6252e47d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0abb4715",
        "accruedReward": "0abb4715",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252e47d",
          "beginScheduleTs": "6252e47d",
          "lastUpdatedTs": "6254438f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1bd58c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252e47d",
          "beginScheduleTs": "6254438f",
          "lastUpdatedTs": "6254438f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9RYMhEyRbAX8SsF7TaoGWSMSAVC6tTMiLvcuBmrWuPAY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BPu6YgKiZApXk9ULSxQVqgjSnp96jtJDFjhKvGWtsgFt",
      "vault": "AeaUfGpaAu7P47AKnTfgqthYDC1YnJ9FVyBXvbYAm8ZY",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1be2975e",
        "accruedReward": "1be2a237",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4fJfXHQUTAeX1tyXSJyrzPGFjKgW6rEykKS97CmNzERz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8dzcWxtGABZW9d2fPxKZv6M2fJARC8Nf8yeRDwRtH63z",
      "vault": "487jWf6iiCGFpT5qsGrHjcBWynbQQfpJrLsRvWL4ae46",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625f532e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "05feaab5",
        "accruedReward": "05feaab5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f532e",
          "beginScheduleTs": "625f532e",
          "lastUpdatedTs": "6265f466",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f66db",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f532e",
          "beginScheduleTs": "6265f466",
          "lastUpdatedTs": "6265f466",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9gBHopzwTx6Zsv1K2A3SEnm5N2hkSoomhuHGpe1ZoZuL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HwMidRXg83Vh5ZUVmJy5cxKak1DojQ9gZYDi19au4yc6",
      "vault": "5MVL2sBBrQRJvRAW8ZXDzXUhvxguFyVQUQ2ddJGfdBQg",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6251e6f2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "64fc7527",
        "accruedReward": "64fc7527",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251e6f2",
          "beginScheduleTs": "6251e6f2",
          "lastUpdatedTs": "6263cf35",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1cd317",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251e6f2",
          "beginScheduleTs": "6263cf35",
          "lastUpdatedTs": "6263cf35",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "H8dBy9AWvX18XMQ1673P4Mf85kJMpoMQHGSkCP5k86n4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DaExu33nVbwJAFARRPcJMkVDKBjBNPVay1o1ybzEeG7a",
      "vault": "BitucdB9Mod9ZTsxhb34nVzhsH23uEDyvZXWcFxaC264",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625c304b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0aabb54f",
        "accruedReward": "0aabb54f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c304b",
          "beginScheduleTs": "625c304b",
          "lastUpdatedTs": "625c304e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1289be",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c304b",
          "beginScheduleTs": "625c304e",
          "lastUpdatedTs": "625c304e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DyzEiqSk3wDbfwtve9vCdjhxFck5WeTFMgHvnxWp5YKa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "42JDUKk3vh2FXiwoPgTRD9v85E2QJu2hyps1PiP63su9",
      "vault": "3MnHvZw6EfdjPxRJcKYSd2ad9mWbxSowyEX6ZEEFPLGH",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247cdad",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "f53175",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247cdad",
          "beginScheduleTs": "6247cdad",
          "lastUpdatedTs": "6248dcd7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26ec5c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247cdad",
          "beginScheduleTs": "6248dcd7",
          "lastUpdatedTs": "6248dcd7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "TZNgjHfkHovUW5C4yp19J8vpLZztcatYomhJDzH9yFi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7y4X7KNXvAqSYkS31AEGhg2NHdAARstYx5tsTnp64cjp",
      "vault": "Dt3RgSd2hhv2pwjAGPUiH199DgGAg3GNtSNXqa5nUBCr",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625005c4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1697b699",
        "accruedReward": "1697b699",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625005c4",
          "beginScheduleTs": "625005c4",
          "lastUpdatedTs": "62690217",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1eb445",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625005c4",
          "beginScheduleTs": "62690217",
          "lastUpdatedTs": "62690217",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8xX5GTqVAV2gXXdQj5PgyrsVgumQcU2YeWnf3JAnuYef",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Cr4Hk1CaMfVFY5HSyi99Bt5Joq7Fw6atAB6MjhnFghrC",
      "vault": "ENcKYAQduLEGTGMVTWcED5Jgd7mXb3Egbqfs3WPrMUxE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624abae9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624abae9",
          "beginScheduleTs": "624abae9",
          "lastUpdatedTs": "624abae9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "23ff20",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624abae9",
          "beginScheduleTs": "624abae9",
          "lastUpdatedTs": "624abae9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2fQ1j7hwvB9ZqYfaAqquiqMym1cHGsgcGKKzTDhoGdos",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Cj2oH7GTXu9Wch1ZncoeLVLrox3BrZ7zPGVYZ6zbhVym",
      "vault": "5PZ6US51Yjy66kmjhjbEckNM6ietnXmx52e4eg2gixwa",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "54ff",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6PoeCXtFycSvq8LvQUknEhxtDpjALH4kTZp65PkaJBk",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CKmEaP3PrdfjFEpJLzHcnTKSUWFNkP5htWrpeHaNzTmA",
      "vault": "Dz211iD7eezn4K7VjLgGnjj7r2We5mgsxd6CoBcSVqad",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d8b09",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d8b09",
          "beginScheduleTs": "625d8b09",
          "lastUpdatedTs": "625d8b09",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "112f00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d8b09",
          "beginScheduleTs": "625d8b09",
          "lastUpdatedTs": "625d8b09",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3mv1yACiSFzzBA63cgoTqdc6zuH3GhoAAxgfgREHirHw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5Fi6qESdzfEZ7dK1svHwdYrVYGj2E9CV4S3SR5CCk8qk",
      "vault": "FZZCPkMZSVdMWaJAZZZBZUnNnZtz6URZGA4jsTtSCNSm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247fdef",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "16360a15",
        "accruedReward": "16360a15",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247fdef",
          "beginScheduleTs": "6247fdef",
          "lastUpdatedTs": "62608e3d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26bc1a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247fdef",
          "beginScheduleTs": "62608e3d",
          "lastUpdatedTs": "62608e3d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "85x8uh7A2ipQ5WwyRJ31kVKzkdxyR7tU8dAZy9zUjeau",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AQKAaB8Tqv9fixEuRMaMF3CS2wuhN8hgXkY7wnyY3h3v",
      "vault": "7M6zcLrsh5TjnDPU7ZG8qLGNGj12gPBt8A1ruH1jqvQM",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CeBZqtSC1uKz7KqphWzncD4LtBt4uYK8hGNuWFAPrGUz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7LKKsp3PdUC1tTkNaqN2kjmLcT26HmtWueGyj5DkoYnB",
      "vault": "FQFhCdB1DjQyrZmJvMJaDsYPRWu7EauSqKxxLcX6D79X",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62514e63",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62514e63",
          "beginScheduleTs": "62514e63",
          "lastUpdatedTs": "62514e63",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d6ba6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62514e63",
          "beginScheduleTs": "62514e63",
          "lastUpdatedTs": "62514e63",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7Qq4vYsKBm5G7P8kVXTbVUBGiSoPFbugG4BvQhn2yyUS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2G4Cy47ugyTh4EF74pqWmN66uYjCSx4L5haKXu5RQvKJ",
      "vault": "4E1TdyqfF453Yw6m4DMiUwveHoLrJauSL8p18Cgpys25",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "04e672f4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J1hENjHYeYDnwzLWHaGz6TkgDiFyzZjsg2knEE5zz63U",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8fS67jo7SxCgam2hpFaQDNZtSZpe7wQwmFZg8rcpirZc",
      "vault": "3b23mk1BJwE4C83uzAF2R5pF3Q2EqJGTtAh35nWEgZ77",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "02f55e89",
        "accruedReward": "02f55e89",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GxZdVx8vrYmwHzbTpUrXNfPM5ncCVk8vk3ZoDEAWa496",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8Tra87cVW3ZjqqZYeXTVuv2cMcYe6xeSBrGTUN8bPQb8",
      "vault": "A4YJnCMik9uLK69n8D7xzQUETzmjRYmRLowcaN7oYnXY",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625cafd1",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "15b3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cafd1",
          "beginScheduleTs": "625cafd1",
          "lastUpdatedTs": "625cafd1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "120a38",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cafd1",
          "beginScheduleTs": "625cafd1",
          "lastUpdatedTs": "625cafd1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "29eb1LLJx8DuDfuq6JQP5igEfkti4ndc2Jy4guCEyVSy",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7cbsoFYgzwJ9Lat4w4BpoArSMjEyjwUsirv6RpJnAGnv",
      "vault": "AocLc5U8y7Ld2q2CyLjgvoyJfPjtPzjXDU99B6z4wdh4",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625ff6e5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2a71ecc9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ff6e5",
          "beginScheduleTs": "625ff6e5",
          "lastUpdatedTs": "6261979a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0ec324",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ff6e5",
          "beginScheduleTs": "6261979a",
          "lastUpdatedTs": "6261979a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "22LJMvZLdFYMJPHYUJDgjbqqAVB9q1kVgsZbBet2kcWs",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7L6Rb8XWPtqRABzj74EQ7cTn4vzUYiXqMfTNWgyWzBAx",
      "vault": "5vXSwTxws7YetiwiWbCUAd9GcTTFzP64fdbPwVGpnU1M",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6268233b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "349b1c4a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268233b",
          "beginScheduleTs": "6268233b",
          "lastUpdatedTs": "6268233b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0696ce",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268233b",
          "beginScheduleTs": "6268233b",
          "lastUpdatedTs": "6268233b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "35Wk63YJzVReeVjoegqr3ikZd8yJUC81t3sXGgh7SQRV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3r9DSCZ4G3rZwdnp8aQGVQm6y1Es7vNQCcokn9kzYTzR",
      "vault": "DX4uda1cmU2pVM4CcmrFXuXMfip2mmvrHf2dpGFxd8Qj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "624b1e57",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "5c422b0f",
        "accruedReward": "5c42c695",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b1e57",
          "beginScheduleTs": "624b1e57",
          "lastUpdatedTs": "6262ef0e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "239bb2",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b1e57",
          "beginScheduleTs": "6262ef0e",
          "lastUpdatedTs": "6262ef0e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3g76hKhaF8QYygfEGa4QVAC2dLgWExXuUHy2Q3Tt3S1f",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Cb4eAAYzenGmAbbojRzd9dB9j3oijeRzEXPuAyXjb9Ex",
      "vault": "9LBmyRw75dWHqjGiY9NR3cX6rHxuWQUWWAkcvztWRxKH",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2LgdKwqQobhZpvztHupZ92dRyJpgmdwM7VEVMh1Uh2fG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5YGwwkFY2uAj4KNDxaXRWU3F97H7jRaQVZbRmZkjr2ex",
      "vault": "98VJ7skubp2zaLf76knsDYJCz7VdFDMsxiq5E9FtYmMG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "626215aa",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "302d7cfe",
        "accruedReward": "302d7cfe",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626215aa",
          "beginScheduleTs": "626215aa",
          "lastUpdatedTs": "626215bd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0ca45f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626215aa",
          "beginScheduleTs": "626215bd",
          "lastUpdatedTs": "626215bd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FzCPUjQbnRodDo35AiQ4qSomSJHkB2B82BDSfwTCzeiC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FYd8YkYn1EiFjwhsCjTxJNjJ6zwqamjpE8aL5Tw2481Q",
      "vault": "CRDDgySueefgYbyb4Mre2awoGz7hC8vDyBxwmaecqR2B",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625dba99",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625dba99",
          "beginScheduleTs": "625dba99",
          "lastUpdatedTs": "625dba99",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "10ff70",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625dba99",
          "beginScheduleTs": "625dba99",
          "lastUpdatedTs": "625dba99",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CY7ap7cNhgdSGna5MhPTUpMAaxVqNMAXmmDKPZqrvxy5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GUDZyYruC2rQUiZnCQjtC87hiqzoPqg2nwzuAizGUfgm",
      "vault": "G4hCGXb6SMhLerVo58jVct7UTgH1xjLAGbChLkpXFnVT",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248856d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1e1eab20",
        "accruedReward": "1e1eab20",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248856d",
          "beginScheduleTs": "6248856d",
          "lastUpdatedTs": "6269d4cf",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26349c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248856d",
          "beginScheduleTs": "6269d4cf",
          "lastUpdatedTs": "6269d4cf",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2TEfeLRcedbBZdHCxoDbG7muyLBY75GhfDvgRzHhvVkM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AB5k9EtaXvPN3GoS2aW7qisMHWxvZDXijq2TmAxQnYHZ",
      "vault": "huEv1AoECRv9f88YGUSddVc2jq3v259EiMvbnEsfXAh",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DtvZjYuHdiQJt5Up9mTviZKSgLY7CQnrDWp8Q46dN57d",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DQ7DoT35ohiE3sjLcqA13Lz6wfuKM8sHwhXJa8SEBzyJ",
      "vault": "3pxS7XeGsVgpgUjCzBaGQ6AWS2YJduz6ioNb4sC3TEMy",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62479197",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3966f1ec",
        "accruedReward": "3966f1ec",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62479197",
          "beginScheduleTs": "62479197",
          "lastUpdatedTs": "625cbabc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "272872",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62479197",
          "beginScheduleTs": "625cbabc",
          "lastUpdatedTs": "625cbabc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DoATCHhkVNxnRywuz7LP2HKxRwo8yLZLcMcQ43wCc9gC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4w1F235aunDbJwWQnB3qs1CesThni3NSjpznzDBLMZFg",
      "vault": "89noGM8BTvFVPdHnVMQBLyapLA3xTkyZjggTQxUFyY4V",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625f2dcf",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f2dcf",
          "beginScheduleTs": "625f2dcf",
          "lastUpdatedTs": "625f2dcf",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f8c3a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f2dcf",
          "beginScheduleTs": "625f2dcf",
          "lastUpdatedTs": "625f2dcf",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HNQvkFWH2igMcNF1FrSYeTKF7VpQ6WH35YMsqDZYgqZh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HsJ9Uh8DFmBtqS7pczyVVFJmPjVA1pTr3N2ZgUv69zCF",
      "vault": "55v32SwzuqqpPgJSrp6SKfpZDiyeb1FSFwu9ptP5LPEF",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624a6a11",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "019a84",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a6a11",
          "beginScheduleTs": "624a6a11",
          "lastUpdatedTs": "624a6a11",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "244ff8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a6a11",
          "beginScheduleTs": "624a6a11",
          "lastUpdatedTs": "624a6a11",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CRhwCr2LV1DwEf1ndM22fcEZiBWp9hpFNwjJ9eqCzcB7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BZ2SYtqUa7hM6h9xvKjQZcBHpRc9vWHy4uKbrC9rcUub",
      "vault": "HADic3LUYnpV7u3VirUZVw6eszpw97eqd6s7gM6xj3Bs",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6259e277",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0b3d8666",
        "accruedReward": "0b3d8666",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259e277",
          "beginScheduleTs": "6259e277",
          "lastUpdatedTs": "626650c7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14d792",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259e277",
          "beginScheduleTs": "626650c7",
          "lastUpdatedTs": "626650c7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EaZz6X9JQE986He3iSbMMAsm89rAyUL9uVSnj9Cyc4pp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3tCZJHqpPa69L3BFPsSvmdLfxVxZWjwe9VYG5vQEgVcp",
      "vault": "8wx9aho2qvquXxQpPMx8Vgp76zkqSeofATxm9UuMkjHx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6268632a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "5966ce59",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268632a",
          "beginScheduleTs": "6268632a",
          "lastUpdatedTs": "6268632a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0656df",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268632a",
          "beginScheduleTs": "6268632a",
          "lastUpdatedTs": "6268632a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3dwTPFUv4tuk21nAb5SReegBcocJ3LtAyu5FJiLitAZh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5Ntof9FCYbW4t75the9t1PMxXEVcuc85SqaUZN8fJ9TB",
      "vault": "ERJx4nhbSiuLDat42TyiYBbCezAPWAcQNhkwoEZ4uh9y",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "16586491",
        "accruedReward": "16586491",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CWCYrJDhurP9ySSCevCD4wz2KqPtEcD5ivS6kwHgjh2X",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9my8hK4B41h1ow5uqdEtz5SbnyVn8zHqW2WMQK4E6rLz",
      "vault": "DdKR9BU2XJqMdP3crjA5dYRCP1thyJXeRiMQSnCtcZPE",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8v5bAJ1XtvYaAHXy5JT2xJsSVoSF8DQRuR7YMEvD6WYz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AvJHe6LszN2XzCttAavdSGSCu1qRKpE8pMPX9qmBQfCC",
      "vault": "3pPBvpMn4cuW7Wie2aheJMgFvVcexcCFCfbkf6PpaXNj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625e0e43",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "28b78de6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e0e43",
          "beginScheduleTs": "625e0e43",
          "lastUpdatedTs": "625e0e43",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "10abc6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e0e43",
          "beginScheduleTs": "625e0e43",
          "lastUpdatedTs": "625e0e43",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "71iq67FR4yYdtbowenC1HDM6QWTeiqUof2VKpcfy7D6A",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6ttH4RvD8UX3bYMrEdXTacnXXNveHMhD3zY5H23gk5Sd",
      "vault": "6puxQ2TSMbX51LHbBvukE9mWtgwu1NoofGNe7nXDaKEk",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6253ef87",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0e4685b9",
        "accruedReward": "0e4685b9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6253ef87",
          "beginScheduleTs": "6253ef87",
          "lastUpdatedTs": "6263b923",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1aca82",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6253ef87",
          "beginScheduleTs": "6263b923",
          "lastUpdatedTs": "6263b923",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "i6xukQBKUG8iRSqXahz8zaxsEQC1iHmmDpGL3XVwE7e",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FTkvdcjfaYqBQSKRY82nFh3tqzfzA4Zvt1XcXEQz8qwn",
      "vault": "3UgeeSV6JQHJovQ9nuAS35YpZC3YAqh9SNjGqUeokhzZ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6259a4ab",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259a4ab",
          "beginScheduleTs": "6259a4ab",
          "lastUpdatedTs": "6259a4ab",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "15155e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259a4ab",
          "beginScheduleTs": "6259a4ab",
          "lastUpdatedTs": "6259a4ab",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6Uq3GLExyHjk42oJjU7a26j5tdansAf2dMGEpkRydf2P",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "28QWsgxUfXC2miatbfcWawLXwRF1YDrac8i99QoBzD9m",
      "vault": "AyV5X2DuVbXSjmE92mqsbbCpDDQ5T6z3Cm1PmBZ1JWSE",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "15355220",
        "accruedReward": "15355220",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "81sPSJLykcXjxVSrQ1pmAoVgnpL3RPT6soDd6ZnJWKvx",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2cuRwBX7QTfNB5553hLT9VS25Tpa547XCWY14BShG35v",
      "vault": "GRk8JPemkknVMXhnK8ZMHbe3fGSN2JB7Gcc1oQpzLfcA",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62484c41",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3a73c01c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62484c41",
          "beginScheduleTs": "62484c41",
          "lastUpdatedTs": "62689e9c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "266dc8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62484c41",
          "beginScheduleTs": "62689e9c",
          "lastUpdatedTs": "62689e9c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HXyVPu3HL3zc3tbNRFcDySFnwwffzMCqhMFCedggXYHX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AyUxNrTTiTnejwTvXeGNWqiV5ujDiMhN8syLLDwDETUt",
      "vault": "2JpvpntyhRg2xHyJ7awCnp4h99zBmepDdfZndquQosRo",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625c4bab",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "109de564",
        "accruedReward": "115fcc9a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c4bab",
          "beginScheduleTs": "625c4bab",
          "lastUpdatedTs": "625c4bab",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "126e5e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c4bab",
          "beginScheduleTs": "625c4bab",
          "lastUpdatedTs": "625c4bab",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E6xrVJLyYExA4Hv5SidS72gYjVLHtDvs5g5bpzh64DyQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ARsQzBhYpfDHguBvovNCAAnPfrTqUsVokA3XM1FGjQsA",
      "vault": "ACKyUTpmZZE8yEoS9uFAFp5zUYCFoSJ2MRUaq2NDytPE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624c1d29",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0e509156",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c1d29",
          "beginScheduleTs": "624c1d29",
          "lastUpdatedTs": "625bf1e1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "229ce0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c1d29",
          "beginScheduleTs": "625bf1e1",
          "lastUpdatedTs": "625bf1e1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FrPhpBm8cB9Y3ct7fG8UcRbNagNZk7YyiFgdG5qvaRq9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DRpR5U5X277mnSygp1CyqXddXnCVwm8JatUJD1SHhxFD",
      "vault": "8fTWbrYz99oVn7L2wG4cbKPLQVWs9ybkwGR2FrBX8Nmg",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1f4ce9b3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FeygiuPLwKCkjQxC8GsAreF5cQxiEp3751ufsuUDwL5b",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5x8JTwuN4UcUGEHBAjR368WM1yKymiMs6ggZPBpKyW5N",
      "vault": "54FxfFeDFoDWCKAGPUWuqkcdVHYkJgj7Ruk5Sh9hwqee",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "626786fe",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626786fe",
          "beginScheduleTs": "626786fe",
          "lastUpdatedTs": "626786fe",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "07330b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626786fe",
          "beginScheduleTs": "626786fe",
          "lastUpdatedTs": "626786fe",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Vo8YopqAAhdqVGrV8z96aBEgbkQ4oM75cNrURW1yCss",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FG8UUbY2cU2RRe9cnN9vzWUb6ouC14rpwX2Lc4tHrdBo",
      "vault": "F12ehGDrfeju5pPcN9fLCCgehHvViiVjk8WBqsqWSxcb",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "18035148",
        "accruedReward": "18035148",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "JDqBvQqZY7QuDZ3bzC9vCAB8GYthh7spcwsv4UEnvz29",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Eb146vrf6ZUzstf49swmbdgCGhdCfJT83ssnSiRgGoNE",
      "vault": "3G1ungDGct2FQUb6KecTKknewejozR3eteDfmqjpcESa",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624cee96",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "fe14",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624cee96",
          "beginScheduleTs": "624cee96",
          "lastUpdatedTs": "624cefaf",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "21cb73",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624cee96",
          "beginScheduleTs": "624cefaf",
          "lastUpdatedTs": "624cefaf",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CvvTuD8damxBF9HbTQ5vTALB5EZ3GYZFcpd8YRe8pX6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CwZ1DSUo1XSCzacVRo7ZQBqaCQ8E7mNasCunEBBydxXg",
      "vault": "sb53nk4BLTKo3Z4aDvCtGN7EXULtZsok9XUzXbX2Ex6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625687f3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2bfb2bb8",
        "accruedReward": "2bfb2bb8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625687f3",
          "beginScheduleTs": "625687f3",
          "lastUpdatedTs": "6262dc31",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "183216",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625687f3",
          "beginScheduleTs": "6262dc31",
          "lastUpdatedTs": "6262dc31",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5r3NhsJvZVa9Zh8dUizHcmsjcs72oo1cLYsjsqTLe7py",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6HSqQbfDWyBdtg9L1s1XRJWCpX6JCZ1a1atA8717czzm",
      "vault": "JCMHXeZFcLWbcHnHeJh2FDQN2gCMrB48Y9KJHU2jsFap",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625cc697",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "093f0d2b",
        "accruedReward": "093f2595",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cc697",
          "beginScheduleTs": "625cc697",
          "lastUpdatedTs": "62670077",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11f372",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cc697",
          "beginScheduleTs": "62670077",
          "lastUpdatedTs": "62670077",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5pq535h3Gxsq6TPQCW2Q9S9cZzCEnDs8p3ohLWermLYq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HPpUSn7Gm4aTJENF1PkEedXyS1Zud9YH6b8D8Rt2bwVq",
      "vault": "HkWBybHxJk5TwC4iNBYNirToMkxx7GPn6NEhQUvNbRed",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2ehsQNMXR7wP71VFxj2sfoQYgbczThn4AFoQd3qESUSa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9pCnZCjXjyJADcitL7aBimhLctAHKAui8eGguXDVZxj",
      "vault": "8VhEvGmHXei6E7LwFhSsxqtuoE12oE8vChzrcpHSxdny",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626a70e2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0b529564",
        "accruedReward": "0b529564",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a70e2",
          "beginScheduleTs": "626a70e2",
          "lastUpdatedTs": "626a70e2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "044927",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a70e2",
          "beginScheduleTs": "626a70e2",
          "lastUpdatedTs": "626a70e2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E1JvKLYCGKVYRgMs2m9kkipd53Rcb5uxUDEnujAzR2iX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2kr1BQf5saC8u1Nyk5xbvMqF5yPU1NZM4mytvLqdiivB",
      "vault": "Hph8bmJ7GegRK8VRTYC2QokpCLpxy1iB7UgzryZztt7S",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ATMcDpBqdXShSkHb3aknt8ZVFSYLbMfkbm7fJXVxLAA5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "QDRo8X2pZGSvfd4T2bfnPXBxSRP8DNnzmThGPgvotgS",
      "vault": "4fqPna7mGyaKRaW8joGXif2vftF7mbN24iD1xNFMswqa",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624845c5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1c94c6c7",
        "accruedReward": "1c94cc33",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624845c5",
          "beginScheduleTs": "624845c5",
          "lastUpdatedTs": "6267e190",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "267444",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624845c5",
          "beginScheduleTs": "6267e190",
          "lastUpdatedTs": "6267e190",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AC2piU5ffjVDaGfVjJMjWCY1SYZ59NN9sSsVyE8oKfZe",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CjFxCZqgP2XwLTqdgmhCX2F5By4CTVj8zFG54ZbFQpXQ",
      "vault": "5trG5uQRtQnkMskAMiAEyVADo2BMG1uD2JZUYYgy4nkj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6247673d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "5e3fca67",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247673d",
          "beginScheduleTs": "6247673d",
          "lastUpdatedTs": "62617617",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2752cc",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247673d",
          "beginScheduleTs": "62617617",
          "lastUpdatedTs": "62617617",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GVXXRHbRf3CLWQ1mznffdAwsm757pktoxe3tQxcr3N3E",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3U8prUaTo2ccV2ZpBKVBRMmrDARetpVgBY214DXkjnvY",
      "vault": "HhWUJEp6HKNC3rw2FB6nD1WbV52gfNx6Hk4SmbJPevzV",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4ekgRB7fBB1tqkMws8BL2DD4FtdPpH8DSr8YZ7mtJztC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "69ghjh3CC9eqCEm4F87he15XY9VvEaCcUKFDNssLzss",
      "vault": "nDdotxjJuxoTjDUsftHBp539jmiCoj8PB2PppU6HELk",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6268146b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "38ac8319",
        "accruedReward": "38ac99b4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268146b",
          "beginScheduleTs": "6268146b",
          "lastUpdatedTs": "6268146b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "06a59e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268146b",
          "beginScheduleTs": "6268146b",
          "lastUpdatedTs": "6268146b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GXJYk52n7yhfBbuyqSbiLC9SYd2gBo7rDLLcq537WHpy",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6BXPwzaRDbUEfGd6k4QCtg1tTk2TD5XyrfEMbD4VmeKM",
      "vault": "6wBzeJYpu46c9jd72aMWz47TbZgFKcNySbqzv6UrFBbB",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6251a14e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1271f851",
        "accruedReward": "1271f851",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251a14e",
          "beginScheduleTs": "6251a14e",
          "lastUpdatedTs": "6251a211",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d18bb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251a14e",
          "beginScheduleTs": "6251a211",
          "lastUpdatedTs": "6251a211",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2RAGkytPBHay6mRyedgWdCEzCkmoDzvhkEgZBeR8ikAQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2CF7FLhcZEPWQNvQjkpAUsDn9ArUJEyd1N93htgrbCRr",
      "vault": "5ARxrgEsZQm1fCL8HDXvdkpC55FMAQe5MQei25rCFoZF",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247baa7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1fb1a453",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247baa7",
          "beginScheduleTs": "6247baa7",
          "lastUpdatedTs": "626ac7b1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26ff62",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247baa7",
          "beginScheduleTs": "626ac7b1",
          "lastUpdatedTs": "626ac7b1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CS6F8UcTLiqMVEQt73qwhUPeCpUFhjhDUjPkXXf5gz3Q",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F96diubv5tHtNog9SkVooa5BLZU2TURXC8bTeUReBJbf",
      "vault": "FS6qNbgQcGfVFyBCQ3jiJ3YCYKpx8bbdoSJgrt4oTnyJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62688bdc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62688bdc",
          "beginScheduleTs": "62688bdc",
          "lastUpdatedTs": "62688bdc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "062e2d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62688bdc",
          "beginScheduleTs": "62688bdc",
          "lastUpdatedTs": "62688bdc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6T5EZGwBguMVfJTQreuRSEHyswFEgL1uCeQVZ2gCWJRb",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9nCHa5tqqbY8tF4CaqNucnJeLmtxWJNksMfYuB7aRvda",
      "vault": "BNdQWZgk7fSW1EKYGqkd3pjFZLgy5YbW5o7RVpCDhy8b",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62549420",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "12e51078",
        "accruedReward": "12e51078",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62549420",
          "beginScheduleTs": "62549420",
          "lastUpdatedTs": "62697988",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a25e9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62549420",
          "beginScheduleTs": "62697988",
          "lastUpdatedTs": "62697988",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9yNwTrQru9hLsBa4XjyLnESG6Eghre3p6oqcuPGTMZQr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BavA95imzq4ia1284LxqzVLq6Hc5i8ypEyN11wNEM7Bv",
      "vault": "5fb3UcV4NKxLLQpKZa9FawPEXj8V8aSaMtBCMNS1QAcR",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "092b95fb",
        "accruedReward": "092b95fb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9k3QPmVSL48LpctzKtzys93p85yQQsb8goUyAAkmWZnw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8X5KWtLmiALUf1qE4aPnJ6cZQDp5WgvwGQSTBFY8EjSs",
      "vault": "DqyuGCiT5ftZbLjcR66B7vvi91R49iyxEMc8XJC4n9RD",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0f48c194",
        "accruedReward": "121a463a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E9Zdf7oX4nQKznMw1ZiyHxspwJGqn1p2RoEjEKLrSahN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A48NLLhGuKrFognpim1S3ZFQnDiaKk54yD5pQcaVZBf3",
      "vault": "H1kSF5LrC7aVnjidKFwuqPV1h73Z8smTQs225B9iMyxn",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6262fc00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "392ad319",
        "accruedReward": "392ad319",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262fc00",
          "beginScheduleTs": "6262fc00",
          "lastUpdatedTs": "62674448",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0bbe09",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262fc00",
          "beginScheduleTs": "62674448",
          "lastUpdatedTs": "62674448",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Eeu8Q66AStJuaJFmM97YKsVhLdZhUYWDYWrbiegHC4ms",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ETg9ESmsiCYV6cq3ahLpgYe2jBd6DRs9dnwahjc54Rxq",
      "vault": "9Km5B8qhrssvzVABri3wL1ZkVHswBdnrj3yji1U1VmoV",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6254b41c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0cfa99ee",
        "accruedReward": "13c2a7e8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254b41c",
          "beginScheduleTs": "6254b41c",
          "lastUpdatedTs": "626a8e94",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a05ed",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254b41c",
          "beginScheduleTs": "626a8e94",
          "lastUpdatedTs": "626a8e94",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EqsJB59QCsp3WuDdWZf3p3sEGushH97JxzK1zgRm6PKM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BoYiRVY1wvCg9fWyzDXn7MaH3WB8E24soPSuQpUmYCFD",
      "vault": "48fBb2cjwxqzT4c1BfGz1wqGtp1QtGR4z1PQdew3PkCw",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "6258854e",
      "cooldownEndsTs": "6261cb60",
      "rewardA": {
        "paidOutReward": "2f9513c0",
        "accruedReward": "2f9513c0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FNTG1YBkVQfwbDmw2oK4eVyL4bRH4R521NN7rDr3LgY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3mpUYUNYfG6XTu4NNQoAhH4q62Yxr7vmU4BG6JZT3FiD",
      "vault": "GJKqXFuQXdqpChrwaZKYuyLBQE24CBiKszrFEcXKsyDR",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624d0c7d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "13458a79",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624d0c7d",
          "beginScheduleTs": "624d0c7d",
          "lastUpdatedTs": "6257b48a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "21ad8c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624d0c7d",
          "beginScheduleTs": "6257b48a",
          "lastUpdatedTs": "6257b48a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EDrG5WSMZXNJzo1AQpukk2vk6baC2UA4neCTp8pWehgL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "KRTnLyRRGLkWNd7JErm9UhtLYZ22adgquMcZRgQnnJ2",
      "vault": "AzFXkpzuGwzFCuD3uTngRUPpd7CLJ4dZs5VHx93ifFQy",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "626515a0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "9add9e68",
        "accruedReward": "9add9e68",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626515a0",
          "beginScheduleTs": "626515a0",
          "lastUpdatedTs": "6265162a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "09a469",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626515a0",
          "beginScheduleTs": "6265162a",
          "lastUpdatedTs": "6265162a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AMcaNMyxRjchdPRQBnzDMtHwXHbAk3bdGHrtFyKNQNXR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "J5MByfCxFUAj6FPbALjzz4Khr2sMNbgahzEkRULNjQs3",
      "vault": "HsuXUNHb4CNXQyxyEznKQK9pQ2K3u5eCtAXrq7TyAp14",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6253ea67",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "14e66e48",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6253ea67",
          "beginScheduleTs": "6253ea67",
          "lastUpdatedTs": "6253ea87",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1acfa2",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6253ea67",
          "beginScheduleTs": "6253ea87",
          "lastUpdatedTs": "6253ea87",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "C5bCbZWgzBuDkiNrXWtWWigYg3uzPwhaPjA1KZ4aT4xB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D6ssHfn3j5UapStTmLomZvAxxfH6Sh4DPAEBnwhgprhr",
      "vault": "Dw12VcaDQVngkD2sUETCbqc3xj8TrjGXhmoP2288pYNm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62476aae",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "29b5566f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476aae",
          "beginScheduleTs": "62476aae",
          "lastUpdatedTs": "6256ca9b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274f5b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476aae",
          "beginScheduleTs": "6256ca9b",
          "lastUpdatedTs": "6256ca9b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "67UDJ2QSUUHa8kkkMwTPicz7EqeKHyoQxofQaiC4JPLr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "34DaMzadpSMsj8Bw9M2c6HmMLZetvzi7eRguzHH9SgB6",
      "vault": "D9MqdHu4K3AmKstpXXxbYxc7QWPYpBDgqxUmUwDBDnvQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476c45",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "07c99a68",
        "accruedReward": "07c99a68",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476c45",
          "beginScheduleTs": "62476c45",
          "lastUpdatedTs": "62500909",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274dc4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476c45",
          "beginScheduleTs": "62500909",
          "lastUpdatedTs": "62500909",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GApeTkS8n4YU7AFHeh3Mvdfkdb9ozbShz3Rov3CenDNw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "THvqNETburQb1PRehUu8j3g1CEB2frd4E74aHBEkvKS",
      "vault": "DqTp2LiksL6bzrVEwUDmwzJ7sWLR2eo3DqHLA5JHWRbs",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0de940c5",
        "accruedReward": "0de9f76c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "59jmPCUpzm3zKfRCRiJegcD45pttefYv72sf6VUCvutq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HWdbqHC7wrVfxs92mkSmAc8fS6NNSkP5szhMKym91NYQ",
      "vault": "DzpcLwC2ykKsR7yn2XiYe2NdETrYmirFN6ZzCqHQR7Kv",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62619302",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "05aa5f62",
        "accruedReward": "05aa742e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62619302",
          "beginScheduleTs": "62619302",
          "lastUpdatedTs": "6267d718",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0d2707",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62619302",
          "beginScheduleTs": "6267d718",
          "lastUpdatedTs": "6267d718",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2zX11kSWtz2Csb4eF5Nj2kJ6M1rZu1v6xH2xh9XvcUk2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "H5dF8SoNvFwMjxFwMNm4t7BR1iLFVCizgf1QPkkrG7ko",
      "vault": "2fPuAuegEVmTRJmJhJuh13Ar8TjY1EaAxPXMhVcbiiM2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6258d453",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "33c97ffd",
        "accruedReward": "33c97ffd",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258d453",
          "beginScheduleTs": "6258d453",
          "lastUpdatedTs": "62632729",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "15e5b6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258d453",
          "beginScheduleTs": "62632729",
          "lastUpdatedTs": "62632729",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9sQiXK4WborZRKkXeATDFRcoswEDGqJb7hAW87uib7BZ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2BbpjuDVmEJ5Ekdz3GAjKhf18bkMCxRLFDpory9Vn3CM",
      "vault": "GyhXhug1WBjos7j3RMVAaG47pTvr36yjVUjmbC8jcqSd",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62569165",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1b59e7af",
        "accruedReward": "1b5aa930",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62569165",
          "beginScheduleTs": "62569165",
          "lastUpdatedTs": "62569165",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1828a4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62569165",
          "beginScheduleTs": "62569165",
          "lastUpdatedTs": "62569165",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "12dPN2YoLNvDYh2YmxDcNX3oGFYHm7DZiD52Vqd4x3jq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3LAxxL7A8hzfiewowQeRdmht5zvDM1e5Xcf9tmLAZEUE",
      "vault": "ruqA1mpd99JDp2PDNnAsQ9JD398whSNv6mFi9u3vbSJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6265c594",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3add56ed",
        "accruedReward": "3add56ed",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265c594",
          "beginScheduleTs": "6265c594",
          "lastUpdatedTs": "626ad9b1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08f475",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265c594",
          "beginScheduleTs": "626ad9b1",
          "lastUpdatedTs": "626ad9b1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DDBUCx9Au6Jzbnu8KMaybWpSjsGm3zeJFpXjoch4YDdf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2gZFCHmKydxkjkfSyB1QU9CW8qh3dFmXf1KshF25NKmW",
      "vault": "GZApD4uDitzWjWUYrNWwGZqqWYfzJqEukaH9BKWBxW5",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0d4f499e",
        "accruedReward": "0d4f499e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EaPnWUHN2kL7LHD22ksrTiPrpUz5CvDWywH48UqFoCZx",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5i8rH15DN9iXurC4Wocy6sTDAF3Tw5h2KDGPHricBkP3",
      "vault": "HPEvFpqeimmjR5jYBMXbNZ4z7zq7sZjPVusJcyYu8Z5r",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "32c3d8d4",
        "accruedReward": "32c3d8d4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8AkFQ297M3ibB2UDAcxbZMQMRCDCXWfaP3H59miNFpeu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9cHAHXkrrktPWqhb1NnTVpk6KQ79A8nbS7eEkMVMJRZo",
      "vault": "GrMN3C7ksLMexvqLF6BhqbCzKaspq7yTDZU5ocMQ1wFz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6255a768",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "363dacb0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255a768",
          "beginScheduleTs": "6255a768",
          "lastUpdatedTs": "62694916",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1912a1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255a768",
          "beginScheduleTs": "62694916",
          "lastUpdatedTs": "62694916",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Bs4vxKfMaLi59VXK7z2x4nKYc7avxioANjpVFunpRjSZ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5KZxcRVaTbGP8Vf2hXSU92eRtxcUHrxcyFns3uN2zAoy",
      "vault": "9bSd98Vutx2DExXptTYduvmtLcBz1XP2v7p7iGNcP8WU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476762",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "10563b94",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476762",
          "beginScheduleTs": "62476762",
          "lastUpdatedTs": "62597899",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2752a7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476762",
          "beginScheduleTs": "62597899",
          "lastUpdatedTs": "62597899",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CUBDuq1ABEVX4C65NekdGa1ZfrbD4BJXZaM4Gjtg5A9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BbHjGYYLa7mxuW8h7uu6LYjv5KPcgreUjZokvZWkhPsN",
      "vault": "Ds63rTzwp5EZUutyTKTdio8b1m4L4gBJTefp8CkWM3GL",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247acd4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247acd4",
          "beginScheduleTs": "6247acd4",
          "lastUpdatedTs": "6247acd4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "270d35",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247acd4",
          "beginScheduleTs": "6247acd4",
          "lastUpdatedTs": "6247acd4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "G979KFENovARgdDQX4214pgqHQRnn7WWkGrdmqownVER",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4TjsArUtnPNKBZmRnjAgqSdAG4gcHpbsL8xkrWKU93ze",
      "vault": "GVbKM19Mf1mQXAb36xpzNAMsMsjJNQB1jq8eEg7uxrDD",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "09ab8dfc",
        "accruedReward": "09ab8dfc",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "C4fPfvFWsnco2KrSTiWY2RUEYLgq4Be22JiuuPT1HHVa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ALWMHhmSzAYeWWCJbokyp85BzJACDQQ9MLjEF3z3yLYp",
      "vault": "DDfFcJJF94YrDV92XH9hBq7uym1DUnYVDNEVzpieTtdY",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248146d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "18519e22",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248146d",
          "beginScheduleTs": "6248146d",
          "lastUpdatedTs": "6262f976",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26a59c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248146d",
          "beginScheduleTs": "6262f976",
          "lastUpdatedTs": "6262f976",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8ZzRnbSAT9wzLebvbNtcceaAEc8mr3eddmzQc6Qnwhb",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FbFpgCyh1kAWMfTRxrgsjfXHoHZxkPAUNS6AzCnjC3CS",
      "vault": "5D3ykDTqcTA2bsUzqnz2pv1oQoqpYbGndWGtwAwQJ3rG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6265c38c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2c6d69f6",
        "accruedReward": "2c6d69f6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265c38c",
          "beginScheduleTs": "6265c38c",
          "lastUpdatedTs": "6265c38c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08f67d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265c38c",
          "beginScheduleTs": "6265c38c",
          "lastUpdatedTs": "6265c38c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "514SbFPRc1oJsTEXLPcHmcXQVMTuqhPi8Jz4JArfwJhY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3yUYfnh1kgfnZSXid7QR5oYtmg21pArT7ixXxUQkywYm",
      "vault": "ETtrahhhEmZMvKByDEW5vWERwHpTL7gjiYYCk6wpCAFG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625ebac3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ebac3",
          "beginScheduleTs": "625ebac3",
          "lastUpdatedTs": "625ebac3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0fff46",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ebac3",
          "beginScheduleTs": "625ebac3",
          "lastUpdatedTs": "625ebac3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2JHtVwqRAXhQvFfMQk1nfD6wLWPx62AHMcdmMRHfBezw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2YaLXLWg9oyGYPi3zgKfhWqLwKzxzDZkcPmreZ9RASzH",
      "vault": "rjoWTvFNut3nKfhoscGmrZwuf9M88uVcNhMMNajSoUc",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624bbd25",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624bbd25",
          "beginScheduleTs": "624bbd25",
          "lastUpdatedTs": "624bbd25",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "22fce4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624bbd25",
          "beginScheduleTs": "624bbd25",
          "lastUpdatedTs": "624bbd25",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E6hFyHqF15oqfuRwhnjto2sytP8HyE1DsHaXJ4Hrbtko",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5A1iGS3QzfxaJ8JhfsGjpikJj7veZGLntU9YEcoeFZBH",
      "vault": "6N8opdNn2yy6qiosNt2X3AQxpxdKtYZbPetR6tSLD2ka",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62541a86",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "10dde422",
        "accruedReward": "10dde422",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62541a86",
          "beginScheduleTs": "62541a86",
          "lastUpdatedTs": "62543798",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a9f83",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62541a86",
          "beginScheduleTs": "62543798",
          "lastUpdatedTs": "62543798",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4Wufkamq459YksAC8Tq1VQkDTX1WgZGbMScHjkheSKDn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AjTJ1u9sjTKpASfTB295hJjKENFCxiBZp2fpAhZtb15M",
      "vault": "9LowyFyXX5XKwBKnbWpdBrUfwmXe2EeW1q5y7TmLDnMZ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "08",
      "rarityPointsStaked": "08",
      "minStakingEndsTs": "6247c7b9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "f3b08aaf",
        "accruedReward": "fd2ae311",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c7b9",
          "beginScheduleTs": "6247c7b9",
          "lastUpdatedTs": "626ac72e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26f250",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c7b9",
          "beginScheduleTs": "626ac72e",
          "lastUpdatedTs": "626ac72e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5pHVbGJ364KSh3ukZFujAkv3MttaS2UC6YsojifjQr1x",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Hjt1CMUksB3MvXrrHiF2vRs6LNHeytdG7r3rivwyHUi7",
      "vault": "4EwrZPFNUSzSEbeKyRaqoXHCnWNJ34FVBcxHLu4Rj9hp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62682aa2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3b3117f8",
        "accruedReward": "3b3117f8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62682aa2",
          "beginScheduleTs": "62682aa2",
          "lastUpdatedTs": "62682ad0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "068f67",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62682aa2",
          "beginScheduleTs": "62682ad0",
          "lastUpdatedTs": "62682ad0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3oYbGqQLywPGsbW7wpafxEJhCHpNyt5bZSGeqgCAUs1T",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "C6knMDMrakm7KnjdXoqeHgonUfhdmwkVYy9t6QHF9Vac",
      "vault": "A2PywBk3yvpfuztXqNzGPQhug6VMFqhPPjgMunx8njGc",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625e7329",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1fd56970",
        "accruedReward": "1fd56970",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e7329",
          "beginScheduleTs": "625e7329",
          "lastUpdatedTs": "6266537d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1046e0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e7329",
          "beginScheduleTs": "6266537d",
          "lastUpdatedTs": "6266537d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5dUrKinK4XLKzSCdR6RQoE7H9ShUxB8NUmoP4wqWtb5d",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BeZuT1qVmfR6xghfBTYbk4L4mNvBXi4gY9uogm8yJ87T",
      "vault": "GoY2BBHw5pHJdvHXvTFxLtrTLA2aG7iZ5ZcuDeKT46Y7",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "04e81200",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DSYVEr8jqnDtJ9cvUtToMPuUqZDpTMs7jcLyBRECuBNi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7G3dvEAk73fT2jFeP5M84UsCfbSuRTHx9gWEXbE14mXH",
      "vault": "5QubsahBpQpS46JcibHWAK7xiiLv3nGEdTx1pMosZssM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624c91cc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "04dfd4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c91cc",
          "beginScheduleTs": "624c91cc",
          "lastUpdatedTs": "624c91cc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "22283d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c91cc",
          "beginScheduleTs": "624c91cc",
          "lastUpdatedTs": "624c91cc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DDxDKbGGf4HguqgFyucHiRjEcrpuubtnYijJ1k96LzeU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DVMvGWyDEMC7YhA5tSQCuNawgDbMP9aBdc19TPNBDNEX",
      "vault": "7CfNHbkDzm2HbZ1U7LBTbdPB3DXKmrLzvdFDsycpLZEC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625a9a31",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2095395e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a9a31",
          "beginScheduleTs": "625a9a31",
          "lastUpdatedTs": "625a9a31",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "141fd8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a9a31",
          "beginScheduleTs": "625a9a31",
          "lastUpdatedTs": "625a9a31",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3PCNdfCkq3b4G4VDWJx4Q8p9uj2Vs1YRph8CuBfrDRM2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4rnfeBq1LcQwjfpXDdxH2hAmnutLERXoVuNRNhg1yveE",
      "vault": "Fe8LcH6K2LsTDDqaaJz47FXZXZkimR1j7XZjDXkuMjVD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6258c7cd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1396da96",
        "accruedReward": "1396da96",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258c7cd",
          "beginScheduleTs": "6258c7cd",
          "lastUpdatedTs": "626a02b8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "15f23c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258c7cd",
          "beginScheduleTs": "626a02b8",
          "lastUpdatedTs": "626a02b8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "25LgQg4CRnFYDxUShJNrjm3dX9hNXyzdKuGqnSr1m2ff",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "67dZBDYJ2JfNtbL2zQHtdRg6VTAtk7oZu8rNFDKZhQZB",
      "vault": "6xyrvfcco89UUzcyYBPAR7kNMsNBN2V2dFZ2VgBwrwm2",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "01cf24a2",
        "accruedReward": "01cf2670",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DruXXhkKTXhSnLktWcu89KR3QemYzvKPHxY8XkSujrb2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DJQjeU6rALK6tUuXRM4xkBCDBxFUiJQLHvY7jMqwxMSP",
      "vault": "2Jae9hmUy1tjaaiHTAuQQmyVSNUSeABJbibr6s6wjQhM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625c468e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c468e",
          "beginScheduleTs": "625c468e",
          "lastUpdatedTs": "625c468e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "12737b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c468e",
          "beginScheduleTs": "625c468e",
          "lastUpdatedTs": "625c468e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7xnwbmGtS2CPKBSCKHUSWMmRjuj4xuQxHXbMVceEfvjx",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Cy8pvnpDLKizWDRCVQNoFbi2e2vNnSpXW8JhNGbyLcPU",
      "vault": "BBnhizaUbx2zH2SzSzLJm263DnjzRRzYNKeCFrFz6et2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6261e466",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1384d8dc",
        "accruedReward": "1384d8dc",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261e466",
          "beginScheduleTs": "6261e466",
          "lastUpdatedTs": "62699146",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0cd5a3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261e466",
          "beginScheduleTs": "62699146",
          "lastUpdatedTs": "62699146",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5C5E4LfkUNxLvYEvnUDUfwyNwjZWoNXVXtpBH4oagfs6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HEdx2Ea5KH9QH7rRZGT2mysxWZ9Kk2ABWvkNe8hTsBhU",
      "vault": "D4J1y8LGwMie9RggNYkZQ3F8ZB3N9XBVKd8cMHnDftLc",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626195ca",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626195ca",
          "beginScheduleTs": "626195ca",
          "lastUpdatedTs": "626195ca",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0d243f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626195ca",
          "beginScheduleTs": "626195ca",
          "lastUpdatedTs": "626195ca",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5XLm4CC2F12dkCpH8FXePobFivvwDejCuwesvtHPKi78",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2nGHeJi4neqZdgP8HTDJTFnXmyEiERzFwJnx6CuudKdB",
      "vault": "4vayfRekRGnNQYG7anUzZfVvjtsPyhGwswCcPmBCGTis",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ApHWXE46x5DySD9z6N7kwZjqWu1J8ocwokPNHNkVSxCv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9WixmzUMzoSuLoKv4VLHXjf5wY7mWDK8LgDdUS2Fzmoc",
      "vault": "9bb1v7fFU1qy4Y5NKQNh43WgqABPVK2f3SRv6HTT2spE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6267c8ca",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267c8ca",
          "beginScheduleTs": "6267c8ca",
          "lastUpdatedTs": "6267c8ca",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "06f13f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267c8ca",
          "beginScheduleTs": "6267c8ca",
          "lastUpdatedTs": "6267c8ca",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "B5xjXmsaL63uXQFrxyLLM5sBWRUbSoarb3JmkfA6ASth",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3Jmoezsr2VcmYbuUBW3VYFhrq9vt6MGp2AeaGcynbaCd",
      "vault": "EmVGCmCDgRe91gfAcaPPd1AzxCr1JqwPvWDmdyzb1CcF",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625e7d56",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "25c7aba0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e7d56",
          "beginScheduleTs": "625e7d56",
          "lastUpdatedTs": "625e7d56",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "103cb3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e7d56",
          "beginScheduleTs": "625e7d56",
          "lastUpdatedTs": "625e7d56",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FooJo6XZd1NVfZBF8sLrPeDxngxfQxEGdghit9whuw9G",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DtpWioTDLUz7SQXZENHNNqsJ1Gpv8mkkWrowBeW5KYgY",
      "vault": "HRtjZnSfYn3pHehhvFTBzqvrw91mBejrCx31M8TKqv4e",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476aac",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1a7ce659",
        "accruedReward": "1b7f877a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476aac",
          "beginScheduleTs": "62476aac",
          "lastUpdatedTs": "6265d3d4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274f5d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476aac",
          "beginScheduleTs": "6265d3d4",
          "lastUpdatedTs": "6265d3d4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2bdUUu5qQe4wfnDcqTmRHA174UhBJXR6cNgx2jSsUpuX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8rVrb5hNkctP5w1zBMcLdk3zGqs6DNL8CKV8jc58x4Wv",
      "vault": "HVwaFLQuswQUsc75CvY2i3faky5TwfzT8zRqDosmdK6K",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1cabf282",
        "accruedReward": "1cabf282",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6PhUCs9deYYFuCHcabzjHaB3ernQpqWhkfdoa6y1nJJb",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6yTif74uzHcquF2Zd3FhXzMwNJUe5GaoYQ5JrWDYx14K",
      "vault": "2aeVJdg7PRqqNPwhYMozuzQccMncj4Sn2ZWj47mB4C7Y",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62478d79",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2ef53dc8",
        "accruedReward": "2ef53dc8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478d79",
          "beginScheduleTs": "62478d79",
          "lastUpdatedTs": "626184bd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "272c90",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478d79",
          "beginScheduleTs": "626184bd",
          "lastUpdatedTs": "626184bd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "B6upJ9hMqpJDnMzepZceYP9sK1RdVRfGYL4ebsKgpBpY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F3Y3MjgtFicsbZ1nzs9QvkL9U2JZqfUeKSAXibMiDnaY",
      "vault": "A2b32u3ChhGL578Huom6obcbxhLErfZmUEvn3xtrkCBd",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "01797d30",
        "accruedReward": "01797d30",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ConkRpgK27LAqPUC7ufpmVejZtrT98ymQ7b14KYbCuVw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FmiEUHfS4j6tRSM9uDkcTAf6PXDcx4hv3hSvnZnWs8LK",
      "vault": "37CETEdYrN38FooHBsvXq3kRG3sTsWA73GtcstKGqEja",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6268d531",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268d531",
          "beginScheduleTs": "6268d531",
          "lastUpdatedTs": "6268d531",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "05e4d8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268d531",
          "beginScheduleTs": "6268d531",
          "lastUpdatedTs": "6268d531",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6pYS9okZxKwC81XzX1QhAkeuBzZKiJmng3VkzteAMKca",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9epiL4VjCoTEmnTXVD6NueyvZBnFcpW8PT9zBV6rtYv8",
      "vault": "2Fcs9Ce7nxjZ6CE4tpcjP4u2ZjxQWUUXJFAmnHuY8YRG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6266d9cf",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "38aa80a1",
        "accruedReward": "38aa80a1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266d9cf",
          "beginScheduleTs": "6266d9cf",
          "lastUpdatedTs": "6266d9cf",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "07e03a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266d9cf",
          "beginScheduleTs": "6266d9cf",
          "lastUpdatedTs": "6266d9cf",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "B67rBQWB48Nw4niv25miLDzWyyeA2eT9kmp4Ko3E9HpA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6B6kFmJ9wNNoaZF55xbLuaXnhcVYQ34cJpJX95KQpr9c",
      "vault": "C7TzuP8xj1KnnxC6HV5x6J29GhpgLH8f8c8eAgYiGFLX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6256b2e8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1084ed6c",
        "accruedReward": "1084ed6c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256b2e8",
          "beginScheduleTs": "6256b2e8",
          "lastUpdatedTs": "6268f7c3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "180721",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256b2e8",
          "beginScheduleTs": "6268f7c3",
          "lastUpdatedTs": "6268f7c3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8vJiQcEbZAgtYet3peNq21ocxCFdpwRPNMjWHM9Q8ATv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AgPhAsvcwPfT8bcAQxfyvymHdS9enkCzHDZP67ihS5YM",
      "vault": "8SShKkcm7jSpS2ty1WopWgkzL9G7gYzwifzzaZryhVWK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62552073",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3abecfcb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62552073",
          "beginScheduleTs": "62552073",
          "lastUpdatedTs": "6269e796",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "199996",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62552073",
          "beginScheduleTs": "6269e796",
          "lastUpdatedTs": "6269e796",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E7MxhP6cGtML155dx8QauRLpjj4CFj1FBFmGdjaY4Ph2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Z3mEG89HKsCpSyq5xx2z7srNN2VgHLsp5Dn5TagZwnq",
      "vault": "NX5Kbxm2c7uwcW3C7um2ScvpobtULfnr3zJSkKFq2A5",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d0bd6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0c0f48a7",
        "accruedReward": "0c0f48a7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d0bd6",
          "beginScheduleTs": "625d0bd6",
          "lastUpdatedTs": "626a20da",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11ae33",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d0bd6",
          "beginScheduleTs": "626a20da",
          "lastUpdatedTs": "626a20da",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DDCBiRG3MCgte9UXV6uGWfWEfNwBVHkQmhpKChZJScqp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5ziJXQy7qrYYCowheEFr1bsrVDiXhfiEdx49r61MEWpi",
      "vault": "DLy9HqZAFJsAS7WyC3rhiYnSm82gxZS7eGVdAp4LX1Hb",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EP2e4rx4GSCaz4YZcadqUn1oNgMfBrUznTcfPBh35ZEo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GjKS1HxUmEQDzQ2q8iqe7N94C1gZqzVXE9xv8wqWqEJ5",
      "vault": "5hWKnyjfriF5bC1rR9mny34Feb9wToveSaEBYsGzhb37",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248250a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1118d88b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248250a",
          "beginScheduleTs": "6248250a",
          "lastUpdatedTs": "625b0d7b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2694ff",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248250a",
          "beginScheduleTs": "625b0d7b",
          "lastUpdatedTs": "625b0d7b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HGNSxc3nvjZW3FZ5Le73owkzPMxPgukyjpwEzZxTjWf7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CFmBpDyssLrZZYUdPkckbW88jahjuYACrTzct3J2CSjP",
      "vault": "EhjSCcG1hZTKug8CughAD7CPJ6DHuh5vutXLDqzX2UCV",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2610e08d",
        "accruedReward": "2610f559",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GaCmK9wCirB6VvCvtPkB35Mviui2HsDEuXY8kGyjWjv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "1UnJUH66vd4i9h8dp8adggiamRSLvywocda3H9eQa35",
      "vault": "6ECA52PspY2Qjfzd2VLDeKXEAmpfz3r2huLdNo5AewXj",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "068b968e",
        "accruedReward": "068b968e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DVeFMcuoqydRxYug2stuYBcr1H5ZMDbobyH2KxST9YNU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "84qe3TnLU2vBRcTnHSZptBjS8AQ4eQdoRYspUv5SYchs",
      "vault": "EXEm4NbR7eoG9JRtjLhG83LEdaWF4vRhusDAfyfTUs32",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "035a8787",
        "accruedReward": "035a8787",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HTsBuPsjejvpUi19S6xS9fpaRKh77mrgUrHpmyEFkLng",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AY9XVJhLbBB2wo3udRzTBDK5mpLRboK4coQdixzSzJ3h",
      "vault": "8R3Be6E1ph7PhBXhZj5vuPGoCeHP1LuxqJaG6yxw7mQt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6267a18e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "39cf7c42",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267a18e",
          "beginScheduleTs": "6267a18e",
          "lastUpdatedTs": "6267a18e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "07187b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267a18e",
          "beginScheduleTs": "6267a18e",
          "lastUpdatedTs": "6267a18e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AG6NcfrGtwy5LR9hESH7FHxiWxZmBPeQJ1RvC2WsQgKg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "66K1hLzo94kSb4Ss9gPRbSZGngvnky4aiBgws2MLpxMA",
      "vault": "5YdvKvbbYs8Zo91XMuNUBbFcx8N9eNTgSS6N3EYJZn8a",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6259f880",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "68614b34",
        "accruedReward": "686158c4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259f880",
          "beginScheduleTs": "6259f880",
          "lastUpdatedTs": "626873a4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14c189",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259f880",
          "beginScheduleTs": "626873a4",
          "lastUpdatedTs": "626873a4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7YgVQcovjMkBbigSoiRdkoTm7wqzfyjkHyLqiEMZyZk",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FiiX42briLcFKyvFGda7CUszxeW5qxucjMnik9MASFHK",
      "vault": "5pxuHjDfkMBAuHaHL5S3tq17y36JYdpkFQCgdEHbUL67",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "17d0de3c",
        "accruedReward": "17d0de3c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DxeT9jCYiJ1PaWmChyYE7HJkwao9NGAzw7bxfUh5SPCY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2PS94D76WNetGuEP38AxX391WPwJR3Zn9DLinpaGmVDD",
      "vault": "GjNq33dzm55Kbp2JUX35fmLX4rRWgXKyuFemdj7evAwQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6255b2d5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1078e941",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255b2d5",
          "beginScheduleTs": "6255b2d5",
          "lastUpdatedTs": "6267ea66",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "190734",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255b2d5",
          "beginScheduleTs": "6267ea66",
          "lastUpdatedTs": "6267ea66",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GSD7SBRuMr3YF43hqMYxtPQ7rBWVhyagkd5g7QNDVmXp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9CPZ8m6oVUV4r4WSQgzHgeWtGoGddb1r3qmeXGXXG3Mh",
      "vault": "DBGNk7t9GJ6sVm9qeNEQgBgbWfKDc4dy1LUmGCoSKqvT",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3137a513",
        "accruedReward": "3137a513",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8GsSkZ53cTswFodk22ozqcLtbssKaVkf8WDEnosRUH5d",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "G4abZdiEFroBdkCgKNaFJQnUnjc7HYqUBjcQ8kC9KvKk",
      "vault": "A1wepjNyXAfuajWv3tBeWCdiq8Ek5UDzC2TyF9LjtLBv",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2fd42MNzYmcyvKqXsV8fSZ5muZtr9G1tTWGWuFzRj4oC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "48ojijVnswnTGiQaxmEZ7gKLhW1yKzQ4oBrji1hMYxVg",
      "vault": "GbaWtz3McfEjJBJkvEMYvAQngRWc92wzUwPpFE987XKB",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625bd151",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "26f3d669",
        "accruedReward": "26f3d669",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625bd151",
          "beginScheduleTs": "625bd151",
          "lastUpdatedTs": "62685b71",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "12e8b8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625bd151",
          "beginScheduleTs": "62685b71",
          "lastUpdatedTs": "62685b71",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "hYUeNK1Sqq7RdJTq61rMqbvyonHCLrAwzArJtfWZJh9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8fq4LvGbaoNbu6hFb6d8iwNc9GVKfjtmea9MKgWzC7Yt",
      "vault": "534gT7iehsG234hzWFRLLddkX2wsaDC9DUR1hL2og1pK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6265f632",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "4cc7c3b8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265f632",
          "beginScheduleTs": "6265f632",
          "lastUpdatedTs": "6265f632",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08c3d7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265f632",
          "beginScheduleTs": "6265f632",
          "lastUpdatedTs": "6265f632",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DowUARowgtmCXhGybL7jVtxSFkzYYKpxgHS9KM3bC2cD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GemSZ2vK4zydi91uviwFFy42YTuLQpYryAaYYjuVD9Dx",
      "vault": "4HDr2Ro65sAddoz9y7BrRr2tCvx6hwxNnhT6SNXPzMwP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62476d85",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "12944c7b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476d85",
          "beginScheduleTs": "62476d85",
          "lastUpdatedTs": "6251b390",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274c84",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476d85",
          "beginScheduleTs": "6251b390",
          "lastUpdatedTs": "6251b390",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4y9iobh5AhtfUPNX3hmP9jrZghMQBeXYUXHVXFHAY1DB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6UUdr43E3b74zNnbshao4vF6P87PHreAaEkCatRfTWRX",
      "vault": "9H4ZvpQkaUuXYXx9iLaR5HEQqY4TDuWwCAiPs7ZaV5RX",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "07d7374b",
        "accruedReward": "07d74791",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E6aGW1bykoHsPYgf6aPrcGx5i4jaLng6FZbXPtJjrD7h",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5HoJrgcH5woQ6KegEK64Xx55oQkmZiCaJen413y9Wit4",
      "vault": "AWqTcRft8dFYDkTWBr11sm178xDN753289nQjhSRRXZv",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625a3c2e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "21565a8e",
        "accruedReward": "21565a8e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a3c2e",
          "beginScheduleTs": "625a3c2e",
          "lastUpdatedTs": "625a3c2e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "147ddb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a3c2e",
          "beginScheduleTs": "625a3c2e",
          "lastUpdatedTs": "625a3c2e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AQgwNApQo7AxjQ4dS1MDVxerRjzJWvPjAufPZzMnjBxy",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HMT1Z4hQRQzWuojRehkMkQTzwyBLtmwnjGHcyNinh5Nj",
      "vault": "4rxHbJiM5AaGSAMPuKdqW9eP213wquF7sUE694rBMGtM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "624c0efb",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3eafa3e2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c0efb",
          "beginScheduleTs": "624c0efb",
          "lastUpdatedTs": "625d63d5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "22ab0e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c0efb",
          "beginScheduleTs": "625d63d5",
          "lastUpdatedTs": "625d63d5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9Vfy8dwhqNkjwsRtCRcpZgFRkH8yyGAqnVCTvacM9cmc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ABasoHakWj8Hm2ENMF2YaTgJZwidhrkY43sv3inHD1RM",
      "vault": "9bgXVaRJgNw4H1EkaThTypQVGF19a9dxXezewyKY6JMQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62633d1f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1257c129",
        "accruedReward": "1257c129",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62633d1f",
          "beginScheduleTs": "62633d1f",
          "lastUpdatedTs": "626a001e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0b7cea",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62633d1f",
          "beginScheduleTs": "626a001e",
          "lastUpdatedTs": "626a001e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BGcRtP6JzhRaNXCrNg8vVTZWXoESuTBfKbbVK5AavL2T",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "J1VXJu982QBxGwyucdT6Wm95J6R9QnN8KKxz42bvGsGM",
      "vault": "CDi3qaoR2GutdEKYNKfu9BS3j8Yneobng389BQTfb5f",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476e47",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476e47",
          "beginScheduleTs": "62476e47",
          "lastUpdatedTs": "62476e47",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274bc2",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476e47",
          "beginScheduleTs": "62476e47",
          "lastUpdatedTs": "62476e47",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3VAK6TycPViR5FnHjo22MKmxTx4XD7NmkxN5GjsAug3D",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CWdRjGAL13FfuAbYqcuoyTLrfWtkfHrS5dR6fWSSxWW5",
      "vault": "4Tc2kiHR3dRMoXs5DJ5gqujQV2DP1rR9uc1guZNwyxcW",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6258ade3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0e8ace31",
        "accruedReward": "0e8ace31",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258ade3",
          "beginScheduleTs": "6258ade3",
          "lastUpdatedTs": "625cb32b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "160c26",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258ade3",
          "beginScheduleTs": "625cb32b",
          "lastUpdatedTs": "625cb32b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5iotdC8houJy3MLv44czgjgThfn8S4B24jLL6NzNHTiq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "L7Skg83vJoGZSJQyCqox8MxhfdPuNfYHmkjbJ2GJkyN",
      "vault": "DMEhUsa91DniVGCYmze2L3rNDVYBEwXTcL74Vuur8Nvq",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6257a3ff",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0e56f175",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257a3ff",
          "beginScheduleTs": "6257a3ff",
          "lastUpdatedTs": "62677fc4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17160a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257a3ff",
          "beginScheduleTs": "62677fc4",
          "lastUpdatedTs": "62677fc4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HeWm8VRr95QP1UVAKgzQEaF11JcdHmUqc8rCVXEw8yX3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GYNT1qUj9Ew45WpHaVZqGSz7R97ve2EjJEEfTcNVrJvQ",
      "vault": "76hxrLakndkDf593ihywjLKT4c99W2HYd4S1CxfTZNvm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6259af10",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1684929c",
        "accruedReward": "1684929c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259af10",
          "beginScheduleTs": "6259af10",
          "lastUpdatedTs": "6259af1e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "150af9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259af10",
          "beginScheduleTs": "6259af1e",
          "lastUpdatedTs": "6259af1e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "84T3CLKHcy1wyLmSBD5xuFEFBjrUUHc1Eu2asbXxEX7c",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6K2d9LvM3i47P9FxzEQP4hXZwiHA2c2wgfzGTeTvJqj8",
      "vault": "AtmyS9qDQJnmGYsN6T5KxYK74orJA24WbTYRQCrBY1UQ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "01932579",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6EojeewqfuBpNF8qmY59WqBifwKV53bxzQEa7dScKZ5T",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AKnYv6RgtrTjDvZ7srXcmKYNoTXEi4mkbGmerb8CE8tc",
      "vault": "9qidsiadQapKssZ9zxXesSVtwjN17Fr7cmZf1xXYDt5y",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6266c598",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "036ad485",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266c598",
          "beginScheduleTs": "6266c598",
          "lastUpdatedTs": "6268a957",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "07f471",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266c598",
          "beginScheduleTs": "6268a957",
          "lastUpdatedTs": "6268a957",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GstW4rQUsomHfXBkKhoqSCX51FJK3XrqCapqG1C4W4MC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Aa8nvBs36EZeXAAaWvNU6aag4GzF1ybFqDbLhTpFwsYB",
      "vault": "4bT13yBGKLDbFdKpbVNGuE7PxppnhfLoCZwFFdpn1yUi",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d3ae6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "07895353",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d3ae6",
          "beginScheduleTs": "625d3ae6",
          "lastUpdatedTs": "62628bcf",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "117f23",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d3ae6",
          "beginScheduleTs": "62628bcf",
          "lastUpdatedTs": "62628bcf",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Anq5YjgnDyRKtS86NdJW4cpA2U2YYkDw32qEJgGZnmSu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "21oUygjRUp5VGNKMJ9SuJpfT6LvnKn7ZDjCEHxxachXt",
      "vault": "2GnJnQV1P6355suyaEZdYSAj39E7YgrEYX7xCb7dknhH",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2ae1b94e",
        "accruedReward": "2ae2651b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9aSbtEFs5h4Q92ZhzHgsSnVjJQAxKpU6aPxxEV5JNvvA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "62zBEjJPAsRxQMcPs6vXpRnADyBGS6mQZFCSJAsZ3Leu",
      "vault": "5cmm1hkENfyuCnuhkpSFCrP5ZP4HjYtvDeQtcaomuEFh",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2c8b1ade",
        "accruedReward": "2c8b286e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4nANY4qyk23cU8xbwPXR6xcCN6nC3vPMY2f3ipuzLBLY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Aj7RZJdT3Yisgr21eSeDijzckTRqkWJ8MoF7UnzTpgsZ",
      "vault": "3v1E8ihw36jRj8zXLyjjDXsSFZ38gAtuc2snBioP1NqK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62571bca",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0c64ad39",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62571bca",
          "beginScheduleTs": "62571bca",
          "lastUpdatedTs": "6264d084",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "179e3f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62571bca",
          "beginScheduleTs": "6264d084",
          "lastUpdatedTs": "6264d084",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DtfrXbnPRkKU2rG3BFAUWDp7NPTSmU3CH81oFxUY5Pa2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D5dAjr7hbKbS2SXUroTmG2UTgRXE1p2TAUJZbtmREu8C",
      "vault": "6LzsVboMkSo2BaR1KnmuH8qzP3DjR8dD1hBTHUrQtQNU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d2de9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d2de9",
          "beginScheduleTs": "625d2de9",
          "lastUpdatedTs": "625d2de9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "118c20",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d2de9",
          "beginScheduleTs": "625d2de9",
          "lastUpdatedTs": "625d2de9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DhK6Ah7J2E5WZQ1tQQAgRkgUD4uztKHa4DKPjtofzpj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2xMFVg9ZJNvtjCSBLVJYHBi3XKxZSuBWYaXs3pG5zydw",
      "vault": "5NPJseprCCLtfa2T63yB2MGDR2LAXsg7RmKMs3r917tT",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6268ede3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3895332d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268ede3",
          "beginScheduleTs": "6268ede3",
          "lastUpdatedTs": "6268ede3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "05cc26",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268ede3",
          "beginScheduleTs": "6268ede3",
          "lastUpdatedTs": "6268ede3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2Nz8Jcn1jBFfEn6XBhNBKYV78xDj1VhPahLtRKrvJvD6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A1gHkho73zJz7vZ9qNTBwi252yQXd2tZ2VpQd5esZV7R",
      "vault": "7CixMqcztYmVu3kjJbfzxbf1D5aSH1RYE4oGJQWdfDMS",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1cae643b",
        "accruedReward": "1cae643b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3p9jmUKTqikKh6YiDK84ab2Pe59Cfo8UXqa2A1taD7by",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FHKn4Su1Tui3MMWXUwmR6KbuSuwR3h75SFEsU2aKkAQk",
      "vault": "DZEHTf5H94p3rwH7fhQjjRm1FhFVTcjKfRxxbrHFahRB",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248395e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "09527244",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248395e",
          "beginScheduleTs": "6248395e",
          "lastUpdatedTs": "62528896",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2680ab",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248395e",
          "beginScheduleTs": "62528896",
          "lastUpdatedTs": "62528896",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BJbnm5mGRkEM8ttpytzVXnDusNM53Bn5eCSJ1LMo3gqS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GJs4dv8nLZ4gdYPwBRiVoo5LnKFkr5VxoAiyx5RakqW8",
      "vault": "6YTvFANvyRYigLkM5YHpuor1nM9aFMCPXx2YWJDt6iTK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62476a13",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "4ac0910a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476a13",
          "beginScheduleTs": "62476a13",
          "lastUpdatedTs": "6262f894",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274ff6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476a13",
          "beginScheduleTs": "6262f894",
          "lastUpdatedTs": "6262f894",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EzQG4mXssbGrDph78uUKCdtXEswCdpcCrPgSCL3PsWY3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2guiy9Xjgo2HT4UZBnXniut6mnEHZgWd4in8akrRtfcj",
      "vault": "8bbnurziCtBjFcjW3UaC1rKuWWQ9dys11zPWhqpbsMTy",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "19736dc2",
        "accruedReward": "19736dc2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "C5H7b6ebVuFujZpNh3uSevjY6tijs1bHRg8fTkFPKxBH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3c4xvJdWq1meAYaYRcAQyEXXSuSMccyNFfAF8d5G7fLN",
      "vault": "3bPhJBu6VTAmmYDs3gS2A68pvj2Re1xKSnq2KiFVTN4m",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624a1209",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "35a28cf7",
        "accruedReward": "35e219c5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a1209",
          "beginScheduleTs": "624a1209",
          "lastUpdatedTs": "6267dd9f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24a800",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a1209",
          "beginScheduleTs": "6267dd9f",
          "lastUpdatedTs": "6267dd9f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9u6FzbGrFMT9LZWdaxsh8GPJh9vDdecbzknkMnCWDLsZ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5CZ7tRcsRVcAZo9rmB5H3nPPkdQm8oFcFKiLQeT3hscq",
      "vault": "4FZ2xc4fiV8Q5woGozhXv6vXFAoCaEs3KULaNXDxLbJ2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6257828a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "190c8610",
        "accruedReward": "1a4417b5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257828a",
          "beginScheduleTs": "6257828a",
          "lastUpdatedTs": "62695b15",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17377f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257828a",
          "beginScheduleTs": "62695b15",
          "lastUpdatedTs": "62695b15",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EzPg66dDhnkAtGBnjwtMSCb6MyPtTc5urrhTSUCoaLSH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9JUp4Lbh3Enwmzsikc2QYrJfydH97pbSh15pAwoeqitY",
      "vault": "EPWR794PJtUg57WZzMbFeQUv778ocYRSSJtpZGbwdW48",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DP61uZ6XM5S7Fbk7hLNT4JjNznE4VfSh4ZG4u5WvRfpR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A67UsxaSwE3ZSoRqf2HUcQhPChNsSjdfVtv2M3oncCdH",
      "vault": "7HaBuzbh8UcUv4HFdG8DFgo9Gb4iNUYWSjGoMVcTwUVQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626249ae",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "02bc99d2",
        "accruedReward": "02bcaf85",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626249ae",
          "beginScheduleTs": "626249ae",
          "lastUpdatedTs": "62624a6a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0c705b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626249ae",
          "beginScheduleTs": "62624a6a",
          "lastUpdatedTs": "62624a6a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4UGRbCSBRbSgfc5pkHpnPjcuhefb8ecP3yFkq43UvwZu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6XwZ7ay87oZDt9Cxodyv4WKB3oxi7RrCWdooEVvARiXt",
      "vault": "82mCEhwy9UXUciomkEUsr2A7AHrmXZ5J93FPwdRqZQf7",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62584bfb",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62584bfb",
          "beginScheduleTs": "62584bfb",
          "lastUpdatedTs": "62584bfb",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "166e0e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62584bfb",
          "beginScheduleTs": "62584bfb",
          "lastUpdatedTs": "62584bfb",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3NB56txPR7uCKPFPFXBRMrEBpWLHMYSeE1gQQ4qBjuoc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AeaxUMdiuHn38rPJ7C5T9Vnm1PDAHHZa7ymjfZSgSu9S",
      "vault": "CVbEtycA3V8YgL9ghykh4m3DVsVf4VRgYLZHkfh77Uks",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AQDa6SG55zW1awpKUqEBuB4T8stw2owdRSNs9xUUaGBQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4Xndb4GZaETyzrz3Q4nzHaKVMmNhH6FCQD112PG99pB9",
      "vault": "omGU9Dvq2NPaTi6FNrM6XXamuppv5ew7WVoJz3KqRDi",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247aa4b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "31faabca",
        "accruedReward": "3d8a4f9c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247aa4b",
          "beginScheduleTs": "6247aa4b",
          "lastUpdatedTs": "6269b1cc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "270fbe",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247aa4b",
          "beginScheduleTs": "6269b1cc",
          "lastUpdatedTs": "6269b1cc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "55tUDTFHi85DtZpc54qRT7qJNknUVXMextgUu6UPpw1g",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BBQgkMf3fFUwwusgwZADVbQ8xpcPVmdDwxX9zEocPC8y",
      "vault": "398eSzpMFjV8grJe1DBZqN3pkyHwbH2k7SFhC5mAkKrS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6269e084",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1d6820dd",
        "accruedReward": "35358d7d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269e084",
          "beginScheduleTs": "6269e084",
          "lastUpdatedTs": "6269e084",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "04d985",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269e084",
          "beginScheduleTs": "6269e084",
          "lastUpdatedTs": "6269e084",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4Z1cX4YBzhkzGB4YzRQcoVJKosjNwakwWkrTqeQzGZjY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CquLHeiyukMehc7n1ZqCyemwuv7sBuqgwwKxRMQegpYQ",
      "vault": "J8f2FcA7i3vniURezGK3LCGAdLgNBGEzKKByXqCDNNPZ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625388d3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "10db864b",
        "accruedReward": "10db864b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625388d3",
          "beginScheduleTs": "625388d3",
          "lastUpdatedTs": "625388d3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1b3136",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625388d3",
          "beginScheduleTs": "625388d3",
          "lastUpdatedTs": "625388d3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "sbWLwVTrpKbRs6QqryxwVs9yqDMyM4RRMj9UbcR2eAG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9J37G9wKxgqvENeDJCimECaVV6H9TkyZr6XphJ84J6Au",
      "vault": "8wVwTSQAyJMa6hF5mPKYsXagYVbiHa8B3NuAkx5z98ER",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "140a870e",
        "accruedReward": "140a9018",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5N1zK8y4MmKyNGekFJGiUdFQQfpyQQhJp9N6jnAQ4Vc8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "84UGdiyJBxCF4QeVaDrvMxFZwrvx1os6nSjrJCNRnnPZ",
      "vault": "7SvZgPF3QZWWu3Y2Lo7Vukh4uNETbR9iBoX4KcB2MNT8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625f90ff",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "02da9d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f90ff",
          "beginScheduleTs": "625f90ff",
          "lastUpdatedTs": "625f90ff",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f290a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f90ff",
          "beginScheduleTs": "625f90ff",
          "lastUpdatedTs": "625f90ff",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DV5NaoL5sEJQ562Ky3asAx9MpFwzwjmboyy1SqSJDJiR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6ShANG9edjbRBpX8gNCuA9u9veH2q3oNnm4zEzL3bzRT",
      "vault": "AVXowWupiR1wnJCvipfMVS6mXvd15mwvhADmLnejSsBV",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626658c0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0db79e98",
        "accruedReward": "0db7a4ec",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626658c0",
          "beginScheduleTs": "626658c0",
          "lastUpdatedTs": "6269b6ad",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "086149",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626658c0",
          "beginScheduleTs": "6269b6ad",
          "lastUpdatedTs": "6269b6ad",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3afgpk9irjVKbyqpZbTh7FSw3qphrhF9cgTiwMcPCDLS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FWuxVsEphYwKsQAmqVJ6Pzecu4HqAimzbWgYxYegk9SV",
      "vault": "5Bkaxvd6JEWWYp1aAwcZxMcCCLrCkE4rYQbwyXCTx39i",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5824g8AYQHzcsC42gtUQ7X1m2M5fmx69nNQWQVG2cC2s",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4vGHMLi64W26t6uKaJED5MZP4pGo911ToB31EBvdzMRV",
      "vault": "8MPd3Vp9YVskfyTCbRGWp4MWXdHqQAvVVWhPWLqE8ZSm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625f99b3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2a37e62b",
        "accruedReward": "3aba1334",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f99b3",
          "beginScheduleTs": "625f99b3",
          "lastUpdatedTs": "6268ba88",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f2056",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f99b3",
          "beginScheduleTs": "6268ba88",
          "lastUpdatedTs": "6268ba88",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8wSUhFdbjEKksBfrCCf7mHsAfbQ8nhePszayLUT1CkiC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DgTXUtJFvzBvM2XsX7P4vWwUp7aMgp7TCa6fngXdzwj5",
      "vault": "EtWSs4xxnHZ7j27XTom75djjD8dnkcJLJ6XVzkpkcFoi",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62483649",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62483649",
          "beginScheduleTs": "62483649",
          "lastUpdatedTs": "62483649",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2683c0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62483649",
          "beginScheduleTs": "62483649",
          "lastUpdatedTs": "62483649",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AdS4oqFRXK5yD511Xs2uq9USLiUUw4Pw6MsXDb841ysj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AbeKYpzFvFqFq8us67zGACjm6bfaK3vQA7QYmycSUpBS",
      "vault": "FpstP5e9EqgdbEpWL3SZGacjdCsQnBfxq7JMUVHUjfkV",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6258deae",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1aed0b7e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258deae",
          "beginScheduleTs": "6258deae",
          "lastUpdatedTs": "6258deae",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "15db5b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258deae",
          "beginScheduleTs": "6258deae",
          "lastUpdatedTs": "6258deae",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "A4mkVkX4Xm9ha6Mq7U24yi2E6G3Y9syYRvQE4mhaQQJd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ABwPxvhm1nAcbiEngGeGfP57rRi72u7jRKbifw46b88S",
      "vault": "FqXpd7hxwxpnFfYwGqQNijXZBCMUFwSHSbn96sp2E5F8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62587356",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0e043401",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62587356",
          "beginScheduleTs": "62587356",
          "lastUpdatedTs": "6267f39a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1646b3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62587356",
          "beginScheduleTs": "6267f39a",
          "lastUpdatedTs": "6267f39a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BDh1DNvEsA4jJG65mqUV6j9u9rjQ6GWincVYfmMLHPyp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CbgZN4FaBJ4zECpUxEYVJuxDu3z7Dkj7iQe6WTYBAFpb",
      "vault": "D9B7mh8Ce3VokCjjpABMkKwwfHKNm9ez3gkuy6wdnXWu",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "02123c61",
        "accruedReward": "02125781",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5qrUG1f1LCZWPrvSbd59uT8wWHbGWxBKNnnDXRwnP3Ay",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GQcSE2FvUo6wre3ECGWfhE8W858LzBL5fsMgpyYF6eM7",
      "vault": "DhKYvQrmfNcTgMybCeHxqtSMC6Fik8ZXfrgo7Yue9B45",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6254f5ee",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2400fe6a",
        "accruedReward": "2400fe6a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254f5ee",
          "beginScheduleTs": "6254f5ee",
          "lastUpdatedTs": "6268de8f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "19c41b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254f5ee",
          "beginScheduleTs": "6268de8f",
          "lastUpdatedTs": "6268de8f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CBPsqUA6LqacnnkopJ3YzAQpE2dRgB3M7t6FhEy1vrQf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7TztE1ZhnbnWUUZ5DY5WMiYwqbs2nX6VfddLvTtnWYnC",
      "vault": "9hdHcmopbgQuWdQcK6BHW27xj31fYC71QpdcHWbLPupv",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6264b1b5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6264b1b5",
          "beginScheduleTs": "6264b1b5",
          "lastUpdatedTs": "6264b1b5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a0854",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6264b1b5",
          "beginScheduleTs": "6264b1b5",
          "lastUpdatedTs": "6264b1b5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5B1zWzG5quVkNbkKdSL2sn6H2cGvQeTVYhDnxA7h29NS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HyaZWKcjUpqGBYYjeWVEBHDuJjg7oiYkrUcUPKjY4WmC",
      "vault": "Ct2bdnNHsstfMzLvhTF8L2EDzsE7ns44JAqeUM1dA7ry",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62691b16",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "77d521a0",
        "accruedReward": "77d521a0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62691b16",
          "beginScheduleTs": "62691b16",
          "lastUpdatedTs": "62691b16",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "059ef3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62691b16",
          "beginScheduleTs": "62691b16",
          "lastUpdatedTs": "62691b16",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "B1QNPHTJtqtYAR3KU1xeoaEd15LbpQSLGkuWXShJ8wDg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7QowEij29NhNGoFSrtUsC7LXqkbytrakB2b9jHGqqPHQ",
      "vault": "HskkR6LGBTYTH7nMb9zciD7Yv6rRiLbac2sucwz25pTx",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "bYdTDVCKopzNBHiGNz7GhTxJWkH7YqAM93EDbqqeiS7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FtHE32krv9e9gGC2MgW1bDf8675B8YwrMFpbbNeJ9kgR",
      "vault": "Fv4t4s8ahjR6UL8DQkKp1BcZzwVNiairKzogpQTzXJMY",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6266f4c7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "23afb835",
        "accruedReward": "23afb835",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266f4c7",
          "beginScheduleTs": "6266f4c7",
          "lastUpdatedTs": "62670199",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "07c542",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266f4c7",
          "beginScheduleTs": "62670199",
          "lastUpdatedTs": "62670199",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9sbCZoqvhH7UH7sTWzTXv9GUvST6wQUquZgzo8QXaLFU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Bq29FvhHuxbk3oDkgAHwdj2n6jDHEvM31xRgZYYoThoQ",
      "vault": "8GPitH4SEXfKaWv73JosjiZq7Xuc3ZFFtHCXFtBzSx8s",
      "state": {
        "staked": {}
      },
      "gemsStaked": "05",
      "rarityPointsStaked": "05",
      "minStakingEndsTs": "624a80ae",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "761a6765",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a80ae",
          "beginScheduleTs": "624a80ae",
          "lastUpdatedTs": "62646e49",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24395b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a80ae",
          "beginScheduleTs": "62646e49",
          "lastUpdatedTs": "62646e49",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3ikEYq9PayTAYTjrX5X9NYhbexm1aHVhc8ZyaEtDNfbW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3DSegsGoCKzaQL8ZJufGtsbF9fh7xBR21LmS6YDzTAVm",
      "vault": "FGAux9yqTaqgCsRTrmdbbWvm2PkcNumzePXWHPGwmtsW",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625b92ec",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2f98f491",
        "accruedReward": "2f98f491",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b92ec",
          "beginScheduleTs": "625b92ec",
          "lastUpdatedTs": "626772a0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13271d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b92ec",
          "beginScheduleTs": "626772a0",
          "lastUpdatedTs": "626772a0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7K851JxQkTc6L7rCtnKCHsJFkKxgYk5Ab8QPsGahxGxM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HoqH2fAJzwN5wXMKbDhmigqLJ7FGjM13b2zRmtbZgFo6",
      "vault": "BTsJzf7Sb4rxbTdfrTfj4GMYnKJFAJUnJh9kmPTH3rLw",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62543b11",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "500aef9b",
        "accruedReward": "500b56af",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62543b11",
          "beginScheduleTs": "62543b11",
          "lastUpdatedTs": "62693ea5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a7ef8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62543b11",
          "beginScheduleTs": "62693ea5",
          "lastUpdatedTs": "62693ea5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AmJD1aJSjoG8BMPeCc3B99grmZ88wALoK1YqSiPknHAR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7p4gr8kXHJbn7z1svZMS1zLy4Z6WKg8emfgCLh6SfUR4",
      "vault": "53wAgK22fewaeB9kJuh8u435iawUCgJqpxS7KLbQ8Euv",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1bb4148e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GLeQszP8sMYE5iwEkd6KcZUnEK9356bGD2fFh7aZpD7j",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3kgKQEQu3Hd8R4VyVDbWXaftCJiM4KigJMQqepcAPutg",
      "vault": "BvVpXquCE6aRcGWKjpi5c9d24eKgrbRqyrKFZTsTsDQA",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6249454f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0f9d7fbc",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249454f",
          "beginScheduleTs": "6249454f",
          "lastUpdatedTs": "6258ac58",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2574ba",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249454f",
          "beginScheduleTs": "6258ac58",
          "lastUpdatedTs": "6258ac58",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Hf2ASdHQnDTE1FKR587oKKde9bCrKZM3rt5D8Nc2czy2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "H4VmWvVsKms6bt47eRtjGZDfJQWbpxaJ5Pw6iAzwqfhR",
      "vault": "MHVY8AE4BP7iVPeq86EBog59QtH7n45aRzBgY7hHPyJ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3fTCn7mypb33auLHSGc4DFFN5gnBpryrF3bYsi179P2w",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "77dcZJoQ17rSrxmufjktreK53TRJD1b9r6LSi2rrMNbv",
      "vault": "Cv5FoxFUcv6o2EcRDUZUX3XD2TtwgTAQZHM6sHECBykL",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6256cc05",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "22217c80",
        "accruedReward": "22217c80",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256cc05",
          "beginScheduleTs": "6256cc05",
          "lastUpdatedTs": "625b912d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17ee04",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256cc05",
          "beginScheduleTs": "625b912d",
          "lastUpdatedTs": "625b912d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BTcomgejBMWFwYD4xjMGq4W8EGY7HS61Zpd5HxPNbAXU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FJw7RyDD7r6SugPs1px9SbcQmGcynDwT9vhiGudujxp1",
      "vault": "8A5a27kfZgneZnwB4Cm8opjRMyG869GGmLT6DX7caU7Z",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62647b44",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62647b44",
          "beginScheduleTs": "62647b44",
          "lastUpdatedTs": "62647b44",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a3ec5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62647b44",
          "beginScheduleTs": "62647b44",
          "lastUpdatedTs": "62647b44",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GdC59w8yhp9m94d9gMq41UfHjJ2hMEQ5S51VcHkXbgFM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6oHuBMqJsfsZkn18EAGUmUoumJRe5RSHkeRvRURhfaGh",
      "vault": "B5mJ27H5bGEtzvr72g5WRT55DUaVbtb95dVMqjsPfdWQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624768bf",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0f747be1",
        "accruedReward": "0f747be1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624768bf",
          "beginScheduleTs": "624768bf",
          "lastUpdatedTs": "624ff486",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27514a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624768bf",
          "beginScheduleTs": "624ff486",
          "lastUpdatedTs": "624ff486",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6rY8dzaCefaYhRdZKNcPPZh826ff9KqEcxRw8jB5xXas",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DCRNjDT12RoqNHbiCADmyhRw9meDVD8mukKfREWkwGML",
      "vault": "4ZtSFfZfqmvbbMwVFWYawYLoYsQRX3t9ACMSff4JsmWz",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0a7cfc35",
        "accruedReward": "0a7cfc35",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8QjVmGm5pwAv8DBFr5KLP1iK56ndbKbaj8BBSTEFyP7t",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7C553eF1TtsbaaY3E3d9wKVNyVUxZsjNuatcbFCyM4G4",
      "vault": "AEk1h7tcvgWgjYaNm9WLZHtgyfUN1UigVSXXgoDfckY9",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "49027f6c",
        "accruedReward": "49027f6c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2ijocBvRmMuzw1ErBNoEGiU34Knwj27qWKgDfs67Jd1e",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HQjZokJ84oaT7HCCpCThLRwYiwgYLrzhu8bzrmH5Hozw",
      "vault": "t8jGDAoupeSF4U9AVPMhpqZWMBgekzsqL2rXKQkrGtz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "626789dc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "6c81",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626789dc",
          "beginScheduleTs": "626789dc",
          "lastUpdatedTs": "626789fa",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "07302d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626789dc",
          "beginScheduleTs": "626789fa",
          "lastUpdatedTs": "626789fa",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3CGqW3ZDDdPVCzRTiscszNyKkeKC3raUqsT1BDjVeGAt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7Eqqth27sESbRWvr1saSATGTyg9mBX1ych3PQXXTGLa8",
      "vault": "2TYxpTaYtdaqJow1yppiW95zrZc44xNSGRxcT8Dx6uJG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6268dbcd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3263063e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268dbcd",
          "beginScheduleTs": "6268dbcd",
          "lastUpdatedTs": "6268dbcd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "05de3c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268dbcd",
          "beginScheduleTs": "6268dbcd",
          "lastUpdatedTs": "6268dbcd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9WZ6UHGChANkMVhuYt5EoaTbDwT6vzUjsX13EYHzM55h",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CoFjjrXyT5Jjmg4ZajhmHXaykcSgJ1Q15K4HY4jMpnbn",
      "vault": "2T3kafd2VpXqjEpJHdzLt44yaJEBPHKfYJjr42rgSDYx",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "05f1ade0",
        "accruedReward": "05f1ade0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CaZ94VdNwT6qiLo9Rk2XbQiX36gAevu7FDv3ay35w91J",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E6m2ZMzq8nyCjdGTfNmreu7JEKMdk5Yxg6xKQRbwnnwD",
      "vault": "6NbYDdKV19B3YikNQeEvJi2NvTYdNHCWf54j8ND2qjZD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "07",
      "rarityPointsStaked": "07",
      "minStakingEndsTs": "6269cccc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "f71c4146",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269cccc",
          "beginScheduleTs": "6269cccc",
          "lastUpdatedTs": "6269cccc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "04ed3d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269cccc",
          "beginScheduleTs": "6269cccc",
          "lastUpdatedTs": "6269cccc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3TYMgpzrdb1ZZ1gw28wcwQva8B8qv4SZsRKBVpCmmdaP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "H5bcGUJHzRcWLfw8vSe99LH4vYJMvmgSbSb1dtoMay7B",
      "vault": "B9FuUy59EtNRUJfs9WnGvAjcSJL9ebDk4GxeNtpVB7x4",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624a87bb",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "34bece62",
        "accruedReward": "34bece62",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a87bb",
          "beginScheduleTs": "624a87bb",
          "lastUpdatedTs": "6267b23e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24324e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a87bb",
          "beginScheduleTs": "6267b23e",
          "lastUpdatedTs": "6267b23e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FDW8UJoNPGopdAjAP3w2Pj8t4K3F6cR4ETX2zEV5nSdB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Brq61zZrvvePbP8Yy3uVnkinQuqfLktwjaPRNYsyfPrk",
      "vault": "HzTVUAt3brykU3i7uvxRT8vxVcsnmZqdnuzyqTBwofRa",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62513b5d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62513b5d",
          "beginScheduleTs": "62513b5d",
          "lastUpdatedTs": "62513b5d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d7eac",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62513b5d",
          "beginScheduleTs": "62513b5d",
          "lastUpdatedTs": "62513b5d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2Qh3S8XafDvkSsm5Sqce9So7g3QkarRqmJGvFtQyck5j",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Gw6gH7KFwvfvsPqbixnpP11QJMumAP2hko7fXncnNp1P",
      "vault": "Aamo9pRUJECeGhznGjwk4ymugNUycQdMXpdPAYu2v6mW",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9BYZgrV226VB6epxvAkSmzrepXAMQ9w7V4fKCEgqsi4c",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F7tnyi9dUdooQEE1FAohG7tQMn38qJ19Jsr6vC3CsrGr",
      "vault": "F6jojAGxhqvjEjpwjYi21ic1KjcWejoBg5QnDtEw1hG9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62567df5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "176559a7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62567df5",
          "beginScheduleTs": "62567df5",
          "lastUpdatedTs": "62567df5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "183c14",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62567df5",
          "beginScheduleTs": "62567df5",
          "lastUpdatedTs": "62567df5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FmctuV5steuDmR4CaSiw57L9N1pZLGEXbQ125V1dmR32",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2Umyvbh1ricfZEnvv87ugccZNbvyXUhXoUdz1LQ2ouva",
      "vault": "5rYvb6Tqan9ej2M4MMAeCfuAhBeUWM1vVvmDbBrLMScL",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6260ce6c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2ebfe9ce",
        "accruedReward": "2ebfe9ce",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260ce6c",
          "beginScheduleTs": "6260ce6c",
          "lastUpdatedTs": "626548f4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0deb9d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260ce6c",
          "beginScheduleTs": "626548f4",
          "lastUpdatedTs": "626548f4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6JAMJH9nUbDWfXMRcwSrGQidcQ7NuL2aAjxC8fK727b7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CxGKMoVhHrSgvPaGCcATCB46L6x8nx846czte2vNFUfJ",
      "vault": "628uPeEDCcuYi41cvf5v1P6B8wqhbtjdCGBhwX9TtAgL",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247c73e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "143da7b8",
        "accruedReward": "143da7b8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c73e",
          "beginScheduleTs": "6247c73e",
          "lastUpdatedTs": "625e29bd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26f2cb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c73e",
          "beginScheduleTs": "625e29bd",
          "lastUpdatedTs": "625e29bd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "F55qteBGem2RPc52iwzZB8Hz5RxB3aVycZ9SvpNnYHrs",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HcPGKm6xcq14M42D5YZWUBZVhtDrq4qBcKNFqc8Tcp35",
      "vault": "8dXLAkQS6rpNEupiyYwCzMkrSg7vTR9SNZBqerXtKNfg",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247c2be",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "12f81fad",
        "accruedReward": "12f81fad",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c2be",
          "beginScheduleTs": "6247c2be",
          "lastUpdatedTs": "625cbd3a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26f74b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c2be",
          "beginScheduleTs": "625cbd3a",
          "lastUpdatedTs": "625cbd3a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5oqGasqWywVh34gobXv7by2uZkzK9L75gAdn8ko2UP5k",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EQAUo4dLknwW52VJ9ZxnmTezWcvvTeaJaByiqEc36CqF",
      "vault": "AECdNxR8tX59dyrJBuFZNUJJf5jmTksmN6Wquca3MWhn",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625b008a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0a9d26f7",
        "accruedReward": "0a9d26f7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b008a",
          "beginScheduleTs": "625b008a",
          "lastUpdatedTs": "6266bd5d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13b97f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b008a",
          "beginScheduleTs": "6266bd5d",
          "lastUpdatedTs": "6266bd5d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9X8PFgwiiLDYpuPKjGLF7AJx39uE6PmbnYJE4sbETRth",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GFLZaauKSHVBvbawZJohA5S3jDmoMh7GrC6zgP55XAGZ",
      "vault": "FeC67bM255eAyv3wcFJojoyC5cya35U8eVwME2J51Jyd",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "053456ea",
        "accruedReward": "053456ea",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6SJhDz2xdUiyWt64qhUUEtcAb13zsAg5ziDbfR32zRna",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9kZF4y61mDznanMEt89NNdDBBqgKsPGk4smsy9sqbuqo",
      "vault": "6iJgZPNn6HCAhkARZi4af24xXc1GUMMUuuqYqsjqeBf6",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "YrwaXqvcqFf25LP3S4oN7UdBypKqsrKqcXhBoZC3grQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CFKPo1TMz2Zok1tRztxSuwPBc2qMz6FsJH4oE7iD8xR9",
      "vault": "GXwdNQyrnQZ4jhCBehioqoKfNqHQu9wqFRhh2SeP8mYc",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8DQNc2mWVzeUjHyLa2qoMZF3FSgrRxYRLnHyFByqyEGd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "93BypBBUnHjV5r1uVAxVTgng7jPNwGES96PUyfmTkjxR",
      "vault": "G6mgEgXMvZS9UKQgjANbZSDvUemGsi4KcLNv95rir3sR",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9Lu1oSRJXt295xzY89Rfkt3kLi9eD6c3fdLV5qEVpKxm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Ev1CHacTNNsFpdZj5ZWqMjgH2E8LCJUWEsggaybS1A5v",
      "vault": "CvQcs79TbmdxmxtwiGcxrzkcuiaec8N9TvM1keeV68oV",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0c437fc8",
        "accruedReward": "0c438c70",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HxgknfKSEnUmt4k6RNGBMAvFNKLMP1UA2sk9qfVb3N56",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "31LKs39pjT5oj6fWjC3F76dHWf9489asCthmgj8wu7pj",
      "vault": "4rGS9fRHz3NzC8fPNp968PjijSbTedLDDHbgzLSzrfXp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "626768b1",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "38611725",
        "accruedReward": "38611725",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626768b1",
          "beginScheduleTs": "626768b1",
          "lastUpdatedTs": "626768c9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "075158",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626768b1",
          "beginScheduleTs": "626768c9",
          "lastUpdatedTs": "626768c9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "65WXAu8KmejjNKJokLhN8F46K9HW6YspDnwVaVGad39h",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "78t5qyEXbGQpmVsLRLDJsaeN9ddKFBePN5AdsQZcqiAo",
      "vault": "BWDDRihzfr5GrGiDe5spD3JLaKB2Xn7cHQWqX4Mqi7uC",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1549f019",
        "accruedReward": "1549f019",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "outDLNr5SvWTnQDrreqaUUTedYg25YyBYLvFd2Jxg7X",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AGohpRN5wizXUFTkyGDZsKJ1Pm9FLLZTv5tynZsufj6F",
      "vault": "Dcg7ZWsESoLsRFYcYqgr67ohMyW3BpFgJkPaFWzxfvfH",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FazGJNFG1k2n4S57VeLGbpbDaLV9LAizCzeixovS431e",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "J9FyBWro5qTXvNimCJXEG9DVZ8HRk3WWyFHfhMZjdeQw",
      "vault": "HrfB1BXpEG8E6CTE2eAm99fgHjge5i4YgyjuLKxqRwr8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625404c1",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625404c1",
          "beginScheduleTs": "625404c1",
          "lastUpdatedTs": "625404c1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1ab548",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625404c1",
          "beginScheduleTs": "625404c1",
          "lastUpdatedTs": "625404c1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AVwyNThxh88hWtajfrKxn5MbvYM1LVPTTFhGyJedMS2X",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "JAVY4rjWn2rG1kuF9wHyT7f5mLbNADnUkkHxPgukYWMV",
      "vault": "EQmBQYemFRgif67yKAGGhYvzQJYuUuUjcceT8pS7kqUd",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2ex1xdo9qwPNqac8QdSvK6zY8prZHWvodfCMPiotQ24c",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5Ljmf1dEHcB3i7FEyNkEADf8ihKST8wFFv82UuYafGMj",
      "vault": "B9r3GmgWNzEaJcE8RzvNsPvt2bm9o1X6fViUyWpHozzH",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "62549c1f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "90c30d7f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62549c1f",
          "beginScheduleTs": "62549c1f",
          "lastUpdatedTs": "62674fe3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a1dea",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62549c1f",
          "beginScheduleTs": "62674fe3",
          "lastUpdatedTs": "62674fe3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BYFQc8AXRGVCsFFUa2sbSDZj4cqjZaD7aAKhEAtFzXVe",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A2mkz8Kk1iBhHBhtpgqRAMcegiEWG8b7pyGfqSxKTeXY",
      "vault": "BvaKHxXUGXgQb1FFxV9JnzsJ1eAgFH8CiR48oTuxdcLS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6248787e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248787e",
          "beginScheduleTs": "6248787e",
          "lastUpdatedTs": "6248787e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26418b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248787e",
          "beginScheduleTs": "6248787e",
          "lastUpdatedTs": "6248787e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2R6usyw2o2LDedG4FhcHsYJhoBUu3KchkMfmjMeqCQVS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3krTYRuL7enyQeFpRNdKx2hmder8H16LECQcvzzJYxF4",
      "vault": "3Ree5KdaHzDBqMaTiKEwcQC2sGvs35wyxShKhVb4cBdj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6259b803",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "075a163c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259b803",
          "beginScheduleTs": "6259b803",
          "lastUpdatedTs": "625c6dd3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "150206",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259b803",
          "beginScheduleTs": "625c6dd3",
          "lastUpdatedTs": "625c6dd3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8Eg5Tf8iDDU5yyeeLW5Ji2XBnGSaTYxpppo7EXLfB6g8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AthFX4REZEFQCNXePxbiRz46SgVQfsMY2jyQrJD2ANo9",
      "vault": "8281BZpEHwu8cma1zf5rN13oeecRVgiXsNAkjGnVQhiB",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "01b5b37a",
        "accruedReward": "01b72ba2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7tZHyuUt38LhZzyKKb4QGX2vq6GJfCjpn6e9vxSUvRGq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FiWjYpfSQEm98VayoLxVK7JfQTFo34qxsrDJtaoVc1aq",
      "vault": "9S1gSuQtLV2SCAALoziBKbz9rpNFL2AjZdhxttFvhXcS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625870f1",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3eae22a6",
        "accruedReward": "3eae22a6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625870f1",
          "beginScheduleTs": "625870f1",
          "lastUpdatedTs": "626a2638",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "164918",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625870f1",
          "beginScheduleTs": "626a2638",
          "lastUpdatedTs": "626a2638",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5rhiyv2cTGtrc6HCCmeeZ597e4mvFBed87sGace6rDAz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CDWewi5GxN1f2fAVcBE9nAGq6uTAmCuTfHUBhwfr84Ch",
      "vault": "CcF1emmmUpnHHpXF71nEA8toGxKy2dJ3MxQbRsct7aDk",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6263ae51",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "11594cd4",
        "accruedReward": "115955de",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263ae51",
          "beginScheduleTs": "6263ae51",
          "lastUpdatedTs": "6263ae51",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0b0bb8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263ae51",
          "beginScheduleTs": "6263ae51",
          "lastUpdatedTs": "6263ae51",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "euz2Hf1AVMtt1oxZ4PbAJM6VfzRMZyFM8TkbgPZdeLZ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7qNkPGBDUNuWDEfaA7QuPjC9w6JTvBGQX6ALHs17d7io",
      "vault": "7B6VNccxbgb6QfbMUXDtreWrTXFqh3FyQQe8Sucb78Xm",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2mQPPcgFgrGqTFRm88HPhc1zejtshxxc3mXYxgozjFf4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3EffUmDXa4Xa4ggVaUKa2GZeCMxLVJ1U2NLaZsUaHSKS",
      "vault": "BJmbHiuj8Dj6AZqbCzkgPDEsBKadQmjWvKXnif3UvfuY",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248966e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0effef2d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248966e",
          "beginScheduleTs": "6248966e",
          "lastUpdatedTs": "62592d17",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26239b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248966e",
          "beginScheduleTs": "62592d17",
          "lastUpdatedTs": "62592d17",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J5rqrir7YFg3BCMHnDSxzU8m9AQ1pe5uaBt5ByayrAnq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9yK5kAA4iRodZLxEC5JGCPBGi5XnFfhpjaJkGzwKT352",
      "vault": "5fLeVGvXo7XK2SuvZFxTvZHptK2xMkiNqY67q3wmTZcp",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8uRoeqrcfCWmUo9QRMiGnGMucjxDbgoweqDDKoaGLKa6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6eymKiVJex1D6hBDmE3bqreJuJjs2Bvzp4AiA9giP8Kw",
      "vault": "4CkskqV5UDwPgu5tAAFGhcTEkiYpR9Xf25esKZYDmXXD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "626ad7f5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3d3effbe",
        "accruedReward": "3d3effbe",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ad7f5",
          "beginScheduleTs": "626ad7f5",
          "lastUpdatedTs": "626ad821",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "03e214",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ad7f5",
          "beginScheduleTs": "626ad821",
          "lastUpdatedTs": "626ad821",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "322PrVexLCRDsEUFb1jna5S1aHPQPn1vur7tWxpaq2XN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4Ezdv4Eb78BqL7PYJdF1Xx6dnXFWqdVRFi8Wo95xTFH7",
      "vault": "DDyqzxcXoepyxZwJtQyDGMSppFCA8PHJC2xZUGxQeHA1",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62513265",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "58586115",
        "accruedReward": "58586115",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62513265",
          "beginScheduleTs": "62513265",
          "lastUpdatedTs": "62656c79",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d87a4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62513265",
          "beginScheduleTs": "62656c79",
          "lastUpdatedTs": "62656c79",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FobRazGrcgihmbcsYtCywy3rvoSEXGdUNmDfdRf89Eom",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "48EFQmsTaGY7ZsZUDtiXeqCYeGeBvioseHBsfRVVFucv",
      "vault": "7LUVnZX91o4m2eXtvKUkeJrt6fTKYmmPW496Ci8JLbTU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6259fbc0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259fbc0",
          "beginScheduleTs": "6259fbc0",
          "lastUpdatedTs": "6259fbc0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14be49",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259fbc0",
          "beginScheduleTs": "6259fbc0",
          "lastUpdatedTs": "6259fbc0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Cp546vMQZ2SJvBwtc1tNJmnCjLWx3yYYqxdVRk5dF3SR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7XZRi26SUCn32NhfAqmG9RF6ktLqSpnpkGuz6aGwFMb8",
      "vault": "62yDm9Fmzazf99gsH5BE9Ld7uTCkyLnecaBhbRJryVsZ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0b18a2ae",
        "accruedReward": "0b1955b7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ABuWZuV8WLtjcthdcoNVg5SW8uyPUm7ZfiBT4C2fXfvn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4u7NepeFZb2LKYhkPdHjSmJU3pqKsa78KxDekbjG4fBL",
      "vault": "9HSJTeEsNyN7mXD1KagPr5AXSDrkvRMi8sdVSStBT2HA",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6249f4af",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249f4af",
          "beginScheduleTs": "6249f4af",
          "lastUpdatedTs": "6249f4af",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24c55a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249f4af",
          "beginScheduleTs": "6249f4af",
          "lastUpdatedTs": "6249f4af",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2uYc2AMkozSPMENkeN7jPhJjgxQKS1Ad49j7XDXKRSqK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CERCxxrr6A4YW3amnKrRU7Xr9F7Hxtbik7fqNrXp18W7",
      "vault": "9mRtZsw3W3fZr6BFm4Q9fW7RxtpbYGE2AMo4HN61RUcW",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3zE993UuD9rFFvHXPPjrmhmjqUdh8mvwKwwbSajWq5C5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3RtPrbx3V7WsCmrayxxV5qaE3ho9euetb556CK1tF6mi",
      "vault": "HJKwA9AQ6J3xEHgFnSZScotP5iQG18EcWcvdti4cUMxz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625ae7c0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "bb2c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ae7c0",
          "beginScheduleTs": "625ae7c0",
          "lastUpdatedTs": "625ae88f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13d249",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ae7c0",
          "beginScheduleTs": "625ae88f",
          "lastUpdatedTs": "625ae88f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EigFYRnQ21m2yrm8knGmcBLvrE5eqxWmhSi8CpTBj9ds",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "G9jZcifakSp8VGbyJ4m3EHmAHFaH9bgEkZbbAuH7tgsF",
      "vault": "DyjEMMVVtsecoJFye7bcNmjBgiiDoBix7YPannR2crqU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624fcbd4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2e190e3c",
        "accruedReward": "2e19de34",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624fcbd4",
          "beginScheduleTs": "624fcbd4",
          "lastUpdatedTs": "626949ca",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1eee35",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624fcbd4",
          "beginScheduleTs": "626949ca",
          "lastUpdatedTs": "626949ca",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5u7b8tm1PdeAqCXdrpUt4jFoLaafvkD81QjhG7EtRSqo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8ez84rRktJZ936VxpgR5DBRyznUAf2PEiKSTXz335ELz",
      "vault": "HHbCXStXyAQGJdn95sCmM5WvK61LosP3WNxULTdXRgkW",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62545d6d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "39323a5a",
        "accruedReward": "39323a5a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62545d6d",
          "beginScheduleTs": "62545d6d",
          "lastUpdatedTs": "6262f255",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a5c9c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62545d6d",
          "beginScheduleTs": "6262f255",
          "lastUpdatedTs": "6262f255",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FLfcPBW52yC4UynbVF7fUsrAKD42D7ZR8uiibWjEmqs2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5Bgwv2NJp9WdMsgiE6BxviHU5QMqVvUD8HuH22J1rxcj",
      "vault": "7UysQe91basP8DcKSvSFTerwU4L6fJWBjYck6Y7s4Yj7",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62476980",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "06b20f5e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476980",
          "beginScheduleTs": "62476980",
          "lastUpdatedTs": "6249e15f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "275089",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476980",
          "beginScheduleTs": "6249e15f",
          "lastUpdatedTs": "6249e15f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7ybA5Tq15ruKcFxFfe4sszEvCYN1sEvHat1GCL7xHeyW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AbWUixPcM81v5TKMyME8opWVVBbf9jbJtnSXayjGfEsf",
      "vault": "8PmjjC8rYNhYwrPmaNWMx9tCi1SXLaeGN815p6PoKuqS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6267340f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2e74ba77",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267340f",
          "beginScheduleTs": "6267340f",
          "lastUpdatedTs": "6267340f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0785fa",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267340f",
          "beginScheduleTs": "6267340f",
          "lastUpdatedTs": "6267340f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GCYyA4CFKPfr4aoRgNVPv2sP6hpXG1bAgxNy1aiHCfE9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6qGmXrcX7wEke4mmj7movkD6WfvT85ufsvCVurFrrXus",
      "vault": "Awv1hp3aerExFxK5eUqtwvmVo59k9vPJ2zkVb2bheyD5",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "42ac24",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4HawCqiRmfqbwJx3PRdpEeU7a4CyFdGNMACpobAVGvxi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BxYS3r4YgLmfTULghMiBpN31m5G9gQzj9sbihY2P2rgT",
      "vault": "2qABHsueeR2pzTrf4inW3yS225dueUstrFRbLF4brtZ8",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "090b7a94",
        "accruedReward": "090b7a94",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GKxBVVD2a895WsipZos6nzyZSd1X69Hy5TknJSsRp7Fv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7Pxe6z5K2P7UCjz9R9UGF6pgb1PfFFkCtN5Y7Kwnyob9",
      "vault": "2AJCnbGMvPEr7LsQMJPBGSPWE3EyND5fe26Tea43T4FJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62476a83",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3a871401",
        "accruedReward": "3a871eda",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476a83",
          "beginScheduleTs": "62476a83",
          "lastUpdatedTs": "6267c794",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274f86",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476a83",
          "beginScheduleTs": "6267c794",
          "lastUpdatedTs": "6267c794",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HUiHvZ7Xabb6TMDxQAPuURTyPcfDf46v8B5fy99fMzFJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "G4ZYbjEvQapDuAnrXPF9NKC16h5STyDfDdojinR9y7sQ",
      "vault": "GQxLa5yCRU1aycb9VuAqdvtwnEB8FuZi6SmT9gsrARuk",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625afa7a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625afa7a",
          "beginScheduleTs": "625afa7a",
          "lastUpdatedTs": "625afa7a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13bf8f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625afa7a",
          "beginScheduleTs": "625afa7a",
          "lastUpdatedTs": "625afa7a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ErqxUStxfTt79yHoUma4Sm9RXMM7KW6E9An7J9v9SVKa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GE4XqRFWoteyHE2v8ZuUJxcctMwN2RSBSbRKR5jhxULS",
      "vault": "AkhnU5ijNGFPLLFLCUyBgtVhpT7SNJ3sT6q6FeqFqpd4",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "62479a61",
      "cooldownEndsTs": "6267d08f",
      "rewardA": {
        "paidOutReward": "36c574e8",
        "accruedReward": "3a40cbc6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2mbmeoF4qChhDbKBhqxEJoLNNJmWCB1mfF7WhQmDph3r",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2ejtRGnVa5EjUDBmjmpPTCafKRfc3jeEvAX2KTgjoRTh",
      "vault": "AJZpwRwAjcMBLUvGox1ppV4WPbGJtWj9STm5bvawQmsQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624a245a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "098f7b44",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a245a",
          "beginScheduleTs": "624a245a",
          "lastUpdatedTs": "6254b712",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2495af",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a245a",
          "beginScheduleTs": "6254b712",
          "lastUpdatedTs": "6254b712",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3X3pMzYaNhxoQRsxBPHUiUTeVW4rJCibbvGRKp7tE2ix",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9zpFpGSTNHX7VgPsAfCDT2oJSSKB5kAojxJmdETaTnqR",
      "vault": "7iebd3ByUQYmd2g11bjWD5RoehVwcW5v7hr9koqvko83",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247a8bb",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "327c6b9e",
        "accruedReward": "327c6b9e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a8bb",
          "beginScheduleTs": "6247a8bb",
          "lastUpdatedTs": "6263936b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27114e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a8bb",
          "beginScheduleTs": "6263936b",
          "lastUpdatedTs": "6263936b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4MT4fXfrJMFZmgkfkZm3mZK1gMMtf2CeyhndGVaiQmbF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DrLKN845NzyETjZ2iSGmLdmRLFbmqgE2CdAHdofbG5XW",
      "vault": "FDFGeXEXPxERZWuuVP4W8GcoJ57DFJ5QaKNyqyDEbvfq",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62487c0d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62487c0d",
          "beginScheduleTs": "62487c0d",
          "lastUpdatedTs": "62487c0d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "263dfc",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62487c0d",
          "beginScheduleTs": "62487c0d",
          "lastUpdatedTs": "62487c0d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FDFPaKww6SpwfxmNFDJ5sfvASpXZxP4BDN4XpkexJC6U",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8YXcDBc6eAFYmG5UdX1MZsNDNTrbQs2KNGdpM9THUQ8z",
      "vault": "5VDKmhmEEUeidDgbwbyapHQA4FVnzn8t2tnZcCuSkfDU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62488c82",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "4dc3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62488c82",
          "beginScheduleTs": "62488c82",
          "lastUpdatedTs": "62488cad",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "262d87",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62488c82",
          "beginScheduleTs": "62488cad",
          "lastUpdatedTs": "62488cad",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "hMJ18k5evcFfsA2bpboH7E85bm3yxcvwdsveHCiNdAh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BUxe4eR2vpBiFoc4dG5WMwUQz9oCEp7h5m1HfTnC9z94",
      "vault": "59WwMJ2X6KLt8k5zGnMg6kWEVRpzurRHRrvQAuPEQV9x",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0af3f44f",
        "accruedReward": "0af3f44f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CzSQV8KqZU4oM1u3G1JGDEaMTJuFjFwdEyNxLjpcrDym",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DGC8NJsrN2TdeUia796AaRH2LbiMExAjWsnX5oaZ3NkM",
      "vault": "At3j8NF8jyNwqqnuDAaite8KReTZpKd4MQVZQTwGm2JU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6260411a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "06a227a5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260411a",
          "beginScheduleTs": "6260411a",
          "lastUpdatedTs": "6260411a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e78ef",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260411a",
          "beginScheduleTs": "6260411a",
          "lastUpdatedTs": "6260411a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HAUc5mDts6mTkFJLYjaG888B14JxotwMyQNeJzmcWE26",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7gmGAUMG42ASVafsJrR2skHsNp2qBKPgQcNzjHFSFoQa",
      "vault": "3841QjQVaYMmH3vAkwWPvhr44D3a3HwdVg2skpXuXrSV",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "12e6e30e",
        "accruedReward": "12e6e30e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2Pv2eD4KsTgszb1qaGizqMkJtwE8tByBUoiWp7mr22pi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EAdU2i9tiqp9PScDs2uGnodfM6gSubEsWES5CGnXgnzk",
      "vault": "3LTmRXXRFnTBQvq1t1yUhU7NhJ2ozrWru4Eu1S5eEWTj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62600b3c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2ca40712",
        "accruedReward": "2ca40712",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62600b3c",
          "beginScheduleTs": "62600b3c",
          "lastUpdatedTs": "626a86bc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0eaecd",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62600b3c",
          "beginScheduleTs": "626a86bc",
          "lastUpdatedTs": "626a86bc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HybvaQT4DjU5CcSi3TidHszp8NabooEDL5aULxZP66Xo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Hhyy9XntwYBRZKiHhZDtJTSeg9GwBseZ6XrbUHamhGTu",
      "vault": "GGgUGvwH3HSjn2NiRkhQ3JkcD6L46rafhGmnwafBStRJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62487c9e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "10eeb60d",
        "accruedReward": "10eeb60d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62487c9e",
          "beginScheduleTs": "62487c9e",
          "lastUpdatedTs": "6251d98a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "263d6b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62487c9e",
          "beginScheduleTs": "6251d98a",
          "lastUpdatedTs": "6251d98a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CYJ6pmE5nD5rVtj8Jr6tCCBeFsji9uLApWbWKZwWdCf8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "H8i8KpiU9r7tFy4D2VrKw7YbVoaYC6TqR8gKPAo2pJhk",
      "vault": "B9bwhB5nqg9gJSnSswm4HxtDUmb1gQKe2PKVyJXJSiZv",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1fb57150",
        "accruedReward": "1fb588d2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3Gbz43cuAyQ2KcX61hyRjKpJ1Pc6TMGxeKGfGy31nNDz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4uLDooFhuj4w1n3xRxKqGfzcdGpqnfo6W7cLzvaQ6HvF",
      "vault": "JCvZuhjNRnCHjZmdTqxnvb1cFhchZ7aHC2fCnHSL14PY",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Do5scUMLDUvbxQSANSP7wifV1FcujdMkW2sAYkqn6onC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BPStfMMzci5mpbRAZdQioaBjGwHGqySZ7ALM8rrAzFju",
      "vault": "BskE5VCJgMzJsWQkDo11txEGjBxu1DAmqrtgsrHPPabm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62548b9e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "063e499a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62548b9e",
          "beginScheduleTs": "62548b9e",
          "lastUpdatedTs": "62548b9e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a2e6b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62548b9e",
          "beginScheduleTs": "62548b9e",
          "lastUpdatedTs": "62548b9e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "p7mXZnNixsASEepBYaMNf69QZNsZyvJvMnvqoZ1qJ9U",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7b8Zh8fC3Sye3ZesMzy9fC42wNgSqYfQh1sLFMFQzpCR",
      "vault": "FK95iNPjPX6x4d5RTLJ2ceG8tqU8YoZpr7jaFZEThUnP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62686a03",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62686a03",
          "beginScheduleTs": "62686a03",
          "lastUpdatedTs": "62686a03",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "065006",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62686a03",
          "beginScheduleTs": "62686a03",
          "lastUpdatedTs": "62686a03",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5S2jwosMswLfMShJiytU84GSK689iJu9BMR9fCyCTccW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8V27K9gqRysX1tr7WjFqSeA9ngEcdqXpfqrbqmQLywo",
      "vault": "HHsX8SiMLN4R79g7x7EZKkXeCU9y11ttHJFbu5CKEB9L",
      "state": {
        "staked": {}
      },
      "gemsStaked": "08",
      "rarityPointsStaked": "08",
      "minStakingEndsTs": "6266b9d0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "d631f03d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266b9d0",
          "beginScheduleTs": "6266b9d0",
          "lastUpdatedTs": "6266b9d0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "080039",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266b9d0",
          "beginScheduleTs": "6266b9d0",
          "lastUpdatedTs": "6266b9d0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4a1TPpjVT1T8K1vv14FhNshyYGEnbfYVeFu7fga6sPus",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "82Mdjkd19auybHF1TQJWTvPg3789FAFXF1TnXnndZffe",
      "vault": "HQcuLbBFJu3AFzFpsfNX6e9tQnPfx5EL7ENrsj8sPuWE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6248bb08",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3257e421",
        "accruedReward": "3257e421",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248bb08",
          "beginScheduleTs": "6248bb08",
          "lastUpdatedTs": "62649185",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25ff01",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248bb08",
          "beginScheduleTs": "62649185",
          "lastUpdatedTs": "62649185",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AnRWqnhpcXRLUmHh5krJ9ukzRXrMHLdEt7MJnsBEcSxD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "79VKv4DpEJpwk7Pgiiehz6U9d3Z4SKu5S4DhALWWSQLK",
      "vault": "2wZbMbKUXPwwVrN56HEP9E7ksme86r33S27UsY2YkgFp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62488488",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62488488",
          "beginScheduleTs": "62488488",
          "lastUpdatedTs": "62488488",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "263581",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62488488",
          "beginScheduleTs": "62488488",
          "lastUpdatedTs": "62488488",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "63oZL4yxmts8KiopfBTfu77XVep7rpUG2pSg53wbxnKD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HLmecYwUYrmNkJu8xVbvc7RgqL4NSMA9kxRk3rCAHLWt",
      "vault": "34fsVjAftDtVrYghS4rntxJu6Xr5uheTbqvPzvXbMTKR",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247c306",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1e12d7da",
        "accruedReward": "1e12d7da",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c306",
          "beginScheduleTs": "6247c306",
          "lastUpdatedTs": "62690554",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26f703",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c306",
          "beginScheduleTs": "62690554",
          "lastUpdatedTs": "62690554",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EVpPiPtmRUUGaBNrC4YSDK3AWoUeK5Gr1UmQCwRSdmX9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BFscbL6QHJPiBoXbsvRC3DU8NKW1vH1r17M9934EtpHJ",
      "vault": "3fEbrHmah892B3Un5fumQE5wnTzEi8NYgTn52u5HApqK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62648970",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62648970",
          "beginScheduleTs": "62648970",
          "lastUpdatedTs": "62648970",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a3099",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62648970",
          "beginScheduleTs": "62648970",
          "lastUpdatedTs": "62648970",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6G7rUqVWeDGqLcK4hvKkdBoRgdWtenNaaFgHQYYown3u",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6j3m5DsBoch6t4vM3bnqky6j4tBSqrbRTkHemANGQ7nX",
      "vault": "6Pgnfhjvy378skgDnvbGi7Vjs1d8NXTMBvfSdZ7XPBKm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625a3072",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a3072",
          "beginScheduleTs": "625a3072",
          "lastUpdatedTs": "625a3072",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "148997",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a3072",
          "beginScheduleTs": "625a3072",
          "lastUpdatedTs": "625a3072",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7gJZVq84L23huihkzXqMg43HdH8sUVxUKJxjzVzuvuqu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4BTpWUPZQLRZBuk9w8YubFpA9HZoD1ty7pupMV39ofDm",
      "vault": "BjRFxkWL17s57D2ZKkYFNdk8LbPwMSx9fXVBUYRVkpwJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626417b2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0ea4104d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626417b2",
          "beginScheduleTs": "626417b2",
          "lastUpdatedTs": "626417b2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0aa257",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626417b2",
          "beginScheduleTs": "626417b2",
          "lastUpdatedTs": "626417b2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8th3KBSaXo8VLXUpxhfqbMCiG1bo6ibQXTTHgqWkPM3b",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9SJtxED1LfQUHk3BLZEvvdWK7YoRdKrznXbzR9Mnbv6b",
      "vault": "GXRcswRyJNNjSKVg7WeDbQXTQWX8zrxd9z4JG93ijCru",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624793c3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2691dedd",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624793c3",
          "beginScheduleTs": "624793c3",
          "lastUpdatedTs": "625ce79e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "272646",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624793c3",
          "beginScheduleTs": "625ce79e",
          "lastUpdatedTs": "625ce79e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FitvjCjgmmbdmWCrS6ReuskBC85oJdVSp6c5SGGrUNxt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AHRhaDg4jj27S5i2yWFCaYeBMwhU55Tp3oZeexxp2dGM",
      "vault": "CfU3891D8eCThLV9bMziyRfAKZcPttmS2GrWwPwUtB86",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62664bd8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "37ca7039",
        "accruedReward": "37ca9295",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62664bd8",
          "beginScheduleTs": "62664bd8",
          "lastUpdatedTs": "62664bd8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "086e31",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62664bd8",
          "beginScheduleTs": "62664bd8",
          "lastUpdatedTs": "62664bd8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HKx8qzQB58o5xhcATwg9UAasdFCLYaB2bGxLChb3keeq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9KBjtBpK97PsocoD77sANziFU1qRN2HKjmxmHTQof8kq",
      "vault": "Hd1xn7xQ966yeSgcp6DX3ncAxQDJQ1SkwZLr8GcKcHuH",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "62476eb5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "95ef0480",
        "accruedReward": "95ef0480",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476eb5",
          "beginScheduleTs": "62476eb5",
          "lastUpdatedTs": "62631177",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274b54",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476eb5",
          "beginScheduleTs": "62631177",
          "lastUpdatedTs": "62631177",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3j1VGz9jFL31hgS2oopvGLQgK4DKVxXBYYfrmkZrvdCJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F15rzk8R8DHeskM1CFsuDKQidbK3yCEE5fSyd3upevze",
      "vault": "79iUfXk91vWPs5WizS2q8Q8m7ow6FPHbxrmFiftNnprs",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "C51WEqMwSpKitbjw7P6YfmMSTLtzNQ9x7gaPFF7QjFYE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AZwbwrcrRj1KjQP3xkwNgcZf8vxhMfQfLoMbXWeBLmT3",
      "vault": "8jnZvMNq9oMPEHrMWZnB31NFoGgCuT5zYg3ieme1v1FP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62492adc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "12dec9ab",
        "accruedReward": "12dec9ab",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62492adc",
          "beginScheduleTs": "62492adc",
          "lastUpdatedTs": "625e0953",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "258f2d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62492adc",
          "beginScheduleTs": "625e0953",
          "lastUpdatedTs": "625e0953",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6d5LD27fnSKsy5yXjncsSrT7orR8GixgxQ84EGRhqATF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CXzjQgWGuuuKQkULYzzsnjiE7GpTUi7azUfhYFPANvB4",
      "vault": "5ChBjsG6PdUXGxTLq7cxAarm7KLKnvWUCn6Vikm9W6NB",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8FWj6rHGU2pKNgL7vf5xj6nCXRf1fvShTmd8gppz3tfk",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "JBpzgmVcunUEcuFKmKakPKQthonKuKViBGwGT8vX1tgj",
      "vault": "FYiqaTLK4o7he2noEfqDN6i1jPoQErT8GAiXuK4SNi7v",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625bdee7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0b39e5c4",
        "accruedReward": "0b39e5c4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625bdee7",
          "beginScheduleTs": "625bdee7",
          "lastUpdatedTs": "62684934",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "12db22",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625bdee7",
          "beginScheduleTs": "62684934",
          "lastUpdatedTs": "62684934",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FZjoisbn2qdsWoPPbL2q5vJwGPChUHPv4EvANSQ9tSxL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "efBQwHGJDSH1bivVz5aJZUJhbkuE6S7dpwsoaK8i9SL",
      "vault": "J5n2GtnKPed6sxyy8SFPVM83ymTMqjhLH8t6ut7G5vsA",
      "state": {
        "staked": {}
      },
      "gemsStaked": "07",
      "rarityPointsStaked": "07",
      "minStakingEndsTs": "6260b8ba",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "6c0fdb24",
        "accruedReward": "6c0fdb24",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260b8ba",
          "beginScheduleTs": "6260b8ba",
          "lastUpdatedTs": "626969ed",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e014f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260b8ba",
          "beginScheduleTs": "626969ed",
          "lastUpdatedTs": "626969ed",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9JKaSqcXbUmgV7vtUkuk5C8Ed7z8BKJu7y5YYzLCpYLL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DTFPXFzCB12gv9fAoCvv7S2T1RMhyyU1AFBFj7kJdHYn",
      "vault": "Do44boczBFzztQ1pAgUEo5Y4DR1iLTZScxP2fGK9HqFn",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "15490162",
        "accruedReward": "15490162",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9y6HHJro3SUKgoMvfafXwRZQGa1xmG63hGr3wc16d5Hs",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7yUXrJdEawQRhkGNrvyWyxDTBtjX159SMtnHGyUygPRc",
      "vault": "7fKUWF29xGigVnzhDFTAL4NGAwpRyo5SYXj8PHeSuVvj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62513054",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1729d0ef",
        "accruedReward": "1729d0ef",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62513054",
          "beginScheduleTs": "62513054",
          "lastUpdatedTs": "626acd8a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d89b5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62513054",
          "beginScheduleTs": "626acd8a",
          "lastUpdatedTs": "626acd8a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5LBXcX3zoKeac2Cm2xNrhgypU1psrCVn8JzYq7Xf9XH6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7XYaXu5hXx5z5Tri3u5brd276zDPjdZAuKbCAA1nLjVV",
      "vault": "4XNzQDZeZDSp6u85bFwhDRgKKLBnemx7gPLog3Bqh2PP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624a89bd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a89bd",
          "beginScheduleTs": "624a89bd",
          "lastUpdatedTs": "624a89bd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24304c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a89bd",
          "beginScheduleTs": "624a89bd",
          "lastUpdatedTs": "624a89bd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3TRnkop7rXZ3DGbQJRXZkC9WvxF8KyeMCT34By8UTupE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "12JJBm9ChPuG3BS2Q3kazzRGCzsycvoyZwHuHthYDbY5",
      "vault": "exgdqLs8bkNxP5zmvXRtRK6jiHxSfTaagcBY5rdvpFg",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62478c70",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "13119722",
        "accruedReward": "13119722",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478c70",
          "beginScheduleTs": "62478c70",
          "lastUpdatedTs": "625ca316",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "272d99",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478c70",
          "beginScheduleTs": "625ca316",
          "lastUpdatedTs": "625ca316",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GzcVzLqvx7cdrkqaJSZXJovzN4hYAnhTETobj1uMWCyz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F6yMZUywzpRVjcSkjqGJRxuLWJds5XFRpaabrUTCfuso",
      "vault": "CpQ8X3yei7cBFt74VChxNzUqKRcmbSssu5zLN4pWkinp",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AJjMU4YoL4M3UhFXUXmbHWwYBrjFzDKT3MVm529cjLLF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GgEsWcnc1bBMYsT6SkNpWKD595f4ZdphAwEfDG1y521Q",
      "vault": "6fjHJCnFGD9PvxSoCUBs4s6x8RjAgF9SSjBkwWJ8p9Cy",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6266e646",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "28a611fa",
        "accruedReward": "28a611fa",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266e646",
          "beginScheduleTs": "6266e646",
          "lastUpdatedTs": "6266e646",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "07d3c3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266e646",
          "beginScheduleTs": "6266e646",
          "lastUpdatedTs": "6266e646",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3ZxSMAuzvbvxGaA6q8kFnqxnHqh9aGgZjjEEppAtySgC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3UMfg5sYSTNStd3JGGWro7v8wnDw5EZLezjFAiGfqqy2",
      "vault": "6t5iZpeDRMUDqTEsFsWpXvvzSHsssu6aNFxxw2zVG8Xq",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3CZfMtGz96hUDiA27Ev4TZCrw4EVTebYegWJVXEBwwqs",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "25Ub6AijmBLH5e3Fqq9Rj3emjzK9KmrY8HsJJbqoQQLQ",
      "vault": "GgPPCbaaERt9Y5qSmNg15Kiqd2BqZKUMxaSZ7BuJuBs4",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6251f8fe",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3e66697c",
        "accruedReward": "3e66697c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251f8fe",
          "beginScheduleTs": "6251f8fe",
          "lastUpdatedTs": "6269ee19",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1cc10b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251f8fe",
          "beginScheduleTs": "6269ee19",
          "lastUpdatedTs": "6269ee19",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "D2TPDnN6C5LexF4wQJqVN4LEaoFcZFkXonH7psY5WNe7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "J69BPaxABwuhS96xXDFtXqVpXDsTYt8MFY3fsUhTgUR3",
      "vault": "FnTXBCpPCG3Gy7ZNeiUMDigLBneEY26Sys33nf1nL5V6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62608fdd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0377cea4",
        "accruedReward": "06365b9c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62608fdd",
          "beginScheduleTs": "62608fdd",
          "lastUpdatedTs": "62608fdd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e2a2c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62608fdd",
          "beginScheduleTs": "62608fdd",
          "lastUpdatedTs": "62608fdd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8L2pYbgjJbtEj3zHAJvmxzyBd7tDm2gG9tmUrDaX61ry",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HFPaT6WKk9SkwtYVZ5m5Sqw7ZbyehGu9GHjsctigm49S",
      "vault": "2e9tCSoBMrBhoV4VQedBg96PeFcE9aCtJxjvvUBSnTDT",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "5161",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6YrLUhBJJzr8PALqQ3yEtNLfnkkN4tjHHNbzkxZ6BgKy",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2bzZ9CRASNnvfrQqV1dt7bbXc1wKMLGFoS8kTtkVmhxi",
      "vault": "4nTj9ZAWyE8qv5uyYip8fGyiqtn9XWrxkwDrSxB8Yw9U",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "15130706",
        "accruedReward": "15130706",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "C8UrLwDKZy5oRNsjZoLxJDGKdjHtkgXJer66DXueUJk7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "59LNmAn9CP8guLoX5J6Bf5hKSthpgVfXFnyveUXiK3RY",
      "vault": "CF4A4pmSuzbtemq8PUPAc2b3jaqNdYjmKWW58dreMDGq",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62628075",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "05d9a83e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62628075",
          "beginScheduleTs": "62628075",
          "lastUpdatedTs": "62628075",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0c3994",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62628075",
          "beginScheduleTs": "62628075",
          "lastUpdatedTs": "62628075",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9KHTYbLTRAs857c53ZYsoW7sNHnGjF8UXou7nwGKSkud",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Hk1g9Mx5pcsmHXTYBFck4GzRJSVr7VC3K8Spo19D9hFs",
      "vault": "86vdYS6PvPvGnKCV3AMKRsZUR9SafcNyK7JFJgmf6LMh",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6250eca7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3c94",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6250eca7",
          "beginScheduleTs": "6250eca7",
          "lastUpdatedTs": "6250eca7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1dcd62",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6250eca7",
          "beginScheduleTs": "6250eca7",
          "lastUpdatedTs": "6250eca7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8NEisZfxEtVV3hae1Bo6jzSQSYUmbqhKV4tNxmWmpSHV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AJAC4NfSnnwKajviT7RYtN5FV2uThxRGB4EENHirGEQe",
      "vault": "HnuvbEGHk3LVT9MGvnJCU7axXThjPt8g6pg6a9HZZqEC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625f80d7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "16a3f92b",
        "accruedReward": "16a422c3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f80d7",
          "beginScheduleTs": "625f80d7",
          "lastUpdatedTs": "625f80d7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f3932",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f80d7",
          "beginScheduleTs": "625f80d7",
          "lastUpdatedTs": "625f80d7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2FK69L9647SRrzHy5ZoRJNdNQrTcUL7f8X25qhvymj8b",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5umYrtaiEczzHDzpMrAiXcE2ow1UHUcjQ2hdCTMPsoXb",
      "vault": "3e5j2n4UK3g6eGd5vuvtpD8QuDSMypT1ozTbs6o3s5V2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62633b4a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "053ec261",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62633b4a",
          "beginScheduleTs": "62633b4a",
          "lastUpdatedTs": "62633b4a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0b7ebf",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62633b4a",
          "beginScheduleTs": "62633b4a",
          "lastUpdatedTs": "62633b4a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9S6ddxNeKJtEWrU3CVupqSXJhjGFpS8quJCPc3fryue9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DgmvzAHx3mKhpshscKDmxitZ46VQ4245tvB4Pje7jdr6",
      "vault": "8MAP9XXjFVUb7JVWGKqEwTtZ4RdNc6cx5nQdqzhppXLQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "626082cd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1ebccc6a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626082cd",
          "beginScheduleTs": "626082cd",
          "lastUpdatedTs": "6266fefa",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e373c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626082cd",
          "beginScheduleTs": "6266fefa",
          "lastUpdatedTs": "6266fefa",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Hxu2RG8nvkEuGgLzv87o5tVtwAK57oMsA9YzBkGXSNY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E5orgVKe6hjM7q9UpE3P1vKEcoqvnPcgyrS1Tg16trXF",
      "vault": "EhZwCszMniFPkDZjc3F4H7dL18X4vEG37erUZ8pZw3Xt",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "05ca04",
        "accruedReward": "05ca04",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8GenchT8Jr47rVZEkBDW5X7NNhtXAiH2MkZZc1YPDVJ3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7tuZoYUuWoBDJHdPSkgXd5i28P2txgdYKw7wSQ5WodxR",
      "vault": "CfBJ4Ue1iDTgpUtoCc2y2TZg7NThC1xkcUNq2Na81euC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62479879",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62479879",
          "beginScheduleTs": "62479879",
          "lastUpdatedTs": "62479879",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "272190",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62479879",
          "beginScheduleTs": "62479879",
          "lastUpdatedTs": "62479879",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FX7FUQtcSRSYaiCPTdk6qbhDgEbkAnVnkrR46tEdvo5v",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4MHBkj3CKvaVNuSPDmFmCcPGPWqeYQAdcmCzcDVauz9j",
      "vault": "BGmyqKbu4CyiaR6StWvo4ZwJhZicwrDU81NiqMpeT9mF",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9wUCDCZJ3AeBTWaSvZ4nVMhvbN3CctvJbXAeHSs3KMAX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HPG7ZWRSC7HbE9VenzdLEjejkimgUerQkkQhyUaQAX2L",
      "vault": "2wHTenN8dHhzeYiNWtWhJBmJbK9CR9wQJvuw4iyQZp5D",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624a034a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1a0d5e92",
        "accruedReward": "1a0d5e92",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a034a",
          "beginScheduleTs": "624a034a",
          "lastUpdatedTs": "6266d314",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24b6bf",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a034a",
          "beginScheduleTs": "6266d314",
          "lastUpdatedTs": "6266d314",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6Fo7WxtCa3EVi9DsiWVaDL9hrnXnCmbG6TckyrvYJArJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9S43Sc8VfiJyqeQoc19XwqWK8UKQ32YAmokDCsc8XcY",
      "vault": "CA67S1yp4UmV5L4L6J4NswXxmWShsNwX6nCyFJf1yd7S",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6251088d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "31e72c78",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251088d",
          "beginScheduleTs": "6251088d",
          "lastUpdatedTs": "62632755",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1db17c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251088d",
          "beginScheduleTs": "62632755",
          "lastUpdatedTs": "62632755",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Aew8TK7bGpwFHeR3XWUgrK7HHrkQ9kci4PrT7ngR6L7S",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7YxZDSEiWdjoqdUi4PR9pajuKhyq2iVnkAPLq3pnAwsN",
      "vault": "D9TUxuwYmfZCgE7RSNFtVvfmaouDsyamko43dTGoaBmf",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Ha93ENMWTau7MXg3EYyDHsAJPKbb7J2mjE8CtGoc1iwf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3Rnh79Y3iNjE78RzyA7wybueiUNoi3uF4LdDfwZ1BBqR",
      "vault": "ALUpdXFS1u3fEFJTCwcBnnNehA2Ta3Wh1VE1Kz3ijGKC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62477d90",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477d90",
          "beginScheduleTs": "62477d90",
          "lastUpdatedTs": "62477d90",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "273c79",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477d90",
          "beginScheduleTs": "62477d90",
          "lastUpdatedTs": "62477d90",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4EZ9hv5BFyUinPihKWLyrdMUmPsmoiLSrq58hgDUZcL2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7k5nURDVGDSd5y1h79PbEA4fiGvZmbp9ghwhnrAoRR8h",
      "vault": "CDEhL45htb88YpR6CcEL3xLtNszu7DPB1QGuq1wQzc2o",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247ae9f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "060be7be",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247ae9f",
          "beginScheduleTs": "6247ae9f",
          "lastUpdatedTs": "624b068d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "270b6a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247ae9f",
          "beginScheduleTs": "624b068d",
          "lastUpdatedTs": "624b068d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "A7AgQ1rfd769wufty7XFK9prBKN6qNw5cXRfRtjwX9F6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FQq33AG8m2P6DXX6CjGj9gm74kWA8STpmGG98c3wvPLA",
      "vault": "2VsSuZV2B2M1Dx8bYVq6TWYNCUW7kmuCjnmtQJbEiUrr",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1138aab4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6SKKnv8gtdQ9Hx8mHtQJVmvZY3e1jaPuprjZxj9w3h6R",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "32PgR7fMJ61VmnzDzUTWu9NPkodNFt4SmBQS26FciCPj",
      "vault": "GjqVn8Y5VQWFt3G9TWfqkdv49dYdE1rKvufJnGb6CEd",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625c2195",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1215",
        "accruedReward": "1215",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c2195",
          "beginScheduleTs": "625c2195",
          "lastUpdatedTs": "625c21a9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "129874",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c2195",
          "beginScheduleTs": "625c21a9",
          "lastUpdatedTs": "625c21a9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HoMRrfTVnaiwPp2krqEGtwRQLdQVRHfrf8NzWNDGbEqm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ENC9P75NzVV3MiAyCFd7gt9h6pbFQRDoveTrDqDQe4Cv",
      "vault": "CJKwZZPWWo3sH3FXV3dWQixZXARwHKDkFwVAaUABuyPb",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624770b4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "27c52c33",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624770b4",
          "beginScheduleTs": "624770b4",
          "lastUpdatedTs": "625d6e5f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274955",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624770b4",
          "beginScheduleTs": "625d6e5f",
          "lastUpdatedTs": "625d6e5f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BYE7jZN5D1HpqbHcQCeaNpU5QXvTemKCvy2Kum2xwiQt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Fn7GxPaqVdgGJn7pXTzUkz514re2Qq25PysgHrUWaMeT",
      "vault": "2Wm6Pc8sxGzmFE3eFPNTuNAdsxgo2YwdyqDxcUJfDcst",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8nAMmiKzbJfwstfULpsUxf3hhfPL8YGv2zBCGEy52Q3c",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5TmBGHSA8pgKZyxsRTBZme8LeAnGpkMH7AHZBGWYzLp7",
      "vault": "DvddmLumWyMCKwv4jxPGwHyrBDG6ejo46DDRreUNiRpm",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "624defc7",
      "cooldownEndsTs": "62607b4d",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "10c4d3c2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "D1bKQpYy6sPRXPxFmWrg6qtbFYpz7wbcsM4Kzm9EjaSq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8QySp1NiHkKzFsdQH57oApyhwT64yXvJM7Mcq86KJrsY",
      "vault": "86yZTpGPkZkosTGbTzfnrAyBRJMj5EnAKkHhpR1Daqwi",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62587aad",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0f6466eb",
        "accruedReward": "0f6466eb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62587aad",
          "beginScheduleTs": "62587aad",
          "lastUpdatedTs": "62587acd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "163f5c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62587aad",
          "beginScheduleTs": "62587acd",
          "lastUpdatedTs": "62587acd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "B8v8mjNfGPxurZhLVKZWT1BfDoed5RXQXfeQKzdHEKoY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3Ci9NrktvpnPXgVSARpKh3YxA9gjvjJaLcuGmFXHBPSc",
      "vault": "4FkMJmYfK4qVwTJ28NiTqsgSVnGRH52KSDoANkCRwcYx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6269070e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1dd6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269070e",
          "beginScheduleTs": "6269070e",
          "lastUpdatedTs": "6269072f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "05b2fb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269070e",
          "beginScheduleTs": "6269072f",
          "lastUpdatedTs": "6269072f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Hg7WBXkFRGo8ZMWKHyFRXHtXcfS3NkQHQZ1oMuj6biGe",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GKWBRyxJrsU7LuTPSQn8srb45REAM8KxesT28EdVLQ6q",
      "vault": "B3d4BsiQAvnGnJSZeMcy1zBco3Dum5vTePRP1jsQvDCJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62676873",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0425b0b6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62676873",
          "beginScheduleTs": "62676873",
          "lastUpdatedTs": "62676873",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "075196",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62676873",
          "beginScheduleTs": "62676873",
          "lastUpdatedTs": "62676873",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5iAKe37eNYLoeXhREYJBJjrjAPUeKahpmi9haGw7Fsu9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AvcV13r2XTQDMTVJGkmMr7VH9emEyFvXxLB4ZtKPzGCP",
      "vault": "ErPE8a4PMBZ7HJSq1GX8dm5rRRWZRTQTg3ZP72oBEKA1",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625e1b84",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "13e4",
        "accruedReward": "13e4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e1b84",
          "beginScheduleTs": "625e1b84",
          "lastUpdatedTs": "625e1b9a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "109e85",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e1b84",
          "beginScheduleTs": "625e1b9a",
          "lastUpdatedTs": "625e1b9a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GeRnmrBjWbp4eC8ityofwy26zBfGGB9kBvatjz3S35Hn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4VUg4PNHXF5i1PYdXiHb9eQjf3ej7DZRXThN8pisv1Wz",
      "vault": "HExjGoYSBPf5mH3qZZMa9CuHRiXJpnsYDiHvvAub3Pfw",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7NE5uZ2aWTMiW7SHEPGrRKtqfBPv2iEdTCvHoRyjV6Am",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8tEGEg48aQAPLcNRbBSHJYARqLVspDxCJytzqAW3C4aw",
      "vault": "Em2CTd3K2Jr8A9qNPgSUtwYE1zM4z26oLbpE96ckfrTs",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FXjrtKwXsFRtXNTD9Nv3FVWYiTcoRFBbRM1mApXha3VS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Epr8M79d53cAqK1E4or2RqJKsHZSxJQQma4r6evCS9an",
      "vault": "DrsJiSzoKB62AWVVwuLpd7r7AWyMKb5H3zax7vcRttFM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624c3104",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1728a68d",
        "accruedReward": "1728a68d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c3104",
          "beginScheduleTs": "624c3104",
          "lastUpdatedTs": "6265cda1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "228905",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c3104",
          "beginScheduleTs": "6265cda1",
          "lastUpdatedTs": "6265cda1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "48CDHnuSmgkizaK919PFkXzCm2ZZTj17c8WJH8se4L7U",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BQmTRAaZM2Bdsi3mv3Swk6FV3kMoo47SwSt3J3qHWttU",
      "vault": "7LrJKzTuXAphjwKP79K1X8aBxnHnDL5BDL9wMm7BSxss",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248e099",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248e099",
          "beginScheduleTs": "6248e099",
          "lastUpdatedTs": "6248e099",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25d970",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248e099",
          "beginScheduleTs": "6248e099",
          "lastUpdatedTs": "6248e099",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Gph8tLQvW758rmzZH6Gh6qFbGDifbC95X42BLjBtUgfs",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GMLuq3hazWXX2a9ksm3VvXgV48Uhhpz1Fvbznz6Gjx3e",
      "vault": "4jC8ofVwNQE6r4YWfFGVwx4tLyiCisgcw7Z4qmt6VxVY",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0ace0409",
        "accruedReward": "0ace0409",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DSnRu3KTh9f7sdRqCWqgnazRZAwQz9iKrozNgkTBDXDY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BpyGoSLWeh5jk7c85eVq8XEepD2Pj6M63FAcQCTeE6EQ",
      "vault": "4JvLD1Jq5iobKxhK9XyddKqypEjHv9uZPZ26Pk7VxijL",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6255d140",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "235e7bf2",
        "accruedReward": "235e7bf2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255d140",
          "beginScheduleTs": "6255d140",
          "lastUpdatedTs": "62695ee6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "18e8c9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255d140",
          "beginScheduleTs": "62695ee6",
          "lastUpdatedTs": "62695ee6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "APqyJcraWmd7CxuphqqHzFNuEM1i3GjZoDukEZKHs9PE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ACzuxyzTN8EprjyPUoUxWhv25rF3S1T1Ekev2vUK9Qu8",
      "vault": "2uPS7H6ZM6DnMw8zJf8vCm4Snd76WaVNC3nDXCx7ayzv",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62489f43",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "345c6142",
        "accruedReward": "345ca7c9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489f43",
          "beginScheduleTs": "62489f43",
          "lastUpdatedTs": "62659380",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "261ac6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489f43",
          "beginScheduleTs": "62659380",
          "lastUpdatedTs": "62659380",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6mSVCht8Wp2LsgaoAg97gD5nMnur9m7sEXqUnxPfJuqn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BVVnAZYNcwnbUDZuXFGWDVDhnGp1WJ5GuQJp8BJobUAV",
      "vault": "7Vtk7zTtpX5Tzpjcg5czCf76zegtkemBrC35t2gzUoFo",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4oyceeabeWCMQuDjndHPbykNe9HFY7DuGZLeV9eZkmGv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8CCPuLHEjyxqmkBLuSgXjmULA4yCgDkRmuLdVDbPFj4R",
      "vault": "3vzshSt8okx6WttCCfan7TdnnKRDyQWRgKvYQrtRFPbC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "625fb820",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "491d4486",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625fb820",
          "beginScheduleTs": "625fb820",
          "lastUpdatedTs": "6266ad42",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f01e9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625fb820",
          "beginScheduleTs": "6266ad42",
          "lastUpdatedTs": "6266ad42",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DZSKzUs3BRp58U73y4hg1Vt3efLuYSK6J3vtYx2qGDWa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4rmPz29sLgEJQQUXHmh9zcpE59fGzR2jw4totXMLxLRr",
      "vault": "8JgfDQeGHtTpR8VzYBYY2LhfqKxpUBdbrzgmk7xZQTGJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625f148e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f148e",
          "beginScheduleTs": "625f148e",
          "lastUpdatedTs": "625f148e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0fa57b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f148e",
          "beginScheduleTs": "625f148e",
          "lastUpdatedTs": "625f148e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "JDLWqjDzRR96Bfgqca4V4w3Lafanjx5DLYgFaLusk1kv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DiCPayzLqJ7maz4BMW62d3w5P8ZYhk8WB5eNYLvu8sXy",
      "vault": "AjsbEE6cFtsLN2JjhYdMhAgQCBcDyWf9ptn7y36rmSdR",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9dQmQJfCYHCGopeAWaAnhmv55tViqFwgBK85DpDPRAcD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BFmywmmp9NRBA53zM4Knhh8ad71nmgTguxdo52TKTgS",
      "vault": "8HED2z4Ku6YwH5uZmAkMT3Ad94kd614yHogxpceNrFD1",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62499618",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62499618",
          "beginScheduleTs": "62499618",
          "lastUpdatedTs": "62499618",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2523f1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62499618",
          "beginScheduleTs": "62499618",
          "lastUpdatedTs": "62499618",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6wXi1TzLZDAdbaqMJn9X4pwAs15AMyGBzcBMP5DJRKLC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2cgNDGBHykqzqZzuMasKGjvP8Ademh7mxbMA41eSPnsS",
      "vault": "4mEvqwBE1dLNpWi7jtc18uQgqmKQ4FdVongSGtF3jGXW",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62570860",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62570860",
          "beginScheduleTs": "62570860",
          "lastUpdatedTs": "62570860",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17b1a9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62570860",
          "beginScheduleTs": "62570860",
          "lastUpdatedTs": "62570860",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4jy7JJZLFTTw1C75iLoXJdfGp94uGGNh5HEmiWL9GwPt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9haUVWTy86vnvDxKTZtGMh1T8HVYuMoEXCtNzZv8bfe1",
      "vault": "DCDNhmKc1vJpq19i8VH1HnsuSRb6yN96DAuiTa3GBTRn",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "624787ea",
      "cooldownEndsTs": "6247880e",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "208d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "63BQWBeSW4QfLpgqKzeerGz6TKSmhFF53Nya58QPhjiq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DqqpJyzMgg21CScmUoV4MAhW9rZgvRwNmg1hgsqkzair",
      "vault": "HZMURys9mGBGV95hcqmJk1tsdtLzQGo9Rbam8nty2NJS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6259fd6c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "04bffbf4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259fd6c",
          "beginScheduleTs": "6259fd6c",
          "lastUpdatedTs": "625f3e34",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14bc9d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259fd6c",
          "beginScheduleTs": "625f3e34",
          "lastUpdatedTs": "625f3e34",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "1nefKjCR45phAHmSbjqeWVzhm3oxvna5uem2P3R14v5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FFpZDBvBQFrtjpDyKsaLWQUg6aKomcdnoCn2y1ukrCgs",
      "vault": "AotqiqSLz89s8baFeJSAWzgpQNCCzAGxejmF48Vw8xRn",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624a3fd0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "04f588",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a3fd0",
          "beginScheduleTs": "624a3fd0",
          "lastUpdatedTs": "624a454c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "247a39",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a3fd0",
          "beginScheduleTs": "624a454c",
          "lastUpdatedTs": "624a454c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CYwNMqJYrz3XVNvxawv7eZH5C69UD2TpfwgyLwpiKuZh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GCMk5ZsEU7wbySNYoixwgRygZfYefp5xfuR7oG7mJL3h",
      "vault": "HKYrmruaiYPXXVEv1xA5sUbFYwfQPFaHfoGcSUPuHGD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625ebaed",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "29a90f57",
        "accruedReward": "29b3e580",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ebaed",
          "beginScheduleTs": "625ebaed",
          "lastUpdatedTs": "626691e2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0fff1c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ebaed",
          "beginScheduleTs": "626691e2",
          "lastUpdatedTs": "626691e2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Hqzxv8HMAXMSucrHpwvxJ6g2wDhbsjiiaF8hjQoTx63q",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EAVrm5eUBFKtnZrKSz9Xpad6XMWQ3YqWCB2zCgMFTBC",
      "vault": "FkS9FCoPbVW6Auboo1yQ5NHr9HJaH5YQF8b9hLRgT585",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "06b2b4d7",
        "accruedReward": "06b2b4d7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5qT25GxnTzvdqRaYN2ztVckF6A1cnzuKC8bJmTtpdHhr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "66kDYpYtuGkaZF2ho64VgEQuAgBPGjY17HtcTtANtNR8",
      "vault": "9X2RAmarMXxMuEm3wCjA67LXDubJM5FbzX4HBMVqXReH",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "121714d1",
        "accruedReward": "121714d1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5F42EcvgSjtmZyNRnoDKJeExfK9Fkz1cFinP5AiJVQ5H",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DGTmpCTsonr7EFrD1s8ckEcdNASWinNJqQkDSCW8wDp7",
      "vault": "DnTkovynF9YHjtavDGJ6G9yvqp2FcJuotCAYZgcN2Y4o",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2541a959",
        "accruedReward": "2541a959",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5RKURym4avS3y9V3eNPYHK1agy7EXKYogZqa3nAJeE7j",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9gtBLUWZu4Bx1am28WDecXFq2Lp9Ec9RNBnMEqX4PgQu",
      "vault": "33mcP82qXu5tqccFbUiTRwjPbhC6SNhnDDWCoqgRsyv9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b84b5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "166ac9c2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b84b5",
          "beginScheduleTs": "624b84b5",
          "lastUpdatedTs": "62644f59",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "233554",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b84b5",
          "beginScheduleTs": "62644f59",
          "lastUpdatedTs": "62644f59",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3wUvubCCeCy7K7gLQKZf7sLnRoBZZVDXaYAzFMp58oJr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3wJiXwoZ8LYyVfafyc455WDWu4PMLkWz2GBvF5XTSQR3",
      "vault": "3Yo3nRB5vWRh4P8xdLdEKebddVgdhSHFzdQ8vQqPPbTd",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62671f7d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "06cda812",
        "accruedReward": "06cdfd10",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62671f7d",
          "beginScheduleTs": "62671f7d",
          "lastUpdatedTs": "62671f7d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "079a8c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62671f7d",
          "beginScheduleTs": "62671f7d",
          "lastUpdatedTs": "62671f7d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8RQz7HCnJmB8vFPm1KJwhyxUNfoCQBEejHxMJXJrFUFH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "39HwsMZ51pSK84RqmxWyy6FhSuvWF8iWj7DHdpJzHMXK",
      "vault": "89SXih6Rt7r3Ti7kFcxFe1son1PtKBb8jvUSWngSZSaj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "626a13e4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1aa50e7d",
        "accruedReward": "1aa57afe",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a13e4",
          "beginScheduleTs": "626a13e4",
          "lastUpdatedTs": "626a1460",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "04a625",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a13e4",
          "beginScheduleTs": "626a1460",
          "lastUpdatedTs": "626a1460",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3AHfUa5rTPF6WZ2Jf5mB9gkEpksJKLixZVULn9oRWkSF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9u1Rjp8T4HpsN7CjPy4FbAcj9FdsM6fJWirVQAbrV7md",
      "vault": "6sv5k5e4msya6gWoJhTBaoDHo1y4WyhbHeLBq5cozyEb",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6258433e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "01e302e5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258433e",
          "beginScheduleTs": "6258433e",
          "lastUpdatedTs": "6258433e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1676cb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258433e",
          "beginScheduleTs": "6258433e",
          "lastUpdatedTs": "6258433e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Hzc2xUVGti46fQrKCVzwTKSaVvCYAQrC4628beXU5a9w",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7fmzAEHR5KEhKuqpjrTfMJYZBQWbh58F1x96oxm54G3x",
      "vault": "HK1W9G8PHGF9cCr7wy8V4MTc2zFqNaJjzNZHAJ5AqDJ5",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Aj6Zzj2kh9NCLugrLNRtcakYQeN2XTDM4FtX9TbW8wVM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "PEpGihX4GyteiK7e12DxDHWQfu8G89aCFb1sVR6StVt",
      "vault": "7JrDc3tqKuvxrKQG3WzGWduhTaQNtcAXUuhxy4rrv2VD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62483fef",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "14499378",
        "accruedReward": "14499378",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62483fef",
          "beginScheduleTs": "62483fef",
          "lastUpdatedTs": "625eaf9d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "267a1a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62483fef",
          "beginScheduleTs": "625eaf9d",
          "lastUpdatedTs": "625eaf9d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9itEp6o1h28C1ff6i7LfnyzPgxRyRnkCE4qLSdK2jtZP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6i8t7Femrv3xdK5exgsagtvdSKsGPjznd7B9PgrEqTFt",
      "vault": "GnmCcnz5fb1Ut6M6KEw9WFwjf6khyVZ12wJoE5XpLnCH",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "K5Mh17d6zXZr9wzuoYkv1HF7kjH7ymefPe26adjg3KJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4N6q9m1C1kgH7GDDZ3D8EzANhKqUHsGjBXT4mLkNiYQW",
      "vault": "3E5goPHPerHYEN1mhDfzm9FDuUZhNrSCskNxcvk1wC7D",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "62478a59",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "430ccba3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478a59",
          "beginScheduleTs": "62478a59",
          "lastUpdatedTs": "625a1412",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "272fb0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478a59",
          "beginScheduleTs": "625a1412",
          "lastUpdatedTs": "625a1412",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BgRkoY2bMgF7xVS7CmQxSPG1QWz6JtBSU3Qy3MfwtMse",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FphPkNdnDKi8HqeuVgTJjmNMsAtsovcKhy4fRNGtLuZE",
      "vault": "Ha3vcfWyAhnfE84wxmhak4iUUGNo1dVCBpkPHDPNfbas",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62661c40",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "12296705",
        "accruedReward": "4d66919f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62661c40",
          "beginScheduleTs": "62661c40",
          "lastUpdatedTs": "62661c5c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "089dc9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62661c40",
          "beginScheduleTs": "62661c5c",
          "lastUpdatedTs": "62661c5c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4fTm7DHHXQF4RMeW2M4veLYMDhorAfvvifL9GcDNtoJo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EQKUzHtGV5KSwhoPLPQu43fFzeSDvb1F9diezmoW4E89",
      "vault": "3u54qELsMHNxkcrEsgXrUsWeZshBQeMB2VUjJwbfTtuC",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "6f5a95",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "H6tEX6d1i5wVe49qfzPb5WgrRLJGjDjQ9TEi1L85G4Bj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EavqjvDb9GQ5YGJyyr3Vq4u4MDJWbEWpnKHweUkqPrJR",
      "vault": "HrjmgP5kkz7cAWEgHF9cdzrBKV2NQmrob1CQdeQqtqiV",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1540a997",
        "accruedReward": "154df8b5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AcuMu9ebmxfd1bsT2CEeC9kUdZWn3mTJc8tMEd4N9xFQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8evMZbVicT9b1nM3nxWHMT4ncEZqdfCoRXjV5JFVpgMt",
      "vault": "8dfCfE6gibkBApByagNFN7wu5oQVPzqpntN8m1LntEL6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626329f4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "043c5ced",
        "accruedReward": "0ca77479",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626329f4",
          "beginScheduleTs": "626329f4",
          "lastUpdatedTs": "626329f4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0b9015",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626329f4",
          "beginScheduleTs": "626329f4",
          "lastUpdatedTs": "626329f4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "qYWD4kQhboXMYe2CGdy8JvUoavspgwDa85QGhLL9uTK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "PwMNh82bnNAZzfhMK7esSXyyXsWCoLeU1DYGMmm1CVd",
      "vault": "7YdkRBxk151JAJGrkQg5S7GuW4o5LpPWAhawBuQmF5yR",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "62582b47",
      "cooldownEndsTs": "626123ac",
      "rewardA": {
        "paidOutReward": "081c6d91",
        "accruedReward": "081c8fed",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "vLS7q3fg9n7KLuC5KwiPne9QNmc9w25c8hANQQbKnvq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9eA5q5fgqHLxLYu7dH7Bbb933GK2mTcWbkdCKaVUB9tD",
      "vault": "3CW29CQkLHNcy4dpWMn77VPHFV87XcFcWuh7ZoAqR73h",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62643195",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "055e5c77",
        "accruedReward": "055e5c77",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62643195",
          "beginScheduleTs": "62643195",
          "lastUpdatedTs": "626a2184",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a8874",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62643195",
          "beginScheduleTs": "626a2184",
          "lastUpdatedTs": "626a2184",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EF7BpWeUDtkkoTNRNVbRF77JxMazCH6Dywq8jB3MBswq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BwnXU2cpY817GDdrGG3SimVw2g5fU8qdjEPpAEvoHK6C",
      "vault": "9w21j1z4GKAurwiZWj4RFeTmJdhPe4bxKuwqYoQRJJ4d",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6269ba63",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "05e1adbc",
        "accruedReward": "05e1e79a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269ba63",
          "beginScheduleTs": "6269ba63",
          "lastUpdatedTs": "6269ba9d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "04ffa6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269ba63",
          "beginScheduleTs": "6269ba9d",
          "lastUpdatedTs": "6269ba9d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HREVZUu2kvWEQKRYFWnAr3gnNMfcTJu6iqq6xnqsjuaF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "J3EGnLwVBrdhY9PejTJYG2241smr9X8v5BjjSw2QWWo7",
      "vault": "9JavuvJZpweYWbCHfibWMP5g2ZeXZKRK6AT3fbdAvV9F",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62639392",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62639392",
          "beginScheduleTs": "62639392",
          "lastUpdatedTs": "62639392",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0b2677",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62639392",
          "beginScheduleTs": "62639392",
          "lastUpdatedTs": "62639392",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9Vq9QM8eqcDzVHhRaHY8FnY8eyRLJc8TiaY3vPangsJP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GV6hT9h1SVhira3CpxSWmu5qwPMLDeyFt5oXebQbcfDi",
      "vault": "2nGhJirkaQdYoMhEDYEXzK7BDGUTSgSQKyU89uZ4pymQ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AEFBBki9oUhV5g7kChYWgGDa9a1fqiFoJVAt7f4VhYkw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A3Y5Aa7dCbtNqxozsZHHN4D36GbDEdYkv1ByVgP3AGro",
      "vault": "9oSMddUGjCt55f3uxnFntrU6bEW5Eih59RUMVToQCz3r",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247ba13",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "289f5f95",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247ba13",
          "beginScheduleTs": "6247ba13",
          "lastUpdatedTs": "625e3051",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26fff6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247ba13",
          "beginScheduleTs": "625e3051",
          "lastUpdatedTs": "625e3051",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CpDJUyZgjxmtCQEWwzt5Y7MTWHyPJqcK5pEjojKenUgL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3SaKisyDTwZTGErjF71PZwSBLEsoVmfkF718UpCuqMvX",
      "vault": "HFchzkedd54zSnDh684GhTTXP1hfAP9bzqNLZ2DNvsW8",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1fa743c3",
        "accruedReward": "1fa79e2f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4naSwNg4DPrEGHbineaZUe2D4GGceVa9TxHK5j7ZPFgh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9maaYEXUzoWZjKKpDeyDArQPYhK9HUre9innZJFmXXrh",
      "vault": "AdVg54FrXiZLQaR5wMtee9S8kxhfUysmgvoceoNptaHt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625b2010",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "29a03c97",
        "accruedReward": "29a03c97",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b2010",
          "beginScheduleTs": "625b2010",
          "lastUpdatedTs": "62688896",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1399f9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b2010",
          "beginScheduleTs": "62688896",
          "lastUpdatedTs": "62688896",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7gocC2gvRmXF5U6W1onojTZ5ppta9KKRfibJxNMXpPdB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8iGmtE26nBETcxiL6rp1Sn6ZJ8F6ZzTKLW6GL9BGPPHe",
      "vault": "4dZdYUPZGAgKnV98572qwWw9KDFhYNKdAJ7UUaK4GVrG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625616ad",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625616ad",
          "beginScheduleTs": "625616ad",
          "lastUpdatedTs": "625616ad",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "18a35c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625616ad",
          "beginScheduleTs": "625616ad",
          "lastUpdatedTs": "625616ad",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CYotronT6ZSMUCZv9UHGMQs4a7Pek7wN4cFa4b9XSCHc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DhkqNzNyu56kNXNjdjaCgxP4yC1B8GQJVHsKZETh6YNh",
      "vault": "DUgwKCMUtMpmKvAUzi6Zz5k88zj2tGBRJJ2J6UAzHS77",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "624bf8f9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "13e339c4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624bf8f9",
          "beginScheduleTs": "624bf8f9",
          "lastUpdatedTs": "624bf8f9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "22c110",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624bf8f9",
          "beginScheduleTs": "624bf8f9",
          "lastUpdatedTs": "624bf8f9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8Emf2Mnt1vHeuMC3G4Jzn91FZkkk4BGk9u8fsaX7DfaG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6Vtmq3p2gdLRFF2PLo2tztwXU9YV5J7YtBwHfq3jF7pN",
      "vault": "DHHezUZ3Pq5FtG563kENcw5mqJaD76V1t8UXKtx1h4Um",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "624a650b",
      "cooldownEndsTs": "624b59d3",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "dd5653",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3xhinQ6AwEeLQhvVsARiivH4yXLswUTGyUmHzZFcAaA8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "14L44XZN3c5U9GQ278hMwN8r9NKcj599jonZipYgAwVG",
      "vault": "7KU6oK94fSpuAz2p4wmEcN4TQbJoxVXJn5KhhY3DMm3q",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62522bb2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1fdf0976",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62522bb2",
          "beginScheduleTs": "62522bb2",
          "lastUpdatedTs": "625939fc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c8e57",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62522bb2",
          "beginScheduleTs": "625939fc",
          "lastUpdatedTs": "625939fc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DDnDdKTTQRdVgfK69L58D2H9EWxsYQD4qqsEHBKT5oz4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FHhWURNKPo2Y13bPbuvunG1eSz4qVpW9EAXRxZHiK5JS",
      "vault": "BwvCxoRNRMejUcU1DPPsmf2zwKdyB4ZDPNdayh9Gjf6a",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6248ed18",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3129f195",
        "accruedReward": "3129f195",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248ed18",
          "beginScheduleTs": "6248ed18",
          "lastUpdatedTs": "62641c9e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25ccf1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248ed18",
          "beginScheduleTs": "62641c9e",
          "lastUpdatedTs": "62641c9e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "93XFP6QZcGYRYDcV3MWCYoEjW9ZoFRVwK8wGAwMcBQu5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9j4QLwMZqrrg6HasRGzr83aVQVzupi2LcRD1z3hibwoK",
      "vault": "4YpGiBxK5eUNr4dMesiDGwk5LdmNCEd9KrKuv1y98CNc",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "30a49121",
        "accruedReward": "30a49a2b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9fTo8zYtvrtxBR26JFgb7wopk2Shsai4QaC5HWJiDWqR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EzFo86sVYeWcgZ79r3ir7kdmJNdrT7cn2Ft2bEqq4EsM",
      "vault": "Bx27P731tXFJWTcKSheN1v2eSEhWYCYrquAooKHcXEAz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62481ec4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2b67",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62481ec4",
          "beginScheduleTs": "62481ec4",
          "lastUpdatedTs": "62481ef4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "269b45",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62481ec4",
          "beginScheduleTs": "62481ef4",
          "lastUpdatedTs": "62481ef4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6f3CkBxTcekHZnTenrMmACA4FbXw2GwsVts2mpj89VS2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6bq3iweq5r7wk3jTbZ2hmRfE9K4nyQCjccdnncF3yBwy",
      "vault": "5CY5a2fwdDPx1pvTDBAWub1ipJg3EWErFHYajaChi2yL",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625cac04",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0bf423b7",
        "accruedReward": "0bf423b7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cac04",
          "beginScheduleTs": "625cac04",
          "lastUpdatedTs": "6269e449",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "120e05",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cac04",
          "beginScheduleTs": "6269e449",
          "lastUpdatedTs": "6269e449",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Fm9K43aGF4khRj53VWkFWwuGhcdUoSG6rtLXHc3wGaoo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EQTzwdY3dDTBgdGnr8bWdsXKq3ZPwZ9ZptGsoBAth4Z",
      "vault": "7WVy9ddtJN7SFMbGCRJgDeEvZyDrzjNw6MAMim2gJcRw",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625cfae7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "01ef83",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cfae7",
          "beginScheduleTs": "625cfae7",
          "lastUpdatedTs": "625cfbf9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11bf22",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cfae7",
          "beginScheduleTs": "625cfbf9",
          "lastUpdatedTs": "625cfbf9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4B3twVfqPiJjzbFN3snLc5K2GRDV5nu8229Jmw1MNzVk",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "YzuWBK2XpDeoVk9u1i2kfNJZqzAxccvs7x84xRrp1o4",
      "vault": "AEV2o2fCs1N5CUtQJz6sa22NYeBBDx76Wkuv8jx2DZsK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624af915",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624af915",
          "beginScheduleTs": "624af915",
          "lastUpdatedTs": "624af915",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "23c0f4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624af915",
          "beginScheduleTs": "624af915",
          "lastUpdatedTs": "624af915",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DWJTqPdF4JRvsH9G47dnPLh5U3gTs6AvvZpinUeLk5P5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "J6fh9fef9U1HLY9oHKYxMbaE4QKRCWwaubsEhdZiua9q",
      "vault": "4BjSs3QMby5fQ8ZhLsCBUpzFcqcGJWDKDcUcM2FCcXHy",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625c4908",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1c6a4473",
        "accruedReward": "1c6a6331",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c4908",
          "beginScheduleTs": "625c4908",
          "lastUpdatedTs": "625c4908",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "127101",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c4908",
          "beginScheduleTs": "625c4908",
          "lastUpdatedTs": "625c4908",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5gAGmrbKGNCuGwA9sBtNyoHcwKejqXbhoZEU3DPLuMrN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ACNqFq9GkyrWVu6R5UQtjc1VZjivRYomoQ25ytasAYdV",
      "vault": "6RLxsj2FytR2WoPFCX6cLpXFeyBBByYbq9udBB3ASX3B",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "11016e6f",
        "accruedReward": "11016e6f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5AaMciA24KBDTZvVF9BZei75cqr2WHK57gb4uC8Wqq6c",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CFgVx6zWZVzNdpFJeb8z7itjhQ1dFaL8MuvL5tuyr2LY",
      "vault": "ZtZugQuRSfiNczUM9SJ8yfiVL1oDQXg9fpcRMvpXSTk",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6266809d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3823daae",
        "accruedReward": "3823daae",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266809d",
          "beginScheduleTs": "6266809d",
          "lastUpdatedTs": "626680ad",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08396c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266809d",
          "beginScheduleTs": "626680ad",
          "lastUpdatedTs": "626680ad",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DDKj1gF2JBZ5c2KLsCpuGYrxhGkmNUnXFpQfMQGyUUXN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AR5g7qWMdyNpb67ujwj6S9Thdo3HpQH85TGzSNSupmTP",
      "vault": "EQDhxGyYiM6M8268omwVXA4Fe3k9yja8dqr6yLCrHiwG",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0ed8a918",
        "accruedReward": "0ed8c182",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "C6X8NBTK7HpeZXRARTm3n9Kt8Q4ELAvhFQhcWiPHYG4A",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CZFmkBzFk3XWk3zPEuEBoPqpPe6zBUGTVxH6KC8iMXGU",
      "vault": "FdApot61pnYKpaMF8mMQfb4fPVaLSSzmR7YbiDuEaQ1s",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "JwNxAEqi5Ku5mQkSQYAT6Bsy2GZRq7f6EdHkEUgUYAj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CwP4aWg9WZMfPxqKjVfv5PfhRzAdG5xndXYXZo2m9JZB",
      "vault": "4Dx9zqzf41xZoAECAQxTgUJmv8J2e53RKRjbtz1eGR6B",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62578774",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62578774",
          "beginScheduleTs": "62578774",
          "lastUpdatedTs": "62578774",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "173295",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62578774",
          "beginScheduleTs": "62578774",
          "lastUpdatedTs": "62578774",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7CLjvRCqfh1VFSvNFtB1LVHyzxbk2WoAjUBHZoo8Eiao",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5ZWXuuWQ2shxbbuFkwjyNbQQjPdLamPGu1MCgpDpVtkk",
      "vault": "5eny3XytitajuHcZw8Nhsb23DUNbXBvJJqFvkYjLu5XB",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6249dea3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249dea3",
          "beginScheduleTs": "6249dea3",
          "lastUpdatedTs": "6249dea3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24db66",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249dea3",
          "beginScheduleTs": "6249dea3",
          "lastUpdatedTs": "6249dea3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BsvzoKV9kzS5s3j8DuuD4t7b8U5u4mu1T3npJyhdqdPU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6wn3qjDGfFFbtvsaTYK8RnTjFffTGheNx11kd8HnFN3M",
      "vault": "8mWCH6L6f9jx861JcXGX5vc6vPG2vcRQSuQmGKgStM1o",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625f817b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2c3ca80a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f817b",
          "beginScheduleTs": "625f817b",
          "lastUpdatedTs": "6268ae17",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f388e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f817b",
          "beginScheduleTs": "6268ae17",
          "lastUpdatedTs": "6268ae17",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "pUa1Kps4Zq2ifZKSD8LJXRhNd5dBkup6Zrc623XsqQu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4TjRmrgDWGsfyvXqwnJAuaWHrkgqSWAWA37BA9jvkKz9",
      "vault": "6rUcMJfdVa6h15qKRV28NaYebYNBMWhjUdoVT4oC9EaU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62477ced",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477ced",
          "beginScheduleTs": "62477ced",
          "lastUpdatedTs": "62477ced",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "273d1c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477ced",
          "beginScheduleTs": "62477ced",
          "lastUpdatedTs": "62477ced",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GYHbJQrSdideShLnwdKx8ss4udZKzXnvew4nFxuJHAuw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "sJ3tjbHLJ3c7yetwnYJt18GJzyi8MpwoFS5EVr7JZZN",
      "vault": "ChSnnSkBFwx1ACcfF5oTSA7DLJbp8q5SBRZ4HsNG1Msa",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "216b0d50",
        "accruedReward": "216b0d50",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J9fgUEqo1j157djU7jBmUcisuEAgs5cEfYm41byReQvu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CUM7mZYVGhECvsfUM2se6ZfiBkqQ165jLLpohgkbs4jP",
      "vault": "6sTP52nHG9ZyCnpE48yo97gewp32EHL7YQT2jym3WFGx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6252eff9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2098e878",
        "accruedReward": "2098e878",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252eff9",
          "beginScheduleTs": "6252eff9",
          "lastUpdatedTs": "626471db",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1bca10",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252eff9",
          "beginScheduleTs": "626471db",
          "lastUpdatedTs": "626471db",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HWVMWjfnZB7eN5teyPyZbX6eimm95WTBktSgThVnHhWH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D9heE4E3mp18DikXgZbS6iNxLk1LRZa5JevLJNtaRM2C",
      "vault": "Ai6ZJgV4Fy6SQ9knphKuhNF3mLUWpdPNqCqdCnPx9hRw",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6248a1b0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "133865da",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248a1b0",
          "beginScheduleTs": "6248a1b0",
          "lastUpdatedTs": "624fb78b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "261859",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248a1b0",
          "beginScheduleTs": "624fb78b",
          "lastUpdatedTs": "624fb78b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2RcxMsrcDRKw3jXK2BGiwi7QPhkbKGUsniskLgyb1149",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AFtCPcsqvNRQVujLZJMB4KRrkmFS3PNZdmoCo9ZfoWTg",
      "vault": "5pHSvrYh96GhkdKRjNhbgSuq52yuBRUFZjgtQU1bk2yw",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247728d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2d5cc3d4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247728d",
          "beginScheduleTs": "6247728d",
          "lastUpdatedTs": "626087f2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27477c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247728d",
          "beginScheduleTs": "626087f2",
          "lastUpdatedTs": "626087f2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FxQQ3UqCgcK3V823HuBfHNH8Vzaa7vcyrBTKF4jaBJh6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DbvuAeAiqjWJ8WY91udL7JE9UfAR6PeSeQJsucwyZCDn",
      "vault": "6ivPceQRSaFJqgvE2JLEJVRKHtEEGeRJP2n4d59iviq9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6252590d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0f4cf1fc",
        "accruedReward": "0f4cf1fc",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252590d",
          "beginScheduleTs": "6252590d",
          "lastUpdatedTs": "626344e1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c60fc",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252590d",
          "beginScheduleTs": "626344e1",
          "lastUpdatedTs": "626344e1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AjNB5Ny39VbZnZDnYFZA94eMsFGaNYZrSnCF7HPkDpKb",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7Am1qdTrum8xYeJBZivwXwCG4dDtic5iWk7hLinmfses",
      "vault": "FbZfq5P7RDrGSh98pcMMGbUbqThjF9bccUvvKyuh6FMq",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6247cf21",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247cf21",
          "beginScheduleTs": "6247cf21",
          "lastUpdatedTs": "6247cf21",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26eae8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247cf21",
          "beginScheduleTs": "6247cf21",
          "lastUpdatedTs": "6247cf21",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "vnpKi3CQsTZQLkMTA169XuFUHexKggEGcRYvWxfTTJB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "911fuPxsEPTdEE4qGyYGdcftbzf8VbzuaHjfVZDBC98X",
      "vault": "9MRUAy1kGSB8UxjTLPgGhwZYVYtRhdLEHBoK815wqgQh",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5W18hSTykzpY7RWC5S91mJb6r4AwC5BmoHpX4KLS4eQy",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8QN9j4kvPNGqFsUHUFGPi2uoGMpxTyHjnZPKwCZnPn3w",
      "vault": "63As31E7tYcmJQpGsxyyHzY4nGTuaVnYvhzk2g6LYtsx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6265f731",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "02b6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265f731",
          "beginScheduleTs": "6265f731",
          "lastUpdatedTs": "6265f731",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08c2d8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265f731",
          "beginScheduleTs": "6265f731",
          "lastUpdatedTs": "6265f731",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "83fjALkhvkDKn8zf4ZKdv6vmqvgTn4QBK4rS7pmHVTdM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "78ZCJrNpULg85xiMDMKfK9W7W4VVd1v5BioV1KLyFMhr",
      "vault": "86YzQDEMMmu6cATVU1D3j7juLx4MC4F1cjs5gdbC81dC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6254506e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "06ee5170",
        "accruedReward": "06ee5c49",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254506e",
          "beginScheduleTs": "6254506e",
          "lastUpdatedTs": "6254506e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a699b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254506e",
          "beginScheduleTs": "6254506e",
          "lastUpdatedTs": "6254506e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6vznCotVhoNX2v83M4zWMV94pFmRwQQ46QcBygBPaDfh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GmLqY8HfbPYT15Vwb2KPFEzFen9KmHuQsLTYuaL1Zo6o",
      "vault": "8R6DE7X7cgt21JbbNjSs5ucnBzo7Vx93ZHTWMMJWhPnP",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0bb354ff",
        "accruedReward": "0cd13f41",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "86ardJWduFxmP3Uubh1o4XUPj9PvcMpjVe4KJ3WBrLbf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4kNpnG4ip1LCRA6L9xHE45qHeAbXyfF8iuGg9oNRuSYW",
      "vault": "7bp58GfvTVZ7WPBtCQ16d2vWnVWARUr69LURzSFgnjk5",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624c7ee1",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "06b27f7e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c7ee1",
          "beginScheduleTs": "624c7ee1",
          "lastUpdatedTs": "6253e6fa",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "223b28",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c7ee1",
          "beginScheduleTs": "6253e6fa",
          "lastUpdatedTs": "6253e6fa",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7AuDKNvPmRDtFbTUteWFBstBTc5DQbuEyPhzXtPxqav7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GKtfoFpqrSgw47DwY9xyHqU4Bpnya26LFnJ66GLTK3qG",
      "vault": "G2eGdDnJraV38W6jRwdJTmM8DAqhsokhQKNyM4fFCvDn",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6258ac25",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "012d27fd",
        "accruedReward": "012d27fd",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258ac25",
          "beginScheduleTs": "6258ac25",
          "lastUpdatedTs": "6258ac30",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "160de4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258ac25",
          "beginScheduleTs": "6258ac30",
          "lastUpdatedTs": "6258ac30",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2Ru6JFLc8NCyXbxfpBPs5UZxsMuapMPkYVU35y77H8Qr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Gpvt1ykksvR1jYUeMPcFxnpkhs727C12Dwapvn3b3DnG",
      "vault": "52Hw45irDh4wD4pr9KpyFj5QzfVceL4QziG6ZPaok1Uo",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0baffcb6",
        "accruedReward": "0baffcb6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7AHPZvvomkJpV7xVrCkwJrZyQk97w8ZUihorc4rP9a6Z",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7Wasd16EUUP2cjo5VHhGVbTiWsASJc8dXgjhitLFh52u",
      "vault": "2rNGzeQvqghhcMgrABanUqMEWUMLovLZAQDiCaShVkM8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "09",
      "rarityPointsStaked": "09",
      "minStakingEndsTs": "625263b4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "cca6d37d",
        "accruedReward": "cca6d37d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625263b4",
          "beginScheduleTs": "625263b4",
          "lastUpdatedTs": "62643092",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c5655",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625263b4",
          "beginScheduleTs": "62643092",
          "lastUpdatedTs": "62643092",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "H8k1yz2X7xWxZxrNH6gQNRfgNX3E6kwsM4xQiSxEUrNp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9PBKyifdHigggWjHotDqMGHmMbTo1QbKwJEddSgTDsbk",
      "vault": "3nvmXjBLkYY4AK6c3gc185mCxUgznwaRnrcRNq86fSfM",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2387c9ee",
        "accruedReward": "23884c23",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8UxmejfbPEwRyjA4kwJyPzD6krQHHy36AaDi941PnVbG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HirPiXu4X9hLCiFPxTuzz5ZqVumUJ6jzyJmvTn9Sw5i2",
      "vault": "DQJA6VH4cycz7iyXUwnbxET5fgPcM7AjZRZhuaM6AG66",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2078f791",
        "accruedReward": "2078fde5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CUWyAMv228ZYv9zCpiTNDni6wYmug77oCce73XDbNpP2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AnvdXFL7mLQdZcvNRdfmuQ52EtnZvxGxoTJi7ye6TTjz",
      "vault": "AwiV8kJBr1ke5xXRRkYK7mW6XX2dqUGXNhCgv8vgS2TX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624e18da",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624e18da",
          "beginScheduleTs": "624e18da",
          "lastUpdatedTs": "624e18da",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "20a12f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624e18da",
          "beginScheduleTs": "624e18da",
          "lastUpdatedTs": "624e18da",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CCvMt4a1STpDHwdDLRbc19qVVasUStVaobhowh5xzyR6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F62j1HpDEH7XhErzEbBtkZt6gm6RSh1SXVeYTHiq5iKE",
      "vault": "7zdgpXJWkHdqzxNJc7TiRHRbsePxGrA7rfQdB8YUSuMf",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6257ad1e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1046",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257ad1e",
          "beginScheduleTs": "6257ad1e",
          "lastUpdatedTs": "6257ad30",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "170ceb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257ad1e",
          "beginScheduleTs": "6257ad30",
          "lastUpdatedTs": "6257ad30",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7reemPmAqFBhw9cYcVYWAwvW32fhhG44DHxhMHkxyPQD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DN6n1CWtdZGgJgNbszYnCpnCi5JyTqdqdnzduiE6FapD",
      "vault": "86THRgV9iSXQ89xQg5SQJ7SX8MsKi6pWwaCgLeb8kj27",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "62477a00",
      "cooldownEndsTs": "6264a143",
      "rewardA": {
        "paidOutReward": "1a5c76de",
        "accruedReward": "1a5c76de",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2CGkqr2sSr4e8s1SvCVtNH6jineEjLHxBQcgWZZgAB7G",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4VgD6qEsihtW2pjjYJNibRv196rRvz3QXguByjz4A6wz",
      "vault": "Byhv2DkNLes2HcVaGALPC5pxMWx8iGJAg6KkgZktSMxo",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625a0f8f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0e0abcca",
        "accruedReward": "0e0abcca",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a0f8f",
          "beginScheduleTs": "625a0f8f",
          "lastUpdatedTs": "62647a8e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14aa7a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a0f8f",
          "beginScheduleTs": "62647a8e",
          "lastUpdatedTs": "62647a8e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3WbG2V6xGV1VDujALYE4VkBo1ohtGARpkJUzxYSPXcya",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3FuxSY4n6NXgQVwGvyo3U77oqgSax7X12XUCiN4Qiake",
      "vault": "B5ygwocvGb7f6QGjxTcCExgbx8yFJgot2YpkpEzAeRP2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6256854e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256854e",
          "beginScheduleTs": "6256854e",
          "lastUpdatedTs": "6256854e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1834bb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256854e",
          "beginScheduleTs": "6256854e",
          "lastUpdatedTs": "6256854e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AAMEp1yvfsWt5gw4PC3qUtz3t2zUN6332QDC6fQCJpAR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CMRvr2dSR8Q4hsREC7xQcXfDh3s43Su3trMGHxFsHd8h",
      "vault": "3nadanAfQjH4NNbocm6JLhgtaPQRbXyGkinxon981pyN",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62562391",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1ef34d80",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62562391",
          "beginScheduleTs": "62562391",
          "lastUpdatedTs": "625886e3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "189678",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62562391",
          "beginScheduleTs": "625886e3",
          "lastUpdatedTs": "625886e3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J4MkWjSNj6PnB6MgRZonhSGj2woEcuQEgoSifVSEoohv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CcJ4Uf498kqUXcs3CBjjCorFp8w41hyovtnUkQfXjC1o",
      "vault": "2iPhfRyc14zkKX7FcEeZuxav3UokkRysfTJvacjPm2Fh",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "625cfdcd",
      "cooldownEndsTs": "625cfeae",
      "rewardA": {
        "paidOutReward": "069d5818",
        "accruedReward": "3565a002",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FbGM6izRLZaqaF6gn77gUeRgaCubzpMrUgMmbSUQKpS6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FaCYD27Wm6aZBFM6HkXLS3FFBKV1WtjJ9vzurLL5ejzC",
      "vault": "8Jjp7zkPzPJ34EYYicA5FjN4ggiU2LstcLWMCsDY53mV",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6256e9e6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256e9e6",
          "beginScheduleTs": "6256e9e6",
          "lastUpdatedTs": "6256e9e6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17d023",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256e9e6",
          "beginScheduleTs": "6256e9e6",
          "lastUpdatedTs": "6256e9e6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GKie7H2An3VTLNHYo26nMVvXwt3vy3VYXh3PM6eh1BJX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F7CiQ8rRMdYvMmfwCYUcRKJhqa3jMiNZPweKHoEXLgmY",
      "vault": "Gna3sbXpDFfTBPeQWXUgd3KjAR2cHpUkMNE53XhfkcQK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247ea43",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "199e81ae",
        "accruedReward": "199e81ae",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247ea43",
          "beginScheduleTs": "6247ea43",
          "lastUpdatedTs": "62643f72",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26cfc6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247ea43",
          "beginScheduleTs": "62643f72",
          "lastUpdatedTs": "62643f72",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7Z1AP84j5rcU5RoskcUXqdXcyvZGW584xZ12wWSTcRFm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FaTUYBZXUTPEyscs14mbgqpkjdbdjh8iCtW8yCi8RFYM",
      "vault": "ESUXKZuxxorSYto18iGGpJNxoCFntvHthS4hqExAwiUt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6259c099",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259c099",
          "beginScheduleTs": "6259c099",
          "lastUpdatedTs": "6259c099",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14f970",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259c099",
          "beginScheduleTs": "6259c099",
          "lastUpdatedTs": "6259c099",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6tiRiN1pL8meCGkGQjkYKkby8Hi46ux8oZm69LBTE2nz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CqrvQecz1kNGCmWKjdE3Bwg4mQAmABPSD5JR4gXNGbhP",
      "vault": "EeAmiPajPgbAeZcaoZFQicYGzftxgfAomeK8fTWq8DWs",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6260cb9b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "133fba1a",
        "accruedReward": "2afd0b71",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260cb9b",
          "beginScheduleTs": "6260cb9b",
          "lastUpdatedTs": "6260cb9b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0dee6e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260cb9b",
          "beginScheduleTs": "6260cb9b",
          "lastUpdatedTs": "6260cb9b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "42UgcLdBXvv34ZfcWuaNJZgZdupS6pYLXyBtBaSSR3R8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6BnGWVNKSh7AbPcFc5zN47Qj1h1orUmXGhwEa56qUZJU",
      "vault": "8SKTLg4vbdDPmZR51vopmDEZWv2YkAgw4nEFuavM2gV",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6257632a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0b90897f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257632a",
          "beginScheduleTs": "6257632a",
          "lastUpdatedTs": "6257632a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1756df",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257632a",
          "beginScheduleTs": "6257632a",
          "lastUpdatedTs": "6257632a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8ipGoFhKvrM478aWXKkbvrPDdAFX9Uz4b7XzSeJjedFL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BepkyYLmGjCpQC1KYdV3XzkSmTsfx4qrnrzyQrY62zfq",
      "vault": "FVJDgQhFJYs7Lv8XU4qPfZ7HNj6GxUSWskkh5eDeFapz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "62647661",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62647661",
          "beginScheduleTs": "62647661",
          "lastUpdatedTs": "62647661",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a43a8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62647661",
          "beginScheduleTs": "62647661",
          "lastUpdatedTs": "62647661",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DUAFXmtYVZzwLpK8DkfTZ8KXaEhAuVY1LPqyoHYbcX79",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3i4JBec6ZNfpxr2SRbseYpQ3C8ykJRwXfP4g5vMV6PkK",
      "vault": "EtAh9HGttXvdtLWqpGgC5b9JxHFNsha2xmFLGwAep6gx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62476d27",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "117b9b91",
        "accruedReward": "117b9b91",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476d27",
          "beginScheduleTs": "62476d27",
          "lastUpdatedTs": "625117fc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274ce2",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476d27",
          "beginScheduleTs": "625117fc",
          "lastUpdatedTs": "625117fc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6v889LnLtGGvAR9JD71mfVVUp1QBtTr82o8Hq2zSpgdi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CuDVC8sZetCciixPhpcH8NgTqjzR1kWSDo4pnVkjpWMj",
      "vault": "57s9VxiW9WLpAsY8wfspK29hvJ8PZS2zBETim41r3jx8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626628d6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0bc1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626628d6",
          "beginScheduleTs": "626628d6",
          "lastUpdatedTs": "626628e3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "089133",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626628d6",
          "beginScheduleTs": "626628e3",
          "lastUpdatedTs": "626628e3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "G38n4YedPNMwr8nMR625unZ8mdgVkDE4JcrMAYRaxQiB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7dxAbneFgcvDPSuYr5TJvL3QGWbzsFSWnnB7mzYzQUBx",
      "vault": "78CkvZXKcNUstUK57suJbqUiauFdZRzgW3YAzNqgrHpu",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625b0115",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0f8234ca",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b0115",
          "beginScheduleTs": "625b0115",
          "lastUpdatedTs": "625b0115",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13b8f4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b0115",
          "beginScheduleTs": "625b0115",
          "lastUpdatedTs": "625b0115",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HMjsiYhDVVxZe5Uxbt1aPq2zcFk5F5SSYfjyDHhMFbce",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8ZG2aggfscFv6KL82v4ZXoXSyFouy46Kvnh6ai4H1Umg",
      "vault": "2M1GxEweGtvcSrWta215xC82JYgbkz7ZfmYTpCMjFpyK",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2c54y89AVVoFLpDmNq7pPCwCSPh2HdaNTVi7ZJUwdR7y",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AFs2WETybFFDXT9c1qmEkZg6FTJvBDPSpBzbBtY5dKid",
      "vault": "FAoEtpecqu7v9Gihk7d2tCHfRXJiCmmDYhwSahAAzrAi",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0971e2be",
        "accruedReward": "0971e743",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AZB1B1NTynRYfAx1JzaSPqYHkB85R65YoHWmKYCMZUGy",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4AUZHHu6XFWKL36RzwiqLT7mcw5RiY77Egfobx4JbCpH",
      "vault": "B4sBYXvWmYRk4VoWhYAoh8UuYX5kuzTwKYN8FQRdVny6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6254b773",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "10b7b65e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254b773",
          "beginScheduleTs": "6254b773",
          "lastUpdatedTs": "62673478",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a0296",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254b773",
          "beginScheduleTs": "62673478",
          "lastUpdatedTs": "62673478",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GA3AJLBT9TohakNgtUcXdm7HjRizPv6gqAMP9Rx5ayr9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2Tu7grWTMcYZtYsPcoFk91j8n7XEAW4DfWNoGgreYWp1",
      "vault": "ANWwSFJVA4yoYow2BqdWgSEr2re8gr1jy3DG1Gj2dhVS",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "833823ab",
        "accruedReward": "8338564d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GdK8bURePnpDu7AoGVAEUURx5bHfgejXBdnALfK3EbWc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9GeuuJiyW8EDYCTkWMpWobxfFTgiKoaEY6iTxNhCQbbB",
      "vault": "14rPL8dZJUBKkczb6Uen4A4SdL81Bx21Eww7BrJWB4FJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "07",
      "rarityPointsStaked": "07",
      "minStakingEndsTs": "62659bb0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "f540768a",
        "accruedReward": "f540768a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62659bb0",
          "beginScheduleTs": "62659bb0",
          "lastUpdatedTs": "626abb0b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "091e59",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62659bb0",
          "beginScheduleTs": "626abb0b",
          "lastUpdatedTs": "626abb0b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E2pLnBVv1uqM8FkeAzrw6yRSHizsmU53TkjTGs1zJ3mt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A7THisYzRTW5ZpiC5orwytKt1gK2WP3qcR8Zs4xDqyj3",
      "vault": "ExxfCFgumj2oCXfPzgCD7unKxkgd2bdjVbA7UhMSNNpz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247c60a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c60a",
          "beginScheduleTs": "6247c60a",
          "lastUpdatedTs": "6247c60a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26f3ff",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c60a",
          "beginScheduleTs": "6247c60a",
          "lastUpdatedTs": "6247c60a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CNrEApMH8s8Ryu5V8w9tPoUhnytMgrSAYRA1hWQoMC8G",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AU1pK1XQ1K8YErVYt4As25DsyptBSKCw6C2sKLEDAUBX",
      "vault": "22qzK1j9WvhsPY538vHAXmLzpLBPPiKdi39SP92Vnzd3",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6NGVWjXpLyTrStFk418MRXDRzcnfwQ9Ag3AWwdsiMMdv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "81Mdq7WsCW1QYaf1Czq5nzv2nqZzoeq7gghV7jEh1QUM",
      "vault": "Hc2mZNsjE8nCDmq9gxZHDKhA1HFojEK684XnZ87JPbe",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AYJSUMhd3PdtnxaBYvvPjzryVuHZ58LDoZc2xmcnsW5v",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CxnnJ2qB3byqevEPK9TeRpxzoGfhoC93DiCMsRiMyUnY",
      "vault": "3V43YqAJTXcskW4zD1B9rduYFFcTuAa58oaSc6Q9uCjQ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "9c0d84",
        "accruedReward": "a26a05",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "61qFufMxKo4oCpLXCtX1X824i6qr3Z1kZaS7YSCRf5xx",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BYQVfmcyao1GuTxyiR6qrZytVmcmBX9iJfFXGuuqHQcg",
      "vault": "HhHpMb8AhcLdL9FyMxFkwkZfVPMwTp5KaFhcrUV5Tre",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6262cc7f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "18b42916",
        "accruedReward": "1e8aa556",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262cc7f",
          "beginScheduleTs": "6262cc7f",
          "lastUpdatedTs": "626941df",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0bed8a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262cc7f",
          "beginScheduleTs": "626941df",
          "lastUpdatedTs": "626941df",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "G4Q1mhXQ3MixeypEV1Dn4Zn6x4Yy3UmDngPVz5QL5DCC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "98xtxuJiZkAQ9nPR2jPm7actcY8kZVeEKaFqKi7PBNEX",
      "vault": "94cMWDzaYNVz7fK9rRRvcq74hdf6czCo2b1k5eUAqk1w",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247aa12",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2cf3ab89",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247aa12",
          "beginScheduleTs": "6247aa12",
          "lastUpdatedTs": "6260855a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "270ff7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247aa12",
          "beginScheduleTs": "6260855a",
          "lastUpdatedTs": "6260855a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2oD9BDVKbdgcAUFUWiA6b8d5saRgxHVgp8ikKeN879hy",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3F5wjw7YdfJUGZcybgs5T8GPpqjvWBXQbgirtE1JvHwU",
      "vault": "7RaQwbDQJ9YDkowqM4D8G2nmLX9yrRjLuuFd6de788sa",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476bb3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0f7fca4d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476bb3",
          "beginScheduleTs": "62476bb3",
          "lastUpdatedTs": "62588fc2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274e56",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476bb3",
          "beginScheduleTs": "62588fc2",
          "lastUpdatedTs": "62588fc2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5TLoTw3to9GPTmnYED88sP3hdLoXfYtyUKdmdamNduTU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ArK3wXNxRiC1PfwJVsKuAZyChyuL9vbptsycetdHHknS",
      "vault": "BQ2dayamdYd1Cy2L3sXE3fduVcVasb6UT6Q7yx2u5k3V",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0594a013",
        "accruedReward": "0594a013",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "288qjAfCTbcPcVKSTzsYEnFwXmE7YWQNyKFAuEEFpZNg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EWq4oigjqM4X94cmTyzU2Y79MXJNu1DB6YqpRt6x6ePn",
      "vault": "BhPVVqXHN1HgbsejfsyPxwXTQGuAGL5kvP8R4crLGDXe",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "021a0f3c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HP1H5Vw4tctxGYiKF6wnLSZ1Fgd91JjdGmMCFTXjzDm1",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Ddntekwhbwc6w8RdEef3YzTJ3zsgsZU2whCZVjqZasrU",
      "vault": "Aq3V54qndn4fXHYMJcdrKPv4sCXX77TSfzrLK9MnxMft",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62484e32",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62484e32",
          "beginScheduleTs": "62484e32",
          "lastUpdatedTs": "62484e32",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "266bd7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62484e32",
          "beginScheduleTs": "62484e32",
          "lastUpdatedTs": "62484e32",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DBkihWRhYWnxh4fVHbf9B3msW292M4go8rLZc3kJkeok",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BxMf5BANrfkMxDNhwpP6412v6MnAwA91xHPzHXDekhKM",
      "vault": "EyxUBFmdwGGCUWyFUtPPabHdufekzVLJBkzxPNLGY5vV",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624a08e8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a08e8",
          "beginScheduleTs": "624a08e8",
          "lastUpdatedTs": "624a08e8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24b121",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a08e8",
          "beginScheduleTs": "624a08e8",
          "lastUpdatedTs": "624a08e8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8VS5g385EtfUiBGgEP7qWQPeWrGbwESQjDrDNT3SAZog",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CKtqMDsQ6i3Rrfy5NK3qkUC48A73S7GCT5jFqPY4BHKV",
      "vault": "H5vxtL6xr6C8CgBxxSkuDdMag67f4YSeTX1SuF4QHEs3",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "62478b60",
      "cooldownEndsTs": "625e93c7",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "29a757e5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7txxYyds5izk5RBnojLd9U2NvywAtV7D8Dw6tuqGTLB9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GKHEP7dVH7he7NUmgfNm7uj4kG7HcnJQyRySTPkaxJTi",
      "vault": "4xh9dh9gq1u3Wiyhf6hKx8DbdE55ZKC7PSGTtYqfrnoH",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6254dcbc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "184a1ada",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254dcbc",
          "beginScheduleTs": "6254dcbc",
          "lastUpdatedTs": "6254dcbc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "19dd4d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254dcbc",
          "beginScheduleTs": "6254dcbc",
          "lastUpdatedTs": "6254dcbc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ExkZNzoTwUFNartPG1iNrnSqbiKPShssELApZFK5DwCx",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BbP1YicFuesxfAh1YaSf8dFB7mij8dj9hQFGbCrLKjUW",
      "vault": "83m7DYLJM6FvtHpCLSpjMfxJE4EcjPShoUTnssvZmhLr",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9XDyiy1jG5jzwzcEUnnFsYrBfLv4DNrzeAC5oGskb6wN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BhhG2WocpxNRkhjr55nkMjvGrYNKsprHEsYkGnH5hmpu",
      "vault": "JBgJYPyaoX5PVMgbg3MBZzioeVhRrcucJWDbCeprkfB3",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "02baa799",
        "accruedReward": "02baaded",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7tBe5TXfFNH1Q4YMDc3qBTsRaxsSEv7aJzkuNzJZrs1W",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "G8ko5u7XEQqiyKH3LLwJDv53vDakPTucQrTgBHBaGWRE",
      "vault": "5uzcpuy69hcVVewzMB3gAViMiLKjKc9EhfDiRR6tUdRz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6265f1e9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2f9d9c5d",
        "accruedReward": "2f9d9c5d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265f1e9",
          "beginScheduleTs": "6265f1e9",
          "lastUpdatedTs": "6265f1e9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08c820",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265f1e9",
          "beginScheduleTs": "6265f1e9",
          "lastUpdatedTs": "6265f1e9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ENSZSrGB5b8ySuc3vPMcuRkyW9ykguW2T7Tf9qPt8Xp6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BnzckW4PewdjwKuB13bGN3UhxtZRrjvxx4noJUaArw1E",
      "vault": "Fg5crMPjwoMHwmx2wmz17wFJU367nvtFGonhX5WRqvNG",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "016f82c1",
        "accruedReward": "016f82c1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "A12amAzbzZTVCFwrxTg97RALeJNUtARzKSSxcC3a1MkJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HW8KpPzXq6X8PdZLdA82Zr3zuDwhdem5JPPCUqMrCu2p",
      "vault": "FrUgB9bjTGwrJDomVSPP2NGCHNuJEdPHL8hRFWpPRZdh",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HopWA77ZQe93vMCA3eD3uNj9DmSxRq5ypWgcSbSm5Gzo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9b1yRkmFCkCAbVtUWMFs9LsttGy9CpV5pTrHqMZ3G2rW",
      "vault": "wbdrzj21meYW99z3n67EvRQLfahTPPnQBTDi6ZPoxk2",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1c4fb3c5",
        "accruedReward": "1c4fb3c5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AWBaithoSvnQKdGefxjhxwqySG3eBbv2qWir3y9RwaeK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3yaX4JdHHptZfMzSUbQKREc3cCdmFw5qBhpzXnp8Ct8e",
      "vault": "Fq1Nx1BU4RDGuKDsqoY8jwJbhYSundX9RNCmDegAFZ5D",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "625cae39",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "7996ce6d",
        "accruedReward": "7996ce6d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cae39",
          "beginScheduleTs": "625cae39",
          "lastUpdatedTs": "6269f365",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "120bd0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cae39",
          "beginScheduleTs": "6269f365",
          "lastUpdatedTs": "6269f365",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5FYFpJqz5tXzmp7TGHDEssfev1MpxyPNCnJ5UbQJ4K7h",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7RJsvg3vHf49WBZu6N8WPQCdPdcKUkxekRQjjaKtGnup",
      "vault": "BZxqLo1qHWmiT3vz1o3HzqMys974U1HRodJzm6uPYfTS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624c1492",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c1492",
          "beginScheduleTs": "624c1492",
          "lastUpdatedTs": "624c1492",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "22a577",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c1492",
          "beginScheduleTs": "624c1492",
          "lastUpdatedTs": "624c1492",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DSiX6WLmvo27wGDEjd2r7XsneWdnDAs77kvWdKWefvrj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "84ksb5ef8jAK5sVDMdy1LNreDMWiEpAcFjKs5yW9NjWW",
      "vault": "CjUXN1Ttu1Vfe7ig1poMEk37BrrkKfQm7MyUCE5ZN1b8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d1ca1",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "073a3f8d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d1ca1",
          "beginScheduleTs": "625d1ca1",
          "lastUpdatedTs": "62651adb",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "119d68",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d1ca1",
          "beginScheduleTs": "62651adb",
          "lastUpdatedTs": "62651adb",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ACNHEBnHZoyMRNrzwK4oD73asDgALAv7rojxSdrWSpnM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "APsNXumPeCdhbbSc4mHBJq8RAjuii4BXBVVcG3UCfLCv",
      "vault": "CvHEF6gWjSUdN2z4hQKd2m5bduwvJSnsFTvhH4JccqBX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624b7fbd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b7fbd",
          "beginScheduleTs": "624b7fbd",
          "lastUpdatedTs": "624b7fbd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "233a4c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b7fbd",
          "beginScheduleTs": "624b7fbd",
          "lastUpdatedTs": "624b7fbd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4GhEaEQHD1TuW1sJm6uCjtj8wu4JGEPRRgn6gQJZavpX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5CtQodmoY3Ze62oubxYpw1XraWCKj8d6Q5k1EAF2sAJD",
      "vault": "6Ar7RamRrZawSiwZfbBhA3W6GcgfTzwZHmThDhYiFNp8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625ed437",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "015def",
        "accruedReward": "0266c7e5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ed437",
          "beginScheduleTs": "625ed437",
          "lastUpdatedTs": "625ed437",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0fe5d2",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ed437",
          "beginScheduleTs": "625ed437",
          "lastUpdatedTs": "625ed437",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GMk2JLxRm6HPckHyEqUNWSpSYDH64b88pwAQYGqKvPHS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "77acHjkutUR7WTvVeaFg7cdFLNXyhNwnGjEottZTiyje",
      "vault": "J3nZejEMaSVxMScKU2amhgn5Mpsp6QXCNDF8Vt8X4Zp6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247cc19",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0217d4ab",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247cc19",
          "beginScheduleTs": "6247cc19",
          "lastUpdatedTs": "624a1caf",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26edf0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247cc19",
          "beginScheduleTs": "624a1caf",
          "lastUpdatedTs": "624a1caf",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HwDtKPT5L2zbn3Z6U3a3q1SVbRdbwZoiTQujLgbz1JXq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BCAX7T6xMFfENtbNReEb2Fhq3WukbaZnyRChMeANrG8n",
      "vault": "AAtDGnk48DNUMhSJsuQDYyjnqUzT6RpLNFjox6xk9ivr",
      "state": {
        "staked": {}
      },
      "gemsStaked": "0a",
      "rarityPointsStaked": "0a",
      "minStakingEndsTs": "6260bfff",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "01124921ef",
        "accruedReward": "01124921ef",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260bfff",
          "beginScheduleTs": "6260bfff",
          "lastUpdatedTs": "6260bfff",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0dfa0a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260bfff",
          "beginScheduleTs": "6260bfff",
          "lastUpdatedTs": "6260bfff",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HYk2uy9jWZ7CNgS2hqZE52ZngMb32e6QpNsgSRmMjeEq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6FFCUYq8XdPGdGHYTA2kC3PisTJHNLSJZ5kzfe1xkizi",
      "vault": "9kUPXUoCj1bPmA9DK3MJ4eSZXk4Mu8YUr5ackxQd7Rfs",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "625c533b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "4370da81",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c533b",
          "beginScheduleTs": "625c533b",
          "lastUpdatedTs": "625c533b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1266ce",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c533b",
          "beginScheduleTs": "625c533b",
          "lastUpdatedTs": "625c533b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "MhhgscDf1nPLXN8aviTQdYtghDUnvi71L3G7K8Bfz9f",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Gs5oV8gjWJD6Uz7RRLUHa412Z5j4edWxN8w5omjth11K",
      "vault": "9iUPkXwdHXvH2vJHJ6GLE3uXT3Nx1DQpjMoQewAykWgW",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6267f4bd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "26bf2618",
        "accruedReward": "26bf2618",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267f4bd",
          "beginScheduleTs": "6267f4bd",
          "lastUpdatedTs": "6267f4db",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "06c54c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267f4bd",
          "beginScheduleTs": "6267f4db",
          "lastUpdatedTs": "6267f4db",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3UQJRMkSrBmz2NgsPvioCySxT9fCfp7Wq8Cbzo2ijtUc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ByYbn3G3nbUdCZJvZAFNz3RGaMCdDrpFMRJR3FXrvBpP",
      "vault": "FTu4GQohZfHiK9nebwCPYydQZ9CwTvU7U96BvfpanTwh",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247d95d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1ad2f6b5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247d95d",
          "beginScheduleTs": "6247d95d",
          "lastUpdatedTs": "626583ad",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26e0ac",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247d95d",
          "beginScheduleTs": "626583ad",
          "lastUpdatedTs": "626583ad",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8EDYpbUwWJgJkkpqgrYmVinCWFSBCGX5HiD2PdSzByeM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EGim475FZ89AfrM77ms4pdGUq5mh1rrcPyuaCE6vke35",
      "vault": "5YgXYQfyDtm64iP8Ssxh3wqrJrYbsesSexgxCJc7JbbC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6258e238",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3efde244",
        "accruedReward": "3efe4b27",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258e238",
          "beginScheduleTs": "6258e238",
          "lastUpdatedTs": "6258e255",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "15d7d1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258e238",
          "beginScheduleTs": "6258e255",
          "lastUpdatedTs": "6258e255",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "gzmwyXMY48mwWht6MBTZxduw3ME2ck3hTcdQtLVBsbN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A43uAkEj5ZfpVAdRKKxUXVRsM7TSnGZj7pwhPHi4o1fu",
      "vault": "7p7KGs7y4DGYyrVwSSqE5Ee66n86W2NEzcie8VyMVeUr",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62478275",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1ccc5f46",
        "accruedReward": "1ccc5f46",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478275",
          "beginScheduleTs": "62478275",
          "lastUpdatedTs": "62675bb6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "273794",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478275",
          "beginScheduleTs": "62675bb6",
          "lastUpdatedTs": "62675bb6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4Ff7aaqrYmxfWSxb1KwNKjwUbyF84mF4pG33zRW9BQFL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CCYwJXQc8h6giN76Ffq9QHtACoURDdPTgxX3nqC14EQP",
      "vault": "6QZuK1Hv2Q5c5TJR54iQMwPYussqKqxj9jhLwzikWX5p",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6260a5da",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3fb13047",
        "accruedReward": "42db998d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260a5da",
          "beginScheduleTs": "6260a5da",
          "lastUpdatedTs": "6261d09a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e142f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260a5da",
          "beginScheduleTs": "6261d09a",
          "lastUpdatedTs": "6261d09a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Cxsvu9BVieo2EwKXUYfABuydaF3HJHLN5DyqmTGRsZQB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8vb4R4MNM7LDtW5ucrpq2civGicLYrwuEAE31hnMCx2f",
      "vault": "6KJTpdpihNKG4tvCbeyZZdnz9eT45CcMdksnHEAqQfXk",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0cbe6f5d",
        "accruedReward": "0cbe6f5d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GYBtDRbzuRj2LyWsftVhU5ZaGMnYH3bhhVB7oQYiedsH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "zsQ1fhd2PNpnFraELADKFen5ttdf3V6C1B4JBTaSqmp",
      "vault": "9vHS7rbdrLtBXkKPf3MWLPYucDEPRrrHgm6YQSe83hRU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6261b033",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "04c76a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261b033",
          "beginScheduleTs": "6261b033",
          "lastUpdatedTs": "6261b1f6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0d09d6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261b033",
          "beginScheduleTs": "6261b1f6",
          "lastUpdatedTs": "6261b1f6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8aEeriMHifiERn8AdoHFKWhz8Pb3HpEPM1dvCEgWwRR2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Bq9Mt93DRNHTyJ8HRvyXdu6n3FwrC1j3LCf2bPGiU12V",
      "vault": "7AK3DQQGphFjyfVSCRN8Y2RUJ3NkrsFFt1uxp8JrdZfU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6261ce93",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "186a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261ce93",
          "beginScheduleTs": "6261ce93",
          "lastUpdatedTs": "6261ce93",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0ceb76",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261ce93",
          "beginScheduleTs": "6261ce93",
          "lastUpdatedTs": "6261ce93",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BAuBzcLvR6K39fShRHc15pr4J6xMWM8xkmw8xGqtyahi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8BQz7R5vdUq1bj2Xk8q9LxLtn8mNHGcnmSKZ9kNND7qm",
      "vault": "5fVwhzYbza7TWwAaQeZGyGF9MQH5d5FbZZ8mH6VSoidM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6257e080",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "03b64322",
        "accruedReward": "03b64322",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257e080",
          "beginScheduleTs": "6257e080",
          "lastUpdatedTs": "625bfaf6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "16d989",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257e080",
          "beginScheduleTs": "625bfaf6",
          "lastUpdatedTs": "625bfaf6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GfrQwqu56saHwZLxDGaQ2yKZM3evQHKRfpB4SDKWLs2e",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Ge8a4vhiqii1SUSCw6oSCkyt2Gg81B9fNuRhgcM3Qga7",
      "vault": "5mrYMjdCxi83bQfEYKXfkEY1ZWN7gYHG8pP2kudyQ89F",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624baf60",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0be8fa60",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624baf60",
          "beginScheduleTs": "624baf60",
          "lastUpdatedTs": "6258db4d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "230aa9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624baf60",
          "beginScheduleTs": "6258db4d",
          "lastUpdatedTs": "6258db4d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5MSs4LVuQEeUp1g6cnrcm1vVD53QYZcbXGsXUK7zMcVA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GqYTq8QmykKhRnc8N4SmkZhqJZqw5SyHCXb7MGMo7orR",
      "vault": "5fwzoavqq7QprzpU3qo8FFgY6CyGkpS4PNMv1XMy8eUQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625a620e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1a03bbd8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a620e",
          "beginScheduleTs": "625a620e",
          "lastUpdatedTs": "625a620e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1457fb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a620e",
          "beginScheduleTs": "625a620e",
          "lastUpdatedTs": "625a620e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ADCjaE5rQNr8ZEastQdyCS5K2FFx4UhsQdKDUd6RvZcY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "235cKQE1SfTfHiYncjYiRBtHNR23aV5718BuLegwbfCh",
      "vault": "HepKeXzfXM3oJ1kDt51GNFPVfvGQekxuCi8N5J2a3WVB",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62523d2c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3f27580c",
        "accruedReward": "3f27580c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62523d2c",
          "beginScheduleTs": "62523d2c",
          "lastUpdatedTs": "626af6d1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c7cdd",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62523d2c",
          "beginScheduleTs": "626af6d1",
          "lastUpdatedTs": "626af6d1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E2uTRmyj1PW4XxU6eZoVxQCBnvGFqDd1jPVCGB4cXD3Z",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2WFgFPyQnKC4BLSsu4ke79GhEeJF3w2qS742yeaz431y",
      "vault": "EUa93LuR7fvJFnKvabpQv8Zit6bKXnMqUmGK6nE9HFN1",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6266cfca",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0f882b1e",
        "accruedReward": "0f882b1e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266cfca",
          "beginScheduleTs": "6266cfca",
          "lastUpdatedTs": "6266cfca",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "07ea3f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266cfca",
          "beginScheduleTs": "6266cfca",
          "lastUpdatedTs": "6266cfca",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3AuMUGM7X1ebemR2rjoKiqDXTxBxA35jTnRHruE5guqS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F9vX7o3mdDiQgpWcGBfz6aXVJTYUAkmZebai23o2m3xu",
      "vault": "DJiHkE6eT78kBb51xQ6QTcqeYzw8vivncedb23KXyHGZ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d8b09",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "10778d1f",
        "accruedReward": "10778d1f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d8b09",
          "beginScheduleTs": "625d8b09",
          "lastUpdatedTs": "625d8b09",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "112f00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d8b09",
          "beginScheduleTs": "625d8b09",
          "lastUpdatedTs": "625d8b09",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E5UtYL1XgHxZXaws7DK39hs5SREwih1dLBkXnadeiFgJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "625yGJSyXBzPRRZQiqHZTQe5ubgmYaF2JtvUFmz7tiG8",
      "vault": "EEd68UrEN2B12PW7noHYM6mgjaxtGhP8RbdieiqKREwp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247a08e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a08e",
          "beginScheduleTs": "6247a08e",
          "lastUpdatedTs": "6247a08e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27197b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a08e",
          "beginScheduleTs": "6247a08e",
          "lastUpdatedTs": "6247a08e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J94psaguNsLQfBbKbSuceb8TLY575mxKKKvB4FDipgBB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GJV1LdLn9KoWSHUqy1BT3x7kZRtG3xySc4i4c7Fb4Sr",
      "vault": "FN9rAmtRjvZN5NmG6EQrF5WoZ6hBizbGXXvZrLB82kZc",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625fd6d3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "ba44",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625fd6d3",
          "beginScheduleTs": "625fd6d3",
          "lastUpdatedTs": "625fd6d3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0ee336",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625fd6d3",
          "beginScheduleTs": "625fd6d3",
          "lastUpdatedTs": "625fd6d3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3ZfAGAND96yoTzPCzHXvDQL326ZCZhrDWNHDL9Me5pmX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A63kbt1qHwMtSRWLazLdmeoGigGEjXmHi9F7RHWhogkG",
      "vault": "9kGrk7btr4FSLkzy8kY7xEw4EqSHj3S8dEb9wfYpx5xp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624d5fd4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "073ea550",
        "accruedReward": "073edb90",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624d5fd4",
          "beginScheduleTs": "624d5fd4",
          "lastUpdatedTs": "62556327",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "215a35",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624d5fd4",
          "beginScheduleTs": "62556327",
          "lastUpdatedTs": "62556327",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7LTxenbKcN7TCL68U9zN9xfRpDvzRQzxHyfqii9VcsoF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "64mvDyyEjNhjSHmZnv1nZ3eetVPoLcCvtXRtXXAxE2aM",
      "vault": "4TDf6G3WMCu2up8MQw9hj1r8zm9nBjufbRAhGomq23hZ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6252b6ed",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252b6ed",
          "beginScheduleTs": "6252b6ed",
          "lastUpdatedTs": "6252b6ed",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c031c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252b6ed",
          "beginScheduleTs": "6252b6ed",
          "lastUpdatedTs": "6252b6ed",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5VqahBeeq9NNbbu7tzEqmsuTWTHMViRK9S6vsjpncVs7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "629vmfpPG1hQbUUiwbY5f4jk5eZSqGNcrCM2hjNVdhb9",
      "vault": "4X2izUz566JDqqNxFQVqgvgYX21ymFNZrtoZ4NSvqman",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62476d53",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "34a63c99",
        "accruedReward": "34a63c99",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476d53",
          "beginScheduleTs": "62476d53",
          "lastUpdatedTs": "62648a40",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274cb6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476d53",
          "beginScheduleTs": "62648a40",
          "lastUpdatedTs": "62648a40",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7ZwFPGRs4cuvfyLEBrC6qQsKX5GJhR9M1X5Ta6jD4mXd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CG6ghqUjaMVi7S3X8iz7BSX65ZtdF5xck3Uw3gQLqxPe",
      "vault": "HuvDwc9Rmc8HP3JDh58BbizJWfor9UF69kBJw5jjXmcC",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "88dRPQ1PWomHWZVuweFKMWavrKpzaDDRr7aBQyYyXyCg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EwUTcnP6nu3rckUCWVut4bu82k8bGuZ1DGnpQt5VsU7m",
      "vault": "HBHrfNEDpBuihuW6VyPxqzqKbPQyaDr1HEcxZ4PcwJr3",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1474d1e4",
        "accruedReward": "1474d1e4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Etdro4hwb7HoJit4p4SXAAooAc5bExS8hwT4pM2cKxXd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Bvw1rr1fvhhpeTG4X9aqfBrwcJxaoRwRAZArHC5bkY7z",
      "vault": "3qwFkabnyTAszcmLFmHLxbTDWNtJJ8Vj8kuciWwY5CJ8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6249f596",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0def6704",
        "accruedReward": "1a1c9993",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249f596",
          "beginScheduleTs": "6249f596",
          "lastUpdatedTs": "6266d638",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24c473",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249f596",
          "beginScheduleTs": "6266d638",
          "lastUpdatedTs": "6266d638",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AGhMNJLvZMvEM8JCbUfJ7wm3hmBwqrCCtwBT5dZdKKZi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9Z6iuW61cBcWTBDJKSqk7cLVaAoKW836w96XFGj9nV6T",
      "vault": "HSyz6Mj2upzbq7euV1MJE9jML5KUmkJ4mcMgP4KZAA79",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0f50b981",
        "accruedReward": "0f5aa82e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Fjf5ijoTA68fxcDrxPny5PYkdVMuphnFbo1RPWP2FGcs",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "78Zi8rGcQqkSDWHrzDF3o8WpHFTQPfFB8tSQPatcQHHK",
      "vault": "HWG2zY8mBoxqNyFXBvJx8pTUrSxcdFsDfxzcjRr1ggmY",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624d3741",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624d3741",
          "beginScheduleTs": "624d3741",
          "lastUpdatedTs": "624d3741",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2182c8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624d3741",
          "beginScheduleTs": "624d3741",
          "lastUpdatedTs": "624d3741",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GVLSTm6Gkx1jea2Rv41QXrDfWKy997cek7MvKsqb7pst",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "68YMLWBrhh6FoLgjN8uNAppMjbVnaPvkeJY2wRNyqsYA",
      "vault": "FxQYdmnmoaYJe3LrA6sZotNf5w1hyuLSRZyVpiwVbtYn",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624fa1ce",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624fa1ce",
          "beginScheduleTs": "624fa1ce",
          "lastUpdatedTs": "624fa1ce",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1f183b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624fa1ce",
          "beginScheduleTs": "624fa1ce",
          "lastUpdatedTs": "624fa1ce",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DWY3xRCCKN92uwXH51WEfeJsLCRNnms3svA5NPqxdFmG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AvyEbGUX9GuwJuVADvX3prr3WYpDbs51W3inBQVWgNDu",
      "vault": "Hm7huNGjALES3crzMYJ69x5VKbLbVyewr6s7N9eaW9Br",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625ac31b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "225c",
        "accruedReward": "225c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ac31b",
          "beginScheduleTs": "625ac31b",
          "lastUpdatedTs": "625ac32e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13f6ee",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ac31b",
          "beginScheduleTs": "625ac32e",
          "lastUpdatedTs": "625ac32e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EvidrnAtyroTdaF7goFSQZPxnZv2QdKVD4X6Yx25UByT",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A3DZDK9a7BYLDtMZTQ8wUBbyENTG7y6D2DCLVMuU7tHo",
      "vault": "83rixGz2FqML1TSvfQVQ5r1Lzbmbxok2vQZWqnBgbcJc",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Dt2UVjMefLME5tSk3D7jMtGCqxFwoAqUzPPP7TCkXMGa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HVgjaD43M4AAmKvr9Bd84os5tK8ZPBazHev3TrCmasR1",
      "vault": "FRQ5kQQLQHn24fFibutQrgibWGqXwUp4txhrsfRW3Rkx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62513bb7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "37022714",
        "accruedReward": "37022714",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62513bb7",
          "beginScheduleTs": "62513bb7",
          "lastUpdatedTs": "62666c76",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d7e52",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62513bb7",
          "beginScheduleTs": "62666c76",
          "lastUpdatedTs": "62666c76",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Fp4KwJ7GBbVT4t9oP8huCRFL3J81y5uwfDFpMn1wkjgJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7KzDDBpxbrU9dofwShc1AtRbTd4yeWRo29gu9U5xMz68",
      "vault": "Bqm8ypJMswqvVU6ZfxFNUut9mPaLvTtZYGABcenHk8nJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625438bd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "200ea499",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625438bd",
          "beginScheduleTs": "625438bd",
          "lastUpdatedTs": "625cb0b1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a814c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625438bd",
          "beginScheduleTs": "625cb0b1",
          "lastUpdatedTs": "625cb0b1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EF5Wy6embSDhHrFrddBXvf5wCzEc3y5yMgpYGUe5CaEv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D3Sgy4gyABs6eut6srtJyLNCjRgeLkCx8qDSfPUk72Ad",
      "vault": "EdPXabTYspA5ng2BGw5c1tSLBpRuHmZVQWAaiMn1dArP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62480aaf",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "244a166e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62480aaf",
          "beginScheduleTs": "62480aaf",
          "lastUpdatedTs": "625c1b8d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26af5a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62480aaf",
          "beginScheduleTs": "625c1b8d",
          "lastUpdatedTs": "625c1b8d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FfWy4hCfvZRw78QKfix8XXFA7N1Qmv1wAhWRvbz1V5j3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "58ujfvmmqJmpGAPk7WfiyfoKWtBsSh6FbfDTSfMYqcCb",
      "vault": "DtCuioWyM5xUcwP1wHv8rX7FuYV1UwtMdGXh7AStLSgf",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3isXUwaZozMX2dinAqTnjbXV2goXWoBu3FJQ1QgA36A4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GdR5MuJ8U7W4o15vRjW7wmAHXG66jtwReQ5XoN49DptM",
      "vault": "4fQBQpt6X7FS3RWN5Rff7gAnf8cY3qL3yyBmaUrBqxhb",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6263b4ef",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "072a75a8",
        "accruedReward": "072a75a8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263b4ef",
          "beginScheduleTs": "6263b4ef",
          "lastUpdatedTs": "6267ab51",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0b051a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263b4ef",
          "beginScheduleTs": "6267ab51",
          "lastUpdatedTs": "6267ab51",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CqcB1KRKmmvFp3yhYwDQ4b7zXw82xynfTQ9ZvG7ZSMnK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Dw6Y4sdbR4mnzo9UjdECZTviViiNJnGppyE4mTkytToC",
      "vault": "CPusJcwxGVf27ZyhB9o3vbWJxCHPSAQbx6PRhwQ3Xwgn",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62654e08",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62654e08",
          "beginScheduleTs": "62654e08",
          "lastUpdatedTs": "62654e08",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "096c01",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62654e08",
          "beginScheduleTs": "62654e08",
          "lastUpdatedTs": "62654e08",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2S62eyxkR1159ZD1M2Ti8GPySUNacWwuXmPPt8oVvhhL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GQyEcstnNmtRQBX4CBFi39NC4apY9QWCQddN9GUPEQt9",
      "vault": "8j3uBXkM18DDsKDNBuRoE4XHVyitCDbCQY8cxqGywfiz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6269d874",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0bda6c0f",
        "accruedReward": "0bda7b6e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269d874",
          "beginScheduleTs": "6269d874",
          "lastUpdatedTs": "6269d874",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "04e195",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269d874",
          "beginScheduleTs": "6269d874",
          "lastUpdatedTs": "6269d874",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "42xYkeR8QXLpE7HsmtJoUksjTTQDJ5iVs18AZA9U35Aa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HR9KqAtxAEMsPgVtJNv8VkJfDaddepA5973GdkgRWH6q",
      "vault": "6PuzKYhodjgqAiTTgpCFNgSTdBZrr2qSCWcGzUKGzRrr",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62482734",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0c1ee8fa",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62482734",
          "beginScheduleTs": "62482734",
          "lastUpdatedTs": "62558ec6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2692d5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62482734",
          "beginScheduleTs": "62558ec6",
          "lastUpdatedTs": "62558ec6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6APcGoEq9V7ZGrXEjajCqHTYWjDc9ogPyhx7AmtDDstt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3L9Sk3Zb64vNCaKCRXQ278hSFuKcoY3gDGZd1d124xWA",
      "vault": "8pwXGH1DdUSFkNUiXjmdQtMq2hrFM7523GmZd5wKcDXS",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "10fba552",
        "accruedReward": "12f3042a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "548LRAqvpLSkbiL8o2FBYRSxWW2WTu3d6GHbkUhFZpYg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DDbS6Ai9qrS5jfoz4kgyakptyJPsqpHaVsHZXDK96tTW",
      "vault": "9V4NGP6vTkpdbkipbYpmMnzsB8vxYbpav8Y3JSWNwwCy",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5B9aM8FC2ZLkXkBkYLRNxrEPcrLqLJDWwc8SD5UVoXLW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HF52bkZ6bYNxkU2dfoYbC5sGurhvcxNfzToAicEnacWL",
      "vault": "EgAVn4g4fK9AAUgtDzsGx9UBB4gMXc4kcrHUGqYwUyq3",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1a0074bb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8ZZmWLzTt5qMhBk9AZv7J3ytAG9faeL7y8gVAqgBNxgA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GJSKZjyTAT7amiVXoCkZVaa5jtSZeiju6etg8tFddRAF",
      "vault": "5q7Qv9RUoJNwZ8H6C6GwbnZjx6T3eooczwtUX4V8CFmN",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624be374",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0e4c1243",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624be374",
          "beginScheduleTs": "624be374",
          "lastUpdatedTs": "625bb333",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "22d695",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624be374",
          "beginScheduleTs": "625bb333",
          "lastUpdatedTs": "625bb333",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4XaEUGhe1ZC5srqbTfRrk7miSZc7jpzYv38mdSHiuGMK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DmnjvTNh5wicJHvfKY9PeNMn7DaKX66M58jQzSiFPm6D",
      "vault": "FV4zVMnJ8ofnDr1ZiAah5PnbzY5ZUVGW2rD1BTCMNZwM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62638196",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "31221694",
        "accruedReward": "31221694",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62638196",
          "beginScheduleTs": "62638196",
          "lastUpdatedTs": "62638196",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0b3873",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62638196",
          "beginScheduleTs": "62638196",
          "lastUpdatedTs": "62638196",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "n8ZLbuvLS9fiJbm6V8pQ7ya77XBe37RNv3JuUVijSEd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7UP6tLSsmSstLHjc134NgVjrnEvidsu2GHpo1MuJH94D",
      "vault": "9bcCayAF11sm5qvhzdYHiAAGB5Xhm8JHnU7Kv5bEs3Qz",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HhB7oK6XRGM66YtiU1d3bWVhZak1jHDqCGmLNgBrz6fE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BrB1Uao9XrUkpEHapC6JUU1hoQW6vn8knS6Fa8PXWPHX",
      "vault": "G1LA8dgJasWJeZcp7EdUbU4zGpQy28yCe6hAkWRiTx8e",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6uuvcEPmw7QEzcsijUam6wCzE4GJRCuPsLN4zavY2BeG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9ABriivD8ARCQoer1syZEUKxquC6CPQRW5E5BHujyzoK",
      "vault": "BEBQDxwA7uze7LxzYkCTTsCTbh4WQNC1a1n27T6sKsVW",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "625adb72",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0e2e9799",
        "accruedReward": "34e3ffdb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625adb72",
          "beginScheduleTs": "625adb72",
          "lastUpdatedTs": "625d3161",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13de97",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625adb72",
          "beginScheduleTs": "625d3161",
          "lastUpdatedTs": "625d3161",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6fwju7Kcmj85sYNZoodRS61AiN4BFuM8XugDnrqZVpYq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E21n4WAncL8iXPjQ9G37isVm8WRHG3dHrZo3uHapxzDG",
      "vault": "HHCY7uB9PFQHKeCMCumq62FBdPNYwhEQTvsCB7jb6j48",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625308f4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0469d7ba",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625308f4",
          "beginScheduleTs": "625308f4",
          "lastUpdatedTs": "625579b6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1bb115",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625308f4",
          "beginScheduleTs": "625579b6",
          "lastUpdatedTs": "625579b6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HNsb82kJkTdmS5m46Xw4tVZ5BDem12R4yWhoxdSriBnc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5whWkriz2wjctcq8GJ3aeKVBLaqDv6RJHBN6quk4HkVx",
      "vault": "HqCDi5W2XzPHuwFoxLMTzVBVDiTUXXMGHQ1twS7QMmZm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62651a32",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62651a32",
          "beginScheduleTs": "62651a32",
          "lastUpdatedTs": "62651a32",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "099fd7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62651a32",
          "beginScheduleTs": "62651a32",
          "lastUpdatedTs": "62651a32",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HDMHWyTm8n56D9B5XbUmG3VdfP4FVgct4ohocq1fGEX2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Aq3yhLDf6zN9x67gionzabhumYB8D21m5jhacXr5QkQL",
      "vault": "HnZCZVDXVn2utchPPDaxUQ8X4pnW5HWeeBtQ6XY8tK98",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HL97pvk7rmuKgVGPxhkwzkoinC1o6JDnaMYMUcGGBK7u",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9ZXeVkMqqoHb6ZcLtHAghsK2zopbXD2hGHed6eJBZsjf",
      "vault": "6T6dPbJfwWawL2DaHkMfLSg3eyKw2aNsV9eYS5rSpC55",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62539631",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1df1b9d1",
        "accruedReward": "1df1b9d1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62539631",
          "beginScheduleTs": "62539631",
          "lastUpdatedTs": "625d6a73",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1b23d8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62539631",
          "beginScheduleTs": "625d6a73",
          "lastUpdatedTs": "625d6a73",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "42YTj3C4JUELhwAdJav2B4mTXpAjjfsncVs44CTAYchX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CEMXdLsTp1SybzHS5Z47rn7EQ1CiVCSRELHDnshPDpqe",
      "vault": "6vQv14ufcdG68TP5BE624Zui1ybkKbdyb23fENExX8Kr",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6260f96d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260f96d",
          "beginScheduleTs": "6260f96d",
          "lastUpdatedTs": "6260f96d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0dc09c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260f96d",
          "beginScheduleTs": "6260f96d",
          "lastUpdatedTs": "6260f96d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5ocAV1PCTfJNmAryQ3rm8WvFsn1LPtKZYC4nuVJREwH6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HeRrfxkQvrg3whUfQZvhwAntquYomy91mLxwk9MSQiHJ",
      "vault": "9gdQc4ZUb4MB8tSd2SrBRj53hbwedgTti4zgwXdNwiQF",
      "state": {
        "staked": {}
      },
      "gemsStaked": "08",
      "rarityPointsStaked": "08",
      "minStakingEndsTs": "6255fb63",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "4687",
        "accruedReward": "1197a71b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255fb63",
          "beginScheduleTs": "6255fb63",
          "lastUpdatedTs": "625869ef",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "18bea6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255fb63",
          "beginScheduleTs": "625869ef",
          "lastUpdatedTs": "625869ef",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DK7NWm1XroEXUX2Mq4SvDhBWmwLRiEZEVUXKaZu57Ex8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DW683KiEFGujpiVCz7dPK7VH7JBnPhQb6228gfFjU31A",
      "vault": "keHovjmrSKaCFGsD5T1kwxgJD87syhs5TRTkyya3jv1",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62480280",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "5a6c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62480280",
          "beginScheduleTs": "62480280",
          "lastUpdatedTs": "624802b2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26b789",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62480280",
          "beginScheduleTs": "624802b2",
          "lastUpdatedTs": "624802b2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5VtJ9unfNqa9agKTiSLYtg2JFb7ytBaesMkhVTwVSmgZ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EyeeLFKVqQrkDo9X6F1B6HMNqcdvkVW5DZLcwCsYSfwq",
      "vault": "FDU6Hdxg4rjsKsknVc8eDPAWDgEs2iJHFpHgNT7WCtmJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62484d04",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62484d04",
          "beginScheduleTs": "62484d04",
          "lastUpdatedTs": "62484d04",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "266d05",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62484d04",
          "beginScheduleTs": "62484d04",
          "lastUpdatedTs": "62484d04",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7xhrf16SnqZy2WZEdJovX5aVawnzQ4DcyUjQV6huvHSu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2YrkZn5NE6iwoU1LXgqgb9Ytuxae8124fnPpNgK2NNWS",
      "vault": "5hFEd9Kb1RtLFtX61KcGme57ALHVU7ykeN9yMNb3y8pu",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247e4bd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247e4bd",
          "beginScheduleTs": "6247e4bd",
          "lastUpdatedTs": "6247e4bd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26d54c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247e4bd",
          "beginScheduleTs": "6247e4bd",
          "lastUpdatedTs": "6247e4bd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "65qfiUwaWiBsYhKzqBiXbhjj7X7aPZoVKcG3prKjqQZB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2KgrnxZCVRBdajpUKQ98XpuurQ68R4iTnsma1MsqFomx",
      "vault": "CdYQgGC5v9GagFmFTV2KrZyV7dB2beX3RU9FQQVzGByz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62474a0c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62474a0c",
          "beginScheduleTs": "62474a0c",
          "lastUpdatedTs": "62474a0c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "276ffd",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62474a0c",
          "beginScheduleTs": "62474a0c",
          "lastUpdatedTs": "62474a0c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3ZTpW3gHST4oCPLVFGcJWJSMRnikJiBiJdXWjzb1MCWx",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4ra8Xz644jKe36KosdSPtdM1twf7A3Ac96ZWyGUz85rV",
      "vault": "98ntPxE54tWszSof1B3dTNKtJeeiceAXXwJhsvuakyV7",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "eGPrVLHUXQbmS2Hpqty7sXwY8NNKVUV4nbV4dTdTgJP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3X2EiKosxFdrUWG4AHedw86RLtU7HezJHLxMKwFFREfD",
      "vault": "GEUcxa8wXP6WLt5tfXUU3sKvPMBo5RabnC3FnEBBLMB1",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624be7ee",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "021a30b0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624be7ee",
          "beginScheduleTs": "624be7ee",
          "lastUpdatedTs": "624e3b10",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "22d21b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624be7ee",
          "beginScheduleTs": "624e3b10",
          "lastUpdatedTs": "624e3b10",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Gtm7Q9vCJFQVBqrfbS2RgtpRHNmzPnA7WGoJ5YbAXwS6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EkUbiHU8GtQ1qY8CuJPoNfdRG46SWnEsmjvCscLuhJba",
      "vault": "5wgN92tFZH9gHECTgYZ2oqZrGixDJJqxGwVKwj3wtgoS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626ac3f3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3b769ebd",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ac3f3",
          "beginScheduleTs": "626ac3f3",
          "lastUpdatedTs": "626ac3f3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "03f616",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ac3f3",
          "beginScheduleTs": "626ac3f3",
          "lastUpdatedTs": "626ac3f3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Dj1w3nfuLmkr1pxqN3BjYF2yWcJzz1Y1dWyn1LSkKFq3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HqijFvkKTDsqHmnVJSiLt61cfZPrh8x62LvQycz99gva",
      "vault": "Co6AsxD5hqaZKsqTqJt8A52nNwXv5z9Qj2LoztgrxSy",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "091d6dd4",
        "accruedReward": "091d8556",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4UPEw2SUHSYqG5uN54EePjM2AtZFodFK1Hu8WuVYe889",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Ew7z9JozFe2NBAbJwUfvLmwPWd5ENF6iHs6qY1KABsFu",
      "vault": "8kWTCUy4BMbgTWByT3srRYxap9ahroxDPLXzM4px3Adg",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6258cc46",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258cc46",
          "beginScheduleTs": "6258cc46",
          "lastUpdatedTs": "6258cc46",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "15edc3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258cc46",
          "beginScheduleTs": "6258cc46",
          "lastUpdatedTs": "6258cc46",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3H9qZNr549mdX622rmzfLZ3PChEWUQdaQb16hnufccxo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "rFLDJpu274pbuLubd9wRnsoaQftbXGYhF3z6hWoqiD3",
      "vault": "ESXbMMifbr1SRtNDbqrtW6Bgi9q6zp4s92btQjtQQVJH",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624794b2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624794b2",
          "beginScheduleTs": "624794b2",
          "lastUpdatedTs": "624794b2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "272557",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624794b2",
          "beginScheduleTs": "624794b2",
          "lastUpdatedTs": "624794b2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E2mKAqmZcoBoc1TPyz7qUJVfzPhudACnGzaALEf2i6dN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2kXdWb8RpVnaPMLPHv63jaFJ5XyKBKibDNKY8b7sus8U",
      "vault": "HV8xrfEK3C84GSWEDWJHrUf8RZiaznmjvYqK2W6ruVu3",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247973b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1772592e",
        "accruedReward": "1c891ded",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247973b",
          "beginScheduleTs": "6247973b",
          "lastUpdatedTs": "6267261b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2722ce",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247973b",
          "beginScheduleTs": "6267261b",
          "lastUpdatedTs": "6267261b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BsBMYkWVjm1iKs9J7MBu49e4e9NvVhbK5mTFAXexDAQH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FDJFPV3JipgHjb1nFVMbcAj22NMDSqSPrgGjn6jLc95v",
      "vault": "7VHbZGEhWcnRGLAjia5cPfjQqCDhdHy3QCr9bENn1nvn",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6248c1d0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0ee42208",
        "accruedReward": "0ee449d1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248c1d0",
          "beginScheduleTs": "6248c1d0",
          "lastUpdatedTs": "6250fddb",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25f839",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248c1d0",
          "beginScheduleTs": "6250fddb",
          "lastUpdatedTs": "6250fddb",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CP5dz5ziSmXWgmm3cNmfCQeBG9Gnd6ED27Dk7RK2uB9n",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9AhoKQNoxFmAFKu5uzGMZnnZshUU3AsPW69DjfL7ErKq",
      "vault": "WX6536Su7ud6mKsL8XYGeTttGTY6t8obbcmZyPBGoMr",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "58bba655",
        "accruedReward": "58bba655",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8JzMzP5UDK1TgRHBDCBgLvSbgK3GQSgwoCBNkh4Du3xv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5wWu75r7gQomPRKa534QwZPyDVmPLhFynSkNe4JJ2qGY",
      "vault": "7gqaeP8xtAD9K3nBmhJsRMD8ch8CMqvsVtJY13jgyTh4",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "F6qpJgymtPvXQG9tWAntEmhpHaHuoB7Ti4ZaUxFSNdVt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4xVfD4vLnJg35e1rqGWamftVZYqVoKDfEq7HCVukWSf9",
      "vault": "fzmLdpgqA5DFSVgUohHRv1r9s9b3MgJztWdFT6WJKpP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624c923b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "14a3f128",
        "accruedReward": "14a40b60",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c923b",
          "beginScheduleTs": "624c923b",
          "lastUpdatedTs": "626365f6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2227ce",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c923b",
          "beginScheduleTs": "626365f6",
          "lastUpdatedTs": "626365f6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Bn7Pn7aXkdqraCnYH7ujjvHwZLCzoavSjig6FLaDVMAs",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A1LKA4FyC9dQiVjcrRDFMdbqfYiQtSSzeHHgovGEuP6S",
      "vault": "2SfHPkRTBs3mCaDZhb9MWBoaXZBWPo3hPFYwa9Vxasy7",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "626ac3f8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "4c8de961",
        "accruedReward": "5f855f0c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ac3f8",
          "beginScheduleTs": "626ac3f8",
          "lastUpdatedTs": "626ac3f8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "03f611",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ac3f8",
          "beginScheduleTs": "626ac3f8",
          "lastUpdatedTs": "626ac3f8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J6bVvAZsKKFWnrehwbZ9wAvjsq89oLdmeLmcBQ2wLdrn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7KhfJNsHhNVBa9BqPe8pnHGNujuZck9gxkQEjVnUYvwA",
      "vault": "BsBiQmUy5JFgD1fL5zBQTBWA5zMuGEkFXksgz18HQcRT",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6259570b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1f218d6e",
        "accruedReward": "1f219be5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259570b",
          "beginScheduleTs": "6259570b",
          "lastUpdatedTs": "6259570b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1562fe",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259570b",
          "beginScheduleTs": "6259570b",
          "lastUpdatedTs": "6259570b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8rTznEMCxixEfKqZ9PV7dNezrc6xM74Qs9g9RGZ4N8Qv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6u7oxS4z3ubgjG4D1LWgDLhwoXGRsfBmfRM4eaS61YCP",
      "vault": "8s9LRZQkWJu5ttUU4KDDrkYvSa1qDPmkpqEmd4pNxY4W",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62597aac",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "048c275d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62597aac",
          "beginScheduleTs": "62597aac",
          "lastUpdatedTs": "62597aac",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "153f5d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62597aac",
          "beginScheduleTs": "62597aac",
          "lastUpdatedTs": "62597aac",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9znxNPMBsDESF2LF2j9iNQJyarEzomjZWDwzcDJgyV39",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7DxxDNMWAJ5QzeZcffGYXXSSAQtyHoj94NHD8WE83pd4",
      "vault": "5cpWHo1MLUaHNs52bnA8Tkz92nKbYDyTx24Ro4vFcwKz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6269e9d5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "f921bf35",
        "accruedReward": "f923b7c3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269e9d5",
          "beginScheduleTs": "6269e9d5",
          "lastUpdatedTs": "6269e9d5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "04d034",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269e9d5",
          "beginScheduleTs": "6269e9d5",
          "lastUpdatedTs": "6269e9d5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5FdpuC9KzFG4NLxq3w4terLzNt6w5bRFmFPTQekfGUiS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "26XWX8r8NfJGT7AZ8aaXXKCvBAM3HLeJW6kLgXZfQcy1",
      "vault": "7cM3Evk2YywhwWwLfTt5fYksTLpZZQY3J4yzae4aQ7ay",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62478753",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "155d57e8",
        "accruedReward": "155d57e8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478753",
          "beginScheduleTs": "62478753",
          "lastUpdatedTs": "625f27fb",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2732b6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478753",
          "beginScheduleTs": "625f27fb",
          "lastUpdatedTs": "625f27fb",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DehxHK8UEJZKXHayT3nmbo8P1pnBMKebj3v9gn1Nc87s",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BpU4mGJUBZD53JZxLUsCbzm9xQxp1S9Royx4Cjn7F1GD",
      "vault": "GHJmH8Ebz9odWYz6HzD4GrKsvXYege1Ji1PfJHyYDaW9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62477a86",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0406b5c2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477a86",
          "beginScheduleTs": "62477a86",
          "lastUpdatedTs": "624bee68",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "273f83",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477a86",
          "beginScheduleTs": "624bee68",
          "lastUpdatedTs": "624bee68",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "APNBt5ZMhvZ7wxHv32FUxhrW9c5YzqQ3nPhwzhNtU2ZC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HHbsR48q4mwgrdURHNGFqPXbrH2vUQJcgiL1ps98KjLv",
      "vault": "BxeMWZ5SCPHEzTPHrVPB1D8qNsFebxCwhyamnQwAQkgd",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "18db4676",
        "accruedReward": "18db4676",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Hf8ubguaer1GNGAKkPVqjZUxy2jTAFCok7FyLhwR25VK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CzaRjyDizU86zneEQf9HBSXvfH8qSg8nLAw4WAueogE8",
      "vault": "6noQ3Qyd9mnyUZQMAdFZjZuEzmKcTV1hzLZQovsjYFAj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625f7536",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "14be79b2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f7536",
          "beginScheduleTs": "625f7536",
          "lastUpdatedTs": "625f7536",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f44d3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f7536",
          "beginScheduleTs": "625f7536",
          "lastUpdatedTs": "625f7536",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8MtJC84dNMxmPQsnLWibomtLGnU7pEx912Yh32GLSgCQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EbK9KR5bbZK1GKTaHv1pv5jGJe6cRB6ba96Wow2gjQAw",
      "vault": "AcT3HcRUSuPJ5QiEjCijQ5AbSHLDPzcUZSVQy2AEq1f",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247aeae",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247aeae",
          "beginScheduleTs": "6247aeae",
          "lastUpdatedTs": "6247aeae",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "270b5b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247aeae",
          "beginScheduleTs": "6247aeae",
          "lastUpdatedTs": "6247aeae",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Fr8rf8Dwi89kjP1kUou1sQ42bBr9j6v8VWzq7LagukdR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DjWS6q8UVbEAhBXETgs2VMNeq1tp7XnvjAbH8pEPFQkj",
      "vault": "3xBHJccLmBueTBbTh7pYstgxhVwyygPqyK1DvkrpWgH2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "6269a6b3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "8999c96f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269a6b3",
          "beginScheduleTs": "6269a6b3",
          "lastUpdatedTs": "6269a6b3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "051356",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269a6b3",
          "beginScheduleTs": "6269a6b3",
          "lastUpdatedTs": "6269a6b3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J4dPJSEbNhp1uzQxsZNY9rpwYXZevkx5KHMPHwsSjjST",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BXGJw91XpkVUqSzhmthdVmPhxdVpj9VMc4cJvSsR9fw7",
      "vault": "DHgKPE6m1YgkzVB1EMNDiL4zPLgHdA72pUg4aKvvWVbs",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625dc452",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "147e6c75",
        "accruedReward": "147e6c75",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625dc452",
          "beginScheduleTs": "625dc452",
          "lastUpdatedTs": "626725e0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "10f5b7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625dc452",
          "beginScheduleTs": "626725e0",
          "lastUpdatedTs": "626725e0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Co6ThnCF2UCn48qxzuMyLBFCCQDu13EaZeiWkuYgvZNK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3Evps3Lmct4jdaXmKqgmZVkwamUKYN6VSs3tETehAge9",
      "vault": "F4fZmCF6uRHgLqMbSRAwZhLbsstqTDaL2q6j8K3Wufdp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476cf2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1d8e8b37",
        "accruedReward": "1d8e8b37",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476cf2",
          "beginScheduleTs": "62476cf2",
          "lastUpdatedTs": "62681cf0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274d17",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476cf2",
          "beginScheduleTs": "62681cf0",
          "lastUpdatedTs": "62681cf0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Fk9ePhHXHx9gufcT329PJmzdZkSGcHLUh6gDk79mikGL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BKW8ZjTiauVBw2fRZ9f1XxoNfFz4YDZZLdejqRvvhwDV",
      "vault": "JCzeMinRwas5rY6AF4MLSB8UddkxEjd9Uk5fvwM3vtao",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247d585",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "01bfcd96",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247d585",
          "beginScheduleTs": "6247d585",
          "lastUpdatedTs": "6249c4c1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26e484",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247d585",
          "beginScheduleTs": "6249c4c1",
          "lastUpdatedTs": "6249c4c1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EfZ5v92NXmmdrifjTdjBshqrbYKWDGhbnqqtzGZ3uhGL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "rRmoCabjjgKqcWHsNSuTW4nUD4kiNAQZ35wrD1ZaiJe",
      "vault": "6YNdgZWGcKbWZNSF8UBQupq7RNGtyRVLq3cTnv8HGk5o",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "57GUxNyD6YwHGHJb8ioJkd82EWdA5CaqtoVhT6aqZai",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6gFAEbGE9BqZG2kHCcnikESuohK1aWdDmskvpsyEDhWs",
      "vault": "21YxRRnvRWrPNvNagkkNjS6j8nUftnsGFjAsvpQ2n2Ji",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624a88b5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "099cd9c0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a88b5",
          "beginScheduleTs": "624a88b5",
          "lastUpdatedTs": "62552a36",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "243154",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a88b5",
          "beginScheduleTs": "62552a36",
          "lastUpdatedTs": "62552a36",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HwykyHQ2fY2TJMKU21JeqYC2hSD4CVmFANcJsEQMQSKC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "53P92CMYRpwdj7TY5DbS95Uab1CzGDnAXgcUTvBhBcpQ",
      "vault": "3anGuuAPh5gbrBjEixAF4LAqmff22ewNesrfHwm4pZv5",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "219d26d6",
        "accruedReward": "219d4763",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9GiTcFA2oCDiwKjcYEowKCxrQRfV3EAhug714iE5V7tU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2KQ74eoVGEZv12ZQQNSijMSDDqq7k5C2MWJPAVCoah6D",
      "vault": "4vaHRFWnq5yHcQTF2gC5xv24ZUT4wwerC8ffYB9WAJH6",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "04b0be39",
        "accruedReward": "04b188c4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E9Z94U8xfjjbacmigFiN2RCS35cYkTxTQLZyt4pGdSXE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7nyhV5CJn2zw5mM982nRuYYGZA19KzVoGUUrhwWxqHLW",
      "vault": "HF7LCBN34kR5nkkGEQ3Sdejyc9QGhRjbi9cvZouN7pyr",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62617a09",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "30c0b17f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62617a09",
          "beginScheduleTs": "62617a09",
          "lastUpdatedTs": "62631cf6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0d4000",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62617a09",
          "beginScheduleTs": "62631cf6",
          "lastUpdatedTs": "62631cf6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HeozniZxBQxbRHxGWjFFQPJyJ5NTtNsaZK7BTJyzXEkP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "55eFZUxXY85DwEtRKNafZVv39R1YG3CPiiVKdVFjutVi",
      "vault": "Bt6Kiz6KsqTNvsomkJnPTiunHG4Yb4zueQP8xQg6Ro5x",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247bb69",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0fef40e9",
        "accruedReward": "0fef7c96",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247bb69",
          "beginScheduleTs": "6247bb69",
          "lastUpdatedTs": "62508b34",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26fea0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247bb69",
          "beginScheduleTs": "62508b34",
          "lastUpdatedTs": "62508b34",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9SWiDnGyiWqQZR1abQySz7byrZyhnRghqCSMfP8xoquU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EJyPmG2KgD3T3RbNye78J3ibT6cqtQhfkaThG2y7ihQ3",
      "vault": "9hcag17qoW4KQEaAj6Q35NP2bDCvJ4y4xyzqRdbngptz",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6byarUZhaur6eWDGXaX3YQXxkkytAVT6vrvsw7MHLKrw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "J1MHZ7AhunsbrgLo4rgGKNieNsTJ9QUpH69RLALCAVJq",
      "vault": "22HJ4QnstrKkH2wm8tFJertqvXPVd9aHzMfK9AdpvSSQ",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "62480b7b",
      "cooldownEndsTs": "6257b6b9",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1c562091",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "61i4dRqVvJ1vtntiZYfJpDE2jqYy1fN4Kedt6MwVyAu6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GY8KEdMrgvWSoonj7RUJZaTVaqH99C5CwVWacVddJjfh",
      "vault": "9c2bndasF8mkd6ho4ybH1pt669dLugnRcFwAzwar6yKN",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8tnB8yN8Vba5KCupHTaou5jgdX8BzAQ8twWsMicgCJ9n",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CMEoyE7n2GGG2Mc7seStVQcd2KPQbRAGJGsXZMSoESHn",
      "vault": "2mscnG8Qka9CyK847W4P9RxqnAkPav4xPf1A5zHjDPw7",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6248212b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "216dfbd1",
        "accruedReward": "216dfbd1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248212b",
          "beginScheduleTs": "6248212b",
          "lastUpdatedTs": "625a9d64",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2698de",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248212b",
          "beginScheduleTs": "625a9d64",
          "lastUpdatedTs": "625a9d64",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HvxRa6KTkDfkJWSvVVnX3Zaagf5GGdDjCXx3DfK2sVu8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FwYCbdKKMVxeE1riN2qNrZs7iNYnjUiULSA9kBbbiZVe",
      "vault": "7mc2D96Jghr3JguzKfNa8fAJtSU8nfuk1ax9rVDsTEHS",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0b7a34fc",
        "accruedReward": "0bb70fdd",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4nqCfmFDVpJx3pUJ8CnKHJLbCdCV6hPtRqKN3QMWxM2W",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4zhA8DihD59LuTELo63GnF4f6iQ33wNNzu7pPeCQTJfQ",
      "vault": "Bu2wp6MWppPfhWAmCsDyGGWJpHRKPeqQbRQk17vhaNrb",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0a74c331",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7Fw9vdoNeixnGPDLkvtCqJL7Y4gosvK63PvL2F9yMu4w",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2hgDJLqSv7buoFac4mASwcxVgsbhPnGwB6NwLjCp1HfY",
      "vault": "Eevke7ovShuGSUm4yk32c8cmYNAvD6gkJKqVKrPwbYVc",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1814241a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9erX856wqepeV642i58Fs48p28kwUijKHXcWY4nJU5ox",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "77nELcxhU5yDjkLE5YRi6XrE1xuyH6cGRDo8pajFKYGx",
      "vault": "9so6X237qcDrwjeqBUZ2Qo9oQEe2CLrpqfgPBTTzhNNz",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GQGJXDyWjWpCrfwutoMrbHhTKKZhWrJrhqMp16ExqLtP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "j4xfdhuWqu6LacEykBC82pAKLGWd3u3DMHUkegbM35D",
      "vault": "FGCQd1Mo4hW2aDxuTvG43ofyizwj1vJDpBCNNspTiA9J",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "012d9ca3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CKEiRQiUKVXbp231musCKsv13BSfDjooxWtCYDVmJkyC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2jpFuzmKC5abNY95o7dFTcy25GRwZFS2cgf2bgdV3bTS",
      "vault": "4AHWTKSnQBAQ3C9BtRwNefn9nmoi3EGL3E49CQgPWLZG",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0e5e01e2",
        "accruedReward": "0e5e2e30",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7WaZCZHadW5uVkChhuCr5p9ECoPbQqe8bpSFU5VXxmAo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HYZ9ukETFPUyu8Fk6mijvavZGREFaaCN5cU1UnQ44VBH",
      "vault": "EwNvK7MtnvkT2s4a6PbLJz6toNdLZErPM4qhpebxEMgp",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HzrFEJF3UyJpGAH9JF1jKnP1Jh9hN4DJM82bMEVAUgYz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2ihrPTcso6xxsAZhAWT8VciUUREikMoVmyNPQTBHUxcQ",
      "vault": "FKHVc3csmjj36RHdj5BEBocEtYNcF7C7Jr5FyHWCqh77",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b41df",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0aab8bb6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b41df",
          "beginScheduleTs": "624b41df",
          "lastUpdatedTs": "62570ebb",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "23782a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b41df",
          "beginScheduleTs": "62570ebb",
          "lastUpdatedTs": "62570ebb",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FFFXyThBGjUw1QTH6vUpT2w4C8RLZs5FLMQjBQQTnFPA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9XrEmy8145AzHPVcp7SxN272adP9YBTn6yjw3LordUHa",
      "vault": "HKEoWE7zcsZmk6fByWJoz8c26oc1trBC9FSWifexd87G",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625cc7a6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0aa602c8",
        "accruedReward": "11cf021a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cc7a6",
          "beginScheduleTs": "625cc7a6",
          "lastUpdatedTs": "6264b2cc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11f263",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cc7a6",
          "beginScheduleTs": "6264b2cc",
          "lastUpdatedTs": "6264b2cc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9eVTN1QP3cCbTyNPjUygwbiwnvSR5LHsfKtpLEQ8eMwH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BM9pYcQY89VJfuYLsWD9zvbzVmfDRY34yA3rRZr58EoU",
      "vault": "89rpxd9yUFarmYeEs9XW5j9XG3e3CxKNiTDC7EdeN7Vn",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d8f36",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "011bed",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d8f36",
          "beginScheduleTs": "625d8f36",
          "lastUpdatedTs": "625d9070",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "112ad3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d8f36",
          "beginScheduleTs": "625d9070",
          "lastUpdatedTs": "625d9070",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AK9quFhsSNyjtUQctsiFbFNrV5yy1JyizEuG6VqPqvhn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3pBhwptYAfHk7FVpjpAaECbRxU5MEh8XvuNJ5LiwKzei",
      "vault": "59kfCywtggpNys3BDcQ7aqD7yM639pMfhj4SKSfZPZQU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624beed2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "24411e98",
        "accruedReward": "34e960fb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624beed2",
          "beginScheduleTs": "624beed2",
          "lastUpdatedTs": "6266f0f1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "22cb37",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624beed2",
          "beginScheduleTs": "6266f0f1",
          "lastUpdatedTs": "6266f0f1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "73Tf9WgC4h8rGmg2eW5Xwz29dbgjiqyttrp5VYYFwVFN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "132DD3KiiMyLzovfFWDg8pVzEogbNzhzT1Y1UdnxQ2QF",
      "vault": "Axx5pgsX54rqhyVuk2faKAGJGubPgdCG11FFeDr3yfAF",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "62478bc6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478bc6",
          "beginScheduleTs": "62478bc6",
          "lastUpdatedTs": "62478bc6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "272e43",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478bc6",
          "beginScheduleTs": "62478bc6",
          "lastUpdatedTs": "62478bc6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FqGox4zE3ApMvcYhYfvFQp8HhQipDeeYdLh4FZCFKYmR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3fcSdtriGDvrph3AVVSq8HgyrXmtLQE2CZRNuSWmfyLZ",
      "vault": "Fx4SgdwJGQNsw3Ygo97QUUAjcS3Xehf4Rta3u8oiSubh",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6267074b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3919baab",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267074b",
          "beginScheduleTs": "6267074b",
          "lastUpdatedTs": "6267074b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "07b2be",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267074b",
          "beginScheduleTs": "6267074b",
          "lastUpdatedTs": "6267074b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HooFtdZqJdYGcJv5CiL4p4E6RVoKEuw6Xrg9Kxqfgtik",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Esg4XcM6qADJu8vFF9j1KFeZvsTy2wcFim1Ah7WkgXYz",
      "vault": "38Ksiq2f3qEyAhjgFZi5P9Svtz8rHsBVs4iJYHpe1oGv",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476d70",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476d70",
          "beginScheduleTs": "62476d70",
          "lastUpdatedTs": "62476d70",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274c99",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476d70",
          "beginScheduleTs": "62476d70",
          "lastUpdatedTs": "62476d70",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GPuobEKG1K53ATPE5fM4kncEzbBT8J9tW5icmdSf6syj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HfQEmXXid3WesZhJxndiq9EDchNQi3WyezRYRVS5ykGa",
      "vault": "5UUVWra49RQriiRwDKUH9xtw4fKMUJM6qiwLRCfQpQkt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6248b1f4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "38a2f35b",
        "accruedReward": "38a2f35b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248b1f4",
          "beginScheduleTs": "6248b1f4",
          "lastUpdatedTs": "6268034b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "260815",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248b1f4",
          "beginScheduleTs": "6268034b",
          "lastUpdatedTs": "6268034b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "VfCQg9wgSfjDgVEPmq9bcxq47TabTPeo2SZR5dJDmE8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6ivrXrmJDenqrkNNFWbx3ixJ15X5xXJx2Mqv1qeqVHfk",
      "vault": "3Aah8mnLzD4jCanKx8N55iAffoRY3tXSjsMYY3rkGbGz",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "01df1477",
        "accruedReward": "01df1477",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Ry6CYV6AaQcTCcJwyzrwRhZz1gQfkQGEPBKQ5uSsJEA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BkqKCEXjULnU8aLWwNaLna2GtVD3GurUQJnJddAZvXRe",
      "vault": "5ZKV4ZLdzfRdB8ZocpokEyyKqLJ1KJQC8adndSzt2qTH",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1419a8c5",
        "accruedReward": "1419a8c5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6rEN4qKfYpSbLtCWDqmJt1T4Civk8g32T4HjspuYGPLc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CY2hDUsaWD5WSJxXrzNLNriFaggr3JB37twHBSuJcU1h",
      "vault": "8G7mJYNtvY6YajPRgx2NFzjrEchDwhXMon1LvY58P83A",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62477a20",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1d447c4b",
        "accruedReward": "1d447c4b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477a20",
          "beginScheduleTs": "62477a20",
          "lastUpdatedTs": "6267d837",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "273fe9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477a20",
          "beginScheduleTs": "6267d837",
          "lastUpdatedTs": "6267d837",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "21XJesnTWpKhy91vN7bXEWoyn6A3gyUJVhrCSmtByoLJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FbbNGFDLL7sAb26V3fEmAGLPiZvw8RxdmGgGwPXddugp",
      "vault": "BpVtTe7Y22KLNsWcHAdALRXo7zTfA9VeCoJ8iHTM99H5",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "048d417b",
        "accruedReward": "04bbe6ab",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EbH7z5WDz9Akz2cJ2wuAg8KiJzhjfs5KmrWBd4VZdnES",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7Ax6ScWwnLnA9aAWeTiGLgndqiCAGnVsav3iXj3rMfdB",
      "vault": "9rTAFyE5SpDDjaetidVSkMGHbWwSQwF3jyarKxCKH1Dj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626a9240",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a9240",
          "beginScheduleTs": "626a9240",
          "lastUpdatedTs": "626a9240",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0427c9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a9240",
          "beginScheduleTs": "626a9240",
          "lastUpdatedTs": "626a9240",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3WPzA8E2RgucxzEp5Za1WwcxPTnbnqf3Ed73se5qy8xk",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HRbsKzMEMsTZH3Az988HhZ1Dnr2Zw1e6eT6hVW2kWNSp",
      "vault": "6FqDozv5EgwTNvZNZyKFxTYtzYNLuNVCfJ7UKFF9uKg1",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Da6rA15kjR6LWsc4qt9bMp2KzceHBqoJsN8MmJud4i9f",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "H3nGBa5obpcZR2f4gwMgv2rvqMZapx9NkY2TMNguVb86",
      "vault": "CLkTyZKDXwTNmVN8Z6zEjBN27NCvY7YsFnJwf5nw2PFV",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "048d94ac",
        "accruedReward": "048d94ac",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J9k6z4nJqmPoW7frnqjAwXY3Ym2EvoVK8WNTzQW5EgRN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3PBNE2bRfswfjb8NCSjVXRuPVwtSYezdW7rHEz59nSuZ",
      "vault": "Doi1Rq8t68RasCv7AGHu5oD61tKEk3sRpuPkQdYVDogv",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624cafe9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0c10c55e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624cafe9",
          "beginScheduleTs": "624cafe9",
          "lastUpdatedTs": "625a07d8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "220a20",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624cafe9",
          "beginScheduleTs": "625a07d8",
          "lastUpdatedTs": "625a07d8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4GbuiWE4NXWZaqVXNtwbg7joCVvQ7aUTiwwjBW6TFRRs",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8H9dcQDpVDY6kBb6TUsVwDXGSWLYjepxPS9REUiKGv9Q",
      "vault": "ArZgaNLTJYSUFRtw9hCqzuv1H135EJUFosbyzdH5bYb1",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "61ovUzKGRAeCHGRYbsWD3thrFjxcgSpmdft3bH3TeTEG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2jc2G15evHigfP3QprSatn6JtSepdtRKWfY4Tpz7ghzm",
      "vault": "29rcvya9uXfAmEpKnGLtPV1peeNGQEAb42DiQaDUC9Sv",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62649b34",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "6861d74a",
        "accruedReward": "85e94e70",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62649b34",
          "beginScheduleTs": "62649b34",
          "lastUpdatedTs": "62676ed9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a1ed5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62649b34",
          "beginScheduleTs": "62676ed9",
          "lastUpdatedTs": "62676ed9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6vCgYBP6qAVaBamcxNNi2R9iCKRZxCsvaMNVdA5X6fRA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4x9XdjDqhyYcK6HQemMPQyG42JfzBnceJs69drHcnffb",
      "vault": "ErHa6EmdRykbQ8ScMoyAV7PaTzrZ7Ec9skzRqy9ef19N",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62586fb4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0d602830",
        "accruedReward": "0d60313a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62586fb4",
          "beginScheduleTs": "62586fb4",
          "lastUpdatedTs": "626468b3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "164a55",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62586fb4",
          "beginScheduleTs": "626468b3",
          "lastUpdatedTs": "626468b3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4bXSuXrLDbSc2h6c6aPhoAniAfuWF9qT5cjZMeMRSAZU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7er48rjsvJr617Wv1z1kRhPSZf3VQnbF9RcWsSg4daKh",
      "vault": "8MaN14TXae6tLjz2w4UG6PC3ardPE2d3ySwPgkEi2E1Y",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "625780be",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "713dd928",
        "accruedReward": "713dd928",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625780be",
          "beginScheduleTs": "625780be",
          "lastUpdatedTs": "6266f9dc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17394b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625780be",
          "beginScheduleTs": "6266f9dc",
          "lastUpdatedTs": "6266f9dc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2AVywtCqbBytRsFWZzZU2D6EpXfJpPZvkZ54pZFKBzkv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6jK6RDzv1NhkHo3Ne7Ta1qkLA2aMmfwf8MZdW3ycZVKx",
      "vault": "7TpKDtiCjQzi7svv4wjJEhvGMdzNsJ3mUg4yG2Eep28C",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1b325f9a",
        "accruedReward": "1b325f9a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DML2ixMJW41fgNmVBhPCp2Y1KguStLi4FTeKfLmkaBB6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GKxvTpH1MK4CkUv9G6DA3yuKiLGyKFbBzBbrUcMJmi5t",
      "vault": "4nUKSteRTF8V4UKg1J46FfCNTeagLY2LkRxjyhaXvY6m",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62489e7d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1694d943",
        "accruedReward": "1694d943",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489e7d",
          "beginScheduleTs": "62489e7d",
          "lastUpdatedTs": "626197a5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "261b8c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489e7d",
          "beginScheduleTs": "626197a5",
          "lastUpdatedTs": "626197a5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Dm1Dtevsq4UjvaFW7LbXvAQGVihRNJu79yxVYmvJoma3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EvjAwbehCugmFmzGHpezRg2xgExPNNVBP2UJYDWhPQTw",
      "vault": "AGj3oHB8dF1qy4VsJXBjpTg1LjotGpdjfa5ung3Gr5Gw",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "077c0c5a",
        "accruedReward": "077c0c5a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HgD5RLV5WpUTdkdvfYe8h7YhVAZTVPDSQ4ZpSu6dBq5J",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "APxfaxtKugpVY1LLM7whb4aFvJ1n8NB51KsGWTGmjniG",
      "vault": "7CUXSbRTxfAExBR4fpEQTzyLXaTn4An5ZFFQqBjS6sq6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6259fcb7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1c366502",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259fcb7",
          "beginScheduleTs": "6259fcb7",
          "lastUpdatedTs": "6259fcb7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14bd52",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259fcb7",
          "beginScheduleTs": "6259fcb7",
          "lastUpdatedTs": "6259fcb7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7ekXZ9nHoLW1e2kGXccjwfbMfiszGbUY2KYhXzYE4G6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HPC4XqF1M5wfYwxSpfcZMMGFKRJ3EBduvCY3n6ADCnA9",
      "vault": "8p3r5XxJDE8BpeKcu7xr8MSEgyVbDcwh7wjU6mxsioyq",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9UJvLD77L4NdmyG4V45YpukEqV1Ys8ecMX8bcpaUvbRG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E8JAjAT9XJvWgLTXdgUynn8uPzeMeeGcb7iQqHTTnF9M",
      "vault": "AkwioxY5qU1LoxHXvxYzgZ63YF1avxm6dQV8XRumwNgB",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625981cf",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625981cf",
          "beginScheduleTs": "625981cf",
          "lastUpdatedTs": "625981cf",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "15383a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625981cf",
          "beginScheduleTs": "625981cf",
          "lastUpdatedTs": "625981cf",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GVugt3Qe6wAQsBgxbmsadbQmSXrfin5JpmTdY4q4SXqD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DRNfNcSJ7ikrTMnn6LKJQZoiPNdHNBjNvnLxpejbGMX9",
      "vault": "7yhmLi7t6tk595TpHDayDghVb7gGnFzNBvZnquPsd77y",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1f112633",
        "accruedReward": "1f112633",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2taAE1HxPVLjqHs4ey3shANbdwjUute1Ns6a3d8t5k5r",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7GGajduyEv3vFeFijposTqZi4ccqf54D8nhXeFa9dSoP",
      "vault": "7miGRuMeo4dNESwAyKRkofa4brHZVyDX61diMzNoxCCU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247ef6b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "138ea703",
        "accruedReward": "138ea703",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247ef6b",
          "beginScheduleTs": "6247ef6b",
          "lastUpdatedTs": "625d9060",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26ca9e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247ef6b",
          "beginScheduleTs": "625d9060",
          "lastUpdatedTs": "625d9060",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CSxAB1Cb6Gti9ZHTuGFJvernMuW3QXD8aCfqQE1s1jsD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8uvdBJddC5RkftwGfdQf5aV1GNEbuFx3dj1kyUmsNB7J",
      "vault": "5LPvF8jci7KtBFvyfXidmUiw2W7SHorgZLUbuUEZVxYQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626832b9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1c396938",
        "accruedReward": "1c39b6fa",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626832b9",
          "beginScheduleTs": "626832b9",
          "lastUpdatedTs": "6268330f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "068750",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626832b9",
          "beginScheduleTs": "6268330f",
          "lastUpdatedTs": "6268330f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4n9Gef4sQY4vmrVcPuPMrBZPtPF1C2HP5jkcM3GzmejG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8gRSwc6dZzqpi5xa1r4U4fbf7CrRqAVrJgKvuVyr4hnt",
      "vault": "ASmuueZck7WuAgVwRnj7GFXe6iq9yZSn7SdKZxr76V77",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6263fac6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263fac6",
          "beginScheduleTs": "6263fac6",
          "lastUpdatedTs": "6263fac6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0abf43",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263fac6",
          "beginScheduleTs": "6263fac6",
          "lastUpdatedTs": "6263fac6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8tcprYYJZ5SA6LsyCr2pcqHCsxxbH9sx9vMxrc7WLK3J",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "B2szJXdUjfTwDnkqRq9B7Z7nqG5ZMTtGACFq7Ua4JFb7",
      "vault": "H2K3ioKfwwf1f2iUJrZrm8EfiT4bBFWFs7L4bfVJnnCK",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2G5jmxJhUn1KCWUpmbr4eYgRs4sg1dEBsQsz9kVAnG5T",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E6z8X5PFr2WYQS9T76yGMRS1JTxe84iPN9FnzKKVsHQe",
      "vault": "8Q5dXBStiujxTjyYufkakbhMdrxp9TwWLFZMH5uEh4yW",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6248e553",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1c1ea89e",
        "accruedReward": "1c1f0de3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248e553",
          "beginScheduleTs": "6248e553",
          "lastUpdatedTs": "6258721d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25d4b6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248e553",
          "beginScheduleTs": "6258721d",
          "lastUpdatedTs": "6258721d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EjKdqh7DUz5AihbzmTALHQqeEsnGDZNLak3grxWSu7W1",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "wXpx4MC2ViUEctJ8mV2s81j78RMBsKS1QvkYQs99Pyb",
      "vault": "H3ucTYeaLk544qpPfzuKDXiY8uUFeQzsFdPAq9tTWXCp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62480c25",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "32325669",
        "accruedReward": "32325669",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62480c25",
          "beginScheduleTs": "62480c25",
          "lastUpdatedTs": "6263cdde",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26ade4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62480c25",
          "beginScheduleTs": "6263cdde",
          "lastUpdatedTs": "6263cdde",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ABJwHFCizqU9TTH6Fa6WzjzfULqJHt7wfgyTZms46KG8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CoTDYS8soaUu4QWRnFnTCNfxbruQRD65W44SN5DuCzpR",
      "vault": "6fdxhLH4QQPVVMUR5SUCdx3tCCyo2ZEAT9Yv669gmZhK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625a68f5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3d5234ec",
        "accruedReward": "3d5234ec",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a68f5",
          "beginScheduleTs": "625a68f5",
          "lastUpdatedTs": "626a4f0e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "145114",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a68f5",
          "beginScheduleTs": "626a4f0e",
          "lastUpdatedTs": "626a4f0e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CYSoND56EVV8MEGgNieirsdUjJojmKWwgt3GWevysdtp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HkVfpbrkV4dRgio9zBEmDvwMEDNhi1KWic7nSEVCRFu",
      "vault": "6g1jh56mucny4MN9eHribeu87VqkE5SggqNHY3uVhY79",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AXF9YUQfMkSKQsgFwynZ2W5hoNK4VCc3dqJFmCTENY3T",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DsGymMZZuj6CM1DcaFkTBX4WjPfVwtmsmza3UbfQqbhc",
      "vault": "47omJLUnQCKvjDsRHeyw1x7nDG3hviXNNGG34tLLiJwS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62476758",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3b53aff5",
        "accruedReward": "3b53aff5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476758",
          "beginScheduleTs": "62476758",
          "lastUpdatedTs": "62683587",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2752b1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476758",
          "beginScheduleTs": "62683587",
          "lastUpdatedTs": "62683587",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FARPxeE7FNHLUtiMzMmdwXiHMeZDAiiNoGHMzztrvGVd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5m4j9R4cjmrv9c57QhczSpvCzABvxvXfZLBAZfrzXyE8",
      "vault": "93oGZzpZguyvqcJHZ7Y2pZWF3N4q2PuaQxcuNdru98wY",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "217a01cc",
        "accruedReward": "217a380c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J3pk6qRxVREYnqKHRkfZtG6N54XnZcDBHQQStUGa2xDB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AAP8XjE6PmACA5nhA1BgxgyzJqyD1LQtjbbHAX6NiatZ",
      "vault": "F2xFYeHDqXBdYuLGRoZwddPtD2h1a9eQhvdp4CGsPDik",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476d2d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "16681b70",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476d2d",
          "beginScheduleTs": "62476d2d",
          "lastUpdatedTs": "626034da",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274cdc",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476d2d",
          "beginScheduleTs": "626034da",
          "lastUpdatedTs": "626034da",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9FothWFKqJvv1MN5oUhHV43VvacCFMBjvdhD2SEz4hmy",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E9N8VpmJddEf3YnkxtQSxFbLi7z8VXCPcp2poRtzPUnm",
      "vault": "6rJdQWN1EeUmRQUdw8Mu4z4SvFSo77Sg5rv9iG21agoj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62523934",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "10d57fb1",
        "accruedReward": "10d5bd2d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62523934",
          "beginScheduleTs": "62523934",
          "lastUpdatedTs": "6252396a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c80d5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62523934",
          "beginScheduleTs": "6252396a",
          "lastUpdatedTs": "6252396a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9YY9yGGZeeDVH41qzxoxYzP4ox5unG7LV8FuKYbmPCQk",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9uZKndcUuin9Kq3XgMifeSnaXyFWyQryxxZQ74qrR4x2",
      "vault": "GypNPVZy9ZXEbyGt5fecrVyGowy7ZdZQ4DvcDQJuSjA3",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3PGXfCbgJo2fmrS5YT9xBdRa5zG4sNwGZNSmUFJktfCC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AxhYPbeU2mtDAEYGghr6BPoCXnNHkaJ19PtavL1pw9o5",
      "vault": "4QSGFpFYrPRMnDQotniKUWE4RuFVUHTrsWXY3Ycf6NZL",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3103ee17",
        "accruedReward": "3104002c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5EV5AAHQcgcY1oBLPtQs63aQrhKvMjPuSmhn4xFYJBKu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7zPfwX7Vf6si254KrtVzv3c419v8Pzt5jnjnnhcCxC23",
      "vault": "4L7NWK2FF9MitV62zPJcWDoHEFH4JFahAh8tTKnPbk7M",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HXjsd1mDgAeG4emquPtjyk3FTZbLvRQV4Ky3yu1F1i5e",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DTFHRdHa75fjio2nFgxgQAKkRCWX5hGSXAZNByRHDiUX",
      "vault": "7XZdRodoFQT9btfc7J2HPzru7Fwbf39vP3juEWRREi8v",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6259f98b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "18b41aa1",
        "accruedReward": "206a76d2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259f98b",
          "beginScheduleTs": "6259f98b",
          "lastUpdatedTs": "6259f98b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14c07e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259f98b",
          "beginScheduleTs": "6259f98b",
          "lastUpdatedTs": "6259f98b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4vbwKNguvku1xkYdD3m8eEkcYjcyDwjRa8fHNuTLsCC1",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6SHRL5r8BBeJFVcg8Y5u2VRvEaYYweSnYtNLjb1N48qw",
      "vault": "J5oHnM64z7rsNfJKkEHFmy1pW5SyzDz7jLLz5ARn7QMu",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5aiacWK6fbRUoWsro7PWK8L3dUhoqA5QqB4jaQ3ATNaX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DA5CeJbH1U9b4CbzYb22scuqmbGgsU46rqeC5g5oEdG9",
      "vault": "31LZNq73AW1XwUMLbp6W2AdbHnHdGLPHrS1rPPrdCRgk",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "01c986e8",
        "accruedReward": "01c9b5ed",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9QptPYbhVphtX8z33phVXSeLd6gedMDys8sW7cirto4E",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9Ztb3mEnzLBqsrEUykYgzsCcaxznfjYRWSpCLswU4Kdw",
      "vault": "5i1xkLWpQUwX9XbJsUBbajJpJDM2PGym31xfvsYSz6An",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6252da09",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "08169c50",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252da09",
          "beginScheduleTs": "6252da09",
          "lastUpdatedTs": "6252da09",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1be000",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252da09",
          "beginScheduleTs": "6252da09",
          "lastUpdatedTs": "6252da09",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BqBMeNu8jWYRcsB3qA2z7eCvNzdVcfdeZqo4Df27KafY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4ubCPpUJooTKRvLCdXDoZsU185YYHHxq5nCuMuMaMWMc",
      "vault": "Es9zciXoaH5UBXDWSnGjmbrChGcVLTvcoBNVPeSXX2Dc",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247e381",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1676e9f7",
        "accruedReward": "1676e9f7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247e381",
          "beginScheduleTs": "6247e381",
          "lastUpdatedTs": "6260bb8e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26d688",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247e381",
          "beginScheduleTs": "6260bb8e",
          "lastUpdatedTs": "6260bb8e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AHp8c14DsrMLszsNEaMjo7Uz3gBBSBwgSiopCEre8YpN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4JvTGUMuYGxymSivsfxhs9dP2zVw6DgsubT9VCJyUfzx",
      "vault": "D7EGLESJxdbBJP8D1HAJqiaL8aS6G582ssycuFNK6Yfc",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0772cc30",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3TGExAWBC7XutLREYe4Mibnpv6e4mkaqh14EnMtUxNPY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HZmXbPDoEtjMHZwLCWg8j9Cx7Y7fAaSAS8YE2Wp5R5E3",
      "vault": "EHmzRhkaoZWDdk1FVNCskRXVk6scXwWtHwYFCzvCzQ7c",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "17704cbf",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6tYPEpZCahXzTzXTMvZ6jiN2BVmUnVXhLoPEjqMwdhQY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4cWqu5TBkF6uqmkcSsKxf1iXRNQ4H9NSSJc71j5tD85D",
      "vault": "Cwkk4XoaJXR1FbPU9RYz6MngtmK8yJJGW6RBhKDJ2WGv",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "624debf3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "380f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624debf3",
          "beginScheduleTs": "624debf3",
          "lastUpdatedTs": "624debf3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "20ce16",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624debf3",
          "beginScheduleTs": "624debf3",
          "lastUpdatedTs": "624debf3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7jKDEoe1acqsDHKkpS3KQXoezUiTir4n4wQUhr1iKrGd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AGSxHPeU29ntH25bG8HPXNT6wbuwoVi2vus72Vx2uvyx",
      "vault": "2uVZ9iaWtoPVMGoxAQVEJGV4eT87VHihWNWzRXA9GsS8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "07",
      "rarityPointsStaked": "07",
      "minStakingEndsTs": "6255f4a3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "c0b6c086",
        "accruedReward": "c0b6c086",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255f4a3",
          "beginScheduleTs": "6255f4a3",
          "lastUpdatedTs": "626814f3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "18c566",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255f4a3",
          "beginScheduleTs": "626814f3",
          "lastUpdatedTs": "626814f3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5xRHvUfV5798g6PtEBLe27odUmMr5hANZDHEQcsTV46p",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ELF8eieJAgKHJypyvoWW6PiRFrEHCwZd27K5f5UmNgjZ",
      "vault": "EwuGyMGfmWX98Wvp6eDo1tYDCSXvVYaGaRjqHMfdjnqZ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AffHt4NxX1tcTNUh126tzY9hJHhJn9kGVPSeAuTnoSrg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CemzpdSzdjFjFF1n8mF6nDe2eqD3xRyfDXqVrzSNVfaU",
      "vault": "GL92C4zJHM5WjUC1EyYdP8omyqJLHB9xsxfjqx7JHpi6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62644979",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62644979",
          "beginScheduleTs": "62644979",
          "lastUpdatedTs": "62644979",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a7090",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62644979",
          "beginScheduleTs": "62644979",
          "lastUpdatedTs": "62644979",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E2Ly6N8KpanfNDGFCHDLVqG8MQPUUmbTDDz9ru1kvVp3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AK7M6YduX7k8NUomfLKFTwvbH5MZfAHn8CghFZucDMu6",
      "vault": "J98k6e7iW7Esedu67aPG47xJ7BWd2DB6qwDzyKRriSuD",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "116e18e5",
        "accruedReward": "116e18e5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CLxwxJgJcjbXuBXtTswMn6ZzQtMVokS34TeR9tZqiyz3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6asMmzbUNCKP23xQNBuVwH1kmCN57Jip1pBRvToo19dK",
      "vault": "H8kZFARxcNMwncFb7S3GH7SmDQvpZgzqHR1CUqFUpWnJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62483962",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62483962",
          "beginScheduleTs": "62483962",
          "lastUpdatedTs": "62483962",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2680a7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62483962",
          "beginScheduleTs": "62483962",
          "lastUpdatedTs": "62483962",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8zVRm8NjM2BDcwFUqEEBnGW7VL1UuVvebgjMo27EfpfH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BXzvDUnewxNRj4fCMGXjeGbssCtaitTMNXtcghKHJs5G",
      "vault": "FgXsw9ad9nbNub9o9awoLF9qgyLSnmxcwvtTKEmXh8XP",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CkFm3fs98zzc6QEbXEQAzvRWuHDgqKSNZZnZDRa2hUeW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "997fhsmZCdMY4yTon98Y3Twd1EmzTw6s4XfVsB64wvw2",
      "vault": "CoUXPcbJFRfwNBsDRT1Ftpt94T4orT4NtGpKbf2hAUhC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625f87ca",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1de6c970",
        "accruedReward": "1de723db",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f87ca",
          "beginScheduleTs": "625f87ca",
          "lastUpdatedTs": "625f87ca",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f323f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f87ca",
          "beginScheduleTs": "625f87ca",
          "lastUpdatedTs": "625f87ca",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4t5T2UEQ9eegaR3fZDHFkSkEryzhxDK3L9vZ8hsXgTTJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A1PATYQrY5Hr7vZrd7aHcXNdvi9iNhHZngPpw6JNhGGL",
      "vault": "GxvEaCMZT6UrtGTdN14G8ep5fMpCsPrcSGDjNKoYyh4H",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625100bc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3e021072",
        "accruedReward": "3e024dee",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625100bc",
          "beginScheduleTs": "625100bc",
          "lastUpdatedTs": "626a3058",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1db94d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625100bc",
          "beginScheduleTs": "626a3058",
          "lastUpdatedTs": "626a3058",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HNShEskUyMcHS5QsnUt25NqN34KcEAZEf4zWAYFozTfa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8jXGJHGj8qSBejctBv7QNbSTsbqB2jtv2QAnGrjLeupb",
      "vault": "F4d3HCLuBDW5idQtRPkZmvs4jZ6ZxpnFvuCmerDXhkHr",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "04b12298",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "A5WRFH4Eu5s22t7Z1yPLikiCd6ZiuMaD9SD1YSeeLfkJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AvhUjFGxr4NmWGMyzr3WjawSrkTq4WpZWbRjTW8AyyGF",
      "vault": "4kpNJhDiN7h7yGqabi4Yqz5WeVW4qGwUWVZx2UyQV3cE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "07",
      "rarityPointsStaked": "07",
      "minStakingEndsTs": "626085a1",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2e0264bc",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626085a1",
          "beginScheduleTs": "626085a1",
          "lastUpdatedTs": "6267ca7b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e3468",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626085a1",
          "beginScheduleTs": "6267ca7b",
          "lastUpdatedTs": "6267ca7b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ByU2chkJQDoMX5vijE5rLPjEySdg7kFNGfAodqimuqDE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Fg6e3M3m87AhEHwwC4Rsmkzdw7z3RnVyZNNXYL28cp8n",
      "vault": "4n59GEUwy5DY51H3cZchV4sDDPTzFr1xEu8GBWC8ML55",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6260432b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2cf16534",
        "accruedReward": "2cf16534",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260432b",
          "beginScheduleTs": "6260432b",
          "lastUpdatedTs": "62604360",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e76de",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260432b",
          "beginScheduleTs": "62604360",
          "lastUpdatedTs": "62604360",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "45oUsqUykLSVtuNwZrARU9Guw5eg6PfiF2R9F5zAAUtz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GifJRCxJw9rCuuqtMkkZFsjAuXBvw5m2H9qn7JGzyQ9a",
      "vault": "DMUCnAnnnQ1KwFhFZkKqrZmNQzs2Z8vMS3HYTcr5AGCm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6259909c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1010b4d2",
        "accruedReward": "1010b4d2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259909c",
          "beginScheduleTs": "6259909c",
          "lastUpdatedTs": "625f3b43",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "15296d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259909c",
          "beginScheduleTs": "625f3b43",
          "lastUpdatedTs": "625f3b43",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4uqwbUhyJNdtfGyAuhYjgBirw8Xu7vRmWgJ39wgSkgE8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "86pA3UjDArLuziSsq6XAzCsFPdsKLdB3tKafoA72Q5JV",
      "vault": "2xhPq9AJJVo5tVzFEsuVRn3tUu9np17daaw79CfBP9rj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6262293b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2685eaf1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262293b",
          "beginScheduleTs": "6262293b",
          "lastUpdatedTs": "6262293b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0c90ce",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262293b",
          "beginScheduleTs": "6262293b",
          "lastUpdatedTs": "6262293b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2YG21uzuKjThAzmUuCqea6NEKtqcNAwq525gDdqiQ37i",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5avEm1Mb5fCVzw92HrbyHNDs29Um1SJUShDnjUhhX5uQ",
      "vault": "EkPf5WpYuCzy68F5QU4fDUCnqYiMbdpJVyeu7ubs4mUs",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62546d37",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62546d37",
          "beginScheduleTs": "62546d37",
          "lastUpdatedTs": "62546d37",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a4cd2",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62546d37",
          "beginScheduleTs": "62546d37",
          "lastUpdatedTs": "62546d37",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CQRBY4pGgoTEg92Mv5GUVW1rdnQAKsaHDuSUcY2FhzxT",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "76FyigyCosgH7RC4zBHqJtVn1jD9iAQ3WvxBxX7yNF8x",
      "vault": "5zh2wroUCjj9NkRGF8N1ztXeWLpUktzeNZSFfWDC3Qwv",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248f2cc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248f2cc",
          "beginScheduleTs": "6248f2cc",
          "lastUpdatedTs": "6248f2cc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25c73d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248f2cc",
          "beginScheduleTs": "6248f2cc",
          "lastUpdatedTs": "6248f2cc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3LnzqNViBpnrMT6CyLGJCqDAi9EiNLCNqeLX9R9aUpMn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6dHXjmTpyHtKFHBQ1cgtNTtzYAmdKA2KqRFGSpKZonFZ",
      "vault": "DangSfjezJwqyEQaw7JHEJeEZniZchB2cbCVfNceLvT4",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EDsWrzcBVEfanRP554f5MdPsuSYCWpwwLHR6ioDMQiFH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2rWaenfjwPvvYBe91W3t936pP8zKkd3ihk5kcbuhm7xX",
      "vault": "4wuU98RgqbELFFdEgweRXrhNr4R6vKjzeM3zD7o9eDaf",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6259b0d9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259b0d9",
          "beginScheduleTs": "6259b0d9",
          "lastUpdatedTs": "6259b0d9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "150930",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259b0d9",
          "beginScheduleTs": "6259b0d9",
          "lastUpdatedTs": "6259b0d9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4LzBuKxQxK1AAr11eLVru53Wdim3EuBvJ4bpdKozpu9U",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CKyQeZ1bBbdnJKrr56NdvfHWaudSVo5iEHcvQmRcryQ7",
      "vault": "BHCi6be7KHLSGfSuSBGVaCwia9STmPCoRFNMtM9WEgra",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62574794",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0686fe29",
        "accruedReward": "0686fe29",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62574794",
          "beginScheduleTs": "62574794",
          "lastUpdatedTs": "625ae392",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "177275",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62574794",
          "beginScheduleTs": "625ae392",
          "lastUpdatedTs": "625ae392",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4cs3HYyhnqcN1qhqHRrinqUS4kCLVkUxYWDbDCygTWUy",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8Q2NnBBkcE9bHd9fA1DQMzfCndou5PePq8HSyoWnE8S8",
      "vault": "8X1wyndxasTJHESrG9qZwCwh6yXXYbVJSdmhjS6Dtipe",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625e388d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2735089c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e388d",
          "beginScheduleTs": "625e388d",
          "lastUpdatedTs": "625e388d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "10817c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e388d",
          "beginScheduleTs": "625e388d",
          "lastUpdatedTs": "625e388d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GZgGoE3dAfoL4A9Rt18m9pRys7JyLJcKPK8y98Sw5diS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7Rp51HCQBf3TFivfqMk65pAKbtogwrUpEAtfN3f8Ciz2",
      "vault": "Bzzza8HVxGZPgNxRcjRCTaEnhamLGmnAPiofVShP3Vzf",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6259bdb6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3ea927ba",
        "accruedReward": "3ea927ba",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259bdb6",
          "beginScheduleTs": "6259bdb6",
          "lastUpdatedTs": "6259bdb6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14fc53",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259bdb6",
          "beginScheduleTs": "6259bdb6",
          "lastUpdatedTs": "6259bdb6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AmzcvGrXBD2LYP5a4CSitAfG9LCKMyK3dZHGoZ7gJnQE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GwBD4L9AZXrc6sAUmRsnxffkHHWQ2yebqrugpk3CYzUw",
      "vault": "FBouYRtxKi4kuvyyCL2EzDKpoD3zdDc7X96TjUEACxo8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625634c4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "255f2ed8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625634c4",
          "beginScheduleTs": "625634c4",
          "lastUpdatedTs": "625f2570",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "188545",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625634c4",
          "beginScheduleTs": "625f2570",
          "lastUpdatedTs": "625f2570",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AMQbdXwwMfuhbmrrMhTyifZFBJbtU6PwhqraFwnj8Kg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5DwukVk8TaQKxE5uWcRvRmUEdmNhv2mGArbcSsqXiP1h",
      "vault": "8EdNMsMLNfcqooGNqkNh7GFNrU17y2F74gn62JUtuLWm",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6QH3ys3eqRd57mMqqpFFFxa7FbrN2HVHQumAGigTtNKS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3y71uSKCKHMxVz46ewx6WqtLAbY8YPya5i4TEFYE18kq",
      "vault": "B83v19iTmEeWCDetoD3kDpZvJvB7197okF4rq5cTrLPD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "624768ae",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "31a63b71",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624768ae",
          "beginScheduleTs": "624768ae",
          "lastUpdatedTs": "6259b62e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27515b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624768ae",
          "beginScheduleTs": "6259b62e",
          "lastUpdatedTs": "6259b62e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "87idZMezd2v7ip2pzkBy8Q8K4LyqoFXmfbPvXqyqs1q3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A2uZAicwsZ1StXTPX7wPvHdeBQ5nFzZETP8SEt4gzHfK",
      "vault": "AxdjV5fcxAUGKd6GZQBhoRP3FqN2iKYRX11vuRMLJqZi",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625ed8c2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "27c9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ed8c2",
          "beginScheduleTs": "625ed8c2",
          "lastUpdatedTs": "625ed8c2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0fe147",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ed8c2",
          "beginScheduleTs": "625ed8c2",
          "lastUpdatedTs": "625ed8c2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Fn772QPeE5JvvehnvmetaxPYJ3HxharGT837XajJc6Jc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4TCS84amtBU4dgAKepfYPcG2fYJXBrEtQVUUF6orxzGQ",
      "vault": "54aGishsufSa5gD51VwqUqv1x6X8cbhKYjZpMb6XvLoz",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6oxTzTaG6zPPVXLdkn9RomY4dCj7UKgVCkYeSLXDxFFj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F5uEVAc6ruRyaweLgn895guNsMqveFLZpqHWP18ZKLzx",
      "vault": "DSqxW6QYgAdTVXd6YV5rHwYaCqALuCZ2i3KDdxEsY4GE",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "067df6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Fu6AcdY6HutfrsySWg69P3pJ8yX7Sh1pPHJf6x9VM9f3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "C6KjwwARhcCtzys1os71rM4gVFyFmL885tZZ9AEtSTQT",
      "vault": "3W7Ym6cSUcFmvdpcxbSNUDAbnris3zok9RABvKbeGa4X",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62589238",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "13288c96",
        "accruedReward": "1629c4cc",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62589238",
          "beginScheduleTs": "62589238",
          "lastUpdatedTs": "62589238",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1627d1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62589238",
          "beginScheduleTs": "62589238",
          "lastUpdatedTs": "62589238",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DBqM4UtmhBqhys11Thm3AXDXRJmMx8m19jNo3B6QNHgW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HPQC4MXZzeZTanH1CUEdjhK41LVLVRLT5wJggLnbSQWW",
      "vault": "G3iT9Mtz7LfrzRTBZsr5TpnCXogWyzTTimrwJAZYMtRf",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62569493",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3145df47",
        "accruedReward": "3145df47",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62569493",
          "beginScheduleTs": "62569493",
          "lastUpdatedTs": "626505ac",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "182576",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62569493",
          "beginScheduleTs": "626505ac",
          "lastUpdatedTs": "626505ac",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4UF7GRX98QrfKKnbe63QErdEKkqvKh3YSoBGAdJc73Wv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2nLZUDB2gnpZFKdR9ChNKu5TtAKW9CzNe2wGjT6KD78W",
      "vault": "BmNz858utP4uKGXiH8XB31MunjQ9JTsrhvzcKopX24SP",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "7255e49b",
        "accruedReward": "7255e49b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CrM3gTfC9kmwDTSRjFSFTx8gBqBmvNG6ck1pSmDVvKxJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5qxHR3WAFvbBm4VeBazXcaPd2htf9SU6MsP8zekF6ezo",
      "vault": "EAiXy9Vc4u5g9MFBfnQSZBBN1F7RY5LaAA9yRm91i3az",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DevTNBLi6tCfsPHsECbfHwD8EHVHMeJaY43fi399D5Sa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AfBn8ZLTS7P9qN2GH3iKbLxcf7E4zDbM59G6Ya32dgxc",
      "vault": "CF54C2yL8GTCuTiDhk6f4U5woyiYHYiLy3TXjhZDcToi",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "33bf1d8b",
        "accruedReward": "33bf1d8b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3gkwJQdnd6xu2kwtCs69wquauMqFu2ex4DS1Q8Tt1jKE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CWXVxxmhjuH56jBAH8CXakJ7dB6MoDu8V8sWph3XvmJt",
      "vault": "HYEo2z4BddeimRN1CojQogKeFGdidZN5wqEx7gv2kCnA",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6266244d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "364f6c6c",
        "accruedReward": "364f748f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266244d",
          "beginScheduleTs": "6266244d",
          "lastUpdatedTs": "6266244d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0895bc",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266244d",
          "beginScheduleTs": "6266244d",
          "lastUpdatedTs": "6266244d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9xc5YbuShQypFWdwSdHL8vCX6ZFFPUEbExzsVtRsiKfQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8thhMYuJzF8ktezVVLqPa4kGFNGVdq4UaLGP6zhXtUfR",
      "vault": "GeoiYLTpVVBsyeav69nM1JzDAK6YnmZ8kMrGjbW4LbRx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625580f6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "232b8def",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625580f6",
          "beginScheduleTs": "625580f6",
          "lastUpdatedTs": "625580f6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "193913",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625580f6",
          "beginScheduleTs": "625580f6",
          "lastUpdatedTs": "625580f6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5nSAcUrEfBRiYKNh3jDwB8wnGcmyKh4dw1Q2RGMPrxjL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5QbAF2Ljnoiex9i642YsyXods3hPg4eGBcqrZXQwTRLF",
      "vault": "BQNic64rKv8nZzkJq9WcbSywZDnZncedgbNu5o3vuWqJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6247b674",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3ad731a8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247b674",
          "beginScheduleTs": "6247b674",
          "lastUpdatedTs": "6257fb20",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "270395",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247b674",
          "beginScheduleTs": "6257fb20",
          "lastUpdatedTs": "6257fb20",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5gV6orkPzJZzxPo6pi7M8bQoR5h9ymBPixvEeGMJQqx2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Do2zvQG1KSyc4PvLEtmBXHdMVkR1K312EDfg6NhZmESf",
      "vault": "5Dq4wErdtU1rRxA2uUaZoWBXMFk1nzGJnTBH5L9KhdgE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625a0a62",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0daf75d9",
        "accruedReward": "0daf75d9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a0a62",
          "beginScheduleTs": "625a0a62",
          "lastUpdatedTs": "62692cee",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14afa7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a0a62",
          "beginScheduleTs": "62692cee",
          "lastUpdatedTs": "62692cee",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2wvQQLD38unp46LUQnV867aqGPQw9GPmkV68hkghFdCt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4jYgZMfME8uR6UdJ3RVqd211AxCpfJgrR2cwS1C8na3B",
      "vault": "AB4N5EnyQUi23yyns8gKN7pi3iibwSLUkU29ZzrEs6J2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625737ab",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "18ca1a24",
        "accruedReward": "19ce0637",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625737ab",
          "beginScheduleTs": "625737ab",
          "lastUpdatedTs": "625737ab",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17825e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625737ab",
          "beginScheduleTs": "625737ab",
          "lastUpdatedTs": "625737ab",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8sTZEcWGwPeovTaeDxLHtuNH8euXDDWW8MBpd8beF96q",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9iquobGJqhhiKDCWs8aML5zZWqceZdujSxqb1VboPCCL",
      "vault": "GYRmdYrgrNgQYLLe6GQoRf2r5wvRJGhY2WzWJrTu6CZL",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d4df8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "06896d26",
        "accruedReward": "06896d26",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d4df8",
          "beginScheduleTs": "625d4df8",
          "lastUpdatedTs": "626488a0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "116c11",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d4df8",
          "beginScheduleTs": "626488a0",
          "lastUpdatedTs": "626488a0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Y5BibWBjskEpYHqtEmi5vBL6zCpeRDCfkCGoHSRCwB2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FDNHVkUctyYu8vW7EZGEcdBQcbs4MHk6LR1fdk2NX4No",
      "vault": "CVJWcKHCXfWUVssrFcsSUBYMxeCKcSizNKXqxb3BkJCt",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9W9zHuwYp1muVP2yxafA8KMtfD3fpabq4UCRDgx3HJBU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7g3hHcbNw65vWYfwYueRou6Y7wgmRGxChoRZtfwgTP9U",
      "vault": "EVrnZazBQZXqmPD7yj5Kmb962C3MD8hqcSdWg7TeHuDG",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3qpfjfvJaLNxXabZwLGaoFkZPKdPG3rR1M7TFn8A8Qds",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GZkGjskfBzGL9srt2yNe9cbJQZB5J1iVNNWpzzdLqwiz",
      "vault": "38tEmiSuDFgRwuJYN9TTz7TWuspVsCY2MkRyttqykx7n",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "105b1e20",
        "accruedReward": "105b35a2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4NVy9EkQZshTCSw7FMagKafNEoQqWuEJTAdSohAuqzK6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "B9cMjZPLnJyMWoHNFnutfTqsygABJfve12yrf7WneHqz",
      "vault": "AsZodoXXiJHN5tTCDaSS3j8yvrjuPRixeggpf3ucHegV",
      "state": {
        "staked": {}
      },
      "gemsStaked": "07",
      "rarityPointsStaked": "07",
      "minStakingEndsTs": "62489955",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "bfffe3ea",
        "accruedReward": "bfffe3ea",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489955",
          "beginScheduleTs": "62489955",
          "lastUpdatedTs": "62665a02",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2620b4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489955",
          "beginScheduleTs": "62665a02",
          "lastUpdatedTs": "62665a02",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Htn7mBW2v1tXoamUirSusrbM9QbnDgz4fNC6NQmtc8yH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AdrbUHBQmiBkVkVzDGRHKn3ZcKpQNULaoMR3ANJT5oCk",
      "vault": "4WAgyHBQzzhx8Nwo6ehjZ16YHuFGdoRzn5dFjdACq26Q",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247a2fa",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "300abb79",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a2fa",
          "beginScheduleTs": "6247a2fa",
          "lastUpdatedTs": "626233af",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27170f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a2fa",
          "beginScheduleTs": "626233af",
          "lastUpdatedTs": "626233af",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GWpx7xoSkybc8zbujrMnJ7wV1ZsPdgYiZJh5Z65LjVbS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EMoHUC4PtTNdVgSCCr1BSTF3NCbakwUg5EgE8H3Cu84h",
      "vault": "8a4bw2eJPy9w5YxKPjtARmfrcVJgC8PR2Kqa86z7cj6L",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5Q2pQ4t8C69WikNCGi3dU7BmFGKr1xVKHpUXoExXY8tt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DuPaTyvtPNA6FMjX7pfpesDte5FSHPi6BGK7fbh7ZiBo",
      "vault": "9w5e4NVerEK7dD47Cj47mTLXaYWbc5Ugz7rUFExU9H92",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DZJWrD7D6VmHuH8tSmEfpp7eHFzvcmUZSaM9AvQhBuHq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DbDXzx3oCdCj2JSfJVKgNAkwpLyXBihatXxrCntGS2WM",
      "vault": "8Zp9Kak6jJo3uejAszFZnFxy59EM45M7DnBbSA7TTybc",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "114af3df",
        "accruedReward": "114b0e17",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DQscBu69Cv8vy1ejdX9LdjVRw2uMV24QY83ExPHg3cnk",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2Bp6Lh3rfV7jVpQN2ajxTTkYfey8vNe5j1Cu7WxB3Huj",
      "vault": "22tchhWKmUCHse1X2YKHDJfwDfTihXeUEcAvpQzJvp1X",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62697a8f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3c517788",
        "accruedReward": "3c517788",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62697a8f",
          "beginScheduleTs": "62697a8f",
          "lastUpdatedTs": "626ac1e9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "053f7a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62697a8f",
          "beginScheduleTs": "626ac1e9",
          "lastUpdatedTs": "626ac1e9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GusBuT7p79zM9tXTAoFrZSFiG5KqLfCMvQ1R9pSowwtj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D99ChTGL4Fcxv63C86swEfJzGtPhZpDxSD1RW9uWxiYG",
      "vault": "7hRDfVaAWGHf2Ez84wWbY2gYiHWdssC8qHLCNjam1cGP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6267615a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "37a36dff",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267615a",
          "beginScheduleTs": "6267615a",
          "lastUpdatedTs": "6267615a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0758af",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267615a",
          "beginScheduleTs": "6267615a",
          "lastUpdatedTs": "6267615a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HrmaWQgrKNCxh4i6HQyFA4aJRyCxmhccqSE9o2kTVhWr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HARdByLUze6KqDypQXbjDrRXGxiJm5jJPFca9pPpae8c",
      "vault": "DxofxK3UkByLd5MqgJv5tNcyGPLU81hVVN9Zu7Zww9Yx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6262f07d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "21ed6c39",
        "accruedReward": "21ed6c39",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262f07d",
          "beginScheduleTs": "6262f07d",
          "lastUpdatedTs": "6262f091",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0bc98c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262f07d",
          "beginScheduleTs": "6262f091",
          "lastUpdatedTs": "6262f091",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8AhGxBaRZebdJk7YR9VQapaNPQpk2cfvBVTe4dbpoAEp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8HxXyNE9eToTzBJPGGBFjfHo9fidD2CguESXGU1ByByh",
      "vault": "81W8JnNQA17Xiufod7Lw8bovAk6K84kYq1TNR2EZjYCw",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "62477ac9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "70e1db86",
        "accruedReward": "70e1db86",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477ac9",
          "beginScheduleTs": "62477ac9",
          "lastUpdatedTs": "6266b077",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "273f40",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477ac9",
          "beginScheduleTs": "6266b077",
          "lastUpdatedTs": "6266b077",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8gpE7bcuxvVL5Hw7auKQCEDPopA1fAMYGyq7cxZd5d9U",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "86wzmtEEZca6HFPf4xxcpMFSxkyaTSCHTrk8kVSCdjZ4",
      "vault": "6i8qspomEHGQ5cxGV3Ariqzy3kk4Syd8vjqVy1Y7FLmm",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "4dba8458",
        "accruedReward": "4dba8458",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9VtxSLWmGjJHTt1Ls56iocNSbu89tBTx9wtPD5LnPkzh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BSwhUUzjYGVCeoM9rsiTAEAe5JmqdGNzGSeiQGwLYTVU",
      "vault": "BxsWUu4risUCHkTWTiMHoEW3jpkrBp97GKpgkthLKd5P",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1f1bd661",
        "accruedReward": "1f1bd661",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BgJbybAoEvqTU9sQjtC9bjvT5MzdbAZmEoJssKhK69cm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AjaZFrrCAY6NjSfJaHq66uqBdf6Rw3SMPZ5WPD6r8YHn",
      "vault": "E9Qo2rbMUXJTZPNJN2dZV7ic1rXu1o3Y4Q4CapqdKStL",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247677b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "077f7df9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247677b",
          "beginScheduleTs": "6247677b",
          "lastUpdatedTs": "624fb249",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27528e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247677b",
          "beginScheduleTs": "624fb249",
          "lastUpdatedTs": "624fb249",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "328gDsv1z3zrUB6c1gc4SL5bAJsk7chxM5UkvWjwbhr7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "xPriHCJZvJaAn7B2rhFCenRtEKQPUpXeV6mLSHJD4SF",
      "vault": "HtSeFKpKDZfz7PMF4jjHCeSGTB5Dpy61vm3oY5hmPRGE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "05",
      "rarityPointsStaked": "05",
      "minStakingEndsTs": "624a8f0f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "92269099",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a8f0f",
          "beginScheduleTs": "624a8f0f",
          "lastUpdatedTs": "62671a7b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "242afa",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a8f0f",
          "beginScheduleTs": "62671a7b",
          "lastUpdatedTs": "62671a7b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8pGbRwQUFx4bvKgaMknWYeK3aKwZsPqM2LqmMcNhUWaf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8PCBMqHZGHZvpPMDB2DanP43x8Gx8QTES6d4nUdQnXHQ",
      "vault": "3qSCs24gsQKkwHVM8Qd81cwbF2tLkgP1UHncU286QcTf",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625a5864",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "13e1abe8",
        "accruedReward": "13e1abe8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a5864",
          "beginScheduleTs": "625a5864",
          "lastUpdatedTs": "625a5864",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1461a5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a5864",
          "beginScheduleTs": "625a5864",
          "lastUpdatedTs": "625a5864",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GHdxyk5719bVusXrHDA4iM1CiA3fnbvT5RDpFmZZUzym",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2jxCXuPd3o1uxgeawkojW3BSWqzYiX88HpA7fDcfGeuQ",
      "vault": "DZcwYdBH677u4ft18XZy7511w2CQrk5Z4TTnfKyXSgu",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9U11tegXN1rux5EBzQucdid4Ko9TccP9nyb627yxGsjM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "C3L4A9A9NMLKcAGvwiFMUxTGgFkBvELirB9ZUDLhsQDM",
      "vault": "66VcZD8JwbDhKLv3e8c67vbctsE9jGYEKAhC23n54sbi",
      "state": {
        "staked": {}
      },
      "gemsStaked": "05",
      "rarityPointsStaked": "05",
      "minStakingEndsTs": "6258336d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258336d",
          "beginScheduleTs": "6258336d",
          "lastUpdatedTs": "6258336d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "16869c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258336d",
          "beginScheduleTs": "6258336d",
          "lastUpdatedTs": "6258336d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8iyWFQ44KN9yQcLyXtSUkpEtWysKhKQq7488UNsetzsr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4c6FCwBqUj96LAfhpdw6ngADVnSEYEjpgn4Xfyu6DFoU",
      "vault": "H2zf4cyQT4WkSF9dsjXwXBp5rd7BmpCnZYoGm4sttcAs",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476d96",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1c2a8d1c",
        "accruedReward": "1c2a8d1c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476d96",
          "beginScheduleTs": "62476d96",
          "lastUpdatedTs": "626693e1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274c73",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476d96",
          "beginScheduleTs": "626693e1",
          "lastUpdatedTs": "626693e1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FJXadFjtEAb8KkUFTvw3JcXKnYXAQejX14SWSUK4ekXH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BbvoQf9k5JpoXoJPecpVJDgj33iAbPpGGj7zu9zTGwF8",
      "vault": "BCqkoqiEZS237ruzKVhUaCPY9oyUYUHDeDzeNaME6hoX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6269432c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269432c",
          "beginScheduleTs": "6269432c",
          "lastUpdatedTs": "6269432c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0576dd",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269432c",
          "beginScheduleTs": "6269432c",
          "lastUpdatedTs": "6269432c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GMZ2jsksoTG3nDXzGR53DuwKuUYqUgcbSrGK5NfMT8a1",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EgMm4frUZLv6NFi2TW3pJ8ETToDzjpV5LsoBmRJNiY8P",
      "vault": "AYMAtGTrU1sxGp2DTzyoquPSBZyK3woNfbBgvcDxE5yt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624a014f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a014f",
          "beginScheduleTs": "624a014f",
          "lastUpdatedTs": "624a014f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24b8ba",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a014f",
          "beginScheduleTs": "624a014f",
          "lastUpdatedTs": "624a014f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BdqJkuKeAcQLCUESmLmwK32uGY18XGUfuV431cgyaEpK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2XSHsVykf62npDDW81vPAerxsLuTkMSC5D23CjWFGJUg",
      "vault": "9w7Fc1YxhxVMtBVuYdDqsWr5Ryn1ELwm9Bv2awTP3euT",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "626722da",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626722da",
          "beginScheduleTs": "626722da",
          "lastUpdatedTs": "626722da",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "07972f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626722da",
          "beginScheduleTs": "626722da",
          "lastUpdatedTs": "626722da",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ELicAPrAqXTy6WwNfTgQvnHE5CMPCLjGwUTo3kgLFJqM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5YVmuVkmrjiAQmVfVc8opjnJQquh585rpxWnU56srWZy",
      "vault": "8HuYWjfL1dAuEzzhwtm8fta6vrRUHJKC4wzANmFzDUoD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6249c1db",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1698d624",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249c1db",
          "beginScheduleTs": "6249c1db",
          "lastUpdatedTs": "6262bf6c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24f82e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249c1db",
          "beginScheduleTs": "6262bf6c",
          "lastUpdatedTs": "6262bf6c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DVrJavckPt3USNhpXchVajPVQLyjGbYgyryYnexG5zTW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5CSkcL2wmiQ45XuLfnykU1Zu7E3A6RvbS11xg2y5ykcr",
      "vault": "B5vXbiam6gGcEShkj2TwckcAxaxvZGYdnVEv9F7KMFc4",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62477543",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1768d9b3",
        "accruedReward": "1768d9b3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477543",
          "beginScheduleTs": "62477543",
          "lastUpdatedTs": "626158e0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2744c6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477543",
          "beginScheduleTs": "626158e0",
          "lastUpdatedTs": "626158e0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5kNgqyVPF5Cc1ZWSQE5CMrUixmHyqnEpftLzmoqgx94x",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DecdrYx5SMzXnQ2ZSh8YtVLXAZRptWQQZhcbz2fmEhLV",
      "vault": "EmvXCDBnnMCXy58ZVHFoRYUa3uQoqAVwoB1MeyNyCH7k",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AAkhzPepNJxKvspV2GZ5uEB9C5CmJienYPSkAVP6zETu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7FixGEQyF86jUkRpdMuCpQfkmVQvhCTuofnuu5zHyiNe",
      "vault": "2NjKzFWfAgNo6vXwcPZBqmeusSqdV6serFW1syxARevh",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625d0e5d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "123e2840",
        "accruedReward": "26e98617",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d0e5d",
          "beginScheduleTs": "625d0e5d",
          "lastUpdatedTs": "625d0e5d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11abac",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d0e5d",
          "beginScheduleTs": "625d0e5d",
          "lastUpdatedTs": "625d0e5d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "A8cjnuDK2xP1RxJy8EvGQM7rh9bDqVVLUdFSQrEwJzRF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "334xTnuWkvBnH2hrT53j4iXKTjbiFsbgsE28C4gvKGVC",
      "vault": "AhadiSdEZMtAFm5yBAw5FAwFBFbzJ6zp9EEAGvs443pg",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62536103",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62536103",
          "beginScheduleTs": "62536103",
          "lastUpdatedTs": "62536103",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1b5906",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62536103",
          "beginScheduleTs": "62536103",
          "lastUpdatedTs": "62536103",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GoHPsAZaiiPkRgwvxtEizGne7eFQazUUsLzdiKCGjNcQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6dJiURkc1LtTB9PvKj8PNsokbeJUxPJaR7kpXNgjjHQA",
      "vault": "7PGPdNmaBhQrUR1J87JxzTAHosbRPEVcGD9VgFfrZa3v",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "03f653f5",
        "accruedReward": "03f653f5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BYSaG73cSUU1NZgKrpeyQbkHZtdpBGCFDm2f1tScEdMu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5ysG7mTnmkxeAKXpJdPZ9dA6qPbCXfBeqeBtaNJj8Y5T",
      "vault": "3hHHie49S7X16BSFdfKReWwUayTgNbiec4MKdyZfYK7N",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62482399",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "225c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62482399",
          "beginScheduleTs": "62482399",
          "lastUpdatedTs": "62482399",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "269670",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62482399",
          "beginScheduleTs": "62482399",
          "lastUpdatedTs": "62482399",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Had3tE2cmGUHSYyicocfRCx8Qi42HG8726tWyZuPt9h9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9xeZKV3qybhWiniDXG46V3CYG1RHdWoPNcm6PdTtaCz5",
      "vault": "DGCBikeBRfcsjNBNvmeeWjWPPgkPgqdVgFB6aJvys8kw",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247746f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "02f014e9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247746f",
          "beginScheduleTs": "6247746f",
          "lastUpdatedTs": "624ab42d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27459a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247746f",
          "beginScheduleTs": "624ab42d",
          "lastUpdatedTs": "624ab42d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EzmUHNLTKepE3aAf9kT9kUPVsM6ySGECMsuYc75zVUuA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HGZ9wnUWsx4bdt34Lm2WxdZ6YiZhC2hijkUBBRpRWLpa",
      "vault": "9WW8biC4pupUAjPUBGhRqUqTPHMQuGBjWqvaykqvqgNj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6262f623",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "27917485",
        "accruedReward": "2791ad7c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262f623",
          "beginScheduleTs": "6262f623",
          "lastUpdatedTs": "626aac5c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0bc3e6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262f623",
          "beginScheduleTs": "626aac5c",
          "lastUpdatedTs": "626aac5c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9wuYNTRSFczkG9dZQ6LhXeZUVYT6JbmoxkYGekXM1kFX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9qtF2v7SgjkSaKaqwgh6xatsDyMZsmfkyV7nd7mTDbqw",
      "vault": "SnqcRAZ6rToKBu8QuFeUU2j4woDvW5YgeQEaphNnoEJ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "TThcGnZitPQ1RiGx251KePrNSnM97jjcuBVU4RcdDSp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CGWbG9R7yF4Hw2PZPkVftBGd9pWabPCZztczeZb2Qh2z",
      "vault": "B6mia7g2EfU8pajwNVckd1Es8QD14k4dLuaa35QvS6CE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6266132f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "01a9fb49",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266132f",
          "beginScheduleTs": "6266132f",
          "lastUpdatedTs": "6266132f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08a6da",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266132f",
          "beginScheduleTs": "6266132f",
          "lastUpdatedTs": "6266132f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "93Wj9AcoQLmJB2KjGGeZ8s9yVsRLdhT3hBTY5ib855Qc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "H5DGVa8qFz4YG2qLNDUyEjiPQmKndW7vK6zu7CVRtKfQ",
      "vault": "F8xii6n4AkRVVNtN9pqzyptkWmkvrhsXiCZGG4qLU65A",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6252b22d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "056c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252b22d",
          "beginScheduleTs": "6252b22d",
          "lastUpdatedTs": "6252b22d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c07dc",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252b22d",
          "beginScheduleTs": "6252b22d",
          "lastUpdatedTs": "6252b22d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9wVYQhqdLKCENktQd8aUemd68zYwZsi3x4M5Vwcquknd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DNX1STfuhQKWiCtogi6ndCfNT6MB3LAYT2WfyRci9TET",
      "vault": "3YU7ZTbsfiNjh9XytfpA5uw8tPbvPd2D6xPUNyYsQCQp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624a4410",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0e5c9da6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a4410",
          "beginScheduleTs": "624a4410",
          "lastUpdatedTs": "625a261b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2475f9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a4410",
          "beginScheduleTs": "625a261b",
          "lastUpdatedTs": "625a261b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Cxipkz3FzJnVpDmznhPVnjfw3c9jd4JTyZjpQP11GNAv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2PhC9Dfy28dvrMdCezjafR2GZFq83f1k31EDeJqAqEkg",
      "vault": "2wuyvTeRsqwnig9F8LjQAF9PVptH993CTq2Rp89BuMwC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62638e68",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62638e68",
          "beginScheduleTs": "62638e68",
          "lastUpdatedTs": "62638e68",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0b2ba1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62638e68",
          "beginScheduleTs": "62638e68",
          "lastUpdatedTs": "62638e68",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4gEYrD21CRPak7vEzrbZhQuZC2agHQuW6oKSz6Xw6vUg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6MWau4CBQ86CHZV3YYMWeRtm4Z85RDoXHLiw5eNqApzZ",
      "vault": "5fTVtQWGHxULwEPP1Kq4BqH6JHpJ3baKjSBnNcJCgEcA",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "11f20ebb",
        "accruedReward": "11f20ebb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CZEvVJgx6KtTU1R6HqudpYqWAXCGiGm2Mpn4dpj6T32w",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6u2kyi29GKkXTvaM8YHeZxdQFv8RYWigi7vE7JDqc2Gg",
      "vault": "APDa9RoemNNEcbD7Ky7FTDzBArPX3wPCQgCjUbvULVAP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62589ac8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0ee65558",
        "accruedReward": "0ee65d7b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62589ac8",
          "beginScheduleTs": "62589ac8",
          "lastUpdatedTs": "6266ffb8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "161f41",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62589ac8",
          "beginScheduleTs": "6266ffb8",
          "lastUpdatedTs": "6266ffb8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2N7ms77bjQzDRKrKdt4XdnRhwR5dQqSpjGGYocMEiqzK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FCLJb9d8SrzWSLoZXiJiP3P6RjMEWxq1W3nG287DqRM4",
      "vault": "4YzGaEtvQFFGuCy77Uk6KZiQ9fzrCx9DEDTkLXPES8gS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625f60da",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "49924dfe",
        "accruedReward": "49924dfe",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f60da",
          "beginScheduleTs": "625f60da",
          "lastUpdatedTs": "6265fdda",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f592f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f60da",
          "beginScheduleTs": "6265fdda",
          "lastUpdatedTs": "6265fdda",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "A3wqtmW4JqkrGgiTHunRTRkCdxr7EC71uaYGJDqAcgEE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6SJj7nVqbTJckCCfBXBz8eju3ehahqodUYpmRHBHBmqa",
      "vault": "Da4gQo4z4dPESEZSALbDapXrr4Sa5NpLEcLBo76aJZxH",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ZC8NoGPwp8PBdpakEC7xfbnKrMrEVXikV6tCWBjcdrt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7C2VBt9GXTyWyKLrrg8w4ebFHuNMNgPN5MQHD13bLMbb",
      "vault": "4yLs6HEHQfpuLLBJF5pb7w2wXiX86AazJ1THbCgJ5iAf",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "625a03db",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "6bfbcfa9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a03db",
          "beginScheduleTs": "625a03db",
          "lastUpdatedTs": "6265d928",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14b62e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a03db",
          "beginScheduleTs": "6265d928",
          "lastUpdatedTs": "6265d928",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "D4yYYEsPG3WA1u6Y1ezjbBjCSnX3bjcVWDs5L7inBi4H",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7EdMJnK3QYvHigv5evPgDvuVTnhVxze4kJVktyFEDJEe",
      "vault": "BxmKDxZ34vVuoFqHqF2h1yCSoRpWRqG9tirfy9GBRa3B",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "625e1d52",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3cdb2640",
        "accruedReward": "3cdb2640",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e1d52",
          "beginScheduleTs": "625e1d52",
          "lastUpdatedTs": "625e1d78",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "109cb7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e1d52",
          "beginScheduleTs": "625e1d78",
          "lastUpdatedTs": "625e1d78",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "bjmpGN8bszXScAKTzYNfA46Ra5t3SvxEHLtCiXrcS1P",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BKcn36qgRkpg55AUNddbG18gXTiLe9Ciy7EhnPxv4Nz2",
      "vault": "AMCsXyrDAQRPhpq5f3nMRHGePRtBDa4nfBpfcVrpAE6A",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626a4f57",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a4f57",
          "beginScheduleTs": "626a4f57",
          "lastUpdatedTs": "626a4f57",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "046ab2",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a4f57",
          "beginScheduleTs": "626a4f57",
          "lastUpdatedTs": "626a4f57",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BaGLqYV4DAEkPcLkZDayonCcZjEYytqFKu3kLF29RbY2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BRAutp569rq73SjZxyELhYVkYqh4K63sdzfqU28akawg",
      "vault": "CH3SwecCgLwrfT9dQ7Gaq8S6whmXRsWoi6mSj8KXFumC",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "01f60002",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FiDsHAgm4cQZDNLLmgasajaxNXUv3PuDDX3S4t1tVzNo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FJqB9npggS4DHpLWi4YCsSa9qj1vH7dWmz64jJezhkBF",
      "vault": "5aqQkXVKs3jYYswoniYfxkZHYQ5eiSxA9cs1KDcesHMD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62647188",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "284fbdd4",
        "accruedReward": "284fce1a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62647188",
          "beginScheduleTs": "62647188",
          "lastUpdatedTs": "62647188",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a4881",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62647188",
          "beginScheduleTs": "62647188",
          "lastUpdatedTs": "62647188",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8sdujSkY3j36CydVdHLZJZwV2nZF1KyDKz8tjo2Jck5r",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FgBDPuCn2vA9Gim6rLiL3bR5HP5xNgSEQ8JPK3MGXNMG",
      "vault": "Ebum3v7DFo1U3vh1qYTiWzBFBYhrVEfGQhFBjgvjkHRW",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "061490e7",
        "accruedReward": "061490e7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2xEMm51TPCqv5zMnd9gtJS5PZmZLka9yA8RjAF8svXDj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DTtxvH5w27NoXBou8XDqCfKChUd63QnuAWMELrNmUwjE",
      "vault": "DBKhpYzM5GSPJiZJr7imCxfH4r2QeZdmBMBWRo7t5vGJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62572276",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "36dae926",
        "accruedReward": "36dae926",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62572276",
          "beginScheduleTs": "62572276",
          "lastUpdatedTs": "626779ec",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "179793",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62572276",
          "beginScheduleTs": "626779ec",
          "lastUpdatedTs": "626779ec",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "B5iiojJpzVa8hpBd8SweMzarm1QN6YBYS9wpC9GmTrSj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5HrE7CyQvs6wJr5jJ3TVZiREmp6iwXKbedUtdGAJK9o4",
      "vault": "5MyFQxGxEiLu26ku69MtX5UPxQmMq28Y6HxK87J7eFE1",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HbGnq2fb9WuNQazBs695je5vkR8K9Lx8MN6QnZ5r9tiy",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "As4TUF6RBUv5iACZQpdwzSyxVmzzNFSnfy2D5hurZ8LJ",
      "vault": "WE1whyLurP75nTQWFsAJnosp8JuN9cNgLRLJqMfs5tN",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62573020",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0b002c08",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62573020",
          "beginScheduleTs": "62573020",
          "lastUpdatedTs": "625d455b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1789e9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62573020",
          "beginScheduleTs": "625d455b",
          "lastUpdatedTs": "625d455b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "K3WKb1AYtDfnTZFanmY3G54pAKxbpBWc2KifZjpjdcP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2432qh9KMJu86uY1SVHz7tDGJSaZWHtuMJgKChK4KoUb",
      "vault": "B1dCFcH1weYc8nLZFTGWNdECzkzERCfbcvpdF5FzX4Yb",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0e98afc7",
        "accruedReward": "113561c1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GPZFLQ4nGxBj1s8H9VsZ6nGGfYpUgPnWeREPBHfAsfoX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HkG8tK2VLshDVrpZghxzkQUDCGPAJUcsLPKNDoeM7NP8",
      "vault": "BRsLmjRXGtGrRMBMysAMK7U3buhzTUGzeT4Z3V4Ema4d",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476bf9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "112e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476bf9",
          "beginScheduleTs": "62476bf9",
          "lastUpdatedTs": "62476c0c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274e10",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476bf9",
          "beginScheduleTs": "62476c0c",
          "lastUpdatedTs": "62476c0c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "56khctonVLkPr6fJUJDLKaJYep4EADJMaSS5WJq5AXVa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DRbQX4Bk53deYUB4BHqtcPrer81rhdiSPi4PM9XMK3F4",
      "vault": "Gs6X75YuemM12gysVeD8pgCv9teFUso1VZPpggsoJqfj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625878ad",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "06323189",
        "accruedReward": "06329984",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625878ad",
          "beginScheduleTs": "625878ad",
          "lastUpdatedTs": "625878c1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "16415c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625878ad",
          "beginScheduleTs": "625878c1",
          "lastUpdatedTs": "625878c1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9HrJ9si7x6qhexK5Q8iZvNuyscoNZuvU5M4VG8b7hkYr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8paQ1LPtyAd7WnsChpo94pF25tybHKA2zMnkoLmCZqbE",
      "vault": "qgt9n4rVkWT67eFaM6DCcW2yXyf9LuG94HDr5L4R939",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625ccda8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1aba4b95",
        "accruedReward": "1f12e87f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ccda8",
          "beginScheduleTs": "625ccda8",
          "lastUpdatedTs": "626ada4e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11ec61",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ccda8",
          "beginScheduleTs": "626ada4e",
          "lastUpdatedTs": "626ada4e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FKP9XzVZUZqpJtFpPTDQjLrkJXJPA7jFenJNMUnWeoBc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6r8KhwJFXEDNC99r8a4HExJ1WM6X1ZoNj2wdG8FYtfiX",
      "vault": "AYjVNzWjoQwjY7XwHqZtNKxp6nM7o3nb2Q4DP8sXLTMw",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624851b3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624851b3",
          "beginScheduleTs": "624851b3",
          "lastUpdatedTs": "624851b3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "266856",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624851b3",
          "beginScheduleTs": "624851b3",
          "lastUpdatedTs": "624851b3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FJ6bXLDFR1yH13f76jU7SNScCdenuJAxGbmXWh957q9D",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "59bD3jS8FnzQssrgdUgzX3WW5WG2fyF7Nfkp8hipQCf",
      "vault": "8suEGHWF3cibSHHZe8xQY1SVC5hiGjFYxHDDtvLL1WNc",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1534dbb0",
        "accruedReward": "1534dbb0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "B3eQtQnMxvTZBo2yWrFBZjxVxgj5SGD4XFs4hZN6tXpg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "79j2yWfDHnAU3Aq4yfoTcE9KCHCDo2m54aL4te67683Q",
      "vault": "6Ju5Ke2gSYnRtXyXG85fkQeJYp8Z3iTwrL4uPGF5BKFp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624c79c6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0a9514d6",
        "accruedReward": "0a9514d6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c79c6",
          "beginScheduleTs": "624c79c6",
          "lastUpdatedTs": "62582dcd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "224043",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c79c6",
          "beginScheduleTs": "62582dcd",
          "lastUpdatedTs": "62582dcd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "H8wGyHMF44rgoT9Le236RWCKFtA1Kudfa86DufzFnvYm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4phZtPPcVKbsvGEHrqDhXoW2v9vzzrQrMTtNXoWAiXwM",
      "vault": "HEWJ7ERJpbHBNT631GxJT1VgW9QX3x34RPcgZsmAXSG8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476add",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0b88645a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476add",
          "beginScheduleTs": "62476add",
          "lastUpdatedTs": "62542bf9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274f2c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476add",
          "beginScheduleTs": "62542bf9",
          "lastUpdatedTs": "62542bf9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3ZBawTrP45ubPPvW4AxzYsL4xjriJcVdo4uNzFkxTBzd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2LCUC3t1FGmDxshiv2hqz8ZSYoovc7YDF3Qsa8koW9i2",
      "vault": "8qv7sEQDL4so9VRa47VsbQ4SMKBybr8xdMZ7NJTXW7Ys",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247a185",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a185",
          "beginScheduleTs": "6247a185",
          "lastUpdatedTs": "6247a185",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "271884",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a185",
          "beginScheduleTs": "6247a185",
          "lastUpdatedTs": "6247a185",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DL4rDzYCnQwq34TYvfGZiQnXnPqJt8Dgob2YE8VtQXZZ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4BMD879eyMKq7Sm1voSHJFACqQsPEATGTWXwCJLYjH7n",
      "vault": "8TT1dWS92cvvy2iFJytoMhbwDapdw1yFE9rNcTya3Txo",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GQFFFE2FsosXu1XBLzfra4zEUFw3jFyKKM45XynLAY75",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CLp7Aj7rbjkjUYfVAYg6q8Wt7en54HteKqHArprXqJ92",
      "vault": "E1M6TboCUPXcFmc65tR29ruQSGDqmkZjnSvSpgZbACYn",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625e8712",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "044d40ec",
        "accruedReward": "044d40ec",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e8712",
          "beginScheduleTs": "625e8712",
          "lastUpdatedTs": "625e8734",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1032f7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e8712",
          "beginScheduleTs": "625e8734",
          "lastUpdatedTs": "625e8734",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8Rp6ChZSa8z48sFoe2ysYCbv4hfgFNf3DZ3Z7aUB1Pis",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8sTnGC2sSiXGY5Y98fSG3jZ7kTBY5QJ3thNY5Tw3zA8y",
      "vault": "A51BNWCK6dxqKu28wv47wgRTiy6UoNKH6RMk4p5djQRA",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6266b882",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1260e8ec",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266b882",
          "beginScheduleTs": "6266b882",
          "lastUpdatedTs": "6266b882",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "080187",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266b882",
          "beginScheduleTs": "6266b882",
          "lastUpdatedTs": "6266b882",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ELW7qbujuvaDr5VhQHvRWaDfTmxmKx57QBk4SUW5NmmX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HQbASCyGffWCg8sQCjB8HgC3ATnEbiv978MvqD2gjC2E",
      "vault": "ASUvehZD8dyUEAS2SFmdZCiypRzEHuVnMCeX4ohYJTBX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247c1e0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c1e0",
          "beginScheduleTs": "6247c1e0",
          "lastUpdatedTs": "6247c1e0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26f829",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c1e0",
          "beginScheduleTs": "6247c1e0",
          "lastUpdatedTs": "6247c1e0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2i6RGcTWdoN5QnV5bBc1ZSrpCnBWFXiyBgiB8aqSLwfD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8BzhpYzGygsZZgAaCxoAzWHxz5SXDHN5NgWkn41mJhXT",
      "vault": "CXDBzs3aiJskxW3pJBhR2NdwK9jjXMyZ2vfeyVWpcACm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62586db9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0f5f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62586db9",
          "beginScheduleTs": "62586db9",
          "lastUpdatedTs": "62586dca",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "164c50",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62586db9",
          "beginScheduleTs": "62586dca",
          "lastUpdatedTs": "62586dca",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BjgXh6XuEKsNFVNqaPC7dx4butfPvNFSshyGiu3zz1Rx",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8mG4CpSs3M2XbENYeLZ6xQhG6Y58wAPfkvzTXoQC51T9",
      "vault": "7XtBNv194gnhJHaPTVJ7mqoRpQ78Nj258hXF7H73qLCq",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9YPL1HWXKKz365kjTwFECYY4Qec91rnGXTxoY3bwv7sq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "C61zCMHw3bojzZZEFShFj3AF8x9SJvLzS7i6Q3ME9SVH",
      "vault": "Gx8JZCJDyw41scd8a6YMdLLw7apw8TNRb6FphVgyGqiU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62696621",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "cb3c2c",
        "accruedReward": "cb3c2c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62696621",
          "beginScheduleTs": "62696621",
          "lastUpdatedTs": "62696621",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0553e8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62696621",
          "beginScheduleTs": "62696621",
          "lastUpdatedTs": "62696621",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Gq4Cr6jemyKqXUumkR9E3ChVMhUDfoutPf76kAEBi8Q1",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AVQMKjT6wLbh6UM2nQ5NdDcTsDsL3VSKJ9KdnYK2ADZW",
      "vault": "H9tDNLzeEociNG3TRk7MuPZHy4g9153UnX2rj3DYWndS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248e3d8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "19693d12",
        "accruedReward": "19693d12",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248e3d8",
          "beginScheduleTs": "6248e3d8",
          "lastUpdatedTs": "6264fe1e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25d631",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248e3d8",
          "beginScheduleTs": "6264fe1e",
          "lastUpdatedTs": "6264fe1e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3Lg2k9wF5Y1wow4bMhsjkXjhDVjufbcury2trckMqAZd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Gr7mco5NaVQLoKv43B58fnrUGjyUMfV5BFPJ2WoM2tkL",
      "vault": "4RSjxjdXxsgmyc2SnwXVY4CRiPvbFfswZ9SwvVTiLNhL",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62531044",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62531044",
          "beginScheduleTs": "62531044",
          "lastUpdatedTs": "62531044",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1ba9c5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62531044",
          "beginScheduleTs": "62531044",
          "lastUpdatedTs": "62531044",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EaKb6hgSAqgoxFn9V6icaiHTuFqjkSSwSNSx8XZtzrTK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3R9ndNL239W65ZHHMmPTMwQr7LQaxN2HGocYtc7ck4LX",
      "vault": "HtaErCmjmc1XvUaFymXL6rBn7pqB2Up6qwVcPjFiLSDD",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Hit77hhqJXLK6f1akFjKjpG2nF7eZ3pDEukLCcxv1aFM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "G8r86HnH8S4HntUXrwdtK9rUpz9CTNKkxZziLMFQEHju",
      "vault": "8yLssgyyGmWnJgTmpa2Hf5zNJAGx54jwBqtMWg3gKbkB",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247b496",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "14506840",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247b496",
          "beginScheduleTs": "6247b496",
          "lastUpdatedTs": "6252f034",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "270573",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247b496",
          "beginScheduleTs": "6252f034",
          "lastUpdatedTs": "6252f034",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BxDLgtagCaRpCDNYnNkRCVDJwTzFdodvU84Yx9Pyqh1f",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2FVbDd4fURCcumognxx99ZKPm8SGQSADohSBEV3YQ24w",
      "vault": "5tycaRjHNjZd6jLWTfQdXSiNtzHqpeycuWggmtzgpxJ9",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "17f729",
        "accruedReward": "17f729",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "JA8vjvAUEpGEH7Qr7DNWqSDawSEhvLtgU7S5EDm4w5xN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4cWEn6tAw713BYVBrAm1RDfRkLyFt8DF5F1M8y2AhbNi",
      "vault": "9duiYRecstEr7pu2nshMiwoPF1eD3LWedvAPg4DDGZis",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247951a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "193dcb1c",
        "accruedReward": "193e3149",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247951a",
          "beginScheduleTs": "6247951a",
          "lastUpdatedTs": "62637fc5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2724ef",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247951a",
          "beginScheduleTs": "62637fc5",
          "lastUpdatedTs": "62637fc5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2TL1yHGAKqBAWukWrYXwyJuNtuCPoz5dpCrD326Xs9v1",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "SMBEFhhLKXVp3gGaws6WAs1K1aMMsgiYfX2hkf4V2rX",
      "vault": "GxMbiCjc9DWrKXucbBQdPHGxUJ2RK91LjYRbh31MnvPo",
      "state": {
        "staked": {}
      },
      "gemsStaked": "08",
      "rarityPointsStaked": "08",
      "minStakingEndsTs": "625964e4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "010b54e077",
        "accruedReward": "010b54e077",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625964e4",
          "beginScheduleTs": "625964e4",
          "lastUpdatedTs": "626839a9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "155525",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625964e4",
          "beginScheduleTs": "626839a9",
          "lastUpdatedTs": "626839a9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DepVtYzc4f5FXu1SycoaguYtcDjJJJbhyxxaKmGXK4W5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "H4yewpNYHcfTMBqcy33N9XkVk54vK96MJ6gDSX4zaZiu",
      "vault": "7cYBzSzM7xfVAnzhVyq6dQTNYz69m9YtzPtsAG2dGpEH",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4KHiayCQFhx9U4SfYsBbieFWSLoBKNeqCzWqoihLJSn7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "85sY6KPLewWjL5WoEfAUDYwcoLrjGDMGY78ULmG7cJPV",
      "vault": "FYXST3XQQ4du8wZ4Jm8GKD3mvGU9ynoQxT5gzv6exDxR",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "019b53a8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HyXBQtFS2ESUrEYDuaXpWwgJoerRuwCw2WiqWzA7Zdzi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7PSsp6wH6ALYY31zFoDyiKVkW1JuQ1pQmEVk86ChvNA7",
      "vault": "A4Xr6UrzL4rwfo5dPxozqks1YxpQd67DFbRYH4nueMnC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625981b9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1c0f479f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625981b9",
          "beginScheduleTs": "625981b9",
          "lastUpdatedTs": "625981b9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "153850",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625981b9",
          "beginScheduleTs": "625981b9",
          "lastUpdatedTs": "625981b9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "JLR2aB2TB9CZ1WkAvAQZGUZxJnoHtEQj3vqLnxwkEtr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3dPYe2fxnHXjRvS7ChMr2eYDnqjFnxgE3B8izPgre528",
      "vault": "BVSeFPqjqNREhWs29Tuf1VbkisMWs5e7jeXqSChGFuf2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6269104c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0436023a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269104c",
          "beginScheduleTs": "6269104c",
          "lastUpdatedTs": "6269106f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "05a9bd",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269104c",
          "beginScheduleTs": "6269106f",
          "lastUpdatedTs": "6269106f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "C7hwEcVrJy75Wr8ZAViqyrBdwcfmHwkFt5ypYYu9twzP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2aq8gSNt4RNKyM7Ht2SCV16ZQJACmPvLY7aPjaTaXgBG",
      "vault": "3kzjPNeNyttWHeDwN1LzzN7gRZfcdnou23cmdW8THQXX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "625a0dbf",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "4e671e2f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a0dbf",
          "beginScheduleTs": "625a0dbf",
          "lastUpdatedTs": "6263cfd2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14ac4a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a0dbf",
          "beginScheduleTs": "6263cfd2",
          "lastUpdatedTs": "6263cfd2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8iP7umH1FkDZcYCDs6X7yckqXJ9M6y3GKnfsPspmZMGr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "H1korvVo3eoyz1KRoeesxRTFYSKoonzXLYdj7Bhi6dZH",
      "vault": "BQ7ASJ8s8bx6ibUpYtfYwZjF717wfpSv8ACUWu7cGW5m",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8j5wtzMxgHp7WoYPXv5fqswkykAVgz2wVaqCrdRu3rJ7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6s46xPoopq38TTqKj2r78hGfeoDZjWCKZ5noL9TKgDar",
      "vault": "2xJL2e5en3je3gqY5SBXCBrNiESa2TkXTSQwFH8kiUXj",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "04ca7075",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3usAnPTNjRyNivf5bpm8UpbTBksB1gbuhgYJMJgtTFUj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ENmMbVXwb5AoT49mJZLBJ4yyzC6gyAuhGeuZpYSKNcY6",
      "vault": "8LvnpTCvXxsmXCG6ULHwBfp15RgApB2jFAUXwabJRt2w",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6254ffc9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254ffc9",
          "beginScheduleTs": "6254ffc9",
          "lastUpdatedTs": "6254ffc9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "19ba40",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254ffc9",
          "beginScheduleTs": "6254ffc9",
          "lastUpdatedTs": "6254ffc9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GgW56e1UGomfXCzf8UdWuvQA8RWk5M6oZJEP4yMyNRKW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2cDvjrJqMwrZQy3ZBUBYJyVCd3sLERaujEfyKz3735E7",
      "vault": "DzZJ8HeHyuQU9dRLFSW5wreGkAZBTSQsRNjKMEx4dgqj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62555d3a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "22358542",
        "accruedReward": "22358542",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62555d3a",
          "beginScheduleTs": "62555d3a",
          "lastUpdatedTs": "62650bca",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "195ccf",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62555d3a",
          "beginScheduleTs": "62650bca",
          "lastUpdatedTs": "62650bca",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EHEmWKdRQzfi3jP7NRhzQAAEzMjZz67vpy5MG3MLQXYp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4FXA1ZqEj9jDPk62QKj72p8Yyz7mKAwCSu68vTe5dUV3",
      "vault": "5x4nMxv91cCmqsV98eghE8QZz5AGPoaa25uqFVXMbhup",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "c3c1a72d",
        "accruedReward": "c3c2aab0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "JAuJcojW7YpEFN3qkJABREv2u1ewAGozA9t6PwuCPGr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8f97tXgnS9aG4P4t8gfcyGSdmARDzgxFJnrtW6nAuWQk",
      "vault": "HCypj83PSfGntWwQvNNwYzryyY9pZS33mH7CcZH9FGGg",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "19e10983",
        "accruedReward": "1a16ab41",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Ce1AG76eUp7SnzMqVK9gXDXtoZhiEafYGtJ1qznDVn7f",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8UqrmPVCC8rztmF6UY3v5upu6U6wpVpe1syprteHGVrG",
      "vault": "5thPKo57xakgA6TXMtQMcstMwXN8mKVGkGVEQdRmgp5R",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GWWgtnSAshdFMju4chCC2gnXctMFuEEWXMN8brL5wtJb",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8EmP9XPBKAQWrrbge7mU3Z6dXTnvgJu81u6zp6Aenksg",
      "vault": "Ap6VJvjBKHyaZhSwKct5zp5RsbT5Cdwya23UMH8Bnb6A",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62489a0b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489a0b",
          "beginScheduleTs": "62489a0b",
          "lastUpdatedTs": "62489a0b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "261ffe",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489a0b",
          "beginScheduleTs": "62489a0b",
          "lastUpdatedTs": "62489a0b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "H7Z262PXru37oZjTuu2grAnBVPatA47VG9ozxLCWNy4p",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9nqDCj7sKSbfpMaGc31JNVnRa2ykiQF5hHQvcpXNRxbL",
      "vault": "2LMzwHwf2Wn7r4LUFdyFppC5TY9wZLfwRuMgLtx1ErE2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6256e30a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "292acfa6",
        "accruedReward": "292acfa6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256e30a",
          "beginScheduleTs": "6256e30a",
          "lastUpdatedTs": "6265bd8d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17d6ff",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256e30a",
          "beginScheduleTs": "6265bd8d",
          "lastUpdatedTs": "6265bd8d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FP3BHnK3ND4s9nYRs3YRN66iiBfFyS4NTWnzuHVSq2aB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8T1vPtZQxSu78KAw4HB5Z6A86WYRVRqc6PFtayezWx4y",
      "vault": "EDnkvpEF6XKFtG4sr7rJTrP2MFFeGuKPmyNqRBTMnX4y",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6262aae0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0150842f",
        "accruedReward": "069d6776",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262aae0",
          "beginScheduleTs": "6262aae0",
          "lastUpdatedTs": "6268877c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0c0f29",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262aae0",
          "beginScheduleTs": "6268877c",
          "lastUpdatedTs": "6268877c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "339xqRDCenGdHeUN7uKaPbkPjFo6A8UJac8EEkNUPKda",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EQLqNPWWX2JUxckc6e6FaKkex1YZKiWNLQHqiJTS2gGv",
      "vault": "4RbbtZMKhcfp3RvSxMf5ZV92Mav4eyor7NGm9MiVVEUE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6268f494",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268f494",
          "beginScheduleTs": "6268f494",
          "lastUpdatedTs": "6268f494",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "05c575",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268f494",
          "beginScheduleTs": "6268f494",
          "lastUpdatedTs": "6268f494",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8W7CPArkAAdfGyhEu1StcWcCP4KB9weD7pFAtZC8vU8K",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6BrUn7ri74UFRdnwkC4g8zwDcMV9b1DQpjgegVPzHK4e",
      "vault": "Cdig3jqKVmpGw9DEY79U5keyFPRWYSMaxQSV6LXkjoP2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6251cecb",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1219b677",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251cecb",
          "beginScheduleTs": "6251cecb",
          "lastUpdatedTs": "625bd10d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1ceb3e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251cecb",
          "beginScheduleTs": "625bd10d",
          "lastUpdatedTs": "625bd10d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HPr6vhrDNGHs1KNWnZfEPmNMQCSirS3corZtczU8Znmg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3Z61QHR2TBncqA8E2akhBZaqSfBRyYUjnNkyXQtqZvkY",
      "vault": "Hib1irrFd8uWwPj5fC8TEqc8xxwDYxVLxq4jk46H5Cws",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "11e50e49",
        "accruedReward": "19c2d688",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7Rx8SoDqSB91y4hXnrVUW27Jz1YffRPwv9pxgf55bd7Y",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "H5dw3AmvUDBTEvGtgxGXpHaCjHK8CP9tE8XcDC6B6FHJ",
      "vault": "EDhku6AYMbqSmvcLunDajZakvKKDGgDod2tmvcUJvUdL",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6259bdd6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "100a6242",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259bdd6",
          "beginScheduleTs": "6259bdd6",
          "lastUpdatedTs": "6259bdd6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14fc33",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259bdd6",
          "beginScheduleTs": "6259bdd6",
          "lastUpdatedTs": "6259bdd6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ELxuGLdWHVgtegim862ASBzaVm1vc9HwJUqLCXwrA6Qj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BEkXNk1jAYJqDyRFvXTFZPs9wE4fQ1BVwSpSVzZ2ghY8",
      "vault": "JCJf48GSWZvGZrpN2uQsf31WevVk1Eq7yAWsHPF7dLyy",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625c0079",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "23fcd36e",
        "accruedReward": "23fd7292",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c0079",
          "beginScheduleTs": "625c0079",
          "lastUpdatedTs": "625c0079",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "12b990",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c0079",
          "beginScheduleTs": "625c0079",
          "lastUpdatedTs": "625c0079",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GGi8H1j9qmeCPauf5cAm6SLp78AuviZraqPsQZ7kQPMd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3uQ1L3nVBqbZEL3ySAR67wRPhDHHrTVnaczRkfB2fQ4S",
      "vault": "3F6qYcdmBk3ocV844FrB2adCERhYmquBfd5iHoGHqikS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62598df0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "07310956",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62598df0",
          "beginScheduleTs": "62598df0",
          "lastUpdatedTs": "626181fa",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "152c19",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62598df0",
          "beginScheduleTs": "626181fa",
          "lastUpdatedTs": "626181fa",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HipmZTKvx5UJ1pHxK9zMbyXhfTa9jxZLvpvBscVXiu8C",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "u9Yq1x6o7XGcPx4NbYLJknPM2FpgR2QPWjUuRu3GwNb",
      "vault": "7yuuBHmakCMwYkouGRJDeVTqLDk3pT7hUjXyGjBC7RsN",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4WEbCsND5dHfAw35dApJJ2iNQCUH98GxNH1RtHShmqdP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4PHkcBukibgZMzd6oUcRBThAht9o5EutUcX9zc9WVZ2f",
      "vault": "2iH8Kdts1pGHMvM5Ysb1sZ2BpVY3tgrJRubuBALrwW3f",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6258daa2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "6407f7a2",
        "accruedReward": "6407f7a2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258daa2",
          "beginScheduleTs": "6258daa2",
          "lastUpdatedTs": "626783f4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "15df67",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258daa2",
          "beginScheduleTs": "626783f4",
          "lastUpdatedTs": "626783f4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "B9JS63EXaVFqcDo88HRbrVQEoLLWEsiYM7eecKMkETGB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "tNqjRpMT5uGPwC487zSnqy8oPumJzvx9b42uGQCKuWm",
      "vault": "46pixDrL5ZGVQRQvUsG42eGWBdzmKmTDmiPZFx8CVefZ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625aff8b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "de6f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625aff8b",
          "beginScheduleTs": "625aff8b",
          "lastUpdatedTs": "625affc9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13ba7e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625aff8b",
          "beginScheduleTs": "625affc9",
          "lastUpdatedTs": "625affc9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J6KU4TwF7QLVmrzyC8hCToydAeA5Uv586Tr8XeD4QSMB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "35rG7MoTiJgybYy37TZUNnPYo3TSHUnxyu8wd6ZtxRXU",
      "vault": "HMwPWKNNwATyw55j2xL8wYvWaTYokYJ8pUwbTqZB2ECK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626473d7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626473d7",
          "beginScheduleTs": "626473d7",
          "lastUpdatedTs": "626473d7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a4632",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626473d7",
          "beginScheduleTs": "626473d7",
          "lastUpdatedTs": "626473d7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CEbAfG4w9jGyybpDYpkFsYj7FQfFSHj9M3ARGGKRTmkL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8izBcaaszMhJ4JKb27VhVkGSSyKrLPrJASVWq1i9ZeTb",
      "vault": "22suBFqZMHgfhHX5xFWHGBXosPud4CLbFuaywKy44t7S",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "048ad278",
        "accruedReward": "048ad278",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HdxRBfP3gkASJCYLrwLWazfnF7pk5P2tXjSkzSsLSine",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Henk9mbpq88XkRdY3GddDhhmF7Byv11tnQJEx7nES9pR",
      "vault": "DQsVG5tyagR5LU4REngfd891iVGbqu7RRqJC89U2pHb7",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62584616",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62584616",
          "beginScheduleTs": "62584616",
          "lastUpdatedTs": "62584616",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1673f3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62584616",
          "beginScheduleTs": "62584616",
          "lastUpdatedTs": "62584616",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "D9PMmU6gCUr2mNm7wPwfaAtuigZF6EfmD2LtXmwUJpTn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AAxchwGfDxZMqAH6736w9bpyshq7sViLdhzkVJuUcsfQ",
      "vault": "DZwW5JszpXokpTj6PsRc8619ZF5SDE6YALRUktco7P6G",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7VRY4HSqoGZJMiX4ZsRRCrtsLC2b6UbWCZ5RwpsrZr2Q",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "B3tqaU1U53qxdE1wsmd9VJjLYUtpsC9zfHqmrYScc4PY",
      "vault": "AyEfmKwUViEKoG1K2AaRskp8DTd2C37dmfLAFSkytqg8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62589b35",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "28b306ab",
        "accruedReward": "28b306ab",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62589b35",
          "beginScheduleTs": "62589b35",
          "lastUpdatedTs": "62589b79",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "161ed4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62589b35",
          "beginScheduleTs": "62589b79",
          "lastUpdatedTs": "62589b79",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Fc86n1BuHd8ZLmrGcpew27zzPNcgduHhEcRiUmQL9uzB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6vWHdga7877dg2h2FctT63jW3rBBxiaujRaVtencBrVw",
      "vault": "BUwcBsDU8Vkfeb1oMPnk9MDUqexwUnnoqis7yLy4EhAi",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5uK8oNTbnMSCzkgUDkqBScJhuMNVzbUJy6A3yu3soukU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4Pvj2XYLqFt9W6oLzgeqYDvBRgyrmqyYhW3TzUYgPGrr",
      "vault": "9VLLaL1iKx5JuXSJdt8U8a1nUfdA7ve7LrK6tzeww9Gg",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625cef81",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "26e147a4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cef81",
          "beginScheduleTs": "625cef81",
          "lastUpdatedTs": "625cef81",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11ca88",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cef81",
          "beginScheduleTs": "625cef81",
          "lastUpdatedTs": "625cef81",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Ac73FadeUUQZmwSuSzqmymsFTuTYpGPtLGAJEaN5hTsc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DhS9eRyiE4ofT6tgpccSqkKKcaNeYJtAqu3iZrsg6rka",
      "vault": "GGzVMN567RVeG876qmcHgSy8r3Rw96cMLrzjokRRvfNE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625f5081",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0379f0cb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f5081",
          "beginScheduleTs": "625f5081",
          "lastUpdatedTs": "625f5081",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f6988",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f5081",
          "beginScheduleTs": "625f5081",
          "lastUpdatedTs": "625f5081",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EVz7SZpwWM5b9AcxUvsxBbX5KA8w8kMHU2NBQmHiiE89",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E2MWf8vUjozYU8ZKh2r7RGMZh8DqsQ6asrVkqAFpsScY",
      "vault": "7mCCrfKnqPji7fctFxkFXUMzpjfnoYsxtzjsWxjEgs1k",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624faee6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "10bc45b9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624faee6",
          "beginScheduleTs": "624faee6",
          "lastUpdatedTs": "626230f6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1f0b23",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624faee6",
          "beginScheduleTs": "626230f6",
          "lastUpdatedTs": "626230f6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FFGEMoEgZ6ro7Pi2JcBeH2cMWd4nig1LdFXeNdjKGnMG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7PqbZsC56GbwKjUvm4pQF4xR8B8CZZEr15sE3ZuX72aH",
      "vault": "5SBXLKvefNus5RmYocRvhqrsSDHA3eLH4stw7NFUMh1a",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62579925",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0257b2da",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62579925",
          "beginScheduleTs": "62579925",
          "lastUpdatedTs": "625a305d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1720e4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62579925",
          "beginScheduleTs": "625a305d",
          "lastUpdatedTs": "625a305d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3d3TL84BFoDu781baMuUXfpxAomWUvW7Zqfy7NyYDSPQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3iEr5YRTSgQww9LC2RsnJBdQFSQvwqyJpn6fQcdSTtwx",
      "vault": "5q2afghnDc7bTUzxHhv23WWWwJdSzuLm2iBdpsZZbkGB",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62548246",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "246b3d77",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62548246",
          "beginScheduleTs": "62548246",
          "lastUpdatedTs": "62651acc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a37c3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62548246",
          "beginScheduleTs": "62651acc",
          "lastUpdatedTs": "62651acc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8SRevm8adUnMHaCzjpWREpNGDxgvNfCHETMDffJHwJe5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E1tDzrntqL8qqBqASHzN7sHn4G8QMaMgUe9wNV2FCQgq",
      "vault": "Cfz3oFM4NGhB7jTzUoT2S6qozT4C5ZTyc7XVYjdqi3WV",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "01a5f0d9",
        "accruedReward": "01a5f0d9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FMBQoa2B6XPcUzEStqbX72Tx1LyrL3ihKKmqjaorPLQV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Cst6UN6i5u8Sjn8QrxCfHzVV9bABDforpfw1dyRnJJbm",
      "vault": "ELsnmXaB18QRm83kyHEeSE9MdQ6UV5DaEbwnc9Vc7j6x",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d2b10",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "34bfb070",
        "accruedReward": "34c073c0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d2b10",
          "beginScheduleTs": "625d2b10",
          "lastUpdatedTs": "625d2b10",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "118ef9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d2b10",
          "beginScheduleTs": "625d2b10",
          "lastUpdatedTs": "625d2b10",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GP4auEDeR9RRFoe7HPSkVsypM22TP764E6kpoRQEfXxr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3XonzZKUbaT61VrUJeLCMthVpvXQH7AFp7qf8JEoUBQp",
      "vault": "B1XNsx8EwJX68psyftChrG3wDZcS1n4QBtdgUVkqosZr",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62585986",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "33785881",
        "accruedReward": "33785881",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62585986",
          "beginScheduleTs": "62585986",
          "lastUpdatedTs": "62631bef",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "166083",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62585986",
          "beginScheduleTs": "62631bef",
          "lastUpdatedTs": "62631bef",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GbarVCSSrvwDqTvh5d3jNQ8o5i38mm3otLe2s4f3KtMy",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BJEm4R2r69ZtYvmAA2AjZikEQCCs22M7dTSy8ERpNRLK",
      "vault": "4zrpTTW67TYk9gCvpvYgYeNbb9oe7PB3ADDQmJXAd8qT",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4hd7FQMGQWxpHK4XTFHTJpRQGCFp1U9FP4bPLWGrDFyn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3LKCJkZ3jDPJ62adEUS2RVTvnpsYCjGMphVKkFZMzqDX",
      "vault": "4yZuSmXfT6dn7BkXmcfZGJ2Sa1WjRpY1VR4sUMgxdm4U",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624872c3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "11e4e3c7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624872c3",
          "beginScheduleTs": "624872c3",
          "lastUpdatedTs": "625c3cdc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "264746",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624872c3",
          "beginScheduleTs": "625c3cdc",
          "lastUpdatedTs": "625c3cdc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "83GQNmxjmPyexhHMkEi11HezqKkMFRMS7tpmLSp7mQyo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BtX3jzcjorqy4BFWQVXBSA1VBzJEYUUngh4JzC5CDFzq",
      "vault": "7E7TtJfq98tHRmFTLCFwupwpatKDfkgg2GgmnCjux6m3",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6260a97e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "04b40c94",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260a97e",
          "beginScheduleTs": "6260a97e",
          "lastUpdatedTs": "6265dd07",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e108b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260a97e",
          "beginScheduleTs": "6265dd07",
          "lastUpdatedTs": "6265dd07",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8iUQ52kMaMNqKEwRE9xU3kZkXtApX71LSbMVDvhG1B4P",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F52vo4vW3Dca67DQ71kXQNiMpgGBsJU9sShhdD3icA4N",
      "vault": "3KjjK2BoAiu5DpURxLZ4X2e2ZU3MRgz9QgxpfREoY3qB",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "550fc93c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HXz3VN6VenPw8XvnHkouWfKNNJCVJ9Ce8RKrgXma9egK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8vZDvjb78vSJo4HRLhoSzSaqnJTQ9facDuKim1hpi2bB",
      "vault": "FUsndaFWkqXjbAXaNSEtJKh3xkN1xGRYWTDKeSf1yH9X",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6268ad25",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2b5db1c6",
        "accruedReward": "2b5dbad0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268ad25",
          "beginScheduleTs": "6268ad25",
          "lastUpdatedTs": "6268ad25",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "060ce4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268ad25",
          "beginScheduleTs": "6268ad25",
          "lastUpdatedTs": "6268ad25",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J8ikRFTv6A8G3XHUk6jMQL3xST9bi5Bx1eBL9i4s2mfs",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "z3YjYS3Lt1hgkCvQ1BYTw2GkbFvpU8E8viHEMFcczwp",
      "vault": "6Wi5bP4uwFyQNvK2VLF8dkP1FRG3nRNzSJKohBZTDJgn",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "06e881fc",
        "accruedReward": "06e881fc",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Fov5WZTYsSWDVePYbo6dQkSgxrL6wxF38Jj4jxLx72Yr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "62XmP57M52CqUaJozq2wVD5XYfbeVhH3JA3yY6ZjSCRj",
      "vault": "8Pjd4m8v8YYMUBYeoaDFp7t5VULcZHG1mz88j3QymHw4",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62477438",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "10968391",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477438",
          "beginScheduleTs": "62477438",
          "lastUpdatedTs": "6259cc86",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2745d1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477438",
          "beginScheduleTs": "6259cc86",
          "lastUpdatedTs": "6259cc86",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "72p7tbXqAZquXFeNb2wk9qiCyg4WA8dvcrFtAyJpvmwE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CtfouYUjgiM9aPqVjfwnY8jnUQ4MdKMZH9EXCfQBwQQ5",
      "vault": "Gca2WwrTKxieUN8FTGsmmfHduSyxJ9PJZRiMtWjpR4LN",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "62592b25",
      "cooldownEndsTs": "6266f8eb",
      "rewardA": {
        "paidOutReward": "0c7b531b",
        "accruedReward": "0c7b531b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HeJFnbaDJYhD1rxbb6ZkhZEzAst76S5bhtvmxMtmiuFa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FwvQXbgMTiUk6gmzRxSxi3u69aGQkQiXjCEhASGv4vy6",
      "vault": "BMdFXSoKt1DDCgJeJdgC5PCvtG1btbcQk24qBLc5JqHR",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "624ba126",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624ba126",
          "beginScheduleTs": "624ba126",
          "lastUpdatedTs": "624ba126",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2318e3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624ba126",
          "beginScheduleTs": "624ba126",
          "lastUpdatedTs": "624ba126",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "F7tRMjMrkuX134WkDVWVG6KrRmWvC37usSUQjo4AqSuw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BTfZbQvqTh7iVfUiaZq2Si1a6q3ve2GuwNHttrGammHe",
      "vault": "2vsL5hyh7DhDdApCWJ8rocK3FdPVm1nBRrcVMuMJgkox",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624774cd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "af6a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624774cd",
          "beginScheduleTs": "624774cd",
          "lastUpdatedTs": "6247752e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27453c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624774cd",
          "beginScheduleTs": "6247752e",
          "lastUpdatedTs": "6247752e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6AhVfRrMD1iQZwpY9LYXtSMj1wLasgsLjSBnn2C1vx5R",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7LexdW3wM1CpfbeeUHECvGSbAam5T8NhtEBS4mPiVLNc",
      "vault": "6P3BWorAFJKhuRRMpPV6NnNVsZQnThzsvijmXCEMawQu",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62479fc8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62479fc8",
          "beginScheduleTs": "62479fc8",
          "lastUpdatedTs": "62479fc8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "271a41",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62479fc8",
          "beginScheduleTs": "62479fc8",
          "lastUpdatedTs": "62479fc8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "52PhCU3k72LmKjbgqAiuTsvXMi9Uz5uhjti7RMPN3tV4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Go68obPHupKc9fKqeDmTjWy5U2cc2pK7w2edpv9YACNJ",
      "vault": "BDT4tM8TfUxXG6UbJeYdAQjozipouuWZHrVvwHPHrso1",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "6247798a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "b8d5458e",
        "accruedReward": "b8d5458e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247798a",
          "beginScheduleTs": "6247798a",
          "lastUpdatedTs": "62698b0f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27407f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247798a",
          "beginScheduleTs": "62698b0f",
          "lastUpdatedTs": "62698b0f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7NkMo5HnWNKTrjPnJHH7h6pDdvjT2uFatDYDtvehqv1L",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Bo9aEUmg9oDAYzFmjnPj6GjCzbMBPq8c78PsdSx8f7vA",
      "vault": "Epp2qRvemEUx7iHNh5apqDWXL2qRVLvSoPRwMjdfpXhq",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3tEXUjkoLKTRQsUCscKX4ovJz8a4teZpDkLxMi8MFpue",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EW9HPXjHS3n27YCS8aTLWWNVXj47fYhjUcTm9h1YM4YA",
      "vault": "3f1c1sH4tFmMA4KdJRXUx2Qm8HepjXYjbb4CM7v7qJUq",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1546e3bf",
        "accruedReward": "1546e3bf",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DHWGPzvjWfxtjnov7hVnXpMjdUTDhVPWxpgJknYpsC6i",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "52T13jRhsipWKVd2g4XrdFd13qDLB5V5ucddjbKR7D7F",
      "vault": "7QYhydWFXvVW6u5bxHL2nahWBoyiaN8SNzr3zAHa1HnM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6258350b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1e58dd32",
        "accruedReward": "1e58fbef",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258350b",
          "beginScheduleTs": "6258350b",
          "lastUpdatedTs": "6258352b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1684fe",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258350b",
          "beginScheduleTs": "6258352b",
          "lastUpdatedTs": "6258352b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "83EWFLDXVgaNjoT4AWaeRZSfnkLuSPTGm1wViUo2sv5V",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5CFLe6NLY8GTunJCofy83ojiqVQSRZN6GT1GSNUdLMb1",
      "vault": "9LmPeFB4L9HLmCsbqbbAdqqzZWm4tMi9K8mzWAVqHwxa",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626a3ddc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a3ddc",
          "beginScheduleTs": "626a3ddc",
          "lastUpdatedTs": "626a3ddc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "047c2d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a3ddc",
          "beginScheduleTs": "626a3ddc",
          "lastUpdatedTs": "626a3ddc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BkG2CYCGT2i5pMUrNbct67v5iwtJ22kVaZ1Mg5cuXGBB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "252ukoPTZ4xM7dK6N9v1nTaN12kSuKKrCcxMsiw5ucoq",
      "vault": "31d9xuAouRXmrNJ7GbqRZ7QAEXoUHjeJpoj88FzLjpyQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6258798d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2e9236f1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258798d",
          "beginScheduleTs": "6258798d",
          "lastUpdatedTs": "62654501",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "16407c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258798d",
          "beginScheduleTs": "62654501",
          "lastUpdatedTs": "62654501",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GVzAKwDjVKHZ4emDbwfVRVbdu5LQXmG4MhVaVUV6jJbh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5m9ZU2MjwqxjbcJxd2rZvR4hFppeTb28Qpph9LWjq1L4",
      "vault": "8qep9EXnGY7HTbiCEVqJNZhvt1SMB98yqJAepc5BNJvS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6253b2fa",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3b935277",
        "accruedReward": "3b935277",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6253b2fa",
          "beginScheduleTs": "6253b2fa",
          "lastUpdatedTs": "6268fcb1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1b070f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6253b2fa",
          "beginScheduleTs": "6268fcb1",
          "lastUpdatedTs": "6268fcb1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EuCYhXoRMsqp8nNtemSgg4AsCyrozcAhYXUFgfiMyi2Y",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9QMxLotipTVSHkNFdi1Z6va6PhB2VLC5r2sSeAWGbCEc",
      "vault": "HPxjPRerPtPvauN5dqE2G38GX22auVXGyiqG8UJ6q73q",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "15798eda",
        "accruedReward": "1579abc9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "oTvZBA6gK86Zn1z6s4mDAkgLYqB8oUbWLbqcLAWjr6C",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "33XA8ApBD5prZiQu2GccLZ7UdSxHU5NuRKJTa7wdGEdp",
      "vault": "GHSZJ2qpiKt9LRaVkVEzM7paqmkyDEBkwWLUsgrYyTAf",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CS5ACEpsyjHJqXKf3GWxj6PqzFFced6iMQJnYaRJRv46",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AaPCFNSTPonqfNiZH9FadP3VDFwzvn6GtmhncK3g9oQ8",
      "vault": "3ZBMMWwaWdxuT82JmrR5qRKtG9mTNoPbxpAGgBSPR8PP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6265a31a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "16a0c8a6",
        "accruedReward": "16a0c8a6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265a31a",
          "beginScheduleTs": "6265a31a",
          "lastUpdatedTs": "626adffe",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0916ef",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265a31a",
          "beginScheduleTs": "626adffe",
          "lastUpdatedTs": "626adffe",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7qZa8vXF17wCoF6kJocKzCDaU6UD2ojgXRg7ZecGVmHJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5Mr9EE5ZFjtRXjPrGNqJwyR4enYynFUASWEovrmspoR7",
      "vault": "9EKXCERQLi7xSr9FZxhYnpqkJbWsExXD14gk7BE46WxB",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "626ad6b8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "40016002",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ad6b8",
          "beginScheduleTs": "626ad6b8",
          "lastUpdatedTs": "626ad6fe",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "03e351",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ad6b8",
          "beginScheduleTs": "626ad6fe",
          "lastUpdatedTs": "626ad6fe",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4Jdd8MfsxnD5ACTfV9qsYfMdFD53CBwKB8h6EGu9h37x",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8bYe54wqeHgBGUfwCQoQBgKGUbA3P68zGp2Cjm7DsvQa",
      "vault": "45ExHoyfzUcYqM3VkryQDvugXbhjAmPc1UdsJyDvC9gx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "625ebd3b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "78ac1e1b",
        "accruedReward": "78ac1e1b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ebd3b",
          "beginScheduleTs": "625ebd3b",
          "lastUpdatedTs": "625ec077",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0ffcce",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ebd3b",
          "beginScheduleTs": "625ec077",
          "lastUpdatedTs": "625ec077",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Cs6i4JWm3F69NiTYuMqbMNS5QmQ5Sxs927skAPTGqrDS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7tiE4F1gnJcHWA5Fqqyz1ZvAcmPibknqXuB6FwUiiGDW",
      "vault": "AxmkYhWYrAfwHWfxeMXR8Zbscw2z2efU2KPWgKTrAdFy",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624c55eb",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "14709de0",
        "accruedReward": "14709de0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c55eb",
          "beginScheduleTs": "624c55eb",
          "lastUpdatedTs": "6262f0c6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "22641e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c55eb",
          "beginScheduleTs": "6262f0c6",
          "lastUpdatedTs": "6262f0c6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E9T2anJcm53oQ6dwKCbN5TGWpLM8qfD4MEX7vvsNSxNA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "G1PRY1bGnLwjgNPqq3Dn5nnYZFATizGsjaAkGnHzippp",
      "vault": "DyGrqm6C2KEyrUtHDqMq1UnHQ1cvC1zsQxhnGbZMkyvz",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "9173f6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BDYjNQJhUSgXpSfjiRXrpNbStwMhVBmQ6a7MEgpWicPA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GPDuy2DXgu5tEsr1UiP8w1fSCh1vHjE5i7oZxMKtxvAK",
      "vault": "5gih3zfWcmtiBVUzjPSxaALVBbx8Mt7QanT3S2RAwUgm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "6269bdb1",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "44e3f134",
        "accruedReward": "44e3f134",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269bdb1",
          "beginScheduleTs": "6269bdb1",
          "lastUpdatedTs": "6269bdb1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "04fc58",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269bdb1",
          "beginScheduleTs": "6269bdb1",
          "lastUpdatedTs": "6269bdb1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9ucSShBtvM8pstNrxAXCmeCnNQSc5nebPrycroiX33KU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DvWKzaFgBLT7F5fe1xBvW8tX4TRhuiGVLVbR94P2j7s8",
      "vault": "pnxAu2j5sKHxiZtCG4Pxm5GXwdvCV8bzM2zgbwxQPWX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d83d0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2724f3ad",
        "accruedReward": "272691ce",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d83d0",
          "beginScheduleTs": "625d83d0",
          "lastUpdatedTs": "625d85ab",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "113639",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d83d0",
          "beginScheduleTs": "625d85ab",
          "lastUpdatedTs": "625d85ab",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EGRBnoQTScfw7dTxTonG5PBw8bPJrvE2XeDuRrPDDwZT",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6TDrccWsSf6LGaUiAn1MuJ8CGJz3eazun7r5BbP4gGXS",
      "vault": "Dj3jssEtLWqaMiwjrZ5yMGmGCfpmX9PF4PqxFbUsHqyF",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625edb16",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "29d8d077",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625edb16",
          "beginScheduleTs": "625edb16",
          "lastUpdatedTs": "625edb16",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0fdef3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625edb16",
          "beginScheduleTs": "625edb16",
          "lastUpdatedTs": "625edb16",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5juiZ1awWGf3rDgku9k2KdhngAUKjKN4Zmmzs3DRsXcS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GyxErSLEd5LzP6eWCrkqCroF9hE1TgWVb67inZB5bnyt",
      "vault": "G9ko4E6MKaXpcvrU2xzBpwSNYa7osjsFkEvw3tZvVRBD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625a12b6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1488cb4a",
        "accruedReward": "1488cb4a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a12b6",
          "beginScheduleTs": "625a12b6",
          "lastUpdatedTs": "62656d82",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14a753",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a12b6",
          "beginScheduleTs": "62656d82",
          "lastUpdatedTs": "62656d82",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "12nbHY3EgKVnYcetYzHx8AQ5hq6cqdFgm1qZMFFaDA6V",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DKiXcrDNQMWrV1P23PyWtUtGNge8UAP6QeEdf5WF3j18",
      "vault": "ErvW5DAnKHQX7DkEN1XzqbBLGZyBvgSVo5vXnrVfWD1h",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624e08e7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "180ed6da",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624e08e7",
          "beginScheduleTs": "624e08e7",
          "lastUpdatedTs": "6268a3b1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "20b122",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624e08e7",
          "beginScheduleTs": "6268a3b1",
          "lastUpdatedTs": "6268a3b1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "w46b1tmmT94wquxMGpESHCEuN5jfGWv6q5LRFS2tV3m",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4nRienJpG7An33HwBnVU3k2hr34oJGKYK9m3fRSscLzw",
      "vault": "rzW8ZWG3okugtRMc46j1VSrTjXZnov4WEQ8ryErctnp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6249bab0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249bab0",
          "beginScheduleTs": "6249bab0",
          "lastUpdatedTs": "6249bab0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24ff59",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249bab0",
          "beginScheduleTs": "6249bab0",
          "lastUpdatedTs": "6249bab0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FEBYdGKP3ZsnTiHCU7vddKg7hktjupGWwCVKCzaDueF9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CArjxsPwoAhHt5gd2cSGPeE1wofv2Mn5T8rQihZ4vCNt",
      "vault": "5cXEkVEmStTqGw3VKoAkHZYq1zWHm4fErjbCp4sLAPUm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624bc835",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624bc835",
          "beginScheduleTs": "624bc835",
          "lastUpdatedTs": "624bc835",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "22f1d4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624bc835",
          "beginScheduleTs": "624bc835",
          "lastUpdatedTs": "624bc835",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Hh2R8KaqdN8Bagz5EYqWcBur5LNGMWJLBSjbQqcLV1K4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6nM4XkK5AQHJhtiUegigt1BgdfYnYAXqCNcSLWH229Mf",
      "vault": "HZY5T2dvutsRcyTL72Y2LvRhpgqbfn1ULHxFjY9rnmR9",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "136eeffc",
        "accruedReward": "1c932991",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AjA5EYbTSWhnrWxYMUoKEf2TdpuNvtPASxrFxqr5oudp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HM2tN6Fg8FgrtNgPc5wVmQFLqqmy3MfCHDeFE3FNhMTi",
      "vault": "4d7j7kFQvtma8ssP8YkVd8n87EHL83Eh5gvobRMMCV2f",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62660a5c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1f849d52",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62660a5c",
          "beginScheduleTs": "62660a5c",
          "lastUpdatedTs": "62660a5c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08afad",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62660a5c",
          "beginScheduleTs": "62660a5c",
          "lastUpdatedTs": "62660a5c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EJLFddwoy6snYNz6ZaiDNQLu4rkYtjUnYuoRzGfjcm9F",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CTTGv4PPpNsSSBHf6vbvP8umjwW5UJ2FMT81EypPnHFL",
      "vault": "9iPj14PFej5K1w3TZJ2xtt2EqhaQWWEKnFPmnk24ZxSZ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62608c60",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2c9be4a3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62608c60",
          "beginScheduleTs": "62608c60",
          "lastUpdatedTs": "62615556",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e2da9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62608c60",
          "beginScheduleTs": "62615556",
          "lastUpdatedTs": "62615556",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5rUZkZgRmozivcovQ9jDZLK93EJjMjWvu1wF4at9dTbB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "o12xAbSqsftMgG2uC5KErUH8NNbcesSv6YJ9yVN15v2",
      "vault": "8LfbhfdEwmASC7H9wuSU2eF3Mk4hcTrGS5H3Baih2SE9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62662d56",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "35c29d85",
        "accruedReward": "35c2c54e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62662d56",
          "beginScheduleTs": "62662d56",
          "lastUpdatedTs": "62662d6c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "088cb3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62662d56",
          "beginScheduleTs": "62662d6c",
          "lastUpdatedTs": "62662d6c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "H9oT4jdgnddxmJXaiH9imwNCfEcsRrFF37aFf2nWb7d2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EixaZGdnWTjjEoZANSC2XLSHFW7W7FG5iy2VRCVJdN6A",
      "vault": "9y3JFKjKro7itwVbQ7gUYHQrCGkbnbDorS1BjFZYdpaa",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6256821d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "20eef448",
        "accruedReward": "20eef448",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256821d",
          "beginScheduleTs": "6256821d",
          "lastUpdatedTs": "6267e613",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1837ec",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256821d",
          "beginScheduleTs": "6267e613",
          "lastUpdatedTs": "6267e613",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BaQdHjaSU8m8DBUVnSYtSaLbYrqyEtkR9bqA24MzHyxU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9xRj97xx2PzHBSdxTvdTB2mxxX9eXbhkDHKVUaK9zW82",
      "vault": "AReemHCeT8jsf2jVXVMGm1s8Q9RDpxp1ot3JfJRP5Ywv",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62497923",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "171954ef",
        "accruedReward": "171954ef",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62497923",
          "beginScheduleTs": "62497923",
          "lastUpdatedTs": "626304cf",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2540e6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62497923",
          "beginScheduleTs": "626304cf",
          "lastUpdatedTs": "626304cf",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AuUpi7xQmA3A9UtAHkfjCHEzWWuB67rCWMjVP4J7Wvim",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BeFcsuZDCCLxqKrfNzCxGsVH19Gfb6kLK1D1KF6WAPVi",
      "vault": "EzBR5VdTwYu6FZkbtAs5H4hSmURWkpbZ9uAYH25yBeXM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d61e6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "09aa4ed8",
        "accruedReward": "09aa4ed8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d61e6",
          "beginScheduleTs": "625d61e6",
          "lastUpdatedTs": "62681249",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "115823",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d61e6",
          "beginScheduleTs": "62681249",
          "lastUpdatedTs": "62681249",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FBcda1nFS4HAjs2C85bFreQirSFSzAxw52CUBaVVno6X",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7bNtrvybQEDU6qCJXNjqZyouK7YNJEJoJPBnSnREPetu",
      "vault": "HxANgCREhLuYB5qHw2bYiox8TxCQiEaDhzYG4p2eBdSR",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625cc518",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "035b1cb9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cc518",
          "beginScheduleTs": "625cc518",
          "lastUpdatedTs": "62607b34",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11f4f1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cc518",
          "beginScheduleTs": "62607b34",
          "lastUpdatedTs": "62607b34",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CcYTAvG7xNgHFZticnTkqfVg92xrL5MehGRatVPrpija",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "76osH8UZoxvm8q54rFvi36ksrSQna4hJ8QPoafgLcHTA",
      "vault": "7k28upKcCdujABQeP96JmLKB8pdCMSTztznvVUEHuFMq",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6aQEngfBaRxmNQ4gwC6Kd51Mz2sR8iKEbKYh4B9qatWq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "J2pFhbqFzi3msXpn5Sxs2DWD71ZUeosxGeCkHqX3MFfN",
      "vault": "HN9XB996B1nNRHJVcguw9iQuz632zKyBeZhmqUUkJFwz",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "65gH4PjcLrGEqEaGrLn3PuCcsaPwXNN3uReCoCMAqghX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6gUFMB1JDhQLqQ37iu4yW4G68JKkfagUiGUvVF9EtHB8",
      "vault": "A9KMWerAmBH5WdG4chy75kETxmD8JVBhcUAVNpqbV7hu",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "119ef5f4",
        "accruedReward": "11a6f34f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5FoKtuqBuzQfWKK4TzLqGZGA6U3zcg1RbQR4euna5MWM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FNqfN1QE7186ZY59CsdGqXL7stM1Uq2DfpvtAHnQ6dj5",
      "vault": "5VrnR2Wh5Dqjo5adB9TNqcRSraDKy15uHTgDxBAaAj93",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "07fa486f",
        "accruedReward": "07fa486f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GfQTDi8RszKyEmGWJCwfVJHKXyN1PHjTeieG6pfxZ2FS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Nf34Y2uDaKwcTzweUe1c7u78eq9GXj1f1nASHeWGmC8",
      "vault": "D99G5KtiksjxB4wQeM7M7Uv226czUEHcgaxi1FsyjdCg",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624a3512",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "149e5b93",
        "accruedReward": "149e5b93",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a3512",
          "beginScheduleTs": "624a3512",
          "lastUpdatedTs": "62610283",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2484f7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a3512",
          "beginScheduleTs": "62610283",
          "lastUpdatedTs": "62610283",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FEtFXgAHwHrbwqvUZVMZonVqU2ayQHaW1t33Lys6at4r",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E94eS3XQMDuDh5Ze2di23tSRtEiYj9LmLEhiazAtjuqc",
      "vault": "Fm4VDEkhkHA5KpKLEKVqkwrWgEVG3BadumZVhFE9giHq",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "88XPdyeesk49KYU5AVtRGK3CpkLSCuW5ZBY9A6meMqfL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8AL4vZCLdwnUp8TNQSHSN3FCzxVkktDAu1KVsiL6C738",
      "vault": "E5m5LMCJn5vSXtpEGs5L4ML9rocHtVFeFQJcoTwbVovc",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "625ebf11",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ebf11",
          "beginScheduleTs": "625ebf11",
          "lastUpdatedTs": "625ebf11",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0ffaf8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ebf11",
          "beginScheduleTs": "625ebf11",
          "lastUpdatedTs": "625ebf11",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "772wmMjAVuPQQ3NE3UBZSjLswEg5XKcyL6ZgyVdqeKY8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4KyKDj9VG8NYd7BJb4RfKDbmbbn6YW25it2PpxDFFQGN",
      "vault": "GbEpM1maLzFR1cEPpGzA2BtxEUsYtpxPkgHCvqDDMy5C",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476708",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476708",
          "beginScheduleTs": "62476708",
          "lastUpdatedTs": "62476708",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "275301",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476708",
          "beginScheduleTs": "62476708",
          "lastUpdatedTs": "62476708",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DDhwpGAgDvtnRXh1zdQ6LUVdLintcC53g7FJRC1e65F2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CVoDfLSixkX658SzxJ4oUyQGUPR7YZuNdMeQdJPHbXty",
      "vault": "Kbv7LDnovQhPL8H2g3xzbye2YStnyYKJLeisNnXixm7",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6252171e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "16805839",
        "accruedReward": "16805839",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252171e",
          "beginScheduleTs": "6252171e",
          "lastUpdatedTs": "626af999",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1ca2eb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252171e",
          "beginScheduleTs": "626af999",
          "lastUpdatedTs": "626af999",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EDRW63brXmXY5ShgW3sxai8vgFVNKAgywzjiRqhNWQYF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9fgMophRfN664awwkNNAruCC4QhxnrgiBjyAdc5YXPSH",
      "vault": "F1mgvPBEjm351rrX1iRx6GvEwevUd9VFQpjoGogpU6Xh",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625a8507",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "21da765b",
        "accruedReward": "21da8a3f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a8507",
          "beginScheduleTs": "625a8507",
          "lastUpdatedTs": "6263bd89",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "143502",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a8507",
          "beginScheduleTs": "6263bd89",
          "lastUpdatedTs": "6263bd89",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CdfW539sZHPhExgm7fPUBpYfftpwY5zQ5rGWhQm4odat",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F9mAQp8a6Xfk7NaVu6xr2n5cwcf356F2UdeiwLwiyKV8",
      "vault": "ACBVaQBvVx8QaJYzReQAsMg4e6khYVHQEjK4KRabsEuE",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7YxcM2dsef98QpbNFc1qp6oEEkDYxYZerhECDZcwV8iS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A2drYRDfBmRiweB21oY6PTh1181ATy6j7MCUzJRsSV7o",
      "vault": "4zwym6GS2XNFzHLKUMdrrNswakWqwwcahfTMkUb6nSzM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62477049",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1d57cbbf",
        "accruedReward": "1d57cbbf",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477049",
          "beginScheduleTs": "62477049",
          "lastUpdatedTs": "6257aa02",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2749c0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477049",
          "beginScheduleTs": "6257aa02",
          "lastUpdatedTs": "6257aa02",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4DzP2N3fgBhWTxVnr9D6PwZoVnb27huJg1qkBMwYAb6J",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DGnG9KaxTM6MrQU7JrGsrVKkm8yDWWxh8VZw6Fs7CPHk",
      "vault": "EEzr8k3smGomdvXj7ysiS5Wm5moVHz2BcHzbqXnwb257",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6248bd20",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "06a78f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248bd20",
          "beginScheduleTs": "6248bd20",
          "lastUpdatedTs": "6248c0ce",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25fce9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248bd20",
          "beginScheduleTs": "6248c0ce",
          "lastUpdatedTs": "6248c0ce",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8mkouonQcUjxaRjMtAB9XkxktDYwcKkg1MbQg2HwfYan",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7NP6i9ntbCR7ACWigfhUtxDmjGZJnUbFtBKnmdRraAa5",
      "vault": "nUHxZd4EdE1ucNsvqkEKCLrcizp45FBY8GqB6ba5u1i",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6262d649",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0851d489",
        "accruedReward": "0851d90e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262d649",
          "beginScheduleTs": "6262d649",
          "lastUpdatedTs": "6262d649",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0be3c0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262d649",
          "beginScheduleTs": "6262d649",
          "lastUpdatedTs": "6262d649",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5euxk7421LKoMA28kizoiEXNDfCcbQYgAQrPrJGbFawS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "aR2LRbsdJGN5JXvCo8zi5DtsSzeuUrk63iGNbADxK8K",
      "vault": "GTVH49oQY1o3EkWndHqSRmpAPd3nVbvBhESoViRoWF23",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "62476c28",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "7c5ba7a6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476c28",
          "beginScheduleTs": "62476c28",
          "lastUpdatedTs": "6269ce14",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274de1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476c28",
          "beginScheduleTs": "6269ce14",
          "lastUpdatedTs": "6269ce14",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FL2sqzS4Vdr8oMdoBerzaSjQAfWVBgNb7bzQ1T3UDErt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8o4V3zFBnPrtn5f6kJgyPh3NSkNUrJqxFka4bmTd2HLs",
      "vault": "DLPJ2NkXqEpn5Jdd7zwKwH2zmmWug5FfCsechv2EwfML",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0ac55ae1",
        "accruedReward": "0ac55ae1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ffV2gbBvTLUzmLWE88pBN2squuLdDffZGdb8twBB8ji",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CXPBYX5E1JRdgFL8CjzYsAzSPxicsXkeUPWNdj7CLGr1",
      "vault": "73yoPrtaFWaNSt8nL4FLzm249GpkoDPEHFwriG6NeffB",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8CWmoHxrST1mYBLaD724qWsSfXkAPmQxBGex27kd5ao4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F43JyRpeKHbmbw1KX5dJa53QNmfsus9xdP6QQhNHXgWF",
      "vault": "8EB9dxVQsYZjGLU4Vwn9fXqVJkB1c3G8aYf7XrvgkfFo",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d5e42",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "038b2375",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d5e42",
          "beginScheduleTs": "625d5e42",
          "lastUpdatedTs": "626148c9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "115bc7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d5e42",
          "beginScheduleTs": "626148c9",
          "lastUpdatedTs": "626148c9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BSrjYQb6qsBHHFuFP825fBRM4HyUp1QKExvRPsujGnfq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CUhiqyo1gQqwDZ6sdQLS4WLRETfxWHXP2hUXSyA5yWJE",
      "vault": "G2hMeANKVHbv4THcXEvtU71TSHm5anKZb9H12pWgQaEM",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0c31d96d",
        "accruedReward": "0c32134b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4YkDsi5Rj6Hzrfy2p3b4EzemKqSWXVgCG25CCcQMTDVz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "48jDEJLquwmthrDbuLgfuCij4MayVQprJ49xt3E6GvF9",
      "vault": "B9NqQsUhPjRZMKwShAMgScRDAsmdy1Fmnse17cSYaR25",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "62546d24",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "4afcc923",
        "accruedReward": "4afcc923",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62546d24",
          "beginScheduleTs": "62546d24",
          "lastUpdatedTs": "625c2a70",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a4ce5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62546d24",
          "beginScheduleTs": "625c2a70",
          "lastUpdatedTs": "625c2a70",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "66jpZp1fizmUhBcv3d2Rd5rbRkXMWUzNpb79VkH3beyP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "26Aytt2kUQ8ZwWqz6kNuECJ3eiiWwCgXzoTGZq21wGYd",
      "vault": "HvhsLztizsCMAm98GokjM7xhYHM8npYgQ57zLemnR6Pq",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248f45d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0108ae10",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248f45d",
          "beginScheduleTs": "6248f45d",
          "lastUpdatedTs": "624a1914",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25c5ac",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248f45d",
          "beginScheduleTs": "624a1914",
          "lastUpdatedTs": "624a1914",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HvRK1sAS4soC9CuPijstSxpbr9eVpFxdzF6xCLbU714V",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FcR8mbTG2bov3tbyD4hhyvbHQG3K2iunqe5R8ccAR571",
      "vault": "5EeqdSH2M3aFiJYUwDTMyRuEsgREnMuLCWoBr5Nkkz5E",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b5a9c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "135d030a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b5a9c",
          "beginScheduleTs": "624b5a9c",
          "lastUpdatedTs": "6260c4ab",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "235f6d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b5a9c",
          "beginScheduleTs": "6260c4ab",
          "lastUpdatedTs": "6260c4ab",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CLtcM5BeAbDmYZ5E7sWUE1iDwRzPQFeUCktFx5nFP8HW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7cjvUY4bYeVQGiKz3PR5Fp4uV7sKpVkJ8ybZ26WnBEdD",
      "vault": "AYM4PWyoMmArz399mvGikBSEXxJNg7LYfrFjvdkxG7LL",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625f9ee1",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f9ee1",
          "beginScheduleTs": "625f9ee1",
          "lastUpdatedTs": "625f9ee1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f1b28",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f9ee1",
          "beginScheduleTs": "625f9ee1",
          "lastUpdatedTs": "625f9ee1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EyYcxPR2S7GTXZGDRF9PYVsnbApn5PZgwnH8eFqgSx4x",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2vEFz57FH2rFdbXPxWRX2i1ew8Uq7XUXRRph4R2PwzzG",
      "vault": "7FtvcTLGML4z5GgFzyVbrqjDhMoDGFGujUZPhXzMk3eD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624e8192",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "11d62daf",
        "accruedReward": "11d62daf",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624e8192",
          "beginScheduleTs": "624e8192",
          "lastUpdatedTs": "6254de8d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "203877",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624e8192",
          "beginScheduleTs": "6254de8d",
          "lastUpdatedTs": "6254de8d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "C4qWnY4pk4qUrEhFawKxUBrWEULfT2bJEFfXxDnHynFd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7hH7wNEVkw714QGBWtqUq2fJaaTmFrbNywDJssYtd7Ka",
      "vault": "FPKCqnhpcL1xfHwMFRyzY5MJsFMgTmmwCoWE3V1hAntf",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "625d8b3f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "6bb2bb40",
        "accruedReward": "6bb2bb40",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d8b3f",
          "beginScheduleTs": "625d8b3f",
          "lastUpdatedTs": "6267faa5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "112eca",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d8b3f",
          "beginScheduleTs": "6267faa5",
          "lastUpdatedTs": "6267faa5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BhtdaM1KShUpTpuJih5y9Hfz1mWnpuqtnyqih36qaF71",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BqN9wKo1pawU7SCBERn6vvVeMicXvCHcQmkwNqH9z3XX",
      "vault": "CP1jtXnYGSj5LCNNXGKJihL1pYsyGtufFHCjX56sftee",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625f65eb",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "041fcf2d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f65eb",
          "beginScheduleTs": "625f65eb",
          "lastUpdatedTs": "6263f58f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f541e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f65eb",
          "beginScheduleTs": "6263f58f",
          "lastUpdatedTs": "6263f58f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DRXzkyDEBfWGGB1tNvGddCkpJt95emqNcNV6ueNBiEWv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "58pe5bgM4guZmcMwvrrRXjif1q3RRJEtdYfL5WGE531w",
      "vault": "2swPNdADhhWCjXbsHF5LLhedHHabYEFkWNeQ14HBSq8V",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "625ae2fe",
      "cooldownEndsTs": "62649e3b",
      "rewardA": {
        "paidOutReward": "08cc55c3",
        "accruedReward": "08cca385",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8qwi9cA5rk5L1gm7jZD5CmEtSE27ExLVaEFkyVFQ6dop",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8BUDk7kq1LLhS1qwhojB45kRJizwuZ4H6vXgg8i4dYce",
      "vault": "E8kxj2tTNHfZ1msZUPaGEAM8Rmv96nmBrG1LZy6Ppp7F",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6266edbc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "38cf5eea",
        "accruedReward": "38d56af2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266edbc",
          "beginScheduleTs": "6266edbc",
          "lastUpdatedTs": "6266edbc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "07cc4d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266edbc",
          "beginScheduleTs": "6266edbc",
          "lastUpdatedTs": "6266edbc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BduE7L2cE7SdVymMEb6BSySz51dZjKsQi3NnPgQYCiKo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EfLGhTCRgaF259ragKetacEqTdSX8Jch8kKjPKYcqJRr",
      "vault": "FPDMZVGiGsFxoV2QzvU8nTdX5AVeCMWq1AKyd9uVXLQe",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6269acb5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1ebe",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269acb5",
          "beginScheduleTs": "6269acb5",
          "lastUpdatedTs": "6269acb5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "050d54",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269acb5",
          "beginScheduleTs": "6269acb5",
          "lastUpdatedTs": "6269acb5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5bgGLThB3pxKXwNTEVGP2tXTgY5co6C9ddaJ2CZzfZzC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3WitG5s324Zq8qD8Wmcct1n6CYs3viBoBHjRQtDjyYvq",
      "vault": "D75QmCfU57JX4w5733CSZGQUKmcjuB3tJQ9P49JfnFes",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62561b22",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "17f7a4d2",
        "accruedReward": "17f7ba85",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62561b22",
          "beginScheduleTs": "62561b22",
          "lastUpdatedTs": "62561b3a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "189ee7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62561b22",
          "beginScheduleTs": "62561b3a",
          "lastUpdatedTs": "62561b3a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6kuMTmd1tShxJZNHEJW8xq272ttLRSZTxFhuEshKiEwJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Fk85QY1ctApzARxhitHeDDLw8uz22Moynp5DsG3uXahU",
      "vault": "aP6BhLuEvsrKCJiJSnyC4fktMWmCNw4LQ3vw2gaHvkS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62478015",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3e03ea4a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478015",
          "beginScheduleTs": "62478015",
          "lastUpdatedTs": "6269cad4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2739f4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478015",
          "beginScheduleTs": "6269cad4",
          "lastUpdatedTs": "6269cad4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DtfTr8jqNXXgKhqd456fEmNePFQydrPTXHfKoEd7ApgH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D1ngXrkHhWL6yQeVfL8rLBG6XZKNCsMtB8BGvN9PXsW3",
      "vault": "3BPRyBEq3XqhwrdcvXYZ27UvE83ZcaCswf1YKxPcKqiz",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "08dbb6bf",
        "accruedReward": "08dbb6bf",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HFwA6xBssHW8dwh326mHBF36oeh72Wzn7SrXgGfyAfDe",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FSPKCwmN41HFpzbYCQSGaj649YnoDkHs3YwN7LfitTCq",
      "vault": "4m3yXw2ViBr69xwaJTpibyhNv45Hvh44wYdmdHCVTLkx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62534844",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "14c231d4",
        "accruedReward": "14c231d4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62534844",
          "beginScheduleTs": "62534844",
          "lastUpdatedTs": "626a3d57",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1b71c5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62534844",
          "beginScheduleTs": "626a3d57",
          "lastUpdatedTs": "626a3d57",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3vFyAf9tEsrciCjWYxH7bE9H2jyF1NTKDWVHhk5sceWc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3d5nnMvMjt3RUTtyJ9Qpjc6dw8KQTNnkEP7Zkknaqa7W",
      "vault": "DtozxvTTrEwiUPq4J6LYvwwZiahkaT4ZL16sdHGeRcea",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6255d68a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "29d3eb18",
        "accruedReward": "2d074b4a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255d68a",
          "beginScheduleTs": "6255d68a",
          "lastUpdatedTs": "626ae6b6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "18e37f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255d68a",
          "beginScheduleTs": "626ae6b6",
          "lastUpdatedTs": "626ae6b6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "L3GscZ4K2qvcXRQG7cBaYB138RCqgc2GJwqvDwa9HzV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7HNhQwygZX4omTrxdb61e7RATfJYNP4KMdQitrtjsXGc",
      "vault": "HKGkERAzJJCsEMtdp6zstrHShCuELxwoDK2GM1pZJYB3",
      "state": {
        "staked": {}
      },
      "gemsStaked": "07",
      "rarityPointsStaked": "07",
      "minStakingEndsTs": "624aa839",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1a38",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624aa839",
          "beginScheduleTs": "624aa839",
          "lastUpdatedTs": "624aa839",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2411d0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624aa839",
          "beginScheduleTs": "624aa839",
          "lastUpdatedTs": "624aa839",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8q3d3pL5jcgxyE6w9x48rAPgEsDJ2fQw3uni9ugBmNCa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7iYo4KEfDTzoTPq7X27Y6VodLiVzPAgRqNZEdjUBt5kB",
      "vault": "D19oq46CBFnb9mUt2Umay5hzCEPW6ThvD6f8FUW4EwPd",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "04f5e9a3",
        "accruedReward": "04f6bd39",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8dK7Wj1ed5XyBw95PTxRtCKDZG1JmCtQ8tauTeYAkWRs",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7hSgwxBs8a4Cc9F5AdCi9emze5nWCaVHTfr9VDmFyWwL",
      "vault": "HzEhHzMs1KG5rztetRBDRCXYUadW6thzhDUJCGaLYHci",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62489c86",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3de7b8c0",
        "accruedReward": "3de7d3e0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489c86",
          "beginScheduleTs": "62489c86",
          "lastUpdatedTs": "626ad6e4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "261d83",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489c86",
          "beginScheduleTs": "626ad6e4",
          "lastUpdatedTs": "626ad6e4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "egXcDJxZ89wswv2kxM73PVCnuh68puZdqwLzmusPC5p",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CmuQsB2vtNoebdKZTktM9C2A6N16qU7RBqm1RTYV9NsA",
      "vault": "ZXRLCHB7fBKwjUaBjjFRocHj3gNxEASCQZpJ1nBjif6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6251a0c3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "06739d95",
        "accruedReward": "06739d95",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251a0c3",
          "beginScheduleTs": "6251a0c3",
          "lastUpdatedTs": "6251a0c3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d1946",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251a0c3",
          "beginScheduleTs": "6251a0c3",
          "lastUpdatedTs": "6251a0c3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8vwxF9jnc9KXJHPHKJujMRoK5sJYYsGZCa2SsR2x4A2n",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5bq2rC8rkVKrq1c9ReYEKSZFGUcouVXzbDvBtkt7Hwbd",
      "vault": "Hbahg119Lwj17L8taxt3pTkgkFVDNqDyCw7eXz9oiKDi",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "7e6e6ba7",
        "accruedReward": "7e6f207f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HFQtnBbC4Nnd1YP9h6oN1MrgSgi8pNMoiBdRXjQsm4Ri",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8iFULCtSnT8oLuXccjYxNZVW4vTtpNitdZ7P29jBuhKG",
      "vault": "HHbbZr7a29Qb5E6HT7qmEZBqQuQWztWX1Gw8w67e3bAC",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0236e63a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "d2hNqEqzGgVWA2xTqUrkgzH1sFtHv9NUkGBUePmNBTn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8xEme73wp3fk8VmiL4eLUvoZmsSutEUemgyKWBg4Z33c",
      "vault": "4ggM7az36XxoD9vGumiNJaB9nqRHS8HouQiyGziJwZ4w",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625e12a0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e12a0",
          "beginScheduleTs": "625e12a0",
          "lastUpdatedTs": "625e12a0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "10a769",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e12a0",
          "beginScheduleTs": "625e12a0",
          "lastUpdatedTs": "625e12a0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GwWgxrbEfxPmv6mWGpbPXJ1HnVEz4AMa3YfdzF8Cprfm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Ata7NN49aVC96jaoDqLbJRMAaY9saeTknZF9YmdPbVAq",
      "vault": "AqyTB9XVa2QArEr6rTfvE1eUk51QNtPWMyeb23ywR1ea",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625cbe74",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "29c08b88",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cbe74",
          "beginScheduleTs": "625cbe74",
          "lastUpdatedTs": "62689036",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11fb95",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cbe74",
          "beginScheduleTs": "62689036",
          "lastUpdatedTs": "62689036",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9DhqJttabf7vEx2T2qPQgvu77wquQ2GZY3HSRDjLxMJE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Fqgd53Bg9GcDAppzcipZfRFr7dEWF7TsqEPNuVwc7u6Y",
      "vault": "3S4eqYjrNSt7cgvFmN5UVcNKryFYQrLgTCxmDYGbi9zN",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "11eb58af",
        "accruedReward": "1a87fae5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "93uejFyXccd3hBnfsuEevH85nDJQUVW5eV2RkdsV5VDR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "B5RhjgFAy9q8yb5LRk7bnK1SUQ7pvDTefetiz3vwrZ5H",
      "vault": "4E3KkFqkFQZFNWp7cRLrwH1Kzxig5aZKmWUEThWU1E1o",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "626ac517",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0468511a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ac517",
          "beginScheduleTs": "626ac517",
          "lastUpdatedTs": "626ac517",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "03f4f2",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ac517",
          "beginScheduleTs": "626ac517",
          "lastUpdatedTs": "626ac517",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Eou3useKipwMWa3xzqaGUoRVkXjKfPSkZo41tDDZcrp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2h3xeHnRq3786TaLuFMQAWxqbn6FWxU8mixdLeRz6Yqd",
      "vault": "EpwLxQ9CcRU3b34DdZxri3JkWV4ark6ZU1972A8hXEui",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62481e5e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1eff9da3",
        "accruedReward": "1eff9da3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62481e5e",
          "beginScheduleTs": "62481e5e",
          "lastUpdatedTs": "626a6686",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "269bab",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62481e5e",
          "beginScheduleTs": "626a6686",
          "lastUpdatedTs": "626a6686",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BaCUkpqtHLt1aVWUeFr5viiZ2VAH5iC8UnSdrzRPE7W2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "G1b5D4bxrQQRK3QaggC9UoRmuMbGjrsG6WRhV35cxNzi",
      "vault": "95qQtJaDofzziZ5DPfU5nhzA7zcz12nA4zfQj9eDDZh",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62545cf9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "077087ad",
        "accruedReward": "2a9def6c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62545cf9",
          "beginScheduleTs": "62545cf9",
          "lastUpdatedTs": "62603aee",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a5d10",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62545cf9",
          "beginScheduleTs": "62603aee",
          "lastUpdatedTs": "62603aee",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GADoVGShF6idDJkexhWCfhiMgii6fGMSDzbkue1pGUZd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EcZvo9CkAE66QXqutRepGnLsJDbTR8burUHw5xyjCfYn",
      "vault": "BKk6YegBGAD3dAKnw18rL7tCdddevtjFcjKwz5Nf3RGR",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62485b1a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62485b1a",
          "beginScheduleTs": "62485b1a",
          "lastUpdatedTs": "62485b1a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "265eef",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62485b1a",
          "beginScheduleTs": "62485b1a",
          "lastUpdatedTs": "62485b1a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7utUCNeQsi4AafoB4BrfE3Q3cXhfwxvKTqBAUPdXWaa2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8bxvsFzN4XuaTHXnhQhZNEKvxAdEreHucLwS53gL7JLK",
      "vault": "Acn5b5CtgFiiBH2qDk7djWvFtBLNrYLS8cQuK3zezo7p",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62489e73",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0af8be72",
        "accruedReward": "0af8be72",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489e73",
          "beginScheduleTs": "62489e73",
          "lastUpdatedTs": "6254c0b2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "261b96",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489e73",
          "beginScheduleTs": "6254c0b2",
          "lastUpdatedTs": "6254c0b2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AEzmECoj4t6WVasfjdjcATYsk1XpnR2kgnoA5eNgWQRe",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Cwu34TR3XusvsQeCcUnTAASRydmtGS5UCCwah6PgzyY5",
      "vault": "4HCmz6QDsetopeTbguGvzbuCWTGgcEABMroQfPRx6nxS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6263a869",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "024dad92",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263a869",
          "beginScheduleTs": "6263a869",
          "lastUpdatedTs": "6266348c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0b11a0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263a869",
          "beginScheduleTs": "6266348c",
          "lastUpdatedTs": "6266348c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DUrWRBqA8sStscXpMZqqKAayhFTg8SqxVvHHFqhdJL4P",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Dk8Didj3rtQDSK2hKSuieaw8N1iaA22B77UHRgy2SqkE",
      "vault": "HQ6A4FKpe8dMNYNHSQHs7MotKfZjtTJHh2SiXpHPYson",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0358348c",
        "accruedReward": "0358348c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GNGuWZzpCePeMF5sXxTuQ4sbnzqGskFWjybK8DBXNQBi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9iaDmbaGG8ThtzwEaZkTaCgDogGUkSFa4z9sRtwT3hL3",
      "vault": "GbvXnQtpd5simdRHcSbhp3b4F2pMFDEp2dEhNpW8KPS7",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625beb18",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0a2c9473",
        "accruedReward": "0a2c9473",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625beb18",
          "beginScheduleTs": "625beb18",
          "lastUpdatedTs": "6262ef0f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "12cef1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625beb18",
          "beginScheduleTs": "6262ef0f",
          "lastUpdatedTs": "6262ef0f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HzHUZrVarogMPo6HcMV9jLVxmcteQUDauWfpm2cKTjo1",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "G1NDfUqdJTzWUok6qhFLuKCLuDkvuCro4qywKMmQszvo",
      "vault": "9VKw7CtioQU3tBRwDMgZv8xsUKc7873xuA4YWA6cSHVP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62604341",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2c9ed23e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62604341",
          "beginScheduleTs": "62604341",
          "lastUpdatedTs": "62604341",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e76c8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62604341",
          "beginScheduleTs": "62604341",
          "lastUpdatedTs": "62604341",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EDnWDKvNSAJk8FbbAUkxYTFnCep8Z8TPFPx2vB88EAcD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HGk4BG9mQHWnX6GdUjS2gdskXUhQx1VzuHqnbdkKuwhP",
      "vault": "94hsGrmmoEytN38pX57DFYjLgf4NaxurGYFmaUvhffrk",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "93fGkSfoBv1483k3t626udJcGjZaRR3ZEWmfgy5v96LD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DTDRTLphhR6AVVdappTR5sBAJ1y659yHagd78J4F5SVv",
      "vault": "FnxTnbCn9KU1ni9mFyHnCViq4Zg4J92N5rQcJ7NJMpyX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624b1c0c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "26477677",
        "accruedReward": "26477677",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b1c0c",
          "beginScheduleTs": "624b1c0c",
          "lastUpdatedTs": "626046c2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "239dfd",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b1c0c",
          "beginScheduleTs": "626046c2",
          "lastUpdatedTs": "626046c2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "96miMFXjZCNjNnzyYXcpCjao1KrwBoVtHctBSSE58Hh2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8CYyhFFqKZaFymmsxXyxMRqssp3nEzMB1zxbVwf6nE4R",
      "vault": "BbbWzk2VVSp3EUm5f3S8YCpiyUYva9bkwWzW4WnjjZZ9",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8MqtAae9rbKsAatUBg4D9ND5Pom8TanPudjcF85QSAjr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EWcGqjMbyC6r9fWc5DdXYrdhCG6kaiUt2r2RPjBPKzJE",
      "vault": "FjyAZXWSrsnUYAJPLdyLtNRPP1eDcRhvCaq3433rX8G2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625f6629",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2ac872aa",
        "accruedReward": "2ac8868e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f6629",
          "beginScheduleTs": "625f6629",
          "lastUpdatedTs": "625f6629",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f53e0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f6629",
          "beginScheduleTs": "625f6629",
          "lastUpdatedTs": "625f6629",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2auovuX3qetY6bFW2FsSDmkRA8fuLfqdJZiJ7u1g795S",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4UF2VK7Kc6VXFBhQJCPUxvaysoHCN8W32NbmWEaNc9zE",
      "vault": "D3ALNCWifoApbuHqPaTt7bZi4b2dSdQGhwSReoqfTrn1",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EzyihJ6aMuqa5kRxFvuEv3dv6DCTz9yGxoSyRoBCLQWP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "H4GUEoKYWtSHo4h7kAntAN6WPdYAuFWopQHmLVrhYD3s",
      "vault": "6qEYwyr9NWTHgUSZLdwdJN4Fc37K8bNmohEpBdvPk9JX",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Bg7ski43rbswriTgSuVhPcc8ECMF1agyAnMSFA6RHSEb",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "C3irL6TWThLjxBzx13bJHDje1J6c9SuGspWkJJoKwQPV",
      "vault": "5eRT4yXbMUt1ojaY8BQ4HhUKJ5QV2BwEYoFFN2JVZTMn",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AEQNzbpTU8GDtbDKBcMknToPsLeaJwyjjuHL6qCc3PDh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "C8xMG9TFgyeZKQYPRbPznbsr5x5K1boWdfkzdpVn6JdX",
      "vault": "6A5Jvtx1Z6ddwcHqiLUgfuXJz254ydavFwcxFxwu2skG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6260b1d7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "07005414",
        "accruedReward": "0700fe12",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260b1d7",
          "beginScheduleTs": "6260b1d7",
          "lastUpdatedTs": "6260b1d7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e0832",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260b1d7",
          "beginScheduleTs": "6260b1d7",
          "lastUpdatedTs": "6260b1d7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DA6JQpfvMFE7ErDHKy5mCvg2zbzA9XxkEJBH4naBZWfN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "123dfdztzwcrqrhEHgdsmY7tp34WLvPEQrD32vsdXbem",
      "vault": "HjcthYx6QGaUFW8s83Kh5WRrBQg3vzNnuYgKh2nYHkYp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6263dd1b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "31f3e1e8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263dd1b",
          "beginScheduleTs": "6263dd1b",
          "lastUpdatedTs": "6263dd1b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0adcee",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263dd1b",
          "beginScheduleTs": "6263dd1b",
          "lastUpdatedTs": "6263dd1b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DWxBEBENWDCyXbkARYyHpw1cS1UjB46coFFaTdnJWLaX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "143f5YtRiUJGfei9g2voDQawdkzZFCpBqavsgNmUJQGx",
      "vault": "5fARq5vBth3sYhgEGKXNWF3rn3LETAHRihTdAfXLxVAV",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62615460",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "5cfc5186",
        "accruedReward": "5cfc8428",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62615460",
          "beginScheduleTs": "62615460",
          "lastUpdatedTs": "62615460",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0d65a9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62615460",
          "beginScheduleTs": "62615460",
          "lastUpdatedTs": "62615460",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "G2z5LZtAywZUCsN8s3rV13HqzcptrTZJ3MNitbGrQgPD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7eiRne8xFzggNafv24S5UkTwK2GfGG4Vn9LxYjoMkhsZ",
      "vault": "Hv5S9j3RBTk1wRNXhTqy7cz3rwBCz76dhuqzbjXAn4Xa",
      "state": {
        "staked": {}
      },
      "gemsStaked": "08",
      "rarityPointsStaked": "08",
      "minStakingEndsTs": "6269bbd1",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "011f3adc6f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269bbd1",
          "beginScheduleTs": "6269bbd1",
          "lastUpdatedTs": "6269bbd1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "04fe38",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269bbd1",
          "beginScheduleTs": "6269bbd1",
          "lastUpdatedTs": "6269bbd1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4aoLwzfGKLEtTfevinUiNj42ER3r8UV6ZM5Nyz3FXKsZ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5RfntQvEgUZLRZBeicZpy2kgPCv3gpunfqe1aoZa9fqz",
      "vault": "4XSCi9vQ522hCNi4zWSQBJVwNnoxQqWntXzs38BbvLYx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6267963d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "020d1d40",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267963d",
          "beginScheduleTs": "6267963d",
          "lastUpdatedTs": "6269daf9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0723cc",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267963d",
          "beginScheduleTs": "6269daf9",
          "lastUpdatedTs": "6269daf9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "JDHdQohWYv9fWayQxtarPBMRxU1pzc5zCuwTvUCZ7DUF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DhoVhzTCxBgcV5kprprkTobyDBphsYXLaqJRUvdpvqSF",
      "vault": "54ptjoqRT21xRnuXiBcUccULmQQzxfWWjynH78c81Eza",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "626a987e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "751c728a",
        "accruedReward": "7e398f03",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a987e",
          "beginScheduleTs": "626a987e",
          "lastUpdatedTs": "626a987e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "04218b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a987e",
          "beginScheduleTs": "626a987e",
          "lastUpdatedTs": "626a987e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GxHewHRUXoTxGH8cH7vBAxsh4QyeZdyqbZkx3yaCXjHh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Abb6WrahBVeKcFWyB1JWiUBojyLsxHaPG6uhMPpkpfgp",
      "vault": "2aU2F6wZUYg8yGTtdRjVEYgwQ1QzA8EUfsbzxjFE1KpA",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DLfWaEN3QoXu1GVpisrvpjxREQNNPH5mjuWcZuSQDMs2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EfUVv23t6YoX3DLew3DdovcgVE3dAzxqLrxGeazhU7ms",
      "vault": "Emb7MUPw3qUSpkXT6quYWrv97Tc4skSYTRXmXNL2uJcy",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62669548",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62669548",
          "beginScheduleTs": "62669548",
          "lastUpdatedTs": "62669548",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0824c1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62669548",
          "beginScheduleTs": "62669548",
          "lastUpdatedTs": "62669548",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5oAFNxL9hxqGizrmhLkKYKzUrhTBd1E8Bfnp3xhv5hoQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ChP8RD9Lp1nwVU1haCvgGEYDiyBySdpGS7wejc4riNdf",
      "vault": "J8ukAviLtzKpgA91YGh3nNrDvgUL5MPSdmAnGFizSaFs",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "625d28a4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "94bbfa14",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d28a4",
          "beginScheduleTs": "625d28a4",
          "lastUpdatedTs": "6268add0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "119165",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d28a4",
          "beginScheduleTs": "6268add0",
          "lastUpdatedTs": "6268add0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5NVxWxyPRJ2PAh24Jr7BAy7fN2dDv9PmvpjXgi8ujijt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2HvXiBThXwa6CDR1o2mL4WnudsJ3xeWDxGV296SSdMGH",
      "vault": "6kmmj4uksg2LxrcovFYGLMPuZRpFZN3GUdB7QUcEaYdY",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "626376a7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "52059e90",
        "accruedReward": "54a894a3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626376a7",
          "beginScheduleTs": "626376a7",
          "lastUpdatedTs": "626376a7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0b4362",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626376a7",
          "beginScheduleTs": "626376a7",
          "lastUpdatedTs": "626376a7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3UTbey4nZMKWo1yo2fbyRJ2Hd9yAg7fW4QXYmWU3prMW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8SsfujC4bNRDPDhfbRx1J3KziToB7kCieDMVXLVygV1Q",
      "vault": "A5Q9aN1BbSfN6WwVyQzJzpPnyTwATvbDfGPsXRURHsrL",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62478ddf",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "027ac3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478ddf",
          "beginScheduleTs": "62478ddf",
          "lastUpdatedTs": "62478f3e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "272c2a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478ddf",
          "beginScheduleTs": "62478f3e",
          "lastUpdatedTs": "62478f3e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6nPV1MeaAL3Ts5VNzYP35GG8kcPXp2mcd8z7qmpxvB3X",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7EkxqfdAz5XXkmJr4ukFhJARsh8zo7n9dzy9nKVojm6z",
      "vault": "EqJJ71iLCh11ZzStCDCDmM6WkPjoKpmwL9PZfSG6tmJt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6255a4b0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "38a55874",
        "accruedReward": "38a55874",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255a4b0",
          "beginScheduleTs": "6255a4b0",
          "lastUpdatedTs": "6268f9df",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "191559",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255a4b0",
          "beginScheduleTs": "6268f9df",
          "lastUpdatedTs": "6268f9df",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8yFejhTzRyF3DPt9opDwhkmhT2gvVGvcNug4BSiabDzw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BKhQvmM7NPdafQogjNW7Ue4UYTRdb6VpVv2NRcMwojYx",
      "vault": "DV4kfLcKAZ9MAUwHJofi1tf1JFPXAvhg6sQMZ7FtBenj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "0b",
      "rarityPointsStaked": "0b",
      "minStakingEndsTs": "62609e1a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0101162362",
        "accruedReward": "0101162362",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62609e1a",
          "beginScheduleTs": "62609e1a",
          "lastUpdatedTs": "626723ed",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e1bef",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62609e1a",
          "beginScheduleTs": "626723ed",
          "lastUpdatedTs": "626723ed",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "H26ABk5yFS8Vqob17Ynaej2UjTsLvrg8ZnMgw61rN64t",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6kYgjyCcNTh2U6D5E71uDtPozkfBszCvPTJSZvBaYMUE",
      "vault": "CX694PrTGcWPLKBayXEVdXHC9d9V56uT48Aq4J1qvXqK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6254f11e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2b3377c5",
        "accruedReward": "2b3377c5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254f11e",
          "beginScheduleTs": "6254f11e",
          "lastUpdatedTs": "625dcfab",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "19c8eb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254f11e",
          "beginScheduleTs": "625dcfab",
          "lastUpdatedTs": "625dcfab",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2oHjaAsQQHL378DvvV11nPhBTyBYk6p2AXzrUXTtNq6e",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FNZTkwQpzqN5LFo3prREidR3EXc12sPJVEAjh2h9cHoS",
      "vault": "bReTAxa9VGwGmyXRaH1YpzDNQgkdFe2CEb8rdLKAene",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6259ffcf",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "10853cfe",
        "accruedReward": "10853cfe",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259ffcf",
          "beginScheduleTs": "6259ffcf",
          "lastUpdatedTs": "626701e9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14ba3a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259ffcf",
          "beginScheduleTs": "626701e9",
          "lastUpdatedTs": "626701e9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EyjfJHRWXXvU5ZTuvzghxTB6k75k75KDEZopY9C5fWEk",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GVK1nJpSrszAfF6orBXmRezhBcX3GjsL9HnhNBwEgnoV",
      "vault": "DAMr3DZz9yKKJtcSn1NQMpnUT3ZrVe6nZic6RHtruuzz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6269bd76",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "ca11c6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269bd76",
          "beginScheduleTs": "6269bd76",
          "lastUpdatedTs": "626a9cef",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "04fc93",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269bd76",
          "beginScheduleTs": "626a9cef",
          "lastUpdatedTs": "626a9cef",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Ap7oLQ2ML36EtQnoxbYmtavYwZEVZ6NNASNgpYHdARLD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Eawici7sUC72vxsB8ZaLvZT1UTeiGRAfm55jmjozMmzF",
      "vault": "7q5DSCmU5c3wowq2bMSz2Edh7gJEd3aZPovdF9oRTfAm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247e345",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247e345",
          "beginScheduleTs": "6247e345",
          "lastUpdatedTs": "6247e345",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26d6c4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247e345",
          "beginScheduleTs": "6247e345",
          "lastUpdatedTs": "6247e345",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2o3sPch4CXTUMC3ABihKqDw8u7ivqgPnQVJT8PEFoS5R",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6Tu1xd8dpYS8zFQqKZ5egLUrkJJ8SL7MLjjtqoYeyJ53",
      "vault": "3akEqTv7gCYx8dNoED3ofMgvTdU5b3D4VoN5WbLdtDME",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "624793d9",
      "cooldownEndsTs": "625590a2",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "194baae7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Dow1gVfNzxbhByznA3Sq5rU8UxkwPx1ArpXAny7tVqXY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5VJTivR2MpPZTCv491evpEaYE9SrV7xx6rgYxKasto23",
      "vault": "9HSEQNFLCoJpzKZW3pArjN2N3h2Ep2n4rJjhE4xYprYp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624a3bba",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a3bba",
          "beginScheduleTs": "624a3bba",
          "lastUpdatedTs": "624a3bba",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "247e4f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a3bba",
          "beginScheduleTs": "624a3bba",
          "lastUpdatedTs": "624a3bba",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6HPh1nAv9Ybv9dW66kUGyA2iL9wwRjNg9R9ajKG69jvv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AVsyuXLQRWCUGQ9L3QBc7vKQtEQduZQK6uhqfhbxGCwY",
      "vault": "BVFwimAthqAeKyKyXiKdYYucpRoxi1UwZUCDtVKoYAct",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "07989764",
        "accruedReward": "07989764",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Av64zbcMsi4rMhFPLRpdC3SxjdkaBuTuavt8ScPmVJRH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DR4ebx6LeDTPKW9kixbucwLHQgKsdDox9bPChMBeW8Wh",
      "vault": "3iaw3TePxcmp9jH6EC1NZvz8tSnLTPDaUSiL6etML6dn",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247c31e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1c6dcd91",
        "accruedReward": "1c6dcd91",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c31e",
          "beginScheduleTs": "6247c31e",
          "lastUpdatedTs": "626733c9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26f6eb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c31e",
          "beginScheduleTs": "626733c9",
          "lastUpdatedTs": "626733c9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BjoaXpUSy59ERGfTweqMu3c3hmLNiTi8XFQBLrkd8L1M",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3ugk4WtNnDry9meK5NtK4q7m3T7D3FRWyRWYhPYR9nxT",
      "vault": "ChgsbnnDe9aa7ozv69z1r1rJs2wtcL5eNNGgyeitpQdA",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62534ac7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3311c405",
        "accruedReward": "3311c405",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62534ac7",
          "beginScheduleTs": "62534ac7",
          "lastUpdatedTs": "6265e0ba",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1b6f42",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62534ac7",
          "beginScheduleTs": "6265e0ba",
          "lastUpdatedTs": "6265e0ba",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6jjHxRrXDTusPvenjhYBQ4JL9js5c4ovmFNKbkzvhnue",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "J1mQr7MWvj3zWPNjs9aFtSYB5d1XHXt3wvEwFBNhUUkt",
      "vault": "FMTSx4YtJ7RuzsKuAwovw9qsgXGWLKKNnX3FrPK9Qot4",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6262c57b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "285e7051",
        "accruedReward": "317520e8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262c57b",
          "beginScheduleTs": "6262c57b",
          "lastUpdatedTs": "6262c57b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0bf48e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262c57b",
          "beginScheduleTs": "6262c57b",
          "lastUpdatedTs": "6262c57b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "B2dMJZjV1b832yZ641VQ17vmPUEMoQvYCnpz59QyxTmE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9jFhmNZPgBkzTHBUv1iuEbiFKPwWs8aojJutSarisqwK",
      "vault": "GwWCKpJRyyFCVyQ2YgsxAXfuiYWj9fj1Gyz1KFRddiQK",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ACCkETrpzt3WLMGVLtW69gYqZydAsaZVn9QgrS5fTqYo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4mubiZe92LxrUrvszY3TghAFKBm4XA9XZnptRyfYpJ9R",
      "vault": "2tgs3ukiFwryTmU5NE2kdAVs74JdwAtGxVF3BQ9VCQfg",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6257315f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2fa851f7",
        "accruedReward": "2fa851f7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257315f",
          "beginScheduleTs": "6257315f",
          "lastUpdatedTs": "62668e91",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1788aa",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257315f",
          "beginScheduleTs": "62668e91",
          "lastUpdatedTs": "62668e91",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5BhJxf5QM6Xn7pUD29A5BByymg8mocDAc3gmgxN9pBsf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8CVZhktC4DefGXrESDHag8tHhph2v5g2jDznAxD7w8d8",
      "vault": "5uJPDS4MWgbXpUuMGuewy8PLjdM2Qxmqjwtv3rS88qVp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6263da03",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0d5f8827",
        "accruedReward": "0d5f8827",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263da03",
          "beginScheduleTs": "6263da03",
          "lastUpdatedTs": "6263da03",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0ae006",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263da03",
          "beginScheduleTs": "6263da03",
          "lastUpdatedTs": "6263da03",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6T8A1koaBXfT5DJRkuJfZ9wVRFjoqF6HLAP5N59bbHu4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "51nFw5VWrYEwF9W8HaeRxp5uc8BcAwg3KcJz4CWRY88i",
      "vault": "4nouqwSieZ8StqkM6VVj29BQ31YDNxdKyiajz1YMb8QC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "625f6274",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "464895ae",
        "accruedReward": "619be360",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f6274",
          "beginScheduleTs": "625f6274",
          "lastUpdatedTs": "62661a19",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f5795",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f6274",
          "beginScheduleTs": "62661a19",
          "lastUpdatedTs": "62661a19",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7H7wzcM9xQir37JDPwbxP9BqRmUuAvG9AgKdCy1GDtJa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ByAed4ySXvuokDLRGEUoPAovoP5BzZrZT5LiUq6hsudG",
      "vault": "3vh5n5K4cq5vAjHL2daaBCx8aN6JKT2Eq6eTMMmodGo4",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62522f8b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0987cac4",
        "accruedReward": "0987cac4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62522f8b",
          "beginScheduleTs": "62522f8b",
          "lastUpdatedTs": "62522f8b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c8a7e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62522f8b",
          "beginScheduleTs": "62522f8b",
          "lastUpdatedTs": "62522f8b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DmZ4Bwxwe6GpyaiDq8aKgRtHhQMYSRHxyydybDj7XGtK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2ggHQf3JTNZop1KmtuYYMUMgUvdiWbpesA4D5MiTySeH",
      "vault": "CXLmNcvHffWwxhCGhR9Z92Vocpp6Jde9AvcvkrmrhYcz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "0b",
      "rarityPointsStaked": "0b",
      "minStakingEndsTs": "625788ef",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "8049ec2f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625788ef",
          "beginScheduleTs": "625788ef",
          "lastUpdatedTs": "62646ecd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17311a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625788ef",
          "beginScheduleTs": "62646ecd",
          "lastUpdatedTs": "62646ecd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HcfVqLQxYH7NgLxMMZwBTDnyG6gM4pbtpFaM12pS46do",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DSHNHMZUhAJbLANJa9CuWG9RGhPQT1PvTcoZ3CwG89zD",
      "vault": "6RFk7xQnbB9os2oZmiZGLpojy2N8rfFh1x1wWJmV2jbg",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "dcf025",
        "accruedReward": "dcf025",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AbnWSNWoqMNtnpMxRTGk4CGoutRLbNpj1NX86PeeeHu3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DS2WmwfUaUYMzBsThmGPUack92t73Cvr6maxzsRZpecx",
      "vault": "FZZb4K83jg1aHxzxYJCpVrcNTamxPmGmerrWV759NW1",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6265ece7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "5ef1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265ece7",
          "beginScheduleTs": "6265ece7",
          "lastUpdatedTs": "6265ece7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08cd22",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265ece7",
          "beginScheduleTs": "6265ece7",
          "lastUpdatedTs": "6265ece7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EBcTQU2b86PkoFaHoee9tP6tCVTHTsv3SBaa58yi9h9S",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "H9nqeFXCowDRHpxchTUJG4szopKMsMRYrbPi6yyRDE7p",
      "vault": "84zmnwQiJiNrm8TddgF3Pt1KSD6c3dWNGN24Jng6fum1",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "G7xMBhKfPBerxWNqi4ZkTRKQV7hprRpZa4rQS9Ta8GS8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D2GQMMn1wodRfXLMxsjhY6vd9FiyCnn9BRp2CfMDBKrv",
      "vault": "F6qYawewRi2iAEXY7W6wUiHDXgiKE7iCc6DYTyuguS2Y",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624769dd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3d9ee1dc",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624769dd",
          "beginScheduleTs": "624769dd",
          "lastUpdatedTs": "62697cbe",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27502c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624769dd",
          "beginScheduleTs": "62697cbe",
          "lastUpdatedTs": "62697cbe",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HWbAmdCzHiGE9nNEUmkixRpuKPEhEzDR1NxtMSsPCNni",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DAacE2aBRqfBxLHqnLgcddf746NRuLcweyAzaSk3jpe2",
      "vault": "6M1Zeo7CkYYCxQRwpzcG8pF9AihvGZN6q63Q3R6QkxNF",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9fF2yL1GF3Qu3AMx1e2D9nrXTmZ1YsFsrxfNjDHjDm7x",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3oHJFH7GKuwywsL5L8Gu9oxiMKBYecLWF2ha9TDsPt9c",
      "vault": "EtxMrGV58vQqTYZy1ds1SunP7huJZndcaxNcTPk9dgw4",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "19e4de",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3hVHdCYX4EBEkuD4uwha3ycVPXd3EFntDRJDk7QMMFBX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Bdwn8N52jTevmVRXZYQiSCUGHwGKchAvEh71pwVsnqNv",
      "vault": "FEjyAfoZhefqJr3mPStQJCCxKzoyacUC8cwubz96zidp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247962d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "381e34d6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247962d",
          "beginScheduleTs": "6247962d",
          "lastUpdatedTs": "62669e1d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2723dc",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247962d",
          "beginScheduleTs": "62669e1d",
          "lastUpdatedTs": "62669e1d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AEFs6RYns3UzCPubaJDYgj2bcEhmd91MNpaDzNhTfDps",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E326ojgQ5uJdekTXXzSUZbEaETGsiYDQToyJ3YT1KZVS",
      "vault": "ANBQXgN3UWwhYHhKBBxAfnDmQMA9kik62wvjjwaE2ZrP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625e278a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "264038cf",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e278a",
          "beginScheduleTs": "625e278a",
          "lastUpdatedTs": "626384b6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "10927f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e278a",
          "beginScheduleTs": "626384b6",
          "lastUpdatedTs": "626384b6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FWNTZtqXT1skAqvqaxTFBoUGV1PbSwiLrdGpPJJ2MyvC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5PdqHf5QK7JvPmiJbG6HGfsmXoeLhg5tdpBgefwBB71Z",
      "vault": "H8f5cMu6yW9GdeGGWTAofjLLk1QpTcMLMzACPg2D8Uph",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6263f6d8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2ed11456",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263f6d8",
          "beginScheduleTs": "6263f6d8",
          "lastUpdatedTs": "6263f6d8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0ac331",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263f6d8",
          "beginScheduleTs": "6263f6d8",
          "lastUpdatedTs": "6263f6d8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4t3sXvdDjLAKnaoZfZunViUyG9yEmH9iY3K29rP3sxGf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5LNMopLNzcjgQTVAiMAMafsNmaDA8pusz2T2QFsjakbe",
      "vault": "5L4taSqjtyBH8BMbGwy1E6AsiXA3HeSXcWZpxRyK3cmu",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62601c95",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62601c95",
          "beginScheduleTs": "62601c95",
          "lastUpdatedTs": "62601c95",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0e9d74",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62601c95",
          "beginScheduleTs": "62601c95",
          "lastUpdatedTs": "62601c95",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DTau5KnSZT65RbF5AhjvZzCRwsaA311pt1peQ81YRumo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D12SX19iaMCaca96WpGWytPUM4NGbfmLyFWLf9mubDic",
      "vault": "J2fGa5qUcExD2L6tYiDrwVoJbBkbnZbVxv3BEmVKTENy",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AzwsrykypHiL9TesNDkvbDZkntFw2sSFpeiFwm8YpK9T",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HAjxDVUn88cXf4W66KEzVJn7nCHT996iLrAonniuPJB9",
      "vault": "BP8WCk3DVJTFo88RYq85LiTKofeK7ra1F572N7tRhHqW",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62485bfc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62485bfc",
          "beginScheduleTs": "62485bfc",
          "lastUpdatedTs": "62485bfc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "265e0d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62485bfc",
          "beginScheduleTs": "62485bfc",
          "lastUpdatedTs": "62485bfc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4Y4WXAyVoodX3GknzwtNxx8Vm8hGaVbHeHvSfJ5FYxJD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9HrBRBmFC4gtsugLTBZEta3hs94j5hPpsrYRCLfhwcAW",
      "vault": "64nXdV3jjFtGV8mQTZGU5ioaWukHQcwD2bXZoYJfRL9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248f36c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248f36c",
          "beginScheduleTs": "6248f36c",
          "lastUpdatedTs": "6248f36c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25c69d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248f36c",
          "beginScheduleTs": "6248f36c",
          "lastUpdatedTs": "6248f36c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4h8tTpcsJaHSsMciMLrkUBABg9r5m6QtPDk2nPRBhcYe",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ELarFs2bEi57DE5yzzfuLDM4TYDnEamfZqNT5SPQeM21",
      "vault": "GnCq9T273ajeWxUVLzULJGE2h2UvYgCodSbhWJ5Edcxr",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624f42dd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624f42dd",
          "beginScheduleTs": "624f42dd",
          "lastUpdatedTs": "624f42dd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1f772c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624f42dd",
          "beginScheduleTs": "624f42dd",
          "lastUpdatedTs": "624f42dd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6yKEgXiaUoLvNEECSPg8vyLQq3dDzWZ9642jnkAydXBV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Fae1MPHqBEJ3kK7Kp1AfhJpzzbZsSvBPTa9CaAhm7ruz",
      "vault": "F6gK5xFTPGkJuuXxe6DRFXHwSPbUZZ4q5P8TGeAyybi9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b3c25",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b3c25",
          "beginScheduleTs": "624b3c25",
          "lastUpdatedTs": "624b3c25",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "237de4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b3c25",
          "beginScheduleTs": "624b3c25",
          "lastUpdatedTs": "624b3c25",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2ZokomvUQQTDXt1tQ3H94V5YwPMrgjf774NqQMsEs2e2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FzgLnMR1XzvuewFT44JQcoPiWu5Lrj4oMxUz5vgHv5fA",
      "vault": "6y5cc4bR1QuKNyydsWaBv3QLWF6GrZyV4ZfcHGb3B3eG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626a53e1",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a53e1",
          "beginScheduleTs": "626a53e1",
          "lastUpdatedTs": "626a53e1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "046628",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a53e1",
          "beginScheduleTs": "626a53e1",
          "lastUpdatedTs": "626a53e1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4CB5E9JA5JD6bP1ng1yKmCnnPYkQzobVdfVgZHKoA7as",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2vy5DSnjT2B3XMH6SMugnqVPYSNHnb6h9gxa1kmeZ8nD",
      "vault": "CgtAMBRcNRXQHuHhRct7Nn2pSGwvSscoRrqtbYmXnfu6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247b070",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247b070",
          "beginScheduleTs": "6247b070",
          "lastUpdatedTs": "6247b070",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "270999",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247b070",
          "beginScheduleTs": "6247b070",
          "lastUpdatedTs": "6247b070",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Bo9KrtXfJRvoK9qDhryhSFffGiTRQT1YSBvABjoeLZmM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4TKJbGqxwNaSJUAR91CA3xBBuTQ5PesAnuezPrpcKWCb",
      "vault": "9qzioy21V1jeHTFNRAwi8GxriyShJeMtJN2tgeTiWZXG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6261a439",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "6b966590",
        "accruedReward": "6b966590",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261a439",
          "beginScheduleTs": "6261a439",
          "lastUpdatedTs": "626a08cb",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0d15d0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261a439",
          "beginScheduleTs": "626a08cb",
          "lastUpdatedTs": "626a08cb",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "YjokxN9Upsr7mFgCaZv5JuK9KnuJqttmTt2CWYYuh1D",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Db9WHKuJrVnXLgcXMZYtmnwbeehPPn9E4nuiwmSJXXpp",
      "vault": "8ph1g8jcnYTEytJZQa8tptgX7a4EdZJJrosV83kqDqzP",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Cptz4Nn5mXHkMqEJRKqBtbn7z3swsYUUEK1eSAx7FSFW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CHPLks1fR1egupWv6BPZTC2Mokr7n2Jt4AyEKd4wvkqL",
      "vault": "8WfbA9wcTvo2gbYwM55XbsZks3ULsXMqQESQL3tWUz2",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BGLRvv6FMzz5fYC3NyXj9o739m2crpU9EqG4jckXcae4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "96mUvMyLgzTG5cZWzd75WZHysNSvuncuRGA1xbsqSyJf",
      "vault": "FHzdAesg8RqH6xgCbFutUPBqKMP2Y9XzHpwrvDqsefrC",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9sK1no8WYQ12EJRCfwdmGrE5jFVmoa5XK4RrXZXzAeda",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "C6Sry1nwM8zbEUguKDmGehMk7jmXdgVtTc54RZmAg8mp",
      "vault": "FpqWetjdsSrL9UxWhT9UPPaUU2ww4qpGyS4bcURjGvLJ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5mrsFxCx5WVrTKFrHKLdQ7hV5XDQU3MrAzVnmaQ4Enwx",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HF8r9ufhFRTLPPZz43GKtaM1R8o8Vwavx4voeukRNYBR",
      "vault": "GUzXd696mrAudGBnuRaUY49fPcw2yk6djAstb1DbFwVv",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1abce8b4",
        "accruedReward": "1abe3912",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7Mb7Yc4xtt95gJSYXYTg8uSoSwZYEBm3n8DWKjaGmhcX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Fbe271LUkJCrk9CryKXoz5sbLSBszRBqNr3fTsYriE9N",
      "vault": "4GPzzkmT16chMNHqCvB8Y22RgBeHzoJn8aPWRK6DV6bq",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476afb",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "72d5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476afb",
          "beginScheduleTs": "62476afb",
          "lastUpdatedTs": "62476b7a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274f0e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476afb",
          "beginScheduleTs": "62476b7a",
          "lastUpdatedTs": "62476b7a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6PwVtB9Xq5osWFiUTVBgzrLHTFaz47B95GQowkBqzTz1",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6hsKsnuXm6tMSytVk7wfQ9VKC4jmBKnSexGiAed9pyR",
      "vault": "8cfLcLsankYwazK8HXrEC1rawUP2LAdukuXzzT1EtQmz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625dff66",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "67fb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625dff66",
          "beginScheduleTs": "625dff66",
          "lastUpdatedTs": "625dff95",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "10baa3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625dff66",
          "beginScheduleTs": "625dff95",
          "lastUpdatedTs": "625dff95",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DprZjrQE5qDfFxx1od7fZYEYK7xj5Z8gwdLbmCQ3T3U9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9mjY8atnCE1rRrYYzp2rYYrkdSaahBjkhi1VqXojvkD8",
      "vault": "79743sQvvLYV2eKPniR9W4rwNcFiEtvmX9ZiLetShkUG",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1c25fdca",
        "accruedReward": "1c266795",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DJ4aXVHTBFGUKfVUxjguPB1FZFgSvdhEj3NVtv4oKxYg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "13RJow8o6jouy6hiZYGekgjWecBvfu2iFeruZMsnZm8n",
      "vault": "HpJ8cnQQyo1mqFpkdshgJ6TpKUarkZeuhLhASreBpAvK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6256e921",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "11989b40",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256e921",
          "beginScheduleTs": "6256e921",
          "lastUpdatedTs": "626a5edd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17d0e8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256e921",
          "beginScheduleTs": "626a5edd",
          "lastUpdatedTs": "626a5edd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FqEma7jQzSiZLo3P996G7tJVmM3u4VaAh9FtE7riQEBN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CAp9dMPQY33AMauVxDhWY4miQdwpK4JTDPbmuHCbyjqo",
      "vault": "5fzkE1cBwhPh4jxG14G729cChjqULzfTsdtb84UmDLuB",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "626ace9a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "21a7e1de",
        "accruedReward": "21a7e1de",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ace9a",
          "beginScheduleTs": "626ace9a",
          "lastUpdatedTs": "626ace9a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "03eb6f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ace9a",
          "beginScheduleTs": "626ace9a",
          "lastUpdatedTs": "626ace9a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "66pTT5BWgWkWGGZHXaNFunLcRzy2mTG6SuHVjnkrZf7N",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5xcrNX37Rn3uAi7whg64AMoTfk7U25J5t5KyKeYpu4Gw",
      "vault": "5SAdMzwoP97CTbMt8ZHUDEP9DBNCSZj4TBRdi5JqzcL1",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62622c6a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62622c6a",
          "beginScheduleTs": "62622c6a",
          "lastUpdatedTs": "62622c6a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0c8d9f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62622c6a",
          "beginScheduleTs": "62622c6a",
          "lastUpdatedTs": "62622c6a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "G4tRCcxYGEri1zRtsfTYUMfZtUnLatERjPYRBbEuGJz4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BTvQ8EoPcf5QLFztdiQoUCqM5xwwVDv4DfXkpwRSHTP2",
      "vault": "5f92EKYy8ffbD13xWjoUyCDf7NNoGqffs4esTUGjmPua",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62492da1",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2f9a9653",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62492da1",
          "beginScheduleTs": "62492da1",
          "lastUpdatedTs": "62638053",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "258c68",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62492da1",
          "beginScheduleTs": "62638053",
          "lastUpdatedTs": "62638053",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Jkh8DLSTEyDb5oaLv8UiCnhCXehrqJhKVpWHASRudBj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2BLucji4k53E2f7j3nENKcMXq2hzXU8RjEA2VtNQwLP6",
      "vault": "CEWrBRJTKwvrqZGDiLqnN3Pw3YVpNjWssrMV46vm71fu",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b4f36",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1538e368",
        "accruedReward": "1538e368",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b4f36",
          "beginScheduleTs": "624b4f36",
          "lastUpdatedTs": "6262c78d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "236ad3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b4f36",
          "beginScheduleTs": "6262c78d",
          "lastUpdatedTs": "6262c78d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EzHnvdZN4DH4DdMDgoYhAytX8oUW8HzcwP8ZccX9dZPf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8yPkhuCXHj6eXUaHtizEbsj9GRcUSCGzwi2yuyhee2xi",
      "vault": "HcXyxx9g5cWbgAf8Mzja3utPN5a1AsGJckPYhJ5BfKAJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6252f755",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1931ffe3",
        "accruedReward": "2f4dc289",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252f755",
          "beginScheduleTs": "6252f755",
          "lastUpdatedTs": "625cb3e2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1bc2b4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252f755",
          "beginScheduleTs": "625cb3e2",
          "lastUpdatedTs": "625cb3e2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5fN83R43VUvjWSHmVvKJDkXCk7ykqBvu3GjyHp1zApSW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2tn4ToRyMT8Mnh29AHcrixZr8GmDBUjBcSqpYdEuWoAU",
      "vault": "EGWU8oDH52Ue5LN5PtU1SLrxoi4kgckv1i9vttmYyx38",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624784a7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "17dec62a",
        "accruedReward": "17dec62a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624784a7",
          "beginScheduleTs": "624784a7",
          "lastUpdatedTs": "6261eaae",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "273562",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624784a7",
          "beginScheduleTs": "6261eaae",
          "lastUpdatedTs": "6261eaae",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3QWeLS4kKS3UVhK9yLHr58mdwi9pkGzLcKB1UMnf8fqu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6pCs22raxzxVRqTehg46bVP3dopJ38XZQCS2zbKPKC45",
      "vault": "DjovUh3b2xrYr3gUG9JfV3R4f4hCP71a1oJ2fnfvJyy",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "62477f4c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "bee56322",
        "accruedReward": "bee56322",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477f4c",
          "beginScheduleTs": "62477f4c",
          "lastUpdatedTs": "626aaee7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "273abd",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477f4c",
          "beginScheduleTs": "626aaee7",
          "lastUpdatedTs": "626aaee7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2FJ33S63kckUqEodQw5wxbgnXn5zy9vDMVBfsydKEDcX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5qJovqiG1j8tdq5S42Jvcx5hG4AT35PN57TPXiGSKiiM",
      "vault": "8qYmpVeaxDAFhf7soqiCvYEo88hvzpbXEwqZS9VBUE7X",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8b9ATSHudTCGaide8fpyPFdv6TJCs8C7qjYrZnvvPUM5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Cchur9GVUrQSmdkWvzsbS1QcfwJojfrj1fkvrKwS4z3C",
      "vault": "GuVdSw5Sm9mRS7YpGm72koQuAjjRapdDMDHVLFJ2bsc7",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AYqzsgnB5yWYPnbhKCUip6PLG5oLxVoU4K1ZuCb7bZFd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7pEDkqyiFbokoMPcc6Sf9ZEXkNeRo9WysfusFNKvTA4",
      "vault": "AK5WxhM8JGa3MGMUWNcDv2Zycfgmq95fuCMsKfhDvF3V",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6266905c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "177631e6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266905c",
          "beginScheduleTs": "6266905c",
          "lastUpdatedTs": "6266905c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0829ad",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266905c",
          "beginScheduleTs": "6266905c",
          "lastUpdatedTs": "6266905c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "235U9k8Hpx4mpkLLEpyB8CgoDwGdsL1FsFLUoDWXz4gx",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "71yPTXQqZL88tNM7B3RL181qtBa7ptUvMwNv2roMb8tU",
      "vault": "HL62St92As3AkB8MSe6TgRWeaVGgeb7wt4hTk3D6LfY8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62485b25",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "17a262a7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62485b25",
          "beginScheduleTs": "62485b25",
          "lastUpdatedTs": "62556cc4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "265ee4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62485b25",
          "beginScheduleTs": "62556cc4",
          "lastUpdatedTs": "62556cc4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "H9Wrgv8qHWFuTRsjP7B2P79yaseM5pC5LkLBgvFA2PHX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "65yvFXEiDd3UFsFC759WC3krcp4Tda6gpV96XJJSxYmB",
      "vault": "7qaWH2BpmQwpiUmTzCxndyQAqrYCLpwRQqFxiVyyNzTx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62659cc2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3d2e8db1",
        "accruedReward": "3d2e8db1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62659cc2",
          "beginScheduleTs": "62659cc2",
          "lastUpdatedTs": "626963f9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "091d47",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62659cc2",
          "beginScheduleTs": "626963f9",
          "lastUpdatedTs": "626963f9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EpADULYges8GLXBNQaGa4JDnMWV8HP3kZ8ruPUyrepMF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EEjA5dDcXF88kdvdewWpNvRZnRQJSVWeamtoHirkP3Vu",
      "vault": "7M7Zq3cGbaEuNBHzQ4owiGogxaHmFBMUxXwYxUnqQwxx",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GjprxxNPoq6QvooRiuCiip2TZ4Em68AkidKUM79WGZ28",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "J4ZYnfTo7XcUfzM4QrDE8JFR7CZjCfm7XWuewo8FATGr",
      "vault": "DTKF5F7EwQKmNfTUGPKU4aBd7sR9HJV3DL5oSpgm8YfP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6257d7d3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1b4577db",
        "accruedReward": "367c2a3d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257d7d3",
          "beginScheduleTs": "6257d7d3",
          "lastUpdatedTs": "6266e3cc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "16e236",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257d7d3",
          "beginScheduleTs": "6266e3cc",
          "lastUpdatedTs": "6266e3cc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DARcud5zhS1Wyws966ZmRYFovCdHW5jYwuUy6ECt3uNy",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EVpaizPzppK4KcBv9ZDPf5HUfc1uPGgigkqgUf2ovTpV",
      "vault": "7kwtwCf991KYy76Hstps6DKLSQvZ7c5GrFkVW5D4asLp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62658203",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3602fc1b",
        "accruedReward": "51935bda",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62658203",
          "beginScheduleTs": "62658203",
          "lastUpdatedTs": "62658203",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "093806",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62658203",
          "beginScheduleTs": "62658203",
          "lastUpdatedTs": "62658203",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FTtQm9jrPXdMAGzMgVX8G2VzbfWTKWumzcXccGXE6D9c",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2u5DM46dzFwucUQj9gcwpVzbq6WJKVzqti8aYph4QYHV",
      "vault": "BANsVtgYDx2rQZzBGPgUZCQPBMYi9icYpnFo7Bi6g3bU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625dbeaa",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1bedfd4f",
        "accruedReward": "1bee217a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625dbeaa",
          "beginScheduleTs": "625dbeaa",
          "lastUpdatedTs": "625dbebe",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "10fb5f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625dbeaa",
          "beginScheduleTs": "625dbebe",
          "lastUpdatedTs": "625dbebe",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "69NbNdM4fPbNgjzGuLTLV5uycDNkc1kDZNh5SiASJSev",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BrnLGAY1mawGrZk9VLa91QAb4uz3p4EbevuHuwANoTAn",
      "vault": "DspZ4nhCnLAj6VZhLjx9LDkWe1yLLhGZ2cFJkkC7k4XE",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EcYceywWf7wPKT9vc2BQjEbkKCyD5EByYHnDT8Xphq8n",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4DniXgBUHgdKfaivqKbsdgZBuZdgrUb1EX99yeWycWsB",
      "vault": "Du9MvnRtqzCePo8KDsZNbe3rCN4avQaasvfWL7GDanQd",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6252f50a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "14d273fd",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252f50a",
          "beginScheduleTs": "6252f50a",
          "lastUpdatedTs": "6252f50a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1bc4ff",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252f50a",
          "beginScheduleTs": "6252f50a",
          "lastUpdatedTs": "6252f50a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HN8a4rXNRiYMAs4VQTZxCrQ6G6tKL6NA3zNgXC6VLubp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8yRGSqX7tLjuvc5idzJh73WzoWpR92251X2M2ZGsUR9b",
      "vault": "9Y6FwSxHfRq8wSivUVBqAXzRugD4Gm8FYUKCDqFvZvgs",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6261b8be",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261b8be",
          "beginScheduleTs": "6261b8be",
          "lastUpdatedTs": "6261b8be",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0d014b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261b8be",
          "beginScheduleTs": "6261b8be",
          "lastUpdatedTs": "6261b8be",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EfKaxphkp9UGQeGjfWtUZtfU2KLbfuNUdz7A1Sc1cJAv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8nGCq3ZeWtnokodXbfFSoXqchvYSqiQxqTMGYp6kiCVz",
      "vault": "CN7mGu5Vggq5e8GJbzvUSgT7YpvceuCQvUyiaT1D3sPP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6258cf92",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1f405de3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258cf92",
          "beginScheduleTs": "6258cf92",
          "lastUpdatedTs": "6258cfb7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "15ea77",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258cf92",
          "beginScheduleTs": "6258cfb7",
          "lastUpdatedTs": "6258cfb7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J78k52Mop1pgLCX213ZkGnFaUM79PpgvaiUVzU4SX5ka",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Tqv1mTQv8KD4qFPyjobbFnYD7Av2NpNCc96fEYdFAbw",
      "vault": "82dNKbDrEHLLH3Kh6k2hMk5Qiv4dWpzKg4jEgKfk2eFX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62575f35",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1b68565d",
        "accruedReward": "1b69a4ed",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62575f35",
          "beginScheduleTs": "62575f35",
          "lastUpdatedTs": "62575f35",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "175ad4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62575f35",
          "beginScheduleTs": "62575f35",
          "lastUpdatedTs": "62575f35",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4pP2ecxAkEJfcrqYAFpWoUteDvjMTtdrPexiBr2NvTtD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "44JWJbSYVZKXHZFoDqDkQDWBCJZuFyRv7gLLhnSiqD1H",
      "vault": "81oETAdirbhRkYZYu31S7iJeD7m2fAiBwzvZHP5rJtbn",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "84ea",
        "accruedReward": "84ea",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DaRSzz2FanB5y7dZXc656n2N6T7ApWtvEmPf2hp4fJaT",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GXcHQ14xKmRfZkPAMS8TaKerPcWAwUxFeWWxjxBQX7Jk",
      "vault": "2uxkhdBrVC5VFS5JhdF9jCaqxuGpfdz7QwriiSQmF1ND",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4rvnXZzw61goCmms3fmh2nwoyzC9nGob9Qa3vLnb8oiQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5rDm9BYzcAvuKTvC8qVyfUqHS58Y5A2tqihag22YAfVd",
      "vault": "7ZKujupPKcdNYzQskfPPZoagMwU45KKCu8ikZzr1SoDv",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62660c21",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "46b6b59b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62660c21",
          "beginScheduleTs": "62660c21",
          "lastUpdatedTs": "62660c21",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08ade8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62660c21",
          "beginScheduleTs": "62660c21",
          "lastUpdatedTs": "62660c21",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GAV6PMnicXBB2Vqyc4DDFYahfEWtAP1HJpbvS1UJsXpY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5ghaogAhKgdeX5q4t1TYUoB3uRgRTdYp4W15M1B3WwG8",
      "vault": "3sAitPgUup4DRxthntCWpJnmkcwcSsHqe7MMnbBHXXfJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62641de7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62641de7",
          "beginScheduleTs": "62641de7",
          "lastUpdatedTs": "62641de7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a9c22",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62641de7",
          "beginScheduleTs": "62641de7",
          "lastUpdatedTs": "62641de7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4BezwP7oH6reuNY7TU7sj11k2bSk22LBt4eN3R7yLLdM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9aayPmHLafKem4GLaBCQHouekMhK3j1aUXHexYTqDrF4",
      "vault": "9K7BdYQoNiU789PaPA5Bz2wCeFDPWedrx36mfJuCjDuU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624ae0ba",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3443ee39",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624ae0ba",
          "beginScheduleTs": "624ae0ba",
          "lastUpdatedTs": "6267c74b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "23d94f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624ae0ba",
          "beginScheduleTs": "6267c74b",
          "lastUpdatedTs": "6267c74b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AzAhMqZRRC4qz2BoWCKa2ko4CB9i6UiVDeEzJR6aGoyD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HKRg9d5qyybcWzkNrMnmkNNGNKao6ngUoiwVzvdzFqXR",
      "vault": "E3UEE5mARF8P9USRx6Mpy9uKqYxhwuRVdBAz6sHsB9wX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6257f0f7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257f0f7",
          "beginScheduleTs": "6257f0f7",
          "lastUpdatedTs": "6257f0f7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "16c912",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257f0f7",
          "beginScheduleTs": "6257f0f7",
          "lastUpdatedTs": "6257f0f7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "fxeT2q6an5q2SAisHcTCP36vPrL8tFWwM2mSafG2rBP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Ts7Rv4oUnJ94ri3W3eRKXmvV35gBodH74JHrJ6BGmmr",
      "vault": "DCRJEwHdQgQtsSpwKVTE5rJFtVP4LKM1GK1S51VXRAT5",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HUFccbkSQhYAEwjJRggHmDMXbWXJCGmmvbkStj6sNiU6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3ijftmtdrPL5uoQAkwAwQ2t9qDwtrLmN5xaygp8D3QNb",
      "vault": "5Q1CfmHYXv8dxmdqwv4g1gVR5JpBHaRCgERqw4iSuPB4",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0451f475",
        "accruedReward": "0451f475",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5mJL9Bs7xmVeWubyx8t9ZsXLAwesZhLE1Gd4vq3KLQ6i",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9rpb6BfhchS9Lmctpgvnn7UWvBLTzcSQt6qS46S3dYcY",
      "vault": "9rJywxgHG76Kze8MbWz2NZqHhTHsn9FQPtVtN9L7VEQE",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "12e00f32",
        "accruedReward": "12e00f32",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "88EmgifrhG6QNzNLY2EPQVXkjEA5JRrdve5tuDHNa4xS",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F9hCraGLJ2z6CjCbHFJ6eLoGuMPrtxMmSDdMyawy3C4r",
      "vault": "73HkpW6VSEafVWitZowNLqLggoPdE5uR2agboTr6G4p",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HdZuDFoQzho8TgYFujV5CeeJ3Dhau9vfh5phnLTdcNej",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9zowARrzX6rwk3pYu7j4c6NiMfz2FjWHkAPs6uxrbNSz",
      "vault": "78k6VNuEauDAemkZoToYdcJCj6QNLBXWR4YhufcJFHLu",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624c6dad",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c6dad",
          "beginScheduleTs": "624c6dad",
          "lastUpdatedTs": "624c6dad",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "224c5c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c6dad",
          "beginScheduleTs": "624c6dad",
          "lastUpdatedTs": "624c6dad",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "F3Ku7b3SbBbCdp66STcpW4vKvaRK62ojDAKmsCmRmu8a",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DeTaArQhY8gQa1zwEZCgmU1RSChJDpspTUEyv1jzPbHd",
      "vault": "5wzZ4j9g42Qk97gShGJyx2XvK2kdNguJ1K4vBi2Kz59",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62696450",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "58e358de",
        "accruedReward": "5d1b2dab",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62696450",
          "beginScheduleTs": "62696450",
          "lastUpdatedTs": "62696450",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0555b9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62696450",
          "beginScheduleTs": "62696450",
          "lastUpdatedTs": "62696450",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AbmaCDJmZ7JNs2rQwvsXhdDjnoxtnPmDBMcnQ9MNT2PY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "35pSEvyjtbL5ZEQhLTnpwBztsLfijNzafHVfsUNe1ddv",
      "vault": "DmjoqAxpmhufAxhXp6RcL47GbwFzHncEPfJuqKwn2FKX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624767f0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3b150195",
        "accruedReward": "3b150195",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624767f0",
          "beginScheduleTs": "624767f0",
          "lastUpdatedTs": "62681376",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "275219",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624767f0",
          "beginScheduleTs": "62681376",
          "lastUpdatedTs": "62681376",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BS1XhWRUQ5v3jwBLc8GoT6LFzHQq6piNmbh8DnCrvHQo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5GJ9wT6zJsgS3MSLFbyABa1k2nVcsdyRLNwxMAEgUUKU",
      "vault": "2E8h7pC96wfcULxYKqPspdBdv3wLkm6Yn7WwsxxPKKYK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b19c2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0ac83666",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b19c2",
          "beginScheduleTs": "624b19c2",
          "lastUpdatedTs": "62570643",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "23a047",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b19c2",
          "beginScheduleTs": "62570643",
          "lastUpdatedTs": "62570643",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "U93ju3TZZyzQPco7DQTdj8Uevyu6BRQYsY7FBa5CneZ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AGUD9AsXgmR5teyg8ToSEfwYXfJXkz4Ju1ZrsPr8PMXu",
      "vault": "DBQQLeqoioonjGDonaAiZE5Zyj9QoudmnHZTsZ1aS6J2",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5FjH7WsGupeNMtHnUuB6BUnTqF9GuPE2qV8MhnMCJ3Yr",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7Y3UgNK6gtmwiHi98ZngLHTtizYnhP73aGJwZGK6CRSr",
      "vault": "6nk8Ah3XwfFCppcPJmMBsu1DfGbG1gXrpxvUvSEJcH7K",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "01ec7a37",
        "accruedReward": "01ecab0b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "G4Z1guND7pvy3vZMSV9tgBbesX3r3DGvgpfSHLR39VfJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AvcNu2CoPeqHYDK8RE9mnAdNWzznYTf3z9RN1ASG9pK3",
      "vault": "3wS9QwT4oF6i5tGPaqaCn5cxCVvLm5dojGt3etu9wMuf",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62483d73",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3d7c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62483d73",
          "beginScheduleTs": "62483d73",
          "lastUpdatedTs": "62483d73",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "267c96",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62483d73",
          "beginScheduleTs": "62483d73",
          "lastUpdatedTs": "62483d73",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5cWLk2auihcf64gExFgVCxXRjNAwvFYjnKKYCGUn2Wn5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9i9cf5URWGY48sjtHazc5sCxqWk44VpejSUg9Yt4MgXQ",
      "vault": "3EcTvwjtaquBU6AefBmdX3aLyjcVTHHP6VJfXx5qMQ7Y",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3bn48mUmKd5tfrBWrRVmGff2mcT12kZv8qH3yYaWM1KA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7BPnp5M74HXWXxAmLCSB9VMUnXN8VJmUnZpU31X3QkCQ",
      "vault": "HUhG9Kf8UvWCFKSZcZYFEiiBZQ5YRdSPRmv6Gw6vdZqJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247f82e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "149dea8e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247f82e",
          "beginScheduleTs": "6247f82e",
          "lastUpdatedTs": "62535ea8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26c1db",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247f82e",
          "beginScheduleTs": "62535ea8",
          "lastUpdatedTs": "62535ea8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7EC26YQxExX7Zmfop1fBGJPQCzHgtzMPnWmGhD9dSkoF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8uA2SsdqLPpVHgafZhgw74NbFwDqJ5QkMDNi49zrnVij",
      "vault": "BvftX66mfjNrieTofZ89SpnPdvvYYyEEpR9dSNSAcfLL",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6257b06e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257b06e",
          "beginScheduleTs": "6257b06e",
          "lastUpdatedTs": "6257b06e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17099b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257b06e",
          "beginScheduleTs": "6257b06e",
          "lastUpdatedTs": "6257b06e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "35uYr5fjgpxABYpk3ALAnd6fBCD9RyReKGuHDgTx7Zpd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2KVqMNbP6jJLuqSDiouSS5pjEujti8wFCH1LTGn8PAJx",
      "vault": "5kQutc7XEbTYCTs4Deag5UTPthNvX5TpRAPBe6WnfxHR",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "037f9052",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "25dcQ5Dh3rt8W4hZ4acZXFrjVNHrjTCPYXTPTPub6v7R",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Cor7hq4CDDAzK6MKp8YPoKsHSmorvCs4Cim7SWd2hpPK",
      "vault": "EuFxWsmM7vRoA3bivgmMTshggP2PuGiXYpBKgtbtF5t8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62691893",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1775b435",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62691893",
          "beginScheduleTs": "62691893",
          "lastUpdatedTs": "62691893",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "05a176",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62691893",
          "beginScheduleTs": "62691893",
          "lastUpdatedTs": "62691893",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8M54rdU5KecnPTn7f2wmMycx4VD5hBQPNNC1aRQE9uUp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EiwB5WXj4NbsVNchGvzUFWkUkmnYGmM4eHiHWQXLcLEA",
      "vault": "DnyeASGFHnKZFscMr3Ns1UsYVMX1Y84WH3i7YaHUgYxD",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "11cc0f12",
        "accruedReward": "11cc164d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AA3wL3qBjxwKxdn6KLbJzRFdQuGwjmXCkfUxsQbXRBDR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CpaCG9J4BQWVcXwDsm73pQUtbCUAR15aSG3HxuxxVYqh",
      "vault": "2hZvmEaPvb2sNpjbt8XAad188swcW8yM6Br5dYkPc3TR",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62477b1c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "6c81",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477b1c",
          "beginScheduleTs": "62477b1c",
          "lastUpdatedTs": "62477b1c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "273eed",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477b1c",
          "beginScheduleTs": "62477b1c",
          "lastUpdatedTs": "62477b1c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Cqk39PrrrpANcqHpuRnUnqwBp7Ux8aTiMs7SqFkpkvhp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FgZkHwZbUY55hve6uRHbbt6aia4J6kZJFSjbmrwif5iW",
      "vault": "RQN2VLt1bQvHWg9rMeZYyNCrz5kkU4tYtvemF2YCftu",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6268b455",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "05e1c0b9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268b455",
          "beginScheduleTs": "6268b455",
          "lastUpdatedTs": "6269f0fa",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0605b4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268b455",
          "beginScheduleTs": "6269f0fa",
          "lastUpdatedTs": "6269f0fa",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Fv9TPJV4sumqh5VNQ25gaExZjWpMNzxky2EtNbNCE2YL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EvM15ZgQhWaCjhD13iSgYhxp42sLEAHewuXiFn6CrHBr",
      "vault": "CFeFeHWgTL5X7Akmufdsq3BaSr7ReRK2LNtPaHRcmxYz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62490885",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "047df62f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62490885",
          "beginScheduleTs": "62490885",
          "lastUpdatedTs": "624b8467",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25b184",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62490885",
          "beginScheduleTs": "624b8467",
          "lastUpdatedTs": "624b8467",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9KMW2EaKMMbnj2BmS4RpBSYV65Fy2SEm9jXCgwaqkF4D",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7uFVrNxqJ8EHHzEXTDdT6xvdBkZFboYc17VV6hyiF1Aw",
      "vault": "Cm3ibbHEuyM5wiRyysG1MP1wg8aXanKiLpkwxCRUhn9K",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62552129",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62552129",
          "beginScheduleTs": "62552129",
          "lastUpdatedTs": "62552129",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1998e0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62552129",
          "beginScheduleTs": "62552129",
          "lastUpdatedTs": "62552129",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Dy8VSLEa4DzQxMcY1gWzZZeoZm3jEi4sW913SwVSgzpL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7HQzUNtNqf87mP4sfT9Fvwk33B16odhn7ScKjZu2QsGQ",
      "vault": "GesaDniTpgPwzLsgSMwoutgFyvK6efwQ19qo3b1UYW3j",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2a71b855",
        "accruedReward": "2a71d375",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FwxwqZ63PDhPLnfCCXZDwgTrgJrrhybchJS4whyAFNq1",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3Q7MJX2Z4z8HPSubdrwqLKtQK7XZTpZXR18BHV1KRZKk",
      "vault": "8f7pDzd4nfvyuWZubhZgZCfKHKSeqy3eWtjzxHNAahQs",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4Mc322G4orv3xV176h7BYqq4iBakUWPNruB9zt8wmfzf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HDRq4wD7FSPpbaqrVxBLSdjVReJXozefxkj5GesVeg2v",
      "vault": "CQV7wuZihSk9mcyVuFJ7xwt2RS5ECwbbyF4ixBZEkTwz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625f3c2e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2e450d3b",
        "accruedReward": "2e450d3b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f3c2e",
          "beginScheduleTs": "625f3c2e",
          "lastUpdatedTs": "626404bf",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f7ddb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f3c2e",
          "beginScheduleTs": "626404bf",
          "lastUpdatedTs": "626404bf",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BdNZhDbUWZ6LtsWJLtid6yTXBsELySkhQktre2Cmx5UA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6USSuKp8Jf14RHCxL41WQ5fZVd54fH8zy2NnAuoDv2GE",
      "vault": "Cw16KjZUyQEDus3MQKnfJajU4g35jzRQUcJabcJZBLeJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "62526f43",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "6665660e",
        "accruedReward": "6665660e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62526f43",
          "beginScheduleTs": "62526f43",
          "lastUpdatedTs": "62645821",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c4ac6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62526f43",
          "beginScheduleTs": "62645821",
          "lastUpdatedTs": "62645821",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2sD89VWwMhmT1vGQLPKH6Fz7MSNzFd4HjSavMS88DFHt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9fti1WHQVy2avS3acjBzfvEcsuGjJfyRyeHP49zfYoHM",
      "vault": "8cyxUsvyb62jdMUcCnoL54mam5DVnVP5zpbF9qc4iyps",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625cc700",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "084c4b9e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cc700",
          "beginScheduleTs": "625cc700",
          "lastUpdatedTs": "6265f44d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11f309",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cc700",
          "beginScheduleTs": "6265f44d",
          "lastUpdatedTs": "6265f44d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GNX7N2DSAoB8QtjUTWja6Qb5fsvZcEuYatLzyaGVVaXG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6pPw9ic4ZTysTzjPoNijCcLUmhZVCZsSJNUZHHshxhoW",
      "vault": "9nRTnZTRzQtyZgyXEcUgPcANXiX1QwLiqYew1v4CJioi",
      "state": {
        "staked": {}
      },
      "gemsStaked": "08",
      "rarityPointsStaked": "08",
      "minStakingEndsTs": "6262f169",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "f868bbec",
        "accruedReward": "f87ff0ad",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262f169",
          "beginScheduleTs": "6262f169",
          "lastUpdatedTs": "6262f169",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0bc8a0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262f169",
          "beginScheduleTs": "6262f169",
          "lastUpdatedTs": "6262f169",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ChKJBZ22DL2ESJf8t1K8LBwpysoVnssJA5bVpM36yhis",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2SQmm3CTPSiBHcLUxJFD92H4nm4pfxJEDgTnSyeHBEq5",
      "vault": "Ce6VnBavASJFQ2mTXMfadywxQwBWLLu6FWhKsdqcg6DK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625b53cd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0e77",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b53cd",
          "beginScheduleTs": "625b53cd",
          "lastUpdatedTs": "625b53cd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13663c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b53cd",
          "beginScheduleTs": "625b53cd",
          "lastUpdatedTs": "625b53cd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "35Q59qqVaesjQsRLaYaqCVYijfMo6cKAb88RY92c2ddX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F1pnhR2L5LgNYgPfThK1SMRTp6LbLxSxqReUqh3MFFXk",
      "vault": "3pSVUsBn1ADbbT3g5CtLVStfPSENxc4kStivtcZQUKCS",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Ed9KUBtiQPjenHtnknvVNToMuRC9cCUEnYem6uq1dYMT",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "54fVYrPmDRB4P3Xa5yeHzPqU6sm7tLjZWf6Tc32rcxYX",
      "vault": "64oChadR8vcwj3zz79oBtp7bF1XfuYPGuBAbHkBWzoQA",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "625eeadc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "5a4c3d1d",
        "accruedReward": "716d40bc",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625eeadc",
          "beginScheduleTs": "625eeadc",
          "lastUpdatedTs": "625eeadc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0fcf2d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625eeadc",
          "beginScheduleTs": "625eeadc",
          "lastUpdatedTs": "625eeadc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2KA5AWt23wsLgJKycBe3N1V3e3tKDAStBkRHmmSyEvwH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Fn9A5Ge92QkwrYAASFv5R7nh9VMve9ssERPfhaNPF3Lj",
      "vault": "8oh67tozvzGTjSuTksBKduocU5sF47Ce746azXtgKnjL",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "129fba8a",
        "accruedReward": "129fc81a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5BfswjyLxrNj9HZuYjmKyumxV8vJnB8JWFTkXosgSje7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9ceVgwyCmGhBeD4hGpHmjNHKC7HMY2HaEQyVD6LE9YeX",
      "vault": "BFXSDd8S4STkBCoCLaBbD5f12YYgZbo9L9xeTT8L3c5k",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "74HRbVs6oKkS6JBtZwhCyUjH2zNH1dsKkxdMgESmk77Y",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5FyuwH1s1sbkFcWryuPFKiQBTVomCrsRJ5ToaABS6nni",
      "vault": "Cpyzfp5WVvEqLxTxxGej3TDopUK2LXXPAbqkWXXVr5CT",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6264e90c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0713907a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6264e90c",
          "beginScheduleTs": "6264e90c",
          "lastUpdatedTs": "62678889",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "09d0fd",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6264e90c",
          "beginScheduleTs": "62678889",
          "lastUpdatedTs": "62678889",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2RYe1sKvGK8SDawXJMC3UFzZKZZHTEzvv9VQn6wpH2ER",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DkEtaF8VabTxM3RB6h4T3zMxRZm6z65z8MSx2W3hP3Xf",
      "vault": "Eq6ZPmEWk6MnRUL8zxTcXa2DjUx6GafoDzjWbeKsYqy9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "626325a9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1ba576dd",
        "accruedReward": "1ba576dd",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626325a9",
          "beginScheduleTs": "626325a9",
          "lastUpdatedTs": "626325cc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0b9460",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626325a9",
          "beginScheduleTs": "626325cc",
          "lastUpdatedTs": "626325cc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7aokB6dgky3Mh9BoivW14XvHEmsm2E1eMGuWCcDV1A57",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Asd4P1ax9XkG324oQGymrShpaKr6UaNPYGDEDp1sKBCR",
      "vault": "F9FcpuBGAZ2sJktBF7SPszME3THrsmzR3dxhdUMwiTMU",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DjPe1NZKNHw6dXroAr7K1KHjBzng4SKxfe84d4VizSKn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F3aTed5HwnQnoXiyWMFytAUQrQhcTF7Dia5jM56kg8KR",
      "vault": "8F6Ny8Tmeu939zMaToE76CQ9yfVybpUfGet6vMdLHRuk",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6268450a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0168d6ac",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268450a",
          "beginScheduleTs": "6268450a",
          "lastUpdatedTs": "6268450a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0674ff",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268450a",
          "beginScheduleTs": "6268450a",
          "lastUpdatedTs": "6268450a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Chtcd5mAeQP43SvSY7JXGqPFXhqbtF3q3yBsiYmNwF9z",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9osbC88Saspck2ux8Sxdjn4JVgHi21fL6GRpUYZa74VZ",
      "vault": "4uKvxeCAEDbBAEufbJuKkS2mbJERQ1tgSvkRV89p5zEu",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "04775a61",
        "accruedReward": "04775a61",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6pB3DhkjDCK592RToQP8woeyWA4xup7G7ABXj7qBodyC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D5JhLAUmrdNpPbutVupo7hH2aVfxjgyUMb1B4jE9WhYL",
      "vault": "8UMAZA4DSepfRoN3qVLFXDwBqpTvYVmtcHU2PEBmycwz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6262773e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "491e922f",
        "accruedReward": "491f5da2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262773e",
          "beginScheduleTs": "6262773e",
          "lastUpdatedTs": "62627789",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0c42cb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262773e",
          "beginScheduleTs": "62627789",
          "lastUpdatedTs": "62627789",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8kYgfvUEmX8DHCmxxYGVSbKbdL827d3Z9thvegtvXfSb",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BvdjokVPYET6gSUB6qP2wQ97WJaXa86xduYSe1BmuDBH",
      "vault": "BPRtg9RwxjrJKUT2pT7Q69AtuQNDDbX1ibgSoB7SQvdx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626ad6a5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ad6a5",
          "beginScheduleTs": "626ad6a5",
          "lastUpdatedTs": "626ad6a5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "03e364",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ad6a5",
          "beginScheduleTs": "626ad6a5",
          "lastUpdatedTs": "626ad6a5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GwqZqiVexx7yWouaUUq4VjspxYE5AXSdr4M9XJih5M9z",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "f8NvnwpUdDf6779kDVu64cawwrN1eB9zEyGMagcZTVE",
      "vault": "2CpZjRxyDsJZFUG9V85WJnyox31cjqGPvZ1nviNRRjyU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624b9ddc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "34b95874",
        "accruedReward": "34b95874",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b9ddc",
          "beginScheduleTs": "624b9ddc",
          "lastUpdatedTs": "6268c55a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "231c2d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b9ddc",
          "beginScheduleTs": "6268c55a",
          "lastUpdatedTs": "6268c55a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6SoTadqvQjRnRdLivCueBuXCyUZqS4JfUm7RTjFo3zMQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4hZo7LxmDBG59m3UkXyp8VTsv3gyAHapi7KuxE9W8YbC",
      "vault": "AttPsvMhz8jrZLBBB3Nyyn2SCXLJuA651YsgDxGdbC8X",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62519009",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62519009",
          "beginScheduleTs": "62519009",
          "lastUpdatedTs": "62519009",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d2a00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62519009",
          "beginScheduleTs": "62519009",
          "lastUpdatedTs": "62519009",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EbyjmCgBf7pDNpckveyacaeA8AXQZes3DB41GMqvhW14",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Gsi9eEya6vYWYuP19h1NovVHC5pdPv8D5Zp3U9tpkknx",
      "vault": "25anm73u1vAiuF8Q64cZwmTo8Lnzkmumq2b8diP6k8pi",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62575fa5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62575fa5",
          "beginScheduleTs": "62575fa5",
          "lastUpdatedTs": "62575fa5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "175a64",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62575fa5",
          "beginScheduleTs": "62575fa5",
          "lastUpdatedTs": "62575fa5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EyWMnP1icbUiUG6aiqSy7ZNGSs1wk9dtzNTnxANCt8q2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Hv9qLetAN9QxJkFWhbQGaPdJ6ca5CgEAyWnsotdGzZdC",
      "vault": "F6Go9vXaGi4PCp4SMnnau2uuW5VmqbhvJm22wpuqKctt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6255ce24",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0485",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255ce24",
          "beginScheduleTs": "6255ce24",
          "lastUpdatedTs": "6255ce24",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "18ebe5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255ce24",
          "beginScheduleTs": "6255ce24",
          "lastUpdatedTs": "6255ce24",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AWBvCPikUQdpjPB4op33b6M82dTsh6TMobdKDozBH9C3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8dit483ASLCmSSQ8X9UmDnyp3YFftdRfotj9BpqKHcGq",
      "vault": "EajvUpXdQeyYG7KfevAcTLjtDXJdqm8bqb3piPd74rUU",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4NccuMypg2xeoeR2VCP7UWMfM5yjs6fGLLddVrhZWc5T",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3VhXAvfHux6JafsW7xRnX1xjct1yw8d9LXYdWXcwa2uY",
      "vault": "7Qqcpt5vFihXeUs7dRr5P4gt4S53yLzvi7cV1hDUKFjt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62489666",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2fa1ff67",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489666",
          "beginScheduleTs": "62489666",
          "lastUpdatedTs": "6262ed31",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2623a3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489666",
          "beginScheduleTs": "6262ed31",
          "lastUpdatedTs": "6262ed31",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HJqF6NeKHngp8t6Vd257yHiPt7bTy7jPmVTmFcNnkfvu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "32cc3J4iWVnWzWb4B5YXSo9wbzwYxJneYeQPbuWuYffX",
      "vault": "9KXe8qdpKfM57UaXmBwPcL2xzemcdn4Jfvk5bzwm2Mvj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62657cff",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "07af213c",
        "accruedReward": "07af213c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62657cff",
          "beginScheduleTs": "62657cff",
          "lastUpdatedTs": "6269a6cd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "093d0a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62657cff",
          "beginScheduleTs": "6269a6cd",
          "lastUpdatedTs": "6269a6cd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DE6CYCfCnwhpjd1y9gAy5CFwxiQqxVFmBrHFK1p6Sk9d",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CSmc8CQ44vceVr8U9QW9TRPU4dLhZ8L5m6wENDxXr31Y",
      "vault": "FM1Fo4pWnfT6nVDEdv5e1cwGgLCha6PpJuWaqxMuFj38",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62679414",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1c90f0cf",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62679414",
          "beginScheduleTs": "62679414",
          "lastUpdatedTs": "62679414",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0725f5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62679414",
          "beginScheduleTs": "62679414",
          "lastUpdatedTs": "62679414",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9pKVozxhtuDvAyqYg6svNvGs19Njo5aq17zqTG33d9cE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A5RoqfkL9TJp16zwbXywjPuQNWzeW2uxYSEn1LsLYdzz",
      "vault": "xzZ7oJUzHGwbAtq5iHpqy9zcWmmGYvAVPXomfPFLQGN",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6253292b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0df317f1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6253292b",
          "beginScheduleTs": "6253292b",
          "lastUpdatedTs": "6253292b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1b90de",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6253292b",
          "beginScheduleTs": "6253292b",
          "lastUpdatedTs": "6253292b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6cEcBTtd48kLwfNtTerpiFMbypbPzrq5LNN3BJgxDtzz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HP2iHsj1goFcfTkjojqwBZw1vzQcXrx4zbmFDJ7ug6sh",
      "vault": "HdPBmTWuQXGHEg3GSEy55zeaAXuKmXmTW5sRdp12jCPc",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624d0d3a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "186a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624d0d3a",
          "beginScheduleTs": "624d0d3a",
          "lastUpdatedTs": "624d0d3a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "21accf",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624d0d3a",
          "beginScheduleTs": "624d0d3a",
          "lastUpdatedTs": "624d0d3a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "69KUoeUm6BgH2D6FERtzuSAz1Cfmm1NdQHsYb9yBcvjD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5hrg9LUKdptcd5cyQteGat5nNjUoCkcg7ux6rzPqcGNd",
      "vault": "H8MTHZ6jMLdSwBV6LMTvbnE2i9dSQpbQCzaLum5bFaht",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HyjBDQ8tYfRb9PgNTvYR8rXzST9YZcoYfgaipDpPKiid",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GNUVkuBB4yyyAwEDSdi6JBk7gzkHuzeidVGmRVvptFqa",
      "vault": "DHSYdWU6byT87YBDqnbFyZgn5UEWdY8Aef1ob8ZzzCA4",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62516c6b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3545e8f2",
        "accruedReward": "3545e8f2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62516c6b",
          "beginScheduleTs": "62516c6b",
          "lastUpdatedTs": "626712ed",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d4d9e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62516c6b",
          "beginScheduleTs": "626712ed",
          "lastUpdatedTs": "626712ed",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DdkQgSHG5jXtFNjHzKJnHCYg6NbDPWXUwaZexJuW7VVW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "64WxbSYDsi4L3kkLmQdB7UNTHVhbc9pB9T323djqqxoi",
      "vault": "5L1FSvA1eiFBzvcLhK7Xz8cKv14AL3NihAp5LbLHXFVG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6266d51b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "23af40da",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266d51b",
          "beginScheduleTs": "6266d51b",
          "lastUpdatedTs": "6266d51b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "07e4ee",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266d51b",
          "beginScheduleTs": "6266d51b",
          "lastUpdatedTs": "6266d51b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DCjNt2LCxPxX4sEMskv3vYiqWSSfjAg6AcJuaNJB1EfL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7B2XcuoYzN2jMkaiGj6gaUrs8SbQPrNfZu264ptbvevs",
      "vault": "G2eBtL9jV1ywLWtJMJCm8TGJwGYWMmCR9KQbRavYgzwM",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "775b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "365rge5vFSyPyFFEcaj8rEtB3deY2xY41TWHmhbwDDnU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EsEikVYNUKB7afXSf1AFiT9tvpkQud9gMzLYQVeZ9Kdd",
      "vault": "79wzTjrS1wMQvJyowg66aMJwFGqcF95DwFB4uhjbUALp",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "14e6bcf1",
        "accruedReward": "14e6bcf1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2krTotppQYs7WqcnSbUGzrHWzFgK1RcKBkEioixbqjVm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "63rdxFgb6CsVuzYVaMQgwVhUArF84WWzBrdWdCv4Awq2",
      "vault": "7sNNBqgG9ubLEyjqREANTSCjxqdfzGJy7VHqKcByzF9p",
      "state": {
        "staked": {}
      },
      "gemsStaked": "05",
      "rarityPointsStaked": "05",
      "minStakingEndsTs": "62666e98",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62666e98",
          "beginScheduleTs": "62666e98",
          "lastUpdatedTs": "62666e98",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "084b71",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62666e98",
          "beginScheduleTs": "62666e98",
          "lastUpdatedTs": "62666e98",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7fPmdDPgHSYcQr3c5qmKs5J1iMiCj5iTbz6jLLYMbfoJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Ce5qeuUu4q1vfPnrDsQLam6hbLGS75adokVnECjP95nG",
      "vault": "6YHmsMQRoFGjaWTKW6fEiBcxCJkoK7vhqqJVyqRQAnXr",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6249d2ee",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "02157d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249d2ee",
          "beginScheduleTs": "6249d2ee",
          "lastUpdatedTs": "6249d415",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24e71b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249d2ee",
          "beginScheduleTs": "6249d415",
          "lastUpdatedTs": "6249d415",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8LduWFkgBeMf8brcu3QbzPuTPG3p9tNgZbQWMUNf3SSi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "43NV8sHEadC7VwUha7M2PS2fkZprurEbrxjbaQfrPp9b",
      "vault": "3N5yB1s1VCiPWWD5CLj7JYGV9cvfQe32vQGuQGtpLMM2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6257710c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3c003410",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257710c",
          "beginScheduleTs": "6257710c",
          "lastUpdatedTs": "6268b175",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1748fd",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257710c",
          "beginScheduleTs": "6268b175",
          "lastUpdatedTs": "6268b175",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "B7KjuyGFj6Z1Xpd6Ub7Ux1R7norsmNa8w5SdzFcTmoQF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GHr7EJ4Tt1ZYYDxCuMnYLQgpGWmWqSwWmdCNG851GyZr",
      "vault": "Gx6PZn8Ry2dzF1mt4E53JAtpTdWBnH1fxYVz4PNk6BSX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6248363e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "27adf203",
        "accruedReward": "27adf203",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248363e",
          "beginScheduleTs": "6248363e",
          "lastUpdatedTs": "625e272e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2683cb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248363e",
          "beginScheduleTs": "625e272e",
          "lastUpdatedTs": "625e272e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3EL1tbdtknWT4ApdEKyBKdjR2oLBPZsm5qbM4tS3SuPE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "63ZdStJgGSCEYRYaGJLwAae3yxY9vT7LW48CiRUCgGKk",
      "vault": "6C3tSPTdAQ5iDUQg7a3kZY7tMNfAaL7mRd5wHS3neBRG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62488045",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1b466778",
        "accruedReward": "1b466778",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62488045",
          "beginScheduleTs": "62488045",
          "lastUpdatedTs": "6266aa40",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2639c4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62488045",
          "beginScheduleTs": "6266aa40",
          "lastUpdatedTs": "6266aa40",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DVW8k4KRGnukBdhHLZkvPZy663WoZRA1VCmLzerxihwF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3Fds87Au12TTxRvA8JkbNjUzsWQxpXZNLyNqno78BNoN",
      "vault": "3yrnLveqDtSM2m7uTzeSTuvoFmTFmjBPb2MwFVSgRBwd",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "625cce1e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "4b124e98",
        "accruedReward": "4b124e98",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cce1e",
          "beginScheduleTs": "625cce1e",
          "lastUpdatedTs": "6266b564",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11ebeb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cce1e",
          "beginScheduleTs": "6266b564",
          "lastUpdatedTs": "6266b564",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Bn8iNGqGxnKyXB34xnKhqBhXL51M8qgMaxbRRzy746DJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7vuBWztwedrB4s6LKH47aghAkWRgSUczggqhL1ohyzw7",
      "vault": "CqqGE8MPXiQRAdwirx1nTx9B4xL8zsNmcHoAMM7gJcWR",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d8f64",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d8f64",
          "beginScheduleTs": "625d8f64",
          "lastUpdatedTs": "625d8f64",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "112aa5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d8f64",
          "beginScheduleTs": "625d8f64",
          "lastUpdatedTs": "625d8f64",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4cwf1TX7HL1dnrxpuoABVb1PD8t3rrkx2UsiD4AaenUJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7ZhTBCXUFXn45BR1Yhxv2fKHmpqxbQxPu6ddHfNyLVos",
      "vault": "Bx8x8F4FrtN3BzV56ek9znmCui7L5rxnGTFs5TBvnGR8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b9796",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b9796",
          "beginScheduleTs": "624b9796",
          "lastUpdatedTs": "624b9796",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "232273",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b9796",
          "beginScheduleTs": "624b9796",
          "lastUpdatedTs": "624b9796",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "B3f37SDaUwS8ZYiqxQwD46xgx7sPbBNKMZR7AQEvxTeJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E5mt4ybDKxXmkTjRLsmbYRHt2rSUYUrhzanSz3KLsWFM",
      "vault": "5fdUnXcFW1C9LvwScYEHHzrVLYoxpXtAsUwmqZA7md6f",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625ede08",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2bc7450a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ede08",
          "beginScheduleTs": "625ede08",
          "lastUpdatedTs": "626af91d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0fdc01",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ede08",
          "beginScheduleTs": "626af91d",
          "lastUpdatedTs": "626af91d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5QzKeqAST5hJ2P1YzSniiJLkSu4eWzAmJXr37exVbQCc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "44cs4G6vWTeGHzQxVf55h15ogjS8WJwRdVyfr6MPNJCp",
      "vault": "sC4zhUYxZzLLBFiR9wWumiYoCUkJbt9QcUEuMZG4J63",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0f823b1d",
        "accruedReward": "0f83ac09",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ACLDFFxktg5rMuCdzr8DUhasnpy2Y9puX6871Ext783T",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ALTeyZGzBAUsqM4yrinuc7vCaAyjbqaPecdzdWuLRMwi",
      "vault": "9N3eRLG77ghsTYqzmRM7esHxyCEWGEpaaJ9EjQwPz4i1",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "582fb40d",
        "accruedReward": "58303473",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2Tvb1M2KFZD9d7UZBTmD1YWaKhBJ3AN4NkPCF9uQWGZ2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7DTsm196Dn9rky1LqEiQawaavSEC8cKJiey8fo64BhB4",
      "vault": "6hXFemxjzUQdtwrzbksuXczdCCDY5B7JpZwmozNMHPM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d42cc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d42cc",
          "beginScheduleTs": "625d42cc",
          "lastUpdatedTs": "625d42cc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11773d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d42cc",
          "beginScheduleTs": "625d42cc",
          "lastUpdatedTs": "625d42cc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3sb2rxuEsCRvNrBhHfpy1pg5w77jwGfbSwYW8zA48rxd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "52MfsbukwStPXpVirwqw7msJXtFpkZZLgWEecVwMZFmy",
      "vault": "EKw5SsenYfJMwdzAuVJSeeZw5Wo3CaGAoQp6bybMvinG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62483e6f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "174dd0cd",
        "accruedReward": "174dd0cd",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62483e6f",
          "beginScheduleTs": "62483e6f",
          "lastUpdatedTs": "62620414",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "267b9a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62483e6f",
          "beginScheduleTs": "62620414",
          "lastUpdatedTs": "62620414",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BVtHuRZhqCmqK2P4fr5zji5Gw8MYw1yf4o544S6mhPnE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9YXvHPqJ4qMP6SYt3f1v2Juh1f7iGuyfnboKWAmBtY28",
      "vault": "48TYvF9cxcBCWNK4Th6MYPynvFEDFQSc86iuWJUtVruy",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625efb33",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "08d2bd",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625efb33",
          "beginScheduleTs": "625efb33",
          "lastUpdatedTs": "625efb33",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0fbed6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625efb33",
          "beginScheduleTs": "625efb33",
          "lastUpdatedTs": "625efb33",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FqfDCAhrmWoHpJmmf9R9JsvXfs73qFkfRco2mrBZGrnN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4di3xJNoB62cyu8Yqbv4VLiM4XHUKC8sgAR8PBcfFRLC",
      "vault": "3crefRNEZpCkzmSnDjkgfJU4vd6AM7PaPiG3DMqy3nog",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624abc24",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "30d741a2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624abc24",
          "beginScheduleTs": "624abc24",
          "lastUpdatedTs": "6265bde2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "23fde5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624abc24",
          "beginScheduleTs": "6265bde2",
          "lastUpdatedTs": "6265bde2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E3UY4m6EHgBtWuMBBmmgd7MppPTb7AzFSZwL3WxZBaeU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GJmVqHU1izKs2XHB6Tqa9kx1wVHSg9Q8WbdzsQgNFFrW",
      "vault": "BvDWrX7FezehJ9ommvUaN968TFnZgXysuLN6nFA5aGdJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6247773c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "740fe660",
        "accruedReward": "740fe660",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247773c",
          "beginScheduleTs": "6247773c",
          "lastUpdatedTs": "62678dfb",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2742cd",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247773c",
          "beginScheduleTs": "62678dfb",
          "lastUpdatedTs": "62678dfb",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9PwzK3PaBovEgsbt5r7HfK995KUeBzNtGCzMVC93mC3x",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EHtF7PPPgPB6FXDd9CHgFwH6xm2fC3R6WsUatsqpUXme",
      "vault": "4SkuJrXG5Jx2BpjM8hVRiQuTCZfVYEr3iEuVmuM7pGsw",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "01aacb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ENnQuyLbTcRuBLMbe82XEXcbdgTYEJAro44dM5CMH4Bf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9hSuCzjLu8maZSGWKSCao8pZ9WZAZEq6MwMELH6msfDG",
      "vault": "3GXR1hnDjMsUCVG2THqKteWBug9w1tC5EpsVmVVE5wsC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625328df",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625328df",
          "beginScheduleTs": "625328df",
          "lastUpdatedTs": "625328df",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1b912a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625328df",
          "beginScheduleTs": "625328df",
          "lastUpdatedTs": "625328df",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4KLG1Jx3cYeyBw3kyUQWKCtL8YEsmtfvH9WZYSCw5Ngd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CdGMaDuRnGdhEc17prsBi3vhb9H2HAnQ7gCzzKbJHij8",
      "vault": "5374G5Sd8ACzhxF3tNfp7GR9chkaJevH3ZXUUse7jcLx",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2d040d55",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BAqqsvdL7DmaCNdU3Y2L2FFHGPuWFgm8XP8Q3Cqys86g",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8euWGumJSgEcm128hXYBvqXPTt8ezp2rroDYm4G6zauX",
      "vault": "6JkajCwf6CYwmG7LKo61tYM7xUh8pr1FH3GnPs5hqDLj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62584963",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1d7ba2dd",
        "accruedReward": "2970b8e9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62584963",
          "beginScheduleTs": "62584963",
          "lastUpdatedTs": "6269dff0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1670a6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62584963",
          "beginScheduleTs": "6269dff0",
          "lastUpdatedTs": "6269dff0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BbbrZs1CLx8LF2Dzj7i7gHyKbMnb3xqHdPQraqcwcEzz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3gNp8Mu6p3m6JQQrJoXQt67cP37L4i7USaEQcomGRjWg",
      "vault": "GgsT7FWuYHA1v9gN8bRxoVYcNUGGiCNsjvVR12e1efLG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476cf2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476cf2",
          "beginScheduleTs": "62476cf2",
          "lastUpdatedTs": "62476cf2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274d17",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476cf2",
          "beginScheduleTs": "62476cf2",
          "lastUpdatedTs": "62476cf2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3stiWYBMPpHeRWj3wzurqMDhTKtGnJ2waM4W5iqrm1zN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CdgEoC4BQdggg25hPbkXFqy5T3r4bDRkXY6NcGHmVynb",
      "vault": "6MJ3GtFvy9uzMT2okDKTwZL437YiXt5NJU6Vyt49A6Jh",
      "state": {
        "staked": {}
      },
      "gemsStaked": "0a",
      "rarityPointsStaked": "0a",
      "minStakingEndsTs": "625f4f9d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "fd710389",
        "accruedReward": "fd710389",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f4f9d",
          "beginScheduleTs": "625f4f9d",
          "lastUpdatedTs": "62651963",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f6a6c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f4f9d",
          "beginScheduleTs": "62651963",
          "lastUpdatedTs": "62651963",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "A5ThbfxWRaCFXUm27rextDoFtfFbiTaEBp7q4JRj1aL3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Dq6PP6nXn1rWa5Fbi3KufbsM7TuuXtxSUPwPwMpXzxdS",
      "vault": "5WkWkd8TdYutBRZ7KquRmEW2nWWz8G2J9EeV1TTrRL15",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625d8b7d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0d8023ec",
        "accruedReward": "0e2e78d4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d8b7d",
          "beginScheduleTs": "625d8b7d",
          "lastUpdatedTs": "62681439",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "112e8c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d8b7d",
          "beginScheduleTs": "62681439",
          "lastUpdatedTs": "62681439",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GxDv6vxtfqiKBQd2RsN6VMfnbQzKooxHN8xNbS7zBR4e",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2MwL6FH5ttqjPbdTwxYYcjYBvmjYXp9yxrTwqSRYkvpR",
      "vault": "6yNoxrSFWmiKP3w8eGtwsiEoa9o21LSdasyrQJHAceYt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247b6de",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1e271429",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247b6de",
          "beginScheduleTs": "6247b6de",
          "lastUpdatedTs": "62690f8d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27032b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247b6de",
          "beginScheduleTs": "62690f8d",
          "lastUpdatedTs": "62690f8d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7yahkE5PjM5kd6togNWv8xrbr3v8xNFXCmiHEEcjWs8t",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ATyhtDZDFLaaf4wTR97oB1MzbUBK4kJQuqapzxyVQdRN",
      "vault": "4boAzHSprxmrBwDFbEy6BV5gnJYs2vpEADmbKzo4SKKG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6249e029",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "192fe891",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249e029",
          "beginScheduleTs": "6249e029",
          "lastUpdatedTs": "6265bb08",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24d9e0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249e029",
          "beginScheduleTs": "6265bb08",
          "lastUpdatedTs": "6265bb08",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7HxfMuKHGMkcPwR3Co51QQ6UpA2JXPHGGzf8aHkdQQDz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E3bVDAae7iiAQrRuMLYro7Rtrj5MZXLL4heiwnETxfiz",
      "vault": "HavKGtoB2tdyjzhQiUbWNifypLK3XdDfLAZY8HAkx3U7",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62484d73",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "10dec1ab",
        "accruedReward": "10dec1ab",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62484d73",
          "beginScheduleTs": "62484d73",
          "lastUpdatedTs": "625af5a6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "266c96",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62484d73",
          "beginScheduleTs": "625af5a6",
          "lastUpdatedTs": "625af5a6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Hb1p8e2Drr8c4ezh6a1G69jw1NFfTmm3d3X2RN9NxKig",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9J31RFgnJozfg1xFrD8PDXfrEZ5EjRigLZPz7iEuHFua",
      "vault": "4Ta2ZJWNpEDiZRWLfvX7c1WQzUvdsGfi8dVURwVBLYLe",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0fead21d",
        "accruedReward": "11ae7212",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Fdv3q8HmJZizUjDKERad3PG4CLuQkcSSSew9fcGBZsfE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FLMjUu6jks5Q1b31jvB6gqxvQN6mACHK15gpgFide8je",
      "vault": "3gFgZRdS8ww5dWNfxqoYpFiv4d7Z9zZPVBZPpXRMScUg",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248dc9a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0a0fad31",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248dc9a",
          "beginScheduleTs": "6248dc9a",
          "lastUpdatedTs": "6253fd18",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25dd6f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248dc9a",
          "beginScheduleTs": "6253fd18",
          "lastUpdatedTs": "6253fd18",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4BbZK2KCegWHC9MnGfmwHWhx7EogfbYSHEqPRku2iane",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5NrQfYpykFKd5TPsVJnnQ9qngwzvDXMzQYyXKsT6DKgF",
      "vault": "CpmzMfemRLLbNmt8ESdpTsgKJNkFytGwHPPgvPjGbxks",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247a834",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a834",
          "beginScheduleTs": "6247a834",
          "lastUpdatedTs": "6247a834",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2711d5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a834",
          "beginScheduleTs": "6247a834",
          "lastUpdatedTs": "6247a834",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5FAY5eBRobwb38zn9YqViJPwayq1KjfCCfkCaqKnvQGb",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "62fyvcyT8ovNpEvnrTwfQXZ9xjqFMZ29pLsXD4rRvQVN",
      "vault": "ximuPdrTT6P55gz8zhP7cLqmVdxBGKoutsyfXvbAk8L",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6257e0fc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257e0fc",
          "beginScheduleTs": "6257e0fc",
          "lastUpdatedTs": "6257e0fc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "16d90d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257e0fc",
          "beginScheduleTs": "6257e0fc",
          "lastUpdatedTs": "6257e0fc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7Ce8EN8eTySEz5oCTvacpJY7LmfFjBtmeHkPmpVt4o9N",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AUdCt79JGu6THzRq2bKPbkyTMzbKNWZT2E2wBfTd2EBU",
      "vault": "2qPRBzzSKC3UK3BJSnSQLuDBCURJPnhJ56cenRXZhLzN",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624fb172",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "06fe69fe",
        "accruedReward": "06ff39f6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624fb172",
          "beginScheduleTs": "624fb172",
          "lastUpdatedTs": "624fb258",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1f0897",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624fb172",
          "beginScheduleTs": "624fb258",
          "lastUpdatedTs": "624fb258",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AZVhKAKBe3qxyUTaDzf1UYVdqq7zrhqez12AtZda2AoL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6Xp93HaTbskhj43u9LHoAYQsyp28WACgHwd935fikJ8Y",
      "vault": "EMKsdpnWCNUeY21cRFDYFQcTh8rxu5Gqd1EVw931tJkZ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "6261733f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "5b58f120",
        "accruedReward": "5b5947ee",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261733f",
          "beginScheduleTs": "6261733f",
          "lastUpdatedTs": "6261733f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0d46ca",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261733f",
          "beginScheduleTs": "6261733f",
          "lastUpdatedTs": "6261733f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CQKCLwLd8jzU7r5zpTgia9m72G7sB9FhtfqmxNYmMDpP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9YH6eoWWqY99mj5CCjVEgQMJAaikTCEUH7V7miohXDJG",
      "vault": "4Ae6HBEUQPA3KeWrHCLCqsetgaeRR8vGGH8H18D1koUy",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1458f474",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7ViWKL1X1tJoXVNWsyH3zQpqc9JvL9R1XuMT1zRn9X8z",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3NbLkj4BqTtdsci8h3ByX1fnoZ5kpNWfKVSqaB33BkW8",
      "vault": "6R62yZYmwJQtSxT62sJRAVPcemV8cscTuNEarismtvjY",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0a613c9e",
        "accruedReward": "0a613c9e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7nM8q2sws5sJz9H5tWWJCHnGqFUhF2cvjExfykqWHoQu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3aJSwVDbUPJmo2i9evYiVX8512h4PPeW3psuCY3dDwzP",
      "vault": "F7foMg7rFu8wTReLkgBhDc45SvQJVuHRX88FEvRS5JJt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247678a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "013cec73",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247678a",
          "beginScheduleTs": "6247678a",
          "lastUpdatedTs": "624816c9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27527f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247678a",
          "beginScheduleTs": "624816c9",
          "lastUpdatedTs": "624816c9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "39vSETPhR7F4GQQc1AzAhmtLX4jm8SSTofgtd8mhEwwf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FCS2W5oQtSS6NwDDDmmJuLNnbQsdtAw4TWR9FP5bTXU2",
      "vault": "GQf4uYVkkoRByJ9fiRFnmVnQGPTnJJMnyVSiMaNj3YYm",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "53xzfxY5zxwaV8fDFJkwDhJgovZdHcPwMMTEGxcVDqvc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F93sNmLDsukp7pzdLFy4UhgQcAkmpy5uyby63RtXckBi",
      "vault": "5m8nxxPigYX7AB5g25LRp7hHVa4hGCMKsBSBcu1JZxhE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6269b777",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269b777",
          "beginScheduleTs": "6269b777",
          "lastUpdatedTs": "6269b777",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "050292",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269b777",
          "beginScheduleTs": "6269b777",
          "lastUpdatedTs": "6269b777",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4RVo7yoCCPDEdQYgb5PZkMrP6mwJgKoAAKLvgXGz7Tio",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DCLLfH3j5pkq3TADRWxZRrCA4NR4G53YyDYZfKqqcPSM",
      "vault": "k7Z33yVdi8HgqDZbjVhEMYm8opTEWw51nQHGhRuTYrH",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b48a8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1a1aa3be",
        "accruedReward": "1a1aa3be",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b48a8",
          "beginScheduleTs": "624b48a8",
          "lastUpdatedTs": "62665f49",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "237161",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b48a8",
          "beginScheduleTs": "62665f49",
          "lastUpdatedTs": "62665f49",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BZ3yCuak8h8NP3ZSSwzeZQsrms4UaVXUj66ndzF2avR7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HeB2Ga8mBYMVr6fEJ4ye4ZR9KSEaMWDH88np21wn4ywm",
      "vault": "FNu1fzjohTswPfgxWnNgg3fhGGyW83931BDWEfhBdDyW",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625695b0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625695b0",
          "beginScheduleTs": "625695b0",
          "lastUpdatedTs": "625695b0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "182459",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625695b0",
          "beginScheduleTs": "625695b0",
          "lastUpdatedTs": "625695b0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5MSqwLdtESF3KhNErB8QFsorjxjpLWaesbaZaiBCzz2S",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7yDZKjhX8oMrBpZx11kx4GoAXPHhMBicpMHjNmT9mPRZ",
      "vault": "H9XG9AuUrZUVV63E1MuxHq9oPpLmUA8TBsUE5EtQz5hF",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0f83818a",
        "accruedReward": "0f839d91",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BqJvR2dxijNGcE25a1J59gX5QANUqPcmEx7H6cie6w9f",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "33DxVHV3rXPbR8HzzZV4wVFcZ9oXXyPxN4c5sSjQdr9P",
      "vault": "C8LdvoKGoJ97s4qM4XoyxgT9pkqv3D2dwo2jJv7SiPEu",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62467118",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "b875",
        "accruedReward": "090900",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62467118",
          "beginScheduleTs": "62467118",
          "lastUpdatedTs": "62467ad7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "de80",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62467118",
          "beginScheduleTs": "62467ad7",
          "lastUpdatedTs": "62467ad7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4bY1uXpovVLHV2HPoNBo7YqtAKhEZ9BFjyoGzyXPSpPQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DiV38quj6R8BDaLfzwLa2mNTXvKfW5KtM3x5SLCuyrYX",
      "vault": "G3Tf47ug7iFQoi66fW7twUA9brZS7TqhDK9yovctdVAo",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "17c7d36f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ET69AkSvZg3FE1vPwyVog4bFRGnzMSdczjwDmAq5XQbC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BC5MhADiZXn4gVpQw6Tg8njm3YMcbwQAZjjb4AA9Uwum",
      "vault": "GfSgM3es7cVEGGb5UzSKpFRdV6RCbfjL43c8jde1fCWH",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "069c7353",
        "accruedReward": "069c82b2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HwzXbjZWeB3Urks6FU7VJn8LcsJkhLdYjAEJh8dX7fjN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9tKj6VwMgQF7hko4r8Eij7bUf6NPTggdVTe6p4JX1w8C",
      "vault": "BjCUXEoRrhwdTnh4eaVWNfTUhcTbE66xxn8NLHp5Qh9S",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6264ae5e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3b2ca676",
        "accruedReward": "4f47452d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6264ae5e",
          "beginScheduleTs": "6264ae5e",
          "lastUpdatedTs": "626968c6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a0bab",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6264ae5e",
          "beginScheduleTs": "626968c6",
          "lastUpdatedTs": "626968c6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "98ESH2WLJqcFzPa3JA4WvwJ1haBDitERV8vB65x2gFqz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4B6qFpxnY6HTS8LLqyc6UnRAVH4z4d8sCiQgmNeoaRSF",
      "vault": "BgWVsbmL6h3bAEkCnBSSz8gXQMCKCC8azXLXeya4utii",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62589eb7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2b971201",
        "accruedReward": "2b9a8bc2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62589eb7",
          "beginScheduleTs": "62589eb7",
          "lastUpdatedTs": "62589fca",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "161b52",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62589eb7",
          "beginScheduleTs": "62589fca",
          "lastUpdatedTs": "62589fca",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2HuR4GCWqH3X1V6JgACr39PbCKFruMJqki5iaucFpp1S",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7wgZmbwh3GdJyhWH3JnXM43FaUCZ3EkS4hxCxetVu9CK",
      "vault": "A2PBXvySfHemAKAmD2bddRk9f2LKmLj5EnCSKtZ1tpMy",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b2d7c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "028c35f6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b2d7c",
          "beginScheduleTs": "624b2d7c",
          "lastUpdatedTs": "624dfec7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "238c8d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b2d7c",
          "beginScheduleTs": "624dfec7",
          "lastUpdatedTs": "624dfec7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DYe4ddXP5ivqjzHzXaTBQ3fE5hXzHZQBoyWBMjNrQ4tH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CQUTdvx2e9xnsgabMvJ2CEnXFeZ54C5NqZjCtCgpfkKP",
      "vault": "BkhYfyUL18Z3NB936czm6e3bmPtrKrpwH4kCccWs67BM",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GLvoYARZAte7ma2HmLMimHkeeEQcNg8cuufVYzUjpH4r",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4dSGg6NdEkwqeq55KUi5R722d3eFV5ptjCctYSxyPGBA",
      "vault": "7JMiaWtTD1iJkzxkwSvo8SRPHu2Hd7MxiKD8GKVKHZ68",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62697810",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62697810",
          "beginScheduleTs": "62697810",
          "lastUpdatedTs": "62697810",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0541f9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62697810",
          "beginScheduleTs": "62697810",
          "lastUpdatedTs": "62697810",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DGkxKPoXxX757QB8GJkGm4PTwd7hbAuYgoqK7kXKt2Ae",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GfsgcBecKrPco5iYsUv3tZBpZtez7ANnvyCwnEfWNWFA",
      "vault": "7E53Pk9R2nBoaTdK4Tc5LSct9Ee47BJLNDATcJoTdodt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6269ce3d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269ce3d",
          "beginScheduleTs": "6269ce3d",
          "lastUpdatedTs": "6269ce3d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "04ebcc",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269ce3d",
          "beginScheduleTs": "6269ce3d",
          "lastUpdatedTs": "6269ce3d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2KkWpqNYp7TVS2C5aabip8mQyY6kqofrWwUP5RtQzcQD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5c22YotUJuRT1z5dHsxEEsLiN22epzGhwwR5n9HEpTVA",
      "vault": "4UmdWZqFxqy12dbuKFhWVCGvMUubZJwKsVk5KsAcUAA3",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6259cde0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "02f8dbe9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259cde0",
          "beginScheduleTs": "6259cde0",
          "lastUpdatedTs": "625d1753",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14ec29",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259cde0",
          "beginScheduleTs": "625d1753",
          "lastUpdatedTs": "625d1753",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Ah4yn3i62WWb5JLNpYa56qeyf5nXLxjif7J1zT33qf5D",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5WFrAqHuHS482DGMkPeBJctMiCA4wrvqv2CsaMgxZt6g",
      "vault": "DdRhg79v92bWLfqoveLHuAw14r5HJ7vdoqdf2bcx9uaU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6252c5fc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1481f684",
        "accruedReward": "1481f684",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252c5fc",
          "beginScheduleTs": "6252c5fc",
          "lastUpdatedTs": "6252c70e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1bf40d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252c5fc",
          "beginScheduleTs": "6252c70e",
          "lastUpdatedTs": "6252c70e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "27zt3SmyLNSKnsuV1Vm28EMXVp5AkdC7rxJZXBA6nvSn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EfwjZSGLvsubDLb2VL4TJYsK8pVAUtV7uhzSiVd3Lwxz",
      "vault": "BhtwUVdwHfqqEFsxRcvLhuJUDBeLZ1pkXHA2vDtvUvGE",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62516324",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "120b9591",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62516324",
          "beginScheduleTs": "62516324",
          "lastUpdatedTs": "62516324",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d56e5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62516324",
          "beginScheduleTs": "62516324",
          "lastUpdatedTs": "62516324",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HRnx1Qf38YZVYfg87hUqMkB7LQhCWxhny2CEDHVYrW4A",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7xyDMEkxhuLztbm1gHKZspaYfGrREBYfQRhrBPXS7Fbx",
      "vault": "BouV4cH65yAiaBfBQrXqS52mAKtg7iUUjrpyjLf5zWot",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6266c2e4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "17c6b215",
        "accruedReward": "17c6c7c8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266c2e4",
          "beginScheduleTs": "6266c2e4",
          "lastUpdatedTs": "6266c2e4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "07f725",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266c2e4",
          "beginScheduleTs": "6266c2e4",
          "lastUpdatedTs": "6266c2e4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2vRxpRPoUxUkXUYNL9SpggHCy3ySWQetYM3PgShcXDk6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Dbf15BxewajcJJHXGnnVUYYQyoph6tsnuYFAWke53Rbk",
      "vault": "BGtmkz49oZy5nMBAkMKhtaqctyupTogtNEQZH1gBAb85",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "626434c2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "33474529",
        "accruedReward": "33474529",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626434c2",
          "beginScheduleTs": "626434c2",
          "lastUpdatedTs": "62669b5f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a8547",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626434c2",
          "beginScheduleTs": "62669b5f",
          "lastUpdatedTs": "62669b5f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6GwYrC9tnYXgsGdjwJXk9V3zL74AtG9gT17FqZJ8gAo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8FfaRX7iDc2VzbueFE8NJFyyq6w5kVHmmcfVjxvRwghd",
      "vault": "GauYTwTK4BngDJmLcVnfiXqF1saiUw5mVWJVtVJAowkK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62559ff2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0d56d33d",
        "accruedReward": "0d56d33d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62559ff2",
          "beginScheduleTs": "62559ff2",
          "lastUpdatedTs": "62646078",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "191a17",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62559ff2",
          "beginScheduleTs": "62646078",
          "lastUpdatedTs": "62646078",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DWCb7vg7bY5ypbYamEPC8iDeK7AaJKMWzxzcdBhwZn72",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Dqi3PHYB2MYPHihHSE4SoptLdvPj7F4aEpM8W4aWp5UP",
      "vault": "GqN8FwD5gu9zxXPqkSib2F47qXcDohDojAfbvD1kyeaj",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "625353e8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "13f16a06",
        "accruedReward": "13f16a06",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625353e8",
          "beginScheduleTs": "625353e8",
          "lastUpdatedTs": "625353fa",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1b6621",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625353e8",
          "beginScheduleTs": "625353fa",
          "lastUpdatedTs": "625353fa",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "zN6S9e1v4j4t1UKQvx9HKQKKMSzsUinumMPhuo6oKTW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Gznmo1btDAJ54CQBbn8e4q4hjssorxftRdRYKDGsqtPw",
      "vault": "8H6nh887mA8pDTSAncztcDWjXDwQQTa7M38PYrSC6x4E",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62528b15",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3bc11af9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62528b15",
          "beginScheduleTs": "62528b15",
          "lastUpdatedTs": "6269ab42",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c2ef4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62528b15",
          "beginScheduleTs": "6269ab42",
          "lastUpdatedTs": "6269ab42",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Bse1Bgv5aHMxwX7syFjkTaRp44gw1uxqzkLD7iyaUdkV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "G4REBqNj2C8adB1mNSpaCpBrnA1sSdFcDntm56pwZC7K",
      "vault": "BEngprZvukpKpJCq9hUtA4KZMTLufrhZgFyXoK3bbLoi",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6259b111",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "03c954",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259b111",
          "beginScheduleTs": "6259b111",
          "lastUpdatedTs": "6259b111",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1508f8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259b111",
          "beginScheduleTs": "6259b111",
          "lastUpdatedTs": "6259b111",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Ecv4ArZBrakt2b39XArFQ7LRNnj6GYGEx2hvXoTT5Wuu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "69WN1WEQwNGRRPkCBszsp25JUvEZ9zwKVMXL9nog7fdr",
      "vault": "AUano3AS4uayt2fDjETDSB2okPXuhfVVweA6gUqzR6Kx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b4d91",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0993bcdb",
        "accruedReward": "0993bcdb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b4d91",
          "beginScheduleTs": "624b4d91",
          "lastUpdatedTs": "6255e4fe",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "236c78",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b4d91",
          "beginScheduleTs": "6255e4fe",
          "lastUpdatedTs": "6255e4fe",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Dubp8CHgq1KUybBvjqGnSGxpB6akRsXJkT4Rvc9DT1vi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3FU9EzQRsfznRBsAMMBkZH5RBRi82JUdvvaemkXCLtQB",
      "vault": "4AgyhrSre7JSdRv46wqRzpFpFQU4pwQkdCi3FnF9y1kg",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626a9785",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0d7e25ed",
        "accruedReward": "0d7e523b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a9785",
          "beginScheduleTs": "626a9785",
          "lastUpdatedTs": "626a9785",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "042284",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a9785",
          "beginScheduleTs": "626a9785",
          "lastUpdatedTs": "626a9785",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HhEmTAy9YGvJGNwmU5bfy9mD5fLBjsTQxZV2DT6nxgGF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "yhYcygNqwcdS8nx8yizM4hyToKkTuHfyWxN22yWPkqw",
      "vault": "3m3kjSc3RWtLS5kc99yqZ2hhuNqAtzuVDKLE7GDFV1Uz",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Fpi5KzzoBYYmoaSJY8GTCpYGuMzrWnxBvGHBHH8Bdeux",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8QmESoRUjkVijyvJ1oMBoHtz9QDZKDyPECxcZzr9bg3K",
      "vault": "4MuwQV33xaA176UxAAE2y9mo6skzjQUWbAQMH8ftjmRy",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62477021",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1b91a454",
        "accruedReward": "1b91a454",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477021",
          "beginScheduleTs": "62477021",
          "lastUpdatedTs": "6265ed37",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2749e8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477021",
          "beginScheduleTs": "6265ed37",
          "lastUpdatedTs": "6265ed37",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3bDtTv2fryG1za2EjLRqop2kwz9ajj7Vce1L88HhBpcF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GjC3U6JpRWqnp7SZwhk5rwKcUWoY5Y44ozXY61BFjWXh",
      "vault": "FCjjp5aK6KhyVm6xNVr2tptgAfVjUAW1azgV9AUv2zb4",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "625c8425",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2d3ed485",
        "accruedReward": "2d3eecef",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c8425",
          "beginScheduleTs": "625c8425",
          "lastUpdatedTs": "625c8425",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1235e4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c8425",
          "beginScheduleTs": "625c8425",
          "lastUpdatedTs": "625c8425",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DJ8NtS9C2J6MwbEjDLtToM3xJkh1nD4b6se3rZK5aPjn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9qGp1HtnftBGuJMGxQpKV8ti1UgZVsPYoPzrZc1icuSw",
      "vault": "DERaLKg3dhsGcEAsLuchtxaVWM1yenuryZGL2qJhdKFm",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E2tVUGQfPYMb2UKM6G4Si5zXB1hViSnbQSjtNagkqUnU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ApsapCQxBR9aVLmd7VZxDrdeh5T7x8bSHq8Z33n7pr7z",
      "vault": "78Sv1VJUw8VqnAGhVdBqHMPsRv9nSdJ6rttgRvkkxsp",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "62547761",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "4162f485",
        "accruedReward": "4163d2f5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62547761",
          "beginScheduleTs": "62547761",
          "lastUpdatedTs": "6254778f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a42a8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62547761",
          "beginScheduleTs": "6254778f",
          "lastUpdatedTs": "6254778f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5oZg4sby4nD7ZTk4oJ35C3U3WWDxFzgx4QNiUCGDjB2f",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "69AxXkvSi3QKT9FEFBcZx6ZLF8nrao67JDYa5MVLDfxS",
      "vault": "6V51ZjTtvDeRJXcNnHxYyeJeNmcW9ZKDyfqr4Tby5p4Z",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "048b3507",
        "accruedReward": "04949a43",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Bsn3RR1qU6HZpruhJ2xBvTnw5UTdMkaEfbrhBHjrSB9T",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ACFdfgVs7PuY77t5FzNibPkCGrtK5TrJn1XmJ4Z2f59V",
      "vault": "3MFh78DSQN7MzStRCPEgdnhkGXpj6ai7wtcFd2aMkQg8",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "01b8b160",
        "accruedReward": "01b8b160",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "D975SpHfFQjBkCXkBqYVNCezwJMU7bwDyJFQCd7gnZAG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "aB46Fxk2SNZfw9EcXozb4ZntZNytP7Nwcvua93FrA18",
      "vault": "JEBghzW498kRgMy3pjnidoSGKbXSLYuuYnpmHeqjEt28",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BcfBo4XLnWBceqYnDsKE33T1JS3pppDtwEDT1hx2SFML",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Df4EXMfQ9rRsve8q2PbDj9Qv2DBZzaAF7GoUJxw9LYa3",
      "vault": "7scU1chYBA6UppXprVYKYyz6BemycSTAfYTPReUCdJBG",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "03476725",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8DgYWVUj6Hgq9PDCivYHMXXXBvUjMKQSzg2CAYSpMZRD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4gqR4QgtV4G85KvH2Bb9mtopYtg8ieNqtPh254mesqRB",
      "vault": "6nxUxuohYjWZrnoEGUbyzCuwhsKrSbvLS8a9mMb51sno",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62478c70",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "38f0e797",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478c70",
          "beginScheduleTs": "62478c70",
          "lastUpdatedTs": "626708e2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "272d99",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478c70",
          "beginScheduleTs": "626708e2",
          "lastUpdatedTs": "626708e2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2KKyVh4UQrdXUUspHgcRBSJojnn4EUeoj7mcwnGw3Pmk",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GmB4gXdUnToANMYvb8XLtSiR9bguuRc5SnzoxSC21rWA",
      "vault": "EcgJG6jEh1JkDN2VvToonGvAvw17XfeHmKrMAYSxEZ3C",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6258b86d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0d3c0d36",
        "accruedReward": "0d3c0d36",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258b86d",
          "beginScheduleTs": "6258b86d",
          "lastUpdatedTs": "626614f7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "16019c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6258b86d",
          "beginScheduleTs": "626614f7",
          "lastUpdatedTs": "626614f7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "dpbD2y9Dp1CsgxxYrdoFoRdU9CbQnDfGqMRQwsJy5Kv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "65MT3CF9t3Bw3VV75bu4qEBLsqpKTkQtyHComRC12EkM",
      "vault": "6M6r2d1MXFrVR1pTBjJT9BL6K58pEZ3RBAV9kiyxW5Er",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6257755b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0bce56b3",
        "accruedReward": "0bce56b3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257755b",
          "beginScheduleTs": "6257755b",
          "lastUpdatedTs": "62638a90",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1744ae",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257755b",
          "beginScheduleTs": "62638a90",
          "lastUpdatedTs": "62638a90",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7q75h5uXdiSkPiVkgQGLfgFsgu8vFnxxJZkZAWDB59E4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DhXFpBoXjAQmhhJUSoDYgm6VayTnhWkoWn2uaNA3ANMh",
      "vault": "AGYDgNy13RKm6YzDBY2JibJcnA8qpRb1kP2nCH2Ye1Tz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626a9f11",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "32a46b08",
        "accruedReward": "32a46b08",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a9f11",
          "beginScheduleTs": "626a9f11",
          "lastUpdatedTs": "626a9f2d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "041af8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626a9f11",
          "beginScheduleTs": "626a9f2d",
          "lastUpdatedTs": "626a9f2d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GSk8stCMU5EyRx3APgusNXDyUevf93ifkBCB7vxTddSk",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AzrCp2wiCwxVh98aAHMCisV4KQrvMcp6CKjS3ZVY51Go",
      "vault": "D3DRY8hhRKjM4kUFL96dof3ZFYVQ3LJCgMtJr3DgDeQX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6249132b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1f4bde0b",
        "accruedReward": "1f4bde0b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249132b",
          "beginScheduleTs": "6249132b",
          "lastUpdatedTs": "6258b936",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25a6de",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249132b",
          "beginScheduleTs": "6258b936",
          "lastUpdatedTs": "6258b936",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4Cahc6tgAZhv25aHy7uaekr6RbbaRSXSbtyizqVkXNcE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "B22fxKMhBZ3KWNG3xQXFSRKS7vF31N6gf4fuH7c4CKEz",
      "vault": "H5ibxyQ8Qo7f7FYcUMsHRip8UMKmmwFmzfFrjgMTRuRQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62546b38",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1f5bfcec",
        "accruedReward": "2f4c0dcf",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62546b38",
          "beginScheduleTs": "62546b38",
          "lastUpdatedTs": "626315f7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a4ed1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62546b38",
          "beginScheduleTs": "626315f7",
          "lastUpdatedTs": "626315f7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CgyHrbSkaDZHPnp6eHhGC9xxZTJVhzL7XPVRRarGx9ax",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DUsbPH5WApq2ReSXSduiNm2EowqogEfz56QeHNHe6RQW",
      "vault": "HeBvwn4q1pmde3rgCoqNEH9JgHakHBeS1hm4B3rqMP6R",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247b06a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0b7cf476",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247b06a",
          "beginScheduleTs": "6247b06a",
          "lastUpdatedTs": "624e0aa5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27099f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247b06a",
          "beginScheduleTs": "624e0aa5",
          "lastUpdatedTs": "624e0aa5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HLFvYfgd3PP99YPiqFxFUyfZXJFNZrsyGGLfrtZu1ju",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7P6LMBHHFrPb1maJk4VSLbnrTZ8cmz49cEQM6bNKLGuZ",
      "vault": "3jSTNFz26yNMpPH3cwKTuCmYGcBdxEb2iUJJrgUNRNCg",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476891",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1440da0b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476891",
          "beginScheduleTs": "62476891",
          "lastUpdatedTs": "625dce99",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "275178",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476891",
          "beginScheduleTs": "625dce99",
          "lastUpdatedTs": "625dce99",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EEL5YMaRmLoNsZN2FYQpVnCdfrYtZ1di3sQRXznh7Snt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2WAUVgqqgowMtyg5LRzWjcbg5841uvYZ14o3g6HxSNXB",
      "vault": "8WyCQG8Xrp3zBHtzpgdkXJq15hLnANyxgvxKJvru2PL2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625b4679",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0222c424",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b4679",
          "beginScheduleTs": "625b4679",
          "lastUpdatedTs": "625da327",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "137390",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b4679",
          "beginScheduleTs": "625da327",
          "lastUpdatedTs": "625da327",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "47buHGsr7NDU1yVZBLCEvjE49MRe92zUndAj7959MgZT",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E3Va7AfxaJWnFrsXHVz78EfVrw5D9BKJZSpmJzcJGgGC",
      "vault": "2kLrThFi9V8qzRZWVEYuZwotCaT9Z44J4RWK4GgiMiDb",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62572d17",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2fb9a27a",
        "accruedReward": "2fb9a27a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62572d17",
          "beginScheduleTs": "62572d17",
          "lastUpdatedTs": "6269ab36",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "178cf2",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62572d17",
          "beginScheduleTs": "6269ab36",
          "lastUpdatedTs": "6269ab36",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3YvyExiGPo6WEtgEgQ4ZosKYjw7j4Q7po22EAZgBWbAw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D5tWbDGSBURHTDQnpXFYvzbjTMn6mePQ2UwbGvfCto2D",
      "vault": "6YdByVygtcKwMNamG5BWrcpoWGisv5fqQ57gL3LADpwe",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "032b20d2",
        "accruedReward": "032b3031",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ASc6ARM3KfcMdevVwccR1SXvTzLqsdaMBpu38xxg9itu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7y4mGJyX4ifQNFZBHF95Zpj2DCzYWhZYa35V2oqr89fZ",
      "vault": "A9XNYZ3MCQ2xg8JVQU4SumSGDsdBfAGxJprF7aPgPUN4",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "62539896",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "286ba9bb",
        "accruedReward": "286ba9bb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62539896",
          "beginScheduleTs": "62539896",
          "lastUpdatedTs": "625ca198",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1b2173",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62539896",
          "beginScheduleTs": "625ca198",
          "lastUpdatedTs": "625ca198",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9dL7opoNRCEw7cHQATgBLRFKeHjRKM5gc2Et3k6tieDR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7oUUEdptZnZVhSet4qobU9PtpPfiNUEJ8ftPnrC6YEaa",
      "vault": "BVYUoDBXAjtxypLDYaenNuDuSoNcf9Sy13bYVo3pkYtA",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9pM2giCEtZ57ncQaUpqb1iScVHFzTbwp5bfuzKXWAntf",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7xbuB6gfBpVA7eRfLodjrsXS4P4hevtHCmmynjTuq1bw",
      "vault": "DTC6jPzGTT73cDMyrFedZciQCU2qsTJCmczsgUqDMEyb",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62671281",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62671281",
          "beginScheduleTs": "62671281",
          "lastUpdatedTs": "62671281",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "07a788",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62671281",
          "beginScheduleTs": "62671281",
          "lastUpdatedTs": "62671281",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Drm1vjcirV5BUfoEzE6kE8XftYepNNRzFBXhQUqr9GAn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4QtoJ7RYYzEVLDbnkcBc3M7gn1t43py2p37sggoiYgzr",
      "vault": "B6pbwXaZiPgetneaGSFbbFpPC2iMj96eyetT5hLLxTfs",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6267982b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267982b",
          "beginScheduleTs": "6267982b",
          "lastUpdatedTs": "6267982b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0721de",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267982b",
          "beginScheduleTs": "6267982b",
          "lastUpdatedTs": "6267982b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CQxR974JUppkPKApX8mfaL49TYxdSEdwxwYPrvx25nQk",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Fo7aZDoRvMedwoPFTMGnRokadTfLYAMk8bw1A9ZjztJr",
      "vault": "EJ9iiCYu4NCx8FfofcqBWXsaqiWUmzSaodSqgT6HG14L",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "625c2961",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "19387faa",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c2961",
          "beginScheduleTs": "625c2961",
          "lastUpdatedTs": "625c2961",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1290a8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c2961",
          "beginScheduleTs": "625c2961",
          "lastUpdatedTs": "625c2961",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6WTASbxsYRECvygzfM4RVtm3nHgnrfbuJVEt6LfPfQY3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D8vWQHVZRnXN9GBfqmevrCRJRML1WaHEZ6WQztv5JBKj",
      "vault": "2oPQE3KQBybXz1kk7XYFJpdLibfNwy6L5L3bcghB5igv",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6265d6fe",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265d6fe",
          "beginScheduleTs": "6265d6fe",
          "lastUpdatedTs": "6265d6fe",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08e30b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6265d6fe",
          "beginScheduleTs": "6265d6fe",
          "lastUpdatedTs": "6265d6fe",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9QMCXJZGvMi6YFYcgWwrXiNQFRVPJsAVS4x4zSCob1X2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EC1SHSoAt25PzhCZAD4hsuTg5VE6BoX6UdcCdCtmSg4c",
      "vault": "Fzbuw2nBdttD2v8iDZjZRkiZLoFJL3fAeFYJ9sv91BhU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62466e13",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2fdeed",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62466e13",
          "beginScheduleTs": "62466e13",
          "lastUpdatedTs": "62466e13",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "e185",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62466e13",
          "beginScheduleTs": "62466e13",
          "lastUpdatedTs": "62466e13",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9vL8a5dtbPcRF6QWjF6PHcX3srckEWrc3PVyMR6rHuwL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9i5TGFZ1CTQBY6p8vBNLtTFCjesmoFjeBF7ktZUzmN41",
      "vault": "CWbtheYXMbb7v9nmgLZWZHPTJQ33BdJN5B44H7K8aZ8t",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626403ec",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "018b5ac1",
        "accruedReward": "018b5ac1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626403ec",
          "beginScheduleTs": "626403ec",
          "lastUpdatedTs": "6265b927",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0ab61d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626403ec",
          "beginScheduleTs": "6265b927",
          "lastUpdatedTs": "6265b927",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E4tdV4EpB9UrAEgbxL5o6oLcmQYG4S9vzcVnEjPqnHNv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HFAEQ8C6rFvaY3p19AaqqEr1oL5bYmgT5JiWxXBf5czc",
      "vault": "Db41tYfsCHK6oyV5P6MDqrefKwfW1WQjTxMMtpq2DJk3",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62631858",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "7619f00c",
        "accruedReward": "7619f00c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62631858",
          "beginScheduleTs": "62631858",
          "lastUpdatedTs": "626a21c3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0ba1b1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62631858",
          "beginScheduleTs": "626a21c3",
          "lastUpdatedTs": "626a21c3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HupS2cfxEA27J6dmsugPRoLCkdaJBUp6x2eLxv3iZRwn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "37swg2U7mC5YG4pX1E6zChGYkLX9EZ8NimKNEm589NUp",
      "vault": "5nnBK3dgb8KoYLmwUYhq5ddTA1fvhonpCWgYt1EAnGDa",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "01ce1813",
        "accruedReward": "01ce1813",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5HmWiYkgBti9NEhPjq3YULNDDBkPwShnC89Zft79c7mR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7RQzisEmHxXhJsZ2qRYRLTYikE2uYLnER6QuZma6RT6U",
      "vault": "EAdo51iUN8ZwweSk43v14ru8i9kWJurGgXKqB1Jx3WPc",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "12c105c3",
        "accruedReward": "12c10fb5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FWfcTmkLdXd6NPNBLf3cM3P2tBqNyyfCao2oyG17jezz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CE2WhGk24wNi5weBZdrsc28PCdpVDej84z9hf58U96r2",
      "vault": "FVTiGrAzJMvye3DdM3D6LDUNZbEDEuCvFhvDS39A8qFy",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0577f565",
        "accruedReward": "05782551",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3Wcjo4ENCSQHQFxdQHU6k5biUqAz2zVUU2BK7roLDVyT",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Hx1T4UMTZWjma1Cc7XESYDN3q3uuhwqSs1XkgQCbBYgP",
      "vault": "4F8zqwa8E8UpPC5wNu6ZyXKfLLAan6WWf7H1LfyhaCuc",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1b9bde14",
        "accruedReward": "1b9bde14",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BK6ycuyDX9XwBcR4q5o9vGP2hxoNN7btbbYiaQnyR3kz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Eb6TQpd5XHpfgG7ntjiEN66uEBhMuC4uuTX6mHod4FTu",
      "vault": "3ty9V2Y2whNz91XYfaodNkm6wTXH4QaGvtmLgWw8iQLH",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624b8778",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "316ac21c",
        "accruedReward": "316ac21c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b8778",
          "beginScheduleTs": "624b8778",
          "lastUpdatedTs": "6262bc40",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "233291",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b8778",
          "beginScheduleTs": "6262bc40",
          "lastUpdatedTs": "6262bc40",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FXVUmbbWPeLGA4Vc4CjaS7fjJzW1TBo9Quig8a1mevZx",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CQGn2XeHjHCxUZszmoPTCHqvZJuLHgYCLQoRPmDL2act",
      "vault": "5g6W2H5yYbvhr5g6BqfREBq1pr8ZzsGM61yyiZ884KP6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62618975",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62618975",
          "beginScheduleTs": "62618975",
          "lastUpdatedTs": "62618975",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0d3094",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62618975",
          "beginScheduleTs": "62618975",
          "lastUpdatedTs": "62618975",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Gb69t1DsQF58yZsabXTL1S2FsfgKpEj9g7v1HkTG7YTA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7YG7hMvko5RiSYnw5AyBDxfA8njdduVqrkCAD3BvY59s",
      "vault": "FSkwPB2xhTrD5TuMBfXs6dPsLFHrYhHPoZD7ifYoxkZ5",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "62526529",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "220c9ba4",
        "accruedReward": "220cf610",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62526529",
          "beginScheduleTs": "62526529",
          "lastUpdatedTs": "62526529",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c54e0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62526529",
          "beginScheduleTs": "62526529",
          "lastUpdatedTs": "62526529",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6sYzr8vHvaBadpF11YhyWFuJwyWmV27M1EB3yhx34AHn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7bHvK8h2kCDX6YD2sQjozjHMdnNDnGgnorZo2AHJ5z1Y",
      "vault": "2Zw3uDhyq8rVNTpLEqLjRuEE8k2SyfLXR1pMjcwNE8iC",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "3b8fbc9e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2jLUFknEYybHTX7zeyBaQBSE4NAQz8RkruqUiWJ9iuQT",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4k52Qx5vN6Bb4YTtR1jUpygrxKWx2NvT4CKKnxHxfEML",
      "vault": "3Pmks6o9PvkDW84wXYw8ihJJPKKMHm84tDnrm4R1F9cy",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2a6bef37",
        "accruedReward": "2a6bef37",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4zRnuhMwp7vR53c8MDjB4SFHvKhmtSQpM9tC8NLfcZiw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2bDwCNz7oaZacDNiRKbhAMfnid8hAmqsUEAE6hNwNxyg",
      "vault": "6i81RiCJQ1B7nkGAJZK9HzB9w7xTdT9T1adaPQyJy39Z",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5AoCufeXVwjajXhHiYJwskpbJvDTV4zyR6JxcyFET7mR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9k4CLuxRgpbJgzi5SNAKWCpd9PqGoUXUfvdz4LbeAiCi",
      "vault": "3HM9xhErWqoWqTY2KXzvwE4qqbyVunRF64ekh49C9aco",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "624a8e7b",
      "cooldownEndsTs": "6260deec",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "142d5466",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9DYpAGewgusrVLQ3YAxe7KGSH1HghWYXePuLAj9FM8yA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BMquu7BHXtNokuW5Vaa9oLniLH32bddVtScpqQnRRU3E",
      "vault": "EfDZuBRDsKqJt9c75aHeUQ8yWc6NwRTr6dMHZYR5sxGA",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625c88c6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "25a6defd",
        "accruedReward": "25a6defd",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c88c6",
          "beginScheduleTs": "625c88c6",
          "lastUpdatedTs": "625c8996",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "123143",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c88c6",
          "beginScheduleTs": "625c8996",
          "lastUpdatedTs": "625c8996",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2mnA4RL84fbqmU7frFw6uL1SHi9eexzW11k4NCu4nDG7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Fv1EA6L8K34pXHn7QUstiRmBoQSemejdxRqTGRbNqX6h",
      "vault": "6q5t8iHf1Y3pc77he6kiVP2BJ5F5jrpwqachwaPLZw5F",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "625ee8fd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "01201e7f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ee8fd",
          "beginScheduleTs": "625ee8fd",
          "lastUpdatedTs": "625ee8fd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0fd10c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ee8fd",
          "beginScheduleTs": "625ee8fd",
          "lastUpdatedTs": "625ee8fd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4LcdBgg3wixRg78mEZhFESwabGSZ6imSmG8Wj9L2douA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Hx2tw5bcT8euSencSgeQAufrynhHpTusWPafhwWY9Q3S",
      "vault": "EvCmtb4X7UXQMXXdE5NVzQ2LtjTu3koSjkNqV4uQim61",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "11429fb3",
        "accruedReward": "1142dc48",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3ahTrb94U49VSdaM1T1KyuRafqJR3KRG8n8fmGGE28wN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BgvGgakipGzLYZa8kQ2FiVc8zYgg7vftBpq4MaH5Jg5B",
      "vault": "4SgDowSdQTgiJPeea4piAiNmGDRbFTrudDZssb7JcAf1",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6251cfc7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "12ce66fa",
        "accruedReward": "12ce66fa",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251cfc7",
          "beginScheduleTs": "6251cfc7",
          "lastUpdatedTs": "6251cfc7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1cea42",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251cfc7",
          "beginScheduleTs": "6251cfc7",
          "lastUpdatedTs": "6251cfc7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FZptZzEiVVZc19hmC9dLn2EvBaz9RG5dEthzLYG8xX5v",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GbmoiiWWRXHPnew3pXTMf7jKp7Ri4EPr9UReCzXq8m4G",
      "vault": "23fQ9cQSYRc7iVbLkbjf5fNZNFKaseYJNfaapq45Gvqe",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624c05d6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c05d6",
          "beginScheduleTs": "624c05d6",
          "lastUpdatedTs": "624c05d6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "22b433",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c05d6",
          "beginScheduleTs": "624c05d6",
          "lastUpdatedTs": "624c05d6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FW54uZPS8MmC3LVjaf4ti76A1kMdwq3JdCkxCAs3ECN7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9wq4X3iXun2sPgJZcpBnStL3V1sZEgaa1UymrddVG5em",
      "vault": "86mRAsk873HRxfvpXGbyWAcwCozppEAaJD6zgwTUh7GY",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624923ab",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1e5e9d45",
        "accruedReward": "1e5e9ffb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624923ab",
          "beginScheduleTs": "624923ab",
          "lastUpdatedTs": "626ab9c8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25965e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624923ab",
          "beginScheduleTs": "626ab9c8",
          "lastUpdatedTs": "626ab9c8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "926VWwExtZDq4GiPE1szaZbnWjLK5xySCjkHEycERnN6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8HXnYZ631d98hkRjJgyxhswiMCXf1Q8hv9bBTRgu4Vmw",
      "vault": "nCWJxeYKGyoagyGggEGuWgznE7tb5fgkpkzkLt7ujmN",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "15489142",
        "accruedReward": "15489142",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5mr6i6Q3QCDVFNBL6z1YKev8YsCQeesC8doGA5mjEVc5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5hTZwrtqqmGwSdohNFTqr2nBGNk2BbouGDBG31hxAVdt",
      "vault": "Bc7cah72t27DBcqMSkL5ZT2W1rQMh5PFXWwyLG3TnB5W",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6256743a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1e3e744d",
        "accruedReward": "1e3e744d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256743a",
          "beginScheduleTs": "6256743a",
          "lastUpdatedTs": "62619bae",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1845cf",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256743a",
          "beginScheduleTs": "62619bae",
          "lastUpdatedTs": "62619bae",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HKMKDowMg5sY13A4HobdC3H4VF8f5nvA6MvXtYseysXj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3vdwao796Gj6P95W4tnyoYqtrsSjM579zWtuhQjBBqzM",
      "vault": "CFRCoDKqQzzdUW4p4X2ktV9cqfmK9eMf88KUGc7Yotxa",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AnpxCjBM86MdEMa8QsCkVmxPoeueTKSWqU9xxnZSuhLn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3uGkJCiprUoHczW136hk4VbZh1gXF9KRe6Qjf8yqa583",
      "vault": "F8SHeXdVpm8KBvbU9AjBo6B3pb67NgyLEV47Jmd4qAK3",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624ea772",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "09668896",
        "accruedReward": "0966aaf2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624ea772",
          "beginScheduleTs": "624ea772",
          "lastUpdatedTs": "62590d07",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "201297",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624ea772",
          "beginScheduleTs": "62590d07",
          "lastUpdatedTs": "62590d07",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9CV6yoRt3co1c3NozWtA8uio4wJcQsVwypGGvBYyDxWD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5Jg6S34D42jwaX2cADXpdNjj2rPJrkRM9utfZ9wmjsHP",
      "vault": "6uUkCz89T1N8uSBUh4S1Jz2GEe716tN84iKtE6xTituZ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8MMcK9TBYLkviPkr7EHmpXS1VPn2UbWUTtyGxPESCSip",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7fuJD6XB3HgTiSojyxGoFxxGRUSZgfuU2BC3yKhTFibg",
      "vault": "2DE4trhUfifcZWV7kuRztGEeZBxwN1Nua6wDSVLNRF9V",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "625dcb91",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "72b032ec",
        "accruedReward": "72b032ec",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625dcb91",
          "beginScheduleTs": "625dcb91",
          "lastUpdatedTs": "6269818a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "10ee78",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625dcb91",
          "beginScheduleTs": "6269818a",
          "lastUpdatedTs": "6269818a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J5YqsFJAxiCinfbmzdwM7CWrLVi3VQXqdhjEByghaPfH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E3PdVVwRzPYM5ufXJEHxinDBVxcx4ZT91iTH9bGbsvsC",
      "vault": "D7De5QD5MkPkCrwU7KDJEYfjTLie78snUC2AXecBYV4V",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0e97bf3f",
        "accruedReward": "0e97bf3f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9iKqm1rYSBSZe57c8mGowAGk7hcPzG37t42mdzF9Lvoe",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Af49ttGPBTqdjokjFeJ9imzJ5W2Sq9VGS5dLhdwNjWz1",
      "vault": "5Zpo27pWSFBxutHdwTTTFwRipyFZ1E71Ghk587i3F28z",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6248a1c8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0ed1fa50",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248a1c8",
          "beginScheduleTs": "6248a1c8",
          "lastUpdatedTs": "6250d3b3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "261841",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248a1c8",
          "beginScheduleTs": "6250d3b3",
          "lastUpdatedTs": "6250d3b3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GQMaamJUNnRw8qit8oDHTnXeXUCkPh4i3vgJGdwU5sKM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A8vWvY7tfuo49VzMVHVQ48tmUKVm2tVZsUj9uuev8pYU",
      "vault": "3DoZg9uW28kh6zj47aonyxek7odEdxdYVxyayeHM51Dq",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "624769c9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624769c9",
          "beginScheduleTs": "624769c9",
          "lastUpdatedTs": "624769c9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "275040",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624769c9",
          "beginScheduleTs": "624769c9",
          "lastUpdatedTs": "624769c9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HMZEKet149jxgAFpqXEPKmgL8GqM8ZRFxZVKhEHznL4H",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6YWmhF6x56mPXE8qGLcJ3xoBR8ntX5eXMXhKNDQeEnX1",
      "vault": "5tLvzW2tXZkiT89CoJxyD5RGLRqTtEn6J9e5jxDNvUcn",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247adf9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "12aa9e47",
        "accruedReward": "12aa9e47",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247adf9",
          "beginScheduleTs": "6247adf9",
          "lastUpdatedTs": "625c52be",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "270c10",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247adf9",
          "beginScheduleTs": "625c52be",
          "lastUpdatedTs": "625c52be",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "85PicD7HC8VcZxbGqud4Ftz9zmkEWuxPgHDBNrfELH3Q",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F4bXmC9GMQf9MzWUo3UqAaj15JnWmGmSdJ6p4UKYGgVc",
      "vault": "CLdqCP6FYaDpL2VrrKnfN4pvxwPe8VvBMuchAzX94f5c",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HPvw9CQWmwPufvJTRWTfzZcTGHWLwmR7NhpMv2twDzYY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "KaMy9vEg7gUMQSUjMsQ7f7aqSyeSoyDzubhVDBFyNJS",
      "vault": "Ddy5HAXgdBzqby3guGjM5Y2isrC7v9e67kphcWyDaddt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6251bcd0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1285d695",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251bcd0",
          "beginScheduleTs": "6251bcd0",
          "lastUpdatedTs": "6251bcd0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1cfd39",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251bcd0",
          "beginScheduleTs": "6251bcd0",
          "lastUpdatedTs": "6251bcd0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EVob4oFFuMtBZCFp8YDutNSDxXUWkFTvZQBXZUvtJYEX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EPd4QdeW2WVY2vfUzFqn2QiNLuELpCr9ehGnRuRHLRb6",
      "vault": "DnyPb1wUkPZntWZbZjWeBEF9JmWZ11p7ELgaTtYamEfG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6252119f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0e66552b",
        "accruedReward": "0e66a68c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252119f",
          "beginScheduleTs": "6252119f",
          "lastUpdatedTs": "6252119f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1ca86a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252119f",
          "beginScheduleTs": "6252119f",
          "lastUpdatedTs": "6252119f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CH8U5MjcMEL8dBbHVmYVfUfnmFGrGhvAntRJtBWpMJr7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6sLJQUetcjrMsUXpA5RjdNDhcsaR7w8h26Tb3AM4iC9N",
      "vault": "471xycv2rDSNPhoeAErw5mcDCYNufSa9g6K9A1B5ARqN",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CtfaRQUS1QSDNGtWJe981rQitiZZk27SDf79UdZrAkrw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2kEAepqqmX5JdQedCeoe4ivgUycSF7XgcpuM5SYzCfCK",
      "vault": "BEG3rnv8ZVAPv3EN7mjf8LEEre3MHQTYjaa2Y9njHKCa",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "626511cc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3386a872",
        "accruedReward": "33a4d53b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626511cc",
          "beginScheduleTs": "626511cc",
          "lastUpdatedTs": "626511cc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "09a83d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626511cc",
          "beginScheduleTs": "626511cc",
          "lastUpdatedTs": "626511cc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "13on67apwz6JKQCZFNNdTB1FSfvWdCCyW5imnKw96CZR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D7eb6a7KDDyzWZmmBBxpcAVdwqZY3x4cYSGiDwubXcjA",
      "vault": "2uquRmr7RgjGx5ktY1Q1sbGjR2yhed2VDMGVRCh3y4vh",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "624a08e4",
      "cooldownEndsTs": "6251b9a5",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0de86423",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BaxEMF6LxLk9ihciW3c3nAHtsLuJriPuvg7GhUufLPFg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9T22QTKntYexrFSKgpFWYbZppWfAFKjebHG1ie3moSEp",
      "vault": "2utXzqTTcrGxMkmiDPgMNGr367EZjU1ib1VUx6ovBtWG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62693dc0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2d06b8e5",
        "accruedReward": "2d06b8e5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62693dc0",
          "beginScheduleTs": "62693dc0",
          "lastUpdatedTs": "62693e17",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "057c49",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62693dc0",
          "beginScheduleTs": "62693e17",
          "lastUpdatedTs": "62693e17",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "C8nwbAqz3rKiBxHKtb3nD2HVgYutGXT2AWiCrJgx3VT5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "r3otiiAuTkKDC63xhBYEtwvZWLYk5oLfDHPHz9CSgMf",
      "vault": "HgeKqAxHWNyr75DE151FuFS7S3U4yJJCT8fMnSPzKhQH",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5ssu2crJKc4fvYmQT35dxPyy4rp4zLmiw29cPcvYqDoC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4A3xWsEn2QjQyPC7d3md32fMj2Qrm8ZtQB9YFNrimYJt",
      "vault": "8vcWpy6L2BgnX8ij3NgoNZAQVcS5bBtVkonJ9JNcHjuk",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62596c30",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3d70d568",
        "accruedReward": "3d70e94c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62596c30",
          "beginScheduleTs": "62596c30",
          "lastUpdatedTs": "6266a870",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "154dd9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62596c30",
          "beginScheduleTs": "6266a870",
          "lastUpdatedTs": "6266a870",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "87y46yjwQUV52jYcJ6atxKcvAjt8yb6oh1ZjVZeJmZth",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CYUu6VGpkra7tqQxFX8Wc2DGHp7Y1boipsy3KJFidBgU",
      "vault": "AYaNa616QpA4fav7bifdnXZMhgLzEPiGD3J54my6LiXg",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247eef2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "18be965b",
        "accruedReward": "1cf33283",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247eef2",
          "beginScheduleTs": "6247eef2",
          "lastUpdatedTs": "6267f323",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26cb17",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247eef2",
          "beginScheduleTs": "6267f323",
          "lastUpdatedTs": "6267f323",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BwQD2muLdWv4vGqKshexqy7ug2RgBvjWdvjViVkXNLrY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GWgmxJZjtNMyLLuMj7y8WESFT8BxXU8cmVnWSwYujxxe",
      "vault": "CLpvk3yTDdGPQLMgVPxb5LiK5ZwLWKWsAy3jFmS3HocP",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "254bf9ae",
        "accruedReward": "254bf9ae",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GxqMcX7i74Ht2Z9ZHYEp72ERL3qVd4LVrUXExmxn8yNV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DjBKUZp8X8q7Ng6zEeaTme7kMH185onDXSttKdhC18rj",
      "vault": "EBRNUMJfa6RBUp9zEcFyXxfwmkXj7EcGXGNpnjQQ3qMC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "08",
      "rarityPointsStaked": "08",
      "minStakingEndsTs": "6247c69f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "a33901c8",
        "accruedReward": "a33901c8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c69f",
          "beginScheduleTs": "6247c69f",
          "lastUpdatedTs": "625e56fb",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26f36a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c69f",
          "beginScheduleTs": "625e56fb",
          "lastUpdatedTs": "625e56fb",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9AtakSpzXHDfhL4ufjHhUKZrCXXk7KKFrDPVhw16T2Eu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FZYSqpdb1c1gXGp4atem3gKTrDwR9QP5bRPSfPeSVjhE",
      "vault": "6HnDWSbEybfgvAxRgHmKaD6o9CQyVBNAPDchsz3nPAz7",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6253b0d5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "085e05ed",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6253b0d5",
          "beginScheduleTs": "6253b0d5",
          "lastUpdatedTs": "625cf1bd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1b0934",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6253b0d5",
          "beginScheduleTs": "625cf1bd",
          "lastUpdatedTs": "625cf1bd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9f5Kn8XBkBFjr6fCpqDogKPBjjBnAzKDeXpVWVKNxVem",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9L1BkUns7ydLJDbQsADrStf2pcVV27gVNxU5kXTBxBkN",
      "vault": "DDvBybFTiWN2TxUriBqGsQuhMEPabt4FVxyRy76uzc1E",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626acfcb",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "149c6ec5",
        "accruedReward": "149c8391",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626acfcb",
          "beginScheduleTs": "626acfcb",
          "lastUpdatedTs": "626acfcb",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "03ea3e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626acfcb",
          "beginScheduleTs": "626acfcb",
          "lastUpdatedTs": "626acfcb",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EofmLFAKJHpZ9iNaNLp24bpSkyzTTzBjKNr1jh7qRzZJ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AqvqQ5pMBnSuHo7ZZEYsphnG4BFY6nxU2niKegUu6Tbu",
      "vault": "7mhuWbxzUT8YCMpmJKTuQdQ1oJhE7XzMW7TfLeyzWAs5",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9vcFMkdXf6Xd1VaToeBEvnmtJBtEKo5UvnGvSUMSLV6u",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HonF3giZpn4xL5w89ELhVyFaPiArEcQYgdp4P1S58F4b",
      "vault": "CvekDeesKojswb4gjvyFt2PfXFfRiQp37t3CB2rUdHpP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6261d7dc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261d7dc",
          "beginScheduleTs": "6261d7dc",
          "lastUpdatedTs": "6261d7dc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0ce22d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261d7dc",
          "beginScheduleTs": "6261d7dc",
          "lastUpdatedTs": "6261d7dc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HmPtwoeH7jrD9rwKy67vCGMznaYaNRTW4zxonPAwoTJq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "H5k3JSRr6hjwV9oPRLjobmyXnEzmvRpeFM6tyeg7LMPT",
      "vault": "5va31Fx3on8qMPZdY7nhVvu3D8ioYkPXss9EP9M4JvqW",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "H73fzjVvCiyswCrvQuXzZp87GEEejfyeGM4CJGvkswvd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8Zdas1rU2He6qWZAFJ1JRZWyXtxzqAEtKZAoAbJL9jFY",
      "vault": "4SJrYTbrDLzKcU9EKoU9p5BUZLL9ypsX6TYxRfDz8Ho1",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62513758",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "21bd6bd2",
        "accruedReward": "21bdb341",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62513758",
          "beginScheduleTs": "62513758",
          "lastUpdatedTs": "6262fee1",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d82b1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62513758",
          "beginScheduleTs": "6262fee1",
          "lastUpdatedTs": "6262fee1",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6TEuLxurfJwcMcfWe96Y6N7LQ1SYyGwA6x8VK7H3R7D9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GZcbasFyVy7ZMXAz6kMDW8aoHXQCpCs1oro9D7Vc5gd4",
      "vault": "6tE3jSZxnnm9VyaqHFtKpiATLr4mU91vyjndTcUKUzV6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476847",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "149ce622",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476847",
          "beginScheduleTs": "62476847",
          "lastUpdatedTs": "625e341b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2751c2",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476847",
          "beginScheduleTs": "625e341b",
          "lastUpdatedTs": "625e341b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8ATNCbWdTYiEn56EXhp8CkADRX7Qou7unSTwUYvdUNev",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3nMoF9HhdZcePZVTVtWF9HTwPh7KnkvZANhBX87i7Z1Y",
      "vault": "AVz9TSF7T6GDUxVRaeDmjn295DLYhwbu7ZkESwdPT4cf",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "34sgzZGg2Nm49ABV2vRu5vVZFL63atLJumDW6a93GTTV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FovjkyircuQ2YaxVaCtTq7Kih4jSSpiBBicqYnhobmLQ",
      "vault": "4GGu8B2pyLc8Hnr1FXZQDtWsRS6JGYc5TPJ6uJrCKTK7",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1aa6d551",
        "accruedReward": "1aa6d551",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8xtN6vgFYyhgxViYevN5W9wyqnb7Ug2Mi5BVC6U77pR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FL9VC3AnZYHvxNhfXRgFJMu52DG5Z2sMMymFyzi4yWW",
      "vault": "5rzTHf8BKzNX7uGZMJQnYXYVYC8UZZN9LGYaSREifmYY",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1cb5ffea",
        "accruedReward": "1cb61dc0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8i6TDFgB3z5QDZ7oozDp6mhd3LhBxjCLp7m4xoUWfiMG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3FfXCgn3ibYzTzBnhQbPTguwFXdKVow8qM6qCgGgnGTd",
      "vault": "27PnssSK82qS6PvJJBoGfR8oqtWQ4ot1upsP7wjbV558",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "05e05e44",
        "accruedReward": "16055cf1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HCTmHqBM9QT1CKEcBz77dYnYi5MaW358U3tuMwLF39hi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4GKDn7PE6F2hkYtG8PK44P1e4HtFy2eF4RSrCUtLNx4v",
      "vault": "8ZMnunSP9krcWSbMLZe3ybWvdt3PN1LJmXureTTZnHs7",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0c21b438",
        "accruedReward": "0c21b438",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4c5A4KFSf1VVXf64cEwGEqkSM6eCBgZCsr5J1s3YMnRj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3drnafq7HqismZpmH1Jp7d795jNgQucHX7e1ivjzXAXB",
      "vault": "9Nf885XJvdsZM81ebXG1cBYvKp16DQYGb9qjBS7wsbCA",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6266b252",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2d0ea99e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266b252",
          "beginScheduleTs": "6266b252",
          "lastUpdatedTs": "6266b252",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0807b7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6266b252",
          "beginScheduleTs": "6266b252",
          "lastUpdatedTs": "6266b252",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6azxgAnHqwPsw3tQ6hGUaM52u4YBxsfxUQaedgZ6HvJo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9CQHfv2VrC9tSnR3uQfZTMDiYDHfP67y5Xnonk8cfRFE",
      "vault": "BKZSh7RsJ1F5b86mNeq3Z3y2oWmo3iJ6MY9KXGue5QbG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6252b708",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252b708",
          "beginScheduleTs": "6252b708",
          "lastUpdatedTs": "6252b708",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c0301",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252b708",
          "beginScheduleTs": "6252b708",
          "lastUpdatedTs": "6252b708",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "T9F2B2AC6SbtGfnukwXBSSEzTmFTSypHiruuHbbFQ7i",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9ywd3tJQw7UTWtZRJn9ofLXaxDtoE8x76KsviVLqYkd8",
      "vault": "4DaNSNH2c3NwmGA6K6wPuKPnVmWQkREWUW35cdh5fPGd",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62475fc8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0255ae",
        "accruedReward": "04162c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62475fc8",
          "beginScheduleTs": "62475fc8",
          "lastUpdatedTs": "62475fc8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "275a41",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62475fc8",
          "beginScheduleTs": "62475fc8",
          "lastUpdatedTs": "62475fc8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E21G6HQyu5dggB6pArwJtesSbAe1pcwKxyoS7uQwDDkM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "95idDNM2k5o1mjrp1DUWxF1BoN5jfMpARDCv7GFddG9c",
      "vault": "BPszLnnFEG7dWP4joD3n4oavyuFd1RrBEvryYByoRbG",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "144508a1",
        "accruedReward": "1445137a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7qyW1XPD6ZbydN6tgpqgt6iEkAxHvL2inZ63J8JxihkG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3PXKRWLNsuNxXG5GLRfkGyZENDnzxS7rD8HBXo8guxVV",
      "vault": "7tBgY3taXmpHKG4gA1xrGPy3TYMVu4MkkB6nGcYWFNFQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b995c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b995c",
          "beginScheduleTs": "624b995c",
          "lastUpdatedTs": "624b995c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2320ad",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b995c",
          "beginScheduleTs": "624b995c",
          "lastUpdatedTs": "624b995c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9foECYLPTH7wVbR9Lsba1FqRC3wZCYuEtLS6LBQy5PmR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6pTNLQeeyfjj6u3fLEuBmETzmKTN4Q5LADbNXdgkycRX",
      "vault": "JDaX1E38qehAKQ3vRbunhXezp1iQLsPwpm1jT7vQAEmc",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247a2d7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0f884f4b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a2d7",
          "beginScheduleTs": "6247a2d7",
          "lastUpdatedTs": "6258d052",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "271732",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a2d7",
          "beginScheduleTs": "6258d052",
          "lastUpdatedTs": "6258d052",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9wbGD2LNp85a2EDuJ1Mc2jXaAEVhTUGBb5zR3wui7pM4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BLgSG2v1U8BuZmuVsXCeU6AKJQESmAFsBaCUydvExums",
      "vault": "DunEbKpU23Fjj3FEbNh1JZxKEGhPbhYWp1LpBPwTv2Ev",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "10013c51",
        "accruedReward": "10013c51",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HcJMSSZYqcF8Hxv59odKirvUL61fbbUZP3PdkHJVBCm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8Z63scHQQ5VyJ4v52PJ8eDivB1opH2M5YyRarJ1p16u",
      "vault": "3KAxtGHaUJnRcgxiZBv1kUenVjcE12vF3CAcyP6FyyCo",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62613e05",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62613e05",
          "beginScheduleTs": "62613e05",
          "lastUpdatedTs": "62613e05",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0d7c04",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62613e05",
          "beginScheduleTs": "62613e05",
          "lastUpdatedTs": "62613e05",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FNquUDG5PFZkxBQxCY6YNXovXARMUJfg2RaG5KvfcJbW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8MaAkCMN2MTw8eMeQSVoqu3wWxPo2mcbMXTqafSKiy9T",
      "vault": "FgbE6YStCy4aXzR9NDn5cQeZAMe5GvvjXZKoy1xDCay9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625e7aab",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e7aab",
          "beginScheduleTs": "625e7aab",
          "lastUpdatedTs": "625e7aab",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "103f5e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625e7aab",
          "beginScheduleTs": "625e7aab",
          "lastUpdatedTs": "625e7aab",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8rxqrNu41tUbWpv4DKSiFGfZqnGnxwSJivfGudEZddEE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FtnT5WtFf68UTxQNsAXDzPL1mmRfrcJtEWVN6K8x8jb5",
      "vault": "34HwZxy3PNbqGVQPhuY48LcqP7SdNFBBwqeu3GpHoS8r",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6249c0bb",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "185c1f4d",
        "accruedReward": "185c1f4d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249c0bb",
          "beginScheduleTs": "6249c0bb",
          "lastUpdatedTs": "6264b162",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "24f94e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6249c0bb",
          "beginScheduleTs": "6264b162",
          "lastUpdatedTs": "6264b162",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6RnehzB41XnesAXEwyWH9mg2PWBv6hmXYJfwiCag58Zc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9yyAXboBhrXWsyhDhwRFKMWCMXPGDQxKjDdwMyveyX2r",
      "vault": "GrpFwbaKaEcMdXNHPcCMmoV4FvCjhqn7jdWQJyCjdVDa",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247a66c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1d95a3cb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a66c",
          "beginScheduleTs": "6247a66c",
          "lastUpdatedTs": "62685e43",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27139d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a66c",
          "beginScheduleTs": "62685e43",
          "lastUpdatedTs": "62685e43",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "C9bE2VNzAufdCy6ocd9TEHUkbyNX1tjaRiB6UY5cp16x",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2WXGKe6vKqvjyJ535xmTzHHpek7aMBc9PzJS8K725t2g",
      "vault": "tp7EeWb6qNUxmA6yEvzz3v7dx2Pz6AeA1q52A2uENeg",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624c5303",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c5303",
          "beginScheduleTs": "624c5303",
          "lastUpdatedTs": "624c5303",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "226706",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c5303",
          "beginScheduleTs": "624c5303",
          "lastUpdatedTs": "624c5303",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8R9NcGpRmDCxr7JYH7uUhCvPXoTdNoczPNBgs3yLVWdi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9xtA3FoqjWX3gyR41UjNSv5GffZJA2fwJ7ejse6EnbEB",
      "vault": "8qjeiuwYE3JVhDVdv4j2Cw7kCgznn3VbuKYTmNse9qTd",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "624773de",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624773de",
          "beginScheduleTs": "624773de",
          "lastUpdatedTs": "624773de",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27462b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624773de",
          "beginScheduleTs": "624773de",
          "lastUpdatedTs": "624773de",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BQgYTiGdVbdjRRPmUXRqPMhymgdVQX5YnqgU3TnY9Z8Q",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "61M1LCCAgQrtkezY41GCb4kryb7VfDLjwVzqWgsytUx2",
      "vault": "FHJx5Mm2K6xvmE8zB19nU27tN2Xv6j89UDhLgAtv4vRt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247c60f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "01f07291",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c60f",
          "beginScheduleTs": "6247c60f",
          "lastUpdatedTs": "6248d893",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26f3fa",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247c60f",
          "beginScheduleTs": "6248d893",
          "lastUpdatedTs": "6248d893",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2kwC98iHKkgaq1ri2FaEjvqarKJuyrykMnfcA5FQjq5t",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9jiGNdqvKyskP4Js9NajccQrtaVToWXmyi1V6KYuxQgb",
      "vault": "GK3Xuc19mNLyNtgJWHYXctbHvrpsU3HN9NUEqy4JJNGG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62576b29",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "074f3c75",
        "accruedReward": "348ef222",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62576b29",
          "beginScheduleTs": "62576b29",
          "lastUpdatedTs": "626743b3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "174ee0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62576b29",
          "beginScheduleTs": "626743b3",
          "lastUpdatedTs": "626743b3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5ymxf7L5bB8dGFmXPKTSEMYkXAmDohH3miWw81hVLBsu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8oa4zPaxHtBvBYyqPQBQKbmKimHwqbGZqSzy5tu6F568",
      "vault": "8c3DazNkkajHkrsoDTs8LzmA4Lx8r2g4r9Xrvqem7q96",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6263e14e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0a3eefb7",
        "accruedReward": "0a3eefb7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263e14e",
          "beginScheduleTs": "6263e14e",
          "lastUpdatedTs": "62698baf",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0ad8bb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263e14e",
          "beginScheduleTs": "62698baf",
          "lastUpdatedTs": "62698baf",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3kfomSoZE4B4xLf4rvj4cbXjUjpzWr4rVswwWexRCsu5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2tE3Wt6agApJGwYVt2rokP6yEJwfLucoaqec5HcphNmt",
      "vault": "cgZFsto8WqzZ5i3TiuyabaHzSpUpmxR8eS4BrTo9grv",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BUnCV51bpYpN9N7Ex5xL5HgHtSSDTWVpp66WGPmT3NFe",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D3LuzZN8iNBANxLn2k8J6dD5Zmd9VpBWM78ZpaB3HmJr",
      "vault": "GYjQMyvenxuP9Kv41odCKjoq4NekCLbHcTRgJqrGBu3p",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6248faff",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1cef",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248faff",
          "beginScheduleTs": "6248faff",
          "lastUpdatedTs": "6248faff",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25bf0a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248faff",
          "beginScheduleTs": "6248faff",
          "lastUpdatedTs": "6248faff",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "33bTmChi1ifsuq4TBQzn3QaFPfXiMsHx7CC8zvM9fEhA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CErcpWMXqoRJarwykcJ4iuMPTcaFYWMxWvXBPLL3Bfkw",
      "vault": "BSJwbeG1h2kB9GBwSwDNJQza5J5CM7mBj7rcqXFZTkA7",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626442ca",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "05dd3eed",
        "accruedReward": "05e239e1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626442ca",
          "beginScheduleTs": "626442ca",
          "lastUpdatedTs": "62681727",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a773f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626442ca",
          "beginScheduleTs": "62681727",
          "lastUpdatedTs": "62681727",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CDSc4zChJhuLfvM1LYSQaMhb5SVoe454tpbs8DyBZXyT",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5tCqPe7XNWSNy8aNLbBcqaf5i28RuEH8onsCHsUsvYmn",
      "vault": "9r5ew3HUjaUCuN85Adv2PwvGo7J4FrR1kUboYmCkyjgP",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1f6e2daf",
        "accruedReward": "1f6e2daf",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "61iMr15iEL3Heyw6nTGww3SHWu21SPcH3E7hWpNGVxp9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DuaxMFECGkCVwquaNX5A8f4Wfgic7N7buEg7zXV4zj4E",
      "vault": "E3qF5NRSUuPEiWpoy1ZRLc7YrtWR2GTPXUQKgZkQvxmr",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "41bYdAvLgY5e377XVKn6EJ6K69X1k9uXuprAKJ6jDNcu",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3aK7WE32o29YVDqiqS7tac6gg87xkFb62LqF2AEXnaT7",
      "vault": "H5DCxM6m6NqewMoUGPe9wwdR9pm8UefVMEC1V8L9naEP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62643563",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62643563",
          "beginScheduleTs": "62643563",
          "lastUpdatedTs": "62643563",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a84a6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62643563",
          "beginScheduleTs": "62643563",
          "lastUpdatedTs": "62643563",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DijeQSzmp7Lz2sTqyiCPD1b725RnKEoQEKdQtz7VwQB5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5uvo5Y4CRtMkT6qPbBTVG4fWaVQLpLFzCr49uk8uYGuS",
      "vault": "6gav88dSNUmykANJUiFwQrXgqMKAkrWBcgZQfw4sM7fo",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62488728",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62488728",
          "beginScheduleTs": "62488728",
          "lastUpdatedTs": "62488728",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2632e1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62488728",
          "beginScheduleTs": "62488728",
          "lastUpdatedTs": "62488728",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GiSRN3ZjUQYPZbHdVFKL6X5wsQYY1sDMtgiztYkMbMGW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HaUA4X2d5mWunoWhPxV3GbNkjaMbqUjkTZpoYZAcSACw",
      "vault": "2TpieUrk8CpANJLAVHvN3cSkYbXJXh9JEAZwDkBzbq2P",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247a12c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1ce39fc7",
        "accruedReward": "1ce39fc7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a12c",
          "beginScheduleTs": "6247a12c",
          "lastUpdatedTs": "62679424",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2718dd",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247a12c",
          "beginScheduleTs": "62679424",
          "lastUpdatedTs": "62679424",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8eQAtarEXDPZ8poHWZ92dvLryuUgCYX7RQcai5v4VLB3",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "31nkFBFMvQ5rveyngMzQprzURwYHjhEvMgMSjnhmWj9u",
      "vault": "3FQKYFM5Am4hYXsBCjcdz5UGRgzCsnqwA1HRCe4XtfEF",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625ddadd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2411d95c",
        "accruedReward": "2411d95c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ddadd",
          "beginScheduleTs": "625ddadd",
          "lastUpdatedTs": "626472ee",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "10df2c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ddadd",
          "beginScheduleTs": "626472ee",
          "lastUpdatedTs": "626472ee",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7WVDATrfyPHHoBD7973mfT8zdxBijddQbhkVcRhUcSzD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BBKV5m14EecGnGSWLesZRkzR2YsSc7pjsLECdHZLBFzN",
      "vault": "BtNZKt8MkvTaoFwJH92R981EgZvCoyoAEVBy2mFLPY9K",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "05df7d1e",
        "accruedReward": "05df7d1e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3arx2YYoqdBirXe4ETHvC4TpUzxrjwUdrTgsgbDji6DY",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "968nijqCubqNBKw8qFKTaT2m3X7V1TYXUKrxVS4VARvS",
      "vault": "CBjmEhHeEFzAqkZQureTuQ24oinqXFPjhke3DS6wZsmP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6252b2d0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0e352ee2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252b2d0",
          "beginScheduleTs": "6252b2d0",
          "lastUpdatedTs": "6252b2d0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c0739",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252b2d0",
          "beginScheduleTs": "6252b2d0",
          "lastUpdatedTs": "6252b2d0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9cygy3QtC6AifiFWvapsaCRkrznWUnbXf3FvPuN2sUx2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AbGrgnK4Za2wBpL3iBN7TtpekTP5YSkjFQ1ns6y2e7F4",
      "vault": "ySwSfqs4QsopJJcNSzwoYTNYt5NeGCqTRisTBhCU3e5",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625fdc9f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2e6257f6",
        "accruedReward": "2e6257f6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625fdc9f",
          "beginScheduleTs": "625fdc9f",
          "lastUpdatedTs": "6262b530",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0edd6a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625fdc9f",
          "beginScheduleTs": "6262b530",
          "lastUpdatedTs": "6262b530",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "gM2zdiNiFP54nx9tRwofNyaKJVi77GEiF1DifTfKs3K",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3M1fuSKcSiNFpeVcqvYiTCnTHNfT6ZJBjmZU3HYFoxjD",
      "vault": "7suywXyd5a2eHjJjg2AUMieqVPWZAdksFQW1m1RNTWR4",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625db8a4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3c12f921",
        "accruedReward": "3c12f921",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625db8a4",
          "beginScheduleTs": "625db8a4",
          "lastUpdatedTs": "6269b9bf",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "110165",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625db8a4",
          "beginScheduleTs": "6269b9bf",
          "lastUpdatedTs": "6269b9bf",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "D1ac4NdSS5yQDoh2BVVDrPHg4tDQSpGTentD6x7vqods",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2DHQfykNgMV7bDnycuydvYdJAD7sQv9P4U8Up2q8j7ZC",
      "vault": "4zUoiX2uW9ytqhbYot2ohkN8GEoc1Pgf6SsqiAeA28bY",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "624b1430",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "57c7b243",
        "accruedReward": "57c7b243",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b1430",
          "beginScheduleTs": "624b1430",
          "lastUpdatedTs": "625ed5cd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "23a5d9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b1430",
          "beginScheduleTs": "625ed5cd",
          "lastUpdatedTs": "625ed5cd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AyNE8rkpe63DXmcx8n5DYZo5AQzPaQevgq3jW2CDkGV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GjsgmWiirtRp7zmDjLUKHpcb5tYBoDr9ym61cdeY9Xpu",
      "vault": "Cectfavx6pSi66a8WhkwzazghwhfmLJycXbfEepSVfTn",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625bfd78",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "19d4e96f",
        "accruedReward": "1ac839d8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625bfd78",
          "beginScheduleTs": "625bfd78",
          "lastUpdatedTs": "625bfd78",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "12bc91",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625bfd78",
          "beginScheduleTs": "625bfd78",
          "lastUpdatedTs": "625bfd78",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EbnNVqCd9zEp5oVYir59GwsT3MxmHiaTcpVrmmGnviXm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5EiH8hMjiiGHd1Sav1rWKipEPsk6ZVK3kENL8BH9Yo8S",
      "vault": "7UnhF4t9kKB5cQw3gymvuEDMjRkAVMa2CPgQr37akbZC",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CWXkzm6DQ1LH67HV6ra3JYbCdDDYqq3SJ12xV911hPba",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Fo13EMeTfzyckCr4ecrPbMJ27odUG3z8RK1RbkAroPNv",
      "vault": "5p7hWSaW83UEpRi5kxK68nP49rB8uDQTGc49Kt3a7R2J",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62641b23",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62641b23",
          "beginScheduleTs": "62641b23",
          "lastUpdatedTs": "62641b23",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a9ee6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62641b23",
          "beginScheduleTs": "62641b23",
          "lastUpdatedTs": "62641b23",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Adb7DvVrzCD1L1AXQaCnVdYFoF2SaSfzJ8GejTBwfUdZ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "R6nJnQSdYwwvQivvxU23CAKRUm7sxKUhyRRYPJQA3h7",
      "vault": "Aij4dPYfLcMbs6iu78SeFitce8WgpujEUQQx4CZi3ZZ9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62484749",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "28f28506",
        "accruedReward": "28f28506",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62484749",
          "beginScheduleTs": "62484749",
          "lastUpdatedTs": "625eebb3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2672c0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62484749",
          "beginScheduleTs": "625eebb3",
          "lastUpdatedTs": "625eebb3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J5jVkN1JDqJLCjWwmgSKDxnrBLBQWkRixfEsmrGxbqX2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Gwfwkh4Pabkue29cv2xDQWYQKNT4P1Qv2GvDptLc5J6X",
      "vault": "3eWw8ticCjq3fwX61tq25bLTNYiGqohEvnjEejtpE7Jx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62490e18",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62490e18",
          "beginScheduleTs": "62490e18",
          "lastUpdatedTs": "62490e18",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25abf1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62490e18",
          "beginScheduleTs": "62490e18",
          "lastUpdatedTs": "62490e18",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "48zoMtNdqaYdcjvhucgpSbgGNXRwTYjzt4vX6CoHTueG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "JEEeKHQ83tTeXqjkrsvFkxyGauS1CChfYS1Gtrj55jyA",
      "vault": "HFEyV3RAfj1UtKn3uCnc5s8c7bPiNhjoCHBGs4Gm24SB",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "6e87ba94",
        "accruedReward": "6e87ba94",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6hAxRAFrKJL7fpLZjn5WnETWMmk7gJnQUMnFZmno7oLa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HFCRS3hV2tpEJUfMCxeqPHxru12pUvCZPfSp9YraYKUS",
      "vault": "Sq36wkTrZyQBMR2qnN2PgLqm8mbcFrCUutgmDhmq61f",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62584b36",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "645e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62584b36",
          "beginScheduleTs": "62584b36",
          "lastUpdatedTs": "62584ba5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "166ed3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62584b36",
          "beginScheduleTs": "62584ba5",
          "lastUpdatedTs": "62584ba5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5byo8eA8qAoN43zz6pTE1f1AC83AuXDfPJGPwqbNUCKo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9PCsyxAmwcTtS7iKiqCvhZxLng64qAQmeuSZEwRVPdjx",
      "vault": "5ku5gJD7G3TbRP4Car6MakG9KF148CG22bbDQiSuCpB9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6263e018",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "362f761c",
        "accruedReward": "87be8daa",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263e018",
          "beginScheduleTs": "6263e018",
          "lastUpdatedTs": "6263e08b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0ad9f1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6263e018",
          "beginScheduleTs": "6263e08b",
          "lastUpdatedTs": "6263e08b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AkyeuvanVb2Gkircg1LNsJ8aceEkt8bCz3ovBKMksNhE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3qVkyPdoC6RZkackyCijxyM5HMF1Zx2QbUjKEeGhEVHy",
      "vault": "A7aNb3fnSxzWnPXCuDj7eXXHhP3JbcsUJq2FdiPANf9b",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625ede54",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ede54",
          "beginScheduleTs": "625ede54",
          "lastUpdatedTs": "625ede54",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0fdbb5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625ede54",
          "beginScheduleTs": "625ede54",
          "lastUpdatedTs": "625ede54",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Fk4JrtvxqSTk5MZ6qcStE87LdoAf28TzkiWqbex9Mh9e",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6KbsF6H99vhrJ7oAGKpcSenKUECZ4jBvE2UbRtVP2giQ",
      "vault": "5xH9ELx4VFh9yrPX86G1FKWwk845HpZj6fc8YTyGJBCJ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "03a4b8c3",
        "accruedReward": "03a4b8c3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9E8sVLGGYhpFBJcyfWJM3zMdRDjxULcQaGaSXXpe8yWD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3McVsmaCRM8KJ8W83oRkfjrqDdnNpB4BTRWfQZqybkn1",
      "vault": "qUC7rgFiX7rooX2NnB1YDSonMnvwoFHeBjQUdrFawPP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626605d4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "01f9b489",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626605d4",
          "beginScheduleTs": "626605d4",
          "lastUpdatedTs": "62683507",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "08b435",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626605d4",
          "beginScheduleTs": "62683507",
          "lastUpdatedTs": "62683507",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "F8pNhcLzKHd3jAWt2PkNEbmTQH53zUGSXxvpVVvXgFgw",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DbCp5DKHkNLpDnKJxP4Ej7xe8QqPTujpChu9eQ8Uxwov",
      "vault": "AvGoCCSvJFrHVTSUTwrbUtFuqh8RzqvPuWMuNRCQaSuD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "17",
      "rarityPointsStaked": "17",
      "minStakingEndsTs": "62596192",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "017fb0196c",
        "accruedReward": "017fb0196c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62596192",
          "beginScheduleTs": "62596192",
          "lastUpdatedTs": "62699667",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "155877",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62596192",
          "beginScheduleTs": "62699667",
          "lastUpdatedTs": "62699667",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GCmAAFUqbxac6EcvytK17xayRfDQ6tsc14dwRaddwkei",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6RscwM1tgrK6M3o1Apacr49upaTQLZ7biG9Be3D1gzda",
      "vault": "DsVpNBMgB5KJvKVyJPy8RqvoyUs7qFKqx9XGZL8tu4T8",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248d51c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1c30f64f",
        "accruedReward": "1c30f64f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248d51c",
          "beginScheduleTs": "6248d51c",
          "lastUpdatedTs": "6268027e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25e4ed",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248d51c",
          "beginScheduleTs": "6268027e",
          "lastUpdatedTs": "6268027e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DcwjdrGyemRpUDEtDLYpjEwTkb4GJ6YqTm18i6mksFZW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9cYP1E5BkJauW3VGCoNwYsPykrmnBMSPtzTMWNZUFsxh",
      "vault": "D9MqAqwQxkfr7wAJ8jHbUdnhsRtxbtXJ8XnA1j15S7mz",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6260d1ca",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0f33b0c6",
        "accruedReward": "0f33b0c6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260d1ca",
          "beginScheduleTs": "6260d1ca",
          "lastUpdatedTs": "6260d252",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0de83f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260d1ca",
          "beginScheduleTs": "6260d252",
          "lastUpdatedTs": "6260d252",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5qXRP1bD2bY4Srqxd5LSv4pgKg7qzhkaHoppwyBuxDuM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2ZMBgz6uksgm7f2t7ryDn9EEWQXVLWT2VoLQKEiaWVKo",
      "vault": "uZDEm6EAUx6aQUz5Fxqv4AMw1gnZntQZ3SWCeU4mu7p",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62668724",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "012c4fe1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62668724",
          "beginScheduleTs": "62668724",
          "lastUpdatedTs": "6267d343",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0832e5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62668724",
          "beginScheduleTs": "6267d343",
          "lastUpdatedTs": "6267d343",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9nP3VWbWFNUM2bmz8C8uJpJtB8ky4BtjG7UWjxRBbRxg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9rYbZ9dx2E6cuxkhwuxLPDAR9ccrgKHHghnb4PjCnSPi",
      "vault": "GpRzuPrL31BGkUwmfHGCn9FsCsHKdg531TNiVVW3rQzF",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6269cd63",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269cd63",
          "beginScheduleTs": "6269cd63",
          "lastUpdatedTs": "6269cd63",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "04eca6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269cd63",
          "beginScheduleTs": "6269cd63",
          "lastUpdatedTs": "6269cd63",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BNgE8s7B8MDCZvYTzKvCvrGn97FGAzoKiZkw1ERh8XH7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6eNsfAHiqLDt21qaYEMDyP35T8wTkCjvojHGsd399uTy",
      "vault": "Diu6m2yXgkDKLBq1uKhhBxFUimMh1MKDFDccw7v26j4E",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6259c4e2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1096a8a3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259c4e2",
          "beginScheduleTs": "6259c4e2",
          "lastUpdatedTs": "6259c4e2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14f527",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259c4e2",
          "beginScheduleTs": "6259c4e2",
          "lastUpdatedTs": "6259c4e2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6GJMNW7vYzsXtngjHWLcFStPpffZcC4DEYHnUEikZ4wp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AVmSauttQpbavBwT8NFqiNC3aeCn1tR9SDzSLWR2Kq3e",
      "vault": "3o6RkXquo1Kq7ghcYBeZz6VnkYXB7fTU1Y3fXrSsVs3S",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476da4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476da4",
          "beginScheduleTs": "62476da4",
          "lastUpdatedTs": "62476da4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274c65",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476da4",
          "beginScheduleTs": "62476da4",
          "lastUpdatedTs": "62476da4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DByneqa3xdUFy6YcWSfSYVn9ur2mJgC9qmc2EvyG2AQF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3cZLSJJSVnWNYomZ3HVEbKsSz15MHt3a24A9Ph9xGf2a",
      "vault": "ELgj3SnkLW7MLBYxnoKmEwMj7EzYsPQFfCadeCRGrA46",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6247818b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "392f5885",
        "accruedReward": "392f5885",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247818b",
          "beginScheduleTs": "6247818b",
          "lastUpdatedTs": "62672084",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27387e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247818b",
          "beginScheduleTs": "62672084",
          "lastUpdatedTs": "62672084",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "A1EPZezW1tzSLSBZoTgKmFZi8fXhYz1TsCGbcP2XBs7G",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8v7gT2WcmsyN2MaanRubcgCSqzi15xRptwNPhvPrQKCH",
      "vault": "9YBNW3X9PHFbmzfbKWVC732QK2uA3qbSRbmfMnc6JrpJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62483ff5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0f7824a8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62483ff5",
          "beginScheduleTs": "62483ff5",
          "lastUpdatedTs": "62595b8f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "267a14",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62483ff5",
          "beginScheduleTs": "62595b8f",
          "lastUpdatedTs": "62595b8f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3nNv8tEhzjQrvLjurhBgo4R6LBr5TdnTuH7mVH6wHPbL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DU4EJnA2z5HbnW8CzAuad9mVoe3cGycjU2HgAksTWVPp",
      "vault": "6urdsch8VZKR77C2JKKBVhw3sLpjmEdzM6y1Sh8ZJ3zh",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "7404aa01",
        "accruedReward": "9339d669",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DXmEdExNk1E7hRjFYR6XeQNu7abJTDfqjGPsEHJJqznh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E5uhdkRq5bgKVJeaQiuf9weV47MYFb9Ru7nJdcSCPhCT",
      "vault": "34srQrFJU8pKzXCQn2VDbta7TT6yVgB7jGEMYF3593yy",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247755f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0e06db12",
        "accruedReward": "1d570c0a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247755f",
          "beginScheduleTs": "6247755f",
          "lastUpdatedTs": "6267e7fd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2744aa",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247755f",
          "beginScheduleTs": "6267e7fd",
          "lastUpdatedTs": "6267e7fd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "D3vRQwKyoa9oCxYH7aRRW9H2JFQ5c9p7TsjUuezDzbjz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AXkkpaDQGyrGa5SLBt1AR2NH9C2Ar8hbfyYcibXN5rXf",
      "vault": "D25NkbbHtU5tMZSomU2JcKHupPMH8G71G37KmsxuyD8E",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624b38da",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "01f8bdae",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b38da",
          "beginScheduleTs": "624b38da",
          "lastUpdatedTs": "624b38da",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "23812f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b38da",
          "beginScheduleTs": "624b38da",
          "lastUpdatedTs": "624b38da",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2d9Ab5audKPSnt1YoQZqm5Dnsvc3uVgP3J8AF9nE3CEk",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "An5ukG68aQvFdyCoJSfwfPAEknVCCXze1rynVt4xdAAb",
      "vault": "BvQYpZk8QgMUgV3FY7wpV2q6mZ5a2JER9c9hJ29JsJL5",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624a9042",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "176c8453",
        "accruedReward": "176c8453",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a9042",
          "beginScheduleTs": "624a9042",
          "lastUpdatedTs": "626477ed",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2429c7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624a9042",
          "beginScheduleTs": "626477ed",
          "lastUpdatedTs": "626477ed",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BpYcLwj7dm5rpYPoWc7nXtGW6mTqV1pYP6JBKHiRoZgX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GsDfA5bBGyfUjBsduCt3yHAqrj6tKzrZZn2T5BX7Rp5g",
      "vault": "Gea3HvUqDgSW91TmyCjPcvi5BEZxrm74siaiN5y3ZGeg",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6259b263",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0c254da0",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259b263",
          "beginScheduleTs": "6259b263",
          "lastUpdatedTs": "62672107",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1507a6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259b263",
          "beginScheduleTs": "62672107",
          "lastUpdatedTs": "62672107",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BxGUiejbU3NXpAwS3vPowsMkLsWGNYZnvaGSvJwSqQv2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GM6MwPucmrNh46VJzKNQiTYUpzJDCBC5zn6RvSJMFJ21",
      "vault": "7PrP6sSGkrAMQbGd2k5TLeQFHKBife1Foikxb861KNUd",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62543019",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0b2a07fd",
        "accruedReward": "0b2a07fd",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62543019",
          "beginScheduleTs": "62543019",
          "lastUpdatedTs": "626088da",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a89f0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62543019",
          "beginScheduleTs": "626088da",
          "lastUpdatedTs": "626088da",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EXpdNVYNVvdQ9VzhmM1Q8WzvZyTEznq4qRJqaLGkat1Z",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7L4t3hNNSe95wUK8excnCh6qtSkaHSWBQgYuzyrRFkcy",
      "vault": "K1fJFkwqvFHkjCDVnAPTjydZiaWFYkpF4VWoSQK5at4",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6259aaa2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3d9ca0f0",
        "accruedReward": "3d9cbc10",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259aaa2",
          "beginScheduleTs": "6259aaa2",
          "lastUpdatedTs": "6264435c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "150f67",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259aaa2",
          "beginScheduleTs": "6264435c",
          "lastUpdatedTs": "6264435c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "KYi5UpW7vaMGavJgHzZsXJBQA3sCzQ6WmSbcZKGhWH9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5XWbfJeQFajvgBNDejBjeyyPgCgmzHsW4GtDqobu4sGz",
      "vault": "9tpESqJfzb5j9ZUoVv5c4RZqgHunJZbGpYyBJEzFwdqX",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3JdHENVK5Arax1qgZCuiRB78zUccPAqukoHjH8YqLYgF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3gvAuFsM5cQUyCugiwEwJNBgrxSjdEsorSet4646qcof",
      "vault": "kXHi6nt5ocNtqkrQYtKXLGa3N4nkD7eQskD8NEJW7K4",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "78d02b93",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DbCN2xQK8bmFMQXxEB9EmR8ona1eMTiuzJCE6rDhtJs4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FJcF5kgvEKPBYfutZXKPhxjtGnx5g6Y1U5kBnezUTvgh",
      "vault": "ALTUFvYqumzciLugQwjaYaQ3fPp3sLdZHTFRDWugyKgB",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "08b887",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "66mJgV7dekENPFwUPP45biY4xEEHkk9NoB2vgPgqfdm9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A9HU3rgr5zHgJYJpJQv5eQS9m9qdRLCdAaB3triMviy5",
      "vault": "A6eXHZHyuZFqiCarKT9peNGLu6zf5rweuUT1xrLcPVxK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6248225f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3c76edc7",
        "accruedReward": "3c776a8e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248225f",
          "beginScheduleTs": "6248225f",
          "lastUpdatedTs": "625e6cb4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2697aa",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248225f",
          "beginScheduleTs": "625e6cb4",
          "lastUpdatedTs": "625e6cb4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4WdspYocJnRdGVTQ6QT6q8ZRxAuYqniQzntmvbD2cTke",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Ht5oToBgq8TQ3yGUHZJGL7nodLQf5fQTgGwD7ZUAn9gn",
      "vault": "HB1AkQw96Jtf36dWR4dHkiF6RLdGHmBfU4wzW4Qgdykw",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6257fdad",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3d00524e",
        "accruedReward": "3d00524e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257fdad",
          "beginScheduleTs": "6257fdad",
          "lastUpdatedTs": "62620efe",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "16bc5c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257fdad",
          "beginScheduleTs": "62620efe",
          "lastUpdatedTs": "62620efe",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "15uUMzvJiqejqzXFoU7a2PjfbRTezbLrDfwwr4LDCwt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DPjyzuhzPozewFVyNgi7dDxcFVHtEekmbWiZGVAEZxKE",
      "vault": "7ugDJMAFmA3acw8ppNZdfRVmFJizUWFqqMdPJc4zKs11",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6259c89a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "04d081eb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259c89a",
          "beginScheduleTs": "6259c89a",
          "lastUpdatedTs": "625f1ba8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14f16f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259c89a",
          "beginScheduleTs": "625f1ba8",
          "lastUpdatedTs": "625f1ba8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HGBSgGS6Rc7LH4VRjaZqJMieSiXPFhMU2jj4Lv21gBcA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FMfELLagDHUEaWgk9Jg2ZSZCRMrniEmdE7szGUhd5pNq",
      "vault": "Decf63wuDPdTirm8y2LwAhHfWu5KDo4v2gTmxZxCLpB9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "625c842e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "733706e6",
        "accruedReward": "733706e6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c842e",
          "beginScheduleTs": "625c842e",
          "lastUpdatedTs": "62695c91",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1235db",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625c842e",
          "beginScheduleTs": "62695c91",
          "lastUpdatedTs": "62695c91",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EXvArDuvDz9i58YsGyurx8rRUYtVKGzdbFQuY3hRohMa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "JA1SesJ8M9ipWHhHh1bTCygEMf9aSxdj6U5G3LsPCBDR",
      "vault": "DFh4Q4xwnFTChSg6zAEBvLFjsjFF7FyYm6NUahHs6bGm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "6254a0b4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "5bdb11a3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254a0b4",
          "beginScheduleTs": "6254a0b4",
          "lastUpdatedTs": "62698eb2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1a1955",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254a0b4",
          "beginScheduleTs": "62698eb2",
          "lastUpdatedTs": "62698eb2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "J8vyKDpJ95kMhSo5ZRgTcmq9WVSBLuYUJMzeJd8jvPUM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AhZa2cqqUFQnCXNjDupX96qk4NkqTuUhuQKvavJ3buqk",
      "vault": "ZsD1GjBrFpaMxdaLDUncyiuxrgDuf4xNDvu1YG92WTP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624935d4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1a4cda34",
        "accruedReward": "1a4cda34",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624935d4",
          "beginScheduleTs": "624935d4",
          "lastUpdatedTs": "62664bd3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "258435",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624935d4",
          "beginScheduleTs": "62664bd3",
          "lastUpdatedTs": "62664bd3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CjZeegnvcsNrVrYS2iTXAP5qTZSMWWatFuzsXNHA2cEm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AfgweGZLAmBR3nVjh4ZJRXMqbRKUsmEvFmymZvFKAXdp",
      "vault": "Dq4xNwDMw2LrUFvqo6qYkX48mjvGbWGcqWZdcxLCtQCM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624f390a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "25e4d147",
        "accruedReward": "25e59834",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624f390a",
          "beginScheduleTs": "624f390a",
          "lastUpdatedTs": "62642da2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1f80ff",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624f390a",
          "beginScheduleTs": "62642da2",
          "lastUpdatedTs": "62642da2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "A2FzEXUuocEJa4up3mfz6aHw2vvjVnajsXYFY55fx92K",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "F636gJs2vsqiesumwHYF1CNHwH95tYDgFA2Qe83Qk8en",
      "vault": "84kDG8J9zzT75AuwWKWuyeMYBFr9iJQUKbx8UZNVXsGG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62593176",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0bc1",
        "accruedReward": "10091e8c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62593176",
          "beginScheduleTs": "62593176",
          "lastUpdatedTs": "626aed65",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "158893",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62593176",
          "beginScheduleTs": "626aed65",
          "lastUpdatedTs": "626aed65",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2geT6mrKMWxff7zLomQwMLVXfZABj4DJW1CTcqeKn5Sm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A6m2idwzz2ZRePkkkTkVr1r4MmD8nZ7QLgTpR6m649Jo",
      "vault": "8BTEuAKwBVh4MWoYfS7c4MYg7YpXtEVEefatzJ8zuSPh",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6251d578",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "322614bf",
        "accruedReward": "322614bf",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251d578",
          "beginScheduleTs": "6251d578",
          "lastUpdatedTs": "62649167",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1ce491",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6251d578",
          "beginScheduleTs": "62649167",
          "lastUpdatedTs": "62649167",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HQ9MpjFQrA2WVoz73VvBJY4A8uBAxYFAq9BjRQRvsaWv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EWweH6VE7ifGwb7ocuQCrNK3PbqhDwdZ4TJrbpfARvBL",
      "vault": "7naAwrDzqnhNBJ6Vw7j5opFm2YLN2BEA6xa6ePE44A8D",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625146d8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "244e05bb",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625146d8",
          "beginScheduleTs": "625146d8",
          "lastUpdatedTs": "62601f37",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1d7331",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625146d8",
          "beginScheduleTs": "62601f37",
          "lastUpdatedTs": "62601f37",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "sg8sPacdHPDxXybG6DXfPfGDUnGHuh8yAWxorWY16dM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6chyNoG6m8eTmGu3XrZ2986wL3DTAgEiMALp2UM2UcXP",
      "vault": "7PAWRCsGPkHk5t4UoKvqK67rjcL2zkLoxVwrzZc3aYat",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626481b6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626481b6",
          "beginScheduleTs": "626481b6",
          "lastUpdatedTs": "626481b6",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0a3853",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626481b6",
          "beginScheduleTs": "626481b6",
          "lastUpdatedTs": "626481b6",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2MGYwS5GEXY1CxWm13ErVZ9c2TSsCC3k944vbfx1qL43",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D6FSbxLvS7fXcgTj2zsnhKwe3PFxWHvKxAwjrKn8tycy",
      "vault": "dABmcmR6TFssyqXqs9K7RVXURdkJb36APdbrwUQDFLQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625a14f8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a14f8",
          "beginScheduleTs": "625a14f8",
          "lastUpdatedTs": "625a14f8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14a511",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a14f8",
          "beginScheduleTs": "625a14f8",
          "lastUpdatedTs": "625a14f8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "91LtAJHZfSvijs9tSTfVJLthSzMwCXzgt3vooDoXtbZQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4PvYpgZLarj2dbMEYPuCwaPd7aZ6fvmsZqvDswq4EjUn",
      "vault": "6LdPmLtpcJ2B7hU8j5FinCn3VGXS1FyJNvA6sijKJsbd",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EpHSZfknsy96aPk5mzDFy8L1XgguJUGkZSUGWAFTxaBq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8G7qPiq5nFZeJ5EDZESCYYrL8URmcRiKqMBSrtnBiicQ",
      "vault": "24Bj1h7GDe4p6qp6Gk42sHuCDZKCNuetpucQYvS3hwvU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626791b7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1a50530c",
        "accruedReward": "1a51b498",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626791b7",
          "beginScheduleTs": "626791b7",
          "lastUpdatedTs": "626791b7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "072852",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626791b7",
          "beginScheduleTs": "626791b7",
          "lastUpdatedTs": "626791b7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DfGsBv38aUyWCuX4C3ezXEdEKeh1SRZX5LHRw71RJpyi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AtprPADJcr9SQVMi1ospwzmyS7RFVs1or8e39E5DH4Q8",
      "vault": "7BegYHrHWP2fyWsiYzUNLWsGwy7Xs8WXGA4Epoo4Pp3h",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6268f14a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1487d558",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268f14a",
          "beginScheduleTs": "6268f14a",
          "lastUpdatedTs": "6268f14a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "05c8bf",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268f14a",
          "beginScheduleTs": "6268f14a",
          "lastUpdatedTs": "6268f14a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6CfQwy14bTNH37JAiUsCDv4RJ4grReMF7F75Kcs6Jeri",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GNnN2gqSn6VMaURJrHQH1pALVmYNGRqvfQTKvpk6ihDT",
      "vault": "Biz6T7qW6g8YFyg9Phwr5b3k81EYn8CTm9KAHKePES2d",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b18c2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "4033",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b18c2",
          "beginScheduleTs": "624b18c2",
          "lastUpdatedTs": "624b18c2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "23a147",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b18c2",
          "beginScheduleTs": "624b18c2",
          "lastUpdatedTs": "624b18c2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "G5H7eWqdaEUdKAfHTtH5EtMQrcruLXmQEzfxqSusGn6K",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FpaNw3PywoFvPtCmqCSERZdJjQHA615dRoQ14Fc2YzTb",
      "vault": "4zuZZa3AegH4sRQ4D98LSrkA3R4mgrU6fYLjmLBdaxQd",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HDyHwaNzhXUNitjmU1xeEbbEaoVmmYg4Aue6qBPgW5E",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5WEygnzkYBQKnwkQXts2bor12bkLJLCx4n4JScTMiMaU",
      "vault": "FL38oEHC5rZvG9QHybKNRHf6TJrMLD1WT8qVVQUYaG6N",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6260ff52",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "65708998",
        "accruedReward": "65708b66",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260ff52",
          "beginScheduleTs": "6260ff52",
          "lastUpdatedTs": "6267069f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0dbab7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6260ff52",
          "beginScheduleTs": "6267069f",
          "lastUpdatedTs": "6267069f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CEAMHxwADAv3Z4G4Rj3zQkN1EVVEyrhagu9XGEihJWQT",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7UpZ6yUhFrMXUdpckdrNuQZtt5D8knJTsG4ea63PhnPW",
      "vault": "D9ymC1fdKxvngCfDosHENbLVVd4dhCbHwXXwd85Nb5cJ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6269739f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "026bb462",
        "accruedReward": "02ecd5ed",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269739f",
          "beginScheduleTs": "6269739f",
          "lastUpdatedTs": "626a026e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "05466a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269739f",
          "beginScheduleTs": "626a026e",
          "lastUpdatedTs": "626a026e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CQ25ExvSd9KRsp9kDoC3yYLjXpf5GCJA8uD8B8yyczL2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6BnAzdBGmUdgcRaTaFGBvMAiAgC2cELiU5q12hBYb8YN",
      "vault": "8t9t3sDZe3w2k6PzFmFTP7roePdmrYb5e8aVozGRZkcV",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247e8b5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247e8b5",
          "beginScheduleTs": "6247e8b5",
          "lastUpdatedTs": "6247e8b5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "26d154",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247e8b5",
          "beginScheduleTs": "6247e8b5",
          "lastUpdatedTs": "6247e8b5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HXKMGFRDDnjXXqMPUG6dgxWrsbVn9tfB5GvKbcGuno1D",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6it3hqXShXJfKFJjoNBJGzvaeVu3bxgihnYvGXjs9bdX",
      "vault": "29SPfSaBMbxJAS46Vu54XdRaXf1RHh2BpAuBWdzRLEFi",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "07eb5878",
        "accruedReward": "07eb950c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HkKxASDpGRvB5n85GY3pXr7CcZ3FCDVVQbnQaxGhYFSs",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "C3UuZnCG7SrViycFXq3Rgg331mQsjhPSH31yHKntUt4t",
      "vault": "FdtEdsz5YEkKqNm8FtBR8Y8cTUscQ6ZnemvXZM6TjeUh",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "03c11a35",
        "accruedReward": "03c11a35",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "B4X7RbCk1p5rCdTmeLFnhyoCkV3fC1713B8TJB62CsVB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2CUy34SwGVDX6xWWmvj6yHfYiLCsyb1YjMhMWeiEhsS2",
      "vault": "69Qbh9RG5MYed7CH2Wa464y67svz9bxhRsf4hz9H7tFc",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624780a3",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2662f4f2",
        "accruedReward": "2cabc6f2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624780a3",
          "beginScheduleTs": "624780a3",
          "lastUpdatedTs": "6260342a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "273966",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624780a3",
          "beginScheduleTs": "6260342a",
          "lastUpdatedTs": "6260342a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "25GpnvFN6TJrprawVNtaqebndycMq2dcvZ9hHx5Z9aAh",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5dMtrPx42aPGhKbVN28C5uyhavxjBiS5LDcVwKFYWQVV",
      "vault": "CSfWZQgBgHwKg7mr5PZMYnqBnfx26B7r5wHEW4qL1CRS",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "625af53a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "469ba1ce",
        "accruedReward": "469c1cc7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625af53a",
          "beginScheduleTs": "625af53a",
          "lastUpdatedTs": "625af53a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13c4cf",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625af53a",
          "beginScheduleTs": "625af53a",
          "lastUpdatedTs": "625af53a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7gFAvg1DcQyLTq6uBQGLsQTUfBJQUxDUcBa5vLYNzt2i",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "E6dek82VzMwiJnyWtYdXaT2GLBc2e8f3kBEcFKKuUnHw",
      "vault": "3N4iKFw6bjCtXgo2JAe9YPuN4ETwhWaWQTxGFmgnGYdi",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62539ffc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "021f9d94",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62539ffc",
          "beginScheduleTs": "62539ffc",
          "lastUpdatedTs": "62539ffc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1b1a0d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62539ffc",
          "beginScheduleTs": "62539ffc",
          "lastUpdatedTs": "62539ffc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "71XDz3vjjfhdxQSFWpLrVu86ciMF3cTQbLJXk1TxmXYd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9FMCTUwpTzddJvdzFWoeY5gEuVpDqig5wjPUhd3bm8cC",
      "vault": "FuLm5VZ5Czfv6xGzfjVRsRex6EXT8WRrNkiir2rtGSwj",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "H5ezawNKm78U11hQHzu4y5wdHL32aJJAgHGManw1oZGD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BdE6EU1Lc51MHrwMUnMVqXQzfRXFVJVZieFptXk3U2Vg",
      "vault": "7Eqh4jn5z3c4vTduuyT9vBkN9XJsvyiaWVHvE2bx6Jb1",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625a8e36",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a8e36",
          "beginScheduleTs": "625a8e36",
          "lastUpdatedTs": "625a8e36",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "142bd3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a8e36",
          "beginScheduleTs": "625a8e36",
          "lastUpdatedTs": "625a8e36",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HrostDETgS3L7YSAMKzLbx3baAT2KXJkd5sHZHJM4wqt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BRt4EEYR1em2JA8eQF3HJqPi5BZd9ZUab2Xjodxzmu2s",
      "vault": "4UiubEZwT4S3NHHmecR6zHG6Ubvnnu49pGEppjVEriA9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62635c0f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "5c7736b8",
        "accruedReward": "5c7736b8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62635c0f",
          "beginScheduleTs": "62635c0f",
          "lastUpdatedTs": "62635c0f",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0b5dfa",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62635c0f",
          "beginScheduleTs": "62635c0f",
          "lastUpdatedTs": "62635c0f",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "72U5qkohYMPYrJ5PGPYmYHpyjgLUoaVqBE5s9YXS6RrV",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ANUkikZTPfEoBrqtveRAeNkvVqZv335H5Nvspm6u9Ghh",
      "vault": "887iekjZR1bdxDsX5LDagm93X2qePdvFRrP9Gbv2CXK1",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624bb627",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "11c8092e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624bb627",
          "beginScheduleTs": "624bb627",
          "lastUpdatedTs": "625f6057",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2303e2",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624bb627",
          "beginScheduleTs": "625f6057",
          "lastUpdatedTs": "625f6057",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9E5UrcwD2n6Z6mFYVr2cEY8MH3ZRUAmuVsJ7YRKoVAUn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7EYziwMevPCFRydkjHDT4s9FE6PuKoUZMiEXA9JtWr8v",
      "vault": "4QsxsyCz5GGZyf1dAT3H79Mk8SehqExCDMUKspbVq6sJ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "3a74b443",
        "accruedReward": "3a74b443",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6Dhjue17AEv8zxgJUxxc4iiuaNpmTg413LZPCzpMcFkQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5qbHUtcPgGbdmypMYR9LH9DXLAj6ttxniaMbq1JwFgcT",
      "vault": "BSp6ZZUqPhGezm3iUVb75TggWCqTcBUn5ScbPwoKUR9o",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62582e76",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1230ec1f",
        "accruedReward": "1230fd4d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62582e76",
          "beginScheduleTs": "62582e76",
          "lastUpdatedTs": "6268b770",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "168b93",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62582e76",
          "beginScheduleTs": "6268b770",
          "lastUpdatedTs": "6268b770",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "31VrbgBRMPJ4AFm3nmhjF5cZSLBcVnsQqVWa7ZAWtgcT",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EebuW2FDpBVo4hBhXCTW3Yd1oDDDdHRLJrhJtJmCSHuG",
      "vault": "7PYVpxPSZV9gKB5UVbDSgiidm86K84swbdJU2a71N9Fd",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "154bd4c3",
        "accruedReward": "154bd4c3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8YBvaC1cSuzU7rQqt5kT9ZtsGA6NWSBF1bYpkFeccY9N",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4yJkCqfeUfHUwVwAg9oiuPr23oF9pfWMnMikUAJaeTG4",
      "vault": "3DY95bu3nCWQhHgUUDaF1LAERgaYVmfMdN1zae2bEPoG",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "07c346ec",
        "accruedReward": "07c346ec",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HD45GoW1Eq4YEqA5AJohfzLhNhdyicXM231DZWeqDzee",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2naSovAToKaELrqvoeZigQGULzYYSCmjcH8DrGmaGQo7",
      "vault": "E1JAKbgn2PX3m1HEyG4SSdmvjiT8LZBq1Dfs6xe6z4Su",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EEfqMKhB4q2AXDhJgvA88KKGFqs6igLitkc3UE5E6PAH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BjVrLpDyVpwNfnVDYVCzjX8urKUTLPitkDgG2oHkSA5y",
      "vault": "Byyp6HsdZtYR7JgGcRCxxfkBLH1W5q2w7bRUgXT46Brx",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6VdWtqYWFhYHdYM8Ri7Moy3STRzw5uutDZBDYBCBYu1o",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CYrfzfGHhda38vfiufdQHFuFCuuXKmCT8KBzH13DdSbv",
      "vault": "Dxc8a4YLz72yhWF7j1YLeyiqHdmRcL9UshkTD5UmcFHB",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2305b443",
        "accruedReward": "2305dc0c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9WKd8TS33gszSTYeiTdyJh1hFy38RNqarRBD6GYtffdH",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "28g4VtfSYA7RCqbzfXwFQyLPK8bjJjS63FKvt3ETq93B",
      "vault": "3khyTUWF8DMp3mt2eRXeZd6zrk4h217NcLnx6LZMq391",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6252b759",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252b759",
          "beginScheduleTs": "6252b759",
          "lastUpdatedTs": "6252b759",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c02b0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6252b759",
          "beginScheduleTs": "6252b759",
          "lastUpdatedTs": "6252b759",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7EgRsPd5jG4P2q4EYH8ooKbrS9A5HTVPNbupqGDZs5YW",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4TyBQ3MjHMrzhk5nQc9cyjYTpXpkZ2VAeQhQ9vFqWAyL",
      "vault": "8rtTUWDVSwwj44sgGUHxitqbS7BpsRgDnGwnLiTkwUX1",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6268ff98",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268ff98",
          "beginScheduleTs": "6268ff98",
          "lastUpdatedTs": "6268ff98",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "05ba71",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6268ff98",
          "beginScheduleTs": "6268ff98",
          "lastUpdatedTs": "6268ff98",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Hf8tHmm2vVykdZhz3Rsm69QD1WzNT81fsKMDa7EKDc5k",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6HNTCS1e14covZqUNUpjrK5rJuC9SVbNZ9fPv78YZqbL",
      "vault": "44Qg3C3oFyj1g3gZXxtThgDZJzSicwEPUmUoAhn2cj1H",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4q7ydCRXK9mitJrnAjjFqvDDydr7ukxvrF2VKmex4uik",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2bdH2BQc7Ta92h2Xe3sSE9DWzynmTjGGAreq2SrWYEHs",
      "vault": "G7rGJhC2D7orPaJddgwjN5W2WewtAnLRM2SKruDrLoi3",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6267b523",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "318799fe",
        "accruedReward": "3187d5ab",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267b523",
          "beginScheduleTs": "6267b523",
          "lastUpdatedTs": "6267b523",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0704e6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6267b523",
          "beginScheduleTs": "6267b523",
          "lastUpdatedTs": "6267b523",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Dr4Wiq9qT4AvKdJv3CowFEUDYnkpK41DAq9Mb4ogSsHq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Hx8iVndzk1GdwkLbdqKPTYd2cfmGEJDDvjUYr3ZtFkMG",
      "vault": "EuNHdMWhf83NCiYuFG9FodBajjfBukko3h1Ebxr4fLHs",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6256c480",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1e043859",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256c480",
          "beginScheduleTs": "6256c480",
          "lastUpdatedTs": "62692eba",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17f589",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6256c480",
          "beginScheduleTs": "62692eba",
          "lastUpdatedTs": "62692eba",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7Dua7ZSynGwCSEUjGGQNt95yBSXYZsSkgMJV4W7ff5J8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Hr8aYMVxYApxgMix3HkakGL5NBwu8FPeeDBaBrJRXZLG",
      "vault": "3kh4XbE8RLaZRbbeqvG4qv4WXqK9CD5gP7Gt71WsdYNP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625a25db",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "21c953f3",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a25db",
          "beginScheduleTs": "625a25db",
          "lastUpdatedTs": "625ce2bd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14942e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625a25db",
          "beginScheduleTs": "625ce2bd",
          "lastUpdatedTs": "625ce2bd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "23qPedDKVQMC758nRx8FbQMidHrt4eW1KCtGQCYawxEU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9tGMe5KpWrVP9owHYsdXTXGHnMBkjzzqF8SqaD67ZbFy",
      "vault": "2LxUnD4r8fhAjDsv9npgE7EZq8MsGLAtiAxmTrTcg5nm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62661c51",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "18978942",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62661c51",
          "beginScheduleTs": "62661c51",
          "lastUpdatedTs": "62661c51",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "089db8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62661c51",
          "beginScheduleTs": "62661c51",
          "lastUpdatedTs": "62661c51",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "tn16KPasDxRLW7VsAjcVacHWuGPcgafPatwURhtHJCA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "91dRx9xGtaoToGBGdh5fn7mywsmFiGAvSooxdEXHYSTK",
      "vault": "7HoST2T6awQZTzhrcTNsrDm3owsbuxtnSp51QDyCSu6R",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625f9e55",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0687ca84",
        "accruedReward": "0687f165",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f9e55",
          "beginScheduleTs": "625f9e55",
          "lastUpdatedTs": "6266d75e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f1bb4",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f9e55",
          "beginScheduleTs": "6266d75e",
          "lastUpdatedTs": "6266d75e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "F1WE5vALot1atZUrRwxqFnhLWKawREA9yiKGBxiAFPPs",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BtoJ6W75EqsZ5qmCjgBRc31gXcworSbhdybWzLGscthj",
      "vault": "B28tVBbv9VKpKDgb2AvHLunTQCyxyCxmHDaLMBuRGdj",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4ZmybeLj9JxXypBxjBJTTHvXrGhxDn9KB5wKj8jWa2ND",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ESwMnajkhYzQf67UgatFs2AYaLfgVas8uYT7o4w7WXs8",
      "vault": "AZGFZHitjc8ShkSjqwsLAL5kh7mHwc2ZYKMXPuds4zYi",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4ATAuydszJMFVGRfEYRMdukJQguCBHwrpGR4yUnFGUET",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5iuDD7rZgZNefFquKYpUv4ksgJZjuSCMyyoPdE9uH8xV",
      "vault": "Dam7oqen2jxu1oAc2JrSLSS3SKK4wGMRVVgnJLpNVqs3",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0926db38",
        "accruedReward": "0926db38",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "JCNiNcHp2UnjG2PSZxGnmb9NnLmnLDGTM5o7HA9diPkd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8C1iDS8cN2eihPTVTfPtudEsa7gkFUFFXjoL3csuYMA",
      "vault": "Euc5GmjyybpWHTPRsPS67Zpv9pzBKBgaHcKvJH5rAsV5",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "625abb7c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "825b8061",
        "accruedReward": "825b8061",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625abb7c",
          "beginScheduleTs": "625abb7c",
          "lastUpdatedTs": "625fdb83",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13fe8d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625abb7c",
          "beginScheduleTs": "625fdb83",
          "lastUpdatedTs": "625fdb83",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "83Mh5Fzg6Eera6LeNuD44mCmgw3M2ZSnHfnAKqQDY7h2",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3dAVTwpEBYKuUKH8KhCiLxHqEQXBay9gHQFxJ3Z9Cdjd",
      "vault": "DoJJoGrJo2CfTBzdz7gc6jUtgoibektSpNgL9dRXcJte",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "12c57e83",
        "accruedReward": "12c57e83",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8HmCKC2kHivzaiWZww866xmeunHCDDvPinc2uLNt58w",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "w2WgXAWEXDUme1vUyVWx31cfTSH3omwy3XhJoKbZpC1",
      "vault": "4kU6KkxKqCUn7PdYg94iuCebxB8Ze4sAt9HHz6mGXDha",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "624892a7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "13e78635",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624892a7",
          "beginScheduleTs": "624892a7",
          "lastUpdatedTs": "62539446",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "262762",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624892a7",
          "beginScheduleTs": "62539446",
          "lastUpdatedTs": "62539446",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "V8JSQyHJjA6rb8wEhorkL1DAcMxa4YCVGZXdtfNySBa",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "B1nU8ScwRkeYzE259QjnP4ELiF9bBTZhZqu6GDTrqcGZ",
      "vault": "25GeZ7TGJhZjFRXkc9jLoZQM5BPKMMA1TxGtj4BEgvjc",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "07477d7e",
        "accruedReward": "07477d7e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3cwPCuunWEUvPxr77iAddxzfRbnnFbMd9a8w9ahg2ik6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4LgrjwszAGWzihcrzfzLUyP4hseGVwfjEsxefSvstKKf",
      "vault": "DR5QQTvjMYzg6o9DM1NdrATiRejJFdgYafeYAt5mpZaG",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62476c1d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0d4f5bb3",
        "accruedReward": "13613540",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476c1d",
          "beginScheduleTs": "62476c1d",
          "lastUpdatedTs": "625cdad0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274dec",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62476c1d",
          "beginScheduleTs": "625cdad0",
          "lastUpdatedTs": "625cdad0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E1uTEt9wwpG5TswQQxWo24EuwsN3iij1Twwi9CRNgqsg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GXZUhrjh3V86ChwoQsZ9D4u7EyBGmoEpqfgTuYvnu8dE",
      "vault": "3LnXakfn8ZcDbfkFfHG7fLYhHUcCTAWCtQg5ASb4ntZQ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6257e843",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1dd61d85",
        "accruedReward": "1de48fcc",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257e843",
          "beginScheduleTs": "6257e843",
          "lastUpdatedTs": "6257f982",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "16d1c6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6257e843",
          "beginScheduleTs": "6257f982",
          "lastUpdatedTs": "6257f982",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3xTZjsu2vm9rEeSu33j7t1mBGG4VpWASHB4h3D7LMXGD",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "33Dd9ei75G4ULiXj5TNroL5e52JeA8VmqkFzdu4SSwv6",
      "vault": "E5ahbkpg5fd1kzekwVgbFz1Hqd7AokC2nSibbyHKdzme",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62558a19",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "16c3ce0a",
        "accruedReward": "16c407e8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62558a19",
          "beginScheduleTs": "62558a19",
          "lastUpdatedTs": "62558a19",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "192ff0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62558a19",
          "beginScheduleTs": "62558a19",
          "lastUpdatedTs": "62558a19",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "D4o12ZyoYVBWtX8rQSK4YFVzYzBVnyDX8Jp25vUtap4M",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "fxPCkRdQKDa5WV6VCvikN7JX1EK1wTBe7D5FUGFGw2Z",
      "vault": "J5wMVLWjhtvcAA3AYpuceSwP6tF3C5nDSwMoYy3YD8mt",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625f4b39",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "05c2fc04",
        "accruedReward": "05c2fc04",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f4b39",
          "beginScheduleTs": "625f4b39",
          "lastUpdatedTs": "6265aa52",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0f6ed0",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625f4b39",
          "beginScheduleTs": "6265aa52",
          "lastUpdatedTs": "6265aa52",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4Pi5Ex8TGTrjhwchisXh7HCGCcSmvEJaa9yiu1BaLLNn",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CAuGYZTWXyXRTKtMNiT1Z8JLuSbNo3qz2thtmYbtWg4n",
      "vault": "AVy88BUG3fRz57X4pB14tTmJehZQJN5cbQ1axxq4u2rt",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "07089457",
        "accruedReward": "07089aab",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7ZfNvZNttUuFFdARnPXwxHnVFuCC5SeKJc4h67CFEjZm",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DUGV46hVtqX7vhtMBCAf2a92Hom4mMiKBJJD2C6YSBys",
      "vault": "1x6qM2fQikNbZHx7sFmE82Gix4G5NVFwaGxznjnyjYP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62478850",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "25fa",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478850",
          "beginScheduleTs": "62478850",
          "lastUpdatedTs": "62478850",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2731b9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62478850",
          "beginScheduleTs": "62478850",
          "lastUpdatedTs": "62478850",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9jP5rohWEzpFvnUoya3uXyb8P9nvt4nTjjFgw8D6Ao3B",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "VdFnQWx5eka6Vz1xa6WBfHoR9hcyBenBPqSTdYorGDR",
      "vault": "EzP6C4njV29BUZ1h8E7LkckGuR5udZjQxBJ5HXBmZw8r",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6255eb23",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0d8f5506",
        "accruedReward": "0d8f5506",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255eb23",
          "beginScheduleTs": "6255eb23",
          "lastUpdatedTs": "625d6aa5",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "18cee6",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255eb23",
          "beginScheduleTs": "625d6aa5",
          "lastUpdatedTs": "625d6aa5",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8bKzMR2DqThQQoRmWdxkNMgx9fZRyTv4CwLgHMxR7ZQR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6ysPKEZUDtMN9TC1SpCDtD3DnoNKBTEJqtdXYjTBAZCV",
      "vault": "3hASTDc9DCD6rG4meaYe85m3CvZp1T1nzrVwz815KoSB",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624b4ca0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b4ca0",
          "beginScheduleTs": "624b4ca0",
          "lastUpdatedTs": "624b4ca0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "236d69",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624b4ca0",
          "beginScheduleTs": "624b4ca0",
          "lastUpdatedTs": "624b4ca0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BXy17diY7aimHwrN4u9zUHGsg4X3SBmnGWCWkfoLs6w5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2md6UuEYDtpDjDsHkPwN37wTNzRCabsWm2cK6UaQrfxR",
      "vault": "7sLB2uodMU9ZEBW3cU5tZJedB2bKbt4D4B8vCCS8UgC2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "08",
      "rarityPointsStaked": "08",
      "minStakingEndsTs": "6259d1fb",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "d204aea8",
        "accruedReward": "d204aea8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259d1fb",
          "beginScheduleTs": "6259d1fb",
          "lastUpdatedTs": "6266474a",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "14e80e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6259d1fb",
          "beginScheduleTs": "6266474a",
          "lastUpdatedTs": "6266474a",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AqTjAXoddXHtPa5mikjcdw51f6kvfLg3WSF5TXiqVvmj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3cLsL8myA7BjmjdTUfyUMXKVemQBNoGAEsMygtsRGanT",
      "vault": "AHbhpdScqShNXF6k7tNSw7iVEiho2QLSQed9GzDkUtEe",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625b2773",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2637d456",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b2773",
          "beginScheduleTs": "625b2773",
          "lastUpdatedTs": "6266ffff",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "139296",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b2773",
          "beginScheduleTs": "6266ffff",
          "lastUpdatedTs": "6266ffff",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FX7jyp4RFdWLqW4AfA27PGT4dEgHbrSrkiqf5qoGestN",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "33CgcAjmHidf6HwCLEN7bLmVScQcFjvjoy5VHfCq3a33",
      "vault": "qFj32a5tAyDiJrzCnaWvF4HcLFt54miLukBuPeYgxVm",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6255b595",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "10bd97e7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255b595",
          "beginScheduleTs": "6255b595",
          "lastUpdatedTs": "6268391b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "190474",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6255b595",
          "beginScheduleTs": "6268391b",
          "lastUpdatedTs": "6268391b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E6dHunzR56x9HzR41PpGYxyvrGFsuoBdq6GJoEaBpNDj",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5Z6DGADYkgpNeL81mi63a8xJ6sJXx8YPiXSHVvjVad1p",
      "vault": "5dRgZviqAdj2D1wjEchyniJ3HPrPUGa8LoFKYVVhN5Dj",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1552b0c4",
        "accruedReward": "1552b0c4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9j2VW7wwmc2iNMc1Zt6iJNtyXVAh8i1gk1B5LULXTDEQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9UqnfdBEnAPyFtc3Dy37brgtaNdMQREP22nNsty7PZ1q",
      "vault": "EwCx7jGMnKwKY1kzpHPMeaD9gx6wF4be1mr7qCgnaSoL",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "07057a70",
        "accruedReward": "07057a70",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "14sKqnmzgaswbM69VL3iYKyuwx5DAXLterPLUNrsyxZ4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AkQrwRvshxXBW8HQMwCE9GFF5YAiqAYhHW2rT3G4uf3z",
      "vault": "CAZrvG6JtcSwBy6RXgEYgmYi6aTpVmoNCeZyjCqBwVxY",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6261045c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "012895",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261045c",
          "beginScheduleTs": "6261045c",
          "lastUpdatedTs": "6261045c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0db5ad",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6261045c",
          "beginScheduleTs": "6261045c",
          "lastUpdatedTs": "6261045c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ED4Ryba6qb4yxQR2VLj3KzW9MvzRGkXXYYUrNHqCoRR1",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BNZvKkxQHHY5utBjXAJ4m32C8on6ZD6Uny3xFusaWBzf",
      "vault": "5YRnyZ7S4g9cDD3euXcVuh1ME6szsAf7uChtMsnJe4H5",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "01265420",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GuAHKwPMNYfPbdC1ab3CaR1zhBg42NgKXVZBpEc1THvK",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Bjg2jp8DJ4v4PeicMvD2FSgeqAudMKp7Brt3hmR615DQ",
      "vault": "B9hQ2AF1yK9QMq6bv6C1DEjn6Tes2dFCCB5osVDvG9E6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "62489c93",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489c93",
          "beginScheduleTs": "62489c93",
          "lastUpdatedTs": "62489c93",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "261d76",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62489c93",
          "beginScheduleTs": "62489c93",
          "lastUpdatedTs": "62489c93",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "2dWdt5to9HCMxUDwNjKW9b2oVuDEJvxVSZTt95E6yc7y",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DkFuxqQtkwMc5q5sB6ogSA23euWoEPEkc6Dh8TtfJs6z",
      "vault": "BMWnzanTL2ULby2DwETMW9hMEDQif2MojBjYiP7CoCXD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62477562",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "1215",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477562",
          "beginScheduleTs": "62477562",
          "lastUpdatedTs": "62477562",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "2744a7",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62477562",
          "beginScheduleTs": "62477562",
          "lastUpdatedTs": "62477562",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "E6z673ow6DWgf4AsbuxQi4TPCNq9CEFXySLiE7QWpMSC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D4GeNqyeqX81AzCA6rPtFf7nJVi1RCPVBk3qkzstJVnd",
      "vault": "9UhewzcJtWwaHyeCNHCUkryxHadn127h7ATdcGDpRUBp",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "04ac69a2",
        "accruedReward": "04ac69a2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9SJ6BD4Fhw55YQkcYW7AGLa6i9iRRCPiJhMTb54y42a5",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "B7iuM7Qacxg8jcXtTaiWSt2YPof1yXhpj5XSYsP2CXGe",
      "vault": "C27JUswSTnxiFfaUYrtBQnkTadAXB7UTM6JptyiYF84N",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625acfca",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0334a21a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625acfca",
          "beginScheduleTs": "625acfca",
          "lastUpdatedTs": "625c9591",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13ea3f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625acfca",
          "beginScheduleTs": "625c9591",
          "lastUpdatedTs": "625c9591",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4RHPSPQNTWHAYoGTrDV1qq69c8YhnkpYkbFdJMSeYQqd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HCGJPzAVXVYyHfyGygG6yi9W7Hr83GzBVeUNMvRaw135",
      "vault": "2T4C2hme4HLw7DU881RYxpqu93vGqktmB9pKkMk1UVsZ",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6247b9a7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1063e7d5",
        "accruedReward": "1063e7d5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247b9a7",
          "beginScheduleTs": "6247b9a7",
          "lastUpdatedTs": "6259d9fd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "270062",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247b9a7",
          "beginScheduleTs": "6259d9fd",
          "lastUpdatedTs": "6259d9fd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6CNbEw7a1tZfybt4ECtj7R9H6sMHYei7hdtaRXJ9hGo9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EXWkj6eXbtuWnvZhKtTfKzH7c15jLw1wpTcZRcZQgvax",
      "vault": "2THMCNmbk34ogk7TibCFKmkW8jrvMcKtbxHz46nShcse",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FF6HGJ1AptPuNGBmnbvmkXcZ6f5jfYFd92iRGXwtvCHE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "2jj3YWC2UJhz5U8kuqT1arFcGnhmjX7q9oARiFTrCa8M",
      "vault": "5stsCubGAVUjPrDRwAddwrEPTxkB5bQGnw8582eEDAKf",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "154a3134",
        "accruedReward": "154a3134",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ALNYsE88hANjFVGzHtsATsT9PGFBAeXWsXHJsJWxWpA9",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6VCwQ16iZTpUHj2CqZqTmsaMaTdku3EFLKfBouowQjLV",
      "vault": "ErJtdg7C97z9JcADR8acFs5CqjtZi31Ksm7YpLRPkSdB",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5xSKqZkkx17Xb861cVDLdQx6teUsaArHqxh8Y5jC7bzg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9szcpBV3QJbAtQbXKhDntrwkfz8ZaMm1vrSpazHkh4zJ",
      "vault": "HjqF2GAKnsg4DUHhNJM6pf1Rr6GdYej74V4EMKUMqKQf",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62533218",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "236f2c66",
        "accruedReward": "236f2c66",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62533218",
          "beginScheduleTs": "62533218",
          "lastUpdatedTs": "625d5420",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1b87f1",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62533218",
          "beginScheduleTs": "625d5420",
          "lastUpdatedTs": "625d5420",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9TprWgLRQ988jTTvhAqCxbL1GFEr2NzCN6jFo9u3tKR1",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6UzvnW6A9ARb1ZiAQpceot3EhqTdVv2dBji7kVdRZJQT",
      "vault": "FnX8kjJtHCQQDgtT6t1n5nPXVPh71bfmKLbVu4nAxVBD",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "709d3c41",
        "accruedReward": "709d3c41",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3SyCNsbYPUHif3LdRJSm41azYGJK112Bj4EBbP9yRFd4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HCZc7Hm1xiDstdk1SEMaCFAPaB8tAe1GbzxTmF67PbPZ",
      "vault": "FhJvYaEUgexDhbfUjSmY5NFoDEEZnbYiiTzHPm1CVCXP",
      "state": {
        "staked": {}
      },
      "gemsStaked": "06",
      "rarityPointsStaked": "06",
      "minStakingEndsTs": "625bfe2d",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "4b13daa8",
        "accruedReward": "693a91ae",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625bfe2d",
          "beginScheduleTs": "625bfe2d",
          "lastUpdatedTs": "625bfe2d",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "12bbdc",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625bfe2d",
          "beginScheduleTs": "625bfe2d",
          "lastUpdatedTs": "625bfe2d",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Gcn1ntWipsZRLW4uVkpeHypd4HGb2csRRdf6thLL3map",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GaP6QZ3Q4PNStyFbrAyNNibgiTgd3e4GU5TTHbMokH9n",
      "vault": "5JhfocuHto3yb3CcH38d3Rn3zvDmGxTzDQbF6ibq1YFS",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "d6722e",
        "accruedReward": "d67def",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4bVvnxzb1xgKXjpk9Xkx3nWBmTc3yMx3RWknMc3NK8Bt",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BpzcpnyiTrehgXhn95oQjyZ3Cj258gau1ZCXVWFsCCbX",
      "vault": "691UgZecuVpQTmu2Wf3oUvP8rkU5WdyMVtXhfS127n8h",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "011f4494",
        "accruedReward": "011f4494",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HXZfXeJq7qJyLpS4SjBfwqb4GfNuZRFf87Yn8sRp9K7X",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "GD8EtLye6AbEmgowBLdZrujLqdYcf4ianpCa4viQn5m8",
      "vault": "CNJcCD1nbukNSru8rnjiLMsvLqDc19pCpf21hBkWbWoX",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5egvtpNqw2x1AkTbxPrUxvgCct5Pu6JT8WjDqoRcnYTB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ELEpAsZohbZnuMTLaeL7CByVDmwfLmLMUPam2R4BCAyp",
      "vault": "FjKJvJqkpVU6Q31NHCiGPNmFwzfx3NKrb9VjJUp6gZcw",
      "state": {
        "staked": {}
      },
      "gemsStaked": "03",
      "rarityPointsStaked": "03",
      "minStakingEndsTs": "624de20a",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0761a45e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624de20a",
          "beginScheduleTs": "624de20a",
          "lastUpdatedTs": "62509aa3",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "20d7ff",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624de20a",
          "beginScheduleTs": "62509aa3",
          "lastUpdatedTs": "62509aa3",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "B5JhHEMjj2ny2BwN3Ah9LBDa8wPKjmj9ovsemgfHDq4q",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5788CagsPEsUgorRL1izfESS9qiW21VNYKsaGdcm8ERz",
      "vault": "GbgKkENpAf2UQTG7tzFG7ZuU8ASvqnutAFktueh8BbJP",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GDPPwmfRDvAq1NfaZSGNkt9QjP7x4npCSdYYEKTezJ1c",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "72mdRf3CtwKiLmJ5n4jACP6Rrb2ALurvyqnGQiH7UHWG",
      "vault": "BjWpSGMUE9q1498VEPJyUiQ6jupyHuuU3QqBG9AiwJxx",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624c897f",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "0f9741f7",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c897f",
          "beginScheduleTs": "624c897f",
          "lastUpdatedTs": "625dc782",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "22308a",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624c897f",
          "beginScheduleTs": "625dc782",
          "lastUpdatedTs": "625dc782",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EySHjapRh9gp684HqNrn5KJ9zyb6DKr5cotZyWiHz8PU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9TXrPg5ymCoDm4msdubEkBvcNqFyo4eyytig39fHTrFy",
      "vault": "D1Fdx1f4nEEhbgJ2DHHtmy8BKcoPpGMVwFRMX94EgCRM",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624772d0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1c8356a4",
        "accruedReward": "1c8356a4",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624772d0",
          "beginScheduleTs": "624772d0",
          "lastUpdatedTs": "6266fb4c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "274739",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624772d0",
          "beginScheduleTs": "6266fb4c",
          "lastUpdatedTs": "6266fb4c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "31iWMztKi7DgMtgUzbDJCZopbBGJZRT4XLQALqUMDbKq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "CctAWY5zrHPycbJuQvy4QpUygwpFfhmeRMxG1aetyaKc",
      "vault": "C2PWXd6hteQgbPN1Jr6mbfbBKZmtW63Ky4bw1HfZhc6B",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "171c050a",
        "accruedReward": "171c2597",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "FmejNAPeye2kqSQhBRi23fSnwKAQ4udgioLLFnnGkiYp",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HNhM7pCK65cHgfRmrqAuyoanSiodrGysXiuNunpdS45",
      "vault": "9RAesMRWxTfUga7rcopmAyuJW9BcyGDUQ9mGbdp8EHWT",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625cc7ac",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cc7ac",
          "beginScheduleTs": "625cc7ac",
          "lastUpdatedTs": "625cc7ac",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11f25d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625cc7ac",
          "beginScheduleTs": "625cc7ac",
          "lastUpdatedTs": "625cc7ac",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Ht8ehf7gfb2VRjty6zHqtsycYC4tthkDxQBvfbXRWy58",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AVzv5HbdTimn7Vyq6J1zKJnBGVT5bGY6h8i3sZAzxUUE",
      "vault": "AmNJwdXpzXU7qMmwJS3rvuYqQ488q2bPzYPhLrsZT1W4",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CcSBsE7xzD2mwWBPVWHefbsT46xB4vxrPMx1GqnvPvdX",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BfPBpbJy1Mi9e5msPnWiu1yU6U4a4Gzyq6aDq5Wt3uVu",
      "vault": "2CnvgQLqer2inGYuoqpA9Wfq4yYyYfGGmd26mMJ4tYno",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625acacd",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625acacd",
          "beginScheduleTs": "625acacd",
          "lastUpdatedTs": "625acacd",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "13ef3c",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625acacd",
          "beginScheduleTs": "625acacd",
          "lastUpdatedTs": "625acacd",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "42heVDyG7HzvoSpHjvfM4w5Ag4Tf8WvHyzLk4zeE6cMb",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3Lfy5sNQaBjXU8V1QBku74Bpbn7byXqavmhGN9MuYPYc",
      "vault": "4jmykUtiYERsZ3d6kGDdhAmSZyqysjY9xHDsq2X9Hzsy",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62576054",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "36a7577b",
        "accruedReward": "36a7577b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62576054",
          "beginScheduleTs": "62576054",
          "lastUpdatedTs": "625764a9",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1759b5",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62576054",
          "beginScheduleTs": "625764a9",
          "lastUpdatedTs": "625764a9",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3JACfBCh1mW8dL5kYkmzEftmZqi9dTUdKYdVFgM1Q2HE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Bwgc4wyMYbLatXMGyFJUS6paqukWpGtv24XNKZ2WoDLE",
      "vault": "78sbsjfrvH44F2uFpgV6uFWWJxjVjbCZKsULrTNU1WTC",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "626294a8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "03acf1cb",
        "accruedReward": "14625d53",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626294a8",
          "beginScheduleTs": "626294a8",
          "lastUpdatedTs": "626294a8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0c2561",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626294a8",
          "beginScheduleTs": "626294a8",
          "lastUpdatedTs": "626294a8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "CyxWwHrwVq8uvETywnknN9c22UrYkRyPxJtMqjkUkKp6",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "HfiQRYRhAi2XfpYZP1QinVfxWQAaK9UHzw6Z2RxJrbr9",
      "vault": "6jEqVoVpE7eBj8AkWonqd8nMuBG64Y1cNPFRj9jcC8hY",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "2c187cca",
        "accruedReward": "2c18c520",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5S1vcHN76f53sPtjeG56qz3gkN3eFujBkUtDGa7f5dhd",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3AHSB5SP5rw5fjQvvHeucQHtadDESH6bu4pbnGTVriUB",
      "vault": "AEEFeih3oiEhS3mYWh43N2QP2aS7gkDJgRK74tM2PRJ2",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624852e4",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624852e4",
          "beginScheduleTs": "624852e4",
          "lastUpdatedTs": "624852e4",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "266725",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624852e4",
          "beginScheduleTs": "624852e4",
          "lastUpdatedTs": "624852e4",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EndULRJcGvFnTUhrc45NEC6cDj9d2ktDtuu39MJL4AA4",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "1skkd9b22UDXT4bbu8K787GEqb1KYrQamq5MhEEcoSF",
      "vault": "4u46YUoYPiBkEfS23pN7m6HJfyJU88DoYBFXLFyxfGGB",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62557cbb",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0819968d",
        "accruedReward": "0819968d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62557cbb",
          "beginScheduleTs": "62557cbb",
          "lastUpdatedTs": "62557cbb",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "193d4e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62557cbb",
          "beginScheduleTs": "62557cbb",
          "lastUpdatedTs": "62557cbb",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3i3QBoK2fdfWT3Ym5WPFqwcT2z7Q5qgujn6JsF8gMHLc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FUXy8T8ovbYVFWz9agzcymf1gp3dwjbxq5xxmj3NhWax",
      "vault": "DdGxwKhBqnXV3NqAbERDNf1ZAdM96JRijn2LSxi7b1gi",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625b4d6c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b4d6c",
          "beginScheduleTs": "625b4d6c",
          "lastUpdatedTs": "625b4d6c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "136c9d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625b4d6c",
          "beginScheduleTs": "625b4d6c",
          "lastUpdatedTs": "625b4d6c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "447i7xT8iTVh2ZPDrgVbQLUmQFHpKs3Mry7cfG5vkRqL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AvB6YMUvo8BiyMqemTEqh29CZnaqfB4zsQ6xC3D8KRrA",
      "vault": "6y5fTA6RfBKrJzLem5pPbhmMYwJZ4gRvGABrv9b56oEX",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6262d4b7",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262d4b7",
          "beginScheduleTs": "6262d4b7",
          "lastUpdatedTs": "6262d4b7",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0be552",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6262d4b7",
          "beginScheduleTs": "6262d4b7",
          "lastUpdatedTs": "6262d4b7",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "8Xj6P1rDJvvmt4zsByG3D1JuPPp1KuWhSfbsQSGieuni",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5AzRCCAf4HKWHb1MwkHeZnTjBMaZkktu9UnHb4sJab9o",
      "vault": "6Wja2EgDTdN5tihFMmQPmWMLj9LWcuuTmJd2mXoprRPg",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0e41f0ee",
        "accruedReward": "0e423039",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "X96z1GH6AffT5H6KByoKR2VBmYokChPbcorWH9UAp31",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7xTiib4cXdKqiXn5Zb4zFAbYEwPqXDdiH18TTaea4ySj",
      "vault": "HPM82q2dJQMUDzgzoXRb9dhkXgGAYbtFK7ncZSoLpp3j",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6247b98c",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247b98c",
          "beginScheduleTs": "6247b98c",
          "lastUpdatedTs": "6247b98c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "27007d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6247b98c",
          "beginScheduleTs": "6247b98c",
          "lastUpdatedTs": "6247b98c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "C93qSxake1E16pa3zLRiJssNwRqskqHKYG1xuADL1t5H",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7YnrHnd6y62WtcZje3SwDjte5pHAKv1ZxNjQhfgZGKvE",
      "vault": "BrQZJLvG8br4y4GDZf9KizbKJ7VTzVsBm1a5jtiHcRHE",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "Auc9rgCjH8N4VVuCbD7fYQ9mRP5raZNrfb3KUKi81aAF",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5KaBVEtLbS88XXjUpffimXtd29S1VLwu4dDXBCjuQ1Ax",
      "vault": "E3zaqEc8Qw5ku8V2X5XDubpQhfeHZBYP2iXFGe2VyPY1",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "057f64c9",
        "accruedReward": "057f64c9",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7sEGYEZdU2P3BHuN2k2VLzDkEAYwrkKaP1kTbHFo8mTU",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "32n3fn2iwA8q5RemUE42YMmkG5xtzbFipdiaLqejUys4",
      "vault": "D7mU2WSPZZutuqFR2Z4xvY63MA8Xt3xbTCH7LtnkT62P",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "624973f6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "158ac26b",
        "accruedReward": "158ac26b",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624973f6",
          "beginScheduleTs": "624973f6",
          "lastUpdatedTs": "626146d8",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "254613",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "624973f6",
          "beginScheduleTs": "626146d8",
          "lastUpdatedTs": "626146d8",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DpVHBt5JcmD12J79HFyVmcBUGVRUc9CTBZAxRYvHnnAQ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Gabz1GYFFJ1Yt3VeRx9SpjYJpjnaCuh3FgCp6r52hStv",
      "vault": "2mhtfGsHrgs7tXDKu6GLihsZkggKizDv9hf1UoWxDnS7",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6269a0b0",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "2924ea7d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269a0b0",
          "beginScheduleTs": "6269a0b0",
          "lastUpdatedTs": "6269a0b0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "051959",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269a0b0",
          "beginScheduleTs": "6269a0b0",
          "lastUpdatedTs": "6269a0b0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "A3eQWkVqExXhsVEyhn5Nz61A3P4Vqfw4qGb7LzBSBxmL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5rWU9QDanRHJYD8FZ5Teu3rPCRom4m5MhWrtMjGSHS3L",
      "vault": "9HZnZLfoKQ76DNyk1uGhappYXLoDM3hARbaDgAyS4WXN",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248a9f8",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "126b46d1",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248a9f8",
          "beginScheduleTs": "6248a9f8",
          "lastUpdatedTs": "625d08b0",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "261011",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248a9f8",
          "beginScheduleTs": "625d08b0",
          "lastUpdatedTs": "625d08b0",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BTFKsSgW54h5Uv93TzjdkwiNmRxAe28ta7UxeFgBJsmz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5JgZNxkmrv6kVmjrDF1sDjGqSLvLX3Q6zF6X9X9WezPo",
      "vault": "3fksYRjbo2dffHmm3hoicAYRcz3joSMTD8VGZDfTBVxf",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "064990c8",
        "accruedReward": "064990c8",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9nq6a4ix68KRct5RqoEar22ZbVGhvfxoQ1XLHeS9zmdc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Anddiiuy3VRE7gx1gh6s9QZUz7dXzHcFb3fxyTppgSed",
      "vault": "J9C9NLzxkoywCbZtuC8nF9PiYNztHnsH6UsdtB9Wvs33",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GtGSWrTpdRR7CiRBeUTAg6HZt4cUtYcgvfSzdhv88stq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A2h5KdkA7dh49aFzGD5ZtsocZDZyTV215oQ2BTcm1KWG",
      "vault": "4nJaeJYuez6qszjrDHvySjvMTPpzfDuDWCXo2FpGJg6J",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "042eac27",
        "accruedReward": "042f9bc5",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "3LWpijZvMPqsuBWTp5zfPyhNoWmUVVbuiZxrsHdT7Qck",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "A24r88tVBjggwkmREq6J8j9sSAuUjWCP1YpFfsgDCVTD",
      "vault": "4GfkfGHdFvhZJdyhRXYtiR6jSF16CZexCQcHaaM2eShD",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625de640",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "04968374",
        "accruedReward": "04968374",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625de640",
          "beginScheduleTs": "625de640",
          "lastUpdatedTs": "6262f92b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "10d3c9",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625de640",
          "beginScheduleTs": "6262f92b",
          "lastUpdatedTs": "6262f92b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "4nLWkiXR3tEd9C4TAyLC7sMno37RoHe4mV4CMRpNYEyi",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DxjU7Gm4hMURDpMP2Hq4RivyeHtje56FHjiVgGKraj8E",
      "vault": "CRaKJLRJgSTjZTSxeCcL2pP7V7JSWCajzuX8T3EgW6U9",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "626146c5",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "22c7162c",
        "accruedReward": "22c7162c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626146c5",
          "beginScheduleTs": "626146c5",
          "lastUpdatedTs": "6268b7ab",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "0d7344",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626146c5",
          "beginScheduleTs": "6268b7ab",
          "lastUpdatedTs": "6268b7ab",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DEjXqjNyTd6tB1cQsevrDbdvBW6YzyPPMjBQMBiAxpir",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Aawf6JPvj6r9tejjghdDVsDtwDLKH2f37HbtPFQi7XRX",
      "vault": "J9yGrimdfymjuDN8Jou6TAqGPtRK3jrgFAnJtzRq1thU",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "6250472e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1a755748",
        "accruedReward": "255ef88c",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6250472e",
          "beginScheduleTs": "6250472e",
          "lastUpdatedTs": "62600bf2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1e72db",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6250472e",
          "beginScheduleTs": "62600bf2",
          "lastUpdatedTs": "62600bf2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9i7QfmhmvF8KzcasXkqtg7oHueCAySa7Q42yrX1EHYLM",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "BuMBevxjVU2wXTuB9u1ubRPCsiG86hfqBj2LcUEi2UWM",
      "vault": "H9GSLnh9WFqecwEQmMDZqgCKXfogTbFfbmEPzDJ5q5u6",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6nFevzsg2eNMt3aGyuSHopBerymMpPdFw2EzebpAunrq",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "96jERPwfVo75FRVsCUe8tTeqkGEFkMs9M4LSUpTJ3ovA",
      "vault": "DeCCC3KqJuenGxG2EGwMDYfK1TH8AnHYTQdk7DZdu6Ch",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7CfXAr9y9XQZBw7srkVJ8WENqUXNk2nW2ZEPZ55S6TwA",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "27fLhJWr5bzGkxUk4kVCLZJa18hrijoXbzdWtnsRqUCy",
      "vault": "7Udr2nBHYd2ZiUTJV7tRzpe1UcuRfsrepTZtQ3gVych6",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "625773cc",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0b558dd9",
        "accruedReward": "0b55f237",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625773cc",
          "beginScheduleTs": "625773cc",
          "lastUpdatedTs": "6263fd1e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "17463d",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625773cc",
          "beginScheduleTs": "6263fd1e",
          "lastUpdatedTs": "6263fd1e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6fXJ6c2wtrVR5h3M4QdwiBXs8DiBs4fJ3DHGDDy4r36",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ByudPLv7AHdpiwu5iLaG4MJC1UsdhR7Bggq7UC782ArC",
      "vault": "4xh7jotu618g17HHNbGi3fZpKc92SU1gRpKN3qZcqpLN",
      "state": {
        "pendingCooldown": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "624812ac",
      "cooldownEndsTs": "62481303",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "4eaa",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AKPTGaKqCsvqrQEGJmKtFUtq4nU3wvSwq7h6vAatixKP",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8msGiTk6PS9cJ3jB9maeSirbVXh9MKRViFEGuLKvXU4i",
      "vault": "7hMfjRihYsdbEgZr3F7JHyvdmEZyRF6M5FTsxadJZjR",
      "state": {
        "staked": {}
      },
      "gemsStaked": "09",
      "rarityPointsStaked": "09",
      "minStakingEndsTs": "626ac0de",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "6662d113",
        "accruedReward": "6662d113",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ac0de",
          "beginScheduleTs": "626ac0de",
          "lastUpdatedTs": "626ac0fc",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "03f92b",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ac0de",
          "beginScheduleTs": "626ac0fc",
          "lastUpdatedTs": "626ac0fc",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "7T7bAsSHiaR4nA8CxhvQRdJoRbstwtS98S7D3J6NppMG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6TvhW97RXai2qBn59yU8tvKLku1QkPxuZ9VAeKePE7V5",
      "vault": "E5DV8NgU5iFHKkaCt27VjpYsz6rT8n16cW6psMmXL8RR",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "17ec1b9f",
        "accruedReward": "17ec1b9f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "6bPo9ha7RAWLTqygWn99b54QJQeavCfBh4qMGk7xebia",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FGfu7v49WWzUxn1Xe6iKqM5hPinwrLpPeDZvJxyJo517",
      "vault": "EjBbK9sAEqUNsn5kKjc7zFaq8F3r3MToyQ5FjZ3DFxJg",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9NYA8ivX5r2E6hThHj4pfKB19NA16E2khWPefnKPD32m",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DPcJideQPqLrL6qk7PXw5kCEryKixNqharYxh2Q4Mo69",
      "vault": "FPEzFZmmhkh84B5uoyDNxXS2dsau9nNeFDQFHSMwuRLR",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62599bd1",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1ad9a57f",
        "accruedReward": "1ad9a57f",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62599bd1",
          "beginScheduleTs": "62599bd1",
          "lastUpdatedTs": "62677d97",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "151e38",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62599bd1",
          "beginScheduleTs": "62677d97",
          "lastUpdatedTs": "62677d97",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9CaH7Cf1gnEPBp4kRBxP3XLNUR5P7oKdFskZyDWHriwo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "5YTyitWWSMJ8Yr5CRM8TRrV4tMmsZB9KDSg29VzLS3Kn",
      "vault": "5YJzbmDM8DESQHYTXtPLSzshpbzMWnboCLPVFsMJQkv2",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0cf457a6",
        "accruedReward": "0cf457a6",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "58HeHRvEiAnGoVe8ewjwafAH167khRWwfDq2X9o8YiLC",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DtpHvfT4fMrteKWPFkaix6eN3EkCx4EkKVbfdSUGk1Y7",
      "vault": "GKGQiCLe53DfrKcUbQdfdqgtNiC667xhmiAXmKLK2gCr",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625d0a6b",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "26bacfba",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d0a6b",
          "beginScheduleTs": "625d0a6b",
          "lastUpdatedTs": "625d0a6b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "11af9e",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625d0a6b",
          "beginScheduleTs": "625d0a6b",
          "lastUpdatedTs": "625d0a6b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HdNxzEVyRmSUTg3jQsnTK598KffFEbwjsrWuB7z3wJK7",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "AeuPKqhJ7H6SjgTUkymWfMhuYjjXSpcRBJ7ukvRE5c8k",
      "vault": "7tyyBcbdSYWAUodMr3Dm5R8N1PbgP8nMnavjTUAF1rkN",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "62558ba9",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "168ce893",
        "accruedReward": "28e31021",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62558ba9",
          "beginScheduleTs": "62558ba9",
          "lastUpdatedTs": "6269d2ab",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "192e60",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62558ba9",
          "beginScheduleTs": "6269d2ab",
          "lastUpdatedTs": "6269d2ab",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DYXrA2LMLdPH5FiQyKwkdWtWsvJY8K8oc3WztdvnFqCL",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "1477K3Jyfu4T7DvHRYJpxTrJWQGqRyrL6VLH6eACfjvc",
      "vault": "64EHQjdZdtjtzQg54ztnwJMBm36P4FZJybNAkNKw8PU5",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6254ec0e",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "6f190a5a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254ec0e",
          "beginScheduleTs": "6254ec0e",
          "lastUpdatedTs": "6269525c",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "19cdfb",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6254ec0e",
          "beginScheduleTs": "6269525c",
          "lastUpdatedTs": "6269525c",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AY7FdyXEon7tRzxVhTCCSFutscbfKUvcBiVs9VyqbKf8",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Bew186DCFLQfwRL7tMBAu7LMKtz579FcDruqVa6puP3Y",
      "vault": "4J3r33VNGWeqG1XJPEGG25gBR41hCC15nVhX2f2QmVqZ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1fea6f64",
        "accruedReward": "1fea6f64",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "AwQ3CyBr9tnWbBvXBMWM4Nq9Qv3HoWHtHKNsB92iwEmE",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "DEh7JUUEouwKgroYztyG1JX1xhEdKxyY3NpxP8x3Nk1x",
      "vault": "7AwntDyyS1MtvgADYqiLaJtHH4b2GxcCdT2HaSiyCJzh",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "625280c6",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "13f04a7e",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625280c6",
          "beginScheduleTs": "625280c6",
          "lastUpdatedTs": "62528103",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "1c3943",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "625280c6",
          "beginScheduleTs": "62528103",
          "lastUpdatedTs": "62528103",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "H2zduxdjgKeGKCqz3w4BNJiWC5gqo32xD71ETdBVp9Tz",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "FdyPk8GaCaFaezjzum4hYhWohhh7exE2MZXSHBrws5Vu",
      "vault": "9C31YE45oY5aAvup6BAQyC6T3ZC4JC69XZkS2qFjTj8G",
      "state": {
        "staked": {}
      },
      "gemsStaked": "02",
      "rarityPointsStaked": "02",
      "minStakingEndsTs": "62481bf2",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62481bf2",
          "beginScheduleTs": "62481bf2",
          "lastUpdatedTs": "62481bf2",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "269e17",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "62481bf2",
          "beginScheduleTs": "62481bf2",
          "lastUpdatedTs": "62481bf2",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "GYtTbxKDiruWfGEhBv43Twvq5ieHogLN1C74PBDtrwwg",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "7oamhjbzw2wHQQCMPj7zRcxtqNPip7LSGDZuYQ2SPAk5",
      "vault": "4CDyn9NN3mg2BuuA8QU425aFEXpuePuPTot5CGgg6ubB",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "BfCMdbDTEi8b1AJHRZ8tbrVo2XxuCWXeW8CvcSa1TwiT",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "4LfqPzrQSBd7mSgLciUEu5qmQRkkeoytG6ShPk3ZE2zs",
      "vault": "35JgsFrXZLYwD4uwmVz5aib8amEhsEH5CDvktJNgHxaK",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "H9FWjSuG5nX5cQUc74fR7KC64Z8zxk8n5HaFGKXDRGqy",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "D776DkuX57Zr6QmZm3M5yf7P7CPhkFNNYACKjfVjvkTA",
      "vault": "36LeMGLG3YhkpjQZY3V6WWhnG9cH1bCe6SnKSyT3xgoJ",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "En2XQbE7YDBVV1fZfbBvKfkZNY4gjEAJPH3n9AsVk4aG",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "G6AaBtrEzzPrjaGaseTrUB1GLa5kA87zfCS1cZAz4kN8",
      "vault": "2SZJKfPu362DtoUfNnB3dxbdoXLNnoBYGEWd1fjZETqu",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "6248f131",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "1abe083d",
        "accruedReward": "1abe083d",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248f131",
          "beginScheduleTs": "6248f131",
          "lastUpdatedTs": "6266845b",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "25c8d8",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6248f131",
          "beginScheduleTs": "6266845b",
          "lastUpdatedTs": "6266845b",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "9VWfsdqC15bXedQrED6Y1j93QiLY132VzdErYd3T6agR",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "EsanfFinPeS8efJokPKgBiUXKLzxyU4cfodR97D9npmG",
      "vault": "DETunQCnHLZ4eHMFJzTUmiHnPDdb6mPvR3mSAi1xJy1n",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "5mYpbQ7WwFvXvjBUL58E6KegKNYaWPANk8jSJUEhWJZc",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "ExphTu49QVNMqMENcWhGr3CM6NosY9ccsKHHftAg9Axp",
      "vault": "3PAyiwvYmzWDQc5vjRnVwgtoKmkbZNjgYatJpX98nM1B",
      "state": {
        "staked": {}
      },
      "gemsStaked": "01",
      "rarityPointsStaked": "01",
      "minStakingEndsTs": "626ae4ba",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ae4ba",
          "beginScheduleTs": "626ae4ba",
          "lastUpdatedTs": "626ae4ba",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "03d54f",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "626ae4ba",
          "beginScheduleTs": "626ae4ba",
          "lastUpdatedTs": "626ae4ba",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "ECuYnxG6CmqfD8sYqBLhi5NcG1C9EZLdMwLwh9hX3k47",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "8UGusDfprtPWoBxAFQSd3qQUEnKWnuvQeqywPRjHBrUc",
      "vault": "HLuh681YxpsjxkXCSW3K9mDJhdQddGfBgBax7tvA4vxK",
      "state": {
        "staked": {}
      },
      "gemsStaked": "04",
      "rarityPointsStaked": "04",
      "minStakingEndsTs": "6269c756",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "01046a",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269c756",
          "beginScheduleTs": "6269c756",
          "lastUpdatedTs": "6269c79e",
          "promisedSchedule": {
            "baseRate": "01312d00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "015180"
          },
          "promisedDuration": "04f2b3",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "6269c756",
          "beginScheduleTs": "6269c79e",
          "lastUpdatedTs": "6269c79e",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DQ3oyQsAFBcMKhsHSMLR6pY5pe4RMs97TviYmGWfuTUB",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "3Fv9YppPQ8Z1p3j3vDT8TGhVqmdNnREmQsW94Wvhg8nh",
      "vault": "8PuZHyAG8SUbcE67QGJ2ZZqUyiBbkSoaLe3krYJaepY5",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "018c8357",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "29c1y74z6ayJM3uJRUS8997FtkVSsZUEqhfYPAJ87Mfv",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "Cpn4ih85qZfoMZ97aEqiThcUVk5HjCgWF1sKupzh24Ww",
      "vault": "3dwYkR1uU7KFHNbNphsRRYBa8Ud6yLxnskjh4Jk28MZ6",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "DDqznDLtrDujt5BGHDH52RFJb8ZKR7NoXWZyvBbyRjXZ",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "9m68VabWUYYebzxDsF1tYUtxUmr6UwNDcUxHAfS3yPZU",
      "vault": "8gfb2bKgNXeW3FVcx3mhkXkz1D3jNq58DZA4RsGFvrLf",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "EzCVBQMC6PeK4qqKopG4dVUUG4PAaragWmgAvcYxhaKo",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "64RmCSpKjBpxJJSEQMhfesNqJsZphLTVejT9bLVV38bF",
      "vault": "G7tFFvPi6Ut58CbXyMxbBcMnU6FWHuspAGu4gM6YotGf",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "0ec14ac2",
        "accruedReward": "0ec14ac2",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  {
    "publicKey": "HwMk8kmBMZuaLXPq6tYEh8fNyimbdVbuzkXMw8LxVT89",
    "account": {
      "farm": "4RZM2mb7Eb1yA6JNF6tn6Wuw2jvx6CvUkKmc2XQc2fYo",
      "identity": "6EuYnyYjFq63wENqykugWe93DWjNhMehMKzXu176SRgt",
      "vault": "HVamfAmdDijnk6Yns64tViMTq2Pjg5AD3MRVHeD8RfT8",
      "state": {
        "unstaked": {}
      },
      "gemsStaked": "00",
      "rarityPointsStaked": "00",
      "minStakingEndsTs": "00",
      "cooldownEndsTs": "00",
      "rewardA": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "rewardB": {
        "paidOutReward": "00",
        "accruedReward": "00",
        "variableRate": {
          "lastRecordedAccruedRewardPerRarityPoint": {
            "n": "00"
          },
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "fixedRate": {
          "beginStakingTs": "00",
          "beginScheduleTs": "00",
          "lastUpdatedTs": "00",
          "promisedSchedule": {
            "baseRate": "00",
            "tier1": null,
            "tier2": null,
            "tier3": null,
            "denominator": "01"
          },
          "promisedDuration": "00",
          "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      "reserved": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  }
]
