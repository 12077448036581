import React from 'react'
import { Box } from '@mui/system'
import { ButtonGroup, Divider, Stack, Typography } from '@mui/material'
import { BiDollarCircle } from 'react-icons/bi'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

const data = [
  {
    name: 'Page A',
    uv: 1000,
    pv: 1000,
    amt: 500,
  },
  {
    name: 'Page B',
    uv: 400,
    pv: 450,
    amt: 1000,
  },
  {
    name: 'Page C',
    uv: 1000,
    pv: 9800,
    amt: 1500,
  },
  {
    name: 'Page D',
    uv: 1780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2500,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 3000,
  },
  {
    name: 'Page G',
    uv: 2900,
    pv: 4300,
    amt: 3300,
  },
]

const timeElapsed = Date.now()
const today = new Date(timeElapsed)

export default function MbcStats(props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      style={{
        width: '100%',
        borderRadius: 8,
        marginTop: 10,
        padding: 0,
        overflow: 'hidden',
        border: '0.84px solid #424549',
      }}
    >
      <Stack
        style={{ width: '100%', padding: 10, background: '#1D262C' }}
        alignItems="center"
        direction="row"
        display="flex"
      >
        <Stack>
          <Typography style={{ fontSize: 14, color: '#DBDBDB' }}> $MBC Burn</Typography>
        </Stack>
        <ButtonGroup> </ButtonGroup>
      </Stack>{' '}
      <Divider style={{ background: '#424549' }} />
      <Stack style={{ margin: 10 }}>
        {/* <Stack
          style={{ display: 'flex', marginBottom: 5 }}
          justifyContent="space-between"
          display="flex"
          direction="row"
        >
          <Stack>
            <Typography style={{ fontSize: 11 }}> {today.toDateString()} </Typography>
          </Stack>
          <Stack>
            <Typography style={{ fontSize: 11 }}> Daily </Typography>
          </Stack>
        </Stack> */}

        <Stack style={{ display: 'flex' }} justifyContent="space-between" display="flex" direction="row">
          {' '}
          <Stack>
            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}> Burn </Typography>
            {/* <Typography style={{ fontSize: 11 }}> {today.toDateString()} </Typography> */}
          </Stack>
          <Stack>
            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
              {' '}
              {props.system ? props.system[1].total : '0'}
            </Typography>
            {/* <Typography style={{ fontSize: 11 }}> daily </Typography> */}
          </Stack>
        </Stack>
      </Stack>{' '}
      {/* <Box display="flex" style={{ width: '100%', zIndex: 0, height: 140, marginTop: -50 }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            height={80}
            data={data}
            margin={{
              top: 10,
              left: 0,
              bottom: 0,
            }}
          >
            <Area type="monotone" dataKey="uv" stackId="1" stroke="#14f095" fill="#14f095" />
            <Area type="monotone" dataKey="amt" stackId="1" stroke="#7970bf" fill="#7970bf" />
          </AreaChart>
        </ResponsiveContainer>
      </Box> */}
    </Box>
  )
}

// <Stack style={{ display: 'flex' }} justifyContent="space-between" display="flex" direction="row">
// {' '}
// <Stack>
//   <Typography style={{ fontSize: 16, fontWeight: 'bold' }}> Emissions </Typography>
//   {/* <Typography style={{ fontSize: 11 }}> {today.toDateString()} </Typography> */}
// </Stack>
// <Stack>
//   <Typography style={{ fontSize: 16, fontWeight: 'bold' }}> 33000{}</Typography>
//   {/* <Typography style={{ fontSize: 11 }}> daily </Typography> */}
// </Stack>
// </Stack>
