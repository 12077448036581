import { ButtonGroup, Divider, Paper } from '@material-ui/core'
import LinearProgress from '@mui/material/LinearProgress'

import { Stack, Typography, Skeleton, Chip, Badge, CircularProgress, Switch } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import Popover from '@mui/material/Popover'

import { collection, where, query, doc, onSnapshot, getFirestore, updateDoc } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import React, { useEffect } from 'react'
import {
  BiCopy,
  BiEdit,
  BiEditAlt,
  BiImageAlt,
  BiSend,
  BiRadioCircleMarked,
  BiCoinStack,
  BiTrophy,
  BiDollarCircle,
  BiKey,
  BiCheck,
  BiDollar,
  BiCircle,
  BiRectangle,
  BiSticker,
  BiSlider,
  BiSliderAlt,
  BiGitCommit,
  BiFilter,
  BiMenu,
  BiCog,
} from 'react-icons/bi'
import { FiEdit2 } from 'react-icons/fi'
import tempAv from '../../assets/images/moneyboys/504.png'
import { theme } from '../../theme'
import { numberWithCommas } from '../../utils/utils'
import EditProfile from '../dialogs/EditProfile'
import { BsFillEyeSlashFill, BsOption, BsTwitter } from 'react-icons/bs'
import { FaDiscord } from 'react-icons/fa'

export default function Profile(props) {
  const [user, setUser] = React.useState(props.user)
  const [isHovering, setIsHovering] = React.useState(false)
  const [isEditing, setIsEditing] = React.useState(true)
  const [username, setUsername] = React.useState(null)
  const [banner, setBanner] = React.useState(null)
  const [profilePicture, setProfilePicture] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [isHoveringAvatar, setIsHoveringAvatar] = React.useState(false)
  const [selectNFTOpen, setSelectNFTOpen] = React.useState(false)
  const [claiming, setClaiming] = React.useState(false)
  const [confirming, setConfirming] = React.useState(false)
  const [image, setImage] = React.useState('')
  const [skin, setSkin] = React.useState('')
  const [discordID, setDiscordID] = React.useState('')

  const [left, setLeft] = React.useState('z')
  const [privateMode, setPrivateMode] = React.useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClickPop = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePop = () => {
    setAnchorEl(null)
  }

  const popOpen = Boolean(anchorEl)
  const id = popOpen ? 'simple-popover' : undefined

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const switchShowNFTInNetWorth = async user => {
    let newConfig = props.user.config

    if (newConfig.showNFTInNetWorth) {
      newConfig.showNFTInNetWorth = false
    } else {
      newConfig.showNFTInNetWorth = true
    }
    const db = getFirestore()
    const userReference = doc(db, 'wallet_users', props.user.wallet)
    await updateDoc(userReference, {
      config: newConfig,
    })
  }

  const switchPrivateMode = async () => {
    setPrivateMode(!privateMode)
  }

  const editBanner = async () => {
    const db = getFirestore()
    const userReference = doc(db, 'Users', props.user.userID)
    await updateDoc(userReference, {
      banner: banner,
    })
  }

  const editDiscordID = async () => {
    const db = getFirestore()
    const userReference = doc(db, 'wallet_users', props.user.wallet)
    await updateDoc(userReference, {
      discordID: discordID,
    })
  }

  const editQuantity = async () => {
    let right = left - 1
    const db = getFirestore()
    const userReference = doc(db, 'values', 'jjan1mIMz4FLtX2thvac')
    await updateDoc(userReference, {
      left: right,
    })
  }

  const editProfilePicture = async () => {
    const db = getFirestore()
    const userReference = doc(db, 'wallet_users', props.user.wallet)
    await updateDoc(userReference, {
      profilePicture: profilePicture,
    })
  }

  const editSkin = async () => {
    const db = getFirestore()
    const userReference = doc(db, 'wallet_users', props.user.wallet)
    await updateDoc(userReference, {
      skin: skin,
    })
  }

  const editUsername = async () => {
    const db = getFirestore()
    const userReference = doc(db, 'wallet_users', props.user.wallet)
    await updateDoc(userReference, {
      username: username,
    })
  }

  const handleFireBaseUpload = async (e, imageAsFile, type) => {
    const storage = getStorage()

    e.preventDefault()
    console.log('start of upload')
    if (imageAsFile === '') {
      console.error(`not an image, the image file is a ${typeof imageAsFile.name}`)
      return
    }
    if (imageAsFile) {
      if (type == 'pfp') {
        const profilePictureRef = await ref(storage, 'images/profilePictures/' + imageAsFile.name)
        await uploadBytes(profilePictureRef, imageAsFile).then(snapshot => {
          console.log('Uploaded a blob or file!')
        })
        let downloadURL = await getDownloadURL(ref(profilePictureRef))
        setProfilePicture(downloadURL)
      } else {
        const bannerRef = await ref(storage, 'images/banners/' + imageAsFile.name)
        console.log('banref', bannerRef)
        await uploadBytes(bannerRef, imageAsFile).then(snapshot => {
          console.log('Uploaded a blob or file!')
        })
        let downloadURL = await getDownloadURL(ref(bannerRef))
        setBanner(downloadURL)
      }
    }
  }

  const handleImageAsFile = async (e, type) => {
    if (isEditing) {
      // setIsLoading(true)
      //   setIsLoadingImage(true);
      const image = e.target.files[0]
      if (type === 'pfp') {
        setProfilePicture(image)
      } else {
        setBanner(image)
      }
      await handleFireBaseUpload(e, image, type)
    }
  }
  const saveEdits = () => {
    if (banner) {
      editBanner()
    }
    if (username) {
      editUsername()
    }
    if (skin) {
      editSkin()
    }
    if (discordID) {
      editDiscordID()
    }
    if (profilePicture) {
      editProfilePicture()
    }
    //SaveChanges
    setIsEditing(false)
  }
  const cancelEdit = () => {
    setIsEditing(false)
  }

  const sumTotalBalance = totalBalance => {
    let sum = 0
    sum += totalBalance.sol
    sum += totalBalance.splToken - totalBalance.sol
    if (!props.user.config.showNFTInNetWorth) {
      sum += totalBalance.NFTs
    }
    return sum
  }

  const showConfirmed = async confirm => {
    setConfirming(true)
    setTimeout(() => {
      setConfirming(false)
    }, 5000)
  }

  const claimReward = async type => {
    setClaiming(true)
    editQuantity()
    setTimeout(async () => {
      if (type === 'og') {
        const db = getFirestore()
        const userReference = doc(db, 'wallet_users', props.user.wallet)
        await updateDoc(userReference, {
          rewards: ['og'],
        })
      }
      setClaiming(false)
      showConfirmed()
    }, 4000)
  }

  useEffect(() => {
    if (props.user && !props.user.profilePicture) {
      getProfile(props.user)
    }
    if (props.user.profilePicture !== null) {
      setImage(props.user.profilePicture)
    }

    return () => {}
  }, [])

  const getProfile = user => {
    if (user.nfts) {
      user.nfts.forEach(nft => {
        if (nft.image) {
          console.log(nft.image)
          return setImage(nft.image)
        }
      })
    }
  }

  const switchOGConfig = async user => {
    let newConfig = user.config
    if (user.config.og) {
      newConfig.og = !user.config.og
    } else {
      user.config.og = true
    }

    const db = getFirestore()
    const userReference = doc(db, 'wallet_users', props.user.wallet)
    await updateDoc(userReference, {
      config: newConfig,
    })
  }

  useEffect(async () => {
    const db = getFirestore()
    const collectionR = collection(db, 'values')
    const q = query(collectionR, where('type', '==', 'og1'))
    const unsubscribe = await onSnapshot(q, querySnapshot => {
      const userCollection = querySnapshot.docs.map(doc => doc.data())
      let data = userCollection[0]
      if (data) {
        setLeft(data.left)
      }
    })
    return () => {}
  }, [])

  // const editProfilePicture = () => {}

  let shortenedWallet =
    props.user.wallet.substring(0, 4) +
    '....' +
    props.user.wallet.substring(props.user.wallet.length - 5, props.user.wallet.length - 1)

  return (
    <Paper
      elevation={0}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        backgroundColor: '#141A1E',
        color: 'white',
        margin: 0,
      }}
    >
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="center"
          style={{
            height: 130,
            overflow: 'hidden',
            background:
              props.user.skin === 'og'
                ? `linear-gradient(${0.25}turn, #735E00, #6B5A00)`
                : `linear-gradient(${0.25}turn, #9945FF, #14F195)`,
            borderBottom: '0.8px solid #424549',
          }}
        >
          <img src={''} style={{ width: '100%' }} />
        </Box>
        <Box style={{ zIndex: 20, padding: 20, marginTop: isHoveringAvatar ? -119 : -95, marginBottom: 0 }}>
          {isHoveringAvatar ? (
            <IconButton
              onMouseLeave={() => setIsHoveringAvatar(false)}
              onClick={() => setSelectNFTOpen(true)}
              style={{ position: 'relative', margin: 0, marginBottom: -160, zIndex: 10, width: 140, height: 140 }}
            >
              {/* <BiEditAlt size={40} /> */}
            </IconButton>
          ) : null}

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <label htmlFor="contained-button-file">
              <Avatar
                onMouseEnter={() => setIsHoveringAvatar(true)}
                style={{
                  cursor: isEditing ? 'pointer' : 'default',
                  boxShadow:
                    isHovering && isEditing
                      ? '0px 5px 20px -2px rgb(0, 0, 0, 0.40)'
                      : '0px 0px 0px 0px rgb(0, 0, 0, 0.2)',
                }}
                src={props.user.profilePicture ? props.user.profilePicture : props.pfp}
                sx={{
                  width: 140,
                  height: 140,
                  border: 5,
                  borderColor: props.user.skin === 'og' ? '#A38500' : '#141A1E',
                }}
              />
            </label>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start" style={{ margin: 0 }}>
            <Box display="flex" flexDirection="column">
              <Stack display="flex" alignItems="center" direction="row" style={{ maxWidth: 230, overflow: 'hidden' }}>
                <p
                  style={{
                    fontSize: 24,
                    color: 'white',
                    fontWeight: 1000,
                    maxWidth: 190,
                    overflow: 'hidden',
                    marginTop: 10,
                  }}
                >
                  @{props.user.username ? props.user.username : shortenedWallet}
                </p>
                <IconButton
                  style={{ marginTop: -6, marginLeft: 1 }}
                  onClick={() => setSelectNFTOpen(true)}
                  size={'small'}
                >
                  <BiEditAlt />
                </IconButton>
              </Stack>
              <Stack display="flex" alignItems="center" direction="row" style={{ marginTop: -16 }}>
                <p style={{ fontSize: 15, color: '#B1B1B1', marginTop: -1 }}>{shortenedWallet}</p>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(props.user.wallet)
                  }}
                  size={'small'}
                  style={{ marginTop: -8, marginLeft: 2 }}
                >
                  <BiCopy />
                </IconButton>
                <Chip
                  size="small"
                  style={{ maxWidth: 140, marginTop: -10, marginLeft: 5, padding: 3, fontSize: 11 }}
                  icon={<BiTrophy size={12} />}
                  variant="outlined"
                  label="early adopter"
                >
                  {' '}
                </Chip>
              </Stack>
              <Stack style={{ fontWeight: '900', marginTop: -10, marginBottom: 10 }}>
                <Stack style={{ marginTop: 2, marginBottom: 2 }} direction="row">
                  <Typography style={{ marginRight: 10, fontSize: 14 }}>Joined in April, 2022</Typography>{' '}
                </Stack>
              </Stack>
              <Stack style={{ fontWeight: '900', marginTop: -5 }}>
                <Stack direction="row">
                  <Typography style={{ marginRight: 10, fontSize: 17, fontWeight: 'bold' }}>0 Followers</Typography>{' '}
                  <Typography style={{ marginRight: 10, fontSize: 17, fontWeight: 'bold' }}>0 Following</Typography>{' '}
                </Stack>
              </Stack>
            </Box>

            <Box display="flex" style={{ width: '100%', maxWidth: 350 }} flexDirection="row">
              <Box
                style={{ marginTop: -55, width: '100%' }}
                display="flex"
                alignItems="flex-end"
                flexDirection="column"
              >
                <Box display="flex" alignItems="center">
                  {props.user.rewards.includes('ogzz') && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-around"
                      style={{
                        marginTop: 0,
                        marginRight: 10,
                        padding: 7,
                        height: 39,
                        width: '100%',
                        border: '0.9px solid #14f095',
                        borderRadius: 7,
                      }}
                    >
                      {confirming ? (
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Typography
                            style={{ fontWeight: '900', minWidth: 80, fontSize: 13, color: '#14f095', marginLeft: 5 }}
                          >
                            {' '}
                            Reward Confirmed. Cha-Ching!
                          </Typography>
                          <BiCheck size={15} style={{ margin: 3, marginLeft: 10, color: '#14f095' }} />
                        </Box>
                      ) : (
                        <>
                          <Typography style={{ fontWeight: '100', fontSize: 13, color: '#14f095' }}>
                            {' '}
                            Reward:{' '}
                          </Typography>
                          <Typography style={{ fontWeight: '900', fontSize: 13, color: '#14f095', marginLeft: 5 }}>
                            {' '}
                            OG Care Package
                          </Typography>

                          {!claiming ? (
                            <Button
                              onClick={() => claimReward('og')}
                              color="secondary"
                              variant="outlined"
                              style={{ marginLeft: 8, padding: 0, textTransform: 'none' }}
                              size="small"
                            >
                              {' '}
                              Claim
                            </Button>
                          ) : (
                            <CircularProgress style={{ marginLeft: 15, width: 20, height: 20, color: '#14f095' }} />
                          )}
                        </>
                      )}
                    </Box>
                  )}

                  {/* <Badge
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    overlap="circular"
                    color="secondary"
                    badgeContent={props.user.rewards.includes('og')}
                    variant="dot"
                  >
                    <IconButton
                      // disabled={true}
                      disabled={!props.user.rewards.includes('og')}
                      onClick={handleClickPop}
                      aria-describedby={id}
                      style={{ border: '0.82px solid #424549', margin: 3 }}
                    >
                      <BiKey style={{ margin: -3 }} />
                    </IconButton>
                  </Badge> */}
                </Box>

                {props.totalBalance ? (
                  <Box
                    style={{
                      margin: 10,
                      marginRight: 0,
                      padding: 20,
                      width: '90%',
                      border: '0.8px solid #424549',
                      borderRadius: 10,
                    }}
                  >
                    <Box display="flex" justifyContent="space-between">
                      <Stack>
                        <Typography style={{ fontWeight: '100', fontSize: 13, color: '#B1B1B1' }}>
                          {' '}
                          Net Worth{' '}
                        </Typography>
                        {privateMode ? (
                          <Box display="flex" style={{ margin: 5 }}>
                            <BsFillEyeSlashFill size={25} />
                          </Box>
                        ) : (
                          <Typography style={{ fontWeight: '900', fontSize: 27 }}>
                            {' '}
                            ${props.totalBalance ? numberWithCommas(sumTotalBalance(props.totalBalance).toFixed(2)) : 0}
                          </Typography>
                        )}
                      </Stack>

                      <IconButton
                        onClick={handleClickPop}
                        aria-describedby={id}
                        size={'small'}
                        style={{
                          color: 'white',
                          border: '1px solid #424549',
                          height: 40,
                          width: 40,
                          padding: 0,
                        }}
                        variant="outlined"
                      >
                        <BiMenu size={18} style={{ margin: -5 }} />
                      </IconButton>

                      <Popover
                        id={id}
                        style={{ border: '0.82px solid #424549' }}
                        open={popOpen}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        anchorEl={anchorEl}
                        onClose={handleClosePop}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                      >
                        <Box
                          style={{
                            padding: 0,
                            minWidth: 200,
                            border: '0.89px solid #424549',
                            backgroundColor: '#141a1e',
                          }}
                        >
                          <Box
                            alignItems="center"
                            justifyContent="space-between"
                            style={{ padding: 10, borderBottom: '0.85px solid #424549' }}
                            display="flex"
                          >
                            {' '}
                            <Typography style={{ fontSize: 15, fontWeight: 'bold' }} sx={{ color: 'white' }}>
                              {' '}
                              Net Worth Settings
                            </Typography>
                            <BiCog style={{ color: 'white' }} />
                          </Box>
                          <Box
                            alignItems="center"
                            justifyContent="space-between"
                            style={{ padding: 10, borderBottom: '0.83px solid #424549' }}
                            display="flex"
                          >
                            {' '}
                            <Typography style={{ fontSize: 13 }} sx={{ color: 'white' }}>
                              {' '}
                              Show NFTs in Net Worth{' '}
                            </Typography>
                            <Switch
                              checked={!props.user.config.showNFTInNetWorth}
                              onChange={() => switchShowNFTInNetWorth(props.user)}
                            />
                          </Box>
                          <Box
                            alignItems="center"
                            justifyContent="space-between"
                            style={{ padding: 10, borderBottom: '0.83px solid #424549' }}
                            display="flex"
                          >
                            {' '}
                            <Typography style={{ fontSize: 13 }} sx={{ color: 'white' }}>
                              {' '}
                              Private Mode{' '}
                            </Typography>
                            <Switch checked={privateMode} onChange={() => switchPrivateMode()} />
                          </Box>
                        </Box>
                      </Popover>
                    </Box>

                    <Divider style={{ backgroundColor: '#424549', height: 0.08, marginTop: 5, marginBottom: 10 }} />
                    <Stack direction="column" display="flex" justifyContent="center">
                      <Stack alignItems="center" display="flex" direction="row">
                        <BiRadioCircleMarked size={12} style={{ marginRight: 2, color: '#9a94fe' }} />
                        <Typography style={{ fontSize: 12, fontWeight: 'bold', color: '#9a94fe', width: 35 }}>
                          SOL
                        </Typography>
                        <Box display="flex" sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ width: '100%', color: '#9a94fe', minWidth: 80, marginLeft: 1 }}>
                            <LinearProgress
                              color="inherit"
                              variant="determinate"
                              value={(props.totalBalance.sol / sumTotalBalance(props.totalBalance)) * 100}
                            />
                          </Box>
                          <Box sx={{ minWidth: 0.1, marginLeft: 1, color: '#9a94fe' }}>
                            <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>{`${Math.round(
                              (props.totalBalance.sol / sumTotalBalance(props.totalBalance)) * 100,
                            )}%`}</Typography>
                          </Box>
                        </Box>
                      </Stack>
                      <Stack alignItems="center" display="flex" direction="row">
                        <BiCoinStack size={12} style={{ marginRight: 2, color: '#fff87f' }} />{' '}
                        <Typography style={{ fontSize: 12, fontWeight: 'bold', color: '#fff87f', width: 35 }}>
                          SPL
                        </Typography>
                        <Box display="flex" sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ width: '100%', color: '#fff87f', minWidth: 80, marginLeft: 1 }}>
                            <LinearProgress
                              color="inherit"
                              variant="determinate"
                              value={
                                ((props.totalBalance.splToken - props.totalBalance.sol) /
                                  sumTotalBalance(props.totalBalance)) *
                                100
                              }
                            />
                          </Box>
                          <Box sx={{ minWidth: 0.1, marginLeft: 1, color: '#fff87f' }}>
                            <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>{`${Math.round(
                              ((props.totalBalance.splToken - props.totalBalance.sol) /
                                sumTotalBalance(props.totalBalance)) *
                                100,
                            )}%`}</Typography>
                          </Box>
                        </Box>{' '}
                      </Stack>
                      <Stack alignItems="center" display="flex" direction="row">
                        <BiImageAlt size={12} style={{ marginRight: 2, color: '#7fff9b' }} />{' '}
                        <Typography style={{ fontSize: 12, fontWeight: 'bold', color: '#7fff9b', width: 35 }}>
                          NFTs
                        </Typography>
                        <Box display="flex" sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ width: '100%', color: '#7fff9b', minWidth: 80, marginLeft: 1 }}>
                            <LinearProgress
                              color="inherit"
                              variant="determinate"
                              value={(props.totalBalance.NFTs / sumTotalBalance(props.totalBalance)) * 100}
                            />
                          </Box>
                          <Box sx={{ minWidth: 0.1, fontSize: 12, marginLeft: 1, color: '#7fff9b' }}>
                            <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>{`${Math.round(
                              (props.totalBalance.NFTs / sumTotalBalance(props.totalBalance)) * 100,
                            )}%`}</Typography>
                          </Box>
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                ) : (
                  <Skeleton
                    variant="rectangular"
                    style={{ borderRadius: 8, margin: 10 }}
                    animation="wave"
                    width={210}
                    height={140}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {selectNFTOpen && (
        <EditProfile
          setSkin={setSkin}
          switchOGConfig={switchOGConfig}
          saveEdits={saveEdits}
          setUsername={setUsername}
          setDiscordID={setDiscordID}
          setBanner={setBanner}
          setProfilePicture={setProfilePicture}
          user={props.user}
          nfts={props.nfts}
          open={selectNFTOpen}
          setOpen={setSelectNFTOpen}
        />
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message="Coming Soon!" />
    </Paper>
  )
}

{
  /* <Button style={{ height: 30, textTransform: 'none' }} variant="contained">
              <p style={{ fontWeight: '600' }}>Connect</p>
            </Button> */
}

{
  /* <p style={{ fontSize: 13, marginTop: -10 }}> Be kind | SolGods, Degods, MoneyBoys | 🤝</p> */
}
{
  /* <Box display="flex" justifyContent="space-between" style={{ width: 95, fontSize: 13, marginTop: -20 }}>
              <p style={{}}> 0 </p> <p> connections </p>
            </Box> */
}

{
  /* <Box display="flex" style={{ marginTop: -30 }}>
              <Stack style={{ textAlign: 'center', marginTop: -10 }}>
                <p style={{ fontWeight: 900, fontSize: 35, marginBottom: -4, margin: 0 }}>2</p>
                <p style={{ fontWeight: 900, fontSize: 15, margin: 0 }}>MoneyBoys</p>
              </Stack>
              <Stack style={{ textAlign: 'center', marginTop: -10, marginRight: 14, marginLeft: 14 }}>
                <p style={{ fontWeight: 900, fontSize: 35, marginBottom: -4, margin: 0 }}>2</p>
                <p style={{ fontWeight: 900, fontSize: 15, margin: 0 }}>MoneyGirls</p>
              </Stack>
              <Stack style={{ textAlign: 'center', marginTop: -10 }}>
                <p style={{ fontWeight: 900, fontSize: 35, marginBottom: -4, margin: 0 }}>2</p>
                <p style={{ fontWeight: 900, fontSize: 15, margin: 0 }}>DiamondBoys</p>
              </Stack>
            </Box> */
  //             <Box display="flex" style={{ width: '100%', zIndex: 0, height: 156, marginTop: -40 }}>
  // <ResponsiveContainer width="100%" height="100%">
  //   <AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
  //     {/* <XAxis dataKey="name" /> */}
  //     {/* <YAxis /> */}
  //     {/* <CartesianGrid strokeDasharray="3 3" /> */}
  //     {/* <Tooltip /> */}
  //     {/* <ReferenceLine x="Page C" stroke="white" label="Min PAGE" /> */}
  //     {/* <ReferenceLine y={4000} label="Max" stroke="red" strokeDasharray="3 3" /> */}
  //     <Area type="monotone" dataKey="uv" stroke="#9a94ff" fill="#9a94ff" />
  //   </AreaChart>
  // </ResponsiveContainer>
  // </Box>
}

//popover

{
  /* <Popover
                    id={id}
                    style={{ border: '0.82px solid #424549' }}
                    open={popOpen}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    anchorEl={anchorEl}
                    onClose={handleClosePop}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    <Box
                      style={{ padding: 0, minWidth: 200, border: '0.89px solid #424549', backgroundColor: '#141a1e' }}
                    >
                      <Box
                        alignItems="center"
                        justifyContent="space-between"
                        style={{ padding: 10, borderBottom: '0.89px solid #424549' }}
                        display="flex"
                      >
                        {' '}
                        <Typography sx={{ color: 'white', fontWeight: 'bold' }}> My Bag </Typography>
                        <BiDollar style={{ color: 'white' }} />
                      </Box>
                      <Box alignItems="center" style={{ padding: 10, marginLeft: 4, marginBottom: -10 }} display="flex">
                        {' '}
                        <Typography sx={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>
                          {' '}
                          OG Care Package (1)
                        </Typography>
                        <Switch
                          checked={props.user.config.og}
                          onChange={() => switchOGConfig(props.user)}
                          color="primary"
                        />
                      </Box>
                      <Box
                        alignItems="center"
                        style={{ padding: 5, margin: 12, borderRadius: 10, border: '1px solid #A38500' }}
                        display="flex"
                      >
                        <BiCircle style={{ color: '#A38500', marginRight: 10 }} />
                        <Typography style={{ color: '#A38500', fontSize: 14 }}> Gold Profile Ring </Typography>
                      </Box>
                      <Box
                        alignItems="center"
                        style={{ padding: 5, margin: 12, borderRadius: 10, border: '1px solid #A38500' }}
                        display="flex"
                      >
                        <BiRectangle style={{ color: '#A38500', marginRight: 10 }} />
                        <Typography style={{ color: '#A38500', fontSize: 14 }}> OG Banner Color </Typography>
                      </Box>
                      <Box
                        alignItems="center"
                        style={{ padding: 5, margin: 12, borderRadius: 10, border: '0.9px solid #A38500' }}
                        display="flex"
                      >
                        <BiImageAlt style={{ color: '#A38500', marginRight: 10 }} />
                        <Typography style={{ color: '#A38500', fontSize: 14 }}> 3 Raffle Tickets </Typography>
                      </Box>
                    </Box>
                  </Popover> */
}
