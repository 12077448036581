import { Avatar, Chip, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import React from 'react'
import mbcLogo from '../../assets/images/logo13.png'
import luna from '../../assets/images/moneyboys/luna.png'
import mb from '../../assets/images/moneyboys/5.png'
import gif from '../../assets/images/nothing.png'
import { theme } from '../../theme'
import breakpoints from '../../utils/breakpoints'
import colors from '../../utils/colors'
import { BiDollar } from 'react-icons/bi'

export default function MarketPreview() {
  const [isHovering, setIsHovering] = React.useState(false)
  const isSmallDevice = useMediaQuery(`(max-width:${breakpoints.smallDevice})`)

  const { palette } = theme
  return (
    <Paper
      border={1}
      elevation={1}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        width: '100%',
        background: `linear-gradient(${0.25}turn, #7dff9d, #0e70ff)`,
        padding: 0,
        height: 150,
        borderRadius: 0,
        borderBottom: '0.8px solid #424549',
        color: 'white',
        overflow: 'hidden',
      }}
    >
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: `${isSmallDevice ? 'column' : 'row'}`,
          maxWidth: '1038px',
          overflow: 'hidden',
        }}
      >
        <Grid
          item
          sx={12}
          md={6}
          style={{
            display: 'flex',
            height: 150,
            alignItems: 'center',
            padding: '20px',
            color: colors.white,
          }}
        >
          <Box>
            <Box style={{}}>
              <Stack style={{ marginLeft: 0 }} display="flex">
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 900,
                    paddingBottom: '5px',
                    fontSize: 35,
                    color: 'black',
                  }}
                >
                  store
                </Typography>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 400,
                    fontSize: 15,
                    color: 'black',
                  }}
                >
                  buy and sell nfts, and more.
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          sx={12}
          md={6}
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            padding: 20,
          }}
        >
          <Box
            style={{
              borderRadius: 8,
              marginTop: 10,
              padding: 5,

              backgroundColor: '#202a30',
              maxWidth: 150,
            }}
          >
            <Stack direction="row" display="flex" alignItems="center">
              <Avatar style={{ margin: 3, marginRight: 5, height: 30, width: 30 }} src={mbcLogo}>
                {' '}
              </Avatar>
              <Stack style={{ marginRight: 6 }} display="flex" alignItems="flex-start">
                {' '}
                <Typography style={{ fontWeight: '400', fontSize: 13 }}> $MBC Burned </Typography>
                <Typography style={{ fontWeight: '600', marginTop: -2, fontSize: 13 }}> 600,000+ </Typography>
              </Stack>
            </Stack>
          </Box>
          {/* <Box
            style={{
              maxWidth: 350,
              border: '0.8px solid #424549',
              backgroundColor: '#202a30',
              borderRadius: 8,
              margin: 10,
              overflow: 'hidden',
            }}
          >
            <img
              src={gif}
              style={{
                maxHeight: 220,
                height: '90%',
                width: '100%',
                objectFit: 'cover',
              }}
            />{' '}
            <Stack display="flex" alignItems="center" justifyContent="space-between" direction="row">
              <Stack display="flex" alignItems="center" direction="row">
                <Avatar style={{ border: '1px solid #424549', margin: 6, height: 30, width: 30 }} src={mb}>
                  {' '}
                </Avatar>
                <p style={{ fontWeight: 800, fontSize: 14 }}> @og_money</p>
              </Stack>
            </Stack>
          </Box> */}
        </Grid>
      </Grid>
    </Paper>
  )
}
