import { Stack } from '@mui/material'
import React from 'react'

export default function Label(props) {
  return (
    <Stack style={{ margin: 0, overflow: 'hidden' }}>
      <p
        style={{
          margin: 0,
          fontSize: 14,
          fontWeight: 'bold',
          textTransform: 'capitalize',
        }}
      >
        {' '}
        {props.title}{' '}
      </p>
      <p
        style={{
          marginTop: props.size == 'small' ? 5 : 0,
          fontSize: props.size == 'small' ? 13 : 16,
          fontWeight: 'bold',
        }}
      >
        {' '}
        {props.metric}{' '}
      </p>
    </Stack>
  )
}
