import { CircularProgress, Toolbar } from '@mui/material'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { BiWallet } from 'react-icons/bi'
import { Typography, Stack } from '@mui/material'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import CoinList from '../../../components/coinList/CoinList'
import NFTGrid from '../../../components/NFTgrid/NFTGrid'
import NFTPreview from '../../../components/NFTPreview/NFTPreview'
import Profile from '../../../components/profile/Profile'
import ConnectWallet from '../../../components/wallet/connectWallet/ConnectWallet'

import ReactTooltip from 'react-tooltip'
import { BiSend } from 'react-icons/bi'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const Alpha = props => {
  const [balance, setBalance] = useState(null)
  const [nfts, setNfts] = useState(null)
  const [moneyNfts, setMoneyNfts] = useState(null)
  const [open, setOpen] = React.useState(true)
  const [top, setTop] = React.useState([])
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  // const { connection } = useConnection()
  // const { publicKey, sendTransaction } = useWallet()

  const handleClickOpen = () => {
    setOpen(true)
  }

  return (
    <div style={{ backgroundColor: '#141A1E', padding: 0 }}>
      <Toolbar style={{ borderBottom: '0.8px solid #424549', maxHeight: 24, fontSize: 19, padding: 0 }}>
        <Box style={{ width: '100%' }} display="flex" justifyContent="space-between" alignItems="center">
          <p style={{ fontWeight: 'bold', marginLeft: 20 }}>Alpha </p>
        </Box>
      </Toolbar>
      <Box
        display="flex"
        justifyContent="center"
        style={{
          background: '#141A1E',
          padding: 0,
          margin: 30,
          borderRadius: 10,
          border: '0.8px solid #424549',
          color: 'white',
          overflow: 'hidden',
          padding: 20,
          width: '90%',
        }}
      >
        {' '}
        <Stack display="flex" justifyContent="center" alignItems="center">
          <Box
            borderRadius={100}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 90, width: 90, backgroundColor: '#202a30' }}
          >
            {' '}
            <p style={{ fontSize: 60 }}> 🤫 </p>
          </Box>

          <Typography style={{ fontSize: 22, marginTop: 10, marginBottom: -5 }}>
            {' '}
            Unlock alpha early with $MBC{' '}
          </Typography>
          <Typography
            color="secondary"
            style={{
              textAlign: 'center',
              textTransform: 'uppercase',
              fontSize: 15,
              marginTop: 20,
              marginBottom: 20,
              fontWeight: 800,
            }}
          >
            Next Update: May 1st, 2022
          </Typography>
        </Stack>
      </Box>
    </div>
  )
}
export default Alpha
