import { Avatar, Button, Divider, Grid, IconButton, Stack, TextField, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Snackbar from '@mui/material/Snackbar'
import { Box } from '@mui/system'
import { createTransfer, encodeURL } from '@solana/pay'
import { clusterApiUrl, Connection, Keypair, PublicKey, sendAndConfirmTransaction } from '@solana/web3.js'
import BigNumber from 'bignumber.js'
import { default as React } from 'react'
import { BiCircle, BiCoin, BiStopwatch } from 'react-icons/bi'
import { collection, where, query, doc, onSnapshot, getFirestore, updateDoc } from 'firebase/firestore'

import Countdown from 'react-countdown'

import { useWallet } from '@solana/wallet-adapter-react'
import { FaDiscord } from 'react-icons/fa'
import { BsTwitter } from 'react-icons/bs'

export default function WhiteListRow(props) {
  const [product, setProduct] = React.useState(null)
  const [amount, setAmount] = React.useState(0)
  const [open, setOpen] = React.useState(false)
  const [openError, setOpenError] = React.useState(false)
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [openSending, setOpenSending] = React.useState(false)
  const [ticketsBought, setTicketsBought] = React.useState(0)
  const [productDBID, setProductDBID] = React.useState(null)
  const [isHovering, setIsHovering] = React.useState(false)

  const { publicKey, sendTransaction } = useWallet()

  const changeTicketAmount = amount => {
    if (amount > 100) {
      setAmount(100)
    } else if (amount < 0) {
      setAmount(0)
    } else {
      setAmount(amount)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenSuccess(false)
    setOpenError(false)
  }

  const getGoLive = date => {
    return new Date(date)
  }

  function convertUTCDateToLocalDate(date) {
    try {
      var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)

      var offset = date.getTimezoneOffset() / 60
      var hours = date.getHours()

      if (offset < 0) {
        return false
      }

      newDate.setHours(hours - offset)

      return newDate
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const addPurchase = async (user, productName, amount) => {
    let am = amount / props.project.whitelist.ticketPrice //needs to be edited
    let userPurchases = user.purchases ? user.purchases : {}
    // console.log(userPurchases)
    if (userPurchases[productName]) {
      let new_amount = userPurchases[productName].amount + am
      userPurchases[productName].amount = new_amount
    } else {
      userPurchases[productName] = {}
      console.log(userPurchases)
      userPurchases[productName]['amount'] = am
    }

    let updatedWhitelist = props.project.whitelist ? props.project.whitelist : { ticketsSold: 0 }
    updatedWhitelist.ticketsSold = updatedWhitelist.ticketsSold + am

    const db = getFirestore()
    const systemReference = doc(db, 'projects', props.project.id)
    await updateDoc(systemReference, {
      whitelist: updatedWhitelist,
    })

    if (props.system) {
      let t = props.system[1].total
      const projectReference = doc(db, 'System', 'spend')
      await updateDoc(projectReference, {
        total: t + amount,
      })
    }

    const userReference = doc(db, 'wallet_users', user.wallet)
    await updateDoc(userReference, {
      purchases: userPurchases,
    })
  }

  async function sendPayment(am, pK) {
    // Variable to keep state of the payment status
    // Connecting to devnet for this example
    console.log('1.  Establish connection to the network')
    const connection = new Connection(clusterApiUrl('mainnet-beta'))
    console.log('2. Begin TXN \n')
    const payer = publicKey
    const splToken = new PublicKey('AShCRr7fqsMf7ieM5AkJqNY566HsYmtvpvK8oPUL4Bh8')
    const recipient = new PublicKey('CPHCzayp9vhxmnaX56zDRRbLFZD8CaUojxyxNyQNEvEH')
    const amount = new BigNumber(am)
    const reference = new Keypair().publicKey
    const label = '$MBC Raffle Ticket'
    let message = 'ticket'
    console.log(message, label)
    const memo = 'RT'
    console.log('3.💰 Send TXN')
    const url = encodeURL({ recipient, amount, reference, label, message, memo })
    // const { recipient, amount, splToken, reference, label, message, memo } = parseURL(url)
    try {
      // const tx = await createTransaction(connection, payer, recipient, amount, {
      //   reference,
      //   memo,
      //   splToken,
      // })
      const tx = await createTransfer(connection, payer, { recipient, amount, reference, memo, splToken })
      console.log(tx)
      const signature = await sendTransaction(tx, connection)
      console.log(signature)
      setOpenError(false)
      setOpen
      setAmount(0)
      setOpenSending(true)
      console.log(signature)
      const signatureStatus = await connection.confirmTransaction(signature, 'processed')
      console.log('status of txn', signatureStatus)
      setOpenSending(false)
      setOpenSuccess(true)
      addPurchase(props.user, props.project.name + '#WL', am)
    } catch (e) {
      console.log('error', e)
      setOpenError(true)
      setOpenSending(false)
      setOpenSuccess(false)
      //   setErrorTxt(e)
    }
  }

  return (
    <>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={{
          margin: 10,
          boxShadow: isHovering ? '0px 5px 20px 0px rgb(0, 0, 0, 0.20)' : '0px 0px 0px 0px rgb(0, 0, 0, 0.1)',
          borderRadius: 10,
          border: '0.8px solid #424549',
          color: 'white',
          overflow: 'hidden',
          padding: 15,
          width: '95%',
        }}
      >
        <Grid
          item
          sx={12}
          md={6}
          lg={6}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{ padding: 5, marginBottom: 10 }}
        >
          <Stack direction="row" display="flex">
            <Avatar style={{ border: '1px solid #14f095', height: 60, width: 60 }} src={props.project.image} />
            <Stack style={{ margin: 5 }}>
              {' '}
              <Typography style={{ marginLeft: 10, fontSize: 19, fontWeight: 'bold' }}>
                {' '}
                {props.project.name}{' '}
              </Typography>
              <Stack style={{ marginTop: -4 }} direction="row" display="flex" alignItems="center">
                {props.project.links.twitter && (
                  <a style={{ textDecoration: 'none' }} href={props.project.links.twitter}>
                    <IconButton
                      style={{ margin: 4, fontWeight: 'bold', height: 30, width: 30, textTransform: 'none' }}
                      color="primary"
                      variant="outlined"
                    >
                      {' '}
                      <BsTwitter size={25} />
                    </IconButton>
                  </a>
                )}

                {props.project.links.discord && (
                  <a style={{ textDecoration: 'none' }} href={props.project.links.discord}>
                    <IconButton
                      style={{ margin: 4, fontWeight: 'bold', height: 30, width: 30, textTransform: 'none' }}
                      color="primary"
                      variant="outlined"
                    >
                      <FaDiscord size={25} />
                    </IconButton>
                  </a>
                )}

                {props.project.links.website && (
                  <a style={{ textDecoration: 'none' }} href={props.project.links.website}>
                    <Button
                      style={{ margin: 4, fontWeight: 'bold', height: 28, textTransform: 'none' }}
                      color="secondary"
                      variant="outlined"
                    >
                      {' '}
                      Website{' '}
                    </Button>
                  </a>
                )}
              </Stack>
            </Stack>
          </Stack>{' '}
          <Stack direction="row">
            <Typography style={{ marginLeft: 10, fontSize: 11, fontWeight: 'bold' }}>
              {' '}
              supply: {props.project.supply ? props.project.supply : 'tbd'}{' '}
            </Typography>
            <Typography style={{ marginLeft: 10, fontSize: 11, fontWeight: 'bold' }}>
              {' '}
              mint price: {props.project.whitelist.mintprice ? props.project.whitelist.mintprice : 'tbd'}{' '}
            </Typography>
          </Stack>
          <Divider style={{ margin: 10 }} />
          <Typography style={{ margin: 10, marginTop: 0, fontSize: 14, fontWeight: '100' }}>
            {props.project.description}{' '}
          </Typography>
        </Grid>

        <Grid
          item
          sx={12}
          md={6}
          lg={6}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            borderRadius: 10,
            padding: 10,
            backgroundColor: '#202a30',
            border: '0.8px solid #424549',
          }}
        >
          <Box style={{ fontWeight: 'bold' }} display="flex" justifyContent="space-between" alignItems="center">
            <Typography style={{ fontSize: 19, fontWeight: 'bold' }}> type: raffle</Typography>
            {props.project.whitelist.live ? (
              <Stack alignItems="center" display="flex" direction="row">
                <Typography style={{ fontSize: 15, fontWeight: 'bold', margin: 5 }}> Live </Typography>
                <BiCircle style={{ color: '#14f095' }} />
              </Stack>
            ) : (
              <Stack alignItems="center" display="flex" direction="row">
                <Typography style={{ fontSize: 15, fontWeight: 'bold', margin: 5 }}> Not Live </Typography>
                <BiCircle style={{ color: 'red' }} />
              </Stack>
            )}
          </Box>
          <Divider style={{ marginTop: 5, marginBottom: 5, backgroundColor: '#424549' }} />
          {props.project.whitelist.endDate && props.project.whitelist.live && (
            <Box
              style={{ marginBottom: 5, marginLeft: 0, fontWeight: 'bold' }}
              display="flex"
              justifyContent="space-between"
            >
              <Stack direction="row" display="flex" alignItems="center">
                <BiStopwatch size={20} style={{ marginLeft: -4 }} />
                <Typography style={{ marginLeft: 5, fontSize: 14, fontWeight: 'bold' }}> Time Left</Typography>
              </Stack>
              {convertUTCDateToLocalDate(new Date(props.project.whitelist.endDate)) && (
                <Countdown date={convertUTCDateToLocalDate(new Date(props.project.whitelist.endDate))}>
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }}></Typography>{' '}
                </Countdown>
              )}
            </Box>
          )}

          <Box
            style={{ marginBottom: 5, marginLeft: 0, fontWeight: 'bold' }}
            display="flex"
            justifyContent="space-between"
          >
            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
              {' '}
              Price: {props.project.whitelist.ticketPrice} $MBC
            </Typography>
            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
              {' '}
              Spots: {props.project.whitelist.allocation}{' '}
            </Typography>
          </Box>
          <Box
            style={{ marginBottom: 30, marginLeft: 0, fontWeight: 'bold' }}
            display="flex"
            justifyContent="space-between"
          >
            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
              {' '}
              Sold: {props.project.whitelist.ticketsSold}{' '}
            </Typography>
            <Typography style={{ fontSize: 14, fontWeight: 'bold', color: '#14f095' }}>
              {' '}
              My Tickets:{' '}
              {props.user && props.user.purchases && props.user.purchases[props.project.name + '#WL']
                ? props.user.purchases[props.project.name + '#WL'].amount
                : 0}
            </Typography>
          </Box>

          {props.project.whitelist.live ? (
            <Grid display="flex" container spacing={1} justifyContent="space-between">
              <Grid item xs={12} sm={4} display="flex" flexDirection="column">
                <TextField
                  size="small"
                  value={amount}
                  onChange={e => changeTicketAmount(e.target.value)}
                  id="outlined-number"
                  label="Tickets"
                  type="number"
                  style={{ maxWidth: 150 }}
                  InputLabelProps={{ inputProps: { min: 0, max: 10 }, shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={8} display="flex" flexDirection="column">
                <Button
                  onClick={() => sendPayment(amount * props.project.whitelist.ticketPrice, publicKey)}
                  variant="contained"
                  style={{ backgroundColor: '#14f095', height: 38, maxWidth: 200 }}
                  startIcon={<BiCoin style={{ color: 'black' }} />}
                >
                  <p style={{ fontWeight: 'bold', textTransform: 'none' }}> Buy {amount} Tickets </p>
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid style={{ marginTop: -20 }} display="flex" container spacing={0} justifyContent="space-between">
              <Grid item xs={12} sm={6} display="flex" flexDirection="column">
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}> End Date: </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display="flex" flexDirection="column">
                <Typography style={{ textAlign: 'right', fontSize: 14, fontWeight: 'bold' }}>
                  {' '}
                  {convertUTCDateToLocalDate(new Date(props.project.whitelist.endDate))
                    .toString()
                    .substring(0, 11)}{' '}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Divider style={{ marginTop: 10, marginBottom: 2, backgroundColor: '#424549' }} />

          <Typography style={{ marginTop: 5, fontSize: 11, fontWeight: '100' }}>
            {'note: to qualify for a whitelist spot you must follow on twitter + join disc & say hi from Moneyboys'}{' '}
          </Typography>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={openSending}
        onClick={handleClose}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      <Snackbar open={openSending} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          variant="filled"
          style={{ backgroundColor: '#22222' }}
          severity="info"
          onClose={handleClose}
          sx={{ width: '100%' }}
        >
          Sending Transaction ...
        </Alert>
      </Snackbar>

      <Snackbar style={{ backgroundColor: '#22222' }} open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          variant="filled"
          style={{ backgroundColor: '#22222' }}
          severity="success"
          onClose={handleClose}
          sx={{ width: '100%' }}
        >
          Transaction Sent! {}
        </Alert>
      </Snackbar>

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert variant="filled" severity="error" onClose={handleClose} sx={{ width: '100%' }}>
          Not Confirmed: Make sure your wallet is connected, and you have enough $MBC and SOL, then try again.
        </Alert>
      </Snackbar>
      <Snackbar open={openSending} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          variant="filled"
          style={{ backgroundColor: '#22222' }}
          severity="info"
          onClose={handleClose}
          sx={{ width: '100%' }}
        >
          Sending Transaction ...
        </Alert>
      </Snackbar>

      <Snackbar style={{ backgroundColor: '#22222' }} open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          variant="filled"
          style={{ backgroundColor: '#22222' }}
          severity="success"
          onClose={handleClose}
          sx={{ width: '100%' }}
        >
          Transaction Sent! {}
        </Alert>
      </Snackbar>
    </>
  )
}

// <Grid style={{ paddingRight: 10, paddingLeft: 10, margin: 0 }} display="flex" container spacing={1.5}>
//           <Grid item xs={6} sm={4}>
//             <Label title={'Status'} metric={'Live'} />
//           </Grid>
//           <Grid item xs={6} sm={4}>
//             {/* <Label title={'Price'} metric={`${product.price} $MBC`} /> */}
//           </Grid>
//           <Grid item xs={6} sm={4}>
//             <Countdown
//               //   date={getGoLive(product.endDate)}
//               renderer={props => (
//                 <Label
//                   title={'Raffle Ends'}
//                   //   metric={`${props.days}d ${props.hours}h ${props.minutes}m ${props.seconds}s`}
//                 />
//               )}
//             />
//           </Grid>
//         </Grid>
