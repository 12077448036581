import React from 'react'
import { Box } from '@mui/system'
import CoinCard from '../coinCard/CoinCard'
import CoinCardPrice from '../coinCard/CoinCardPrice'
import { Typography } from '@mui/material'
import { Stack } from '@mui/material'
import { Divider } from '@mui/material'

export default function TokenWatchlist(props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      style={{
        width: '100%',
        borderRadius: 8,
        marginTop: 10,
        padding: 0,
        overflow: 'hidden',
        border: '0.84px solid #424549',
      }}
    >
      <Stack style={{ width: '100%', margin: 10 }} alignItems="center" direction="row" display="flex">
        <Stack>
          <Typography style={{ fontSize: 14, color: '#DBDBDB' }}> Token Watchlist </Typography>
        </Stack>
      </Stack>{' '}
      <Divider style={{ background: '#424549' }} />
      {props.dbCoins && props.dbCoins.map(coin => <CoinCardPrice coin={coin} />)}
      {/* <CoinCardPrice />  */}
    </Box>
  )
}
