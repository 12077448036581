import React, { useEffect } from 'react'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

import ProductPreview from '../../../components/mbcMarketplace/ProductPreview'
import MarketPreview from '../../../components/mbcMarketplace/MarketPreview'

import { WalletNotConnectedError } from '@solana/wallet-adapter-base'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { Keypair, SystemProgram, Transaction, sendAndConfirmTransaction } from '@solana/web3.js'

import { Toolbar, Stack, Avatar } from '@mui/material'
import { Routes, Route, Outlet, Link } from 'react-router-dom'

import { Cluster, clusterApiUrl, Connection, PublicKey } from '@solana/web3.js'
import { encodeURL } from '@solana/pay'
import BigNumber from 'bignumber.js'

import milly from '../../../assets/images/store/milly.jpg'
import crown from '../../../assets/images/store/crown.png'
import neon from '../../../assets/images/store/neon.png'
import neonpfp from '../../../assets/images/store/neonpfp.png'

import banners from '../../../assets/images/store/banners.png'

import { onSnapshot, collection, query, orderBy, getFirestore, where } from 'firebase/firestore'

import { parseURL, createTransfer } from '@solana/pay'
import { Collections } from '../../../constants'

let mbcRaffle = '$MBC Raffle'

let starterProducts = [
  {
    title: 'SolGod #3174 ',
    description: 'A Brand Born in Blockchain.',
    type: mbcRaffle,
    image: 'https://i.postimg.cc/7YV2fYhp/Sologod.png',
  },
  {
    title: 'Solana Money Girl',
    description: 'Building the Metaverse',
    type: mbcRaffle,
    image: 'https://i.postimg.cc/52qGzYTq/Document-1-copy-20.jpg',
  },
  {
    title: 'Mystery NFT',
    description: 'Chosen by the Alpha Team',
    type: mbcRaffle,
    image: 'https://i.postimg.cc/j238cyKB/mystery.webp',
  },
]

export default function Store(props) {
  const [products, setProducts] = React.useState([])

  const [finished, setFinished] = React.useState([])

  async function sendPayment() {
    // Variable to keep state of the payment status
    // Connecting to devnet for this example
    console.log('1. ✅ Establish connection to the network')
    const connection = new Connection(clusterApiUrl('mainnet-beta'), 'confirmed')
    console.log('2. 🛍 Simulate a customer checkout \n')
    const recipient = new PublicKey('CT7hHAwYnDQHo82NCFBuVsiLnnvpdK4p2P9zJDR227k4')
    const amount = new BigNumber(1)
    const reference = new Keypair().publicKey
    const label = 'MBC Store'
    const message = 'Raffle Ticket - your order - #001234'
    const memo = 'JC#4098'
    console.log('3. 💰 Create a payment request link \n')
    const url = encodeURL({ recipient, amount, reference, label, message, memo })
    console.log(url)
    // const { recipient, amount, splToken, reference, label, message, memo } = parseURL(url)
    const payer = publicKey
    const tx = await createTransfer(connection, payer, recipient, amount, {
      reference,
      memo,
    })
  }

  useEffect(async () => {
    const db = getFirestore()
    const collectionRef = collection(db, Collections.PRODUCTS)
    const q = query(collectionRef)
    const unsubscribe = onSnapshot(q, querySnapshot => {
      const products = querySnapshot.docs.map(doc => doc.data())
      let prod = products.filter(prod => prod.isLive)
      let finished = products.filter(prod => prod.isComplete)
      setProducts(prod)
      setFinished(finished)
    })
    return () => {
      unsubscribe()
    }
  }, [props.user])

  return (
    <div>
      <Toolbar style={{ borderBottom: '1px solid #424549', padding: 0 }}>
        <p style={{ fontWeight: 'bold', margin: 5, marginLeft: 20, fontSize: 19 }}>Store </p>{' '}
      </Toolbar>
      <Box style={{ marginBottom: 20, margin: 0 }} display="flex" justifyContent="center" container>
        <MarketPreview />
      </Box>

      <div style={{ margin: 20 }}>
        {/* <p style={{ fontSize: 25, fontWeight: 800 }}> Merchant Pages </p>
        <p style={{ fontSize: 15, marginTop: -25, fontWeight: 200 }}> coming soon. </p> */}

        {/* <Grid container display="flex" spacing={{ xs: 2, md: 2, lg: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
          <Box
            style={{
              border: '0.7px solid #424549',
              backgroundColor: '#202a30',
              borderRadius: 8,
              margin: 10,
              overflow: 'hidden',
            }}
          >
            <img
              src={crown}
              style={{
                maxHeight: 190,
                maxWidth: 190,

                height: '90%',
                width: '100%',

                objectFit: 'cover',
              }}
            />{' '}
            <Stack
              style={{ paddingBottom: 10 }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              direction="row"
            >
              <Stack display="flex" alignItems="center" direction="row">
                <Avatar style={{ border: '1.1px solid #424549', margin: 6, height: 40, width: 40 }} src={crown}>
                  {' '}
                </Avatar>
                <Stack>
                  <p style={{ fontWeight: 800, fontSize: 14, margin: 0 }}> Fresh Mints </p>
                  <p style={{ fontWeight: 200, fontSize: 12, margin: 0 }}> @6atman </p>
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <Box
            style={{
              border: '0.7px solid #424549',
              backgroundColor: '#202a30',
              borderRadius: 8,
              margin: 10,
              overflow: 'hidden',
            }}
          >
            <img
              src={banners}
              style={{
                height: 190,
                maxWidth: 190,
                width: '100%',
                objectFit: 'cover',
              }}
            />{' '}
            <Stack display="flex" alignItems="center" justifyContent="space-between" direction="row">
              <Stack display="flex" alignItems="center" direction="row">
                <Avatar style={{ border: '1.3px solid #424549', margin: 6, height: 40, width: 40 }} src={milly}>
                  {' '}
                </Avatar>
                <Stack>
                  <p style={{ fontWeight: 800, fontSize: 14, margin: 0 }}> Custom Banners </p>
                  <p style={{ fontWeight: 200, fontSize: 12, margin: 0 }}> @mcmilly </p>
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <Box
            style={{
              border: '0.8px solid #424549',
              backgroundColor: '#202a30',
              borderRadius: 8,
              margin: 10,
              overflow: 'hidden',
            }}
          >
            <img
              src={neon}
              style={{
                maxHeight: 190,
                maxWidth: 190,
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                maxWidth: 180,
              }}
            />{' '}
            <Stack
              style={{ paddingBottom: 10 }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              direction="row"
            >
              <Stack display="flex" alignItems="center" direction="row">
                <Avatar style={{ border: '1px solid #424549', margin: 6, height: 40, width: 40 }} src={neonpfp}>
                  {' '}
                </Avatar>
                <Stack>
                  <p style={{ fontWeight: 800, fontSize: 14, margin: 0 }}> Neon Signs </p>
                  <p style={{ fontWeight: 200, fontSize: 12, margin: 0 }}> @NFTNEONS </p>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Grid> */}
      </div>

      <div style={{ margin: 20 }}>
        <p style={{ fontSize: 25, fontWeight: 800 }}> Live Raffles </p>
        <Grid container spacing={{ xs: 3, md: 5, lg: 3 }} columns={{ xs: 12, sm: 12, md: 12 }}>
          {products.map(index => (
            <Grid item xs={6} sm={4} md={4} lg={5} key={index}>
              <ProductPreview sendPayment={sendPayment} product={index} />
            </Grid>
          ))}
        </Grid>
        <p style={{ fontSize: 25, fontWeight: 800 }}> Ended Raffles </p>

        <Grid container spacing={{ xs: 2, md: 2, lg: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
          {finished.map(i => (
            <Grid item xs={12} sm={4} md={4} lg={5} key={i}>
              <ProductPreview sendPayment={sendPayment} product={i} />
            </Grid>
          ))}
        </Grid>
      </div>
      <Outlet />
    </div>
  )
}
