import { CircularProgress, Toolbar } from '@mui/material'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'

import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Stack } from '@mui/material'
import { Button } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import CoinList from '../../../components/coinList/CoinList'
import NFTGrid from '../../../components/NFTgrid/NFTGrid'
import NFTPreview from '../../../components/NFTPreview/NFTPreview'
import Profile from '../../../components/profile/Profile'
import ConnectWallet from '../../../components/wallet/connectWallet/ConnectWallet'
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui'

import '@dialectlabs/react-ui/index.css'
import { ChatButton } from '@dialectlabs/react-ui'

import { NotificationsButton } from '@dialectlabs/react-ui'
import ReactTooltip from 'react-tooltip'

import { BiSend } from 'react-icons/bi'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const Home = props => {
  const [balance, setBalance] = useState(null)
  const [nfts, setNfts] = useState(null)
  const [moneyNfts, setMoneyNfts] = useState(null)
  const [open, setOpen] = React.useState(true)
  const [top, setTop] = React.useState([])
  const [value, setValue] = React.useState(0)

  const theme: 'dark' | 'light' = 'dark'
  const wallet = useWallet()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  // const { connection } = useConnection()
  // const { publicKey, sendTransaction } = useWallet()

  const handleClickOpen = () => {
    setOpen(true)
  }

  return (
    <div style={{ backgroundColor: '#141A1E', padding: 0 }}>
      <Toolbar style={{ borderBottom: '0.8px solid #424549', maxHeight: 24, fontSize: 19, padding: 0 }}>
        <Box style={{ width: '100%' }} display="flex" justifyContent="space-between" alignItems="center">
          <p style={{ fontWeight: 'bold', marginLeft: 20 }}>Home </p>
          <ReactTooltip place="bottom" effect="solid" style={{ border: '1.1px solid #424549', borderRadius: 10 }}>
            {' '}
            <Stack display="flex" alignItems="center" direction="row">
              <Typography style={{ fontSize: 12, margin: 2 }}>coming soon!</Typography>
            </Stack>
          </ReactTooltip>
          <Stack
            data-tip="hello world"
            style={{ marginRight: 10, minWidth: 220 }}
            direction="row"
            display="flex"
            justifyContent="space-around"
          >
            <Button
              endIcon={<BiSend size={12} />}
              disabled
              size="small"
              style={{ border: '1px solid #424549', textTransform: 'none', color: 'white', minWidth: 100 }}
            >
              Send
            </Button>
            <Button
              disabled
              size="small"
              style={{
                marginLeft: 2,
                border: '1px solid #424549',
                textTransform: 'none',
                color: 'white',
                minWidth: 100,
              }}
            >
              Receive
            </Button>
          </Stack>{' '}
        </Box>
        {/* <WalletModalProvider>
          <WalletMultiButton />
        </WalletModalProvider> */}
        {/* <ChatButton wallet={wallet} network={'mainnet-beta'} theme={theme} /> */}
      </Toolbar>
      <Grid container spacing={1} display="flex" justifyContent="center">
        {props.user ? (
          <Grid item xs={12} md={12} lg={12}>
            <Profile
              pfp={props.pfp}
              totalBalance={props.totalBalance}
              nfts={props.user.nfts}
              publicKey={props.user.wallet}
              coins={props.coins}
              dbCoins={props.dbCoins}
              balance={props.balance}
              user={props.user}
            />
            <Box sx={{ width: '100%', marginTop: -1 }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab
                    label={
                      <Typography style={{ fontSize: '20', fontWeight: 'bold', textTransform: 'none' }}>
                        Portfolio
                      </Typography>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <Typography style={{ fontSize: '20', fontWeight: 'bold', textTransform: 'none' }}>
                        NFTs
                      </Typography>
                    }
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Box display="flex" alignItems="center" flexDirection="column">
                  <CoinList coins={props.coins} />
                  {props.user.nfts && props.money ? (
                    <NFTPreview
                      money={props.money}
                      solPrice={props.dbCoins ? props.dbCoins[0].price : 0}
                      dbCoins={props.dbCoins}
                      user={props.user}
                      goToNFTTab={() => handleChange('', 1)}
                      nfts={props.nfts}
                    />
                  ) : (
                    <Box display="flex" justifyContent="center" alignItems="center" style={{ padding: 10 }}>
                      <CircularProgress style={{ margin: 30 }} color="primary" />
                    </Box>
                  )}
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                {props.user.nfts && props.money ? (
                  <NFTGrid
                    money={props.money}
                    solPrice={props.dbCoins ? props.dbCoins[0].price : 0}
                    user={props.user}
                    nfts={props.user.nfts ? props.user.nfts : []}
                  />
                ) : (
                  <Box display="flex" justifyContent="center" alignItems="center" style={{ padding: 10 }}>
                    <CircularProgress style={{ margin: 30 }} color="primary" />
                  </Box>
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                Coming soon!
              </TabPanel>
            </Box>
          </Grid>
        ) : (
          <ConnectWallet verificationResult={props.verificationResult} verifying={props.verifying} />
        )}
      </Grid>
    </div>
  )
}
export default Home
