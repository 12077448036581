import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'

import colors from '../../utils/colors'

const Search = ({ placeholder, onChange }) => {
  return (
    <SearchWrapper>
      <SearchIconWrapper>
        <SearchIcon size={'small'} style={{ height: 19, width: 19 }} />
      </SearchIconWrapper>
      <StyledInputBase placeholder={'Search...'} onChange={onChange} />
    </SearchWrapper>
  )
}

export default Search

const SearchWrapper = styled('div')(({ theme }) => ({
  margin: 5,
  position: 'relative',
  borderRadius: 10,
  borderWidth: 1,
  border: 1,
  height: 38,
  borderColor: 'white',
  borderStyle: 'solid',
  borderColor: '#202a30',
  backgroundColor: '#202a30',
  boxSizing: 'border-box',
  '&:hover, &:active, &:focus': {
    borderColor: theme.palette.grey[700],
    backgroundColor: '#202a30',
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: colors.white,
  '&': {
    width: '100%',
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.8, 0, 0, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3.2)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    fontSize: 15,
    // [theme.breakpoints.up('md')]: {
    //   width: '20ch',
    // },
  },
}))
