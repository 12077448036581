import React, { useEffect } from 'react'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardMedia, Stack, Typography, Button, IconButton } from '@mui/material'
import MeLogo from '../../assets/images/melogo.png'
import { numberWithCommas } from '../../utils/utils'

import { BiNavigation, BiStore } from 'react-icons/bi'

export default function NFTCard(props) {
  const classes = useStyles()
  const [isHovering, setIsHovering] = React.useState(false)
  const [money, setMoney] = React.useState(props.money)
  const [isHoveringDelete, setIsHoveringDelete] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [nft, setNFT] = React.useState(props.nft)
  const [selected, setSelected] = React.useState(false)

  return (
    <Card
      onClick={() => handleClick()}
      style={{
        border:
          props.selected === props.nft.mintAddress
            ? '2px solid #7fff9b'
            : !isHovering
            ? `1px solid #424549`
            : `2px solid #424549`,
        margin: 4,
        height: 235,
        backgroundColor: !isHovering ? '#191c20' : '#1D262C',
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      variant={'outlined'}
      className={classes.card}
    >
      <CardMedia component="img" height="170" image={nft ? nft.image : null} />
      <Box
        display="flex"
        style={{
          borderTop: '1px solid #424549',
          padding: 0,
          width: '100%',
          color: 'white',
        }}
      >
        <Stack
          display="flex"
          justifyContent="flex-end"
          style={{
            paddingLeft: 8,
            paddingBottom: 4,
            marginTop: -90,
            width: '100%',
            height: 90,
            background: 'linear-gradient(rgba(20, 26, 30, 0), rgba(26, 34, 39, .8))',
          }}
        >
          <Typography
            noWrap
            style={{
              fontWeight: '900',
              fontSize: 16,
              fontWeight: 600,
              marginTop: 10,
              verticalAlign: 'baseline',
            }}
          >
            {nft && nft.name ? nft.name.substring(0, nft.name.indexOf('#')) : ''}
          </Typography>
          <Typography
            noWrap
            style={{
              fontWeight: '900',
              fontSize: 14,
              fontWeight: 800,
              marginTop: 0,
              verticalAlign: 'baseline',
            }}
          >
            {nft && nft.name ? nft.name.substring(nft.name.indexOf('#'), nft.name.length + 1) : ''}
          </Typography>
        </Stack>
      </Box>

      <Box style={{ margin: 10, marginTop: 10, color: 'white' }} display="flex" justifyContent="space-between">
        <Stack>
          <Typography
            noWrap
            style={{
              fontSize: 16,
              fontWeight: 800,
              marginTop: 0,
              verticalAlign: 'baseline',
            }}
          >
            {nft.floorPrice.floorPrice > 0 ? nft.floorPrice.floorPrice + '◎' : ''}
          </Typography>

          <Typography
            noWrap
            style={{
              fontWeight: 100,
              fontSize: 9,
              marginTop: 0,
              verticalAlign: 'baseline',
            }}
          >
            {nft.floorPrice.source}
          </Typography>
        </Stack>
        <Stack display="flex" justifyContent="center" style={{ textAlign: 'right' }}>
          <a href={'https://magiceden.io/item-details/' + nft.mintAddress}>
            <IconButton size="small" style={{ border: '1px solid #424549' }}>
              <BiNavigation size={18} style={{ color: 'white' }} />
            </IconButton>
          </a>
          {/* <Typography
            noWrap
            style={{
              fontSize: 14,
              fontWeight: 200,
              marginTop: 0,
              verticalAlign: 'baseline',
            }}
          >
            ${(nft.highestTraitValue.floorPrice * props.solPrice).toFixed(2)}
          </Typography> */}
        </Stack>
      </Box>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    marginTop: 50,
  },
  card: {
    borderRadius: 10,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 5px 10px 1px rgb(44, 44, 44, 0.2)',
  },
  cardMedia: {
    minWidth: 280,
    // paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}))

{
  /* <Box
style={{ marginLeft: 10, marginTop: 8, marginRight: 10, marginBottom: -38 }}
display="flex"
justifyContent="flex-end"
>
<IconButton
  style={{
    zIndex: 10,
    width: 30,
    height: 30,
  }}
>
  {' '}
  <img src={MeLogo} style={{ width: 30, height: 30, border: `2px solid #424549`, borderRadius: 100 }} />{' '}
</IconButton>
</Box> */
}
