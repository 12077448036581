import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets'
import { clusterApiUrl } from '@solana/web3.js'
import { collection, getFirestore, onSnapshot, query, where, getDocs } from 'firebase/firestore'
import React, { useEffect, useMemo } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import AppLayout from './components/appLayout'
import { Collections } from './constants'
import FirebaseContextProvider from './contexts/firebaseContext'
import { store } from './store'
import { theme } from './theme'
import ProductPage from './views/appViews/store/ProductPage'
import Store from './views/appViews/store/Store'
import Home from './views/appViews/wallet/wallet'
import Whitelist from './views/dao/dao'
import { farm } from './assets/mint_addresses/staked.js'
import Alpha from './views/appViews/alpha/alpha'
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { TokenListProvider, TokenInfo } from '@solana/spl-token-registry'
import { doc, setDoc } from 'firebase/firestore'
import { getSPLTokens } from './services/blockchainapi'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { set } from 'firebase/database'
import Dao from './views/dao/dao'

require('@solana/wallet-adapter-react-ui/styles.css')

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST

function refreshPage() {
  window.location.reload(false)
}

function App() {
  const [user, setUser] = React.useState(null)
  const [verifying, setVerifying] = React.useState(false)
  const [tokenList, setTokenList] = React.useState(null)
  const [totalBalance, setTotalBalance] = React.useState(null)
  const [coins, setCoins] = React.useState(null)
  const [projects, setProjects] = React.useState(null)
  const [system, setSystem] = React.useState(null)
  const [mbc, setMBC] = React.useState(null)
  const [userWallets, setUserWallets] = React.useState(null)
  const [localWallet, setLocalWallet] = React.useState(null)
  const [nfts, setNFTs] = React.useState(null)
  const [summary, setSummary] = React.useState(null)
  const [dbCoins, setDBCoins] = React.useState(null)
  const [pfp, setPFP] = React.useState('')
  const [money, setMoney] = React.useState(null)
  const network = WalletAdapterNetwork.Mainnet // as WalletAdapterNetwork;
  const { publicKey, sendTransaction } = useWallet()
  const [pk, setPk] = React.useState(publicKey)
  const [verificationResult, setVerificationResult] = React.useState('not_called')
  const endpoint = useMemo(() => clusterApiUrl(network), [network])
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    [network],
  )

  /** Add a User */
  const addLocalWallet = async wallet => {
    if (wallet) {
      //first check if they already are stored locally
      let pullLocalWallet = await JSON.parse(localStorage.getItem('userWallet'))
      if (pullLocalWallet && pullLocalWallet.wallet === wallet) {
        console.log('Wallet Profile Detected! 😎' + pullLocalWallet)
      } else {
        //then check if they already a user.
        let noUser = await checkUser(wallet)
        if (noUser) {
          console.log('No user found')
          const verified = await verifyUser(wallet)
        }
      }
    }
  }

  useEffect(async () => {
    let coin = await fetch(
      ' https://public-api.birdeye.so/public/price?address=AShCRr7fqsMf7ieM5AkJqNY566HsYmtvpvK8oPUL4Bh8',
    )
      .then(res => res.json())
      .then(json => {
        setMBC(json.data.value)
      })

    return () => {}
  }, [])

  /** Get wallet profile from the server */
  useEffect(async () => {
    if (localWallet) {
      let wallet = localWallet.wallet
      const db = getFirestore()
      const collectionRef = collection(db, 'wallet_users')
      const q = query(collectionRef, where('wallet', '==', wallet))
      const unsubscribe = await onSnapshot(q, querySnapshot => {
        const userCollection = querySnapshot.docs.map(doc => doc.data())
        let user = userCollection[0]
        if (user) {
          setUser(user)
          addWalletTokenData(user)
        } else {
          console.log('wallet cached, but not user found. ')
        }
      })
      return () => {
        unsubscribe()
      }
    }
  }, [localWallet])

  const checkUser = async wallet => {
    const db = await getFirestore()
    const collectionR = collection(db, 'wallet_users')
    const q = query(collectionR, where('wallet', '==', wallet))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach(async doc => {
      if (doc.data()) {
        let newUser = { wallet }
        await localStorage.setItem('userWallet', JSON.stringify(newUser))
        location.reload()
        return false
      }
    })
    return true
  }
  const addUserToServer = async wallet => {
    const db = getFirestore()
    let newUser = { wallet }
    await localStorage.setItem('userWallet', JSON.stringify(newUser))
    await setDoc(doc(db, 'wallet_users', wallet), {
      wallet: wallet,
      username: null,
      createdAt: Date.now(),
      profilePicture: null,
      banner: null,
      following: [],
      followers: [],
      rewards: [],
      items: [],
      config: { darkMode: true },
    })
      .then(() => {
        window.location.reload(false)
        console.log('wallet profile successfully created! 🚀')
      })
      .catch(error => {
        console.error('Error creating wallet profile ', error)
      })
  }

  /** Get wallet profile from local storage */
  useEffect(async () => {
    let pullLocalWallet = await JSON.parse(localStorage.getItem('userWallet'))
    setLocalWallet(pullLocalWallet)
    let coinsLocal = await JSON.parse(localStorage.getItem('coins'))
    setCoins(coinsLocal)
    let totalBalance = await JSON.parse(localStorage.getItem('totalBalance'))
    setTotalBalance(totalBalance)
    let nfts = await JSON.parse(localStorage.getItem('nfts'))
    setNFTs(nfts)
  }, [])

  useEffect(() => {
    const db = getFirestore()
    const collectionRef = collection(db, Collections.COINS)
    const q = query(collectionRef)
    const unsubscribe = onSnapshot(q, querySnapshot => {
      const coins = querySnapshot.docs.map(doc => doc.data())
      let res = coins.filter(c => {
        // return c.live
        return c
      })
      setDBCoins(res)
    })
    return () => {
      unsubscribe()
    }
  }, [user])

  const addWalletTokenData = async user => {
    let summary = null
    let nfts = []
    let nfts2 = null
    let count = 0
    let url = 'https://portfolio-api.pyx.world/NFT/'
    let userBalance = await fetch('https://portfolio-api.pyx.world/NFT/summary/' + user.wallet)
      .then(response => response.json())
      .then(data => (summary = data))
      .catch(error => {
        console.log('error getting wallet summary! ', error)
      })

    let tokenCount = await fetch('https://portfolio-api.pyx.world/NFT/tokenCount/' + user.wallet)
      .then(response => response.json())
      .then(data => (count = data))
      .catch(error => {
        console.log('error getting wallet token count! ', error)
      })
    let increment = Math.floor(count / 20) + 1
    let start = 0
    let size = 20
    let nftFetch = null
    console.log(increment, size)
    for (let i = 0; i < increment; i++) {
      nftFetch = await fetch(url + user.wallet + '/' + start + '/' + size)
        .then(response => response.json())
        .then(data => (nfts = data.concat(nfts)))
        .catch(error => {
          console.log('error getting wallet nfts! ', error)
        })
      start += 20
      size += 20
    }
    console.log(nfts.length)
    user.nfts = nfts
    user.summary = summary
    setNFTs(nfts)
    localStorage.setItem('nfts', JSON.stringify(nfts))
    setSummary(summary)
    setUser(user)
    addPFP(nfts)
    addSOL()
  }

  const addPFP = nfts => {
    let pfp = ''
    nfts.forEach(nft => {
      if (nft.image) {
        pfp = nft.image
      }
    })
    setPFP(pfp)
  }
  const addSOL = async coins => {
    if (
      user &&
      user.summary &&
      user.summary.totalSOLBalance &&
      dbCoins &&
      coins &&
      coins[0] &&
      coins[0].symbol !== 'SOL'
    ) {
      let solana = ''
      dbCoins.forEach(c => {
        if (c.name === 'Solana') {
          solana = c
        }
      })
      let sol = {
        price: solana.price,
        symbol: 'SOL',
        user: { ui_amount: user.summary.totalSOLBalance },
      }
      let newCoins = coins
      newCoins.unshift(sol)
      return newCoins
    } else {
      return coins
    }
  }

  useEffect(async () => {
    await new TokenListProvider().resolve().then(async tokens => {
      const tokenList = await tokens.filterByClusterSlug('mainnet-beta').getList()
      setTokenList(tokenList)
    })
  }, [])

  //Get Wallet SPL Tokens
  React.useEffect(async () => {
    //testing
    if (user && tokenList) {
      let wallet = user.wallet
      pullSPLTokens(wallet, tokenList)
    }
  }, [user, tokenList])

  //Pull SPL mint address of Wallet from Blockchain API and find the corresponding token from registry
  // TODO Split into 2 functions
  let pullSPLTokens = async (wallet, tokenList) => {
    let splTokens = await getSPLTokens(wallet)
    let coins = []
    //Add Registry Data
    for (let i = 0; i < tokenList.length; i++) {
      for (let j = 0; j < splTokens.length; j++) {
        let listToken = tokenList[i]
        let splToken = splTokens[j]
        if (splToken.mint_address === listToken.address) {
          let coin = listToken
          coin.user = splToken
          coins.push(coin)
        }
      }
    }
    for (let j = 0; j < coins.length; j++) {
      let coin = coins[j]
      if (coin.extensions && coin.extensions.coingeckoId) {
        const functions = getFunctions()
        let getCoinPrice = await httpsCallable(functions, 'getCoinPrice')
        await getCoinPrice(coin.extensions.coingeckoId).then(result => {
          // Read result of the Cloud Function.
          /** @type {any} */
          coins[j].price = result.data.market_data.current_price.usd
        })
      }
    }
    let coinsWithSol = await addSOL(coins)
    setCoins(coinsWithSol)
    computeTotalBalance(coins)
    localStorage.setItem('coins', JSON.stringify(coins))
  }

  const computeTotalCoinBalance = coins => {
    let balance = null
    if (coins) {
      coins.forEach(coin => {
        if (coin.price) {
          balance += coin.price * coin.user.ui_amount
        }
      })
    }
    return balance
  }

  useEffect(async () => {
    const db = getFirestore()
    const collectionRef = collection(db, Collections.MONEY)
    const q = query(collectionRef)
    const unsubscribe = onSnapshot(q, querySnapshot => {
      let money = querySnapshot.docs.map(doc => doc.data())
      setMoney(money[0].nft)
      money = money[0].nft
      setNFTs(nfts)
    })
    return () => {
      unsubscribe()
    }
  }, [user])
  useEffect(async () => {
    const db = getFirestore()
    const collectionRef = collection(db, 'wallet_users')
    const q = query(collectionRef)
    const unsubscribe = onSnapshot(q, querySnapshot => {
      let wallets = querySnapshot.docs.map(doc => doc.data())
      setUserWallets(wallets)
    })
    return () => {
      unsubscribe()
    }
  }, [user])
  useEffect(async () => {
    const db = getFirestore()
    const collectionRef = collection(db, 'System')
    const q = query(collectionRef)
    const unsubscribe = onSnapshot(q, querySnapshot => {
      let data = querySnapshot.docs.map(doc => doc.data())
      setSystem(data)
    })
    return () => {
      unsubscribe()
    }
  }, [user])
  useEffect(async () => {
    const db = getFirestore()
    const collectionRef = collection(db, Collections.PROJECTS)
    const q = query(collectionRef)
    const unsubscribe = onSnapshot(q, querySnapshot => {
      let projects = querySnapshot.docs.map(doc => doc.data())
      let liveProject = []
      projects.forEach(project => {
        if (project.live) {
          if (project.whitelist.live) {
            liveProject.unshift(project)
          } else {
            liveProject.push(project)
          }
        }
      })
      setProjects(liveProject)
    })
    return () => {
      unsubscribe()
    }
  }, [user])

  //* Create object with Sol, NFT and SPL token balance  //
  const computeTotalBalance = coins => {
    let solana = null
    dbCoins.forEach(coin => {
      if (coin.name === 'Solana') {
        solana = coin
      }
    })
    if (dbCoins && solana && user && user.summary) {
      let solPrice = solana.price
      let totalBalance = {
        sol: user.summary.totalSOLBalance * solPrice,
        NFTs: user.summary.totalFloorValue * solPrice,
        splToken: computeTotalCoinBalance(coins),
      }
      setTotalBalance(totalBalance)
      localStorage.setItem('totalBalance', JSON.stringify(totalBalance))
    }
  }

  const verifyUser = async wallet => {
    console.log('start verify', wallet)
    let isVerified = false
    let verificationResult = 'not_auth'
    setVerifying(true)

    let nfts = []

    let count = 0

    let tokenCount = await fetch('https://portfolio-api.pyx.world/NFT/tokenCount/' + wallet)
      .then(response => response.json())
      .then(data => (count = data))
      .catch(error => {
        console.log('error getting wallet token count! ', error)
      })

    let increment = Math.floor(count / 20) + 1
    let start = 0
    let size = 20
    let nftFetch = null

    for (let i = 0; i < increment; i++) {
      nftFetch = await fetch('https://portfolio-api.pyx.world/NFT/' + wallet + '/' + start + '/' + size)
        .then(response => response.json())
        .then(data => (nfts = data.concat(nfts)))
        .catch(error => {
          console.log('error getting wallet nfts! ', error)
        })
      start += 20
      size += 20
    }
    console.log(nfts)

    nfts.forEach(nft => {
      let sub = nft.name.substring(0, 10)
      if (sub === 'Solana Dia' || sub === 'Solana Mon' || sub === 'Money Key#') {
        isVerified = true
        verificationResult = 'auth'
      }
    })
    if (!isVerified) {
      let f = farm
      f.forEach(p => {
        if (wallet === p.account.identity) {
          isVerified = true
          verificationResult = 'auth'
        }
      })
    }

    if (!isVerified) {
      let matricaBoys = []
      let matricaVerified = await fetch(
        'https://api.matricalabs.io/v1/snapshot/role/927403040342937621?apiKey=2MGNvYtuCFIxKMNyDoPmU',
      )
        .then(response => response.json())
        .then(data => (matricaBoys = data))
        .catch(error => {
          console.log('error getting matrica accounts ', error)
        })
      matricaBoys.forEach(item => {
        if (item.id === wallet) {
          isVerified = true
          verificationResult = 'auth'
        }
      })
    }

    if (!isVerified) {
      let matricaGirls = []
      let matricaVerifiedGirls = await fetch(
        'https://api.matricalabs.io/v1/snapshot/role/963922283464892436?apiKey=2MGNvYtuCFIxKMNyDoPmU',
      )
        .then(response => response.json())
        .then(data => (matricaGirls = data))
        .catch(error => {
          console.log('error getting matrica girl accounts ', error)
        })
      matricaGirls.forEach(it => {
        if (it.id === wallet) {
          isVerified = true
          verificationResult = 'auth'
        }
      })
    }

    if (!isVerified) {
      let matricaDiamonds = []
      let matricaVerifiedDiamonds = await fetch(
        'https://api.matricalabs.io/v1/snapshot/role/942204973549228133?apiKey=2MGNvYtuCFIxKMNyDoPmU',
      )
        .then(response => response.json())
        .then(data => (matricaDiamonds = data))
        .catch(error => {
          console.log('error getting matrica diamond accounts ', error)
        })
      matricaDiamonds.forEach(i => {
        if (i.id === wallet) {
          isVerified = true
          verificationResult = 'auth'
        }
      })
    }

    setTimeout(() => {
      setVerificationResult(verificationResult)
    }, 3500)
    setTimeout(async () => {
      // setVerifying(false)
      setVerificationResult('not_called')
      console.log(isVerified)
      if (isVerified) {
        await addUserToServer(wallet)
      }
    }, 5000)
  }

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets}>
            <FirebaseContextProvider>
              <Router>
                <AppLayout
                  dbCoins={dbCoins}
                  mbc={mbc}
                  setLocalWallet={addLocalWallet}
                  nfts={nfts}
                  pfp={pfp}
                  user={user}
                >
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Home
                          money={money}
                          pfp={pfp}
                          verificationResult={verificationResult}
                          verifying={verifying}
                          nfts={nfts}
                          summary={summary}
                          totalBalance={totalBalance}
                          dbCoins={dbCoins}
                          coins={coins}
                          tokenList={tokenList}
                          user={user}
                        />
                      }
                    />
                    <Route path="/product" element={<ProductPage />} />
                    <Route path="/store" element={<Store />}></Route>
                    <Route
                      path="/dao"
                      element={
                        user ? (
                          <Dao
                            system={system}
                            userWallets={userWallets}
                            dbCoins={dbCoins}
                            money={money}
                            projects={projects}
                            user={user}
                          />
                        ) : (
                          <Home
                            money={money}
                            pfp={pfp}
                            verificationResult={verificationResult}
                            verifying={verifying}
                            nfts={nfts}
                            summary={summary}
                            totalBalance={totalBalance}
                            dbCoins={dbCoins}
                            coins={coins}
                            tokenList={tokenList}
                            user={user}
                          />
                        )
                      }
                    ></Route>
                  </Routes>
                </AppLayout>
              </Router>
            </FirebaseContextProvider>
          </WalletProvider>
        </ConnectionProvider>
      </Provider>
    </ThemeProvider>
  )
}
export default App
