import { configureStore } from '@reduxjs/toolkit'

import cartReducer from './redux/cartSlice'
import uiReducer from './redux/uiSlice'

export const store = configureStore({
  reducer: {
    cart: cartReducer,
    ui: uiReducer,
  },
})
