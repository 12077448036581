import { createContext, useContext } from 'react'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getDatabase } from 'firebase/database'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyDToPHf5NAH0gjUmaqdpK8nedhJ9IhalRA',
  authDomain: 'moneymachine-64dc6.firebaseapp.com',
  projectId: 'moneymachine-64dc6',
  storageBucket: 'moneymachine-64dc6.appspot.com',
  messagingSenderId: '457884799845',
  appId: '1:457884799845:web:1dc775a067a16bf5bac78c',
  measurementId: 'G-GT1G7VM5R3',
}

const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)

export const FirebaseContext = createContext(null)
export const useFirebaseContext = () => useContext(FirebaseContext)

const FirebaseContextProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider
      value={{
        auth: getAuth(app),
        db: getDatabase(app),
        firestore: getFirestore(app),
        storage: getStorage(app),
        functions: getFunctions(app),
        analytics: getAnalytics(app),
      }}
    >
      {children}
    </FirebaseContext.Provider>
  )
}

export default FirebaseContextProvider
