import { Toolbar, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useWallet } from '@solana/wallet-adapter-react'

import { Grid } from '@material-ui/core'
import WhiteListList from '../../components/whitelist/WhiteListList'
import { CircularProgress } from '@mui/material'
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui'

import LeaderBoardGrid from '../../components/leaderboardGrid/index'
import CoinCardPrice from '../../components/coinCard/CoinCardPrice'
import { Stack } from '@mui/material'

import MbcStats from '../../components/widgets/MbcStats'
import { Paper } from '@mui/material'
import { Avatar } from '@mui/material'
import { BiInfoCircle } from 'react-icons/bi'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const Dao = props => {
  const [balance, setBalance] = useState(null)
  const [nfts, setNfts] = useState(null)
  const [moneyNfts, setMoneyNfts] = useState(null)
  const [open, setOpen] = React.useState(true)
  const [top, setTop] = React.useState([])
  const [value, setValue] = React.useState(0)
  const [isHovering, setIsHovering] = React.useState(false)
  const [projects, setProjects] = React.useState(props.projects)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const { publicKey, sendTransaction } = useWallet()
  const handleClickOpen = () => {
    setOpen(true)
  }
  return (
    <div style={{ backgroundColor: '#141A1E', padding: 0 }}>
      <Toolbar style={{ borderBottom: '0.8px solid #424549', maxHeight: 24, fontSize: 19, padding: 0 }}>
        <Box style={{ width: '100%' }} display="flex" justifyContent="space-between" alignItems="center">
          <p style={{ fontWeight: 'bold', marginLeft: 20 }}>moneydao</p>
        </Box>
        <Grid display={{ xs: 'block', md: 'none', lg: 'none' }} style={{ margin: 10, minWidth: 200 }}>
          <WalletModalProvider>
            <WalletMultiButton />
          </WalletModalProvider>
        </Grid>
      </Toolbar>
      <Grid container spacing={0} display="flex" justifyContent="center">
        <Paper
          border={1}
          elevation={1}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          style={{
            width: '100%',
            background: `linear-gradient(${0.25}turn, #7970bf, #FFB56F)`,
            padding: 0,
            height: 150,
            borderRadius: 0,
            borderBottom: '0.79px solid #424549',
            color: 'white',
            overflow: 'hidden',
          }}
        >
          <Grid
            container
            style={{
              display: 'flex',
              maxWidth: '1038px',
              overflow: 'hidden',
            }}
          >
            <Grid
              item
              sx={12}
              md={6}
              style={{
                display: 'flex',
                height: 150,
                alignItems: 'center',
                padding: '20px',
                color: 'white',
              }}
            >
              <Box>
                <Box style={{}}>
                  <Stack style={{ marginLeft: 0 }} display="flex">
                    <Typography
                      variant="h4"
                      style={{
                        fontWeight: 900,
                        paddingBottom: '5px',
                        fontSize: 35,
                        color: 'black',
                      }}
                    >
                      moneydao
                    </Typography>
                    <Typography
                      variant="h4"
                      style={{
                        fontWeight: 400,
                        fontSize: 15,
                        color: 'black',
                      }}
                    >
                      a communal dashboard for the moneyboys
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              sx={12}
              md={6}
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                padding: 20,
              }}
            ></Grid>
          </Grid>
        </Paper>
        <Grid style={{ padding: 10, borderBottom: '0.8px solid #424549' }} item xs={12} md={12} lg={12}>
          <Grid container spacing={2}>
            <Grid style={{ padding: 15 }} item xs={12} md={6} lg={6}>
              <p style={{ fontSize: 19, marginLeft: 10, marginTop: 0, fontWeight: 'bold' }}> metrics </p>
              <MbcStats system={props.system} />
              {props.userWallets && (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  style={{
                    width: '100%',
                    border: '0.7px solid #424549',
                    marginTop: 10,
                    borderRadius: 6,
                    width: '100%',
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    style={{ fontSize: 14, width: '100%', padding: 10, background: '#1D262C' }}
                  >
                    <Typography style={{ fontSize: 14 }}> moneyplex </Typography>
                  </Box>{' '}
                  <Box display="flex" justifyContent="space-between" style={{ padding: 10 }}>
                    <Typography style={{ fontWeight: 'bold' }}> Connected Wallets</Typography>
                    <Typography style={{ fontWeight: 'bold' }}> {props.userWallets.length}</Typography>
                  </Box>{' '}
                </Box>
              )}
            </Grid>
            <Grid style={{ padding: 15 }} item xs={12} md={6} lg={6}>
              <p style={{ fontSize: 19, marginLeft: 10, marginTop: 0, fontWeight: 'bold' }}> tokens </p>

              {props.dbCoins && (
                <Box
                  style={{
                    border: '0.7px solid #424549',
                    background: '#1D262C',
                    maxWidth: 350,
                    margin: 10,
                    borderRadius: 6,
                  }}
                >
                  <CoinCardPrice
                    coin={
                      props.dbCoins.filter(coin => {
                        return coin.name === 'MoneyBoyCoin'
                      })[0]
                    }
                  />
                </Box>
              )}
              {props.money && (
                <LeaderBoardGrid
                  data={{
                    rows: props.money.map(row => {
                      return row.collection
                    }),
                    size: 6,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid style={{ padding: 10 }} item xs={12} md={12} lg={12}>
          {/* <p style={{ fontSize: 89, margin: 40, fontWeight: 'bold', textAlign: 'center' }}> 👀 </p> */}
          <p
            style={{
              fontSize: 19,
              marginTop: 0,
              fontWeight: 'bold',
              textAlign: 'left',
              marginLeft: 10,
              marginBottom: 5,
            }}
          >
            {' '}
            whitelist and more ...{' '}
          </p>
          {/* <Stack style={{ marginLeft: 10, marginTop: -10 }} display="flex" alignItems="center" direction="row">
            <BiInfoCircle style={{ color: '#f19eb5' }} />
            <p
              style={{
                fontSize: 13,
                textAlign: 'left',
                marginLeft: 5,
              }}
            >
              {' '}
              Note: If the SOL network is impacted, transactions may fail to confirm.{' '}
            </p>
          </Stack> */}

          {props.projects ? (
            <WhiteListList system={props.system} user={props.user} projects={props.projects} />
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress style={{ margin: 30 }} color="primary" />
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
export default Dao
