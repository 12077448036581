import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    mode: 'dark',

    primary: {
      main: '#9a94ff',
      dark: '#9a94ff',
    },
    secondary: {
      main: '#14f095',
      dark: '#95f1c5',
    },
    error: {
      main: '#ea564f',
    },
    background: {
      default: '#141A1E',
      paper: '#141A1E',
    },
    text: {
      primary: '#e3e6fe',
    },
  },
  shape: {
    borderRadius: 5,
  },
  typography: {
    fontFamily: 'PlusJakartaText',
  },
})
