import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, CssBaseline, Button, Stack, Switch, Chip, Avatar, TextField, Checkbox } from '@mui/material'
import { WalletModalProvider, WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MailIcon from '@mui/icons-material/Mail'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import TopNav from '../navigation/TopNav'
import Cart from '../nftMarketplace/Cart'
import { selectIsCartOpen, toggleCart } from '../../redux/uiSlice'
import NavMenuDesktop from '../navigation/NavMenuDesktop'
import { BsCoin, BsGearFill, BsSun, BsCheckCircle, BsGear, BsSquare, BsTools, BsFilePerson } from 'react-icons/bs'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import {
  BiCopy,
  BiImage,
  BiCoinStack,
  BiArrowFromRight,
  BiSend,
  BiMoney,
  BiCircle,
  BiKey,
  BiUpArrow,
  BiChart,
} from 'react-icons/bi'
import { NavLink } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { TokenListProvider, TokenInfo } from '@solana/spl-token-registry'
import Popover from '@mui/material/Popover'

const axios = require('axios')

import { useAppSelector } from '../../redux/hooks'
import AddWalletInput from '../wallet/addWalletInput/AddWalletInput'
import { useThemeProps } from '@mui/material'
import AddWalletPopOver from '../wallet/addWalletPopOver/AddWalletPopOver'
import { FaHourglassEnd } from 'react-icons/fa'

import Search from '../search/Search'
import DailyIncome from '../widgets/DailyIncome'
import MbcStats from '../widgets/MbcStats'
import TokenWatchlist from '../widgets/TokenWatchlist'
import ComingSoon from '../widgets/ComingSoon'
const drawerWidth = 240

export default function AppLayout({ children, user, setLocalWallet, nfts, pfp, dbCoins, mbc }) {
  const [tokenMap, setTokenMap] = React.useState(new Map())
  const { window } = ''
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const { publicKey, sendTransaction } = useWallet()

  const container = window !== undefined ? () => window().document.body : undefined

  const handleKeyPress = useCallback(event => {
    // console.log(`Key pressed: ${event.key}`)
  }, [])

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress)

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  const dispatch = useDispatch()
  const isCartOpen = useSelector(selectIsCartOpen)
  const handleToggleCart = () => {
    dispatch(toggleCart())
  }

  const removeLocalWallet = async () => {
    localStorage.setItem('userWallet', null)
    location.reload()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  useEffect(() => {
    if (publicKey) {
      setLocalWallet(publicKey.toBase58())
      return () => {}
    }
  }, [publicKey])

  return (
    <Box style={{ maxHeight: '100vh', overflow: 'hidden' }} display="flex" justifyContent="center">
      <CssBaseline />
      <Grid container sx={{ maxWidth: 1250 }}>
        <Grid
          item
          display={{ xs: 'none', md: 'block' }}
          xs={0}
          md={3}
          lg={2}
          style={{ padding: 20, overflow: 'hidden', borderRight: '0.8px solid #424549', height: '100vh' }}
        >
          <Box
            style={{ height: '95vh', maxWidth: 300 }}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <NavLink to={'/'} style={{ textDecoration: 'none' }}>
                <Box display="flex">
                  <p
                    style={{ fontWeight: 900, color: 'white', fontSize: 24, margin: 5, marginBottom: 20, marginTop: 0 }}
                  >
                    {' '}
                    moneyplex{' '}
                  </p>
                </Box>
              </NavLink>
              {user ? (
                <>
                  <Button
                    onClick={handleClick}
                    style={{
                      textTransform: 'none',
                      // border: '0.9px solid #424549',
                      textAlign: 'left',
                      borderRadius: 10,
                      paddingTop: 5,
                      paddingBottom: 5,
                      maxWidth: 200,
                      overflow: 'hidden',
                      paddingRight: 0,
                      paddingLeft: 0,
                      marginLeft: -3,
                      marginTop: 10,
                    }}
                  >
                    <Box display="flex" style={{ marginTop: 0 }}>
                      <Stack direction="row">
                        <Avatar
                          src={user.profilePicture ? user.profilePicture : pfp}
                          style={{ margin: 5, padding: 0, height: 40, width: 40, border: '1px solid #424549' }}
                        />
                        <Stack display="flex" justifyContent="center" style={{ margin: 3 }}>
                          {user.username && (
                            <Typography style={{ color: '#14f095', fontWeight: 'bold', fontSize: 14 }}>
                              {' '}
                              {user.username ? user.username : '-'}{' '}
                            </Typography>
                          )}
                          <Stack display="flex" alignItems="center" direction="row" style={{ marginTop: -6 }}>
                            <p style={{ fontSize: 13, color: 'white', marginTop: 0, margin: 1 }}>
                              {' '}
                              {user.wallet.substring(0, 4) +
                                '....' +
                                user.wallet.substring(user.wallet.length - 5, user.wallet.length - 1)}
                            </p>{' '}
                            <IconButton
                              onClick={() => {
                                navigator.clipboard.writeText(user.wallet)
                              }}
                              style={{ marginTop: 0, marginLeft: -4 }}
                            >
                              <BiCopy size={14} />
                            </IconButton>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Button
                      onClick={() => removeLocalWallet()}
                      style={{ textTransform: 'none' }}
                      variant="contained"
                      color="primary"
                    >
                      {' '}
                      Disconnect{' '}
                    </Button>
                  </Popover>
                </>
              ) : null}
              {/* <div style={{ marginTop: 20, marginBottom: -10, minWidth: 200 }}>
                <WalletModalProvider style={{ zIndex: '10' }}>
                  <WalletMultiButton />
                </WalletModalProvider>
              </div> */}
              <div style={{ height: 20 }}> </div>
              {/* <Button
                fullWidth
                variant="contained"
                style={{
                  display: 'flex',
                  textTransform: 'none',
                  marginBottom: 20,
                  maxWidth: 170,
                  height: 34,
                  margin: 2,
                  borderRadius: 8,
                }}
              >
                <Box
                  style={{ width: '100%', fontWeight: 900 }}
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  {' '}
                  Send{' '}
                  <div
                    style={{
                      borderRadius: 4,
                      marginLeft: 0,
                      marginRight: -6,
                      fontSize: 10.2,
                      width: 30,
                      height: 22,
                      fontWeight: '900',
                      padding: 1,
                      border: '1.2px solid #141a1e',
                    }}
                  >
                    {' '}
                    ⌘K{' '}
                  </div>
                </Box>
              </Button> */}
              <div style={{ height: 20 }}> </div>
              <NavMenuDesktop />
            </Box>
            <Box
              style={{ marginLeft: 0 }}
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Chip
                icon={<BsCheckCircle style={{ color: '#141a1e', width: 17, height: 17 }} />}
                style={{
                  background: `linear-gradient(0.25turn, #9945FF, #14F195)`,
                  color: '#141a1e',
                  marginLeft: 5,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  marginTop: 10,
                  marginBottom: 10,
                  width: 80,
                }}
                label="SOL"
              />
              {/* <Button
                disable={true}
                style={{ textTransform: 'none', fontWeight: 'bold' }}
                size={'large'}
                startIcon={<BsGearFill />}
              >
                Settings
              </Button> */}

              <p style={{ fontSize: 13, textAlign: 'left' }}> Made by the Solana Money Boys 🤝 </p>
              <p style={{ fontSize: 11, textAlign: 'left', marginTop: 0 }}> ＠ 2022, All rights reserved.</p>
            </Box>
          </Box>
        </Grid>
        <Grid style={{ maxHeight: '100vh', overflow: 'scroll' }} xs={12} md={9} lg={7} item>
          {children}
        </Grid>
        <Grid
          display={{ xs: 'none', md: 'block' }}
          style={{ padding: 0, borderLeft: '0.8px solid #424549', width: '100%', padding: 15, height: '100vh' }}
          item
          xs={0}
          md={0}
          lg={3}
        >
          <Search />
          <Box style={{ marginTop: 20, padding: 2 }} display="flex" justifyContent="space-between">
            <Typography style={{ fontWeight: 'bold' }}>Widgets</Typography>
          </Box>
          {mbc && dbCoins && <TokenWatchlist dbCoins={dbCoins} mbc={mbc} />}
          {/* <ComingSoon /> */}
        </Grid>
      </Grid>
    </Box>
  )
}

{
  /* <Box
component="nav"
sx={{ backgroundColor: 'black', width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
aria-label="mailbox folders"
>
{/* The implementation can be swapped with js to avoid SEO duplication of links. */
}
// </Box> */}
{
  /* <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            backgroundColor: 'black',
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'black' },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'flex' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'black' },
          }}
          open
        >
          {drawer}
        </Drawer> */
}
