import React from 'react'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { theme } from '../../theme'
import { Avatar } from '@mui/material'
import { alpha } from '@mui/system'

import Countdown from 'react-countdown'

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import { Routes, Route, Outlet, Link } from 'react-router-dom'

import Label from '../label/Label'
import Typography from '@mui/material/Typography'

export default function ProductPreview(props) {
  const [isHovering, setIsHovering] = React.useState(false)
  const { palette } = theme

  const getGoLive = date => {
    return new Date(date)
  }

  return (
    <Link style={{ textDecoration: 'none' }} to={`/product/?product=${props.product.id}`}>
      <Card
        onClick={() => console.log('clicked on', props.product.id)}
        border={1}
        elevation={1}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={{
          boxShadow: isHovering ? '0px 5px 20px -2px rgb(0, 0, 0, 0.40)' : '0px 0px 0px 0px rgb(0, 0, 0, 0.2)',
          width: '100%',
          background: '#141A1E',
          height: 285,
          padding: 0,
          border: '0.8px solid #424549',
          borderRadius: 8,
          color: 'white',
        }}
      >
        <CardMedia
          component="img"
          height="180"
          image={props.product.image ? props.product.image : ''}
          alt={props.product.title}
        />
        <CardContent style={{ display: 'flex', height: 60, overflow: 'hidden', flexDirection: 'column' }}>
          <Typography style={{ fontSize: 15, fontWeight: '900' }} component="div">
            {props.product.title}
          </Typography>
          <Typography style={{ fontSize: 13 }} variant="body2" color="text.secondary">
            {props.product.description}
          </Typography>
        </CardContent>
        <Box style={{ padding: 10, height: 45 }} display="flex" alignItems="flex-end" justifyContent="flex-start">
          {props.product.isLive ? (
            <Chip size="small" color="secondary" style={{ margin: 3, fontSize: 12 }} label={'Live'} />
          ) : (
            <Chip
              size="small"
              style={{ margin: 5, fontSize: 12, color: 'black', backgroundColor: '#14f095' }}
              label={'Ended'}
            />
          )}
          <Chip
            size="small"
            variant="outlined"
            color="primary"
            style={{ fontSize: 12, margin: 5 }}
            label={props.product.type}
          />
        </Box>
      </Card>
    </Link>
  )
}
