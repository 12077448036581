import React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { IconButton, Typography, Box, Stack, CircularProgress, Divider } from '@mui/material'
import { BiX } from 'react-icons/bi'
import NFTGrid from '../NFTgrid/NFTGrid'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

export default function EditProfile(props) {
  const [username, setUsername] = React.useState(null)
  const [banner, setBanner] = React.useState(null)
  const [profilePicture, setProfilePicture] = React.useState('')
  const [skin, setSkin] = React.useState(props.user.skin)

  const onSelect = value => {
    setSkin(value)
    props.setSkin(value)
  }

  function saveEdits() {
    props.saveEdits()
    props.setOpen(false)
  }

  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: 10, border: '0.4px solid #424549' },
      }}
      fullWidth
      maxWidth="sm"
      style={{}}
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      <DialogTitle
        style={{
          backgroundColor: '#202a30',
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: 'bold',
          color: 'white',
        }}
      >
        <Typography style={{ fontWeight: 'bold', fontSize: 20 }}> Update Profile </Typography>
        <IconButton onClick={() => props.setOpen(false)}>
          {' '}
          <BiX> </BiX>
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: '#202a30',
          borderBottom: '0.8px solid #424549',
          minHeight: '45vh',
          padding: 10,
          borderTop: '0.8px solid #424549',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ borderBottom: '0.83px solid #424549', padding: 10, marginTop: -10 }}
        >
          <Stack style={{ width: '50%' }}>
            <Typography style={{ color: 'white', margin: 5, fontWeight: 'bold' }}> Username </Typography>
            <TextField
              style={{ marginRight: 4 }}
              color="primary"
              focused
              fullWidth
              margin="dense"
              variant="outlined"
              inputProps={{ style: { color: 'white' } }}
              onChange={e => props.setUsername(e.target.value)}
              defaultValue={props.user.username}
              id="outlined-basic"
            />
            <Typography style={{ color: 'white', margin: 5, fontWeight: 'bold' }}> Discord ID </Typography>
            <TextField
              style={{ marginRight: 4 }}
              color="primary"
              focused
              fullWidth
              margin="dense"
              variant="outlined"
              inputProps={{ style: { color: 'white' } }}
              onChange={e => props.setDiscordID(e.target.value)}
              defaultValue={props.user.discordID}
              id="outlined-basic"
            />
          </Stack>

          <Divider style={{ background: '#424549' }} orientation="vertical" variant="middle" flexItem />

          <Stack style={{ width: '50%' }}>
            <Typography style={{ color: 'white', margin: 5, fontWeight: 'bold' }}> Skin </Typography>
            <Select onChange={e => onSelect(e.target.value)} value={skin} style={{ margin: 7 }}>
              <MenuItem
                style={{
                  color: 'white',
                  border: '0.5px solid #424549',
                  padding: 10,
                  backgroundColor: '#202a30',
                  marginTop: -10,
                  marginBottom: !props.user.rewards.includes('og') ? -10 : 0,
                }}
                value={'Default'}
              >
                {' '}
                Default{' '}
              </MenuItem>
              {props.user.rewards.includes('og') && (
                <MenuItem
                  style={{
                    color: 'gold',
                    border: '0.5px solid #424549',
                    padding: 10,
                    backgroundColor: '#202a30',
                    marginBottom: -10,
                  }}
                  value={'og'}
                >
                  OG
                </MenuItem>
              )}
            </Select>
          </Stack>
        </Box>
        <Box style={{ padding: 10, marginTop: -10 }}>
          <Typography style={{ color: 'white', margin: 5, fontWeight: 'bold' }}> Avatar</Typography>
          {props.nfts ? (
            <NFTGrid isSelect={true} setProfilePicture={props.setProfilePicture} nfts={props.nfts} />
          ) : (
            <CircularProgress />
          )}
        </Box>
      </DialogContent>

      <DialogActions style={{ backgroundColor: '#202a30', padding: 20 }}>
        <Button
          style={{ textTransform: 'none', borderRadius: 10, fontWeight: '900', fontSize: 17 }}
          variant="contained"
          fullWidth
          color="primary"
          onClick={() => saveEdits()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
