import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd'

import NFTCard from '../nftCard/NFTCard'
import CircularProgress from '@mui/material/CircularProgress'

export default function NFTGrid(props) {
  const [items, setItems] = React.useState(props.nfts)
  const [nfts, setNfts] = React.useState(props.nfts)
  const [money, setMoney] = React.useState(props.money)
  const [selected, setSelected] = React.useState(null)
  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const nextState = swap(items, sourceIndex, targetIndex)
    setItems(nextState)
  }

  useEffect(() => {
    let nfts = props.nfts
    if (props.limit) {
      nfts = nfts.slice(0, props.limit)
    }

    nfts.forEach(nft => {
      if (nft.name) {
        let sub = nft.name.substring(0, 10)
        let long = nft.name.substring(0, 15)

        if (props.money && (sub === 'Solana Dia' || sub === 'Solana Mon' || sub === 'Money Key#')) {
          if (long === 'Solana Money Bo') {
            nft.floorPrice.floorPrice = props.money[0].stat.floorPrice / 1000000000
          }

          if (long === 'Solana Money Gi') {
            nft.floorPrice.floorPrice = props.money[1].stat.floorPrice / 1000000000
          }

          if (long === 'Solana Diamond ') {
            nft.floorPrice.floorPrice = props.money[2].stat.floorPrice / 1000000000
          }

          nft.floorPrice.source = 'Magic Eden'
        }
      }
    })

    setNfts(nfts)

    return () => {}
  }, [])

  const clickedNFT = nft => {
    if (props.isSelect) {
      props.setProfilePicture(nft.image)
      setSelected(nft.mintAddress)
    }
  }

  return (
    <GridContextProvider>
      <GridDropZone id="nfts" boxesPerRow={3} rowHeight={260} style={{ height: props.limit ? '100%' : '100vh' }}>
        {nfts.map(item => (
          <GridItem onClick={() => clickedNFT(item)} key={item.name}>
            <NFTCard
              money={props.money}
              solPrice={props.solPrice}
              selected={selected}
              isSelect={props.isSelect}
              nft={item}
              style={{
                width: '100%',
                height: '100%',
              }}
            ></NFTCard>
          </GridItem>
        ))}
      </GridDropZone>
    </GridContextProvider>
  )
}
