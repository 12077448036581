import { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import Box from '@mui/material/Box'

import DataGridSearch from '../dataGridSearch'
import LeaderBoardGridColumns from './LeaderBoardGridColumns'

import { escapeRegExp } from '../../utils/escapeRegExp'
import colors from '../../utils/colors'

export default function LeaderBoardGrid({ data }) {
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [rows, setRows] = useState(data.rows)

  useEffect(() => {
    const searchRegex = new RegExp(escapeRegExp(searchText), 'i')
    const filteredRows = data.rows.filter(row => {
      // console.log(row)
      return searchRegex.test(row?.name.toString())
    })
    setRows(filteredRows)
  }, [data.rows, searchText])

  useEffect(() => {
    setLoading(!rows?.length)
  }, [rows])

  useEffect(() => {}, [searchText])

  return (
    <Box
      display="flex"
      flexDirection="row"
      style={{
        overflow: 'hidden',
        border: '0.5px solid #424549',
        color: 'white',
        margin: 10,
        height: 207,
        borderRadius: 5,
        // minHeight: '500px',
      }}
      // sx={{
      //   height: 'calc(100vh - 22rem)',
      // }}
    >
      <DataGrid
        columns={LeaderBoardGridColumns}
        rows={rows}
        hideFooter
        getRowId={row => row.symbol}
        rowHeight={55}
        headerHeight={40}
        hideFooterPagination
        disableSelectionOnClick
        initialState={{
          pagination: {
            pageSize: 100,
          },
        }}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: event => setSearchText(event.target.value),
            clearSearch: () => setSearchText(''),
          },
        }}
        rowsPerPageOptions={[100]}
        sx={{
          border: '0.55px solid #424549',
          color: colors.white,
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: '0.6px solid #424549',
            backgroundColor: '#202a30',
          },
          '& .MuiDataGrid-columnHeader, & .MuiDataGrid-footerContainer .MuiTablePagination-root, & .MuiSelect-icon, & .MuiTablePagination-actions .MuiIconButton-root.Mui-disabled .MuiSvgIcon-root, & .MuiSvgIcon-root':
            {
              color: 'white',
            },
          '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: '0.5px solid #424549',
          },
          '& .MuiDataGrid-iconSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            lineHeight: 1,
          },

          '& .MuiTablePagination-actions .MuiIconButton-root .MuiSvgIcon-root': {
            color: colors.white,
          },
        }}
        loading={loading}
      />
    </Box>
  )
}
