import React from 'react'
import WhiteListRow from './WhiteListRow'
import { Box } from '@mui/system'
export default function WhiteListList(props) {
  return (
    <Box display="flex" flexDirection="column">
      {props.projects.map(project => (
        <WhiteListRow system={props.system} user={props.user} project={project} />
      ))}
    </Box>
  )
}
