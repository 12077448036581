import { Box, Link, Avatar } from '@mui/material'
import colors from '../../utils/colors'
import PercentChange from '../utils/PercentChange'

const LeaderBoardGridColumns = [
  {
    field: 'collections',
    headerName: 'name',
    width: 250,
    sortable: false,
    renderCell: ({ row }) => {
      return (
        <Box display="flex" alignItems="center" flexDirection="row" sx={{ pl: 0.2, overflow: 'hidden' }}>
          <Avatar
            style={{ margin: 0 }}
            sx={{
              width: 38,
              height: 38,
              border: '0.5px solid #424549',
            }}
            src={row.image || row.img}
          />

          <Box
            style={{
              marginLeft: 10,
              marginRight: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <Link
              style={{ textTransform: 'none', textDecoration: 'none' }}
              href={`https://magiceden.io/marketplace/${row.symbol}`}
              target="_blank"
            >
              <p
                style={{
                  fontWeight: 600,
                  fontSize: 11.4,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  color: 'white',
                }}
              >
                {row.name.substring(7, row.name.length)}
              </p>
            </Link>
            <p style={{ fontSize: 11, color: 'white', marginTop: -12 }}>
              Listed: {row?.listedCount ? new Intl.NumberFormat().format(row.listedCount) : 'N/A'}
            </p>
          </Box>
        </Box>
      )
    },
    // flex: 1,
    width: 140,
  },
  {
    field: 'floorPrice',
    headerName: 'floor',
    width: 90,
    type: 'number',
    renderCell: ({ row }) => {
      return (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="start"
          flexDirection="row"
          sx={{
            color: '#14f095',
            fontSize: 14,
          }}
        >
          {row.floorPrice / 1000000000} ◎
        </Box>
      )
    },
  },

  // {
  //   field: 'floorPriceDollar',
  //   headerName: '$ VALUE',
  //   width: 90,
  //   type: 'number',
  //   renderCell: ({ row }) => {
  //     return (
  //       <Box
  //         display="flex"
  //         justifyContent="flex-end"
  //         alignItems="center"
  //         flexDirection="row"
  //         sx={{
  //           color: colors.green,
  //         }}
  //       >
  //         {row?.currentFloorPriceDollar
  //           ? `$ ${new Intl.NumberFormat('en-US', {
  //               minimumFractionDigits: 2,
  //               maximumFractionDigits: 2,
  //             }).format(row.currentFloorPriceDollar)} `
  //           : 'N/A'}
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   field: 'last1DayFloorPriceChange',
  //   headerName: 'FLOOR PRICE % CHG (24H)',
  //   width: 120,
  //   type: 'number',
  //   renderCell: ({ row }) => {
  //     return (
  //       <Box display="flex" justifyContent="flex-end" alignItems="center" flexDirection="row">
  //         <PercentChange value={row.last1DayFloorPriceChange} />
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   field: 'Listed Count',
  //   headerName: 'LISTED',
  //   width: 80,
  //   type: 'number',
  //   renderCell: ({ row }) => {
  //     return (
  //       <Box
  //         display="flex"
  //         flexDirection="row"
  //         style={{
  //           textAlign: 'left',
  //           color: colors.green,
  //         }}
  //       >
  //         {row?.listedCount
  //           ? `${new Intl.NumberFormat('en-US', {
  //               minimumFractionDigits: 0,
  //               maximumFractionDigits: 0,
  //             }).format(row.listedCount)}`
  //           : 'N/A'}
  //       </Box>
  //     )
  //   },
  // },
  {
    field: 'volumeAll',
    headerName: 'volume',
    width: 120,
    type: 'number',
    renderCell: ({ row }) => {
      return (
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          flexDirection="row"
          style={{
            color: '#14f095',
            fontSize: 14,
          }}
        >
          {(row.volumeAll / 1000000000).toFixed(2)} ◎
        </Box>
      )
    },
  },
  // {
  //   field: 'last1DayVolumeAllChange',
  //   headerName: 'VOLUME % CHG (24H)',
  //   width: 240,
  //   type: 'number',
  //   renderCell: ({ row }) => {
  //     return (
  //       <Box display="flex" justifyContent="flex-end" alignItems="center" flexDirection="row">
  //         <PercentChange value={row.last1DayVolumeAllChange} />
  //       </Box>
  //     )
  //   },
  // },
]

export default LeaderBoardGridColumns
