const theblockchainapi = require('theblockchainapi')
const axiosMEInstance = require('../utils/axiosMEInstance')
const axios = require('axios').default

let defaultClient = theblockchainapi.ApiClient.instance

// Get a free API Key Pair here: https://dashboard.blockchainapi.com/api-keys

let APIKeyID = defaultClient.authentications['APIKeyID']
APIKeyID.apiKey = 'bjWaAu1pKhaSfmi'

let APISecretKey = defaultClient.authentications['APISecretKey']
APISecretKey.apiKey = 'cuNH9e045PhaFnB'

let apiInstance = new theblockchainapi.SolanaWalletApi()
let apiAnalyticInstance = new theblockchainapi.SolanaNFTMarketplacesApi()

let net = 'mainnet-beta'

// let network = 'mainnet-beta' // String | The network ID (devnet, mainnet-beta)

export async function getSOL(wallet) {
  const balance_request = new theblockchainapi.BalanceRequest() // BalanceRequest |
  balance_request.public_key = wallet
  balance_request.network = net
  balance_request.unit = 'sol'

  let opts = {
    balanceRequest: balance_request,
  }

  return apiInstance.solanaGetBalance(opts).then(
    data => {
      console.log('API called successfully.')
      return data
    },
    error => {
      console.error(error)
      return error
    },
  )
}

export async function getSPLTokenPrice(symbol, address) {
  console.log('symbol,', symbol, address)
  const url = 'https://api.blockchainapi.com/third-party-apis/OdAtu7xds9aTiZYW4Xnu/v0.0.1/sol/tokenprice'
  const headers = {
    APIKeyId: 'bjWaAu1pKhaSfmi',
    APISecretKey: 'cuNH9e045PhaFnB',
  }
  const response = await axios.post(
    url,
    {
      max_retries: 10.0,
      token_symbol: 'INU',
      token_address: '5jFnsfx36DyGk8uVGrbXnVUMTsBkPXGpx6e69BiGFzko',
    },
    {
      headers,
    },
  )

  // const response = await axios.post(
  //   url,
  //   {
  //     max_retries: 10.0,
  //     token_symbol: symbol,
  //   },
  //   {
  //     headers,
  //   },
  // )
  console.log(response.data, response.status)
}

export async function getSPLTokens(wallet) {
  let publicKey = wallet
  let network = net

  let opts = {
    includeNfts: false, // Boolean | Whether or not to include NFTs in the response
    includeZeroBalanceHoldings: false, // Boolean | Whether or not to include holdings that have zero balance. This indicates that the wallet held this token or NFT in the past, but no longer holds it.
  }

  return apiInstance.solanaGetTokensBelongingToWallet(network, publicKey, opts).then(
    data => {
      console.log('API SPL called successfully.')
      return data
    },
    error => {
      console.error(error)
      return error
    },
  )
}

const getNFTTokens = async mintAddress => {
  // get collection information
  const tokenResponse = await axiosMEInstance.get(`/v2/tokens/${mintAddress}`)

  return tokenResponse.data
}

export async function getNFTs(wallet) {
  let address = wallet
  let apiInstance = new theblockchainapi.SolanaWalletApi()
  let network = 'mainnet-beta'
  let publicKey = wallet
  let nftsBelongingResponse
  try {
    nftsBelongingResponse = await apiInstance.solanaGetNFTsBelongingToWallet(network, publicKey)
  } catch (error) {
    console.info(
      `wallets::getAllNFTsWalletScheduler - failed getting NFTs belonging to wallet of address ${publicKey}`,
      error.message,
    )
    return
  }
  return nftsBelongingResponse.nfts_metadata
}

export async function getNFTFloorPrice(mintAddresses) {
  let nFTAnalyticsRequest = new theblockchainapi.NFTAnalyticsRequest() // NFTAnalyticsRequest |
  let mint_addresses = mintAddresses
  nFTAnalyticsRequest.mint_addresses = mint_addresses

  nFTAnalyticsRequest.start_time = -1
  // nFTAnalyticsRequest.end_time = 1644982038;

  let opts = {
    nFTAnalyticsRequest: nFTAnalyticsRequest,
  }

  return await apiAnalyticInstance.solanaGetNFTMarketplaceAnalytics(opts).then(
    data => {
      console.log('API called successfully.')
      return data
    },
    error => {
      console.error(error)
      return error
    },
  )
}

//   let totalPrice = 0
//   const uniqueCollectionList = {}
//   const nfts = []
//   // loop through each NFT, get NFT data, collection floor price and update total price
//   for (let i = 0; i < nftsMetaData.length; i++) {
//     // get tokens with given mint address
//     let tokensResponse
//     try {
//       tokensResponse = await getNFTTokens(nftsMetaData[i].mint)
//       console.log(tokensResponse)
//     } catch (error) {
//       console.info(
//         `wallets::getAllNFTsWalletScheduler - failed getting tokens ${nftsMetaData[i].mint} of address ${address}`,
//         error.message,
//       )

//       continue
//     }

//     // add NFT object to nfts list
//     nfts.push({
//       mintAddress: tokensResponse.mintAddress,
//       collection: tokensResponse.collection,
//       name: tokensResponse.name,
//       image: tokensResponse.image,
//       attributes: tokensResponse.attributes,
//     })

//     // if a NFT does not contain collection info, then continue to next NFT
//     if (!tokensResponse.collection) {
//       //   nftsHaveNoCollectionInfo.push(tokensResponse.name)

//       continue
//     }

// get collection floor price
// let collectionFloorPrice
// try {
//   collectionFloorPrice = await getCollectionFloorPrice(tokensResponse.collection, uniqueCollectionList)
// } catch (error) {
//   console.info(
//     `wallets::getAllNFTsWalletScheduler - failed getting collection floor price of address ${address}`,
//     error.message,
//   )

//   continue
// }

//     // add up collection floor price
//     totalPrice += collectionFloorPrice

//   return nfts

//   console.info('wallets::getAllNFTsWalletScheduler - totalPrice', totalPrice)
//   console.info(
//     'wallets::getAllNFTsWalletScheduler - nftsHaveNoCollectionInfo',
//     JSON.stringify(nftsHaveNoCollectionInfo),
//   )

//   // get SOL balance
//   let SOLBalance = 0
//   try {
//     SOLBalance = await getSOLBalance(address)
//   } catch (error) {
//     console.info(`wallets::getAllNFTsWalletScheduler - failed getting SOL balance of address ${address}`, error.message)
//   }
//   console.info('SOLBalance', SOLBalance)

// then(
//     data => {
//       console.log('API called successfully.')
//       return data
//     },
//     error => {
//       console.error(error)
//       return error
//     },
//     )
