import { Box, Divider, Grid, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'

import RemoveIcon from '../utils/RemoveIcon'

// import ConnectWallet from './ConnectWallet'
import { removeToCart, getTotalCartPrice, clearCart } from '../../redux/cartSlice'

import colors from '../../utils/colors'

const Cart = () => {
  const cartItems = useAppSelector(state => state.cart.items)
  const totalCartPrice = useAppSelector(getTotalCartPrice)

  const dispatch = useAppDispatch()

  const cartItemsCount = Object.keys(cartItems).length

  return (
    <Box
      style={{
        width: '22rem',
      }}
    >
      <Box
        display="flex"
        style={{
          margin: '10px',
          border: '1px solid #262e39',
          borderRadius: '10px',
          padding: '10px',
        }}
        flexDirection="column"
      >
        <Grid
          container
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid
            item
            lg={10}
            md={10}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h5"
              style={{
                color: 'white',
              }}
            >
              My Wallets
            </Typography>
            {cartItemsCount > 0 && (
              <Box
                style={{
                  marginLeft: '20px',
                  padding: '2px 10px',
                  backgroundColor: '#8c99af',
                  borderRadius: '50%',
                  color: 'white',
                }}
              >
                <Typography variant="p">{cartItemsCount}</Typography>
              </Box>
            )}
          </Grid>
          <Grid item lg={2} md={2}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              {cartItemsCount > 0 && (
                <Typography
                  variant="p"
                  style={{
                    cursor: 'pointer',
                    fontSize: '13px',
                    color: colors.green,
                  }}
                  onClick={() => dispatch(clearCart())}
                >
                  Clear
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        <Divider
          style={{
            backgroundColor: '#262e39',
            margin: '10px 0',
          }}
        />
        <Box
          style={{
            maxHeight: 'calc(100vh - 18rem)',
            overflow: 'auto',
          }}
        >
          {cartItemsCount > 0 &&
            Object.values(cartItems).map(cartItem => <CartItem key={cartItem.id} cartItem={cartItem} />)}
          {cartItemsCount < 1 && <Typography variant="p">No wallets connected.</Typography>}
        </Box>
        {cartItemsCount > 0 && (
          <>
            <Divider
              style={{
                backgroundColor: '#262e39',
                margin: '10px 0',
              }}
            />
            <Grid
              container
              style={{
                display: 'flex',
              }}
            >
              <Grid item lg={4}>
                <Typography variant="h5">You pay</Typography>
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Box
                  style={{
                    color: 'white',
                  }}
                >{`${totalCartPrice.toFixed(2)} ◎`}</Box>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      {/* <Box
        style={{
          margin: '20px 10px',
        }}
      >
        <ConnectWallet />
      </Box> */}
    </Box>
  )
}

export default Cart

const CartItem = ({ cartItem }) => {
  const dispatch = useAppDispatch()

  return (
    <Box
      style={{
        padding: '10px 0',
      }}
    >
      <Grid container spacing="5px">
        <Grid
          item
          lg={2}
          md={2}
          xs={2}
          style={{
            position: 'relative',
          }}
        >
          <RemoveIcon
            onClick={() => dispatch(removeToCart(cartItem.id))}
            style={{
              right: '-10px',
              top: '-5px',
            }}
          />
          <img
            src={cartItem.image}
            alt={cartItem.name}
            style={{
              width: '100%',
            }}
          />
        </Grid>
        <Grid
          item
          lg={7}
          md={7}
          xs={7}
          style={{
            paddingLeft: '15px',
          }}
        >
          <Typography
            variant="p"
            style={{
              color: 'white',
              fontWeight: 'bold',
              fontSize: '15px',
            }}
          >
            {cartItem.name}
          </Typography>
          <Box
            style={{
              fontSize: '13px',
            }}
          >
            Solana Money Boys
          </Box>
        </Grid>
        <Grid
          item
          lg={3}
          md={3}
          xs={3}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            color: 'white',
          }}
        >
          {`${cartItem.price.toFixed(2)} ◎`}
        </Grid>
      </Grid>
    </Box>
  )
}
