import React from 'react'
import { BiWallet } from 'react-icons/bi'
import { Typography, Stack } from '@mui/material'
import { Box } from '@mui/material'
import { LinearProgress } from '@mui/material'

import logo from './../../../assets/images/moneyboys/504.png'

import { useWallet } from '@solana/wallet-adapter-react'
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
export default function ConnectWallet(props) {
  console.log(props.verificationResult)
  return (
    <Box
      display="flex"
      justifyContent="center"
      style={{
        background: '#141A1E',
        padding: 0,
        margin: 30,
        borderRadius: 10,
        border:
          props.verificationResult === 'not_called'
            ? '0.8px solid #424549'
            : props.verificationResult === 'not_auth'
            ? '0.8px solid #f19eb5'
            : '0.8px solid #14f095',

        color: 'white',
        padding: 20,
        width: '100%',
      }}
    >
      {' '}
      <Stack display="flex" justifyContent="center" alignItems="center">
        <Typography
          color="secondary"
          style={{
            textAlign: 'center',
            textTransform: 'uppercase',
            fontSize: 13,
            marginTop: 20,
            marginBottom: 0,
            fontWeight: 100,
          }}
        >
          Solana Money Boys
        </Typography>
        <Typography style={{ fontSize: 22, fontWeight: 'bold', marginTop: -3, marginBottom: 18 }}>
          {' '}
          welcome to the moneyplex
        </Typography>
        <Box
          borderRadius={100}
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: 90, width: 90, margin: 5, backgroundColor: '#202a30' }}
        >
          {' '}
          <img style={{ border: 1, borderColor: 'white', height: 100, width: 100, borderRadius: 100 }} src={logo}></img>
        </Box>
        {!props.verifying ? (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            {' '}
            <Typography style={{ fontSize: 22, marginTop: 12, fontWeight: 'bold', marginBottom: -5 }}>
              {' '}
              authenticate
            </Typography>
            <Typography style={{ textAlign: 'center', fontSize: 13, margin: 8, marginBottom: 20, fontWeight: 300 }}>
              verify moneyboy.
            </Typography>
          </Box>
        ) : (
          <Box>
            {props.verificationResult !== 'not_called' ? (
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                sx={{ width: '100%', height: 75 }}
              >
                <Typography style={{ fontSize: 12, fontWeight: 'bold', fontSize: 22, color: 'white' }}>
                  {props.verificationResult === 'not_auth' ? 'access denied' : 'access granted!'}{' '}
                </Typography>
                <Typography style={{ fontWeight: 'light', alignText: 'center', fontSize: 12, color: 'white' }}>
                  {props.verificationResult === 'not_auth' ? 'must be a holder or staking wallet' : 'welcome.'}{' '}
                </Typography>
              </Box>
            ) : (
              <Box
                alignItems="center"
                style={{ minWidth: 180, height: 60, margin: 10 }}
                flexDirection="column"
                display="flex"
              >
                <Typography style={{ fontSize: 22, fontWeight: 'bold', margin: 10 }}> verifying </Typography>
                <Box sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>
              </Box>
            )}
          </Box>
        )}

        <WalletModalProvider>
          <WalletMultiButton />
        </WalletModalProvider>
        <a style={{ color: 'white' }} href=" https://solanamoneyboys.com/">
          <Typography style={{ fontSize: 12, margin: 20, marginBottom: 20, fontWeight: 300 }}>learn more</Typography>
        </a>
      </Stack>
    </Box>
  )
}
