import React from 'react'
import { Box } from '@mui/system'
import { Typography, Stack } from '@mui/material'
import { BiCoin, BiWalletAlt } from 'react-icons/bi'
import { BsWallet2 } from 'react-icons/bs'
import CircularProgress from '@mui/material/CircularProgress'
import CoinCard from '../coinCard/CoinCard'
import { numberWithCommas } from '../../utils/utils'

export default function CoinList(props) {
  const computeTotalCoinBalance = coins => {
    let balance = 0
    if (coins) {
      coins.forEach(coin => {
        if (coin.price) {
          balance += coin.price * coin.user.ui_amount
        }
      })
    }

    return balance
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        width: '100%',
        borderRadius: 10,
        border: '0.5px solid #424549',
        margin: 4,
        overflow: 'scroll',
        marginTop: 0,
        marginBottom: 7,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ borderBottom: '0.5px solid #424549', padding: 16, width: '100%' }}
      >
        <Stack style={{ margin: 0 }} display="flex" alignItems="center" direction="row">
          <BiCoin style={{ marginRight: 4, marginLeft: 10 }} />
          <Typography style={{ marginLeft: 4, fontWeight: 'bold', fontSize: 17 }}> Coins </Typography>
        </Stack>

        {props.coins && (
          <Stack>
            {' '}
            <Typography style={{ fontSize: 17, fontWeight: 'bold', textAlign: 'right' }}>
              ${numberWithCommas(computeTotalCoinBalance(props.coins).toFixed(2))}{' '}
            </Typography>
            <Typography style={{ fontSize: 12, color: '#e3e6fe', textAlign: 'right' }}>Total</Typography>
          </Stack>
        )}
      </Box>
      <Box
        style={{ maxHeight: 202, minHeight: 100, overflow: 'scroll' }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {props.coins ? (
          props.coins.map(coin => <CoinCard key={coin.symbol} coin={coin} />)
        ) : (
          <CircularProgress style={{ margin: 25 }} color="secondary" />
        )}
      </Box>
    </Box>
  )
}
